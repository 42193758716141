import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ApiCall from '../../../Helper/ApiCalls';
import DataTable from '../../Components/Table/DataTable';
import TableHead from '../../Components/Table/TableHead';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { insertUserPersonalDataAction } from '../../Redux/Actions/UserPersonalDetailsActions';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import { baseUrl } from '../../RequestMethord';
import axios from 'axios'
import { useTitle } from '../../../Hooks/UseTitle';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions';
import { toast } from 'react-toastify';


const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },
  {
      id: "userID",
      label: "Id",
      minWidth: 180,
      sort: false,
    },

  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "bookings",
    label: "Bookings",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "cancelled",
    label: "Cancelled",
    minWidth: 200,
    align: "left",
    sort: true,
  },
  {
    id: "reviews",
    label: "Reviews",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "joinedDate",
    label: "Joined Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },

  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];




function User() {
  //  STATES   
      useTitle("Users - Geolah");
     
      const [rows, setRows] = useState();
      const [success, setSuccess] = useState()
      const [selectedItems, setSelectedItems] = useState([]);
      const [tableData, setTableData] = useState([]);
      const [searchInput , setSearchInput] = useState('')
      const [isFilterOn , setIsFilterOn] = useState(false)
      const [deleteStatus , setDeleteStatus] = useState(false)
      const [deleteData , setDeleteData] = useState({})
      const [order, setOrder] = useState("ASC");
      const [rowsPerPage,setRowsPerPage] = useState(10)
      const [currentPage,setCurrentPage] = useState(1)
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const [totalRows,setTotalRows] = useState(0)
      dispatch(insertNavigationDataAction('Users'))
      const {currentCountrydata:CountryID} = useSelector((state) => state.country)
      const [any,setAny] = useState()
      const [localTime, setLocalTime] = useState(null);

      const [write,setWrite] = useState()
      const [edit,setEdit] = useState()
      const [searchPage,setSearchPage] = useState(0)
      const [deletePer,setDeletePer] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Users')
  setAny(isAany)
  let isWrite = checkPrivilage('Users','CREATE')
  setWrite(isWrite)
 let isEdit = checkPrivilage('Users','UPDATE')
   setEdit(isEdit)

   let isDelete = checkPrivilage('Users','DELETE')
   setDeletePer(isDelete)
   },[])
  
  
  // RENDERS AND API-CALLS



//   useEffect(() => {
//     // Get the user's time zone
//     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const utcDate = new Date('2024-01-01T12:00:00Z');
//     const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));
//     setLocalTime(localDate.toLocaleString());
//   }, []);

// console.log(localTime , " <---local_timeee")



  
  useEffect(()=>{
    if(searchInput?.length > 0){
      setSearchPage(1)
    }else{
      setSearchPage(0)
    }
    const ulteredPage = searchPage ? searchPage :currentPage;
    let currentLimit = rowsPerPage
    let currentOffset = (currentPage-1) * rowsPerPage

 
 
      ApiCall("GET", `/user-service/v1/admin/user/customers/all?limit=${currentLimit}&&offset=${currentOffset}&&countryId=${CountryID}&&search=${searchInput}`, null, "uui").then((data)=>{

        if(data?.result){
          const userData = data?.result?.UsersData;
          setTotalRows((data?.result?.TotalUsers)-3)
          console.log(userData,"<----Dataaaaaa")

          const filteredData = userData?.map((eachItem,index)=>{
             let testT = convertToCurrentCountryTime(eachItem?.Created_At)
             
             console.log(testT , " <--testT")
            let items={
              id:index+1+currentOffset,
              _id:eachItem?.ID,
              userID:eachItem?.ID ,
              name:eachItem?.FirstName ? eachItem?.FirstName +' '+ eachItem?.LastName : 'N/A',
              phone:eachItem?.PhoneNumber,
              bookings:eachItem?.totalbookings,
              cancelled:eachItem?.cancelledcount,
              reviews:eachItem?.reviewscount,
              joinedDate:testT,
              userTypeId:eachItem?.userTypeID,
              allUserData:eachItem
            }

            return items
          })


          setRows(filteredData)
          setTableData(filteredData);
   
        }

       })
    },[success,currentPage,rowsPerPage,searchInput])

    // useEffect(()=>{
    //   setRows(
    //     tableData.filter((item) =>
    //     item?.userID?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.name?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.phone?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.bookings?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.cancelled?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.reviews?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //     item?.joinedDate?.toLowerCase().includes(searchInput.toLowerCase())
   
    //       ))
    //    },[searchInput])
  
  
  
  
  //FUNCTIONS
  
  
  //UPDATE STATUS
  const handleStatusChange =(id, newData)=>{
      //  console.log(`Switch with id ${id} changed. New data:`, newData);
      //  const paramsData = {valueServiceId:id,status:newData?.status}
      //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
    }
  
  // DELETE STATUS 
  const handleServiceDelete =(id, newData)=>{
      // console.log(`Delete with id ${id}. New data:`, newData);
      setDeleteStatus(true)
      const paramsData ={id,userTypeId:1}
      setDeleteData(paramsData)
      }  
   
  //DELETE
  const onDeleteYes =(yesData)=>{

    console.log(yesData,"<----in deleteeee")
    const apiUrl = `${baseUrl}/user-service/v1/app/auth/delete/account`;  
    const TOKEN = localStorage?.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: yesData?.id,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
        'Content-Type': 'application/json',
  
      },
    };

      axios.patch(apiUrl,{userTypeId:yesData?.userTypeId}, config)
      .then(response => {
        if(response?.data?.status === 'success'){
        
            setSuccess(response) 
            setDeleteStatus(false)
            toast.success("Successfully deleted.");
          
        }else{
          toast.error("Something went wrong.!");
         }
        console.log(response,"<---------------------")
      })
      .catch(error => {
    
        toast.error("Something went wrong.!");
   
      });

      // ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
      //  setSuccess(data) 
      // setDeleteStatus(false)
      //    })
     }
  
  //CLOSE DELETE NOTIFICATION

  const onCloseNotification = ()=>{
          setDeleteStatus(false)
       }

    //   const handleServiceEdit =(id,newData)=>{
   //     if(id){
  //       navigate(`/edit-service/${id}`)
  //     }
    //  }
     
   //HANDLE SEARCH CHANGE
  const handleChangeInSearch = (value)=>{
      setSearchInput(value)

   }
  
   
  // COLUMN FILTER 
  const handleColumnFilter = (items, searchText) => {
  
  
  
      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    
        // Check if the row matches all of the selected vehicleType items
        const vehicleTypeMatch = items.every(
          (item) => row.vehicleType && row.vehicleType.includes(item)
        );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };
  
  
  // FILTERBAR
  const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }

     //handle page change

 const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
}
  
  //NAVIGATE EDIT 
  const handleServiceEdit =(id,newData)=>{
      // if(id){
      //   navigate(`/edit-service/${id}`)
      // }
     } 
  
   //NAVIGATE PROFILE DETAILS
   
   const handleProfileEdit = (id,newData)=>{

    const newTab = window.open(`/user-details/${id}`, '_blank');
    if (newTab) {
      newTab.focus(); // Optionally, you can focus on the new tab
    }
      // if(id){
      //   navigate(`/user-details/${id}`)
      // }
   }
   const handleUserData = (id,newData)=>{
    dispatch(insertUserPersonalDataAction(newData))
 
    // if(id){
    //   navigate(`/user-details/${id}`)
    // }
 }
  
  
  //COLUMN FILTER
  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }
  
    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };
  
    return (
      any ? (
  <Box>
  <TableHead title={'Users'}/>
  <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
  <Box sx={{ width: "100%", height: "100%" }} pt={1}>
  <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
  <DataTable
  handlingPage={handlePageChange}
  totalCount={totalRows}
     DeletePermission={deletePer}
     ButtonPermission={write}
     EditPermission={edit} 
          handleUserPersonalData={handleUserData}
         handleClickProfileDetails={handleProfileEdit}
         isFilterOn={isFilterOn}
         openFilter={handleFilterBar}
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
          // ButtonPath={'/create-user'}
          // ButtonLabel={'Create User'}
          handleSwitchChange={handleStatusChange}
          handleDelete={handleServiceDelete}
          handleEdit={handleServiceEdit}
          columnFilter={handleColumnFilter}
          searchInput={handleChangeInSearch}
          filterComponent={''}
        />
  </Box>
  </Box>
  </Box>) : any === false ?  (
   <RestrictedUsage/>
  ) : null
    )
  }
  
  export default User
  