import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCalls';
import { Block } from '@mui/icons-material';
import DataTable from '../../Components/Table/DataTable';
import CategoryFilter from './CategoryFilter';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { toast } from 'react-toastify';



const columns = [
    {
      id: "id",
      label: "No",
      maxWidth: 100,
      sort: true,
    },
  
    {
      id: "rideCategory",
      label: "Ride Category",
      maxWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "rideservice",
      label: "Ride Service",
      maxWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "seats",
      label: "Seats",
      maxWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "vehicleType",
      label: "Vehicle Type",
      maxWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "vehicleCategory",
      label: "Vehicle Category",
      maxWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "basicFare",
      label: "Basic Fare",
      maxWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "minKm",
        label: "Min. KM",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "costPerKm",
        label: "Cost/KM",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "advFare",
        label: "Adv. Fare",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "twoHr",
        label: "2hr",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "fourHr",
        label: "4hr",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "sixHr",
        label: "6hr",
        maxWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "status",
        label: "Status",
        maxWidth: 170,
        align: "left",
        sort: false,
        title: {
          onTrue: true,
          onFalse: false,
        },
      },
 
    {
      id: "actions",
      label: "Actions",
      maxWidth: 170,
      align: "left",
      sort: false,
    },
  ]; 






function RideCategoryMangement() {
  useTitle("Ride Category - Geolah");
    const [rows, setRows] = useState();
    const [totalRows,setTotalRows] = useState(0);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)

    const dispatch = useDispatch()
    dispatch(insertNavigationDataAction('Ride Category'))
    const navigate = useNavigate()
    const [any,setAny] = useState()

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Ride Category')
      setAny(isAany)
      let isWrite = checkPrivilage('Ride Category','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Ride Category','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Ride Category','DELETE')
       setDeletePer(isDelete)
       },[])



    const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }


useEffect(()=>{
setSuccess(1000+1)
},[])


    // /trip-service/v1/admin/ride-category

    useEffect(()=>{
      let currentLimit = rowsPerPage
      let currentOffset = (currentPage-1) * rowsPerPage

        ApiCall("GET", `/trip-service/v1/admin/ride-category/?countryId=${CountryID}`, null, "uui").then((data)=>{
          if(data?.result){

            const categoryData = data?.result
            setTotalRows(categoryData?.length)
            if(searchInput.length <= 0){
              
            const filteredData = categoryData?.slice(currentOffset, currentOffset + currentLimit).map((eachCat,index)=>{
              console.log(eachCat,"<---categoryyyyyyyy")
              const RowVehicletypes = eachCat?.VehicleTypeIDS?.map((eachVehicle)=>{
                return eachVehicle?.Type
              })
              const RowVehicleCat = eachCat?.VehicleCategory?.map((eachVehicle)=>{
                return eachVehicle
              })
              console.log(RowVehicletypes,"<---rowwww")

              const VehicleTypes = RowVehicletypes?.join(", ")
              const isHourly = eachCat?.RideCategoryFareID?.HourlyBookingID
              // const {IsEnabled,Block1,Block2,Block3} = eachCat?.HourlyBookingID
              // const allSeats = eachCat?.Seats?.map((eachSe))
              const fairPerKilometres = parseFloat(eachCat?.RideCategoryFareID?.FairPerKilometres);
              const basicFares = parseFloat(eachCat?.RideCategoryFareID?.MinCharge)
              const advancedFare = parseFloat(eachCat?.RideCategoryFareID?.AdvancedBookingID?.AdvancedBookingCost)
              const twoHrFare =  parseFloat(isHourly?.Block1?.Cost)
              const threeHrFare = parseFloat(isHourly?.Block2?.Cost)
              const fourHrFare = parseFloat(isHourly?.Block3?.Cost)
              const items = {
                _id:eachCat._id,
                id:index+1+currentOffset,
                rideCategory:eachCat?.Name,
                rideservice:eachCat?.ValueService?.ServiceName,
                seats:eachCat?.Seats.join(','),
                vehicleType:VehicleTypes || "N/A",
                vehicleCategory:RowVehicleCat?.join(", "),
                basicFare: isNaN(basicFares) ? "N/A" : `S$ ${basicFares.toFixed(2)}` || "N/A",
                minKm:eachCat?.RideCategoryFareID?.MinDistance || "N/A",
                costPerKm: isNaN(fairPerKilometres) ? "N/A" : `S$ ${fairPerKilometres.toFixed(2)}` || "N/A",
                advFare: isNaN(advancedFare) ? "N/A" : `S$ ${advancedFare.toFixed(2)}`  || "N/A",
                status:eachCat?.isEnabled,
                twoHr:isNaN(twoHrFare) ? "N/A" : `S$ ${twoHrFare.toFixed(2)}` ||  "N/A" ,
                fourHr:isNaN(threeHrFare) ? "N/A" : `S$ ${threeHrFare.toFixed(2)}` ||  "N/A" ,
                sixHr:isNaN(fourHrFare) ? "N/A" : `S$ ${fourHrFare.toFixed(2)}` ||  "N/A"  ,
               }
               return items
              })
              setRows(filteredData)
            }



              const filteredRowData = categoryData?.map((eachCat,index)=>{
                console.log(eachCat,"<---categoryyyyyyyy")
                const RowVehicletypes = eachCat?.VehicleTypeIDS?.map((eachVehicle)=>{
                  return eachVehicle?.Type
                })
                const RowVehicleCat = eachCat?.VehicleCategory?.map((eachVehicle)=>{
                  return eachVehicle
                })
                console.log(RowVehicletypes,"<---rowwww")
  
                const VehicleTypes = RowVehicletypes?.join(", ")
                const isHourly = eachCat?.RideCategoryFareID?.HourlyBookingID
                // const {IsEnabled,Block1,Block2,Block3} = eachCat?.HourlyBookingID
                // const allSeats = eachCat?.Seats?.map((eachSe))
                const fairPerKilometres = parseFloat(eachCat?.RideCategoryFareID?.FairPerKilometres);
                const basicFares = parseFloat(eachCat?.RideCategoryFareID?.MinCharge)
                const advancedFare = parseFloat(eachCat?.RideCategoryFareID?.AdvancedBookingID?.AdvancedBookingCost)
                const twoHrFare =  parseFloat(isHourly?.Block1?.Cost)
                const threeHrFare = parseFloat(isHourly?.Block2?.Cost)
                const fourHrFare = parseFloat(isHourly?.Block3?.Cost)
                const items = {
                  _id:eachCat._id,
                  id:index+1+currentOffset,
                  rideCategory:eachCat?.Name,
                  rideservice:eachCat?.ValueService?.ServiceName,
                  seats:eachCat?.Seats.join(','),
                  vehicleType:VehicleTypes || "N/A",
                  vehicleCategory:RowVehicleCat?.join(", "),
                  basicFare: isNaN(basicFares) ? "N/A" : `S$ ${basicFares.toFixed(2)}` || "N/A",
                  minKm:eachCat?.RideCategoryFareID?.MinDistance || "N/A",
                  costPerKm: isNaN(fairPerKilometres) ? "N/A" : `S$ ${fairPerKilometres.toFixed(2)}` || "N/A",
                  advFare: isNaN(advancedFare) ? "N/A" : `S$ ${advancedFare.toFixed(2)}`  || "N/A",
                  status:eachCat?.isEnabled,
                  twoHr:isNaN(twoHrFare) ? "N/A" : `S$ ${twoHrFare.toFixed(2)}` ||  "N/A" ,
                  fourHr:isNaN(threeHrFare) ? "N/A" : `S$ ${threeHrFare.toFixed(2)}` ||  "N/A" ,
                  sixHr:isNaN(fourHrFare) ? "N/A" : `S$ ${fourHrFare.toFixed(2)}` ||  "N/A"  ,
                 }
                 return items
                })
  






            
            setTableData(filteredRowData);
        
            // console.log(filteredData , "<---##reeEEEEsulltt")
       
       } })
      },[success,CountryID,currentPage,rowsPerPage,searchInput])


   
  const [order, setOrder] = useState("ASC");

  const columnSortingFn = (id) => {
      if (order === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id"
            ? a[id].toLowerCase() > b[id].toLowerCase()
              ? 1
              : -1
            : a[id] > b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("DSC");
      }
  
      if (order === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() < b[id].toLowerCase()
              ? 1
              : -1
            : id === "date"
            ? new Date(a.date).getTime() - new Date(b.date).getTime()
            : a[id] < b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("ASC");
      }
    };











   // update category status

  const handleStatusChange =(id, newData)=>{

     const paramsData = {rideCategoryId:id,status:newData?.status}
     ApiCall("PATCH", "/trip-service/v1/admin/ride-category/update/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
  }

  // delete status  
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);

  setDeleteStatus(true)
  const paramsData ={id,name:newData?.rideCategory}
  setDeleteData(paramsData)
 
}

const onDeleteYes =(yesData)=>{
 ApiCall("PATCH", `/trip-service/v1/admin/ride-category/${yesData?.id}`,null).then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
})
 } 

const onCloseNotification = ()=>{
  setDeleteStatus(false)
}


const handleServiceEdit =(id,newData)=>{
  if(id){
    navigate(`/edit-category/${id}`)
  }
}



const handleChangeInSearch = (value)=>{
  setSearchInput(value)
}

useEffect(()=>{
  
  setRows(
    tableData.filter((item) =>
    item?.advFare?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.basicFare?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.costPerKm?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.fourHr?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.minKm?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.rideCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.rideservice?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.seats?.toString().includes(searchInput.toLowerCase()) ||
    item?.sixHr?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.twoHr?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase()) 
      ))
   },[searchInput])



const handleColumnFilter = (items, searchText) => {


  console.log("items:",items ,"SearchText:",searchText)
  setSelectedItems(items); // Update selected filter items

  // If no items are selected and no search text, show all rows
  if (items.length === 0 && searchText === "") {
    setRows(tableData);
    return;
  }
       
  console.log(tableData,"<-----TTABLEDATA")
  console.log(items,"<-----ITEMS")

  // Filter the rows based on the selected items and search text
  const filteredRows = tableData.filter((row) => {
    // Check if any of the selected items are present in the row's features
    const featuresMatch = items.every(
      (item) => row.features && row.features.includes(item)
    );

    // Check if the row matches any of the selected vehicleCategory items
    const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    const vehicleCat = items.every(
      (item) => row.vehicleCategory && row.vehicleCategory.includes(item)
    );

    // Check if the row matches all of the selected vehicleType items
    // const vehicleTypeMatch = items.every(
    //   (item) => row.vehicleType && row.vehicleType.includes(item)
    // );
    const vehicleTypeMatch = items.every(
      (item) => row.seats && row.seats.includes(item)
    );

    const serviceNameMatch = items.every(
      (item) => row.rideservice && row.rideservice.includes(item)
    );

    // Check if the row contains the search text in any of its values
    const searchTextMatch = Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    // Return true if any of the filters match the row, otherwise return false
    return (
      (vehicleCat || serviceNameMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
      searchTextMatch
    );
  });

  // Update the filtered rows
  setRows(filteredRows);
};


const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}



  return (
    any === true ? (
<Box>
<TableHead title={'Ride Category Management'}/> 
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<DataTable
handlingPage={handlePageChange}
    totalCount={totalRows}
     DeletePermission={deletePer}
     ButtonPermission={write}
     EditPermission={edit} 
        isFilterOn={isFilterOn}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        openFilter={handleFilterBar}
        ButtonPath={'/create-category'}
        ButtonLabel={'Create Category'}
        handleEdit={handleServiceEdit}
        handleRowSelection={(noOfrows) => setRowsPerPage(noOfrows) }
        // columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <CategoryFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      />

</Box>


    </Box>  

</Box>): any === false ?(
   <RestrictedUsage/>
) : null
  )
}

export default RideCategoryMangement
