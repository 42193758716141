import { Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SelectField from '../../Components/Forms/SelectField'
import { AD_TYPE, App_Type } from '../../FakeData/FakeData'

function AppAdsFilter({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)
  const [filteredAd , setFilteredAd] = useState([])
  
    const AppType = [
        {name:'User App'},{name:'Driver App'}
    ]
    const AddType = [
        {name:'OFFER'},{name:'ALERT'},{name:'WHATS_NEW'},
    ]


    const handleMultipleSelect = (label,data)=>{

      if(label === 'App Type'){
        if(data?.id === 2){
          let temp = AD_TYPE?.filter((f) => f.id !== 'OFFER' && f.id !== 'HEADER_OFFERS')
          setFilteredAd(temp)
       }else if(data?.id === 1){
         let temp = AD_TYPE?.filter((f) => f.id !== 'ALERT' && f.id !== 'WHATS_NEW')
         setFilteredAd(temp)
       }
   data?.id &&  FilterColumn([data?.name],"")
    
      }else if(label === 'Ad Type'){

     data?.id &&   FilterColumn([data?.name],"")
      }
    
    }

    const HandleClearFilter =()=>{
      setIsClear((i)=> !i)
    }




  return (
  <Box >
   <Stack alignItems={'center'} spacing={2} direction={'row'}>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={AppType} fieldName={'App Type'}/> */}
    {/* <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"App Type"} Data={AppType} />
    </Box> */}
    <Box  width={'250px'}>
    <SelectField clearStatus={isClear} isError={false} onSelecting={handleMultipleSelect} Data={App_Type} sideLabel={'App Type'} placeHolder={'choose'} />
    </Box>

    {/* <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Ad Type"} Data={AddType} />
    </Box> */}
<Box  width={'250px'}>
<SelectField clearStatus={isClear} isError={false} onSelecting={handleMultipleSelect} Data={filteredAd} sideLabel={'Ad Type'} placeHolder={'choose'} />
</Box>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={AddType} fieldName={'Ad Type'} /> */}

    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
    </Stack>
    
  </Box>
  )
}

export default AppAdsFilter