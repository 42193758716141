import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import MultistepProgressBar from '../../../Components/Forms/ProgressBar/MultistepProgressBar';


import PersonalFormFleet from './Pages/PersonalFormFleet';
import ServiceFormFleet from './Pages/ServiceFormFleet';

import DrivingLicenseFormFleet from './Pages/DrivingLicenseFormFleet';
import MultistepProgressBarFleet from '../../../Components/Forms/ProgressBar/MultistepProgressBarFleet';


function FleetProgressionDriver() {
    const [page, setPage] = useState("pageone");
    const [pageOneStatus , setPageOneStatus] = useState(false)
    const [pageTwoStatus , setPageTwoStatus] = useState(false)
    const [pageThreeStatus , setPageThreeStatus] = useState(false)
    const [pageFourStatus , setPageFourStatus] = useState(false)
    const [pageFiveStatus , setPageFiveStatus] = useState(false)

    const nextPage = (page) => {
   
      setPage(page);
    };

const pageDone=(status,name)=>{
if(name === 'personal'){
  setPageOneStatus(status)
}else if(name === 'service'){
  setPageTwoStatus(status)
}else if(name === 'driving'){
  setPageThreeStatus(status)
}else if(name === 'vehicle'){
  setPageFourStatus(status)
}else if(name === 'insurance'){
  setPageFiveStatus(status)
}
}

    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
          case "1":
            setPage("pageone");
            break;
          case "2":
            setPage("pagetwo");
            break;
          case "3":
            setPage("pagethree");
            break;
          case "4":
            setPage("pagefour");
            // alert("Ooops! Seems like you did not fill the form.");
            break;
          //   case "3":
          //     setPage("pagefive");
          //     break;
          default:
            setPage("1");
        }
      };
  return (
    <Box>
        <Stack spacing={4} >
        <Stack spacing={0.5} >

         <MultistepProgressBarFleet Pfive={pageFiveStatus} Pfour={pageFourStatus} Ptwo={pageTwoStatus} Pthree={pageThreeStatus} Pone={pageOneStatus} page={page} onPageNumberClick={nextPageNumber} />
         <Box width="100%" display="flex" justifyContent="space-between" >
         <Typography alignSelf="center"  variant='caption' >Personal Details</Typography>
         <Typography alignSelf="center"  variant='caption' >Service Details</Typography>
         <Typography alignSelf="center"  variant='caption' >Driving Licence </Typography>
         {/* <Typography alignSelf="center"  variant='caption' >Owner Details</Typography> */}
         {/* <Typography alignSelf="center"  variant='caption' >Insurance Details</Typography> */}


         </Box>
         </Stack>
         {
        {
          pageone: <PersonalFormFleet onSectionComplete={pageDone} onButtonClick={nextPage} />,
          pagetwo: <ServiceFormFleet onSectionComplete={pageDone} onButtonClick={nextPage} />,
          pagethree: <DrivingLicenseFormFleet onSectionComplete={pageDone} onButtonClick={nextPage} />,
          // pagefour: <OwnerDetailsFleet onSectionComplete={pageDone} onButtonClick={nextPage} />,
        //   pagefive: <InsuranceForm onSectionComplete={pageDone} onButtonClick={nextPage} />,
        }[page]
      }
      </Stack>
    </Box>
 
  )
}

export default FleetProgressionDriver