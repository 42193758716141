import { Box, Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import closeIcon from '../../../Assets/Table/Group 4336.svg'

function DriverFIlters({CloseModal,FilterColumn}) {

  return (
    <Box p={2}>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>

    <Box  width={'250px'}>
    <FormControlLabel onChange={(e) => FilterColumn(e.target.checked,"")}  control={<Checkbox  color="success" />} label="Verification pending" />
    </Box>

    <IconButton   sx={{
    '&:hover': {
      backgroundColor: 'transparent', // Remove hover effect by making background transparent
    },
  }} onClick={() => CloseModal(false)} aria-label="delete" size="large">
             <img src={closeIcon} alt="filter"/>
            </IconButton> 
    </Stack>
    
  </Box>
  )
}

export default DriverFIlters
