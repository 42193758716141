import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'

import React, { Fragment, useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField'
import { BONUS_TYPE, FARE_CONDITION, HOURLY_BOOKING, HOUR_DATA, MINUTES_DATA, NO_OF_SEATS } from '../../../FakeData/FakeData'
import InputField from '../../../Components/Forms/InputField'
import BonusFare from './BonusFare'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import { useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'
import NotifiProgress from '../../../Components/Forms/NotifiProgress'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import deleteIcon from '../../../../Assets/Forms/Vector.svg'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { toast } from 'react-toastify'


function AddMainFareForm() {
    const [submitHit,setSubmitHit] = useState(false)
    const [basicFare , setBasicFare] = useState('')
    const [maxDistance,setMaxDistance] = useState('')
    const [additionalCost,setAdditionalCost] = useState('')
    const [progressionDistance,setProgressionDistance] = useState('')
    const [isShowBonus , setIsShowBonus] = useState(false)
    const [isShowInsurance , setIsShowInsurance] = useState(false)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [bonusFareCount, setBonusFareCount] = useState(1); 
    const { currentBasicdata } = useSelector((state) => state.basic);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [advancedBookingCost,setAdvancedBookingCost] = useState('')
    const [minBookingTime , setMinBookingTime] = useState('')
    const [maxBookingTime , setMaxBookingTime] = useState('')
    const [selectedFreequency , setSelectedFreequency] = useState('')
    const [freequencyCost,setFreequencyCost] = useState('')
    const [isShowAdvanced,setIsShowAdvanced] = useState('')
    const [isShowHourly,setIsShowHouly] = useState('')
    const [hourlyBlockOne , setHourlyBlockone] = useState('')
    const [blockOneCost, setBlockOneCost] = useState('')
    const [hourlyBlockTwo , setHourlyBlockTwo] = useState('')
    const [blockTwoCost, setBlockTwoCost] = useState('')
    const [hourlyBlockThree , setHourlyBlockThree] = useState('')
    const [blockThreeCost, setBlockThreeCost] = useState('')
    const [hourlyBlockOverTime , setHourlyBlockOverTime] = useState('')
    const [blockOverTimeCost, setBlockOverTimeCost] = useState('')
    const [selectedAdults,setSelectedAdults ] = useState('')
    const [selectedPets,setSelectedPets ] = useState('')

    const [tripInsuranceId , setTripInsuranceId] = useState('')
    const [currentCat , setCurrentCat] = useState([])

    const [inputFields, setInputFields] = useState([
      { Value : '', Condition:'Greater',Type:'Amount',Fare:''}
    ]);


console.log(maxDistance,"<---max distanceessssssssssssssssssssssssssssssssss")
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
     const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

     const [write,setWrite] = useState()
  

     useEffect(()=>{
    
       
       let isWrite = checkPrivilage('Basic Fare','CREATE')
       setWrite(isWrite)
   
        },[])

        console.log(write,"<----------------writeeeee")



        function isHourlyBookingValid(hourlyBooking) {
          // Check if any of the fields in block1, block2, or block3 is empty, null, or 0
          const isBlockValid = (block) => block.Hour && block.Cost;
        
          // Check if all blocks are valid
          const areAllBlocksValid = isBlockValid(hourlyBooking.block1) &&
                                    isBlockValid(hourlyBooking.block2) &&
                                    isBlockValid(hourlyBooking.block3);
        
          // Return false if any block is not valid, otherwise return true
          return areAllBlocksValid; 
        }
        



     useEffect(()=>{

    
      ApiCall("GET", `/trip-service/v1/admin/insurance/ride/coverage/?countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
         //its done like this because in api you will recive only active  
          const results = data?.result?.[0] 
          
          // console.log(results,"<")
          
          setTripInsuranceId(results?._id) 
          
          const categoryData = data?.result?.[0]?.Cot

          console.log(categoryData,"<-------RQ")
          let filteredCot = categoryData?.map((eachItem)=>{
            return {
              cotID:eachItem?._id,
              cost:eachItem?.cost,
              isMandatory:eachItem?.isMandatory,
              IsEnabled:isShowInsurance,
              cotName:eachItem?.cotname

            }
          })
          setCurrentCat(filteredCot)
         
     } })



     },[isShowInsurance])

     



     const handleSelect = (label,data,index)=>{
          
        if(label === 'Minimum Advance Booking Time(min)'){
          setMinBookingTime(data?.name)
        } else if(label === 'Maximum Advance Booking Time(days)'){
          setMaxBookingTime(data?.name)
        } else if(label === 'Cost Change Frequency(hr)'){
          setSelectedFreequency(data?.name)
        } else if(label === 'Hourly Block 1'){
          setHourlyBlockone(data?.name)
        }else if(label === 'Hourly Block 2'){
          setHourlyBlockTwo(data?.name)
        }else if(label === 'Hourly Block 3'){
          setHourlyBlockThree(data?.name)
        }else if(label === 'Hourly Overtime'){
          setHourlyBlockOverTime(data?.name)
        } else if(label === 'Type'){
          handleInputChange(index,data?.name,'Type')
           }
     
    
      }

   


     
      
      

     
const handleFormSubmit =()=>{
  setSubmitHit(true)
 let filteredBonus = inputFields.map((each)=>{
  return{...each,IsEnabled:isShowBonus}
 })

  let data={

    // valueServiceId:currentBasicdata?.valueServiceId,
    rideCategoryId:currentBasicdata?.rideCategoryId,
    paymentSplitId:currentBasicdata?.paymentSplitId,
    baseFare:basicFare,
    maxDistanceFare:maxDistance,
    aditionalCost:additionalCost,
    progressiveDistance:progressionDistance,
    bonus: filteredBonus,
    advanceBookingCharge:{
      bookingCost:advancedBookingCost,
      minBookingTime:minBookingTime,
      maxBookingTime:parseInt(maxBookingTime, 10) * 60,
      changeFrequency:selectedFreequency,
      changeFare:freequencyCost,
      IsEnabled:isShowAdvanced,
     },
     hourlyBooking:{
      block1:{
        Hour:hourlyBlockOne,
        Cost:blockOneCost
      },
      block2:{
        Hour:hourlyBlockTwo,
        Cost:blockTwoCost
            },
      block3:{
        Hour:hourlyBlockThree,
        Cost:blockThreeCost
      },
      overtime:{
        Hour:hourlyBlockOverTime,
        Cost:blockOverTimeCost
      },
      IsEnabled:isShowHourly
     },
     travellerSplit: [
      {
        travelers: "Adult",
        Count: selectedAdults
      },
      {
        travelers: "Pets",
        Count: selectedPets
      }
    ],

    tripInsuranceData:{
      tripInsuranceID:tripInsuranceId, 
      CotData:currentCat,

    },
  }
  const isAllValid = isHourlyBookingValid(data?.hourlyBooking)
  if(isShowHourly && isAllValid || !isShowHourly && !isAllValid){

    if(isShowBonus && filteredBonus[0]?.Value && filteredBonus[0]?.Fare){

      ApiCall("POST", "/trip-service/v1/admin/ride-category/add/fare",data).then((data)=>{
        if(data?.status === 'success'){
          setOnSuccess(true)
    
          // setOnSuccess((i) => !i )
          // setOnApiSuccess("Succesfully created service")
          
    
        }
    })
    .catch((error) => {
      setOnFailed((i) => !i)
        
      if(error.message){
        setOnApiFailed(error.message)
      }else{
        setOnApiFailed("Something went wrong.!")
      }
      console.error(error, "PUT API ERROR");
    
    
    });
    
    }else if(!isShowBonus && !filteredBonus[0]?.Value && !filteredBonus[0]?.Fare){
      ApiCall("POST", "/trip-service/v1/admin/ride-category/add/fare",data).then((data)=>{
        if(data?.status === 'success'){
          setOnSuccess(true)
    
          // setOnSuccess((i) => !i )
          // setOnApiSuccess("Succesfully created service")
          
    
        }
    })
    .catch((error) => {
      setOnFailed((i) => !i)
        
      if(error.message){
        setOnApiFailed(error.message)
      }else{
        setOnApiFailed("Something went wrong.!")
      }
      console.error(error, "PUT API ERROR");
    
    
    });
    }else{
       toast.error("Fill all mandatory fields")
    }

  }else{
   toast.error("Fill all mandatory fields A")
  }


   


  console.log(data,"<====SUBMITTED|-|DATAA")

}


const handleCostChange = (dataName, index) => {
  setCurrentCat((prevCat) => {
    const updatedCat = [...prevCat]; // Create a copy of the currentCat array
    if (index >= 0 && index < updatedCat.length) {
      // Check if the index is valid
      updatedCat[index] = {
        ...updatedCat[index],
        cost: dataName, // Update the 'cost' property with the new value
      };
    }
    return updatedCat;
  });
};




  //DYNAMIC FORM HANDLING
  
  const handleInputChange = (index, event,name) => {
    const values = [...inputFields];
    if (name === "Fare") {
      values[index].Fare = event.target.value;
    } else if(name === 'Bonus Amount') {
      values[index].Value = event.target.value;
    }else if(name === 'Type') {
      values[index].Type = event? event :null;
    }

    setInputFields(values);
  };

  const handleAddFields = () => {
    if(inputFields?.length < 4 ){
      const values = [...inputFields];
      values.push({Value : '', Condition:'Greater',Type:'Amount',Fare:''});
      setInputFields(values);
    }

  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };





  return (
<Box>
<Stack spacing={3} >


{/* TOP FORM */}

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'20%'}>
<InputField Type={'number'} isError={submitHit && basicFare?.length < 1 } ErrNote={""} InChange={(e) => setBasicFare(e.target.value)}   sideLabel={"Base Fare"} placeHolder={"Base Fare"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputField Type={'number'} isError={submitHit && maxDistance?.length < 1 } ErrNote={""} InChange={(e) => setMaxDistance(e.target.value)}   sideLabel={"Minimum Distance for Base Fare (Km)"} placeHolder={"Minimum Distance for Base Fare"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputField Type={'number'} isError={submitHit && progressionDistance?.length < 1 } ErrNote={""} InChange={(e) => setProgressionDistance(e.target.value)}   sideLabel={"Progressing Distance Threshold"} placeHolder={"Progressing Distance Threshold"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputField Type={'number'} isError={submitHit && additionalCost?.length < 1 } ErrNote={""} InChange={(e) => setAdditionalCost(e.target.value)}   sideLabel={"Progressing Distance Fare"} placeHolder={"Progressing Distance Fare"} />
    </Box>
</Stack>


{/* BONUS FORM */}
<Box>

<Stack spacing={1} alignItems={'center'} direction={'row'}>

<FormControlLabel onChange={(e) => setIsShowBonus(e.target.checked)}  control={<Checkbox  color="success" />} label="Bonus" />
{
 isShowBonus && <Button size='small'
onClick={() => handleAddFields()}
 sx={{
 
    textTransform:"none",
     height:"24px" ,
      color:'black',
      borderColor:"ButtonShadow",
      borderRadius:"4px",
      backgroundColor:'white',
      }}  variant="outlined">Add Another</Button>
}

  
</Stack>
</Box>
{/* <BonusFare/> */}

{ isShowBonus && inputFields.map((inputField, index) => (

<Fragment key={`${inputField}~${index}`}>

<Box bgcolor={'#F9F9F9'} height={isMatch ? '500px' :'125px'} width={isMatch ? '50%':'100%'} >
    <Stack padding={2} justifyContent={'center'} alignItems={'center'} width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'60%'}>

<Typography variant='p'><strong>Total Ride Fare</strong></Typography>
    </Box>
    <Box width={isMatch ? "100%" : "40%"} >
    <SelectField  isError={''} onSelecting={handleSelect} Data={FARE_CONDITION} sideLabel={'Condition'} placeHolder={'choose'} />
    </Box>

    <Box width={isMatch ? "100%" : "40%"} >
    <InputFieldUpdate Type={'number'} Value={inputField.Fare}  isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Fare')}  sideLabel={"Fare "} placeHolder={"Fare"} />
    </Box>

    <Box width={isMatch ? "100%" : "40%"} >
    <SelectFieldUpdate indexes={index} currentSelect={inputField?.Type || 'select'} isError={''} onSelecting={handleSelect} Data={BONUS_TYPE} sideLabel={'Type'} placeHolder={'choose'} />
    </Box>
    
    <Box width={isMatch ? "100%" : "50%"} >
    <InputFieldUpdate Type={'number'} Value={inputField.Value}  isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Bonus Amount')}   sideLabel={"Bonus Amount"} placeHolder={"Bonus Amount"} />
    </Box>
    <Box  width={isMatch ? "100%" : "50%"} >
      { index !== 0  &&   <IconButton  onClick={() => handleRemoveFields(index)}> <img src={deleteIcon} alt='delete'/> </IconButton> }
   </Box>

</Stack>

 </Box>

</Fragment>


))}





{/* <BonusFare showBonus={isShowBonus} handleFareChange={handleBonusFareChange} isDelete={false} />
{[...Array(bonusFareCount - 1)].map((_, index) => (
          <BonusFare showBonus={isShowBonus} handleFareChange={handleBonusFareChange} Indexx={bonusFareCount} isDelete={true} onDelete={handleRemoveAnother} key={index} />
        ))} */}
{/* <Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >


</Stack> */}


{/* ADVANCED BOOKING CHARGE */}

<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowAdvanced(e.target.checked)}  control={<Checkbox  color="success" />} label="Advance Booking Charge" />

</Stack>
</Box>

{isShowAdvanced ?
<>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
<InputField Type={'number'} isError={submitHit && advancedBookingCost?.length < 1 } ErrNote={""} InChange={(e) => setAdvancedBookingCost(e.target.value)}   sideLabel={"Advance Booking Cost "} placeHolder={"choose"} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField isError={''} onSelecting={handleSelect} Data={MINUTES_DATA} sideLabel={'Minimum Advance Booking Time(min)'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Maximum Advance Booking Time(days)'} placeHolder={'choose'} />
    </Box>

</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'21%'}>
<SelectField isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Cost Change Frequency(hr)'} placeHolder={'choose'} />
</Box>
<Box width={'8.2%'}>
<InputField Type={'number'} isError={submitHit && freequencyCost?.length < 1 } ErrNote={""} InChange={(e) => setFreequencyCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>
</Stack>
</> :

<>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3}>

<Box width={'50%'}>
<InputField disable={true} isError={submitHit && advancedBookingCost?.length < 1 } ErrNote={""} InChange={(e) => setAdvancedBookingCost(e.target.value)}   sideLabel={"Advance Booking Cost"} placeHolder={"choose"} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Minimum Advance Booking Time(min) '} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Maximum Advance Booking Time(days)'} placeHolder={'choose'} />
    </Box>

</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'22.5%'}>
<SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Cost Change Frequency(hr)'} placeHolder={'choose'} />
</Box>
<Box width={'8%'}>
<InputField disable={true} isError={submitHit && freequencyCost?.length < 1 } ErrNote={""} InChange={(e) => setFreequencyCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>

</Stack>

</>



}








{/* HOURLY BOOKING CHARGE */}


<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowHouly(e.target.checked)}  control={<Checkbox  color="success" />} label="Hourly Booking Charge" />

</Stack>
</Box>

{isShowHourly ? (

<>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >


<Box width={'100%'}>
<SelectField isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 1'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField Type={'number'} isError={submitHit && blockOneCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOneCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>



<Box width={'100%'}>
<SelectField isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 2'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField Type={'number'} isError={submitHit && blockTwoCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockTwoCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>



<Box width={'100%'}>
<SelectField  isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 3'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField Type={'number'} isError={submitHit && blockThreeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockThreeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>
</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={4} >
<Box width={'21%'}>
<SelectField isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Hourly Overtime'} placeHolder={'choose'} />
</Box>
<Box width={'8.2%'}>
<InputField Type={'number'} isError={submitHit && blockOverTimeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOverTimeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>

</Stack>
</>

):(

  <>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >


<Box width={'100%'}>
<SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 1'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField  disable={true} isError={submitHit && blockOneCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOneCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>



<Box width={'100%'}>
<SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Block 2'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField  disable={true} isError={submitHit && blockTwoCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockTwoCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>



<Box width={'100%'}>
<SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Block 3'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputField  disable={true} isError={submitHit && blockThreeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockThreeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>
</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={4} >
<Box width={'21%'}>
<SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Overtime'} placeHolder={'choose'} />
</Box>
<Box width={'8.2%'}>
<InputField  disable={true} isError={submitHit && blockOverTimeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOverTimeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>

</Stack>
</>
  
)}







{/*TRAVELERS SPLIT*/}

<Box >
<Stack alignItems={'center'} direction={'row'}>

{/* <FormControlLabel onChange={(e) => setIsShowBonus(e.target.checked)}  control={<Checkbox  color="success" />} label="Travelers Split" /> */}

</Stack>
</Box>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'15.5%'}>
<InputField Type={'number'} isError={submitHit && selectedAdults?.length < 1 } ErrNote={""} InChange={(e) => setSelectedAdults(e.target.value)}   sideLabel={"Maximum no of Adults"} placeHolder={"No of Adults"} />
    </Box>
    <Box width={isMatch ? "50%" : "15.5%"} >
    <InputField Type={'number'} isError={submitHit && selectedPets?.length < 1 } ErrNote={""} InChange={(e) => setSelectedPets(e.target.value)}   sideLabel={"Maximum no of pets"} placeHolder={"No of pets"} />
    </Box>

</Stack>

{/*TRIP INSURANCE DETAILS */}



{/* un comment code below inorder to show trip insurance part */}

{/* <Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowInsurance(e.target.checked)}  control={<Checkbox  color="success" />} label="Trip Insurance" />
</Stack>
</Box> */}

{/* {currentCat?.map((eachItem,index)=>(



<>

<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch?'100%':'22.5%'}>
<Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Cot Name</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={eachItem?.cotName}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>

    <Box width={isMatch ? "100%" : "8%"} >

    <InputFieldUpdate Type={'number'} disable={isShowInsurance ? false :true} Value={eachItem?.cost}  isError={false} ErrNote={""} InChange={(e) => handleCostChange(e.target.value,index)}   sideLabel={"Cost"} placeHolder={"Cost"} />

    </Box>


</Stack>

</>
))} */}



{/* <NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} /> */}
<SnackBars submitStatus={submitHit} successNote={'Successfully created'} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />

</Stack>

</Box>
  )
}

export default AddMainFareForm
