import React, { useEffect, useState } from "react";
import io from "socket.io-client";

let socket;

function SocketConnection({ DriverData }) {
  useEffect(() => {
    socket = io("http://192.168.29.72:7000", {
      query: { socketId: "-9bz_gkn9aAsAMQQAAAJ" },
    });

    socket.on("connect", () => {
      console.log(socket.id, "<---");
    });

    socket.on("driver-move", (data) => {
      if (data) {
        DriverData(data?.location?.coordinates);
      }
    });
  });

  return <div></div>;
}

export default SocketConnection;
