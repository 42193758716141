import { Box, Typography } from '@mui/material'
import React from 'react'
import TableHead from '../../Components/Table/TableHead'
import Card from './Card'
import carImage from '../../../Assets/Service-Mangement/car (2) 1.svg'
import truckIcon from '../../../Assets/Service-Mangement/delivery-truck 1.svg'
import sharedIcon from '../../../Assets/Service-Mangement/car-sharing 1.svg'




const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Service Management
      </Typography>,
 <Typography key="3" color="text.primary">
      Service Management
      </Typography>,
 

  ]
function ServiceManagement() {
  return (

    <Box>
        

    <TableHead title={'Service Management'} BreadList={breadcrumbs} />
    <Box display={'flex'} justifyContent={'space-between'} >
       <Card cardIcon={carImage} cardTitle={'Drive'} /> 
       <Card cardIcon={truckIcon} cardTitle={'Deliver'} /> 
       <Card cardIcon={sharedIcon} cardTitle={'Carpool'} /> 
    </Box>

    </Box>
    


  )
}

export default ServiceManagement
