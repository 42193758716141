import React, { useEffect, useState } from 'react'
import InputField from '../../../Components/Forms/InputField'
import { Box, Button, Stack, createTheme, useMediaQuery } from '@mui/material'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import { useSelector } from 'react-redux'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import ApiCall from '../../../../Helper/ApiCalls'
import { useNavigate } from 'react-router-dom'
import { checkPrivilage } from '../../../../Sessions/Permisssions'

function CreateOtherChargeForm() {
    const [chargeName , setChargeName] = useState('')
    const [graceTime , setGraceTime] = useState('')
    const [waitTimeCap,setWaitTimeCap] = useState('')
    const [waitTimeCost,setWaitTimeCost] = useState('')
    const [remarks,setRemarks] = useState('')

    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [isWaiting,setIsWaiting] = useState(false)
    const {currentUserPersonaldata} = useSelector((state) => state.userPersonal)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Other Charges','UPDATE')
      setEdit(isEdit)
      
  
       },[])
    



    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


      const navigate  = useNavigate()

      const handleDiscard =()=>{
        navigate(-1)
      }
  


      const handleFormSubmit =()=>{
        setSubmitHit(true)

        let sendData = {
            remarks:remarks,
            value:waitTimeCost,
            chargeId:currentUserPersonaldata?._id,
            isWaitingCharge:isWaiting,
            countryId:1,
            name:chargeName
      
    
          }

        let sendWdata = {
          remarks:remarks,
          slabCharge:waitTimeCost,
          chargeId:currentUserPersonaldata?._id,
          isWaitingCharge:isWaiting,
          countryId:CountryID,
          name:chargeName,
          graceTime:graceTime,
          waitTimeSlab:waitTimeCap
        }
    
   
    
          ApiCall("PATCH", "/trip-service/v1/admin/fare-charges/other-charge", isWaiting ? sendWdata : sendData,'BODY').then((data)=>{
            if(data?.status === 'success'){
              setOnSuccess(true);
              setTimeout(() => navigate(-1),1000);
            }
        })
        .catch((error) => {
          setOnFailed((i) => !i)
            
          if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
          console.error(error, "PUT API ERROR");

        });
     }


    useEffect(()=>{

        if(currentUserPersonaldata){
          setIsWaiting(currentUserPersonaldata?.isWaiting)
            console.log(currentUserPersonaldata,"<------reduxx dataa")
           setChargeName(currentUserPersonaldata?.allData?.Name || 'Waiting Charge')
           setRemarks(currentUserPersonaldata?.allData?.Remarks)
           setWaitTimeCost(currentUserPersonaldata?.allData?.SlabCharge || currentUserPersonaldata?.allData?.Value)
          
           setGraceTime(currentUserPersonaldata?.allData?.GraceTime)
           setWaitTimeCap(currentUserPersonaldata?.allData?.WaitTimeSlab)
        }

    },[currentUserPersonaldata])

  return (
    <Box>
     
    <Stack spacing={3} >
    {/* form   */}
    <Stack spacing={2}>
    <Stack direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'43%'}>
        <InputFieldUpdate Value={chargeName} isError={false} ErrNote={""} InChange={(e) => setChargeName(e.target.value)}   sideLabel={"Charge Name"} placeHolder={"Charge Name"} />
        </Box>
        <Box width={'43%'}>
       { isWaiting && <InputFieldUpdate Type={'number'} Value={graceTime} isError={false} ErrNote={""} InChange={(e) => setGraceTime(e.target.value)}   sideLabel={"Grace Time (min)"} placeHolder={"Grace Time"} />}
        </Box>
        <Box width={'43%'}>
        {isWaiting && <InputFieldUpdate Type={'number'} Value={waitTimeCap} isError={false} ErrNote={""} InChange={(e) => setWaitTimeCap(e.target.value)}   sideLabel={"Wait Time Cap (min)"} placeHolder={"Wait Time Cap"} />}
        </Box>
    </Stack>
    <Stack direction={isMatch ? 'column':'row'} spacing={3} >

        <Box width={'49%'}>
        <InputFieldUpdate Type={'number'} Value={waitTimeCost} isError={false} ErrNote={""} InChange={(e) => setWaitTimeCost(e.target.value)}   sideLabel={"Cost"} placeHolder={"Cost"} />
        </Box>
        <Box width={'100%'}>
        <InputFieldUpdate notRequired={true}  Value={remarks} isError={false} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
        </Box>
    </Stack>

    <Stack direction={isMatch ? 'column':'row'} spacing={3} >
 
   
    </Stack>

    </Stack>

    <SnackBars submitStatus={submitHit} successNote={'Successfully Updated'} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>
    </Stack>

    </Box>
  )
}

export default CreateOtherChargeForm
