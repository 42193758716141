import { Box } from "@mui/material";
import TableNav from "../../Components/TableSearchExport/TableNav";
import mockData from "../../FakeData/MOCK_DATA.json";
import DataTable from "../../Components/Table/DataTable";
import { useState } from "react";


const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },

  {
    id: "rider",
    label: "Rider",
    minWidth: 100,
    align: "left",
    sort: true,
  },
  {
    id: "driver",
    label: "Driver",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "address",
    label: "Pick / Drop Address",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "ride_fare",
    label: "Ride Fare",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Completed",
      onFalse: "Cancelled",
    },
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];

function Booking() {
  const [rows, setRows] = useState(mockData);
  const [order, setOrder] = useState("ASC");
  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };

  return (

    <Box>
      
    </Box>
    // <Box>
    // //   <TableNav title={"Booking"} />
    // //   <Box sx={{ width: "100%", height: "100%" }} pt={3}>
    //     // <DataTable
    //     //   columns={columns}
    //     //   tableData={rows}
    //     //   columnSorting={columnSortingFn}
    //     // />
    // //   </Box>
    // </Box>
  );
}

export default Booking;
