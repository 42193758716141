import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import SnackBars from '../../../Components/Forms/SnackBars'
import { Box, Grid, Stack, createTheme, useMediaQuery, useStepContext } from '@mui/material'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import { App_Type, BONUS_TYPE, ISSUE_STATUS, PH_CODE, PRIORITY_LIST, USER_TYPES, VehicleCategory } from '../../../FakeData/FakeData'
import { useTitle } from '../../../../Hooks/UseTitle'
import ApiCall from '../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import { baseUrl } from '../../../RequestMethord'
import axios from 'axios'
import { checkPrivilage } from '../../../../Sessions/Permisssions'



export function removeSpaceBetweenWords(inputString) {
  // Split the input string by space into an array of words
  var words = inputString.split(' ');

  // Join the words without any space to form a single string
  var resultString = words.join('');

  return resultString;
}







function CreateTicketsForm() {
  useTitle("Create Tickets - Geolah");
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)

    const [requesterName , setRequesterName] = useState('') 
    const [selectedStatus , setSelectedStatus] = useState('') 
    const [phoneNumber , setPhoneNumber] = useState('')
    const [selectedPriority , setSelectedPriority] = useState('') 
    const [selectedCode , setSelectedCode] = useState('')
    const [selectedUserType , setSelectedUserType] = useState('')
    const [selectedAppType , setSelectedAppType] = useState('')
    const [assign,setAssign] = useState('')
    const [remarks , setRemarks] = useState('')
    const [description,setDescription] = useState('')
    const[empData,setEmpData] = useState([])
    const[issueData,setIssueData] = useState([])
    const [selectedIssue , setSelectedIssue] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
  

    useEffect(()=>{   
      let isWrite = checkPrivilage('Ticket','CREATE')
      setWrite(isWrite)
  
       },[])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));





// api call
useEffect(()=>{

  const apiUrl = `${baseUrl}/user-service/v1/general/all/issues`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      // ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  axios.get(apiUrl, config).then(response => {

    if(response?.data?.result){
      const issueData =response?.data?.result
      console.log(issueData,"<---the isuueeeeeee")
      let filteredData = issueData?.map((eachData)=>{
            return{id:eachData?.ID,name:eachData?.Name}        
      }) 

      setIssueData(filteredData)
  
    }
    
      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });



  ApiCall("GET", `/user-service/v1/admin/employee/get-all/?countryId=${CountryID}&&limit=100000`, null, "uui").then((data)=>{
    if(data?.result){

    const empData = data?.result
 
 const filterdData = empData?.map((eachItem)=>{
  return {id:eachItem?.ID,name:eachItem?.FirstName}
 })

 setEmpData(filterdData)



 } })
},[CountryID])






    const handleFormSubmit =()=>{
        setSubmitHit(true)
       
    let sendData = {
      phoneNumber: selectedCode+phoneNumber,
      userTypeId: selectedUserType === 'User' ? 1 : selectedUserType === 'Driver' ? 2:null ,
      priorityStatus: selectedPriority,
      // status: selectedStatus,
      appType:removeSpaceBetweenWords(selectedAppType),
      assignee:parseInt(assign),
      issueID:parseInt(selectedIssue),
      description: description,
      CountryID: CountryID,
      remark: remarks,
    }
        
        ApiCall("POST", "/user-service/v1/admin/driver/create/tickets", sendData,).then((data)=>{
            if(data.result === 'success'){
          
              setOnSuccess((i) => !i )
              setOnApiSuccess("Succesfully created")
              
        
            }
        })
        .catch((error) => {
           setOnFailed((i) => !i)
          
            if(error.message){
              setOnApiFailed(error.message)
              console.log(error , "<----errrrrrr")
            }else{
              setOnApiFailed("Something went wrong.!")
            }
        
        
        });
       
       
       
        
       
       
       }


       const handleSelect = (label,data,index)=>{
          
        if(label === 'Priority'){
          setSelectedPriority(data?.name)
         }else if(label === 'Status'){
            setSelectedStatus(data?.name)
         }else if(label === 'Assignee'){
            setAssign(data?.id)
         }else if(label === 'User Type'){
          setSelectedUserType(data?.name)
         }else if(label === 'App Type'){
         setSelectedAppType(data?.name)
         }else if(label === 'Issue'){
         setSelectedIssue(data?.id)
         }else if(label === 'Code'){
         setSelectedCode(data?.name)
  }
      
               
      }




  return (
    <Box>
     
    <Stack spacing={3} >
    {/* form   */}
    <Stack spacing={2}>


{/* FIRST ROW */}


<Stack direction={'row'} spacing={3}>

<Box width={'25%'}>
<SelectField placeHolder={'choose'} isError={false} onSelecting={handleSelect} sideLabel={"App Type"} Data={App_Type} />
        </Box>
        <Box width={'25%'}>
<SelectField placeHolder={'choose'} isError={false} onSelecting={handleSelect} sideLabel={"Issue"} Data={issueData} />
        </Box>
        <Box width={isMatch ? "100%" : "25%"} >
        <SelectField   isError={''} onSelecting={handleSelect} Data={PRIORITY_LIST} sideLabel={'Priority'} placeHolder={'choose'} />
        </Box>  
        <Box width={isMatch ? "100%" : "25%"}>
<SelectField   isError={''} onSelecting={handleSelect} Data={empData} sideLabel={'Assignee'} placeHolder={'choose'} />
</Box>
        {/* <Box width={isMatch ? "100%" : "25%"} >
        <SelectField   isError={''} onSelecting={handleSelect} Data={ISSUE_STATUS} sideLabel={'Status'} placeHolder={'choose'} />
        </Box>   */}

</Stack>


{/* SECOND ROW */}


<Stack direction={'row'} spacing={3}>


<Box width={isMatch ? '100%':'24.4%'}>
<Grid container>
<Grid item xs={3.5} >
<SelectField isError={false} onSelecting={handleSelect} Data={PH_CODE} sideLabel={'Code'} placeHolder={'+65'} />
</Grid>
<Grid item xs={0.4} />

<Grid item xs={8} >
<InputField isError={''} ErrNote={""} InChange={(e) => setPhoneNumber(e.target.value)}   sideLabel={"Phone"} placeHolder={"Phone"} />
</Grid>
</Grid>
</Box>

<Box  width={'24%'}>
    <SelectField isError={false} onSelecting={handleSelect} sideLabel={"User Type"} Data={USER_TYPES} placeHolder={'choose'} />
    </Box>


 <Box width={'50%'}>
   <InputField isError={false} ErrNote={""} InChange={(e) => setDescription(e.target.value)}   sideLabel={"Description"} placeHolder={"Description"} />
 </Box>

</Stack>


{/* LAST ROW */}

<Stack direction={'row'} spacing={3}>
<Box width={'50%'}>
        <InputField isError={false} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
        </Box>



{/* <Box width={'50%'}>
        <InputField isError={false} ErrNote={""} InChange={(e) => setDescription(e.target.value)}   sideLabel={"Description"} placeHolder={"Description"} />
        </Box> */}

</Stack>





        </Stack>
          {/* <AlertPopUp/> */}
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />
    </Stack>
 </Box>
 
  )
}

export default CreateTicketsForm
