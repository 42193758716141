import { Box,Typography } from '@mui/material'
import React, { useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import ToggleNav from '../../Components/Forms/ToggleNav'
import Tabs from '../../Components/Tabs/Tabs'
import Economy from './Economy/Economy'


const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Service Management
      </Typography>,
 <Typography key="3" color="text.primary">
      Service Management
      </Typography>,
 

  ]


function Drive() {
  const [tab , setTab] = useState('left')
  // const [rows, setRows] = useState(employeeData);
  // const [order, setOrder] = useState("ASC");
  
  const TabItems = [
    
    { name: "Economy", value: "Economy" },
    {
      name: "Economy XL",
      value: "Economy XL",
    },
    {
      name: "Premium XL",
      value: "Premium XL",
    },
    {
      name: "Premium",
      value: "Premium",
    },
    {
      name: "Taxi",
      value: "Taxi",
    },
    {
      name: "Tickets",
      value: "Tickets",
    },
  ];

  const Components = [
    {
      page: <Economy />,
      value: "Economy",
    },
  
  ];



  const tabRender = (alignment)=>{
    setTab(alignment)
  }

  return (
    <Box>
  <TableHead title={'Drive'} BreadList={breadcrumbs} />
  <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100vh' >
  <ToggleNav lName={'Car/Taxi'} rName={'Bus /Coach'} toggleChange={tabRender} />
  <Box  sx={{ width: "100%", height: "100%"}} >
  {
        tab === 'left' ?  
     <Tabs List={TabItems} component={Components}  />:  
      null


    }

  </Box>

  </Box>
    </Box>
  )
}

export default Drive
