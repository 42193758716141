import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'

import React, { useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField'
import { NO_OF_SEATS } from '../../../FakeData/FakeData'
import { useDispatch, useSelector } from 'react-redux'
import { insertBasicDataAction } from '../../../Redux/Actions/BasicDataActions'
import ApiCall from '../../../../Helper/ApiCalls'

function AddBasicFareForm() {
  // STATES AND VARIABLES
    const [submitHit,setSubmitHit] = useState(false)
    const [selectedService , setSelectedService] = useState('')
    const [selectedSplit , setSelectedSplit] = useState('')
    const [selectedCategory , setSelectedCategory] = useState('')
    const [serviceData , setServiceData] = useState([])
    const [categoryData , setCategoryData] = useState([])
    const [paymentSplitData , setPaymentSplitData] = useState([])
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
     const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
     const dispatch = useDispatch()
   
   

//RENDERS AND API CALLS

useEffect(()=>{
//GETTING SERVICE DATA  
ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
if(data?.result){
  const serviceData = data?.result;
  let filterdService = serviceData?.map((eachItem)=>{
    return {id:eachItem?._id , name:eachItem?.ServiceName,catData:eachItem?.RideCategoryIDS}
  })
  setServiceData(filterdService)
} })

// GETIING CATEGORYDATA
// ApiCall("GET", "/trip-service/v1/admin/ride-category", null, "uui").then((data)=>{
//   if(data?.result){
//     const serviceData = data?.result;
//     let filterdService = serviceData?.map((eachItem)=>{
//       return {id:eachItem?._id , name:eachItem?.Name}
//     })
//     setCategoryData(filterdService)
//   } })

 //GETTING PAYMENT DATA 

 ApiCall("GET", `/trip-service/v1/admin/ride-category/all/split/?countryId=${CountryID}`, null, "uui").then((data)=>{
  if(data?.result){
  const paymentData = data?.result;
  let filterdService = paymentData?.map((eachItem)=>{
    return {id:eachItem?._id , name:eachItem?.Name}
  })

  setPaymentSplitData(filterdService)


  } })



},[])



useEffect(()=>{

  let Items = {
    valueServiceId:selectedService,
    rideCategoryId:selectedCategory,
    paymentSplitId:selectedSplit,
   }

   dispatch(insertBasicDataAction(Items));


},[selectedService,selectedCategory,selectedSplit])







// FUNCTIONS


     const handleSelect = (label,data)=>{
          
        if(label === 'Select Service'){


            setSelectedService(data?.id)
            let RideCat = data?.catData;
            console.log(RideCat , "<-- cat det-t-a-i-l-s")
            
            let filterdService = RideCat?.map((eachItem)=>{
              if(!eachItem?.RideCategoryFareID){
                return {id:eachItem?._id , name:eachItem?.Name}
              }else{
                console.log("its in elseee")
                return {id:1000,name:"No data"}
              }
           
            })

            
            setCategoryData(filterdService)

        }else if(label === 'Select Ride Category'){
             setSelectedCategory(data?.id)
        }else if(label === 'Select Payment Split'){
            setSelectedSplit(data?.id)
        }
     
    
      }

      console.log(categoryData , " <---O-U-T-S-I-D-E")


  return (
<Box>
<Stack spacing={3} >

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
<SelectField isError={ submitHit && selectedService === undefined || selectedService === ''} onSelecting={handleSelect} Data={serviceData} sideLabel={'Select Service'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField isError={ submitHit && selectedCategory === undefined || selectedCategory === ''} onSelecting={handleSelect} Data={categoryData} sideLabel={'Select Ride Category'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField isError={ submitHit && selectedSplit === undefined || selectedSplit === ''} onSelecting={handleSelect} Data={paymentSplitData} sideLabel={'Select Payment Split'} placeHolder={'choose'} />
    </Box>

</Stack>






</Stack>

</Box>
  )
}

export default AddBasicFareForm
