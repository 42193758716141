import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPrivilage } from '../../../Sessions/Permisssions';
import ApiCall from '../../../Helper/ApiCalls';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import dayjs from 'dayjs';
import TransferFilter from './TransferFilter';
import { convertToCurrentCountryTime, formatDates } from '../../Components/MultiFunctions/MultiFunctions';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "transactionId",
      label: "Transaction Id",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "senderType",
      label: "Sender Type",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "reciverType",
      label: "Receiver Type",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "senderName",
      label: "Sender Name",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "reciverName",
      label: "Receiver Name",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    
    
    {
      id: "DateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
      
    },
  
    {
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "type",
      label: "Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "isOnline",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: "Online",
        onFalse: "Offline",
      },
    },   
  ];

function TransferDetails() {
    const [rows, setRows] = useState();
    const [order, setOrder] = useState("ASC")
    const [success, setSuccess] = useState()
    const [tab , setTab] = useState('left')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [tableData, setTableData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [transferStatus,setTransferStatus] = useState('')
    const [senderType,setSenderType] = useState('')
    const [receiverType,setReceiverType] = useState('')
    const navigate = useNavigate()
    const [any,setAny] = useState()
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    const [searchPage,setSearchPage] = useState(0)


    useEffect(()=>{

        let isWrite = checkPrivilage('Payments','CREATE')
        setWrite(isWrite)
       let isEdit = checkPrivilage('Payments','UPDATE')
         setEdit(isEdit)
       
         let isDelete = checkPrivilage('Payments','DELETE')
         setDeletePer(isDelete)
         },[])


         useEffect(()=>{
          if(searchInput?.length > 0){
            setSearchPage(1)
          }else{
            setSearchPage(0)
          }
          const ulteredPage = searchPage ? searchPage :currentPage;
          const currentLimit = rowsPerPage
          const currentOffset = (ulteredPage-1) * rowsPerPage
            ApiCall("GET", `/pay-service/v1/admin/transfer/all/details/?countryId=${CountryID}&&limit=${currentLimit}&&offset=${currentOffset}${searchInput && `&&search=${searchInput}`}${startDate && `&&fromDate=${startDate}`}${endDate && `&&toDate=${endDate}`}${receiverType && `&&reciverType=${receiverType}`}${senderType && `&&senderType=${senderType}`}${transferStatus && `&&transferStatus=${transferStatus}`}`, null, "uui").then((data)=>{
              if(data?.result){
               console.log(data?.result,"<---splittt detailssssssssss")
                const paymentData = data?.result?.transfers
                setTotalRows(data?.result?.totalCount)
      
        
                let filteredData = paymentData?.map((eachItem,index)=>{
                  let dateString = eachItem?.CreatedAt
                  let testT = convertToCurrentCountryTime(dateString)
                  const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
                  const currentStartTime  =  dateString?.slice(11, 16);
                //  let currentDate = eachItem?.Date
                //  const formattedDate = dayjs(currentDate).format('YYYY-MM-DD');
                //  const dayOfWeek = dayjs(currentDate).format('dddd')
                //  console.log(dayOfWeek , ",0----speciall dateeeee")
                // const rideFares = parseFloat(eachItem?.PriorityStickerDeduction)
                  let items = {
        
                    id:index+1,
                    _id:eachItem._id,
                    transactionId:eachItem?.TransactionID,
                    senderType:eachItem?.SenderUserType,
                    DateTime:testT,
                    reciverType:eachItem?.ReceiverUserType,
                    type:eachItem?.TripInternalTransfer_ID?eachItem?.TransferType:"cashRide internalTransfer",
                    amount:eachItem?.Amount,
                    isOnline:eachItem?.InternalTransferHistory_TransferStatus,
                    senderName:eachItem?.senderName,
                    reciverName:eachItem?.ReceiverUserType === 'Geolah' && 'N/A' || eachItem?.reciverName

                    // driversSplit:`${eachItem?.Splits?.[1]?.percentage}%`,
                    // geolahSplit:`${eachItem?.Splits?.[0]?.percentage}%`,
                    // prioritySticker:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
                   }
        
                   return items
        
                })
        
                setRows(filteredData)
                setTableData(filteredData);
           
           
           } })
          },[success,currentPage,searchInput,startDate,endDate,transferStatus,senderType,receiverType])



          // useEffect(()=>{
          //   setRows(
          //     tableData.filter((item) =>
          //     item?.splitName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          //     item?.driversSplit?.toLowerCase().includes(searchInput.toLowerCase()) ||
          //     item?.geolahSplit?.toLowerCase().includes(searchInput.toLowerCase()) ||
          //     item?.prioritySticker?.toLowerCase().includes(searchInput.toLowerCase()) 
        
          //       ))
          //    },[searchInput])
    
        const columnSortingFn = (id) => {
            if (order === "ASC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id"
                  ? a[id].toLowerCase() > b[id].toLowerCase()
                    ? 1
                    : -1
                  : a[id] > b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("DSC");
            }
        
            if (order === "DSC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id" && id !== "ride_fare"
                  ? a[id].toLowerCase() < b[id].toLowerCase()
                    ? 1
                    : -1
                  : id === "date"
                  ? new Date(a.date).getTime() - new Date(b.date).getTime()
                  : a[id] < b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("ASC");
            }
          };
          const tabRender = (alignment)=>{
            setTab(alignment)
                                    }
        
        const handleFilterBar = (status)=>{
            setIsFilterOn(status)
            setStartDate('')
            setEndDate('')
            setSenderType('')
            setReceiverType('')
                                   } 
        
        const handleServiceEdit =(id,newData)=>{
        
        if(id){
          navigate(`/edit-split/${id}`)
        }
        }                            
                                   
        const handleServiceDelete =(id, newData)=>{
        // console.log(`Delete with id ${id}. New data:`, newData);                             
        setDeleteStatus(true)
        const paramsData ={splitId:id}
        setDeleteData(paramsData)
                                     
                                    }
        
        const onDeleteYes =(yesData)=>{
        ApiCall("PATCH", `/trip-service/v1/admin/payments/delete/split`,yesData,'BODY').then((data)=>{setSuccess(data)})
                                     }
        
        const onCloseNotification = ()=>{
          setDeleteStatus(false)
           }                              
        
        
        
        
        
        
        const handleChangeInSearch = (value)=>{
        setSearchInput(value)
        }                             
        
        const handleStatusChange =(id, newData)=>{
        // const paramsData = {surgeId:id,status:newData?.status,type:"General",action:"ChangeStatus"}
        // ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",paramsData,'BODY').then((data)=>{setSuccess(data)})
                                               }                             
        
        const handleColumnFilter = (items, searchText) => {
        setSelectedItems(items); // Update selected filter items
        
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
             
        
        
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
        
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.zone);
        
          // Check if the row matches all of the selected vehicleType items
          // const vehicleTypeMatch = items.every(
          //   (item) => row.vehicleType && row.vehicleType.includes(item)
          // );
          const vehicleTypeMatch = items.includes(row.days);
        
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
        
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
        
        // Update the filtered rows
        setRows(filteredRows);
        };

        const handlePageChange=(no,rowsCount)=>{
          setCurrentPage(no)
          setRowsPerPage(rowsCount)
          
        }

        const handleColumnDateFilter = async(startsUtc, endsUtc) => {
          try{
      
            if(endsUtc === 'senderType'){
                setSenderType(startsUtc)
            }else if(endsUtc === 'receiverType'){
                  setReceiverType(startsUtc)
            }else if(endsUtc === 'status'){
                  setTransferStatus(startsUtc)
            }
            else{
      
              const filteredDates = await formatDates(startsUtc,endsUtc)
              setStartDate(filteredDates?.formattedStartDate)
              setEndDate(filteredDates?.formattedEndDate)
      
            }
      
           
      
          }catch(error){
      
          }
        
          };
      








  return (
    <>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
   
    <DataTable
    handlingPage={handlePageChange}
    totalCount={totalRows}
    DeletePermission={deletePer}
    ButtonPermission={write}
    EditPermission={edit} 
    isSurcharge={false} 
    isFilterOn={isFilterOn}
    columns={columns}
    tableData={rows}
    columnSorting={columnSortingFn}
    handleSwitchChange={handleStatusChange}
    handleDelete={handleServiceDelete}
    openFilter={handleFilterBar}
    // ButtonPath={'/create-split'}
    // ButtonLabel={'Create Split'}
    handleEdit={handleServiceEdit}
    columnFilter={handleColumnFilter}
    searchInput={handleChangeInSearch}
    filterComponent={  <TransferFilter CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
  />
   </>
  )
}

export default TransferDetails
