import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { getUtcTime } from '../MultiFunctions/MultiFunctions';
import { useEffect } from 'react';

export default function MyDateRangePicker({handleUtc,isLta,currentRange,clearStatus}) {
    const [selectedDateRange, setSelectedDateRange] = React.useState([null, null]);
    console.log(currentRange , "the current range")
    console.log(selectedDateRange , "<---selectedRangeee")

    useEffect(()=>{
setSelectedDateRange([null, null])
    },[clearStatus])

     useEffect(()=>{
     
  if(Array.isArray(currentRange) && currentRange.length === 2){
    console.log("<---rendering hereee")
  setSelectedDateRange(currentRange)
}
     },[currentRange])



    const handleDateRangeChange = (newDateRange) => {
      setSelectedDateRange(newDateRange);
      if(isLta){

        if(newDateRange?.[1]){
      const startDate = newDateRange?.[0]
      const endDate = newDateRange?.[1]
      const formatedStartTime = startDate?.$d     
      const formatedEndTime = endDate?.$d      

      const UtcStartTime = getUtcTime(formatedStartTime)
      const UtcEndTime = getUtcTime(formatedEndTime)
      handleUtc(UtcStartTime,UtcEndTime)

     
    }

      }else{
       

        if(newDateRange?.[1]){
          const startDate = newDateRange?.[0]
          const endDate = newDateRange?.[1]
          const formatedStartTime = startDate?.$d     
          const formatedEndTime = endDate?.$d
    
          
    
        
          handleUtc(formatedStartTime,formatedEndTime,true)
    
         
        }


      }
        
    };



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateRangePicker']}>
        <DateRangePicker
         localeText={{ start: 'Start date', end: 'End date' }}
         value={selectedDateRange}
         onChange={handleDateRangeChange}
          />
      </DemoContainer>
    </LocalizationProvider>
  );
}