import { Box } from "@mui/material";
import React from "react";
import DetailsNav from "../../../Components/DetailsPage/DetailsNav";
import Tabs from "../../../Components/Tabs/Tabs";
import UserPersonalDetails from "./UserPersonalDetails";
import UserWalletDetails from "./UserWalletDetails";

function UserDetails() {
  const TabItems = [
    { name: "Personal Details", value: "Personal Details" },
    {
      name: "Activity",
      value: "Activity",
    },
    {
      name: "Wallet",
      value: "Wallet",
    },
    {
      name: "Reviews",
      value: "Reviews",
    },
  ];

  const Components = [
    {
      page: <UserPersonalDetails />,
      value: "Personal Details",
    },
    {
      page: <UserWalletDetails />,
      value: "Wallet",
    },
  ];
  return (
    <Box>
      <DetailsNav title={"User Details"} />
      <Tabs List={TabItems} component={Components} />
    </Box>
  );
}

export default UserDetails;
