import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import InputField from '../../Components/Forms/InputField'
import SelectField from '../../Components/Forms/SelectField'
import { PH_CODE } from '../../FakeData/FakeData'
function TicketForm() {
  return (
    <Box>
   <Stack spacing={2}>
   <Stack direction={'row'} spacing={3} >
        <InputField sideLabel={"Requester Name"} placeHolder={"Enter name"} />

        <SelectField
              sideLabel={"Priority"}
              placeHolder={"select"}
        />
          <SelectField
              sideLabel={"Status"}
              placeHolder={"select"}
              

            />
        </Stack>



        <Stack width={'66%'} direction={'row'} spacing={3} >



 <Grid container>
<Grid item xs={2} >
<SelectField
  sideLabel={"Code"}
  placeHolder={"+67"}
  Data={PH_CODE}
/>
</Grid>
<Grid item xs={0.5} />

<Grid item xs={9.5} >
<InputField sideLabel={"Phone"} placeHolder={"Phone"} />
</Grid>

</Grid> 
       
<SelectField
              sideLabel={"Assignee"}
              placeHolder={"select"}
              

            />


        
        </Stack>


<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Remarks"} placeHolder={"Remarks"} />
</Stack>



   </Stack>
    </Box>
   
  )
}

export default TicketForm

