import { Box, Button, Chip, FormControl, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../../Components/Forms/InputField'
import PasswordField from '../../../Components/Forms/PasswordField'

import { PH_CODE } from '../../../FakeData/FakeData'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import AddIcon from '@mui/icons-material/Add';

import { useSelector } from 'react-redux'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import ApiCall from '../../../../Helper/ApiCalls'
import { useParams } from 'react-router-dom'
import ImageSelectorUpdate from '../../../Components/Forms/ImageSelectorUpdate'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from "react-copy-to-clipboard";
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate'


function EditAdmin() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
  const [imageFile , setImageFile] = useState({})
  const [firstName,setFirstName] = useState('')
  const [middleName,setMiddleName] = useState('')
  const [lastName,setLastName] = useState('')
  const [selectedCode , setSelectedCode] = useState('')
  const [phoneNumber,setPhoneNumber] = useState('')
  const [designation , setDesignation] = useState('')
  const [password,setPassword] = useState('')
  const [email,setEmail]= useState('')
  const [confirmPassword,setConfirmPassword] = useState('')
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [submitHit,setSubmitHit] = useState(false)
  const [resetHit,setResetHit] = useState(false)
  const [onSuccess , setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)
  const [selectedChip, setSelectedChip] = useState([]);
  const [adOnFeatures , setAdOnFeatures] = useState([]);
  const [featureData , setFreatureData] = useState([]);
  const [selectedCountry , setSelectedCountry] = useState([])
  const [ImageLink , setImageLink] = useState('');
  const [resetUrl , setReserUrl] = useState('');
  const [adminCountryId , setAdminCountryId] = useState([])
  const { id:empID } = useParams();
  const {currentUserdata} = useSelector((state) => state.user)
  const currentCountries = currentUserdata?.CountryIDS
  const [currentCountryArray,setCurrentCountry] = useState([])
 
  const [isUpdatePassword , setIsUpdatePassword] = useState(false)
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)

  const [edit,setEdit] = useState()
  

  useEffect(()=>{
    let isEdit = checkPrivilage('Employee','UPDATE')
    setEdit(isEdit)
     },[])



// FUNCTIONS
const handleImageFiles = (files)=>{
  setImageFile(files)
  }



useEffect(()=>{
    ApiCall("GET", `/user-service/v1/admin/employee/get-employee/?employeeId=${empID}`, null).then((data)=>{
        if(data.result){
          const currentEmpData = data?.result
          const countryArray = currentEmpData?.CountryIDS
          
         const filteredCountry = countryArray?.map((i) => {
            return {id:i,name:i === 2 ? 'India' :i === 1 ? 'Singapore' : null}
          })
          setCurrentCountry(filteredCountry)
          console?.log(currentEmpData,",--- the employee datassss")
          setAdminCountryId(countryArray)
          setFirstName(currentEmpData?.FirstName)
          setMiddleName(currentEmpData?.MiddleName)
          setLastName(currentEmpData?.LastName)
          setEmail(currentEmpData?.Email)
          setDesignation(currentEmpData?.Designation)
          setImageLink(currentEmpData?.PhotoPath)
          let fullPhNo = currentEmpData?.PhoneNumber

          if(fullPhNo?.length == 11){
            const code = fullPhNo?.substring(0, fullPhNo?.length - 8)
            const number = fullPhNo?.substring(fullPhNo?.length - 8);
            setPhoneNumber(number)
            setSelectedCode(code)
      
          }else{
      
            const code = fullPhNo?.substring(0, fullPhNo?.length - 10);
            const number = fullPhNo?.substring(fullPhNo?.length - 10);
            setPhoneNumber(number)
            setSelectedCode(code)
      
          }





          let chipData = currentEmpData?.AdminUserRole?.map((each)=>{
            console.log(each?.Role?.Name,"<------each")
            return each?.Role?.Name
          })
          let featureData = currentEmpData?.AdminUserRole?.map((each)=>{
            return {id:each?.Role?.ID , name:each?.Role?.Name,countryId:each?.Role?.CountryID}
          })

          setFreatureData(featureData)
          setSelectedChip(chipData)
          
            console.log(currentEmpData , ".#.#.#.#.#.#")
        }
    })


},[])



  useEffect(()=>{
    ApiCall("GET", "/user-service/v1/admin/employee/roles", null,).then((data)=>{
      if(data.result){

        let filteredRowData
        // console.log(adminCountryId,"<---adminnn countryyyyyyyy")

                  const rowData = data?.result
                  if(adminCountryId.length > 0 && selectedCountry?.length < 1){
                    filteredRowData = rowData?.filter((eachRow) => adminCountryId?.includes(eachRow?.CountryID))
                  }

                  if (selectedCountry?.length > 0){
                    filteredRowData = rowData?.filter((eachRow) => selectedCountry?.includes(eachRow?.CountryID))
                  }
                  // console.log(rowData,"<--the main row dataasssssssssssssss")
              
                   filteredRowData = rowData?.map((eachData)=>{
                      return {name:eachData?.Name,id:eachData?.ID,countryId:eachData?.CountryID}
      
                  })
                 
      
                  setAdOnFeatures(filteredRowData)
              }

    })
  },[adminCountryId])







  const handleFormSubmit =()=>{
    setSubmitHit(true)
    const roleIds = featureData?.map(item => item.id).join(',')

    const formData = new FormData();
    formData.append('employeeId', empID);
    formData.append('firstName', firstName);
    formData.append('middleName', middleName);
    formData.append('lastName', lastName);
    formData.append('phoneNumber',selectedCode+phoneNumber);
    formData.append('email', email);
    formData.append('designation', designation);
    password?.length > 1 && password === confirmPassword && formData.append('password', password);
    formData.append('roleIds', roleIds);
    imageFile && formData.append('adminProfileImage', imageFile?.file);
    // formData.append('countryIds', selectedCountry?.length > 0 ? selectedCountry : adminCountryId);
selectedCountry ? 
    selectedCountry.forEach((id, index) => {
      formData.append(`countryIds[${index}]`, id);
    }) :  adminCountryId.forEach((id, index) => {
      formData.append(`countryIds[${index}]`, id);
    })


    ApiCall("PUT", "/user-service/v1/admin/employee/update", formData).then((data)=>{

      if(data.result === 'success'){    
        setOnSuccess((i) => !i )
        setOnApiSuccess("Succesfully updated employee")
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });
 

    

    

    
  }


  const handleUpdateClick =(status)=>{
    setIsUpdatePassword(status)
  }



  const handleAddChip = (chipName) => {
    if (!selectedChip?.includes(chipName)) {
        setSelectedChip([...selectedChip, chipName?.name]);
        setFreatureData([...featureData, chipName])
      }
  };

  const handleMultipleSelect = (label,data)=>{

    if(label === 'Country'){          
      let filteredData = data?.map((item)=>{
        return item?.id
      })
  
      setSelectedCountry(filteredData)
  
    }
  
  }
  
const handleSelect = (label,data)=>{
          
  if(label === 'Code'){
         setSelectedCode(data?.name)
  }


}



  const handleDeleteChip = (chipName) => {
    setSelectedChip(
       selectedChip.filter((each) => each !== chipName)
    )
    setFreatureData(featureData.filter((each) => each.name !== chipName ))
 };

 const chipAdornment = selectedChip ? (
  <InputAdornment position="start">
    {featureData.map(eachItem => (
      <Chip sx={{bgcolor:eachItem?.countryId === 2 ? '#DEEAED' :eachItem?.countryId === 1 ? '#fab1a0':null}}  label={eachItem?.name} onDelete={() => handleDeleteChip(eachItem?.name)} />
    ))}
  </InputAdornment>
    ) : null;

  const handleResetPassword=()=>{
    // setSubmitHit(true)
  const sendData ={
  email,
  isEditPassword:true
  }
  ApiCall("POST", "/user-service/v1/admin/employee/forgot/password", sendData).then((data)=>{
        if(data.status === 'success'){  
          
        const resetLink = data?.result?.resetLink
        setReserUrl(resetLink)
        localStorage.setItem("reserLink", resetLink)
        // setOnSuccess((i) => !i)
        // setOnApiSuccess("Reset link send successfull")
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });

  }
 
  





  return (
<Box>

<Stack spacing={2}>

    {/* <ImageSelector/> */}
    <Box width={isMatch?'100%':'32.4%'}>
    {/* <ImageSelector isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} /> */}
    <ImageSelectorUpdate imageLink={ImageLink} isError={false} handleFileChange ={handleImageFiles} />
    </Box>

        <Stack direction={'row'} spacing={3}>

        <InputFieldUpdate Value={firstName} InChange={(e) => setFirstName(e.target.value)} sideLabel={"First Name"} placeHolder={"First Name "} />
         
        <InputFieldUpdate Value={middleName} InChange={(e) => setMiddleName(e.target.value)} sideLabel={"Middle Name"} placeHolder={"Middle Name"} />
        
        <InputFieldUpdate Value={lastName} InChange={(e) => setLastName(e.target.value)} sideLabel={"Last Name"} placeHolder={"Last Name"} />
        </Stack>

       
        <Stack direction={'row'} spacing={3}>
        <Grid container>
            <Grid item xs={3} >
            <SelectFieldUpdate
              currentSelect={selectedCode}         
              onSelecting={handleSelect}
              sideLabel={"Code"}
              placeHolder={"select"}
              Data={PH_CODE}
            />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={8.5} >
            <InputFieldUpdate Value={phoneNumber} InChange={(e) => setPhoneNumber(e.target.value)} sideLabel={"Phone"} placeHolder={"Phone"} />
            </Grid>

           </Grid>

          
        <InputFieldUpdate Value={email} InChange={(e) => setEmail(e.target.value)} sideLabel={"E-mail"} placeHolder={"Email"} />
          
        <InputFieldUpdate Value={designation} InChange={(e) => setDesignation(e.target.value)} sideLabel={"Designation"} placeHolder={"Designation"} />
        </Stack>
        
        <Box width={'32%'}>
          {currentCountries?.length > 1 &&
          <MultipleSelectUpdate currentSelect={currentCountryArray} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Country"} Data={[{name:'India',id:2},{name:"Singapore",id:1}].filter(country => currentCountries.includes(country.id))} />
          }
          </Box>
 





        {/* readOnly input field */}

        <Stack>
        <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Privileges</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
       
        <OutlinedInput
        error={submitHit && selectedChip.length < 1 }
    readOnly
    sx={{ borderRadius: '4px', height: '46px' }}
    fullWidth
    placeholder="select"
    startAdornment={chipAdornment}
  />
      </FormControl>
        </Stack>



        <Stack alignItems={'left'}>

<Button  sx={{width:'15px',color:'#636e72',marginLeft:'10px'}} size='small'>SINGAPORE</Button>
   <Box
 width="100%"
 height="100px"
 sx={{
   overflowX: 'auto', // Enable horizontal scroll
  // Prevent buttons from wrapping to the next line
 }}
>
   
       {
         adOnFeatures ? 
           adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && eachFeature?.countryId === 1 && (

               <Button   
               onClick={() => handleAddChip(eachFeature)}
               sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',maxWidth:"100ox",marginRight: '8px',marginTop:"8px",
                 '&:hover': {
                   backgroundColor: '#FFFFFF',
                   color: '#0B2863',
                 },
               }}
               variant="contained"
               startIcon={<AddIcon />}
             >
           {eachFeature?.name}
             </Button>

           )) : 
           (
               <Typography color={'red'}>No roles available</Typography>
           )
       }
</Box>
</Stack>

<Stack alignItems={'left'}>
<Button  sx={{width:'15px',color:'#636e72'}} size='small'>INDIA</Button>

<Box
 width="100%"
 height="100px"
 sx={{
   overflowX: 'auto', // Enable horizontal scroll
  // Prevent buttons from wrapping to the next line
 }}
>

   


{
         adOnFeatures ? 
           adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && eachFeature?.countryId === 2 &&(

               <Button   
               onClick={() => handleAddChip(eachFeature)}
               sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',maxWidth:"100ox",marginRight: '8px',marginTop:"8px",
                 '&:hover': {
                   backgroundColor: '#FFFFFF',
                   color: '#0B2863',
                 },
               }}
               variant="contained"
               startIcon={<AddIcon />}
             >
           {eachFeature?.name}
             </Button>

           )) : 
           (
               <Typography color={'red'}>No Features available</Typography>
           )
       }
</Box>
</Stack>



   { resetUrl && 
         <Stack pt={3} direction={'row'} spacing={3}>
   <InputFieldUpdate disable={true} notRequired={true} Value={resetUrl} InChange={(e) => {}}/>
   <Box width={'5%'} >
   <CopyToClipboard
          options={{ message: "Coppied" }}
          text={resetUrl}
        >
<IconButton><FileCopyIcon/></IconButton>
</CopyToClipboard>
   </Box>
   </Stack>
   }
      

     {/* {isUpdatePassword ? 
      <Stack alignItems={'center'} flexDirection={'row'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
      <Stack width={'66%'}  direction={'row'} spacing={3}>
        <PasswordField isError={confirmPassword?.length > 0 && password !== confirmPassword} InChange={(e) => setPassword(e.target.value)} sideLabel={"Password"}/>
         <PasswordField isError={confirmPassword?.length > 0 && password !== confirmPassword} InChange={(e) => setConfirmPassword(e.target.value)} sideLabel={"Confirm Password"}/>
         </Stack>
          <Typography onClick={() => handleUpdateClick(false)} mt={3} variant='body2' sx={{color:'#021640',textDecoration:"underline",cursor:"pointer"}}>hide</Typography>
          </Stack>
          :  <Stack display={'flex'} justifyContent={'flex-end'} width={'100%'}  direction={'row'} spacing={3}>
          <Typography onClick={() => handleUpdateClick(true)} mt={3} variant='body2' sx={{color:'#021640',textDecoration:"underline",cursor:"pointer"}}>Update Password</Typography>
          </Stack>
          } */}
       
    
      




        <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />

    <Stack flexDirection={'row'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
    <Box >
  <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
  </Box>
  <Box >
  <Button onClick={handleResetPassword} sx={{color:"white",bgcolor:"red",width:"200px",borderRadius:"6px", textTransform:"none"}}>Reset Password</Button>
 </Box>
  </Stack> 

</Stack>
</Box>
  )
}

export default EditAdmin