import React from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { Provider } from 'react-redux'
import { Store , Persistor } from '../src/App/Redux/store'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
// require('dotenv').config()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={Store}>
      <PersistGate loading={null} persistor={Persistor}>
      <ToastContainer/>
      <App/>
     
      </PersistGate>
      </Provider>
   
    
  </React.StrictMode>
);



