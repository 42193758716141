import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import testCar from '../../../../Assets/other-images/Car.png'
import aIcon from '../../../../Assets/Table/Visual (2).svg'
import bIcon from '../../../../Assets/Table/Visual (3).svg'


function TripCard({cardDetails}) {

      const removeUnderscoresAndAddSpaces = (inputString)=> {
                // Use a regular expression to replace underscores with spaces globally
                return inputString?.replace(/_/g, ' ');
              }
              

  return (

 <Box padding={3} borderRadius={'8px'} bgcolor={'white'} width={'500px'} height={'310px'}>

    <Stack  spacing={2}>
{/* ROW ONE*/}
        <Typography sx={{textTransform:"none"}} variant='inherit' >{cardDetails?.dateTime}</Typography>
{/* ROW TWO*/}        
        <Box display={'flex'}  justifyContent={'space-between'} width={'100%'}>
         <Stack direction={'row'} spacing={3}>

          <img width={'86px'} height={'41px'} src={cardDetails?.vehicleImage} alt='vehicle' />  
            
          <Stack spacing={0.5}>
         <Typography sx={{textTransform:"none",fontWeight:600}} variant='inherit' >{cardDetails?.categoryName}</Typography>
         <Typography sx={{textTransform:"none",opacity:'50%'}} variant='caption' >{`${cardDetails?.totalDistance} Km`}</Typography>
         </Stack> 
         </Stack>
         <Button  size='small' sx={{textTransform:"none",backgroundColor:"#e6ffe6", color:"#009432",height:"30px"}}>{cardDetails?.rideStatus === 'PASSENGER_PICKED_UP_RIDE_STARTED'? 'RIDE STARTED': cardDetails?.rideStatus === 'RIDE_CANCELLED_BY_PASSENGER'? 'CANCELLED BY PASSENGER':cardDetails?.rideStatus === 'RIDE_CANCELLED_BY_DRIVER'? 'CANCELLED BY DRIVER':cardDetails?.rideStatus === 'DRIVER_ON_THE_WAY_TO_PICKUP'? 'ON THE WAY TO PICKUP': cardDetails?.rideStatus === 'DRIVER_ARRIVED_PICKUP'? 'DRIVER ARRIVED': removeUnderscoresAndAddSpaces(cardDetails?.rideStatus)}</Button>

        </Box>

{/* ROW THREE*/}   

<Stack direction={'row'} spacing={2}>
<img src={aIcon} alt='address'/>
<Stack spacing={0.5}>
<Typography sx={{textTransform:"none",opacity:'50%'}} variant='caption' >Pick-up</Typography>  
<Typography sx={{textTransform:"none", fontWeight:549}} variant='body2' >{cardDetails?.pickupAddress}</Typography>
</Stack>
</Stack>

{/* ROW FOUR */}
<Stack direction={'row'} spacing={3}>
<img src={bIcon} alt='address'/>
<Stack spacing={0.5}>
<Typography sx={{textTransform:"none",opacity:'50%'}} variant='caption' >Drop</Typography>  
<Typography sx={{textTransform:"none", fontWeight:549}} variant='body2' >{cardDetails?.destinationAddress}</Typography>
</Stack>
</Stack>

    </Stack>

 </Box>
  )
}

export default TripCard

//expppp