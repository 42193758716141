import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead';
import couponMockData from "../../FakeData/MOCK_DATA2.json";
import DataTable from '../../Components/Table/DataTable';
import ToggleNav from '../../Components/Forms/ToggleNav';


import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import ApiCall from '../../../Helper/ApiCalls';
import { useNavigate } from 'react-router-dom';
import RideFilter from '../RideServiceManagement/RideFilter';
import { each, iteratee } from 'lodash-es';
import dayjs from 'dayjs';
import VoucherFilter from './VoucherFilter';
import { useTitle } from '../../../Hooks/UseTitle';
import { useSelector } from 'react-redux';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions';
import { toast } from 'react-toastify';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      minWidth: 100,
      align: "left",
      sort: false,
    },
  
    {
      id: "code_name",
      label: "Code Name",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "type",
      label: "Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "couponType",
      label: "Coupon Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "value",
      label: "Value",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "usage_limit",
      label: "Usage Limit",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "user_used",
      label: "User Used",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "userType",
      label: "User Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    // {
    //   id: "start_date",
    //   label: "Start Date",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
 
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: true,
        onFalse: false,
      },
    },

    {
      id: "expiryDate",
      label: "Expiry Date",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];




function Voucher() {
  useTitle("Voucher - Geolah");
  const [rows, setRows] = useState();
  const [success, setSuccess] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [any,setAny] = useState()

  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [totalRows,setTotalRows] = useState(0)

  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()

  const test = process.env.REACT_APP_Base_Url;

  console.log(test , "<---testsss")

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Vouchers')
    setAny(isAany)
    let isWrite = checkPrivilage('Vouchers','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Vouchers','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Vouchers','DELETE')
     setDeletePer(isDelete)
     },[])

  const navigate = useNavigate()







  useEffect(()=>{
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/trip-service/v1/admin/coupon/all/?countryId=${CountryID}&&limit=${currentLimit}&&offset=${currentOffset}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const serviceData = data?.result?.coupon
      setTotalRows(data?.result?.totalCount)
      console.log(serviceData,"<-----r--r---r")

      const filteredData  = serviceData?.map((eachData,index)=>{
        let editedDate = eachData?.ToDateTime
        const formattedDate = dayjs(editedDate).format('YYYY-MM-DD');
        let testT = convertToCurrentCountryTime(editedDate)

        const myValues = parseFloat(eachData?.Value)
        const descType = eachData?.DiscountType
        let Items = {
          _id:eachData._id,
          id:index+1,
          code:eachData?.Code,
          couponType:eachData?.CouponType || 'N/A',
          code_name:eachData?.Name,
          type:eachData?.DiscountType,
          userType:eachData?.UserTypeID === 1 ?"User":"Driver",
          value: descType === 'amount' ? isNaN(myValues) ? "N/A" : myValues.toFixed(2) || "N/A" : myValues,
          usage_limit:eachData?.UsageLimit,
          user_used:eachData?.NUmberOfUse || 'N/A',
          status:eachData?.IsEnabled,
          countryId:eachData?.CountryID,
          expiryDate:testT,

           }

        return Items
      })

     
  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID,currentPage,rowsPerPage])


  useEffect(()=>{
    setRows(
      tableData.filter((item) =>
      item?.code?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.code_name?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.type?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.value?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.usage_limit?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.user_used?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.expiryDate?.toLowerCase().includes(searchInput.toLowerCase())
        ))
     },[searchInput])








    const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }


    // update service status

  const handleStatusChange =(id, newData)=>{
    //  console.log(`Switch with id ${id} changed. New data:`, newData);
     const paramsData = {couponId:id,status:newData?.status,countryId:newData?.countryId}
     ApiCall("PATCH", "/trip-service/v1/admin/coupon/update/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
  }




// delete status  
const handleServiceDelete =(id, newData)=>{
// console.log(`Delete with id ${id}. New data:`, newData);
setDeleteStatus(true)
const paramsData ={couponId:id,countryId:newData?.countryId}
setDeleteData(paramsData)

}

const onDeleteYes =(yesData)=>{
ApiCall("PATCH", '/trip-service/v1/admin/coupon/delete',yesData,'BODY',null).then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
// setDeleteStatus(false)
})
} 


const onCloseNotification = ()=>{
setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
if(id){
  navigate(`/edit-voucher/${id}`)
}


}

// handle search change

const handleChangeInSearch = (value)=>{
 setSearchInput(value)
 console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
}


const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
}






    

    const handleColumnFilter = (items, searchText) => {



      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.couponType);
        const vehicleTypeMatch = items.includes(row.userType);
        
    
        // Check if the row matches all of the selected vehicleType items
        // const vehicleTypeMatch = items.every(
        //   (item) => row.vehicleType && row.vehicleType.includes(item)
        // );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };
    
    
    
        
        const [order, setOrder] = useState("ASC");
    
        const columnSortingFn = (id) => {
            if (order === "ASC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id"
                  ? a[id].toLowerCase() > b[id].toLowerCase()
                    ? 1
                    : -1
                  : a[id] > b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("DSC");
            }
        
            if (order === "DSC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id" && id !== "ride_fare"
                  ? a[id].toLowerCase() < b[id].toLowerCase()
                    ? 1
                    : -1
                  : id === "date"
                  ? new Date(a.date).getTime() - new Date(b.date).getTime()
                  : a[id] < b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("ASC");
            }
          };
    




  return (
    any ? (
<Box>

<TableHead title={'Voucher'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
          DeletePermission={deletePer}
           ButtonPermission={write}
           EditPermission={edit} 
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-voucher'}
        ButtonLabel={'Create Voucher'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <VoucherFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      />
    </Box>

</Box>

</Box>
): any === false ? (
  <RestrictedUsage/>
):null
  )
}

export default Voucher
