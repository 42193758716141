import { tableConstants } from "../Constants/Constants"; 

const initialTableState = {
    currentTabledata: {},
};

export const TableReducer = (state = initialTableState, action) => {
    switch (action.type) {
        case tableConstants.INSERT_TABLE_DATA:
            return { ...state, currentTabledata: action.payload };
        default:
            return state;
    }
};