import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import CreateAdsForm from './CreateAdsForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import AppAds from '../AppAds';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';





function CreateAds() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      App Ads
      </Typography>,
 <Typography key="3" color="text.primary">
     Create App Ad
      </Typography>,
  ];
  useTitle("Create App Ads - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('App Ads')
    setAny(isAany)
     },[])
  return (
 any ? (   
<Box>
<CreatePageHead title={'Create App Ad'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
       <CreateAdsForm/>  
    </Box>
    </Box>   
</Box>
) : any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default CreateAds
