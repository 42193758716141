import { Box, Stack, createTheme, useMediaQuery} from '@mui/material'
import React from 'react'
import TripDetailsOverview from '../TripDetailsOverview/TripDetailsOverview'
import TicketInfoandReg from '../TicketInfo/TicketInfoandReg'
import Activity from '../Activity/Activity'


function OverView() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 1848,
    
      },
    },
  });

 const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
  return (
    !isMatch ?
  
<Box p={1} pt={2} flexWrap={'wrap'} display={'flex'} justifyContent={'space-around'}>
  <TripDetailsOverview/>
  <TicketInfoandReg/>
  <Activity/>
</Box>
:
<>
<Stack mt={2} direction={'row'} spacing={2}>
<TripDetailsOverview/>
  <TicketInfoandReg/>
</Stack>
<Activity/>
</>
  )
}

export default OverView
