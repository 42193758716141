import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import AddBasicFareForm from './AddBasicFareForm';
import AddMainFareForm from './AddMainFareForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';




function CreateBasicFare() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Basic Fare
      </Typography>,
 <Typography key="3" color="text.primary">
      Create Fare
      </Typography>,
  ];

  useTitle("Create Basic Fare - Geolah");
  const [any,setAny] = useState()
  useEffect(()=>{
    let isAany = checkAnyPrivilage('Basic Fare')
    setAny(isAany)
     },[])
  return (
    any ? (
<Box>
<CreatePageHead title={'Create Fare'} BreadList={breadcrumbs}/>
   <Stack spacing={2}>
   <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
  <AddBasicFareForm/>
    </Box>
    </Box>

    <Box Box borderRadius={'8px'} marginTop={2} bgcolor='white' width='97%' minHeight='850px'>
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
  <AddMainFareForm/>
    </Box>
    </Box>

   </Stack>

</Box>
    ):any === false? (
      <RestrictedUsage/>
    ) : null
  )
}

export default CreateBasicFare
