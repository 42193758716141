import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

function DashBox({Name , Amount , LineDetails, Subs}) {
  return (
    <Box pl={3} width='32.5%' borderRight={LineDetails ? "1px solid #DDDDDD" : null} >
        <Stack spacing={2} >
            <Typography fontWeight={600} variant='h7' >{Name}</Typography>
            <Typography fontWeight={500} variant='h4' color={'#021640'} >{Amount}</Typography>
            <Stack direction={'row'} spacing={3}>
              {
              Subs?.map((eachItems)=>(

                <Stack direction={'row'} spacing={1}>
<Typography variant='caption' color={'#c6c6c6'}>{eachItems?.title}</Typography>
<Typography variant='caption' color={eachItems?.title === 'pending' || eachItems?.title === 'offline' || eachItems?.title === 'cancelled' ? '#D40000' :'#021640'}>{eachItems?.value}</Typography>
</Stack>
              ))



              }
                 

                  {/* <Stack direction={'row'} spacing={1}>
                    <Typography variant='caption' color={'#c6c6c6'}>Cash</Typography>
                    <Typography variant='caption' color={'#021640'}>S$ 69,846</Typography>
                  </Stack>

                  <Stack direction={'row'} spacing={1}>
                    <Typography variant='caption' color={'#c6c6c6'}>Pending</Typography>
                    <Typography variant='caption' color={'#D40000'}>S$ 64</Typography>
                  </Stack> */}

            </Stack>
        </Stack>

    </Box>
  )
}

export default DashBox
