import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs'
import { useSelector } from 'react-redux';
import ToggleNav from '../../Components/Forms/ToggleNav';
import CreateTab from '../../Components/Forms/CreateTab';
import DataTable from '../../Components/Table/DataTable';
import paymentData from '../../FakeData/MOCK_DATA11.json'
import TableHead from '../../Components/Table/TableHead';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCalls';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import SurChargeFilter from '../SurgeCharge/SurChargeFilter';
import { useTitle } from '../../../Hooks/UseTitle';
import PaymentSplitTable from './PaymentSplitTable';
import dayjs from 'dayjs';
import PaymentFilter from './PaymentFilter';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import TransferDetails from './TransferDetails';
import PaymentFilterDriver from '../DriverModule/DriverDetails/GeneralDetails/Pages/PaymentFilterDriver';
import { convertToCurrentCountryTime, formatDates } from '../../Components/MultiFunctions/MultiFunctions';

const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },
  {
    id: "userName",
    label: "User Name",
    minWidth: 100,
    sort: true,
  },
  // {
  //   id: "cardType",
  //   label: "Transaction Medium",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "method",
  //   label: "Method",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  {
    id: "transactionId",
    label: "Transaction Reference",
    minWidth: 200,
    align: "left",
    sort: true,
  },
  {
    id: "transactionRef",
    label: "Psp Reference ",
    minWidth: 150,
    align: "left",
    sort: true,
    
  },
  {
    id: "type",
    label: "Type",
    minWidth: 150,
    align: "left",
    sort: false,
    
  },
  // {
  //   id: "tripId",
  //   label: "TripId",
  //   minWidth: 150,
  //   align: "left",
  //   sort: false,
    
  // },
  {
      id: "dateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
  // {
  //   id: "minimumFair",
  //   label: "Minimum Fair",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "extraFair",
  //   label: "Extra Fair",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "amentiasFair",
  //   label: "Amentias Fair",
  //   minWidth: 200,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "surgeCharge",
  //   label: "Surge Charge",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //     id: "powerlah",
  //     label: "Powerlah",
  //     minWidth: 170,
  //     align: "left",
  //     sort: true,
  //   },
  {
    id: "totalFair",
    label: "Authorised Amount",
    minWidth: 180,
    align: "left",
    sort: true,
  },
  {
    id: "capturedAmount",
    label: "Captured Amount",
    minWidth: 180,
    align: "left",
    sort: true,
  },
  {
    id: "transferAmount",
    label: "Transfer Amount",
    minWidth: 180,
    align: "left",
    sort: true,
  },
  // {
  //   id: "geolahAmount",
  //   label: "Geolah Amount",
  //   minWidth: 180,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "driverAmount",
  //   label: "Driver Amount",
  //   minWidth: 180,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "pendingAmount",
  //   label: "Pending Amount",
  //   minWidth: 180,
  //   align: "left",
  //   sort: true,
  // },
    // {
    //   id: "method",
    //   label: "Method",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },


    {
      id: "isOnline",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: "Online",
        onFalse: "Offline",
      },
    },
];








function Payment() {
  useTitle("Payment - Geolah");
  const [rows, setRows] = useState();
  const [order, setOrder] = useState("ASC")
  const [success, setSuccess] = useState()
  const [tab , setTab] = useState('left')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const [tableData, setTableData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const [totalRows,setTotalRows] = useState(0)
  const navigate = useNavigate()
  const [any,setAny] = useState()
  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()
  const [rowsPerPage,setRowsPerPage] = useState(10)

  const [currentPage,setCurrentPage] = useState(1)
  const [startDate,setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [typeFilter,setTypeFilter] = useState('')
  const [methodFilter,setMethodTypeFilter] = useState('')
  const [searchPage,setSearchPage] = useState(0)

useEffect(()=>{
  let isAany = checkAnyPrivilage('Payments')
  setAny(isAany)
  let isWrite = checkPrivilage('Payments','CREATE')
  setWrite(isWrite)
 let isEdit = checkPrivilage('Payments','UPDATE')
   setEdit(isEdit)
 
   let isDelete = checkPrivilage('Payments','DELETE')
   setDeletePer(isDelete)
   },[])


   useEffect(()=>{
    console.log(searchInput?.length , "<---searchIput")
    if(searchInput?.length > 0){
      setSearchPage(1)
    }else{
      setSearchPage(0)
    }
   console.log(currentPage , "<--- currentPageee")
    const ulteredPage = searchPage ? searchPage :currentPage;
    console.log(ulteredPage , "<---ULTEREDpage")
    const currentLimit = rowsPerPage
    const currentOffset = (ulteredPage-1) * rowsPerPage

    ApiCall("GET", `/pay-service/v1/admin/payment/all/transaction-history/?limit=${currentLimit}&&offset=${currentOffset}${searchInput && `&&search=${searchInput}`}${startDate && `&&fromDate=${startDate}`}${endDate && `&&toDate=${endDate}`}${typeFilter && `&&transactionType=${typeFilter}`}${methodFilter && `&&category=${methodFilter}`}`, null, "uui").then((data)=>{
      if(data?.result){  
        const paymentData = data?.result?.TransactionHistory  
        setTotalRows(data?.result?.totalCount)
        const filteredData =  paymentData?.map((eachItem,index)=>{
         let dateString = eachItem?.CreatedAt
         let testT = convertToCurrentCountryTime(dateString)
         const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
         const currentStartTime  =  dateString?.slice(11, 16);
         const rideFares = parseFloat(eachItem?.Amount);
         const capAmount = parseFloat(eachItem?.CapturedAmount)
         const surgeAmount = parseFloat(eachItem?.SurgeAmount)
         const geolahAmount = parseFloat(eachItem?.GeolahAmount)
         const driverAmount = parseFloat(eachItem?.DriverAmount)
         const pendingAmount = parseFloat(eachItem?.PendingAmount)
         const transAmount = parseFloat(eachItem?.TransferAmount)
   
   
         let items = {
               _id:eachItem._id,
               id:index+1+currentOffset,
               userName:eachItem?.userName === "null null" ? eachItem?.SenderType : eachItem?.userName,
               method:eachItem?.TransactionCategory,              
               transactionId:eachItem?.TransactionPubRef || 'N/A',
               dateTime:testT,
               type:eachItem?.TransactionType,
               tripId:eachItem?.TripId || 'N/A',
               cardType:eachItem?.TransactionMethod,
              //  surgeCharge:isNaN(surgeAmount) ? "N/A" : surgeAmount.toFixed(2) || "N/A",
               totalFair:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
               capturedAmount:isNaN(capAmount) ? "N/A" : capAmount.toFixed(2) || "N/A",
               geolahAmount:isNaN(geolahAmount) ? "N/A" : geolahAmount.toFixed(2) || "N/A",
               driverAmount:isNaN(driverAmount) ? "N/A" : driverAmount.toFixed(2) || "N/A",
               pendingAmount:isNaN(pendingAmount) ? "N/A" : pendingAmount.toFixed(2) || "N/A",
               transferAmount:isNaN(transAmount) ? "N/A" : transAmount.toFixed(2) || "N/A",
               transactionRef:eachItem?.TransactionRef || 'N/A',
               isOnline:eachItem?.Status || 'N/A'
  
              }
     
              return items;
        })
     
       
     
        setRows(filteredData)
        setTableData(filteredData);
      } })
  },[success,searchInput,startDate,endDate,typeFilter,methodFilter,currentPage])


// TABLE SEARCH

  // useEffect(()=>{

  //   setRows(
  //     tableData.filter((item) =>
  //     item?.id?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.method?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.totalFair?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.transactionId?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.transactionType?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.appType?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.name?.toLowerCase().includes(searchInput.toLowerCase()) 

      
  
  //       ))
  //    },[searchInput])



   const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };
  const tabRender = (alignment)=>{
    setTab(alignment)
                            }

     const handleFilterBar = (status)=>{
          setIsFilterOn(status)
          setStartDate('')
          setEndDate('')
          setTypeFilter('')
          setMethodTypeFilter('')
                                      }

const handleServiceEdit =(id,newData)=>{

if(id){
  navigate(`/surcharge-edit/${id}`)
}
}                            
                           
const handleServiceDelete =(id, newData)=>{
// console.log(`Delete with id ${id}. New data:`, newData);                             
setDeleteStatus(true)
const paramsData ={surgeId:id,status:false,type:"General",action:"Delete"}
setDeleteData(paramsData)
                             
                            }

const onDeleteYes =(yesData)=>{
// ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",yesData,'BODY').then((data)=>{setSuccess(data)})
                             }

const onCloseNotification = ()=>{
  setDeleteStatus(false)
   }                              


const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount) 
}

const handleColumnDateFilter = async(startsUtc, endsUtc) => {
  try{

    if(endsUtc === 'type'){
        setTypeFilter(startsUtc)
    }else if(endsUtc === 'method'){
          setMethodTypeFilter(startsUtc)
    }
    else{
      const filteredDates = await formatDates(startsUtc,endsUtc)
      setStartDate(filteredDates?.formattedStartDate)
      setEndDate(filteredDates?.formattedEndDate)

    }

   

  }catch(error){

  }

  };



const handleChangeInSearch = (value)=>{
setSearchInput(value)
}                             

const handleStatusChange =(id, newData)=>{
// const paramsData = {surgeId:id,status:newData?.status,type:"General",action:"ChangeStatus"}
// ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",paramsData,'BODY').then((data)=>{setSuccess(data)})
                                       }                             

const handleColumnFilter = (items, searchText) => {
setSelectedItems(items); // Update selected filter items

// If no items are selected and no search text, show all rows
if (items.length === 0 && searchText === "") {
  setRows(tableData);
  return;
}
     


// Filter the rows based on the selected items and search text
const filteredRows = tableData.filter((row) => {
  // Check if any of the selected items are present in the row's features
  const featuresMatch = items.every(
    (item) => row.features && row.features.includes(item)
  );

  // Check if the row matches any of the selected vehicleCategory items
  const vehicleCategoryMatch = items.includes(row.method);

  // Check if the row matches all of the selected vehicleType items
  // const vehicleTypeMatch = items.every(
  //   (item) => row.vehicleType && row.vehicleType.includes(item)
  // );
  const vehicleTypeMatch = items.includes(row.appType);

  // Check if the row contains the search text in any of its values
  const searchTextMatch = Object.values(row).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  // Return true if any of the filters match the row, otherwise return false
  return (
    (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
    searchTextMatch
  );
});

// Update the filtered rows
setRows(filteredRows);
};

  return (
    any ? (
    <>
      <Box>
    <TableHead title={'Payment'}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <ToggleNav tName={'Transfer Details'} showThird={true} lName={'Payments'} rName={'Payment Split'} toggleChange={tabRender} />  
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    {
    tab === 'left' ?  
// render the General Table
<>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
     DeletePermission={deletePer}
     ButtonPermission={write}
     EditPermission={edit} 
        isSurcharge={false} 
        isFilterOn={isFilterOn}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        openFilter={handleFilterBar}
        // ButtonPath={'/create-general-surcharge'}
        // ButtonLabel={'Add Surge'}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <PaymentFilterDriver CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      /> </> : tab === 'right' ? <PaymentSplitTable/> : <TransferDetails/>
     }
    </Box>
    </Box>
  </Box>
    

      </>
      ) : any === false ? (
         <RestrictedUsage/>
      ):null
    
  )
}

export default Payment