import React, { useState } from 'react'
import ProgressionSubmit from '../Forms/ProgressionSubmit'
import { Box, FormLabel, Grid,Stack, createTheme, useMediaQuery } from '@mui/material'
import { FilePond } from 'react-filepond'
import InputField from '../Forms/InputField';
import Radio from '@mui/joy/Radio';

function DriverVehicleDeatils({onButtonClick}) {
    const [selectedValue, setSelectedValue] = useState('j');

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd:855,
            md: 900,
            fmd:950,
            slg:1098,
            lg: 1200,
            sxl: 1430,
            xl: 1836,
          },
        },
      });
      
      const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl"));
      const [files, setFiles] = useState([])
  return (
    <Box>
      
    <Box overflow='auto' height={isXlMatch ? "25vh" : '28vh'}  >  
      <Stack spacing={3}>


      <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vehicle Model"} placeHolder={"Vehicle Model"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vehicle Model"} placeHolder={"Vehicle Model "} />
          </Grid>
        </Grid>


        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Color "} placeHolder={"Color"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"License Plate Number"} placeHolder={"License Plate Number"} />
          </Grid>
        </Grid>




        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Insurance"} placeHolder={"Insurance"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vehicle Log Card"} placeHolder={"Vehicle Log Card "} />
          </Grid>
        </Grid>




        <Grid container>
          <Grid xs={12} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
        </Grid>
        </Grid>


  
        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vehicle Type"} placeHolder={"Vehicle Type"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vehicle Inspection Details"} placeHolder={"Vehicle Inspection Details"} />
          </Grid>
        </Grid>





        <Grid container>
          <Grid xs={5.5} item>
            
          <Stack spacing={3} >

         
<FormLabel>Child Seat*</FormLabel>

<Box sx={{ display: 'flex', gap: 2 }}>
<Radio
checked={selectedValue === 'a'}
label="Available"
onChange={handleChange}
value="a"
name="radio-buttons"
slotProps={{ input: { 'aria-label': 'A' } }}
/>
<Radio
checked={selectedValue === 'b'}
label="Not Available"
onChange={handleChange}
value="b"
name="radio-buttons"
slotProps={{ input: { 'aria-label': 'A' } }}
/>



</Box>
</Stack>
</Grid>
<Grid xs={0.5} item />
<Grid xs={5.5} item>
<Stack spacing={3} >

         
          <FormLabel>Ownership Type *</FormLabel>

          <Box sx={{ display: 'flex', gap: 2 }}>
      <Radio
        checked={selectedValue === 'a'}
        label="Own vehicle"
        onChange={handleChange}
        value="a"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
         <Radio
        checked={selectedValue === 'b'}
        label="Rented vehicle"
        onChange={handleChange}
        value="b"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
     </Box>
     </Stack>
     </Grid>
     </Grid>













     <Grid container>
          <Grid xs={5.5} item>
            
          <Stack spacing={3} >

         
<FormLabel>Accept pet *</FormLabel>

<Box sx={{ display: 'flex', gap: 2 }}>
<Radio
checked={selectedValue === 'a'}
label="Yes"
onChange={handleChange}
value="a"
name="radio-buttons"
slotProps={{ input: { 'aria-label': 'A' } }}
/>
<Radio
checked={selectedValue === 'b'}
label="No"
onChange={handleChange}
value="b"
name="radio-buttons"
slotProps={{ input: { 'aria-label': 'A' } }}
/>



</Box>
</Stack>
</Grid>
<Grid xs={0.5} item />
<Grid xs={5.5} item>
<Stack spacing={3} >

         
          <FormLabel>Bicycle *</FormLabel>

          <Box sx={{ display: 'flex', gap: 2 }}>
      <Radio
        checked={selectedValue === 'a'}
        label="Yes"
        onChange={handleChange}
        value="a"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
         <Radio
        checked={selectedValue === 'b'}
        label="No"
        onChange={handleChange}
        value="b"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
     </Box>
     </Stack>
     </Grid>
     </Grid>




    
      

  

      
     

      </Stack>
    </Box>
    <Box paddingTop={5}  >
      <ProgressionSubmit Clicking={onButtonClick} toPage={"pagefour"} goBack={"pagetwo"} />
    </Box>
    </Box>
  )
}

export default DriverVehicleDeatils
