import { Box, Checkbox, FormControlLabel, IconButton, Paper, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataList from '../../../Components/Table/DataList'
import ShowOnlyTable from '../../../Components/Table/ShowOnlyTable'
import ApiCall from '../../../../Helper/ApiCalls'
import DraggableList from '../../../Components/Forms/DraggableList'
import InputField from '../../../Components/Forms/InputField'
import TableSearchBar from '../../../Components/Forms/TableSearch'
import closeIcon from '../../../../Assets/Table/Group 4336.svg'
import { useSelector } from 'react-redux'


function UserList({UserType,selectedUsers}) {

const [userData,setUserData] = useState([])
const [tableData,setTableData] = useState([])
const [limit,setLimit] = useState(5)
const [offset,setOffSet] = useState(0)
const [totalUsers , setTotalUsers] = useState(0)
const [rowsPerPage , setRowsPerPage] = useState(5)
const {currentCountrydata:CountryID} = useSelector((state) => state.country)

const [bookingCount , setBookingCount] = useState('')
const [isOnline , setIsOnline] = useState('')
const [isPriority , setIsPriority] = useState('')
const [filterStatus ,  setFilterStatus] = useState(false)
const [searchInput, setSearchInput] = useState('')



//FUNCTIONS


const handlingRowsPerpage = (rowsPerpage)=>{
     setLimit(rowsPerpage)
}

const handlePageChange = (pageNo) => {
  const newOffset = pageNo * rowsPerPage;
  // setOffSet(newOffset);

  const newLimit = (pageNo + 1) * rowsPerPage;
  setLimit(newLimit > totalUsers ? totalUsers : newLimit);
};

const handleSearch = (value)=>{
  setSearchInput(value)
 }


 const CloseModal = (status)=>{
  setFilterStatus(status)
}


console.log(offset,limit,"<---limittt off settttettttt")


useEffect(()=>{

  console.log(UserType,",typeeeeeeeeee")
  if(UserType === 2){

    ApiCall("GET", `/trip-service/v1/admin/driver/profile/details/?Limit=20000&&countryId=${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
  
       const driverData = data?.result
       const filteredData = driverData?.map((eachItem,index)=>{
  
  
       let items={
  
        // id:index+1,
        userID:eachItem?.UserID,
        name:eachItem?.FirstName +' '+eachItem?.LastName,
        phone:eachItem?.PhoneNumber,
        onlineStatus:eachItem?.OnlineStatus,
        bookingCount:eachItem?.TotalBooking?.toString(),
       
  }
  
  return items;
  
       })
  
      setUserData(filteredData) 
      setTableData(filteredData)
  
  
   } })

  }else if(UserType === 1){


    ApiCall("GET", `/user-service/v1/admin/user/customers/all?limit=${200000}&offset=${0}&&countryId=${CountryID}&&search=`, null, "uui").then((data)=>{

      if(data?.result){
        const userData = data?.result?.UsersData
        setTotalUsers(data?.result?.TotalUsers)

        console.log(userData,"<----Dataaaaaa")
        const filteredData = userData?.map((eachItem,index)=>{
          
          let items={
            id:index+1,
            userID:eachItem?.ID,
            name:eachItem?.FirstName +''+ eachItem?.LastName,
            phone:eachItem?.PhoneNumber,
            bookingCount:eachItem?.totalbooking?.stoString(),
       
          }

          return items
        })


        setUserData(filteredData)
        setTableData(filteredData) 
 
      }

     })


  }


},[UserType,limit,offset])


useEffect(()=>{
  
  setUserData(
    tableData.filter((item) =>
    item?.userID?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.phone?.toLowerCase().includes(searchInput.toLowerCase()) 
 
      ))
   },[searchInput])



   const handleColumnFilter = (items, searchText) => {
    console.log("items:",items ,"SearchText:",searchText)
    // setSelectedItems(items); // Update selected filter items
  
    // If no items are selected and no search text, show all rows
    if (items.length === 0 && searchText === "") {
      setUserData(tableData);
      return;
    }
         
    console.log(tableData,"<-----TTABLEDATA")
    console.log(items,"<-----ITEMS")
  
    // Filter the rows based on the selected items and search text
    const filteredRows = tableData.filter((row) => {
      // Check if any of the selected items are present in the row's features
      const featuresMatch = items?.includes(row.bookingCount)
  
      // Check if the row matches any of the selected vehicleCategory items
      const vehicleCategoryMatch = items?.includes(row.vehicleCatogery);
  
      // Check if the row matches all of the selected vehicleType items
      // const vehicleTypeMatch = items.every(
      //   (item) => row.vehicleType && row.vehicleType.includes(item)
      // );
      const vehicleTypeMatch = items?.includes(row.onlineStatus);
  
      // Check if the row contains the search text in any of its values
      const searchTextMatch = Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
  
      // Return true if any of the filters match the row, otherwise return false
      return (
        (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
        searchTextMatch
      );
    });
  
    // Update the filtered rows
    setUserData(filteredRows);
  };





  return (
  <Box>
    <Stack spacing={2}>

    { filterStatus && 
     <Paper  sx={{width:UserType === 1 ? "60%":"60%" , padding:"10px", height:"100px"}} elevation={3} >
      <Stack alignItems={'center'} direction={'row'} spacing={1}>
     <Box paddingBottom={'12px'} width={'32.5%'}>
        <InputField sideLabel={"Booking Count"} placeHolder={"Trip Count"} InChange={(e) => handleColumnFilter([e.target.value],"")} />
             </Box>
             <Box paddingTop={1}>
             <TableSearchBar changeInSearchBar={handleSearch} />  
             </Box>
        {UserType === 2 && <FormControlLabel onChange={(e) => handleColumnFilter([e.target.checked],"")} required control={<Checkbox  color="success" />} label="Online Drivers" />}
           {/* {UserType === 2 &&  <FormControlLabel onChange={(e) => setIsPriority(e.target.checked)} required control={<Checkbox  color="success" />} label="Priority Sticker" />} */}
           <IconButton  onClick={() => CloseModal(false)} aria-label="delete" size="large">
             <img src={closeIcon} alt="filter"/>
            </IconButton> 

             </Stack>
           
      
     </Paper>
     }
  
    <Box display={'flex'} justifyContent={'space-between'} >
        <Box width={'50%'} >
        <DataList openFilter={CloseModal} handlePgination={handlePageChange} TotalUserCount={totalUsers} handleRowsper={handlingRowsPerpage} sendSelectedData={selectedUsers} TableData={userData} Title={UserType === 1 ? 'Users':UserType === 2 ? 'Drivers': 'Select User Type'}/>
        </Box>
        {/* <Box width={'30%'} maxHeight={'500px'} sx={{overflowY:'scroll'}}> */}
          {/* <DraggableList items={datas}/> */}
        {/* <ShowOnlyTable/>    */}
        {/* </Box> */}
     

    </Box>

    </Stack>

  </Box>
  )
}

export default UserList
