import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import carImage from '../../../Assets/Service-Mangement/car (2) 1.svg'

function Card({cardIcon,cardTitle}) {
  return (
   <Box mt={2} borderRadius={'4px'} display={'flex'} alignItems={'center'} p={3} bgcolor={'white'}   width='31.39%' height='208px' >
   <Stack spacing={2} >
    <img width={'55px'} height={'32px'} src={cardIcon} alt='icon'/>
    <Typography fontWeight={600} >{cardTitle}</Typography>
    <Typography color={'grey'} variant='subtitle2'>Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</Typography>
   </Stack>
   </Box>
  )
}

export default Card
