import { Box, Button, FormControl, FormControlLabel, FormLabel, OutlinedInput, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate'
import TimePickers from '../../../Components/Forms/TimePickers'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import InputField from '../../../Components/Forms/InputField'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import ApiCall from '../../../../Helper/ApiCalls'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import { BONUS_TYPE } from '../../../FakeData/FakeData'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'

function EditSpecialDaySurchargeForm() {

  
    const [submitHit,setSubmitHit] = useState(false)
    const [startTime , setStartTime] = useState('')
    const [endTime , setEndTime] = useState('')
    const [currentZone,setCurrentZone] = useState('')
    const [selectedDay,setSelectedDay] = useState('')
    const [surgeAmount , setSurgeAmount] = useState('')
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [selectedDate , setSelectedDate] = useState('')
    const [selectedZone,setSelectedZone] = useState('')
    const [surgeType , setSurgeType] = useState('')
    const { id:surchargeID } = useParams();
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

   
    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Surcharges','UPDATE')
      setEdit(isEdit)
      
  
       },[])



//RENDERS 

useEffect(()=>{

  if(surchargeID){
     let type = 'Special'
    ApiCall("GET", `/trip-service/v1/admin/sur-charges/surge-details/?surgeId=${surchargeID}&&type=${type}`,null,).then((data)=>{
      if(data.result){
       const rowData = data?.result[0]
     
       let currentDate = rowData?.Date
       const formattedDate = dayjs(currentDate).format('YYYY-MM-DD');
       const dayOfWeek = dayjs(currentDate).format('dddd')

       const dateString = rowData?.SurgeDateStartTime;
 
       const dateStringEnd = rowData?.SurgeDateEndTime;

       const currentStartTime  =  dateString?.slice(11, 16);
       const currentEndTime  =  dateStringEnd?.slice(11, 16);
   


       setCurrentZone(rowData?.ZoneID?.Name)
       setSelectedDate(formattedDate)
       setSelectedDay(dayOfWeek)
       setSurgeAmount(rowData?.SurgeCharge)
       setStartTime(currentStartTime)
       setEndTime(currentEndTime)
       setSelectedZone(rowData?.ZoneID?._id)
       setSurgeType(rowData?.SurgeType)

       console.log(rowData,"<=-----detaiolrsss")
     

      
          
      }
  })
  .catch((error) => {
    if(error.message){
      console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong [valueservice - get]')
      }
  
  
  });








  }




},[surchargeID])

const handleTimeChange = (label,data)=>{

  if(label === 'Start Time'){
   setStartTime(data)
  }else if(label === 'End Time'){
   setEndTime(data)
  }

}


const handleFormSubmit =()=>{
    setSubmitHit(true) 
    
    let sendData = {
      zoneIds: [selectedZone],
      days:[selectedDay],
      specialDate:selectedDate,
      startTime:startTime,
      endTime:endTime,
      amount:surgeAmount,
      specialDayZoneSurgesId:surchargeID,
      countryId:CountryID,
      isSpecialDay:true,
      type:"Special",

    }


    ApiCall("PUT", "/trip-service/v1/admin/sur-charges/edit/surge",sendData).then((data)=>{
      if(data?.status === 'success'){
        setOnSuccess(true)
      }
  })
  .catch((error) => {
    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  
  
  });
    

    }



     
    const handleSelect = (label,data,index)=>{
          
      if(label === 'Type'){
        setSurgeType(data?.name)
      } 
    
    
    }





    const navigate  = useNavigate()

    const handleDiscard =()=>{
      navigate(-1)
    }




  return (
    <Box>
   <Stack spacing={3} >
     
   <Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
          {/* {
            !isSingleZone ? (
              <MultipleSelect isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            ):(
              <MultipleSelectUpdate currentSelect={allZoneName} isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            )
          } */}
         

       <Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Zone</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={currentZone}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>
       
     
         <Box width={'30%'}>
         <Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Date</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={selectedDate}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
         {/* <MultipleSelectUpdate isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Days"} Data={daysData} /> */}
         </Box>
        </Stack>

{/* TIME SECTION */}
<Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
        <TimePickers initialValue={startTime} TimeChange={handleTimeChange} sideLabel={"Start Time"} />
         </Box>
         <Box width={'30%'}>
         <TimePickers initialValue={endTime} TimeChange={handleTimeChange} sideLabel={"End Time"}/>
         </Box>
        </Stack>

 {/* REMARKS */}
{/* <Stack direction={'row'} spacing={3} >
         <Box width={'100%'}>
         <InputField isError={submitHit && remarks.length < 1} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}  sideLabel={"Remarks"} placeHolder={"Remarks"} />
         </Box>
         </Stack> */}

     

{/* SURGE AMOUNT */}

<Stack direction={'row'} spacing={3} >
<Box width={"30%"} >
        <SelectFieldUpdate currentSelect={surgeType}   isError={''} onSelecting={handleSelect} Data={BONUS_TYPE} sideLabel={'Type'} placeHolder={'choose'} />
        </Box>
         <Box width={'30%'}>
         <InputFieldUpdate Type={'number'} Value={surgeAmount} isError={submitHit && surgeAmount.length < 1} ErrNote={""} InChange={(e) => setSurgeAmount(e.target.value)}  sideLabel={"Surge Amount"} placeHolder={`Surge Amount in ${surgeType === 'Amount' ? 'S$':surgeType === 'Percentage' ? '%' : 'S$'}`} />
         </Box>
         </Stack>

{/* <AlertPopUp/> */}

    <SnackBars submitStatus={submitHit} successNote={'Successfully Created'} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}  handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>

{/* SAVE FORM */}

   </Stack>
   </Box>
  )
}

export default EditSpecialDaySurchargeForm
