import { Avatar, Box, Button, Checkbox, FormControlLabel, Link, Paper, Skeleton, Stack, TextField, Typography, createTheme, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react";
import { Link as Link1, useNavigate } from "react-router-dom"
import ApiCall from "../Helper/ApiCalls";
import { publicRequest } from "../App/RequestMethord";
import PermissionChecker from "../Helper/PermissionChecker";
import mainLogo from '../Assets/Outlook-zfx3n5fs_1.png'
import { LoadingButton } from "@mui/lab";

function SigninSkelton() {
    const [loading, setLoading] = useState(false);
    const [email , setEmail] = useState("");
    const [password , setPassword] = useState("");
  
    function handleClick() {
      setLoading(true);
    }
    function delayedFunction() {
      setLoading((i) => !i)
      navigate('/') 
    }
  
  
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          smd:855,
          md: 900,
          fmd:950,
          slg:1098,
          lg: 1200,
          sxl: 1430,
          xl: 1836,
        },
      },
    });
  
  
    const navigate = useNavigate()
    const isMatch = useMediaQuery(theme.breakpoints.down('lg'))    //sets true below breakpoint
    const secMatch = useMediaQuery(theme.breakpoints.down('md'))   //sets true below breakpoint
    const smMatch = useMediaQuery(theme.breakpoints.down('sm'))    //sets true below breakpoint
    const xlMatch = useMediaQuery(theme.breakpoints.up('xl')) 
  
  
  
    const handleLogin = ()=>{
    const Pdetails =   PermissionChecker('1000');
    console.log(Pdetails,"<--pppp");
   
    handleClick()
    const timeoutId = setTimeout(delayedFunction, 2000);
    const userDetails = {email,password}
     try {
      
     const Response =  ApiCall('POST','/user/login', userDetails,publicRequest )
     if(Response){
      console.log(Response);
  
     }
  
     } catch (error){
        console.log(error)
     }
   
    }
  return (
    <Box display="flex"
    justifyContent="center"
    alignItems="center"


    sx={{
      '& > :not(style)': {
        m: 1,
       width: isMatch === true && secMatch === false ? '50%' : secMatch === true && smMatch === false ? '80%' : smMatch ? '100%' : '35%',
        height: xlMatch ? 650 : 500,
      },
      minHeight: secMatch === true && smMatch === false ? '100vh' : smMatch ? '85vh' : '100vh',
    }}
  >
    <Paper elevation={3} >

      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={secMatch === true && smMatch === false ? '45vh' : '65vh'}
        spacing={4}>

       <Skeleton variant="rectangular" width={210} height={40} />

       <Skeleton width={350}  variant="text" sx={{ fontSize: '1rem' }} />

   
         <Skeleton variant="rectangular" width={370} height={45} />
         <Skeleton variant="rectangular" width={370} height={45} />
 
        <Stack direction='row' display='flex' alignItems='center' spacing={7} >
       
          <Skeleton width={150}  variant="text" sx={{ fontSize: '1rem' }} />

          <Skeleton width={150}  variant="text" sx={{ fontSize: '1rem' }} />

      
        </Stack>
        
      
  
        <Skeleton sx={{borderRadius:'8px'}} variant="rectangular" width={370} height={35} />
      
        

      </Stack>

    </Paper>
  </Box>
  )
}

export default SigninSkelton
