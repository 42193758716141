import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import TopArrow from '../../../../Assets/Dashboard/right-arrows 1.svg'

function SingleDashCard({Title,Color,Caption}) {
  return (
<Box mt={2} width="200px" height="125px" bgcolor={Color} borderRadius="12px" >
    <Box pr={1} display="flex" justifyContent="flex-end" >
     
     <img src={TopArrow} alt='arrow'/>

    </Box>

    <Box display="flex" justifyContent="center" >
  <Stack spacing={0.3}>

  <Typography variant='h3'   fontWeight='500' >{Title}</Typography>
  <Typography alignSelf="center" variant='caption'>{Caption}</Typography>

  </Stack>
        

    </Box>

</Box>
  )
}

export default SingleDashCard
