import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'


import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCalls';
import { useTitle } from '../../../Hooks/UseTitle';
import DataTable from '../../Components/Table/DataTable';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import TableHead from '../../Components/Table/TableHead';
import RideFilter from '../RideServiceManagement/RideFilter';
import { insertUserPersonalDataAction } from '../../Redux/Actions/UserPersonalDetailsActions';
import { useDispatch, useSelector } from 'react-redux';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: false,
    },
  
    {
      id: "chargeName",
      label: "Charge Name",
      minWidth: 400,
      align: "left",
      sort: false,
    },
    {
      id: "cost",
      label: "Cost",
      minWidth: 400,
      align: "left",
      sort: false,
    },
   
    {
      id: "remarks",
      label: "Remarks",
      minWidth: 170,
      align: "left",
      sort: false,
    },
 
      {
        id: "status",
        label: "Activate",
        minWidth: 170,
        align: "left",
        sort: false,
        title: {
          onTrue: true,
          onFalse: false,
        },
      },
      {
        id: "actions",
        label: "Actions",
        minWidth: 170,
        align: "left",
        sort: false,
      },
   

  ]; 






function OtherCharges() {
    useTitle("Other Charges - Geolah");

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [totalRows,setTotalRows] = useState(0);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    const dispatch = useDispatch()
  
    const navigate = useNavigate()

    const [any,setAny] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Other Charges')
      setAny(isAany)
      let isWrite = checkPrivilage('Other Charges','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Other Charges','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Other Charges','DELETE')
       setDeletePer(isDelete)
       },[])


  // api call
useEffect(()=>{

    ApiCall("GET", `/trip-service/v1/admin/fare-charges/other-charge/${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const chargeData = data?.result
      setTotalRows(chargeData?.Charges?.length)
      chargeData.Charges.push(chargeData.WaitingCharge);
     const filteredData =  chargeData?.Charges?.map((eachItem,index)=>{
       const costs = parseFloat(eachItem?.Value)?.toFixed(2)
      
      let items = {
            _id:eachItem?._id,
            id:index+1,
            chargeName:eachItem?.Name || 'Waiting Charge',
            remarks:eachItem?.Remarks || 'N/A',
            status:eachItem?.IsActive,
            allData:eachItem,
            cost: isNaN(costs) ? parseFloat(eachItem?.SlabCharge)?.toFixed(2) : costs ,
            isWaiting:eachItem?.WaitTimeSlab? true :false,
            
           }
  
           return items;
     })       
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID])




    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.chargeName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.remarks?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.cost?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])



    const handleStatusChange =(id, newData)=>{
      console.log(newData , " <----new dataaaa")
         console.log(`Switch with id ${id} changed. New data:`, newData);
         let paramsData
         if(newData?.isWaiting){
          paramsData = {waitingChargeId:id,status:newData?.status}
         }else{
          paramsData = {chargeId:id,status:newData?.status}
         }
         
         ApiCall("PATCH", "/trip-service/v1/admin/fare-charges/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    dispatch(insertUserPersonalDataAction(newData))
    console.log(newData,"<#####newwwwdattttrrrr")
    if(id){

      navigate(`/edit-other-charge`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
     console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
  }


    const handleColumnFilter = (items, searchText) => {



        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }





    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

 



  return (
    any ? (
<Box>
<TableHead title={'Other Charges'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       totalCount={totalRows}
           DeletePermission={deletePer}
           ButtonPermission={write}
           EditPermission={edit} 
      isAvoidDelete={true}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-other-charge'}
        // ButtonLabel={'Add Other Charge'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
    </Box>

</Box>
</Box>
): any === false ? (
  <RestrictedUsage/>
): null
  )
}

export default OtherCharges