import { countryConstants } from "../Constants/Constants"; 

const initialCountryState = {
    currentCountrydata: {},
};

export const CountryReducer = (state = initialCountryState, action) => {
    switch (action.type) {
        case countryConstants.INSERT_COUNTRY_DATA:
            return { ...state, currentCountrydata: action.payload };
        default:
            return state;
    }
};