import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';
import AddServiceForm from './AddServiceForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage, checkPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';





function CreateRideService() {
  
  const [any,setAny] = useState()
  const navigate = useNavigate()
  const [write,setWrite] = useState()


  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Service Management
      </Typography>,
 <Typography key="3" color="text.primary">
     Create Service
      </Typography>,
  ];

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Ride Service')
    setAny(isAany)
    
    let isWrite = checkPrivilage('Ride Service','CREATE')
    setWrite(isWrite)

     },[])
  useTitle("Create Service - Geolah");
  return (
    any  ? (

    
   <Box>
    <CreatePageHead title={'Create Service'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%'>
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
       <AddServiceForm/>  
    </Box>
    </Box>
   </Box>): any === false? (
    <RestrictedUsage/>
   ) : null
  )
}

export default CreateRideService
