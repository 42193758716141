import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducers from "./Reducers/RootReducer";



const persistConfig = {
  key: 'root',
  storage: storage,
  // Add any other configuration options as needed
};
const persistedReducer = persistReducer(persistConfig, RootReducers);

const middlewares = [thunk];

export const Store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
);

export const Persistor = persistStore(Store);