import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import RideFilter from '../RideServiceManagement/RideFilter';
import ApiCall from '../../../Helper/ApiCalls';
import TableHead from '../../Components/Table/TableHead';
import { useTitle } from '../../../Hooks/UseTitle';
import { each } from 'lodash-es';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';


const columns = [

  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },
  {
    id: "vehicleId",
    label: "Vehicle ID",
    minWidth: 150,
    align: "left",
    sort: true,
  },
  {
    id: "make",
    label: "Make",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "model",
    label: "Model",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "vehicleType",
    label: "Vehicle Type",
    minWidth: 200,
    align: "left",
    sort: true,
  },
  {
    id: "color",
    label: "Color",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "licensePlate",
    label: "License Plate",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "driver",
    label: "Driver",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "isOnline",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Online",
      onFalse: "Offline",
    },
  },

  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
]; 






function Vehicle() {
  useTitle("Vehicles - Geolah");
  const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const navigate = useNavigate()
    const [any,setAny] = useState()

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0)

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Vehicles')
      setAny(isAany)
      let isWrite = checkPrivilage('Vehicles','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Vehicles','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Vehicles','DELETE')
       setDeletePer(isDelete)
       },[])


    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
      ApiCall("GET", `/trip-service/v1/admin/vehicle/?countryId=${CountryID}&&Limit=${currentLimit}&&Offset=${currentOffset}${searchInput && `&&search=${searchInput}`}`, null, "uui").then((data)=>{
      if(data?.result){    
           const vehicleData = data?.result?.vehicleDetails
           setTotalRows(data?.result?.totalCount)
           console.log(vehicleData,"<------vehicleee dataaaaaaa")
          const filteredData =  vehicleData?.map((eachItem,index)=>{
                    
           let items = {
                 _id:eachItem?._id,
                 userID:eachItem?._id,
                 id:index+1+currentOffset,
                 vehicleId:eachItem?._id,
                 make:eachItem?.VehicleBrand?.[0]?.Name,
                 model:eachItem?.VehicleBrandModel?.[0]?.Name,
                 vehicleType:eachItem?.VehicleType?.[0]?.Type,
                 color:eachItem?.VehicleColor,
                 licensePlate:eachItem?.RegistrationNumber,
                 driverId:eachItem?.UserID,
                 driver:eachItem?.DriverProfileDetails?.[0]?.FirstName+" "+eachItem?.DriverProfileDetails?.[0]?.LastName,
                 isOnline:eachItem?.DriverProfileDetails?.[0]?.OnlineStatus === true ? 'Available':'N/A'     
                }
       
                return items;
          })
       
          
       
          setRows(filteredData)
          setTableData(filteredData);
        } })
       },[success,CountryID,currentPage,rowsPerPage,searchInput])



        //  useEffect(()=>{
        // setRows(
        //   tableData.filter((item) =>
        //   item?.vehicleId?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.make?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.model?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.color?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.licensePlate?.toLowerCase().includes(searchInput.toLowerCase()) || 
        //   item?.driver?.toLowerCase().includes(searchInput.toLowerCase()) ||
        //   item?.isOnline?.toLowerCase().includes(searchInput.toLowerCase())
        //     ))
        //  },[searchInput])


    const handleStatusChange =(id, newData)=>{
      //  console.log(`Switch with id ${id} changed. New data:`, newData);
       const paramsData = {valueServiceId:id,status:newData?.status}
      //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
    }




// delete status  
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);
  setDeleteStatus(true)
  const paramsData ={id,name:newData?.rideService}
  setDeleteData(paramsData)
 
}

const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
} 


const onCloseNotification = ()=>{
  setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
  if(id){
    navigate(`/edit-vehicle/${id}/${newData?.licensePlate}/${newData?.driverId}`)
  }
 

}

// handle search change

const handleChangeInSearch = (value)=>{
   setSearchInput(value)
   console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
}


  const handleColumnFilter = (items, searchText) => {



      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    
        // Check if the row matches all of the selected vehicleType items
        const vehicleTypeMatch = items.every(
          (item) => row.vehicleType && row.vehicleType.includes(item)
        );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };


  const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }





  const columnSortingFn = (id) => {
      if (order === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id"
            ? a[id].toLowerCase() > b[id].toLowerCase()
              ? 1
              : -1
            : a[id] > b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("DSC");
      }
  
      if (order === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() < b[id].toLowerCase()
              ? 1
              : -1
            : id === "date"
            ? new Date(a.date).getTime() - new Date(b.date).getTime()
            : a[id] < b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("ASC");
      }
    };


    const handleProfileEdit = (id,newData)=>{
      const newTab = window?.open(`/vehicle-details/${id}`, '_blank');
      if (newTab) {
        newTab?.focus(); // Optionally, you can focus on the new tab
      }
        // if(id){
        //   navigate(`/driver-details/${id}`)
        // }
     }
  //handle page change

  const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)
  }






  return (
    any ? (
<Box>
<TableHead title={'Vehicles'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
         handlingPage={handlePageChange}
         totalCount={totalRows}
         DeletePermission={false}
         ButtonPermission={write}
         EditPermission={edit}
        handleClickProfileDetails={handleProfileEdit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/add-vehicle'}
        ButtonLabel={'Create Vehicle'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
    </Box>

</Box>
</Box>
) : any === false ?  (
  <RestrictedUsage/>

): null
  )
}

export default Vehicle



//use when filter is required
//<RideFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} /> 