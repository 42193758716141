import { dashConstants } from "../Constants/Constants"; 

const initialDashState = {
    currentDashdata: {},
};

export const DashReducer = (state = initialDashState, action) => {
    switch (action.type) {
        case dashConstants.INSERT_DASH_DATA:
            return { ...state, currentDashdata: action.payload };
        default:
            return state;
    }
};