import { Box, Button, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { transform } from "framer-motion";
import SearchBar from "./SearchBar";
import TableSearchBar from "./TableSearch";

function CreateTab({ title, handleClick , nonTable }) {
  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])


const {currentTabledata} = useSelector((state) => state.table)



  return (
    <Box  alignItems='center' pt={3} pl={2}>
        <Box width={'100%'} >
{


 
  nonTable ? <></> : <Typography fontSize={'80%'} >Showing {currentTabledata.perPage > currentTabledata.total ? currentTabledata.total : currentTabledata.perPage } result of {currentTabledata.total} </Typography>

}
</Box>

  {/* <Box marginRight={20} width={'15%'}>
  {
      loadingData ? 
      <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> :
      <TableSearchBar/>


     } 

  </Box> */}
    
     
    
    </Box>
  );
}

export default CreateTab;
