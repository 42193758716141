import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead';
import ZoneMapContainer from './ZoneMap/ZoneMapContainer';
import Tabs from '../../Components/Tabs/Tabs';
import { useTitle } from '../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';


const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Zone Management
      </Typography>,
 <Typography key="3" color="text.primary">
      Zone List
      </Typography>,
 

  ];


  const TabItems = [
    
    { name: "Zone A", value: "Zone A" },
    {
      name: "Zone B",
      value: "Zone B",
    },
    {
      name: "Zone C",
      value: "Zone C",
    },
    {
      name: "Zone D",
      value: "Zone D",
    },
  
  ];



function ZoneManagament() {
  useTitle("Zone Management - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Zone')
    setAny(isAany)
     },[])


  return (
any ? (
<Box>
<TableHead title={'Zone Management'} BreadList={breadcrumbs} />
<Box pb={5} justifyContent={'center'}   borderRadius={'8px'} margin={2} bgcolor={'white'}   width='100%' height='100%' >
<ZoneMapContainer/>

</Box>
</Box>
):any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default ZoneManagament
