import { Box, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLoadScript, GoogleMap,OverlayView } from "@react-google-maps/api";
import { Polygon } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import ApiCall from "../../../../Helper/ApiCalls";
import { useNavigate } from "react-router-dom";
import { GOOGLE_MAP_API_KEY, mapStyles } from "../../../Components/Maps/MapStyles";
import { insertAllCoordinatesDataAction } from "../../../Redux/Actions/AllCoordinatesActions";
// import { Marker, OverlayView } from "react-google-maps";

function ZoneMapContainer({ width, title }) {
  const [loadingData, setLoadingData] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [zonePoints , setZonePoints] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

// this delay is used in rendering zone inorder to avoid initial render error 300 is least , 

  useEffect(() => {
    function delayedFunction() {
      setLoadingData(true);
      renderZones()
    }
    const timeoutId = setTimeout(delayedFunction, 500);

    return () => clearTimeout(timeoutId);
  },[]);






  // const [center] = useState({ lat: 1.342818, lng: 103.828285 });
  const [center,setCenter] = useState({})
  const [zoom,setZoom] = useState(11.5);
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const colors = ["#FB8184", "#3A9D75", "#F0A65D", "#7D7DBE"];

 

useEffect(()=>{

  if(CountryID === 1){
    setCenter({ lat: 1.342818, lng: 103.828285 })
  }

  if(CountryID === 2){
    setCenter({ lat: 10.8505, lng: 76.2711 })
    setZoom(7.4)
  }

},[CountryID])

const handleZoneClick =(zoneName,zoneId)=>{
  navigate(`/zone-details/${zoneName.replace(/\s+/g, '')}/${zoneId}`)
}






useEffect(()=>{

  ApiCall("GET", `/trip-service/v1/admin/zone/get-all/zones/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){

      const zoneData = data?.result
      console.log(zoneData,"<------###-------")
      const filteredZOnes = zoneData?.map((eachZone,index)=>{
          let pointList = eachZone?.location?.coordinates?.[0]             
          let filteredCoordinates = pointList?.map((eachOne,index)=>{

            let items={

            lat:eachOne[1],
            lng:eachOne[0]
      
            }

            return {...items,id:eachZone?._id}
          })
      return filteredCoordinates
      })
      setZonePoints(filteredZOnes)
      dispatch(insertAllCoordinatesDataAction(filteredZOnes))
  console.log(filteredZOnes,"zone====")
      } })

},[CountryID])



  const renderZones = () => {


console.log(process.env.GOOGLE_MAP_API_KEY,"<---ENVVVV")


    const zoneLabels = ["Zone A", "Zone B", "Zone C", "Zone D"];
  
    return zonePoints?.map((coordinates, index) => {

      const polygonBounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coordinate) => {
        polygonBounds.extend(coordinate);
      });
      const polygonCenter = polygonBounds.getCenter();
      const textPosition = { lat: polygonCenter.lat(), lng: polygonCenter.lng() };

  
      return (
        <Fragment key={index}>
          <Polygon
            path={coordinates}
            options={{
              strokeColor: colors[index],
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: colors[index],
              fillOpacity: 0.3,
            }}
            />
            <OverlayView
               
          position={textPosition}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -(height / 2),
          })}
        >
          <div
           onClick={() => handleZoneClick(zoneLabels[index],coordinates?.[0]?.id)}
            style={{
             width:'150px',
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: 'none', 
            }}

            onMouseEnter={(e) => {
                e.currentTarget.style.color = 'white';
                e.currentTarget.style.textDecoration = 'underline'; // Add underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = 'black';
                e.currentTarget.style.textDecoration = 'none'; // Remove underline when not hovering
              }}
          >
            {zoneLabels[index]}
          </div>
        </OverlayView>
 
        </Fragment>
      );
    });
  };

  // useEffect(()=>{
  //   setRenderedMap(renderZones)
  //   },[])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  // const renderMap = () => {
  //   return (
  //     <Fragment>
  //       <GoogleMap
  //         center={center}
  //         zoom={zoom}
  //         onLoad={() => setMapLoaded(true)}
  //         mapContainerStyle={{
  //           height: "614px",
  //           width: "95%",
  //           borderRadius: "4px",
  //         }}
  //         options={{
  //           mapTypeControl: false,
  //           styles: mapStyles,
  //         }}
  //       >
  //         {mapLoaded && renderZones()} 
  //         {/* Adjust above line there is some rendering issues */}
  //       </GoogleMap>
  //     </Fragment>
  //   );
  // };

  return isLoaded  ? (
    <Box
    pt={5}
    // pl={6}
    // pr={0}
    display={'flex'}
    justifyContent={'center'}
    
      sx={{
        flexWrap: "wrap",
        mt:'10px',
        "& > :not(style)": {
      
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems:"center",
        },
      }}
    >
      <Typography variant="h6">
        <strong>{title}</strong>
      </Typography>
      <Fragment>
       <GoogleMap
          center={center}
          zoom={zoom}
          onLoad={() => setMapLoaded(true)}
          mapContainerStyle={{
            height: "614px",
            width: "95%",
            borderRadius: "4px",
          }}
          options={{
            mapTypeControl: false,
            styles: mapStyles,
          }}
        >
          {loadingData && renderZones()} 
          
          {/* Adjust above line there is some rendering issues */}
         </GoogleMap>
   
      </Fragment>
      {/* {renderMap()} */}
    </Box>
  ) : (
    <div>Loading...</div>
  );
}

export default ZoneMapContainer;




//AIzaSyB5gb2yasQUrSvgA8D-uAcugZ1Ycaneg7A