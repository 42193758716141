import { Box, Button, IconButton, Stack, Typography, createTheme, useMediaQuery } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import AddIcon from '@mui/icons-material/Add';
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate';
import { useSelector } from 'react-redux';
import ApiCall from '../../../../Helper/ApiCalls';
import { useParams } from 'react-router-dom';
import { checkPrivilage } from '../../../../Sessions/Permisssions';

function EditConvenienceForm() {

    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [write,setWrite] = useState(true)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [selectedMethord,setSelectedMethord] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [from,setFrom] = useState(0)
    const [to,setTo] = useState(0)
    const [platFormFee,setPlatFormFee] = useState(0)
    const [serviceFee,setServiceFee] = useState(0)
    const [insuranceFee,setInsuranceFee] = useState(0)
    const { id:convenienceId } = useParams();
    const [edit,setEdit] = useState()


     
        //DYNAMIC FORM HANDLING
  


        useEffect(()=>{

            ApiCall("GET",`/trip-service/v1/admin/convenience-fee/byId/?convenienceId=${convenienceId}`, null,).then((data)=>{
                if(data.result){
                    const rowData = data?.result?.[0]
                    setFrom(rowData?.StartCharge)
                    setTo(rowData?.EndCharge)
                    setPlatFormFee(rowData?.PlatformCharge)
                    setServiceFee(rowData?.ServiceCharge)
                    setInsuranceFee(rowData?.DriverInsuranceCharge)   
                  
                }
             
            })
        
        },[])
        
 
        useEffect(()=>{
   
      
          let isEdit = checkPrivilage('Convenience Fee','UPDATE')
          setEdit(isEdit)
          
      
           },[])



    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

    const handleFormSubmit =()=>{
      setSubmitHit(true)
      
      let sendData={
        countryId:CountryID,
        currency: "SGD",
        from,
        to,
        platFormFee,
        serviceFee,
        insuranceFee,
        convenienceId
    
      }

      ApiCall("PUT", "/trip-service/v1/admin/convenience-fee/update", sendData).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully updated")      
        }
    })
    .catch((error) => {
        setOnFailed((i) => !i)
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    });

    }

  return (
    <Box>     
    <Stack spacing={3} >

  
  <Fragment>
    <Stack spacing={2}>
        <Stack direction={'row'} spacing={0.5}>
        <Typography sx={{fontWeight:'bold'}}>Add Tier</Typography>
        </Stack>
    

        <Box width={isMatch?'100%':'30%'}>
          
        <Stack direction={'row'} spacing={1}>
        <InputFieldUpdate sideLabel={'From'} Type={'number'} InChange={e => setFrom(e.target.value) } Value={from} isError={false} ErrNote={""}  placeHolder={"From"} />
        <InputFieldUpdate sideLabel={'To'} Type={'number'} InChange={e => setTo(e.target.value) } Value={to} isError={false} ErrNote={""}   placeHolder={"To"} />
        </Stack>
 
    </Box>

    <Stack alignItems={'center'} direction={'row'} spacing={3} >
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={e => setPlatFormFee(e.target.value)} Value={platFormFee} sideLabel={'Platform fee'} isError={false} ErrNote={""}   placeHolder={""} />
    </Box>
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={e => setServiceFee(e.target.value) }  Value={serviceFee} sideLabel={'Service fee'} isError={false} ErrNote={""}   placeHolder={""} />
    </Box>
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={e => setInsuranceFee(e.target.value) } Value={insuranceFee} sideLabel={'Driver insurance fee'} isError={false} ErrNote={""}  placeHolder={""} />
    </Box>

    </Stack>
    </Stack>
    </Fragment>
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
    </Stack>
    </Box>
  )
}

export default EditConvenienceForm
