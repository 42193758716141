import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import SnackBars from '../../../Components/Forms/SnackBars'
import { Box, Grid, Stack, createTheme, useMediaQuery, useStepContext } from '@mui/material'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import { App_Type, BONUS_TYPE, ISSUE_STATUS, PH_CODE, PRIORITY_LIST, USER_TYPES, VehicleCategory } from '../../../FakeData/FakeData'
import { useTitle } from '../../../../Hooks/UseTitle'
import ApiCall from '../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import { baseUrl } from '../../../RequestMethord'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import { checkPrivilage } from '../../../../Sessions/Permisssions'


function EditTicketsForm() {

  useTitle("Edit Tickets - Geolah");
  const { id:ticketID,userId } = useParams();
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)

    const [requesterName , setRequesterName] = useState('') 
    const [selectedStatus , setSelectedStatus] = useState('') 
    const [phoneNumber , setPhoneNumber] = useState('')
    const [selectedPriority , setSelectedPriority] = useState('') 
    const [selectedCode , setSelectedCode] = useState('')
    const [selectedUserType , setSelectedUserType] = useState('')
    const [selectedAppType , setSelectedAppType] = useState('')
    const [assign,setAssign] = useState('')
    const [remarks , setRemarks] = useState('')
    const [description,setDescription] = useState('')
    const[empData,setEmpData] = useState([])
    const[issueData,setIssueData] = useState([])
    const [currentIssueName , setCurrentIssueName] = useState('')
    const [currentAssignName , setCurrentAssignName] = useState('')
    const [selectedIssue , setSelectedIssue] = useState(0)
    const [initialIssueId , setInitialIssueId] = useState(0)
    const [initialAssignId , setInitialAssignId] = useState(0)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Ticket','UPDATE')
      setEdit(isEdit)
      
  
       },[])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));





// api call
useEffect(()=>{
     
  const apiUrl = `${baseUrl}/user-service/v1/general/all/issues`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      // ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  axios.get(apiUrl, config).then(response => {

    if(response?.data?.result){
      const issueData =response?.data?.result

      let filteredData = issueData?.map((eachData)=>{
            return{id:eachData?.ID,name:eachData?.Name}        
      }) 

      setIssueData(filteredData)
  
    }
    
      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });



  ApiCall("GET", `/user-service/v1/admin/employee/get-all/?countryId=${CountryID}&&limit=100000`, null, "uui").then((data)=>{
    if(data?.result){

    const empData = data?.result
 
 const filterdData = empData?.map((eachItem)=>{
  return {id:eachItem?.ID,name:eachItem?.FirstName}
 })

 setEmpData(filterdData)

 } })
},[CountryID])



//GETTING CURRENT TICKET DETAILS


useEffect(()=>{

    if(ticketID){

      ApiCall("GET", `/user-service/v1/admin/driver/single/ticket/ById/?ticketId=${ticketID}&&driverId=${userId}`,null).then((data)=>{
           if(data.result){
            const rowData = data?.result
            console.log(rowData,"<--TICKETSSSSSSSS-#############-!!!!!!!!!^^^^^^^^^")
            setSelectedAppType(rowData?.AppType === 'DriverApp' ? 'Driver App' : rowData?.AppType === 'UserApp' ? 'User App' : null )
            setInitialIssueId(rowData?.IssueID)
            setSelectedPriority(rowData?.Priority)
            setRemarks(rowData?.Remark)
            setDescription(rowData?.Description)
            setSelectedUserType(rowData?.UserTypeID === 1 ? 'User' : rowData?.UserTypeID === 2 ? 'Driver':null)
            setSelectedStatus(rowData?.Status)
            setInitialAssignId(rowData?.Assignee)


            let fullPhNo = rowData?.PhoneNumber
            // console.log(fullPhNo,"<--(*)-------|")
             if(fullPhNo?.length == 11){
             const code = fullPhNo?.substring(0, fullPhNo?.length - 8)
             const number = fullPhNo?.substring(fullPhNo?.length - 8);
           setPhoneNumber(number)
           setSelectedCode(code)

           }else{

           const code = fullPhNo?.substring(0, fullPhNo?.length - 10);
           const number = fullPhNo?.substring(fullPhNo?.length - 10);
           setPhoneNumber(number)
           setSelectedCode(code)

    }
    

 
      
           }
       })
       .catch((error) => {
         if(error.message){
           console.log(`Error -> ${error.message}`)
           }else{
             console.log('something went wrong [valueservice - get]')
           } 
       });
    }
    },[ticketID])





    // api call
  useEffect(()=>{

    const apiUrl = `${baseUrl}/user-service/v1/general/all/issues`;  
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        // ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
  
      },
    };
  
    axios.get(apiUrl, config).then(response => {
  
      if(response?.data?.result){
          const issueData = response?.data?.result
       
          let filteredData = issueData?.map((eachData)=>{
              return{id:eachData?.ID,name:eachData?.Name}        
        }) 
  
        setIssueData(filteredData)
 


       const currentIssue = filteredData?.filter((item) => item?.id === initialIssueId)
       console.log(filteredData)
       setCurrentIssueName(currentIssue?.[0]?.name)
       selectedIssue(currentIssue?.[0]?.id)
    
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });
  
  
  
    ApiCall("GET", `/user-service/v1/admin/employee/get-all/?countryId=${CountryID}&&limit=100000`, null, "uui").then((data)=>{
      if(data?.result){
  
      const empData = data?.result
   
   const filterdData = empData?.map((eachItem)=>{
    return {id:eachItem?.ID,name:eachItem?.FirstName}
   })
  
   setEmpData(filterdData)
   const currentAssign = filterdData?.filter((item) => item?.id === initialAssignId)
   setCurrentAssignName(currentAssign?.[0]?.name)
   setAssign(initialAssignId)
   

  
   } })
  },[CountryID,initialIssueId,initialAssignId])
   
    
























    const handleFormSubmit =()=>{
        setSubmitHit(true)
       
    let sendData = {
      // phoneNumber: selectedCode+phoneNumber,
      userId:userId,
      ticketId:ticketID,
      userTypeId: selectedUserType === 'User' ? 1 : selectedUserType === 'Driver' ? 2:null ,
      priorityStatus: selectedPriority,
      // status: selectedStatus,
      appType: selectedAppType,
      assigneeId:parseInt(assign),
      issueId:parseInt(selectedIssue || initialIssueId),
      description: description,
      countryId: CountryID,
      remark: remarks,
    }
        
        ApiCall("PUT", `/user-service/v1/admin/driver/tickets/update`, sendData).then((data)=>{
            if(data.result === 'success'){
          
              setOnSuccess((i) => !i )
              setOnApiSuccess("Succesfully updated tickets")
              
        
            }
        })
        .catch((error) => {
           setOnFailed((i) => !i)
          
            if(error.message){
              setOnApiFailed(error.message)
              console.log(error , "<----errrrrrr")
            }else{
              setOnApiFailed("Something went wrong.!")
            }
        
        
        });
       
       
       
        
       
       
       }


       const handleSelect = (label,data,index)=>{
            
        if(label === 'Priority'){
          setSelectedPriority(data?.name)
        }else if(label === 'Status'){
            setSelectedStatus(data?.name)
        }else if(label === 'Assignee'){
            setAssign(data?.id)
        }else if(label === 'User Type'){
          setSelectedUserType(data?.name)
      }else if(label === 'App Type'){
        setSelectedAppType(data?.name)
    }else if(label === 'Issue'){
      setSelectedIssue(data?.id)
  }
      
               
      }




  return (
    <Box>
     
    <Stack spacing={3} >
    {/* form   */}
    <Stack spacing={2}>


{/* FIRST ROW */}


<Stack direction={'row'} spacing={3}>

<Box width={'25%'}>
<SelectFieldUpdate currentSelect={selectedAppType} placeHolder={'choose'} isError={false} onSelecting={handleSelect} sideLabel={"App Type"} Data={App_Type} />
        </Box>
        <Box width={'25%'}>
<SelectFieldUpdate currentSelect={currentIssueName} placeHolder={'choose'} isError={false} onSelecting={handleSelect} sideLabel={"Issue"} Data={issueData} />
        </Box>
        <Box width={isMatch ? "100%" : "25%"} >
        <SelectFieldUpdate currentSelect={selectedPriority}   isError={''} onSelecting={handleSelect} Data={PRIORITY_LIST} sideLabel={'Priority'} placeHolder={'choose'} />
        </Box>  
        <Box width={isMatch ? "100%" : "25%"} >
        <SelectFieldUpdate currentSelect={selectedStatus}   isError={''} onSelecting={handleSelect} Data={ISSUE_STATUS} sideLabel={'Status'} placeHolder={'choose'} />
        </Box>  

</Stack>


{/* SECOND ROW */}


<Stack direction={'row'} spacing={3}>
<Box width={'23.5%'}>
<SelectFieldUpdate currentSelect={currentAssignName}   isError={''} onSelecting={handleSelect} Data={empData} sideLabel={'Assignee'} placeHolder={'choose'} />
</Box>

{/* <Box width={isMatch ? '100%':'33%'}>
<Grid container>
<Grid item xs={2} >
<SelectFieldUpdate currentSelect={selectedCode} isError={false} onSelecting={handleSelect} Data={PH_CODE} sideLabel={'Code'} placeHolder={'+67'} />
</Grid>
<Grid item xs={0.5} />

<Grid item xs={9.1} >
<InputFieldUpdate Value={phoneNumber} isError={''} ErrNote={""} InChange={(e) => setPhoneNumber(e.target.value)}   sideLabel={"Phone"} placeHolder={"Phone"} />
</Grid>
</Grid>
</Box> */}

<Box  width={'23.5%'}>
    <SelectFieldUpdate currentSelect={selectedUserType} isError={false} onSelecting={handleSelect} sideLabel={"User Type"} Data={USER_TYPES} placeHolder={'choose'} />
    </Box>

    <Box width={'49%'}>
        <InputFieldUpdate Value={remarks} isError={false} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
        </Box>

</Stack>


{/* LAST ROW */}

<Stack direction={'row'} spacing={3}>




<Box width={'100%'}>
        <InputFieldUpdate Value={description} isError={false} ErrNote={""} InChange={(e) => setDescription(e.target.value)}   sideLabel={"Description"} placeHolder={"Description"} />
        </Box>

</Stack>





        </Stack>
          {/* <AlertPopUp/> */}
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
    </Stack>
 </Box>
 
  )
}

export default EditTicketsForm
