import { Box } from '@mui/material'
import React from 'react'
import DayFair from './DayFair'
import NightFair from './NightFair'

function Economy() {
  return (
   <Box display={'flex'} justifyContent={'space-between'} >
    <DayFair/>
    <NightFair/>
   </Box>
  )
}

export default Economy
