import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }


function BreadCrumbs({Breadcrumbz}) {


  return (
    <Breadcrumbs
    sx={{opacity:"50%",cursor:'pointer'}}
    separator={<NavigateNextIcon fontSize="small" />}
    aria-label="breadcrumb"
  >
    {Breadcrumbz}
  </Breadcrumbs>
  )
}

export default BreadCrumbs
