import { Box, Button, Grid, Stack, createTheme, useMediaQuery } from "@mui/material";
import React from "react";

function FormSubmitButtons({handleSubmit,buttonStatus}) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd: 855,
        md: 900,
        fmd: 950,
        slg: 1098,
        lg: 1200,
        sxl: 1430,
        xl: 1536,
      },
    },
  });

  const isSlgMatch = useMediaQuery(theme?.breakpoints?.down("slg"));

  const isSmdMatch = useMediaQuery(theme?.breakpoints?.down("smd"));

  return (
    <Box
 
      alignSelf="center"
      sx={{
        width: "100%",
        height: "88px",
      
       
      }}
    >
    
      <Stack direction={'row'} spacing={2} >

      <Button
      disabled={!buttonStatus}
            sx={{
              width: "114px",
              height: "40px",
              borderRadius: "8px",
              backgroundColor: "#021640",
              color: "white",
            }}
            variant="contained"
            onClick={() => handleSubmit() }
          >
            {" "}
            save
          </Button>
      
   
          {/* <Button
            sx={{
              width: "114px",
              height: "40px",
              borderRadius: "8px",
              backgroundColor: "#D3D3D9",
              color:'black',
            }}
            variant="contained"
          >
            {" "}
            Reset
          </Button> */}

      </Stack>
  
 
    </Box>
  );
}

export default FormSubmitButtons;
