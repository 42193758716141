import React from 'react'
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material'

function DetailsNavSkelton() {
  return (
    <Box alignItems='center' display='flex' justifyContent='flex-start' >
    
    <Stack alignItems='center' direction='row' spacing={2}  >
 
    <Skeleton variant="circular" width={40} height={40} />
 

   
    <Typography variant="h6">
    <Skeleton width={"150px"} variant="text" sx={{ fontSize: '1rem' }} />
    </Typography>
    </Stack>



   
 

</Box>
  )
}

export default DetailsNavSkelton
