import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import downloadIcon from '../../../../../Assets/Vector.svg'
import FileIcon from '../../../../../Assets/jpg-file 1.svg'
import { DocListStyles } from '../../../Vehicles/VehicleDetails/Pages/VehicleDocumentsList';
import DocumentSingleCard from '../../../../Components/Documents/DocumentsSingleCard';
function UserLicense({LicenseDoc,InsuranceDoc,VehicleDoc,ProfileDoc}) {
   const classes = DocListStyles();

  console.log(LicenseDoc,"<--- the licenseeeeee")
  return (
   <Stack spacing={2}>
   
  
{/* first box */}
{
  
LicenseDoc?.length > 0 &&

<>
<Typography m={2} >License Documents</Typography>
 
    <Box  p={2} width="1200px" className={classes.container} justifyContent={LicenseDoc?.length > 2 && "space-between"}  borderRadius={'4px'}  border={'1px solid #c6c6c6'}  height={'300px'}>
    {LicenseDoc?.map((eachData,index)=>(
   <DocumentSingleCard singleData={eachData} />
   ))}

 </Box>

 </>

}

{/* second box */}
{
ProfileDoc?.length > 0 &&  
<>
<Typography m={2} >Profile Documents</Typography>

<Box p={2} width="1200px" className={classes.container} justifyContent={ProfileDoc?.length > 2 && "space-between"}  borderRadius={'4px'}  border={'1px solid #c6c6c6'}  height={'300px'}>
   

    {ProfileDoc?.map((eachData,index)=>(
   <DocumentSingleCard singleData={eachData} />
   ))}

 </Box>
 </>}


{/* third box */}
{
 VehicleDoc?.length > 0 &&
  <>
<Typography m={2} >Vehicle Documents</Typography>
<Box p={2} width="1200px" className={classes.container} justifyContent={VehicleDoc?.length > 2 && "space-between"}  borderRadius={'4px'}  border={'1px solid #c6c6c6'}  height={'300px'}>
 

    {VehicleDoc?.map((eachData,index)=>(
   <DocumentSingleCard singleData={eachData} />
   ))}

 </Box>
 </>
 }


 {/* Fourth Document */}
 { InsuranceDoc?.length > 0 &&
  <>
 <Typography m={2} >Insurance Documents</Typography>
 <Box p={2} width="1200px" className={classes.container} justifyContent={InsuranceDoc?.length > 2 && "space-between"}  borderRadius={'4px'}  border={'1px solid #c6c6c6'}  height={'300px'}>
  

    {InsuranceDoc?.map((eachData,index)=>(
   <DocumentSingleCard singleData={eachData} />
   ))}

 </Box>
 </>

}



</Stack>
  )
}

export default UserLicense
