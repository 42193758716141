import { Box, Button, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import InputField from '../../../Components/Forms/InputField'
import ApiCall from '../../../../Helper/ApiCalls'
import { useNavigate, useParams } from 'react-router-dom'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'

function EditSplitForm() {

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [submitHit,setSubmitHit] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false) 

    const [splitName , setSplitName] = useState('')
    const [driverSplit , setDriverSplit] = useState('')
    const [geolahSplit , setGeolahSplit] = useState('')
    const [priorityDeduction , setPriorityDeduction] = useState('')
    const { id:splitID } = useParams();

    const [edit,setEdit] = useState()
  

    useEffect(()=>{
      let isEdit = checkPrivilage('Payments','UPDATE')
      setEdit(isEdit)
      
  
       },[])



useEffect(()=>{
  ApiCall("GET", `/trip-service/v1/admin/payments/split/byId/?splitId=${splitID}&&countryId=${CountryID}`,null,).then((data)=>{
    if(data.result){
     const rowData = data?.result

     setSplitName(rowData?.Name)
     setDriverSplit(rowData?.Splits?.[1]?.percentage)
     setGeolahSplit(rowData?.Splits?.[0]?.percentage)
     setPriorityDeduction(rowData?.PriorityStickerDeduction)

     console.log(rowData,"<----rowwwwderrr")
    }
  })



},[splitID])




const navigate  = useNavigate()

const handleDiscard =()=>{
  navigate(-1)
}





    const handleFormSubmit =()=>{
        setSubmitHit(true)

        let sendData = {
            name:splitName,
            splitId:splitID,
            psd:priorityDeduction,
            countryId:CountryID,
            splits:[
                {
                    name: "Geolah",
                    percentage: geolahSplit,
                    type: "Geolah",
                 
                  },
                  {
                    name: "Driver",
                    percentage: driverSplit,
                    type: "Driver",
                 
                  }
            ]


        }



        ApiCall("PUT", "/trip-service/v1/admin/payments/update/splits",sendData).then((data)=>{
            if(data?.status === 'success'){
              setOnSuccess(true)
            }
        })
        .catch((error) => {
          setOnFailed((i) => !i)
            
          if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
          console.error(error, "PUT API ERROR");
        
        
        });


    }



  return (
<Box>
<Stack spacing={3} >
{/* form   */}
 <Stack spacing={2}>

{/* ROW ONE */}

    <Stack  direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'32%'}>
         <InputFieldUpdate Value={splitName} isError={submitHit && splitName.length < 1} ErrNote={""} InChange={(e) => setSplitName(e.target.value)}  sideLabel={"Payment Split Name"} placeHolder={"Payment Split Name"} />
         </Box>
         <Box width={'32%'}>
         <InputFieldUpdate Value={driverSplit} isError={submitHit && driverSplit.length < 1} ErrNote={""} InChange={(e) => setDriverSplit(e.target.value)}  sideLabel={"Driver’s split (%)"} placeHolder={"Driver’s split"} />
         </Box>
         <Box width={'32%'}>
         <InputFieldUpdate Value={geolahSplit} isError={submitHit && geolahSplit.length < 1} ErrNote={""} InChange={(e) => setGeolahSplit(e.target.value)}  sideLabel={"Geolah’s split (%)"} placeHolder={"Geolah’s split"} />
         </Box>

    </Stack>


{/* ROW TWO */}

<Stack  direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'32%'}>
         <InputFieldUpdate Value={priorityDeduction} isError={submitHit && priorityDeduction.length < 1} ErrNote={""} InChange={(e) => setPriorityDeduction(e.target.value)}  sideLabel={"Priority Sticker Deduction"} placeHolder={"Amount"} />
         </Box>

</Stack>






    </Stack>

    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>

   <SnackBars submitStatus={submitHit} successNote={'Successfully Updated'} Status={onSuccess}/>
   <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </Stack>

</Box>
  )
}

export default EditSplitForm