import { Box, Button, Stack, useMediaQuery} from '@mui/material'
import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, createTheme } from "@mui/material/styles";
import searchLens from '../../../Assets/search-normal.svg'

const Search = styled("div")(({ theme,...rest }) => ({
    position: "relative",
 
    borderRadius: "10px",
    backgroundColor: "#F2F6FA",
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
  
    marginLeft: 20,
    width:rest.Match && !rest.Sec ?"150px":rest.Sec?"110px":"300px",
    height:'40px'
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 34),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "lightgray",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(0)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        width: "28ch",
      },
    },
  }));





function SearchBar() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd: 844,
        md: 900,
        fmd: 950,
        slg: 1000,
        lg: 1200,
        sxl: 1430,
        xl: 1836,
      },
    },
  });


  const isMatch = useMediaQuery(theme.breakpoints.down("slg")); //sets true below breakpoint
  const isSecMatch = useMediaQuery(theme.breakpoints.down("smd")); //sets true below breakpoint


 return (
   
    <Search Sec={isSecMatch} Match={isMatch}>
        <Stack direction='row' display='revert-layer'  justifyContent="space-between"  >

        <SearchIconWrapper>
       {!isMatch ? <img src={searchLens} alt='lens'/> : null }  
          </SearchIconWrapper>
         
          <StyledInputBase
         
            placeholder={"Search"}
            inputProps={{ "aria-label": "search" }}
          />

        </Stack>
          
     
    
        </Search>


  )
}

export default SearchBar
