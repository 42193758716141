

import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectUpdate({ sideLabel, Data,onMultiSelect, currentSelect, isError }) {

 


  const [selectedData, setSelectedData] = useState([]);
  
  

useEffect(()=>{
setSelectedData(currentSelect)
},[currentSelect])

  // Store selected data objects (id and name)

  useEffect(()=>{

    onMultiSelect(sideLabel,selectedData)

  },[selectedData])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Update the selectedData array with the id and name of the selected items
    setSelectedData(
      value?.map((selectedItemName) => {
        const selectedItem = Data.find((item) => item?.name === selectedItemName);
        return selectedItem; // Object containing both id and name
      })
    );
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
      <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
        <Select
        error={isError}
          sx={{ borderRadius: "4px", minHeight: "46px" }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          displayEmpty
          value={selectedData?.map((item) => item?.name)} // Display selected names in the Select
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => {
            if (!selected?.[0]) {
              return <Typography sx={{ opacity: "50%" }}>Select</Typography>;
            } else
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>select</em>
          </MenuItem>
          {Data?.map((ty) => (
            <MenuItem key={ty?.id} value={ty?.name}>
              {ty?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultipleSelectUpdate;