import { Box, Typography } from '@mui/material'
import React from 'react'

function AccountInfoCard() {
  return (
<Box sx={{height:"400px" , width:"35%", backgroundColor:"white" , borderRadius:"6px" }}>
<Box p={1.5} height={"50px"} width={"100%"} sx={{backgroundColor:"lightgrey"}} >
<Typography  variant='p'>Account Information</Typography>
</Box>
<Box pt={5}>
<Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Joined On</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >10/12/2023</Typography>
   </Box>
<Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Last Login</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >20/05/26</Typography>
   </Box>
   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Role</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >Super Admin</Typography>
   </Box>
   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Account Email</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >kane@gmail.com</Typography>
   </Box>
   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Gender</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >Male</Typography>
   </Box>
   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption'>Profile Id</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >789644</Typography>
   </Box>
   </Box>
</Box>
  )
}

export default AccountInfoCard
