import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

export default function CustomSelectBar({Selectdata,initialText,handleSelection,clearState}) {
  const [names,setNames] = React.useState([])

  React.useEffect(()=>{
    if(Selectdata){
   setNames(Selectdata)
    }

  },[Selectdata])
  // const names = Selectdata;
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(initialText); // Change to single-value state

  React.useEffect(()=>{
    if(clearState){
      setPersonName(initialText)
    }

  },[clearState])

  console.log(typeof personName,"<---persone nameessssss")

React.useEffect(()=>{

  if(personName == '' || personName == 'Year'|| personName == 'Month' || personName == undefined || personName == 'Week' || personName == 'Days' ){
    console.log("hmmmmmmmmmmmmmmm")
  }else if(personName?.length > 2 || typeof personName === 'number' ){
    handleSelection(personName,initialText)
    console.log("donwmnnn ")
  }

},[personName])
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value); // Set the selected value directly
  };

  return (
    <div>
      <FormControl sx={{  width: 120,  }}>
        <Select
        sx={{height:"24px",borderRadius:"8px"}}
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput size='small' />}
          renderValue={(selected) => {
            if (!selected) {
              return <em>Placeholder</em>;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* <MenuItem disabled value="">
            <em>Placeholder</em>
          </MenuItem> */}
          {names?.map((name) => (
            <MenuItem
         
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
