import React from 'react'
import { TextField } from '@material-ui/core'
import { FormControl, FormLabel,Stack, Typography } from '@mui/material'

function TextAreaUpdate({sideLabel,placeHolder,InChange,isError,ErrNote,Value,disable,Type,Note,notRequired}) {
  return (
    <FormControl
    required
    component="fieldset"
    sx={{ width: "100%"}}
    variant="standard"
  >
      <Stack direction={'row'}>
      <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
     {!notRequired && <Typography color={'red'}>*</Typography>}
      </Stack>
   
      <TextField
      multiline
      variant='outlined'
      maxRows={10}
      type={Type && Type}
      disabled={disable}
      value={Value}
      error={isError}
      helperText={isError ? ErrNote : null}
      onChange={(e) => InChange(e)}
      sx={{
       borderRadius: '4px',
       height: '46px',
       "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
       WebkitAppearance: "none",
       margin: 0,
  },
}}
fullWidth
placeholder={placeHolder}
/>
   {<Typography variant="caption" color={'red'}>{Note}</Typography>}
  </FormControl>
  )
}

export default TextAreaUpdate
