import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import ClearAllIcon from '@mui/icons-material/ClearAll';

function PaymentFilter({CloseModal,FilterColumn}) {

  const [isClear,setIsClear] = useState(false)
    const handleMultipleSelect = (label,data)=>{

        if(label === 'App Type'){
            
          let filteredData = data?.map((item)=>{
            return item?.name
          })
      
       FilterColumn(filteredData,"")
      
        }else if(label === 'Method'){

          let filteredData = data?.map((item)=>{
            return item?.name
          })

          FilterColumn(filteredData,"")
        }
      
      }

      const appData = [
        {name:   "DriverApp"},
        {name:  "UserApp"},
           ]  

           const methordData = [
            {name:   "DEBIT"},
            {name:  "CREDIT"},
               ] 


               const HandleClearFilter =()=>{
                setIsClear((i)=> !i)
              }

  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={daysData} fieldName={'Days'}/> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"App Type"} Data={appData} />
    </Box>
    
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={allZoneName} fieldName={'Zone'} /> */}
    <Box width={'250px'}>
    <MultipleSelect clearStatus={isClear} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Method"} Data={methordData} />
    </Box>
  

    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
    </Stack>
    
  </Box>
  )
}

export default PaymentFilter
