import { Box, Stack } from '@mui/material'
import React from 'react'
import FormNav from '../Forms/FormNav'
import CouponForm from './CouponForm'

function AddCoupon() {
  return (
   <Box>
    <Stack spacing={4}>

    <FormNav title={'Add Coupon Code'}/>
    <CouponForm/>

    </Stack>
    

   </Box>
  )
}

export default AddCoupon
