import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import StarIcon from '@mui/icons-material/Star';

function UserDriverDetailsCard({CardTitle,cardDeatails}) {
  return (
    <Box padding={3} borderRadius={'8px'} bgcolor={'white'} width={'500px'} height={'310px'}>
        <Stack spacing={5}>
        <Typography sx={{textTransform:"none",fontWeight:550}} variant='h5' >{CardTitle}</Typography>   

{/*ROW ONE*/}
    <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
        <Stack direction={'row'} spacing={2}>
    <Avatar src={ cardDeatails?.profile || 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'} sx={{ width: 110, height: 110 }}/>
    <Stack spacing={0.5}>
    <Typography fontWeight={550} variant='p'>{cardDeatails?.name}</Typography>
    <Typography sx={{opacity:"50%"}} variant='caption'>{ CardTitle === 'Driver Details' ? 'Driver':'Passenger'}</Typography>
    </Stack>
    </Stack>
    <Stack direction={'row'} spacing={1} >
    <Typography fontWeight={550} variant='p'>{parseInt(cardDeatails?.rating)||0}</Typography> 
    <StarIcon/>

    </Stack>
  
    </Box>

{/*ROW TWO*/}  

<Stack direction={'row'} spacing={4}>
    

<Stack spacing={0.5}>
<Typography sx={{opacity:"50%"}} variant='caption'>Email</Typography>
<Typography fontWeight={400} variant='body2'>{cardDeatails?.email}</Typography>
</Stack>

<Stack spacing={0.5}>
<Typography sx={{opacity:"50%"}} variant='caption'>Phone</Typography>
<Typography fontWeight={400} variant='body2'>{cardDeatails?.phone}</Typography>
</Stack>


</Stack>
        

        </Stack> 

    </Box>
  )
}

export default UserDriverDetailsCard
