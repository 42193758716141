import { Avatar, Box, Button, IconButton, Rating, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import emailIcon from '../../../../Assets/Forms/Visual.svg'
import phoneIcon from '../../../../Assets/Forms/Visual (1).svg'
import WarningSwitch from '../../../Components/Forms/WarningSwitch'
import FormModal from '../../../Components/Modal/FormModal'
import AddAmount from './AddAmount'
import { getBalance } from '../../../Components/MultiFunctions/MultiFunctions'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { toast } from 'react-toastify'
import { insertModalDataAction } from '../../../Redux/Actions/ModalActions'


function WalletHeadDetails() {
  const { id:driverId } = useParams(); 
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [modalStatus, setModalStatus] = useState(false);
  const [profileMainDetails,setProfileMainDetails] = useState({})
  const [walletBalance , setWalletBalance] = useState(0)
  const [deductStatus , setDeductStatus] = useState(false)
  const [modalData,setModalData] = useState({})
  const dispatch = useDispatch()
  const {currentModaldata} = useSelector((state) => state.modal)

  const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Wallet Details','UPDATE')
      setEdit(isEdit)
      
  
       },[])
  

    const theme = createTheme({
        breakpoints: {
          values: {
            ht: 1268,
        
          },
        },
      });
      const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));

      useEffect(()=>{
       
        (async () => {
          try {
           let currentBalance = await getBalance(driverId);
           setWalletBalance(currentBalance?.balance)
           console.log(currentBalance,"<BBBBBBBBBBBBBBIII")
            // console.log(allBalances,"<---resultsssss herreeeeee")
            // Handle the data here
          } catch (error) {
            // Handle any errors that occurred during the API call
            console.error(error);
          }
        })();
    

       },[])


      useEffect(()=>{
        ApiCall("GET", `/trip-service/v1/admin/driver/details/${driverId}`, null, "uui").then((data)=>{
          if(data?.result){
            const driverDetails  = data.result?.[0]
            console.log(driverDetails,"<---the driver detailsssdsdss")

            let profileData = {
              driverEmail:driverDetails?.Email,
              driverPhone:driverDetails?.PhoneNumber,
              driverProfileImage:driverDetails?.DriverProfilePicPath,
              driverRating:driverDetails?.DriverRating?.TotalRating,
              driverReview:driverDetails?.DriverRating?.TotalReviews,
              driverName:driverDetails?.FirstName +' '+ driverDetails?.LastName,
     
             }

             setProfileMainDetails(profileData)
            }
        })
      },[])


      const handleAddAmount =(status)=>{
        status && setDeductStatus(true)
         let modalMainData = {
     
           driverId:driverId,
           countryId: CountryID,
           amount: 0
         }
     
         setModalData(modalMainData)
         setModalStatus(true);

       }
    
      const onClose = (isFlag) => {
        if(isFlag){
          dispatch(insertModalDataAction(!currentModaldata))
          toast.success("Transaction success");
        }
        setModalStatus(false);
        setDeductStatus(false)
      };




  return (
    <>
<FormModal Type={'Small'} handleclose={onClose} status={modalStatus} component={<AddAmount isDuduct={deductStatus} Success={onClose} sendData={modalData}/>} />
<Box mt={2} flexDirection={isMatch ? 'column' : 'row'} borderRadius={'8px'} width={'100%'} height={isMatch ? '360px':'180px'} bgcolor={'white'} display={'flex'} justifyContent={'space-between'}>

{/* BOX ONE */}
    <Box display={'flex'} justifyContent={'space-between'} width={'30%'} >
    <Stack justifyContent={'center'} padding={3} spacing={3}>
    <Stack direction={'row'} spacing={3}>
    <Avatar src={profileMainDetails?.driverProfileImage} sx={{ width: 64, height: 64 }}/>
    <Stack spacing={0.3}>
    <Typography fontWeight={'bold'} variant='p'>{profileMainDetails?.driverName}</Typography>
    <Typography variant='caption'>Driver</Typography>
    </Stack>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={1} >
 <Typography fontWeight={'bold'} variant='p'>{parseInt((profileMainDetails?.driverRating/100)*5) || 0}</Typography>
 <Rating name="read-only" value={parseInt((profileMainDetails?.driverRating/100)*5)|| 0} readOnly />
 <Typography variant='caption'>{`${profileMainDetails?.driverReview || 0} reviews`}</Typography>
 </Stack>
    {/* <IconButton> <img alt='edit' src={editIcon}/> </IconButton> */}
 
     </Stack>



     <Stack justifyContent={'center'} padding={3} spacing={3}>


     <Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='email' src={emailIcon}/> </IconButton>
<Stack spacing={0.2}>
<Typography variant='caption'>Email</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileMainDetails?.driverEmail}</Typography>
</Stack>
</Stack>



<Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='phone' src={phoneIcon}/> </IconButton>
  <Stack spacing={0.2}>
<Typography variant='caption'>Phone</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileMainDetails?.driverPhone}</Typography>
</Stack>
</Stack>

     </Stack>
</Box>


{/* BOX TWO */}
  <Box  display={'flex'} justifyContent={'space-between'} width={'50%'} >
  <Stack justifyContent={'center'} padding={3} spacing={3}>
  <Typography fontWeight={'bold'} variant='p'>Wallet Balance</Typography>
  <Typography fontWeight={'bold'} variant='h4'>{`S$ ${(walletBalance/100)?.toFixed(2)}`}</Typography>
  </Stack>
  <Stack justifyContent={'center'} padding={3} spacing={3}>
  <Stack direction={'row'} spacing={2}>
  <Button disabled={!edit} size='small'  fullWidth onClick={() => handleAddAmount(false)} sx={{textTransform:"none",height:'45px',width:"150px",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Add Amount</Button>
  <Button disabled={!edit} size='small' fullWidth onClick={() => handleAddAmount(true)} sx={{textTransform:"none",height:'45px',width:"150px",backgroundColor:"#D3D3D9",color:"black",borderRadius:"6px"}} >Deduct Amount</Button>
 </Stack>
 {/* <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'}  width={'100%'} sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
<Typography sx={{ opacity: "50%" }} variant="caption">Disable Wallet</Typography>
<WarningSwitch/>
</Box> */}
 </Stack>
  </Box>    

</Box>
</>
  )
}

export default WalletHeadDetails
