import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashProgressBars from '../../../Components/Graphs/DashProgressBar'
import { useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'

function DriverRegistrationOverview() {
  const [fromDate , setFromDate] = useState('2020-01-10T10:10:05.833Z')
  const [toDate , setToDate] = useState('2030-01-10T10:10:05.833Z')
  const [mainData , setMainData] = useState({})
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const {currentDashdata} = useSelector((state) => state.dashboard)

  useEffect(()=>{

    if(currentDashdata?.status !== 'Year'){
      setFromDate(currentDashdata?.startDate)
      setToDate(currentDashdata?.endDate)
    }else{
      setFromDate('2020-01-10T10:10:05.833Z')
      setToDate('2030-01-10T10:10:05.833Z')
    }

  },[currentDashdata])


  useEffect(()=>{

    ApiCall("GET", `/trip-service/v1/admin/dashboard/all/driver/reg-details/?countryId=${CountryID}&&startDate=${fromDate}&&endDate=${toDate}`, null).then((data)=>{
      if(data.status === 'success'){
       const rowData = data?.result;
        console.log(rowData,"<###$$477878778787878")
         setMainData(rowData)
      }

    }).catch((error) => {
      console.log(error,"<---the errrorr")
   });

  },[fromDate,toDate])



  return (
<Box p={2} borderRadius={'8px'} bgcolor={'white'} width={'517px'} height={'250px'}>
<Typography sx={{fontSize:"18px"}} variant='body1' >Drivers registrations</Typography>

{/* ONE */}
     <Stack>
<Box p={1}  mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='body1' >New</Typography>
     <Typography sx={{fontWeight:600}} variant='body1'>{mainData?.newDrivers}</Typography>
   </Box>
   <Box pl={1}    display={'flex'} justifyContent={'space-between'}>
   <DashProgressBars barValue={mainData?.newDrivers} barColor={'#54D14D'}/>
   </Box>
   </Stack>
{/* TWO */}
   <Stack>
<Box p={1}  mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='body1' >Pending</Typography>
     <Typography sx={{fontWeight:600}} variant='body1'>{mainData?.pendingDrivers}</Typography>
   </Box>
   <Box pl={1}    display={'flex'} justifyContent={'space-between'}>
   <DashProgressBars barValue={mainData?.pendingDrivers} barColor={'#EF7E5D'}/>
   </Box>
   </Stack>
 {/* THREE   */}
 <Stack>
<Box p={1}  mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='body1' >Approved</Typography>
     <Typography sx={{fontWeight:600}} variant='body1'>{mainData?.approvedDrivers}</Typography>
   </Box>
   <Box pl={1}    display={'flex'} justifyContent={'space-between'}>
   <DashProgressBars barValue={mainData?.approvedDrivers} barColor={'#35C2FD'}/>
   </Box>
   </Stack>


</Box>
  )
}

export default DriverRegistrationOverview
