import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";

function Tabs({ List, component,current }) {
  
  const [value, setValue] = useState(current || "Personal Details");

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <Box
   
      marginTop="10px"
      sx={{ width: "100%", typography: "body1" }}
    >
      <TabContext value={value}>
        <Box>
          <TabList
            variant="scrollable"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {List.map((eachItem) => (
              <Tab
                sx={{ textTransform: "inherit" }}
                label={eachItem.name}
                value={eachItem.value}
              />
            ))}
          </TabList>
        </Box>
        {component?.map((eachItem, index) => (
          <TabPanel key={index} value={eachItem?.value}>
            {eachItem?.page}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

export default Tabs;
