import { createBrowserRouter } from "react-router-dom";
import { wrappedRouterList } from "./RouteLists";
import Signin from "../Sessions/Signin";








export const routerList = createBrowserRouter([...wrappedRouterList]);

// export const protectedRoute = createBrowserRouter([{path:'/signin',element: <Signin/>}]);
