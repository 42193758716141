import { userPersonalConstants } from "../Constants/Constants"; 

const initialUserPersonalState = {
    currentUserPersonaldata: {},
};

export const UserPersonalReducer = (state = initialUserPersonalState, action) => {
    switch (action.type) {
        case userPersonalConstants.INSERT_USERPERSONAL_DATA:
            return { ...state, currentUserPersonaldata: action.payload };
        default:
            return state;
    }
};