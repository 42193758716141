import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'

function SelectTabButtons({ActiveButton,OnChange}) {

const Active = {
    backgroundColor:'#EBF2FD',
    color:"#000000",
    border:'none'
}

const NonActive = {
    color:"#c6c6c6",borderColor:'#c6c6c6'
}

  return (
  <Stack spacing={1} >
  <Typography>Select peak hours</Typography>
 <Stack direction={'row'} spacing={2} >
 <Button onClick={() => OnChange('6 AM - 7 AM')} sx={ActiveButton === '6 AM - 7 AM' ? Active : NonActive } variant="outlined" size="small">6 AM - 7 AM</Button>
  <Button onClick={() => OnChange('7 AM - 8 AM')} sx={ActiveButton === '7 AM - 8 AM' ? Active : NonActive } variant="outlined" size="small">7 AM - 8 AM</Button>
  <Button onClick={() => OnChange('8 AM - 9 AM')} sx={ActiveButton === '8 AM - 9 AM' ? Active : NonActive } variant="outlined" size="small">8 AM - 9 AM</Button>
  <Button onClick={() => OnChange('9 AM - 10 AM')} sx={ActiveButton === '9 AM - 10 AM' ? Active : NonActive } variant="outlined" size="small">9 AM - 10 AM</Button>
 </Stack>


  </Stack>
  )
}

export default SelectTabButtons
