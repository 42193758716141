import { Box, Button, Chip, FormControl, FormLabel, Grid, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../Components/Forms/InputField'
import PasswordField from '../../Components/Forms/PasswordField'
import SelectField from '../../Components/Forms/SelectField'
import { PH_CODE } from '../../FakeData/FakeData'
import ImageSelector from '../../Components/Forms/ImageSelector'
import SnackBars from '../../Components/Forms/SnackBars'
import ErrorNotify from '../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../Components/Forms/FormSubmitButtons'
import AddIcon from '@mui/icons-material/Add';
import ApiCall from '../../../Helper/ApiCalls'
import { last } from 'lodash-es'
import { Email } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../Sessions/Permisssions'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import addNotification from 'react-push-notification';

function AddAdmin() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,

      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
  const [imageFile , setImageFile] = useState({})
  const [firstName,setFirstName] = useState('')
  const [middleName,setMiddleName] = useState('')
  const [lastName,setLastName] = useState('')
  const [selectedCode , setSelectedCode] = useState('')
  const [phoneNumber,setPhoneNumber] = useState('')
  const [designation , setDesignation] = useState('')
  const [password,setPassword] = useState('')
  const [email,setEmail]= useState('')
  const [confirmPassword,setConfirmPassword] = useState('')
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [submitHit,setSubmitHit] = useState(false)
  const [onSuccess , setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)
  const [selectedChip, setSelectedChip] = useState([]);
  const [adOnFeatures , setAdOnFeatures] = useState([])
  const [featureData , setFreatureData] = useState([])
  const [selectedCountry , setSelectedCountry] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const {currentUserdata} = useSelector((state) => state.user)
  const currentCountries = currentUserdata?.CountryIDS


  console.log(selectedChip, "<--selected chipps")
  console.log(featureData,",---fatureee")

  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Employee','CREATE')
    setWrite(isWrite)

     },[])




// FUNCTIONS
const handleImageFiles = (files)=>{
  setImageFile(files)
  }



  useEffect(()=>{
    ApiCall("GET", "/user-service/v1/admin/employee/roles", null,).then((data)=>{
      if(data.result){
        let filteredRowData
           console.log(selectedCountry,"<----~(&(")
                  const rowData = data?.result
                  if(selectedCountry?.length > 0){
                    filteredRowData = rowData?.filter((eachRow) => selectedCountry?.includes(eachRow?.CountryID))
                  }else{
                    filteredRowData = rowData?.filter((eachRow) => currentCountries?.includes(eachRow?.CountryID))
                  }
           const filteredData = filteredRowData?.map((eachData)=>{
                 return {name:eachData?.Name,id:eachData?.ID,countryId:eachData?.CountryID}
                                                              })

                  console.log(filteredData,"#===#===#")
      
                  setAdOnFeatures(filteredData)
              }

    })
  },[selectedCountry])







  const handleFormSubmit =()=>{
    setSubmitHit(true)
    const roleIds = featureData?.map(item => item.id).join(',')
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('middleName', middleName);
    formData.append('lastName', lastName);
    formData.append('phoneNumber',selectedCode+phoneNumber);
    formData.append('email', email);
    formData.append('designation', designation);
    password === confirmPassword && formData.append('password', password);
    formData.append('roleIds', roleIds);
    formData.append('adminProfileImage', imageFile?.file);
    selectedCountry?.length > 0 ?  selectedCountry.forEach((Country, index) => {
      formData.append(`countryIds[${index}]`,parseInt(Country));
    }) : currentCountries.forEach((Country, index) => {
      formData.append(`countryIds[${index}]`,parseInt(Country));
    })
    // formData.append('countryId', selectedCountry?.length > 0 ? selectedCountry : currentCountries);


    ApiCall("POST", "/user-service/v1/admin/employee/create", formData).then((data)=>{
      if(data.status === 'success'){
        const resData = data?.result
        if(data?.result){
          const link = data?.result?.resetLink
          console.log(link , "<----link ")
           
          addNotification({
            title: 'Created Successfully',
            subtitle: 'password reset link for employee',
            message: link,
            duration: 1000000,
            theme: 'darkblue',
            native: true 
        });

        setOnSuccess((i) => !i )
        setOnApiSuccess("Succesfully created employee")

        }
        console.log(resData , "<---Response datasss")
   
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
    
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });
     
  }

  const handleAddChip = (chipName) => {
    console.log(chipName,"<--->")
    if (!selectedChip.includes(chipName)) {
        setSelectedChip([...selectedChip, chipName?.name]);
        setFreatureData([...featureData, chipName])
      }
  };


  
const handleSelect = (label,data)=>{
          
  if(label === 'Code'){
         setSelectedCode(data?.name)
  }


}



  const handleDeleteChip = (chipName) => {
    setSelectedChip(
       selectedChip.filter((each) => each !== chipName)
    )
    setFreatureData(featureData.filter((each) => each.name !== chipName ))
 };

  const chipAdornment = selectedChip ? (
    <InputAdornment position="start">
      {featureData.map(eachItem => (
        <Chip sx={{bgcolor:eachItem?.countryId === 2 ? '#DEEAED' : '#fab1a0'}}  label={eachItem?.name} onDelete={() => handleDeleteChip(eachItem?.name)} />
      ))}
    </InputAdornment>
      ) : null;





      const handleMultipleSelect = (label,data)=>{

        if(label === 'Country'){          
          let filteredData = data?.map((item)=>{
            return item?.id
          })
      
          setSelectedCountry(filteredData)
      
        }
      
      }






  return (
<Box>

<Stack spacing={2}>

    {/* <ImageSelector/> */}
    <Box width={isMatch?'100%':'32.4%'}>
    <ImageSelector isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} />
    </Box>

        <Stack direction={'row'} spacing={3} >

        <InputField InChange={(e) => setFirstName(e.target.value)} sideLabel={"First Name"} placeHolder={"First Name "} />
         
        <InputField InChange={(e) => setMiddleName(e.target.value)} sideLabel={"Middle Name"} placeHolder={"Middle Name"} />
        
        <InputField InChange={(e) => setLastName(e.target.value)} sideLabel={"Last Name"} placeHolder={"Last Name"} />
        </Stack>

       
        <Stack direction={'row'} spacing={3}>
        <Grid container>
            <Grid item xs={3} >
            <SelectField
              onSelecting={handleSelect}
              sideLabel={"Code"}
              placeHolder={"select"}
              Data={PH_CODE}
            />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={8.5} >
            <InputField InChange={(e) => setPhoneNumber(e.target.value)} sideLabel={"Phone"} placeHolder={"Phone"} />
            </Grid>

           </Grid>

          
        <InputField InChange={(e) => setEmail(e.target.value)} sideLabel={"E-mail"} placeHolder={"Email"} />
          
        <InputField InChange={(e) => setDesignation(e.target.value)} sideLabel={"Designation"} placeHolder={"Designation"} />
        </Stack>



      <Stack width={'66%'}  direction={'row'} spacing={3}>
      <PasswordField isError={confirmPassword?.length > 0 && password !== confirmPassword} InChange={(e) => setPassword(e.target.value)} sideLabel={"Password"}/>
      <PasswordField isError={confirmPassword?.length > 0 && password !== confirmPassword} InChange={(e) => setConfirmPassword(e.target.value)} sideLabel={"Confirm Password"}/>     
      </Stack>
      <Stack width={'100%'}  direction={'row'} spacing={3}>
      <Box width={'32%'}>
          {currentCountries?.length > 1 &&
          <MultipleSelect isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Country"} Data={[{name:'India',id:2},{name:"Singapore",id:1}].filter(country => currentCountries.includes(country.id))} />
          }
          </Box>
          </Stack>
        {/* readOnly input field */}

        <Stack>
        <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Privileges</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
       
        <OutlinedInput
        error={submitHit && selectedChip.length < 1 }
    readOnly
    sx={{ borderRadius: '4px', height: '46px' }}
    fullWidth
    placeholder={selectedChip.length < 1 && "select"}
    startAdornment={chipAdornment}
  />
      </FormControl>
        </Stack>





     <Stack alignItems={'left'}>

     <Button  sx={{width:'15px',color:'#636e72',marginLeft:'10px'}} size='small'>SINGAPORE</Button>
        <Box
      width="100%"
      height="100px"
      sx={{
        overflowX: 'auto', // Enable horizontal scroll
       // Prevent buttons from wrapping to the next line
      }}
    >
        
            {
              adOnFeatures ? 
                adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && eachFeature?.countryId === 1 && (

                    <Button   
                    onClick={() => handleAddChip(eachFeature)}
                    sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',maxWidth:"100ox",marginRight: '8px',marginTop:"8px",
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#0B2863',
                      },
                    }}
                    variant="contained"
                    startIcon={<AddIcon />}
                  >
                {eachFeature?.name}
                  </Button>

                )) : 
                (
                    <Typography color={'red'}>No roles available</Typography>
                )
            }
 </Box>
 </Stack>

 <Stack alignItems={'left'}>
<Button  sx={{width:'15px',color:'#636e72'}} size='small'>INDIA</Button>

 <Box
      width="100%"
      height="100px"
      sx={{
        overflowX: 'auto', // Enable horizontal scroll
       // Prevent buttons from wrapping to the next line
      }}
    >
    
        
     

{
              adOnFeatures ? 
                adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && eachFeature?.countryId === 2 &&(

                    <Button   
                    onClick={() => handleAddChip(eachFeature)}
                    sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',maxWidth:"100ox",marginRight: '8px',marginTop:"8px",
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#0B2863',
                      },
                    }}
                    variant="contained"
                    startIcon={<AddIcon />}
                  >
                {eachFeature?.name}
                  </Button>

                )) : 
                (
                    <Typography color={'red'}>No Features available</Typography>
                )
            }
 </Box>
 </Stack>

      



    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />   
    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />

</Stack>
</Box>
  )
}

export default AddAdmin
