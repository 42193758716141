import { Box, Grid, Stack, createTheme, useMediaQuery } from '@mui/material';
import React from 'react'
import FormSubmitButtons from '../Forms/FormSubmitButtons';
import InputField from '../Forms/InputField';
import SelectField from '../Forms/SelectField';
import { couponType } from "../../FakeData/FakeData";

function DropOffForm() {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd:855,
            md: 900,
            fmd:950,
            slg:1098,
            lg: 1200,
            sxl: 1430,
            xl: 1836,
          },
        },
      });
      
      const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl")); 
  return (
    <Box >
      
    <Box overflow='auto' height={isXlMatch ? "25vh" : '40vh'}  >  
      <Stack spacing={3}>
        <Grid container>
          <Grid xs={5.75} item>
          <SelectField
              sideLabel={"Type"}
              placeHolder={"select"}
              Data={couponType}

            />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
          <InputField sideLabel={"Amount"} placeHolder={"Amount"} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} item>
            <InputField sideLabel={"Remarks"} placeHolder={"Remarks"} />
          </Grid>
        
        </Grid>

     


     

      </Stack>
    </Box>
    <Box paddingTop={5} >
      <FormSubmitButtons/>
    </Box>
    </Box>
  )
}

export default DropOffForm
