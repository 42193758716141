import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Nodatafound from '../../../../../Components/NoDataFound/Nodatafound';

function Remarks({RemarkData}) {
  let totalItems = RemarkData?.length
  return (
  <Box width={'35%'}  height={'500px'}
  sx={{overflowY:'scroll', '&::-webkit-scrollbar': {
    width: '5px',  // Adjust the width as needed
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'lightgray',  // Change the thumb color
    borderRadius: '5px',     // Add rounded corners to the thumb
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',  // Change the track color
  },
  scrollbarWidth: 'thin',  // Firefox scrollbar width
  scrollbarColor: 'lightgray transparent',}}
  
  >

<Stack spacing={3}>
    <Box  width={'100%'}>
    <Timeline position='left' >
     

    {totalItems ? 
RemarkData?.map((eachData,index)=>(

<TimelineItem>
<TimelineSeparator>
  <TimelineDot color="success">
    <AdsClickIcon fontSize='small'/>
  </TimelineDot>
 {index+1 < totalItems && <TimelineConnector />}
</TimelineSeparator>
<TimelineContent>

<Box sx={{ bgcolor: '#ecf0f1', width: '200px', height: 'auto', maxHeight: '300px', borderRadius: '6px' }}>
<Box p={1} display={'flex'} style={{ height: '30px', width: '100%', borderBottom: '1px solid #ccc' }}>
<Typography fontWeight={'bold'} variant='caption'>{eachData?.Title}</Typography>
</Box>

<Stack display={'flex'} alignItems={'flex-start'} p={1} spacing={1}>
<Typography  fontSize={'10px'}>{eachData?.Desc}</Typography> 
<Stack  spacing={0.5}>

    <Stack direction={'row'} spacing={0.5}>
    <Typography fontSize={'10px'}>{`Remarks:`}</Typography> 
    <Typography fontSize={'10px'}>{eachData?.Remark}</Typography> 
    </Stack>

    <Stack direction={'row'} spacing={0.5}>
    <Typography fontSize={'10px'}>{`Date :`}</Typography> 
    <Typography fontSize={'10px'}>{eachData?.Date}</Typography> 
    </Stack>
    
    <Stack direction={'row'} spacing={0.5}>
    <Typography fontSize={'10px'}>{`Time :`}</Typography> 
    <Typography fontSize={'10px'}>{eachData?.Time}</Typography> 
    </Stack>
   
</Stack>
</Stack>
</Box>


 
  </TimelineContent>
</TimelineItem>

)) : <Nodatafound Title={'No Data Found'}/>

}

      
    </Timeline>  
    </Box>
    </Stack>

  </Box>
  )
}

export default Remarks