import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'

import closeIcon from '../../../Assets/Table/Group 4336.svg'
import SelectField from '../../Components/Forms/SelectField';
import ClearAllIcon from '@mui/icons-material/ClearAll';

const methods = [
  { name: "ONDEMAND", id: "ONDEMAND" },
  { name: "SCHEDULED", id: "SCHEDULED" },
  { name: "CARPOOL", id: "CARPOOL" },
  { name: "CARPOOLSCHEDULE", id: "CARPOOLSCHEDULE" },
];
const methodsTwo = [
  { name: "SCHEDULED", id: "SCHEDULED" },
  { name: "GOINGTOPICKUP", id: "GOINGTOPICKUP" },
  { name: "STARTED", id: "STARTED" },
  { name: "HOLD", id: "HOLD" },
  { name: "COMPLETED", id: "COMPLETED" },
  { name: "CANCELLED", id: "CANCELLED" },
];

function RideFilter({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)

 


      const handleSelect = (label,data)=>{

        if(label === 'Ride Type'){   
         data?.id &&  FilterColumn(data?.id,"rideType") 
        }
        if(label === 'Ride Status'){
          data?.id &&  FilterColumn(data?.id,"rideStatus") 
        }
      }

      const HandleClearFilter =()=>{
        setIsClear((i)=> !i)
      }



  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
        
    <Box pb={2} width={'200px'}>
    <SelectField clearStatus={isClear}  isError={false} onSelecting={handleSelect} Data={methods} sideLabel={'Ride Type'} placeHolder={'select'} />
    </Box>

    <Box pb={2} width={'200px'}>
    <SelectField clearStatus={isClear}  isError={false} onSelecting={handleSelect} Data={methodsTwo} sideLabel={'Ride Status'} placeHolder={'select'} />
    </Box>

    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
      </Stack>
      
    </Box>
  )
}

export default RideFilter