import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';

import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import EditTipForm from './EditTipForm';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import { useNavigate } from 'react-router-dom';







function EditTip() {
  const navigate = useNavigate()

  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Tip
      </Typography>,
 <Typography key="3" color="text.primary">
     Edit Tip
      </Typography>,
  ];


  useTitle("Edit Tip - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Tip')
    setAny(isAany)
     },[])


  return (
    any ? (
   <Box>
    <CreatePageHead  title={'Edit Tip'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
   {/* <CreateTipForm/> */}
   <EditTipForm/>
    </Box>
    </Box>
   </Box>
   ) : any === false ? (
    <RestrictedUsage/>
   ): null
  )
}

export default EditTip