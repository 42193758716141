import { Box} from '@mui/material'
import React from 'react'
import DetailsNav from '../../../Components/DetailsPage/DetailsNav'
import MapContainer from '../../../Components/Maps/MapContainer'
import Tabs from "../../../Components/Tabs/Tabs";
import UserWalletDetails from '../../Administration/UserDetails/UserWalletDetails';
import Booking from '../Booking';
import VehicleInfo from './VehicleInfo';

function BookingDetails() {
  const TabItems = [
    
    { name: "Booking Info", value: "Booking Info" },
    {
      name: "Payment Info",
      value: "Payment Info",
    },
    {
      name: "Customer Info",
      value: "Customer Info",
    },
    {
      name: "Driver Info",
      value: "Driver Info",
    },
    {
      name: "Vehicle Info",
      value: "Vehicle Info",
    },
    {
      name: "Tickets",
      value: "Tickets",
    },
  ];

  const Components = [
    {
      page: <Booking />,
      value: "Driver Info",
    },
    {
      page: <VehicleInfo />,
      value: "Vehicle Info",
    },
  ];



  return (
   <Box>
      <DetailsNav title={"Booking Details"} />
      <MapContainer/>
      <Box paddingTop={0.002} borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100vh'>

      <Tabs List={TabItems} component={Components} />

      </Box>
   
   </Box>
  )
}

export default BookingDetails
