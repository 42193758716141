import {
  Avatar,
  Box,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import userImage from "../../../../Assets/photo-1633332755192-727a05c4013d.jpeg";
import UserInfo from "./UserInfo";
import UserContactDetails from "./UserContactDetails";

function UserPersonalDetails() {

  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])


  return (
    loadingData ? (
      <UserPersonalDetails/>
    ):(
      <>
      <Box mt={2} ml={2} width="90%" height={"150px"}>
        <Stack direction="row" spacing={3}>
          <Avatar
            alt="Remy Sharp"
            src={userImage}
            sx={{ width: 60, height: 60 }}
          />

          <Stack pt={0.5} spacing={1}>
            <Typography fontWeight={500} variant="h7">Mateo Miguel</Typography>
            <Typography sx={{ opacity: "50%" }} variant="caption">Passenger</Typography>
        
          </Stack>
         


        </Stack>

        <Stack mt={2} spacing={2.5} direction={'row'}  >
              <Typography
                alignItems="center"
                justifyContent="center"
                variant="h7"
              >
                {" "}
                <strong>2.2</strong>{" "}
              </Typography>
              <Rating
                sx={{ marginLeft: "5px" }}
                name="half-rating-read"
                defaultValue={2.5}
                precision={0.5}
                readOnly
              />
              <Typography style={{textDecoration:'underline', cursor:'pointer' }} fontSize={'15px'} variant="h7">152 reviews</Typography>
            </Stack>
      </Box>
      <UserInfo/>
      <UserContactDetails/>
      {/* <Box width="400px" display="flex" justifyContent="space-between">
        <Stack spacing={3}>
          <Typography fontWeight={550}>Phone</Typography>
          <Typography fontWeight={550}>Email ID</Typography>
          <Typography fontWeight={550}>Gender</Typography>
          <Typography fontWeight={550}>Date of Birth</Typography>
          <Typography fontWeight={550}>Postal Code</Typography>
        </Stack>
        <Skeleton variant="rounded" width="5px" height="220px" />
        <Stack spacing={3}>
          <Typography fontWeight={550}>+65 9888 3883</Typography>
          <Typography fontWeight={550}>miguelmiguel@gmail.com</Typography>
          <Typography fontWeight={550}>Male</Typography>
          <Typography fontWeight={550}>15-05-1998</Typography>
          <Typography fontWeight={550}>N/A</Typography>
        </Stack>
      </Box> */}
    </>
    )
  );
}

export default UserPersonalDetails;
