import { Box } from '@mui/material'
import React, { useState } from 'react'
import TableNav from '../../Components/TableSearchExport/TableNav'
import CreateTab from '../../Components/Forms/CreateTab'
import FormModal from '../../Components/Modal/FormModal';
import CardCollection from '../../Components/Services/CardCollection';
import AddServices from '../../Components/Services/AddServices';

function Services() {
    const [modalStatus, setModalStatus] = useState(false);
    const onClick = () => {
        setModalStatus(true);
      };
    
      const onClose = () => {
        setModalStatus(false);
      };
    
  return (
    <Box>
    {/* <TableNav title={"Services"} /> */}
    <CreateTab nonTable={true}  handleClick={onClick} title={"Add Service"} />
    <FormModal
      handleclose={onClose}
      status={modalStatus}
      component={<AddServices />}
    />

    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
     <CardCollection/>
    </Box>
  </Box>
  )
}

export default Services
