import { Box, Button, IconButton,} from '@mui/material'
import React from 'react'
import createButton from '../../../Assets/Table/Frame 47916.svg'
import TableSearchBar from '../Forms/TableSearch'
import { useNavigate, useParams } from 'react-router-dom'
import AllZone from '../Forms/AllZone'
import { insertModalDataAction } from '../../Redux/Actions/ModalActions'
import { useDispatch, useSelector } from 'react-redux'
import { insertAllZoneDataAction } from '../../Redux/Actions/AllZoneActions'

function NewTableHead({handleSearch,pathLink,ButtonName,toggleStats,statusZone,inPopularClicked,buttonStatus}) {
  
const navigate = useNavigate()
const dispatch = useDispatch()
const paramsData = useParams()
const {currentAllZonedata} = useSelector((state) => state.allzone)



  const handleClick = ()=>{
    if(pathLink === '/create-popular'|| pathLink === '/export'){
      inPopularClicked()
      // dispatch(insertAllZoneDataAction(!currentAllZonedata))
    }else{
      navigate(pathLink)
    }
   
  }

  return (
 <Box width={ pathLink ? '500px' : '500px'}  padding={1} alignItems={'center'} display={'flex'} justifyContent={ButtonName ? 'space-between':'space-around'}>
      <TableSearchBar changeInSearchBar={handleSearch} />
      {
        toggleStats &&  <AllZone zoneDetailStatus={statusZone}  />
      }
     
   { ButtonName &&  <Button disabled={!buttonStatus}  sx={{backgroundColor: pathLink === '/create-popular' || pathLink ===  '/export' && '#021640',height:"44px",width:"170px",textTransform:"none",borderRadius:"4px",marginLeft:toggleStats && '10px'}} onClick={handleClick} variant="contained">{ButtonName}</Button>}

 </Box>
  )
}

export default NewTableHead
