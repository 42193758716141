import { Box, Stack, createTheme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectField from "../../../Components/Forms/SelectField";
import InputField from "../../../Components/Forms/InputField";
import SingleCard from "../../../Components/Services/SingleCard";
import { cardData } from "../../../FakeData/FakeData";
import SnackBars from "../../../Components/Forms/SnackBars";
import ErrorNotify from "../../../Components/Forms/ErrorNotify";
import FormSubmitButtons from "../../../Components/Forms/FormSubmitButtons";
import ApiCall from "../../../../Helper/ApiCalls";
import { processNestedObject } from "../../../Components/MultiFunctions/MultiFunctions";
import { useSelector } from "react-redux";
import { checkPrivilage } from "../../../../Sessions/Permisssions";

function AddRoleForm() {
  const [onSuccess, setOnSuccess] = useState(false);
  const [onFailed, setOnFailed] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [onApiSuccess, setOnApiSuccess] = useState("");
  const [onApiFailed, setOnApiFailed] = useState("");
  const [submitHit, setSubmitHit] = useState(false);
  const [allData, setAllData] = useState([]);
  const [cardDatas, setCardData] = useState({});
  const [AllCardData,setAllCardData] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Roles','CREATE')
    setWrite(isWrite)

     },[])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


  const updateCardData = (cardId, cardName, isChecked) => {
    console.log("CODE INSIDEEEEEE")
    setCardData((prevData) => ({
      ...prevData,
      [cardId]: {
        name: cardName,
        data: isChecked,
      },
    }));
  };


  useEffect(()=>{
    ApiCall("GET", `/user-service/v1/admin/role/module`, null, "uui").then((data)=>{
      if(data?.result){
        setAllCardData(data?.result)     
      }
    })
  },[])





const handleFormSubmit = () => {
setSubmitHit(true)

let SendData = {
  roleName:roleName,
  countryID:CountryID,
  description:description,
  rolePermission: processNestedObject(cardDatas)
}

ApiCall("POST", "/user-service/v1/admin/role/create",SendData).then((data)=>{
if(data.status === 'success'){
    setOnSuccess((i) => !i )
    setOnApiSuccess("Succesfully created role")  
  }
})
.catch((error) => {
 setOnFailed((i) => !i)

  if(error.message){
    setOnApiFailed(error.message)
  console.log(`error:${error}`)
  }else{
    setOnApiFailed("Something went wrong.!")
  }
});

console.log(SendData,"<---api Dataa")

};



  return (
    <Box>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <Stack direction={isMatch ? "column" : "row"} spacing={3}>
            <Box width={"30%"}>
              <InputField
                isError={false}
                ErrNote={""}
                InChange={(e) => setRoleName(e.target.value)}
                sideLabel={"Role Name"}
                placeHolder={"Role Name"}
              />
            </Box>
            <Box width={"70%"}>
              <InputField
                isError={false}
                ErrNote={""}
                InChange={(e) => setDescription(e.target.value)}
                sideLabel={"Description"}
                placeHolder={"Enter description"}
              />
            </Box>
          </Stack>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            {AllCardData?.map((eachItem, index) => (
              <SingleCard
                key={index}
                ID={eachItem?.ID}
                handleCardData={updateCardData}
                cardName={eachItem?.Name}
                cardImage={eachItem?.IconUrl}
              />
            ))}
          </Box>
        </Stack>
        {/* <AlertPopUp/> */}
        <SnackBars
          submitStatus={submitHit}
          successNote={onApiSuccess}
          Status={onSuccess}
        />
        <ErrorNotify
          submitStatus={submitHit}
          Status={submitHit && onFailed}
          errorNote={onApiFailed}
        />

        <FormSubmitButtons buttonStatus={write} handleSubmit={handleFormSubmit} />
      </Stack>
    </Box>
  );
}

export default AddRoleForm;
