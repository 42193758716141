import { Box} from '@mui/material'
import React, { useEffect, useState } from 'react'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import DataTable from '../../Components/Table/DataTable'
import RideFilter from '../RideServiceManagement/RideFilter'
import { useNavigate } from 'react-router-dom'
import TableHead from '../../Components/Table/TableHead';
import ApiCall from '../../../Helper/ApiCalls'
import { useTitle } from '../../../Hooks/UseTitle'
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions'
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    // {
    //   id: "partnerName",
    //   label: "Partner Name",
    //   minWidth: 100,
    //   align: "left",
    //   sort: false,
    // },
    {
      id: "insuranceName",
      label: "Insurance Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    // {
    //   id: "partnerType",
    //   label: "Partner Type",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    {
      id: "cotOne",
      label: "Cot One",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "cotTwo",
      label: "Cot Two",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "cotThree",
        label: "Cot Three",
        minWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "cotFour",
        label: "Cot Four",
        minWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "status",
        label: "Status",
        maxWidth: 170,
        align: "left",
        sort: false,
        title: {
          onTrue: true,
          onFalse: false,
        },
      },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];








function Insurance() {
  useTitle("Insurance - Geolah");
  const [rows, setRows] = useState();
  const [success, setSuccess] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const navigate = useNavigate()
  const [order, setOrder] = useState("ASC");
  const [any,setAny] = useState()
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)

  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()

  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [totalRows,setTotalRows] = useState(0);

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Insurance')
    setAny(isAany)
    let isWrite = checkPrivilage('Insurance','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Insurance','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Insurance','DELETE')
     setDeletePer(isDelete)
     },[CountryID])










// FUNCTION

useEffect(()=>{

  ApiCall("GET", `/trip-service/v1/admin/insurance/${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
      const insuranceData = data?.result
      setTotalRows(insuranceData?.length)

      console.log(insuranceData,"insuranceeeeeeeee")


      let filteredData = insuranceData?.slice(currentOffset, currentOffset + currentLimit)?.map((eachItem,index)=>{

        let CotTwoCost = eachItem?.Cot?.[1]?.cost === undefined ? 'N/A': eachItem?.Cot?.[1]?.cost
        let CotThreeCost = eachItem?.Cot?.[2]?.cost === undefined ? 'N/A': eachItem?.Cot?.[2]?.cost
        let CotFourCost = eachItem?.Cot?.[3]?.cost === undefined ? 'N/A': eachItem?.Cot?.[3]?.cost
        
        let items = {

          id:index+1,
          _id:eachItem._id,
          partnerName:eachItem?.PartnerID?.Name ,
          insuranceName:eachItem?.Name || "N/A",
          cotOne:eachItem?.Cot?.[0]?.cotname + "-" +eachItem?.Cot?.[0]?.cost || "N/A",
          cotTwo:eachItem?.Cot?.[1]?.cotname || "N/A" + "-" + CotTwoCost,
          cotThree:eachItem?.Cot?.[2]?.cotname || "N/A" + "-" +CotThreeCost,
          cotFour:eachItem?.Cot?.[3]?.cotname|| "N/A" + "-" +CotFourCost,
          status:eachItem?.IsEnabled,
         }

         return items

      })

      setRows(filteredData)
      setTableData(filteredData);
 
 
 } })
},[success,currentPage])




useEffect(()=>{
  setRows(
    tableData.filter((item) =>
    item?.partnerName?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.insuranceName?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.cotOne?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.cotTwo?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.cotThree?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.cotFour?.toLowerCase().includes(searchInput.toLowerCase()) 

      ))
   },[searchInput])



  const handleFilterBar = (status)=>{
    setIsFilterOn(status)
   }


  // update service status

const handleStatusChange =(id, newData)=>{
  //  console.log(`Switch with id ${id} changed. New data:`, newData);
   const paramsData = {
    tripInsuranceId: id,
    countryID:CountryID,
    status: newData?.status
  }

   ApiCall("PATCH", "/trip-service/v1/admin/insurance/update/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
}


// delete status  
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);                             
   setDeleteStatus(true)
   const paramsData ={tripInsuranceId:id,countryID:1}
   setDeleteData(paramsData)
                                
                               }
 
  const onDeleteYes =(yesData)=>{
   ApiCall("PATCH", "/trip-service/v1/admin/insurance/delete/details",yesData,'BODY').then((data)=>{
    if(data?.status === 'success'){
      setSuccess(data) 
      setDeleteStatus(false)
      toast.success("Successfully deleted.");
     }else{
      toast.error("Something went wrong.!");
     }
  })
                                }
  
  const onCloseNotification = ()=>{
     setDeleteStatus(false)
      }  
        
        const handleServiceEdit =(id,newData)=>{
        if(id){
          navigate(`/edit-insurance/${id}`)
        }
    }

    // handle search change

const handleChangeInSearch = (value)=>{
    setSearchInput(value)

   }



   const handleColumnFilter = (items, searchText) => {



    setSelectedItems(items); // Update selected filter items
  
    // If no items are selected and no search text, show all rows
    if (items.length === 0 && searchText === "") {
      setRows(tableData);
      return;
    }
  
   
  
    // Filter the rows based on the selected items and search text
    const filteredRows = tableData.filter((row) => {
      // Check if any of the selected items are present in the row's features
      const featuresMatch = items.every(
        (item) => row.features && row.features.includes(item)
      );
  
  
  
      // Check if the row matches any of the selected vehicleCategory items
      const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
  
      // Check if the row matches all of the selected vehicleType items
      const vehicleTypeMatch = items.every(
        (item) => row.vehicleType && row.vehicleType.includes(item)
      );
  
  
  
      // Check if the row contains the search text in any of its values
      const searchTextMatch = Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
  
      // Return true if any of the filters match the row, otherwise return false
      return (
        (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
        searchTextMatch
      );
    });
  
    // Update the filtered rows
    setRows(filteredRows);
  };

  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };



  const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)
    
  }

  return (
    any ? (
 <Box>
    <TableHead title={'Insurance'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
        DeletePermission={deletePer}
        ButtonPermission={write}
        EditPermission={edit} 
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-insurance'}
        ButtonLabel={'Create Insurance'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
    </Box>

</Box>
 </Box>
 ):any === false ? (
  <RestrictedUsage/>
 ): null
  )
}

export default Insurance
