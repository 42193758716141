import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

function PersonalCard() {
  const {currentUserdata} = useSelector((state) => state.user)
  return (
<Box sx={{bgcolor:"white", width:"40%", height:"500px",borderRadius:"6px"}}> 
<Stack spacing={2}>
    <Stack pt={2} alignItems={"center"} justifyContent={"center"} spacing={1.5}>
    <Avatar alt="Remy Sharp" src={"https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"} sx={{ width: 200, height: 200 }}/>
    <Stack alignItems={"center"} spacing={0.4}>
    <Typography fontWeight={'bold'} variant='p'>{currentUserdata?.FirstName +" "+currentUserdata?.LastName}</Typography>
    <Typography sx={{ opacity: "50%" }} variant="caption">{currentUserdata?.Designation}</Typography>
    </Stack>
    </Stack>

    <Stack pt={2} sx={{borderTop:"0.5px solid lightGrey"}} pl={2}  justifyContent={"center"} spacing={1.5}>
    <Stack spacing={0.4}>
    <Typography  variant='body2'>Email</Typography>
    <Typography sx={{ opacity: "50%" }} variant="caption">{currentUserdata?.Email}</Typography>
    </Stack>

    <Stack spacing={0.4}>
    <Typography  variant='body2'>Phone</Typography>
    <Typography sx={{ opacity: "50%" }} variant="caption">{currentUserdata?.PhoneNumber}</Typography>
    </Stack>

    <Stack spacing={0.4}>
    <Typography  variant='body2'>Profile Id</Typography>
    <Typography sx={{ opacity: "50%" }} variant="caption">{currentUserdata?.ID}</Typography>
    </Stack>
    </Stack>    

</Stack>
</Box>
  )
}

export default PersonalCard
