import { navigationConstants } from "../Constants/Constants"; 

const initialNavigationState = {
    currentNavigationdata: {},
};



export const NavigationReducer = (state = initialNavigationState, action) => {
    
    switch (action.type) {
        case navigationConstants.INSERT_NAVIGATION_DATA:
            return { ...state,currentNavigationdata: action.payload };
        default:
            return state;
    }
};