import React from 'react'
import { Box , Skeleton, Stack} from '@mui/material'


function DetailsSkeleton() {
  return (
    <Box marginTop={2}  p={2} borderRadius="4px" width="430px" height="160px" border={1} borderColor="grey.300"  >
    <Stack>
    <Skeleton width={"120px"} variant="text" sx={{ fontSize: '1rem' }} />
        <Box alignItems="center"  display="flex" justifyContent="space-between" >
        <Skeleton variant="rectangular" width={'190px'} height={'56px'} />
        <Skeleton variant="rectangular" width={'190px'} height={'56px'} />
            

       </Box>
       <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />
       <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />
    </Stack>

   </Box>
  )
}

export default DetailsSkeleton
