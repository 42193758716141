import React, { useState } from 'react'
import DataTable from '../../../Components/Table/DataTable'
// import VehicleInfoData from '../../../FakeData/MOCK_DATA 14.json'





const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },
  
    {
      id: "image",
      label: "Image",
      minWidth: 100,
      align: "left",
      sort: true,
    },
 
    {
      id: "maker",
      label: "Maker",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "model",
      label: "Model",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "service",
      label: "Service",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    {
      id: "color",
      label: "Color",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "no_of_seats",
      label: "No. of seats",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "child_seat",
      label: "Child Seat",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "pets",
      label: "Pets",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "booster_seat",
      label: "Booster Seat",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "bicycle",
      label: "Bicycle ",
      minWidth: 170,
      align: "left",
      sort: true,
    },
   
  ];



function VehicleInfo() {
    const [rows, setRows] = useState();
    const [order, setOrder] = useState("ASC");
    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };



  return (
    <DataTable
    columns={columns}
    tableData={rows}
    columnSorting={columnSortingFn}
  />
  )
}

export default VehicleInfo
