import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import TableHead from '../../Components/Table/TableHead';
import ToggleNav from '../../Components/Forms/ToggleNav';
import AddNewTicket from './AddNewTicket';

const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Tickets
      </Typography>,
 <Typography key="3" color="text.primary">
      Tickets List
      </Typography>,
 

  ];



function Tickets() {
    const [tab , setTab] = useState('left')

const tabRender = (alignment)=>{
  setTab(alignment)
}


  return (
  <Box>
<TableHead title={'Voucher'} BreadList={breadcrumbs} />
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100vh' >
<ToggleNav lName={'Ticket List'} rName={'New Ticket'} toggleChange={tabRender} /> 
<Box  sx={{ width: "100%", height: "100%"}} p={4}>
{
        tab === 'left' ?  
    null :  
    //   <AddNewVoucher/>
    <AddNewTicket/>
    


    }
    </Box>

</Box>
  </Box>

  )
}

export default Tickets
