import { Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormNav from '../../../Components/Forms/FormNav'
import InputField from '../../../Components/Forms/InputField'
import ApiCall from '../../../../Helper/ApiCalls'
import { toast } from 'react-toastify'

function AddAmount({sendData,Success,isDuduct}) {
    const [amount , setAmount] = useState('')
    const [driverDetails,setDriverDetails] = useState({})
    
  useEffect(()=>{
 setDriverDetails(sendData)
  },[sendData])

  const handleFormSubmit =()=>{

  let addData={
    driverId:parseInt(driverDetails?.driverId),
    countryId: driverDetails?.countryId,
    amount: parseFloat(amount),
    transactionType:'INTERNAL_TRANSFER'
  }
  const addUrl = "/pay-service/v1/admin/driver/add/amount"
  const deductUrl = "/pay-service/v1/admin/driver/transfer/geolah"

     ApiCall("POST",isDuduct ? deductUrl : addUrl, addData).then((data)=>{
      if(data?.status === 'success'){
       Success(true)
      }
  })
  .catch((error) => {
    //  setOnFailed((i) => !i)
    
      if(error.message){
        toast.error(error.message);
        console.log(error , "<----errrrrrr")
      }else{
        toast.error("Something went wrong.");
      }
  
  
  });
  }


  return (
    <Box>
<Stack spacing={4}>
<FormNav title={isDuduct ? 'Deduct Wallet Balance':'Add Wallet Balance'}/>
<Stack alignItems={'center'} direction={'row'} spacing={4}>
<Box width={'50%'}>
<InputField Type={'number'} isError={''} ErrNote={""} InChange={(e) => setAmount(e.target.value)}   sideLabel={isDuduct ? 'Amount to be deducted' :"Amount to be add"} placeHolder={"Amount"} />
</Box>
<Box pt={2} width={'30%'}>
<Button onClick={handleFormSubmit}  sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >{isDuduct? 'Deduct Amount':'Add Amount'}</Button>
</Box>
</Stack>


    </Stack>
    

   </Box>
  )
}

export default AddAmount
