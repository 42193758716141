import { userConstants } from "../Constants/Constants"; 

const initialUserState = {
    currentUserdata: {},
};

export const UserReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case userConstants.INSERT_USER_DATA:
            return { ...state, currentUserdata: action.payload };
        default:
            return state;
    }
};