import { Box, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../Components/Forms/SelectField';
import InputField from '../../../../Components/Forms/InputField';
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import ImageSelector from '../../../../Components/Forms/ImageSelector';
import SnackBars from '../../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrl } from '../../../../RequestMethord';
import ApiCall from '../../../../../Helper/ApiCalls';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import MultipleSelect from '../../../../Components/Forms/MultipleSelect';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function DrivingLicenseForm({onButtonClick,onSectionComplete}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
          },
        },
      });
    
    //  STATES AND VARIABLES 
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [submitHit,setSubmitHit] = useState(false)
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
    const {currentDriverPersonaldata} = useSelector((state) => state.driverPersonal)

    const[licenseFrontFile, setLicenseFrontFile] = useState({}) 
    const[licenseBackFile , setLicenseBackFile ] = useState({})
    const[vLicenseFrontFile, setVLicenseFrontFile] = useState({}) 
    const[vLicenseBackFile, setVLicenseBackFile] = useState({})

    const [licenseClassData , setLicenceClassData] = useState([])
    const [vLicenseClassData , setVlicenceClassData] = useState([])
    const [selectedIssueDate,setSelectedIssueDate] = useState('')
    const [selectedVid,setSelectedVid] = useState('')
    const [selectedLid,setSelectedLid] = useState([])
    const [vocationalLicenceNumber , setVocationalLicenceNumber] = useState('')
    const [licenceNumber , setLicenceNumber] = useState('')

    const [selectedLicenseType , setSelectedLicenseType] = useState('')
    const [selectedVLicenseType , setSelectedVLicenseType] = useState('')
    const [remarkDetails,setRemarkDetails] = useState('')
    const [vocationalIssueDate , setVocationalIssueDate ] = useState('')
    const [driverId,setDriverId] = useState(0)
    const VehicleName = localStorage.getItem("vehicleName");

//RENDERSS

useEffect(()=>{
  setDriverId(currentDriverPersonaldata?.driverId)
  },[currentDriverPersonaldata])

  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Drivers','CREATE')
    setWrite(isWrite)

     },[])

useEffect(() => {


  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']:currentDriverPersonaldata?.driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };





    const apiUrlTwo = `${baseUrl}/trip-service/v1/app/vehicle/license/class`;  
 
  
  
    axios.get(apiUrlTwo, config).then(response => {
   
     if(response?.data?.result){
      const classData = response?.data?.result
        let filtered = classData?.map((items)=>{
          return{id:items?._id,name:items?.Name}
        })

        setLicenceClassData(filtered)
     }
     
       })
       .catch(error => {
         console.error(error,"GET API ERROR");
       });



const apiUrlThree = `${baseUrl}/trip-service/v1/app/vehicle/vocational/license`;  


axios.get(apiUrlThree, config).then(response => {
   
  if(response?.data?.result){

   const classData = response?.data?.result


     let filtered = classData?.map((items)=>{
       return{id:items?._id,name:items?.Type}
     })

     setVlicenceClassData(filtered)
  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });






}, [driverId]);


useEffect(()=>{
  if(VehicleName === 'Car'){
       setVlicenceClassData(vLicenseClassData?.filter((i)=> i.name !== 'ODVL'))
  }

},[vLicenseClassData,VehicleName])



// FUNCTIONS  

const handleDateSelection =(date)=>{
    setSelectedIssueDate(date)
   }
   const handleVDateSelection =(date)=>{
    setVocationalIssueDate(date)
   }


const handleSelect = (label,data)=>{
          
    if(label === 'License Type'){
           setSelectedLid(data?.id)
    }

    if(label === 'Vocational License Type'){
      setSelectedVid(data?.id)
                  }
  }

 
  const handleMultipleSelect = (label,data)=>{

    if(label === 'License Type'){

      let filteredData = data?.map((item)=>{
        return item?.id
      })

      setSelectedLid(filteredData)

    }

  }





  const handleImageFiles = (files)=>{
 
    setLicenseFrontFile(files)
  
    }
  
     
    const handleImageFilesTwo = (files)=>{
      setLicenseBackFile(files)
    
      }
  
      const handleImageFilesThree = (files)=>{
        setVLicenseFrontFile(files)
      
        }
        const handleImageFilesFour = (files)=>{
          setVLicenseBackFile(files)
        
          }

          const handleFormSubmit =()=>{
            setSubmitHit(true);
            const formData = new FormData();

            formData.append('driverId',driverId);
            selectedLid.forEach((classId, index) => {
              formData.append(`licenceClassIds[${index}]`, classId);
            });
            // formData.append('licenceClassId', selectedLid);
            formData.append('licenceNumber', licenceNumber);
            formData.append('issueDate', selectedIssueDate);
            formData.append('vocationalLicenceTypeID',selectedVid); 
            formData.append('vocationalLicenceNumber', vocationalLicenceNumber);
            formData.append('vocIssueDate',vocationalIssueDate );
            formData.append('LicenceFrontImage',licenseFrontFile.file);
            formData.append('LicenceBackImage',licenseBackFile.file);
            formData.append('VocLicenceFrontImage',vLicenseBackFile.file);
            formData.append('VocLicenceBackImage',vLicenseBackFile.file);

            ApiCall("POST", "/trip-service/v1/admin/driver/create/licence/details", formData).then((data)=>{
              if(data.status === 'success'){
                console.log(data?.result , "<----- data resulllttttttttt <-=--------")
          
                setOnSuccess((i) => !i )
                setOnApiSuccess("Success")

                
                onSectionComplete(true,'driving')
                onButtonClick('pagefour')
                
          
              }
          })
          .catch((error) => {
             setOnFailed((i) => !i)
            
              if(error.message){
                setOnApiFailed(error.message)
                console.log(error , "<----errrrrrr")
              }else{
                setOnApiFailed("Something went wrong.!")
              }
          
          
          });


          }
    


  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
<Stack spacing={2}>
 {/* FIRST ROW    */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'33%'}>
<MultipleSelect isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"License Type"} Data={licenseClassData} />
{/* <SelectField  isError={false} onSelecting={handleSelect} Data={licenseClassData} sideLabel={'License Type'} placeHolder={'choose'} /> */}
    </Box>
    <Box width={isMatch ? "100%" : "33%"} >
    {/* <DatePickerComponent sideLabel={'Issue Date'}/> */}
    <DatePickerComponent disableFuture={true} isError={false}  handleSelection={handleDateSelection} sideLabel={'Issue Date'}/>
    </Box>
    <Box width={isMatch?'100%':'32.2%'} >

<InputField Note={licenceNumber !== '' && licenceNumber?.length > 9 && 'Invalid number' || licenceNumber !== '' && licenceNumber?.length < 5 && 'Invalid number'  }  isError={false} ErrNote={""} InChange={(e) => setLicenceNumber(e.target.value)}   sideLabel={"License Number"} placeHolder={"License Number"} />
</Box>

</Stack>

{/* SECOND ROW */}


{/* THIRD ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelector sideLabel={'Front Picture'}  isError={false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelector sideLabel={'Back Picture'}  isError={false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>

{/* VOCATIONAL SECTION */}


<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'33%'}>
{/* <SelectField isError={''} onSelecting={()=>{}} Data={NO_OF_SEATS} sideLabel={'Vocational License Type'} placeHolder={'choose'} /> */}
<SelectField  isError={false} onSelecting={handleSelect} Data={vLicenseClassData} sideLabel={'Vocational License Type'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'33%'} >
{/* <InputField isError={''} ErrNote={""} InChange={(e) => {}}  sideLabel={"Vocational License Number"} placeHolder={"Vocational License Number * "} /> */}
<InputField Note={vocationalLicenceNumber && vocationalLicenceNumber?.length > 9 && 'Invalid number' || vocationalLicenceNumber && vocationalLicenceNumber?.length < 5 && 'Invalid number'  }  isError={false} ErrNote={""} InChange={(e) => setVocationalLicenceNumber(e.target.value)}   sideLabel={"Vocational License Number"} placeHolder={"Vocational License Number"} />
</Box>
    <Box width={isMatch ? "100%" : "33%"} >
    {/* <DatePickerComponent sideLabel={'Issue Date'}/> */}
    <DatePickerComponent disableFuture={true} isError={false}  handleSelection={handleVDateSelection} sideLabel={'Issue Date'}/>
    </Box>
</Stack>

{/* SECOND ROW */}


{/* THIRD ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
{/* <ImageSelectorUpdate sideLabel={'Front Picture'} imageLink={'https://www.fakeidscard.com/wp-content/uploads/2023/templates/driver-license/singapore-driver-license-template/cropped-singapore-driver-license-template_0_cp-570x342.jpg'} isError={''} handleFileChange ={()=>{}} /> */}
<ImageSelector sideLabel={'Front Picture'}  isError={false} handleFileChange ={handleImageFilesThree} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
{/* <ImageSelectorUpdate sideLabel={'Back Picture'} imageLink={''} isError={''} handleFileChange ={()=>{}} /> */}
<ImageSelector sideLabel={'Back Picture'}  isError={false} handleFileChange ={handleImageFilesFour} />
</Box>
</Stack>

{/* <Box width={isMatch?'100%':'32.2%'} >
<InputField  isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
</Box> */}

<SnackBars isProgress={true} submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Stack alignItems={'center'} spacing={1} direction={'row'}> 
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />
  {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagefour"} goBack={"pagetwo"} /> */}
  </Stack>
</Stack>


  </Box>
  )
}

export default DrivingLicenseForm
