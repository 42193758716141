import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import bagIcon from '../../../../Assets/Visual.svg'
import walletIcon from '../../../../Assets/Visual2.svg'
import clockIcon from '../../../../Assets/Visual3.svg'
import calenderIcon from '../../../../Assets/Visual4.svg'

function UserInfo() {
  return (
 <Box pl={2}>
    <Typography fontWeight={500} variant='h7'>Info</Typography>
    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={bagIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography fontSize={'13px'} fontWeight={600}>Honda</Typography>
        <Typography fontSize={'12px'} color={'#C6C6C6'} variant='subtitle2'>Accord</Typography>
     </Stack>  
    </Stack>

    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={walletIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography color={'green'} fontSize={'13px'} fontWeight={600}>S$ 254.00</Typography>
        <Typography fontSize={'12px'} color={'#C6C6C6'} variant='subtitle2'>Wallet Balance</Typography>
     </Stack>  
    </Stack>

    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={clockIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography fontSize={'13px'} fontWeight={600}>07:30 AM</Typography>
        <Typography fontSize={'12px'} color={'#C6C6C6'} variant='subtitle2'>Log in</Typography>
     </Stack>  
    </Stack>


    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={calenderIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography fontSize={'13px'} fontWeight={600}>12 February 2023</Typography>
        <Typography fontSize={'12px'} color={'#C6C6C6'} variant='subtitle2'>Joining date</Typography>
     </Stack>  
    </Stack>
 </Box>
  )
}

export default UserInfo
