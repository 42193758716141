import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableNav from '../../Components/TableSearchExport/TableNav'
import FormModal from '../../Components/Modal/FormModal'
import DataTable from '../../Components/Table/DataTable'
import ticketData from '../../FakeData/MOCK_DATA5.json'
import AddTickets from '../../Components/Ticketing/AddTickets'
import CreateTab from '../../Components/Forms/CreateTab'
import { useNavigate } from 'react-router-dom'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import TableHead from '../../Components/Table/TableHead'
import { useTitle } from '../../../Hooks/UseTitle'
import ApiCall from '../../../Helper/ApiCalls'
import dayjs from 'dayjs'
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions'
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage'
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions'
import { toast } from 'react-toastify'

const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },

  {
    id: "userName",
    label: "UserId",
    minWidth: 100,
    align: "left",
    sort: false,
  },
  {
    id: "issueType",
    label: "App Type",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "agentName",
    label: "Priority",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "dateAndTime",
    label: "Date & Time",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,

  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];

function Ticketing() {
  useTitle("Tickets - Geolah");
  const [rows, setRows] = useState();
  const [success, setSuccess] = useState()
  const [tab , setTab] = useState('left')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const navigate = useNavigate()
  const [any,setAny] = useState()

  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [totalRows,setTotalRows] = useState(0);


  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Ticket')
    setAny(isAany)
    let isWrite = checkPrivilage('Ticket','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Ticket','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Ticket','DELETE')
     setDeletePer(isDelete)
     },[])



  useEffect(()=>{
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", "/user-service/v1/admin/driver/?limit=1000&&offset=0", null, "uui").then((data)=>{
      if(data?.result){
  
      const serviceData = data?.result
      setTotalRows(serviceData?.length)
      console.log(serviceData,"<[[[[the serviceee")
      const filteredData = serviceData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{

        const formattedDate = dayjs(eachItem?.CreatedAt).format('YYYY-MM-DD')
        let dateString = eachItem?.CreatedAt
        let testT = convertToCurrentCountryTime(dateString)
        const currentStartTime  =  dateString?.slice(11, 16);

       let items = {
          
        _id:eachItem?.ID,      
        id:index+1+currentOffset,
        userName:eachItem?.UserID,
        issueType:eachItem?.AppType,
        description:eachItem?.Description,
        agentName:eachItem?.Priority,
        dateAndTime:testT,
        status:eachItem?.Status,        
       }
       return items


      })

     setRows(filteredData)
     setTableData(filteredData);
   }

   })
  },[success,currentPage])



  useEffect(()=>{
    setRows(
      tableData.filter((item) =>
      item?.userName?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.issueType?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.agentName?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.dateAndTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.status?.toLowerCase().includes(searchInput.toLowerCase()) 
    
        ))
     },[searchInput])



  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };

  const tabRender = (alignment)=>{
    setTab(alignment)
                            }

const handleFilterBar = (status)=>{
    setIsFilterOn(status)
                           } 

const handleServiceEdit =(id,newData)=>{

if(id){
  navigate(`/edit-tickets/${id}/${newData?.userName}`)
}
}                            
                           
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);
  setDeleteStatus(true)
  const paramsData ={userId:newData?.userName,ticketId:newData?._id}
  setDeleteData(paramsData)
 
}

const onDeleteYes =(yesData)=>{
  console.log(yesData,"<--the yess datasss")
 ApiCall("PATCH", `/user-service/v1/admin/driver/tickets/delete`,yesData,'BODY').then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
})
}

const onCloseNotification = ()=>{
  setDeleteStatus(false)
   }                              

   
const handleChangeInSearch = (value)=>{
setSearchInput(value)
}                             

const handleStatusChange =(id, newData)=>{
const paramsData = {surgeId:id,status:newData?.status,type:"General",action:"ChangeStatus"}
// ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",paramsData,'BODY').then((data)=>{setSuccess(data)})
                                       }                             

const handleColumnFilter = (items, searchText) => {
setSelectedItems(items); // Update selected filter items

// If no items are selected and no search text, show all rows
if (items.length === 0 && searchText === "") {
  setRows(tableData);
  return;
}
     


// Filter the rows based on the selected items and search text
const filteredRows = tableData.filter((row) => {
  // Check if any of the selected items are present in the row's features
  const featuresMatch = items.every(
    (item) => row.features && row.features.includes(item)
  );

  // Check if the row matches any of the selected vehicleCategory items
  const vehicleCategoryMatch = items.includes(row.zone);

  // Check if the row matches all of the selected vehicleType items
  // const vehicleTypeMatch = items.every(
  //   (item) => row.vehicleType && row.vehicleType.includes(item)
  // );
  const vehicleTypeMatch = items.includes(row.days);

  // Check if the row contains the search text in any of its values
  const searchTextMatch = Object.values(row).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  // Return true if any of the filters match the row, otherwise return false
  return (
    (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
    searchTextMatch
  );
});

// Update the filtered rows
setRows(filteredRows);
};

const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}
 
  return (
    any ? (
    <Box>
    <TableHead  title={'Tickets'}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
   
    <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
        DeletePermission={deletePer}
        ButtonPermission={write}
        EditPermission={edit} 
    isSurcharge={false} 
    isFilterOn={isFilterOn}
    columns={columns}
    tableData={rows}
    columnSorting={columnSortingFn}
    handleSwitchChange={handleStatusChange}
    handleDelete={handleServiceDelete}
    openFilter={handleFilterBar}
    ButtonPath={'/create-ticket'}
    ButtonLabel={'Create Ticket'}
    handleEdit={handleServiceEdit}
    searchInput={handleChangeInSearch}
    filterComponent={''}
  />
      </Box>
    </Box>
  </Box>) : any === false ? (
     <RestrictedUsage/>
  ) : null
 
  )
}

export default Ticketing