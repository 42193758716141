import {
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import settingsIcon from '../../../Assets/settings.svg'

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "0.5px solid lightgrey",
  borderRadius: "10px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "lightgray",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "28ch",
    },
  },
}));

function TableSearchExport() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme?.breakpoints?.down("md"));
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={isMatch ? "what are y.. " : "what are you looking for?"}
            inputProps={{ "aria-label": "search" }}
          />
          <Button
            sx={{
              backgroundColor: "#1C1B20",
              color: "white",
              width: "1px",
              borderRadius: "9px",
              height: "28px",
              position: "absolute",
              right: "4px", // add this line to position the button
              top: "50%", // add this line to position the button
              transform: "translateY(-50%)", // add this line to center the button vertically
            }}
            size="small"
            variant="contained"
            href="#contained-buttons"
          >
            Search
          </Button>
        </Search>

        <Button
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "7px",
            borderRadius: "9px",
            height: "35px",
          }}
          size="small"
          variant="contained"
          href="#contained-buttons"
        >
          Export
        </Button>
        <IconButton>
          {/* <TuneIcon /> */}
          <img src={settingsIcon} alt={"settings"}  />
        </IconButton>
      </Stack>
    </>
  );
}

export default TableSearchExport;
