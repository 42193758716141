import { FormControl, FormHelperText, FormLabel, OutlinedInput, Stack, Typography, useFormControl } from "@mui/material";
import { useMemo } from "react";

// uncomment when the helper text is required dont forget to import rquired items......//

// function MyFormHelperText({errorNote}) {
//     const { focused } = useFormControl() || {};

//     const helperText = useMemo(() => {
//       if (focused) {
//         return 'This field is being focused';
//       }

//       return errorNote;
//     }, [focused]);

//     return;
//   }

function InputFieldUpdate({sideLabel,placeHolder,InChange,isError,ErrNote,Value,disable,Type,Note,notRequired}) {

  const handleKeyDown = (event) => {
    // Prevent digits (0-9) from being entered
    if (/^\d+$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <FormControl
      required
      component="fieldset"
      sx={{ width: "100%"}}
      variant="standard"
    >
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
       {!notRequired && <Typography color={'red'}>*</Typography>}
        </Stack>
     
        <OutlinedInput
        onKeyDown={Type === 'text' ? handleKeyDown : null}
        type={Type && Type}
        disabled={disable}
        value={Value}
        error={isError}
        helperText={isError ? ErrNote : null}
        onChange={(e) => InChange(e)}
        onInput={(e) => {
          // Check if the input type is 'number'
          if (Type === 'number') {
            // If type is 'number', remove non-numeric characters and the letter 'e'
            e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/e/gi, '');
          }
          // For other types, allow all characters
          InChange(e);
        }}
        sx={{
         borderRadius: '4px',
         height: '46px',
         "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
         WebkitAppearance: "none",
         margin: 0,
    },
  }}
  fullWidth
  placeholder={placeHolder}
/>
     {<Typography variant="caption" color={'red'}>{Note}</Typography>}
    </FormControl>
  );
}

export default InputFieldUpdate;

