import { Box, Stack } from '@mui/material'
import React from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import AddDayFair from './AddDayFair'

function DayFairForm() {
  return (
  <Box>
       <Box>
      <Stack spacing={5} >
    {/* <VoucherForm/> */}
     <AddDayFair/>
    <FormSubmitButtons/>
    </Stack>
   </Box>
  </Box>
  )
}

export default DayFairForm
