import { Box, ToggleButton, ToggleButtonGroup, createTheme, styled } from '@mui/material'
import React, { useState } from 'react'
import MuiToggleButton from '@mui/material/ToggleButton';
import { Padding } from '@mui/icons-material';

function ToggleNav({rName , lName, toggleChange,showThird,tName}) {

    const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
        '&.Mui-selected, &.Mui-selected:hover': {
          color: 'white',
          backgroundColor: selectedColor,
        },
      }));

      const theme = createTheme({
        palette: {
          text: {
            primary: '#00ff00',
          },
        },
      });

      





const [alignment, setAlignment] = useState('left');

    const handleChange = (event, newAlignment) => {
      console.log(newAlignment,"<---new<#---[-][-][-][-][-] alignment")
      if(newAlignment){
        setAlignment(newAlignment)
      }

      toggleChange(newAlignment)
   
    };

    const children = [
        <ToggleButton selectedColor="#181A20"  sx={{border:'none' , borderRadius:'8px',textTransform:"none" }} value="left" key="left">
         {lName}
        </ToggleButton>,
     
        <ToggleButton  selectedColor="#181A20" sx={{border:'none' , borderRadius:'8px',textTransform:"none" }} value="right" key="right">
        {rName}
        </ToggleButton>,
        
          showThird &&
          <ToggleButton  selectedColor="#181A20" sx={{border:'none' , borderRadius:'8px',textTransform:"none" }} value="last" key="right">
          {tName}
          </ToggleButton>

        
        ,]
     
     const control = {
        value: alignment,
        onChange: handleChange,
        exclusive: true,
      };



  return (
<Box alignItems='center' display='flex' justifyContent='flex-start' borderBottom={0.5} borderColor="grey.300" width='100%' height="80px">

<ToggleButtonGroup    sx={{margin:'5px'}}  size="medium" {...control} aria-label="Large sizes">
        {children}
      </ToggleButtonGroup>

</Box>
  )
}

export default ToggleNav
