import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSelectBar from '../../../Components/Forms/CustomSelectBar'
import ApexChart from '../../../Components/DetailsPage/ApexChart'
import { useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage'
import Nodatafound from '../../../Components/NoDataFound/Nodatafound'

function TicketInfo() {

  const [fromDate , setFromDate] = useState('2020-01-10T10:10:05.833Z')
  const [toDate , setToDate] = useState('2030-01-10T10:10:05.833Z')
  const {currentDashdata} = useSelector((state) => state.dashboard)
  const [cardData , setCardData] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [hideChart,setHideChart] = useState(false)
  const allElementsAreZero = (data) => {
    return data.every(element => element === 0);
  };

  useEffect(()=>{

    if(currentDashdata?.status !== 'Year'){
      setFromDate(currentDashdata?.startDate)
      setToDate(currentDashdata?.endDate)
    }else{
      setFromDate('2020-01-10T10:10:05.833Z')
      setToDate('2030-01-10T10:10:05.833Z')
    }

  },[currentDashdata])


  useEffect(()=>{

    ApiCall("GET", `/user-service/v1/admin/dashboard/ticket/details/?countryId=${CountryID}&&startDate=${fromDate}&&endDate=${toDate}`, null).then((data)=>{
      if(data.status === 'success'){
       const rowData = data?.result;
       const result = [['Open', 'Closed'], [rowData?.openTicketCount, rowData?.closedticketCount]]
      let isAllZero =  allElementsAreZero([rowData?.openTicketCount, rowData?.closedticketCount])
      setHideChart(isAllZero)
      // console.log(isAllZero , "< --is all zeroosss")
         setCardData(result)

      }

    }).catch((error) => {
      console.log(error,"<---the errrorr")
   });

  },[fromDate,toDate])





console.log(cardData , "<--c-a-r-d-D-a-t-a>")






  return (
<Box p={2}  borderRadius={'8px'} bgcolor={'white'} width={'517px'} height={'250px'}>
<Box  display={'flex'} justifyContent={'space-between'}>
     <Typography sx={{fontSize:"18px"}} variant='body1' >Ticket Info</Typography>
     {/* <CustomSelectBar/> */}
    </Box>
    {
      hideChart ? 
      <Box mt={-5}>
 <Nodatafound Title={'No Data Found'}/>
      </Box>:
    <Box mt={2}>
    <ApexChart isDash={true} chartValue={cardData?.[1]} chartLable={cardData?.[0]} />


    </Box>
  }
</Box>
  )
}

export default TicketInfo
