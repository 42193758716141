import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import ApproveButton from './ApproveButton'

function SaveAndVerify({handleSubmitHere,handleYesClick,verificationStatus,buttonStatus}) {
  return (
 <Box mt={5}>
<Stack direction={'row'} spacing={1}>
<Button disabled={!buttonStatus} onClick={() => handleSubmitHere()} sx={{color:'white',backgroundColor:"#021640",textTransform:'none',borderRadius:'6px',  '&:hover': {color: 'black'},}}>Save</Button>
<ApproveButton disableStatus={buttonStatus} isVerified={verificationStatus} handleConfirm={handleYesClick} DialogText={'Are you sure you want to verify ?'} TitleText={'Verify service details'} buttonColor={'success'} Label={'Verify'} />

</Stack>
</Box>
  )
}

export default SaveAndVerify
