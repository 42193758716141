import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import InputField from '../../../Components/Forms/InputField'
import ApiCall from '../../../../Helper/ApiCalls'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'

function PaymentSplitForm() {

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

    const [submitHit,setSubmitHit] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false) 

    const [splitName , setSplitName] = useState('')
    const [driverSplit , setDriverSplit] = useState(0)
    const [geolahSplit , setGeolahSplit] = useState(0)
    const [priorityDeduction , setPriorityDeduction] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
  

    useEffect(()=>{   
      let isWrite = checkPrivilage('Payments','CREATE')
      setWrite(isWrite)
  
       },[])



    const handleFormSubmit =()=>{
        setSubmitHit(true)

        let sendData = {
            name:splitName,
            psd:priorityDeduction,
            countryId:CountryID,
            splits:[
                {
                    name: "Geolah",
                    percentage: geolahSplit.toString(),
                    type: "Geolah",
                 
                  },
                  {
                    name: "Driver",
                    percentage: driverSplit.toString(),
                    type: "Driver",
                 
                  }
            ]


        }



        ApiCall("POST", "/trip-service/v1/admin/payments/splits",sendData).then((data)=>{
            if(data?.status === 'success'){
              setOnSuccess(true)
            }
        })
        .catch((error) => {
          setOnFailed((i) => !i)
            
          if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
          console.error(error, "PUT API ERROR");
        
        
        });


    }


const handleChangeInSplit=(value,title)=>{

  console.log(value, "<--- vallsss")

  if(title === 'Driver' && value <= 100 || isNaN(value) && title === 'Driver'){
    let inValue = isNaN(value) ? 0 : value
    setDriverSplit(inValue)
    setGeolahSplit(100-inValue)

  }else if(title === 'Geolah' && value <= 100 ||  isNaN(value) && title === 'Geolah'){
    let inValue = isNaN(value) ? 0 : value
    setGeolahSplit(inValue)
    setDriverSplit(100-inValue)

  }


}






  return (
<Box>
<Stack spacing={3} >
{/* form   */}
 <Stack spacing={2}>

{/* ROW ONE */}

    <Stack  direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'32%'}>
         <InputField isError={submitHit && splitName.length < 1} ErrNote={""} InChange={(e) => setSplitName(e.target.value)}  sideLabel={"Payment Split Name"} placeHolder={"Payment Split Name"} />
         </Box>
         <Box width={'32%'}>
         <InputFieldUpdate Value={driverSplit} isError={submitHit && driverSplit.length < 1} ErrNote={""} InChange={(e) => handleChangeInSplit(parseInt(e.target.value),"Driver")}  sideLabel={"Driver’s split (%)"} placeHolder={"Driver’s split"} />
         </Box>
         <Box width={'32%'}>
         <InputFieldUpdate Value={geolahSplit} isError={submitHit && geolahSplit.length < 1} ErrNote={""} InChange={(e) => handleChangeInSplit(parseInt(e.target.value),"Geolah")}  sideLabel={"Geolah’s split (%)"} placeHolder={"Geolah’s split"} />
         </Box>

    </Stack>


{/* ROW TWO */}

<Stack  direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'32%'}>
      <InputField isError={submitHit && priorityDeduction.length < 1} ErrNote={""} InChange={(e) => setPriorityDeduction(e.target.value)}  sideLabel={"Priority Sticker Deduction(%)"} placeHolder={"Priority Sticker Deduction(%)"} />
</Box>

</Stack>






    </Stack>

    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>

   <SnackBars submitStatus={submitHit} successNote={'Successfully Created'} Status={onSuccess}/>
   <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </Stack>

</Box>
  )
}

export default PaymentSplitForm
