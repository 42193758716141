import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { Box, Button, IconButton, Pagination, Rating,Skeleton,Stack,Switch,Typography,createTheme, useMediaQuery } from "@mui/material";
import "../../../Styles/Form.min.css";
import ActionsButton from "./ActionsButton";
import { makeStyles } from "@mui/styles";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useDispatch, useSelector } from "react-redux";
import { insertTableDataAction } from "../../Redux/Actions/TableActions";
import CreateTab from "../Forms/CreateTab";
import filterButton from '../../../Assets/Table/Default.svg'
import NewTableHead from "./NewTableHead";
import MultipleSelectBar from "./MultipleSelectBar";
import FormModal from "../Modal/FormModal";
import KycDetails from "../../Views/WalletModule/WalletDetails/KycDetails";
import { insertModalDataAction } from "../../Redux/Actions/ModalActions";
import { baseUrl } from "../../RequestMethord";
import axios from "axios";
import ApiCall from "../../../Helper/ApiCalls";
import SnackBars from "../Forms/SnackBars";
import ErrorNotify from "../Forms/ErrorNotify";
import LinearIndeterminate from "../Loading/LoadingPage";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    height: 10,
  },
});

function DataTable({
  
  needButton,
  handlingPage,
  totalCount,
  handleCancelClick,
  handleConfirmClick,
  DeletePermission,
  EditPermission,
  ButtonPermission,
  handleOnline,
  addWalletMoney,
  isLoading,
  modalClicked,
  addDestination,
  zoneStatus,
  isAvoidDelete,
  handleUserPersonalData,
  isSurcharge,
handleClickProfileDetails,
 tableData,
 openFilter,
 ButtonLabel,
 ButtonPath,
 columnSorting,
 isFilterOn,
 columns,
 handleSwitchChange,
 handleDelete,
 columnFilter,
 searchInput,
 handleEdit,
 handleRowSelection,
 filterComponent }) {

  const [loadingData , setLoadingData] = useState(false)
  const {currentModaldata} = useSelector((state) => state.modal)

// useEffect(()=>{
//   function delayedFunction() {
//     setLoadingData(false)
//    }
//   const timeoutId = setTimeout(delayedFunction, 2000);
// },[])





  const [rows, setRows] = useState(tableData);
  const dispatch = useDispatch()
  
  useEffect(() => {
    setRows(tableData);
  }, [tableData]);
  // const [order , setOrder] = useState('ASC')

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        fst:1347,
        snd:1300,
        thd:1123,
        frh:1032,
        fft:962,
        spl:1397,
        sxt:861,
        sth:784,
        sxl: 1430,
        xl: 1536,
        bxl:1596,
        dxl:1700,
        exl:1800,
      },
    },
  });
  const isMdMatch = useMediaQuery(theme?.breakpoints?.down("md"))
  const isLgMatch = useMediaQuery(theme?.breakpoints?.down("lg"))
  const firstMatch = useMediaQuery(theme?.breakpoints?.down("fst"))
  const secndMatch = useMediaQuery(theme?.breakpoints?.down("snd"))
  const thirdMatch = useMediaQuery(theme?.breakpoints?.down("thd"))
  const fourthMatch = useMediaQuery(theme?.breakpoints?.down("frh"))
  const fifMatch = useMediaQuery(theme?.breakpoints?.down("fft"))
  const sxthMatch = useMediaQuery(theme?.breakpoints?.down("sxt"))
  const svthMatch = useMediaQuery(theme?.breakpoints?.down("sth"))
  const firstXl =    useMediaQuery(theme?.breakpoints?.up("bxl"))
  const secondXl =    useMediaQuery(theme?.breakpoints?.up("dxl"))
  const thirdXl =    useMediaQuery(theme?.breakpoints?.up("exl"))
  const splMatch =    useMediaQuery(theme?.breakpoints?.down("spl"))
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);




//MODAL DETAILS

const [submitHit,setSubmitHit] = useState(false)
const [onApiSuccess , setOnApiSuccess] = useState('')
const [onApiFailed , setOnApiFailed] = useState('')
const [onSuccess, setOnSuccess] = useState(false)
const [onFailed , setOnFailed] = useState(false)
const [apiLoading,setApiLoading] = useState(false)

const handleSubmit=()=>{
  setApiLoading(true)
  setSubmitHit(true)
 
 let sendData = {
   userTypeId:2, countryID:1, driverId:parseInt(kycData?.UserID),docType:"NricImagePath", 
 }
 
   ApiCall("POST", "/trip-service/v1/admin/driver/submit/kyc-adyen",sendData).then((data)=>{
     if(data.result === 'success'){
      setApiLoading(false)
       dispatch(insertModalDataAction(!currentModaldata))
       setOnSuccess((i) => !i )
       setOnApiSuccess("Succesfully Submitted")
       
 
     }
 })
 .catch((error) => {
  setApiLoading(false)
    setOnFailed((i) => !i)
   
     if(error.message){
       setOnApiFailed(error.message)
       console.log(error , "<----errrrrrr")
     }else{
       setOnApiFailed("Something went wrong.!")
     }
 
 
 });
 }





const [modalStatus, setModalStatus] = useState(false);
const [kycData,setKycData]= useState({})
const [nricData,setNricData] = useState({})
const [countryId,setCountryId] = useState()
const [changeData,setChangesData] = useState({})


const {currentCountrydata:CountryID} = useSelector((state) => state.country)
useEffect(()=>{

  setCountryId(CountryID)

},[CountryID])

const onClick = (data) => {


  ApiCall("GET", `/pay-service/v1/admin/driver/?userId=${data?.allData?.UserID}&&countryId=${countryId}`,null,).then((data)=>{
    
    if(data.result){

      const rowData = data?.result
      const changedFields = rowData?.differingFields

       setKycData(rowData)
       setChangesData(changedFields)   
    
          
    }
})
.catch((error) => {

  if(error.message){
    console.log(`Error -> ${error.message}`)
    }else{
      console.log('something went wrong [ridecategory - get]')
    }

});


  const apiUrl = `${baseUrl}/trip-service/v1/driver/profile/activity`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']:data?.allData?.UserID,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  axios.get(apiUrl, config).then(response => {

    if(response?.data?.result){
      const personalData =response?.data?.result
      setNricData(personalData)
  
      // console.log(personalData,"#########eeeeeeeeeeeeeeeeeeeeee#############33333")
  
    }

      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });
  setModalStatus(true);
};

const onClose = () => {
  setModalStatus(false);
};

  const { down, values } = theme.breakpoints;

  const maxWidth = down('lg')
    ? '95vw' // For screen widths from 1920px to 1280px
    : down('md')
    ? '75vw' // For screen widths from 1280px to 960px
    : down('sm')
    ? '100%' // For screen widths from 960px to 600px
    : '100%'; // For screen widths from 600px and below (e.g., iPad mini)

    
 
  const count = Math.ceil(totalCount / rowsPerPage);
  


  
  const handleChangePage = (event, newPage) => {

    setPage(newPage);
  };


  useEffect(()=>{
   handlingPage && handlingPage(page,rowsPerPage)
  },[page,rowsPerPage])

  const handleProfileDetails = (rowData) => {
    // Perform the same action as the switch change
    handleUserPersonalData && handleUserPersonalData(rowData.userID, { ...rowData})
    handleClickProfileDetails(rowData.userID, { ...rowData});
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  handleRowSelection && handleRowSelection(event.target.value)
    // setPage(0);
  };

 useEffect(()=>{

  let showData = {
    total:totalCount,
    perPage: rowsPerPage
  }

  dispatch(insertTableDataAction(showData))
 },[rows,rowsPerPage,totalCount])

 const label = { inputProps: { 'aria-label': 'Color switch demo' } };
 const isTTMatch = useMediaQuery('(max-width: 960px)');
//  const totalColumnWidth = columns.reduce((acc, column) => acc + column.minWidth, 0);
 const totalColumnWidth = 2600;


//only KYC


const handleCancel=()=>{
  dispatch(insertModalDataAction(!currentModaldata))
}






  return (
 
    <>
    <FormModal handleclose={onClose} status={modalStatus} component={<KycDetails loadingStatus={apiLoading} changedItems={changeData} confirmSubmit={handleSubmit} CancelSubmit={handleCancel} WalletData={{...kycData,...nricData}}/>} />
    <Box paddingLeft={3} width={'98%'} display={'flex'} justifyContent={'space-between'} flexDirection={splMatch ? 'column':'row'} >
      
      <Stack alignItems={'center'} direction={'row'} spacing={1}>
     {

       needButton ? (
        <>
            <TablePagination
            sx={{width:'120px' , overflow:'hidden'}}
           rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
           component="div"
           count={rows?.length}
           rowsPerPage={rowsPerPage}
           page={page}
          //  onPageChange={handleChangePage}
           onRowsPerPageChange={handleChangeRowsPerPage}
           labelDisplayedRows={() => null}
           labelRowsPerPage=""
           SelectProps={{
             sx: {
               width: '84px',
               height: '48px',
               borderRadius: '8px',
               border: '0.5px solid lightgray',
             },
           }}
         />
         {filterComponent && !needButton &&
         <IconButton   sx={{
        '&:hover': {
          bgcolor: 'transparent', // Disable hover background color
        },
      }} aria-label="delete" size="large" onClick={() => openFilter(true)}>
          <img src={filterButton} alt="filter"/>
         </IconButton>
 }
{

<Paper  sx={{backgroundColor:"white",margin:"5px",padding:"5px"}} elevation={5} >

{filterComponent || null}

</Paper>

}

         </>

       ):
          !isFilterOn && !needButton ? (
              <>
            <TablePagination
            sx={{width:'120px' , overflow:'hidden'}}
           rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
           component="div"
           count={rows?.length}
           rowsPerPage={rowsPerPage}
           page={page}
          //  onPageChange={handleChangePage}
           onRowsPerPageChange={handleChangeRowsPerPage}
           labelDisplayedRows={() => null}
           labelRowsPerPage=""
           SelectProps={{
             sx: {
               width: '84px',
               height: '48px',
               borderRadius: '8px',
               border: '0.5px solid lightgray',
             },
           }}
         />
         {filterComponent &&
         <IconButton   sx={{
        '&:hover': {
          bgcolor: 'transparent', // Disable hover background color
        },
      }} aria-label="delete" size="large" onClick={() => openFilter(true)}>
          <img src={filterButton} alt="filter"/>
         </IconButton>
 }
         </>

          ): (

        <Paper  sx={{backgroundColor:"white",margin:"5px",padding:"5px"}} elevation={5} >

              {filterComponent || null}
              
        </Paper>
          ) 
        
      }

     
        

      </Stack>
     {!isFilterOn && <NewTableHead buttonStatus={ButtonPermission} inPopularClicked={modalClicked}  statusZone={zoneStatus} toggleStats={isSurcharge} pathLink={ButtonPath} ButtonName={ButtonLabel} handleSearch={searchInput} />}

    </Box>
     
           
   <Paper   elevation={0} sx={{ width: '100%', padding:"30px", paddingLeft:"55px" }}>
        {/* <TableContainer
          sx={{ maxHeight:isMdMatch ? 800 : 800,zoom: 0.7 }}
        > */}



            
         {/* <TableContainer sx={{ maxHeight: 800,overflow:'auto',
         maxWidth:firstMatch && secndMatch === false ? '1200px': secndMatch && thirdMatch === false ? '1000px':thirdMatch && fourthMatch === false ? '900px':fourthMatch && fifMatch === false ? '800px':fifMatch && sxthMatch === false ? '700px':sxthMatch && svthMatch === false?'600px':svthMatch ? '550px':firstXl && secondXl === false ? '1650px':secondXl && thirdXl === false ? '1800px':thirdXl ? '2000px'  : '1500px',
          zoom: 0.8  }}> */}
          <TableContainer sx={{ maxHeight: 800, maxWidth, zoom: 0.8 }}>
                <div
                     style={{
                      display: 'flex',
                      justifyContent: 'center', // Center the table horizontally
                    
                      width: '100%',
                    }}>
                <div style={{ width:"100%", height: '100vh' }}>
                {/* <div style={{ width: totalColumnWidth, height: '100vh' }}> */}

                  
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              
                {
                  loadingData ? (
                 
                    <TableRow>
                    {columns?.map((column) => (
                      
                        <TableCell
                          key={column?.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                           
                          }}
                        >
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                  </TableRow>


                  ):(
              <TableRow>
                {columns?.map((column) => {
                  return column.sort ? (
                    <TableCell
                      
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        backgroundColor:'#FBFBFB',
                      }}
                    >
                      <Box alignItems={'center'} width={"100%"} display={'flex'} justifyContent={'space-between'} >
                      {column.label}
                      <IconButton size="small" onClick={() => columnSorting(column.id)}>
                        <UnfoldMoreIcon />
                      </IconButton>

                      </Box>
                
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column?.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        backgroundColor:'#FBFBFB',
                      }}
                    >
                      <Box>
                      {column.label}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
                  )
              }


            </TableHead>
            {rows?.length > 0 ? 
            <TableBody>
              {rows?.map((row,index) => {
                  return (
                     
                    loadingData ? (


                    
                      <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {columns?.map((column) => {
                        const value = row[column?.id];

                        return (
                          <>
                            {column?.id === "rider" || column?.id === "name"  ? (
                              <>
                                <TableCell
                                  className={classes.TableCell}
                                  sx={{
                                   
                                    alignItems: "center",
                                  }}
                                  key={column?.id}
                                >
                               <Skeleton animation="wave" />
                                </TableCell>
                              </>
                            ) : column?.id === 'rating' ? (
                              <TableCell>
                           <Skeleton animation="wave" />
                              </TableCell>

                            ) :  (
                              <TableCell
                                sx={{ alignItems: "center" }}
                                key={column?.id}
                                align={column.align}
                              >
                                <Skeleton animation="wave" />
                              </TableCell>
                            )}
                          </>
                        );
                      })}
                    </TableRow>




                    ):(


                    <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns?.map((column) => {
                        const value = row[column.id];

                        return (
                          <>
                            {column?.id === "rideId" || column?.id === "vehicleId" || column?.id === "name"  ? (
                              <>
                                <TableCell
                                  onClick={() => handleProfileDetails(row)}
                                  className={classes.TableCell}
                                  sx={{
                                    cursor:"pointer"
                                  //  paddingTop:"30px",
                                    // display: "flex",
                                    // alignItems: "center",
                                    // marginTop:"10px"
                                  }}

                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.color = '#0C1116';
                                    e.currentTarget.style.textDecoration = 'underline'; // Add underline on hover
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.color = 'black';
                                    e.currentTarget.style.textDecoration = 'none'; // Remove underline when not hovering
                                  }}
                                  key={column?.id}
                                >
                                  {/* <Avatar
                                    sx={{ marginRight: "0.2in" }}
                                    alt={value}
                                    src="/static/images/avatar/1.jpg"
                                  /> */}

                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              </>
                            ) : column?.id === 'rating' ? (
                              <TableCell>
                            <Rating name="half-rating-read" defaultValue={value} precision={0.5} readOnly />
                              </TableCell>

                            ) :  (
                              <TableCell sx={{ alignItems: "center" }} key={column?.id} align={column.align}>
                               {column.format && typeof value === "number" ? (
                               column.format(value)
                              ) : typeof value === "boolean" && value === true ? (
                                <Switch
                                 disabled={!EditPermission}
                                style={{
                                  color: 'black',                    // Set the color of the thumb (the moving part of the switch) to black
                                  '& .MuiSwitch-track': {
                                    backgroundColor: 'black',       // Set the color of the track (the background of the switch) to black
                                  },
                                  '& .MuiSwitch-thumb': {
                                    color: 'black',                 // Set the color of the thumb (the moving part of the switch) to black (for mobile devices)
                                  },
                                }}
                                defaultChecked color="default"
                                {...label}
                               checked={column.title.onTrue}
                              onChange={() => handleSwitchChange(row._id, { ...row, status: false })}
                                 
                                  />
                             ) : typeof value === "boolean" && value === false ? (
                           <Switch
                           disabled={!EditPermission}
                            {...label}
                            checked={column.title.onFalse}
                            onChange={() => handleSwitchChange(row._id, { ...row, status: true })}
                            color="default"
                            />
                             ) : column?.id === "actions" ? (
                             <ActionsButton deleteStatus={DeletePermission} editStatus={EditPermission} avoidDelete={isAvoidDelete} handleEditServices={handleEdit} rowData={row} handleServiceDelete={handleDelete} />
                              ):
                              column?.id === "actionCancel" ? (
                                EditPermission  ?  <Button
                                 disabled={row?.disable}
                                  onClick={() => handleDelete(row)}
                                    sx={{backgroundColor:"#c0392b",
                                         color:'white',
                                         "&:hover": {
                                          backgroundColor: "#e74c3c", // Change this to the desired hover color
                                        },
                                         textTransform:'none'}} variant="contained" color="primary" size="small">
                                {'Cancel Ride'}
                              </Button> : <Button disabled={true} onClick={() => handleDelete(row)}  sx={{backgroundColor:"grey",color:'white'}} variant="contained" color="primary" size="small">
                                {'Cancel Ride'}
                              </Button>
                                // <ActionsButton handleEditServices={handleEdit} rowData={row} handleServiceDelete={handleDelete} />
                                 ):
                                 column?.id === "actionWallet" ? (
                                  <Stack direction={'row'} spacing={1} >
                                           <Button disabled={!EditPermission} onClick={()=> addWalletMoney(row,false)}  sx={{backgroundColor:"green",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                  {'Add'}
                                </Button>

                                 <Button disabled={!EditPermission} onClick={()=> addWalletMoney(row,true)}  sx={{backgroundColor:"red",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                  {'Duduct'}
                                </Button>

                                  </Stack>
                              
                                  // <ActionsButton handleEditServices={handleEdit} rowData={row} handleServiceDelete={handleDelete} />
                                   )
                                   :
                                   column?.id === "actionKYC" ? (
                                    <Stack direction={'row'} spacing={1} >
                                             <Button disabled={!EditPermission} onClick={() => onClick(row)}  sx={{backgroundColor:"#021640",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                              {'View'}
                                             </Button>
  
      
  
                                    </Stack>
                                
                                 
                                     ):
                                     column?.id === "actionPopular" ? (
                                      <Stack direction={'row'} spacing={1} >
                                               <Button disabled={!EditPermission} onClick={() => addDestination(row)}  sx={{backgroundColor:"#021640",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                                {'Update'}
                                               </Button>
    
        
    
                                      </Stack>
                                  
                                   
                                       ):

                                   
                              
                               column?.id === "actionPending" ? (
                                <Stack direction={'row'} spacing={1} >
                                         <Button onClick={() => handleConfirmClick(row)} disabled={!EditPermission}  sx={{backgroundColor:"green",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                {'Confirm'}
                              </Button>

                               <Button onClick={() => handleCancelClick(row)}  disabled={!EditPermission}  sx={{backgroundColor:"red",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                {'Cancel'}
                              </Button>

                                </Stack>
                            
                                // <ActionsButton handleEditServices={handleEdit} rowData={row} handleServiceDelete={handleDelete} />
                                 )
                             : 
                              column?.id === "isOnline" ? (
                                <TableCell sx={{ alignItems: "center" }} key={column?.id} align={column.align}>

                                  {typeof value === "string" && value === 'Online' ? (
                                    <Button onClick={() => handleOnline(row,false)} sx={{backgroundColor:"green",color:'white'}} variant="contained" color="primary" size="small">
                                      {column.title.onTrue}
                                    </Button>
                                  ) :
                                  typeof value === "string" && value === 'Offline' ? (
                                    <Button onClick={() => handleOnline(row,true)} sx={{backgroundColor:'red',color:'white'}} variant="contained" color="secondary" size="small">
                                      {column.title.onFalse}
                                    </Button>
                                  ) : 
                                  typeof value === "string" && value === 'Pending' ? (
                                    <Button sx={{backgroundColor:"#00cec9",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'SendForSettle' ? (
                                    <Button sx={{backgroundColor:"#fdcb6e",color:'black',textTransform:'none'}} variant="contained" color="primary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'Settled' ? (
                                    <Button sx={{backgroundColor:"#44bd32",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                      {value}
                                    </Button>
                                  )
                                  :
                                  typeof value === "string" && value === 'booked' ? (
                                    <Button sx={{backgroundColor:"#2980b9",color:'white',textTransform:'none'}} variant="contained" color="primary" size="small">
                                      {value}
                                    </Button>
                                  )
                                  :
                                  typeof value === "string" && value === 'Completed' ? (
                                    <Button sx={{backgroundColor:'grey',color:'white','&:hover': {
                                      backgroundColor: '#ecf0f1', 
                                      color: 'black',
                                    },}} variant="contained" color="secondary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'authorised' ? (
                                    <Button sx={{backgroundColor:'grey',color:'white','&:hover': {
                                      backgroundColor: '#a29bfe', 
                                      color: 'black',
                                    },}} variant="contained" color="secondary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'Ongoing' ? (
                                    <Button sx={{backgroundColor:'green',color:'white','&:hover': {
                                      backgroundColor: '#ecf0f1', // You can also set this to 'transparent' or any other value
                                      color: 'black', // You can also set this to your desired color
                                    },}} variant="contained" color="secondary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'Ongoing' ? (
                                    <Button sx={{backgroundColor:'green',color:'white','&:hover': {
                                      backgroundColor: '#ecf0f1', // You can also set this to 'transparent' or any other value
                                      color: 'black', // You can also set this to your desired color
                                    },}} variant="contained" color="secondary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'Cancelled' ? (
                                    <Button sx={{backgroundColor:'#d63031',color:'white','&:hover': {
                                      backgroundColor: '#d63031', // You can also set this to 'transparent' or any other value
                                      color: 'black', // You can also set this to your desired color
                                    },}} variant="contained" color="secondary" size="small">
                                      {value}
                                    </Button>
                                  ):
                                  
                                  typeof value === "string" && value === 'Available' ? (
                                    <Button sx={{backgroundColor:"#00891E",color:'white',textTransform:'none'}} variant="contained"  size="small">
                                      {value}
                                    </Button>
                                  ):
                                  typeof value === "string" && value === 'N/A' ? (
                                    <Button sx={{backgroundColor:"#e74c3c",color:'white',textTransform:'none'}} variant="contained"  size="small">
                                      {value}
                                    </Button>
                                  ):

          
                                  (
                                    value
                                  )}
                                </TableCell>
                              ):
                              
                              
                              (
                              value
                               )}
                             </TableCell>
                            )}
                          </>
                        );
                      })}
                    </TableRow>
                    )
                  );
                })}
            </TableBody>:
            <NoDataAvailable/>
            }
          </Table>
          </div></div>

        </TableContainer>
       
          {
            loadingData ?
            (  <Box paddingTop={'30px'}  display='flex' justifyContent="space-between"  >
              <Skeleton width={'180px'} variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton width={'160px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Box>
            ): (
              <Box mt={10} display='flex' justifyContent="space-between"  >
                  <CreateTab/>
              {/* <TablePagination
              
              sx={{width:'180px' , overflow:'hidden'}}
              rowsPerPageOptions={[7, 10, 20, 30, 40, 50, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      
            <Pagination
          
            size="small"
            sx={{paddingTop:'15.5px'}}
            onChange={handleChangePage}
            
             count={count}
             page={page}
             
             variant="outlined" shape="rounded"/> 

             </Box>
               
              

            )
            
          }
       

     
      
      </Paper>
      <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </> 
  );
}

export default DataTable;
