import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import Tabs from '../../Components/Tabs/Tabs'
import LtaReports from './Pages/LtaReports';
import PassengerReports from './Pages/PassengerReports';
import { checkAnyPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import LtaReportsTwo from './Pages/LtaReportsTwo';

const TabItems = [
    
    // { name: "Driver", value: "Driver" },
    {
      name: "Passenger",
      value: "Passenger"
    },
    // {
    //   name: "Service Type",
    //   value: "Service Type",
    // },
    // {
    //   name: "Vehicle Type",
    //   value: "Vehicle Type",
    // },
    // {
    //   name: "Trips",
    //   value: "Trips",
    // },
    {
      name: "LTA Reports",
      value: "LTA Reports",
    },
    {
      name: "LTA Reports 2",
      value: "LTA Reports 2",
    },
    // {
    //   name: "Coupon Analysis",
    //   value: "Coupon Analysis",
    // },
  
  ];


  const Components = [
    {
      page: <LtaReports />,
      value: "LTA Reports",
    },
    {
      page: <PassengerReports />,
      value: "Passenger",
    },
    {
      page: <LtaReportsTwo />,
      value: "LTA Reports 2",
    },

];




function Reports() {
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Reports')
    setAny(isAany)
     },[])

  return (
    any ? (
<Box>
<TableHead title={'Reports'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<Tabs current={'LTA Reports'} List={TabItems} component={Components}  />
</Box>
</Box>
</Box>):any === false ? (
  <RestrictedUsage/>
):null

  )
}

export default Reports
