import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import FlagIcon from '../../../Assets/Singapore.png'
import indianFlag from '../../../Assets/india.png'
import { useDispatch, useSelector } from 'react-redux';
import {insertCountryDataAction} from '../../Redux/Actions/CountryActions'

function DropDown({countryData}) {
  const {currentCountrydata} = useSelector((state) => state.country)
 
    const [age, setAge] = React.useState(typeof currentCountrydata === 'number' && currentCountrydata || 1);


    const dispatch = useDispatch()


    useEffect(()=>{

      if(!Number.isInteger(currentCountrydata)){
        dispatch(insertCountryDataAction(1))
      }

      

    },[currentCountrydata])
 

    const handleChange = (event) => {
        console.log(event.target.value,"<-----#")
        dispatch(insertCountryDataAction(event.target.value))
      setAge(event.target.value);
      let timer = setTimeout(() => {
        window.location.reload(true);
      },700);
    };

  return (
    <Box sx={{ minWidth: 200}}>
    <FormControl fullWidth>
 
      <Select
      sx={{borderRadius:'25px',height:'36px'}}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
       
        onChange={handleChange}
      >
        {

countryData?.map((eachCountry)=>(

        <MenuItem value={eachCountry?.ID}>
  <Button sx={{color:'black',textTransform:'none'}} variant="text" startIcon={<img width={'24px'} height={'24px'}  src={eachCountry?.ID === 1 && FlagIcon || eachCountry?.ID === 2 && indianFlag  } alt='country' />}>
  {eachCountry?.name}
 </Button>
</MenuItem>
))
 
    }
        {/* <MenuItem value={2}>
        <Button sx={{color:'black',textTransform:'none'}} variant="text" startIcon={<img width={'24px'} height={'20px'}  src={Malasian} alt='country' />}>
        Malaysia 
</Button>
        </MenuItem> */}
   
      </Select>
    </FormControl>
  </Box>
  )
}

export default DropDown
