import React from 'react'
import {
    Box,
    Button,
    CardMedia,
    Grid,
    Paper,
    Skeleton,
    Stack,
    Typography,
    createTheme,
    useMediaQuery,
  } from "@mui/material";


function VehicleDetailsSkeleton() {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
           smd: 855,
            md: 900,
           fmd: 950,
           slg: 1098,
            lg: 1200,
           sxl: 1430,
            xl: 1536,
          },
        },
      });
    
      const islgMatch = useMediaQuery(theme?.breakpoints?.down("lg"));



  return (
    <Box
    sx={{
      flexWrap: "wrap",
      "& > :not(style)": {
        width: "100%",
        height: "398px",
        borderRadius: "2%",
      },
    }}
  >
    <Paper sx={{ padding: "16px" }} elevation={3}>
      <Stack direction="row" spacing={4}>
        {/* column item one */}
    
        <Skeleton sx={{borderRadius:'16px'}} variant="rectangular" width={'45%'} height={'334px'} />

        {/* column item two */}
        <Stack spacing={islgMatch ? 0.5 : 4.5}>
          {/* inside row 1 */}
          <>
            <Stack spacing={1}>
              <Typography fontWeight={600} variant="h6">
              <Skeleton width={'300px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
              <Typography fontWeight={450} variant="h7">
              <Skeleton width={'50px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
            </Stack>
          </>
          {/* inside row 2 */}
          <>
            <Stack direction="row" spacing={islgMatch ? 3 : 14}>
              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />
                </Typography>
                <Typography fontWeight={450}> <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}> <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />  </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>
            </Stack>
          </>
          {/* inside row 3 */}

          <>
            <Stack direction="row" spacing={islgMatch ? 4 : 9.5}>
              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> 
                </Typography>
                <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /> </Typography>
              </Stack>
            </Stack>
          </>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={islgMatch ? 7.5 : 9.5}>
            <Skeleton width={'500px'} variant="text" sx={{ fontSize: '4rem' , borderRadius:'8px' }} /> 
            </Grid>
            <Grid item xs={islgMatch ? 3.5 : 2}>
         
              <Skeleton   width={'114px'} variant="text" sx={{ fontSize: '40px' , borderRadius:'8px' }} /> 
            </Grid>
          </Grid>

          <></>
        </Stack>
      </Stack>
    </Paper>
  </Box>
  )
}

export default VehicleDetailsSkeleton
