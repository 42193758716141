import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import DataTable from '../../Components/Table/DataTable'
import { useTitle } from '../../../Hooks/UseTitle'
import { useNavigate } from 'react-router-dom'
import ApiCall from '../../../Helper/ApiCalls'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions'
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage'
import ToggleNav from '../../Components/Forms/ToggleNav'
import ScheduledRide from './ScheduledRide'
import CancelledRide from './CancelledRide'
import { Search } from '@mui/icons-material'
import RideFilter from './RideFilters'
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions'


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "rideId",
      label: "Ride Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "rideType",
      label: "Ride Type",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "userName",
        label: "User Name",
        minWidth: 170,
        align: "left",
        sort: true,
      },
    {
      id: "driverName",
      label: "Driver Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    
    {
      id: "pickupAddress",
      label: "Pickup Address",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "address",
      label: "Drop Address",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "fair",
      label: "Fare",
      minWidth: 200,
      align: "left",
      sort: true,
    },

    {
      id: "DateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "rideStatus",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: true,
    },

    // rideType
    // {
    //   id: "status",
    //   label: "Status",
    //   minWidth: 170,
    //   align: "left",
    //   sort: false,
    //   title: {
    //     onTrue: true,
    //     onFalse: false,
    //   },
    // },
    {
        id: "actionCancel",
        label: "Actions",
        minWidth: 170,
        align: "left",
        sort: false,
      },
  ]; 





function Ride() {
    useTitle("Ride - Geolah");
    const [order, setOrder] = useState("ASC");
    const [tab , setTab] = useState('left')
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [disableCancel,setDisableCancel] = useState(false)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [filterRideType,setFilterRideType] = useState('')
    const [filterRideStatus , setFilterRideStatus] = useState('')
    const [any,setAny] = useState()
    
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [searchPage,setSearchPage] = useState(0)
    const [totalRows,setTotalRows] = useState(0)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Ride')
  setAny(isAany)
  let isWrite = checkPrivilage('Ride','CREATE')
  setWrite(isWrite)
 let isEdit = checkPrivilage('Ride','UPDATE')
   setEdit(isEdit)
 
   let isDelete = checkPrivilage('Ride','DELETE')
   setDeletePer(isDelete)
   },[])

    
  
    const navigate = useNavigate()
    const removeUnderscoresAndAddSpaces = (inputString)=> {
      // Use a regular expression to replace underscores with spaces globally
      return inputString?.replace(/_/g, ' ');
    }



  // api call
useEffect(()=>{ 
  if(searchInput?.length > 0){
    setSearchPage(1)
  }else{
    setSearchPage(0)
  }
  const ulteredPage = searchPage?searchPage:currentPage
  const currentLimit = rowsPerPage
  const currentOffset = (ulteredPage-1) * rowsPerPage
  ApiCall("GET", `/trip-service/v1/admin/trip/all/ride/details/?countryId=${CountryID}&&limit=${currentLimit}&&offset=${currentOffset}${searchInput && `&&search=${searchInput}`}${filterRideStatus && `&&rideStatusData=${filterRideStatus}`}${filterRideType && `&&ridetype=${filterRideType}`}`, null, "uui").then((data)=>{
      if(data?.result){
      const rideData = data?.result?.details
      setTotalRows(data?.result?.totalCount)
      const filteredData =  rideData?.map((eachItem,index)=>{
      const currentStatus = eachItem?.CurrentRideStatus
      let tempStatus = false   
      if(currentStatus === 'RIDE_CANCELLED_BY_ADMIN' ||currentStatus === 'RIDE_CANCELLED_BY_PASSENGER'|| currentStatus === 'RIDE_CANCELLED_BY_DRIVER'|| currentStatus === 'RIDE_COMPLETED' || currentStatus === 'RIDE_COMPLETE'  ){
      tempStatus = true;        
      }
  
      const formattedDate = dayjs(eachItem?.Date).format('YYYY-MM-DD')
      let dateString = eachItem?.Date
    let cDate =  convertToCurrentCountryTime(dateString)
      const currentStartTime  =  dateString?.slice(11, 16);
     
      let items = {
            _id:eachItem._id,
            userID:eachItem._id,
            id:index+1+currentOffset,
            rideId:eachItem?.CabTripID,
            fair:parseFloat(eachItem?.CabDurationFareInfo?.TotalFare)?.toFixed(2),
            DateTime:cDate,
            rideStatus:removeUnderscoresAndAddSpaces(eachItem?.CurrentRideStatus),
            rideType:eachItem?.RideType,
            address:eachItem?.UserDestinationInfo?.[0]?.Address || 'N/A',
            pickupAddress:eachItem?.UserPickUpInfo?.PickupPointAddress || 'N/A',
            driverName:eachItem?.driverProfileDetails?.[0]?.FirstName +" "+eachItem?.driverProfileDetails?.[0]?.LastName,
            userName:eachItem?.userName,
            disable:tempStatus
           }  
           return items;
     })  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID,currentPage,rowsPerPage,searchInput,filterRideType,filterRideStatus])




    // useEffect(()=>{
    //     setRows(
    //       tableData.filter((item) =>
    //       item?.rideId?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.fair?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.DateTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.rideStatus?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.rideType?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.address?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.userName?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.driverName?.toString().toLowerCase().includes(searchInput.toLowerCase())      
    //         ))
    //      },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(newData)=>{

    setDeleteStatus(true)
    const paramsData ={
      tripId:newData?._id
    }
    setDeleteData(paramsData)

  }
  
  const onDeleteYes =(yesData)=>{
    ApiCall("POST", `/cab-alloc-service/v1/admin/trip/cancel/ride`,yesData).then((data)=>{
     setSuccess(data) 
     // setDeleteStatus(false)
   })
   }
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-service/${id}`)
    }  
  }

  //handle page change

  const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)
  }
 


  const handleProfileEdit = (id,newData)=>{
    const newTab = window.open(`/ride-details/${id}`, '_blank');
  if (newTab) {
    newTab.focus(); // Optionally, you can focus on the new tab
  }
    // if(id){
    //   navigate(`/ride-details/${id}`)
    // }
 }

    const handleChangeInSearch = (value)=>{
        setSearchInput(value)
   
     }

     const tabRender = (alignment)=>{
      setTab(alignment)
                              }


    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items?.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items?.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
        setFilterRideStatus('')
        setFilterRideType('')
      }
      


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };


      const handleColumnDateFilter =(data,type)=>{
       
        if(type === 'rideType'){
          setFilterRideType(data)
        }

        if(type === 'rideStatus'){
          setFilterRideStatus(data)
        }
      
     
     }

  return (
    any ? (
<Box>
<TableHead title={'Ride'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<ToggleNav showThird={true} tName={'Cancelled Rides'} lName={'Ride'} rName={'Scheduled Ride'} toggleChange={tabRender} />  
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
  { tab === 'left' ? 
    <>
<NotifyAlertWhileDelete AlertNote={'Are you sure you want to cancel ride ?'} OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
      
          handlingPage={handlePageChange}
          totalCount={totalRows}
         DeletePermission={deletePer}
         ButtonPermission={write}
         EditPermission={edit} 
        handleClickProfileDetails={handleProfileEdit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={ <RideFilter CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
      </> : tab === 'right' ?  <ScheduledRide/> : <CancelledRide/> }
    </Box>

</Box>
</Box>
) : any === false ? (

  <RestrictedUsage/>
) : null
  )
}

export default Ride
