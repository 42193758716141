import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead';
import ApiCall from '../../../Helper/ApiCalls';
import DataTable from '../../Components/Table/DataTable';
import CategoryFilter from '../RideCategoryManagement/CategoryFilter';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../../Hooks/UseTitle';
import { useSelector } from 'react-redux';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { toast } from 'react-toastify';

const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },

  {
    id: "rideCategory",
    label: "Ride Category",
    minWidth: 100,
    align: "left",
    sort: true,
  },
  {
    id: "rideservice",
    label: "Ride Service",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "paymentSplit",
    label: "Payment Split",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "seats",
    label: "Seats",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  // {
  //   id: "vehicleType",
  //   label: "Vehicle Type",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  {
    id: "vehicleCategory",
    label: "Vehicle Category",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "basicFare",
    label: "Basic Fare",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "minKm",
    label: "Min.Km",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "progressingDistance",
    label: "Progressing Distance",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "costPerKm",
    label: "Progressing Fare",
    minWidth: 170,
    align: "left",
    sort: true,
  },

  {
    id: "advFare",
    label: "Adv.Fare",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   minWidth: 170,
  //   align: "left",
  //   sort: false,
  //   title: {
  //     onTrue: "Active",
  //     onFalse: "InActive",
  //   },
  // },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];






function BasicFare() {

  useTitle("Basic Fare - Geolah");
  const [order, setOrder] = useState("ASC");
  const [rows, setRows] = useState();
  const [tableData, setTableData] = useState([]);
  const [success, setSuccess] = useState()
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [searchInput , setSearchInput] = useState('')
  const [selectedItems,setSelectedItems] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [totalRows,setTotalRows] = useState(0);
  const [any,setAny] = useState()

  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Basic Fare')
    setAny(isAany)
    let isWrite = checkPrivilage('Basic Fare','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Basic Fare','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Basic Fare','DELETE')
     setDeletePer(isDelete)
     },[])
  const navigate = useNavigate()



useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage

  ApiCall("GET", `/trip-service/v1/admin/ride-category/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
      console.log(data?.result , "<[[[[[s[s[s[s[s[s")

      const categoryData = data?.result
      setTotalRows(categoryData?.length)
      let itemCount = 1
      if(searchInput?.length <= 0){
      const filteredData = categoryData?.slice(currentOffset, currentOffset + currentLimit).map((eachCat,index)=>{
 
          
        if(eachCat?.RideCategoryFareID){

        const RowVehicletypes = eachCat?.VehicleTypeIDS?.map((eachVehicle)=>{
          return eachVehicle?.Type
        })

       const VehicleTypes = RowVehicletypes?.join(", ")

             const splitName = eachCat?.PaymentSplitID?.Splits?.map((eachSplit)=>{
             return eachSplit?.name
             })
            
          const allSplitNames = splitName?.join(", ")    
             
        // const {Block1,Block2,Block3} = eachCat?.HourlyBookingID
        const RowVehicleCat = eachCat?.VehicleCategory?.map((eachVehicle)=>{
          return eachVehicle
        })

        const fairPerKilometres = parseFloat(eachCat?.RideCategoryFareID?.FairPerKilometres);
        const basicFares = parseFloat(eachCat?.RideCategoryFareID?.MinCharge)
        const advancedFare = parseFloat(eachCat?.RideCategoryFareID?.AdvancedBookingID?.AdvancedBookingCost)
         itemCount =itemCount+1
        const items = {
                    
          _id:eachCat._id,
          id:itemCount-1+currentOffset,
          rideCategory:eachCat?.Name,
          rideservice:eachCat?.ValueService?.ServiceName,
          paymentSplit:eachCat?.RideCategoryFareID?.PaymentSplitID?.Name ||"N/A",
          seats:eachCat?.Seats.join(','),
          vehicleType:VehicleTypes||"N/A",
          vehicleCategory:RowVehicleCat?.join(", "),
          basicFare: isNaN(basicFares) ? "N/A" : `S$ ${basicFares.toFixed(2)}` || "N/A",
          minKm:eachCat?.RideCategoryFareID?.MinDistance||"N/A",
          costPerKm: isNaN(fairPerKilometres) ? "N/A" : `S$ ${fairPerKilometres.toFixed(2)}` || "N/A",
          progressingDistance:eachCat?.RideCategoryFareID?.ProgressiveDistanceThreshold,
          advFare: isNaN(advancedFare) ? "N/A" : `S$ ${advancedFare.toFixed(2)}`  || "N/A",
          status:eachCat?.isEnabled,
        
         }
         return items
        }else{
          return null
        }
        })
        const lastFil = filteredData?.filter((item) => item !== null)
      setRows(lastFil)
      }


        const filteredRowData = categoryData?.map((eachCat,index)=>{
 
          
          if(eachCat?.RideCategoryFareID){
  
          const RowVehicletypes = eachCat?.VehicleTypeIDS?.map((eachVehicle)=>{
            return eachVehicle?.Type
          })
  
         const VehicleTypes = RowVehicletypes?.join(", ")
  
               const splitName = eachCat?.PaymentSplitID?.Splits?.map((eachSplit)=>{
               return eachSplit?.name
               })
              
            const allSplitNames = splitName?.join(", ")    
               
          // const {Block1,Block2,Block3} = eachCat?.HourlyBookingID
          const RowVehicleCat = eachCat?.VehicleCategory?.map((eachVehicle)=>{
            return eachVehicle
          })
  
          const fairPerKilometres = parseFloat(eachCat?.RideCategoryFareID?.FairPerKilometres);
          const basicFares = parseFloat(eachCat?.RideCategoryFareID?.MinCharge)
          const advancedFare = parseFloat(eachCat?.RideCategoryFareID?.AdvancedBookingID?.AdvancedBookingCost)
           itemCount =itemCount+1
          const items = {
                      
            _id:eachCat._id,
            id:itemCount-1+currentOffset,
            rideCategory:eachCat?.Name,
            rideservice:eachCat?.ValueService?.ServiceName,
            paymentSplit:eachCat?.RideCategoryFareID?.PaymentSplitID?.Name ||"N/A",
            seats:eachCat?.Seats.join(','),
            vehicleType:VehicleTypes||"N/A",
            vehicleCategory:RowVehicleCat?.join(", "),
            basicFare: isNaN(basicFares) ? "N/A" : `S$ ${basicFares.toFixed(2)}` || "N/A",
            minKm:eachCat?.RideCategoryFareID?.MinDistance||"N/A",
            costPerKm: isNaN(fairPerKilometres) ? "N/A" : `S$ ${fairPerKilometres.toFixed(2)}` || "N/A",
            progressingDistance:eachCat?.RideCategoryFareID?.ProgressiveDistanceThreshold,
            advFare: isNaN(advancedFare) ? "N/A" : `S$ ${advancedFare.toFixed(2)}`  || "N/A",
            status:eachCat?.isEnabled,
          
           }
           return items
          }else{
            return null
          }
          })




       
        const lastFilTwo = filteredRowData?.filter((item) => item !== null)

      setTableData(lastFilTwo)


 
 } })

},[CountryID,currentPage,rowsPerPage,searchInput])

// searching items

useEffect(()=>{
  setRows(
    tableData.filter((item) =>
    item?.rideCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.rideservice?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.paymentSplit?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.seats?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.minKm?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.costPerKm?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.advFare?.toLowerCase().includes(searchInput.toLowerCase())
 
      ))
   },[searchInput])






const handleFilterBar = (status)=>{
  setIsFilterOn(status)
}



// delete status  
const handleServiceDelete =(id, newData)=>{

  console.log(`Delete with id ${id}. New data:`, newData);
  const paramsData ={valueServiceId:id}
  ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${id}`,null).then((data)=>{
    if(data?.status === 'success'){
      setSuccess(data) 
      // setDeleteStatus(false)
      toast.success("Successfully deleted.");
     }else{
      toast.error("Something went wrong.!");
     }
  })
  
}

// update status
const handleStatusChange =(id, newData)=>{
  //  console.log(`Switch with id ${id} changed. New data:`, newData);
   const paramsData = {valueServiceId:id,showInValueServices:newData?.status}
   ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
}

const handleServiceEdit =(id,newData)=>{
  console.log(newData,"<---newDataaaa")
  if(id){
    navigate(`/edit-fare/${id}`)
  }
}
const handleChangeInSearch = (value)=>{
  setSearchInput(value)
}


const columnSortingFn = (id) => {
  if (order === "ASC") {
    const sorted = [...rows].sort((a, b) =>
      id !== "id"
        ? a[id].toLowerCase() > b[id].toLowerCase()
          ? 1
          : -1
        : a[id] > b[id]
        ? 1
        : -1
    );
    setRows(sorted);
    setOrder("DSC");
  }

  if (order === "DSC") {
    const sorted = [...rows].sort((a, b) =>
      id !== "id" && id !== "ride_fare"
        ? a[id].toLowerCase() < b[id].toLowerCase()
          ? 1
          : -1
        : id === "date"
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : a[id] < b[id]
        ? 1
        : -1
    );
    setRows(sorted);
    setOrder("ASC");
  }
};




const handleColumnFilter = (items, searchText) => {


  console.log("items:",items ,"SearchText:",searchText)
  setSelectedItems(items); // Update selected filter items

  // If no items are selected and no search text, show all rows
  if (items.length === 0 && searchText === "") {
    setRows(tableData);
    return;
  }
       


  // Filter the rows based on the selected items and search text
  const filteredRows = tableData.filter((row) => {
    // Check if any of the selected items are present in the row's features
    const featuresMatch = items.every(
      (item) => row.features && row.features.includes(item)
    );

    // Check if the row matches any of the selected vehicleCategory items
    const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    const vehicleCat = items.every(
      (item) => row.vehicleCategory && row.vehicleCategory.includes(item)
    );

    // Check if the row matches all of the selected vehicleType items
    // const vehicleTypeMatch = items.every(
    //   (item) => row.vehicleType && row.vehicleType.includes(item)
    // );

    const vehicleTypeMatch = items.every(
      (item) => row.seats && row.seats.includes(item)
    );


    const serviceNameMatch = items.every(
      (item) => row.rideservice && row.rideservice.includes(item)
    );

    // Check if the row contains the search text in any of its values
    const searchTextMatch = Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    // Return true if any of the filters match the row, otherwise return false
    return (
      (vehicleCat || serviceNameMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
      searchTextMatch
    );
  });

  // Update the filtered rows
  setRows(filteredRows);
};

const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}



  return (
    any ? (
<Box>
<TableHead title={'Basic Fare'} />
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<DataTable
        handlingPage={handlePageChange}
        totalCount={totalRows}
        DeletePermission={false}
        ButtonPermission={write}
        EditPermission={edit}  
        isFilterOn={isFilterOn}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        openFilter={handleFilterBar}
        ButtonPath={'/create-fare'}
        ButtonLabel={'Create Fare'}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnFilter}
        // searchInput={handleChangeInSearch}
        handleRowSelection={(noOfrows) => setRowsPerPage(noOfrows) }
        filterComponent={<CategoryFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      />
</Box>
</Box>
): any === false ? (
<RestrictedUsage/>
) : null
  )
}

export default BasicFare
