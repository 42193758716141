import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead';
import DataTable from '../../Components/Table/DataTable';
import ApiCall from '../../../Helper/ApiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import RideFilter from './RideFilter';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { toast } from 'react-toastify';


  const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "rideService",
      label: "Ride Service",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "vehicleType",
      label: "Vehicle Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "vehicleCatogery",
      label: "Vehicle Category",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "features",
      label: "Features",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: true,
        onFalse: false,
      },
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ]; 



function RideServiceManagement() {
 
  useTitle("Ride Service - Geolah");

  const [rows, setRows] = useState();
  const [success, setSuccess] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const [order, setOrder] = useState("ASC");
  const [any,setAny] = useState()
  const [write,setWrite] = useState()
  const [edit,setEdit] = useState()
  const [deletePer,setDeletePer] = useState()
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [totalRows,setTotalRows] = useState(0)
  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)


  const navigate = useNavigate()
   const dispatch = useDispatch()
  dispatch(insertNavigationDataAction('Ride Service'))

//temp

useEffect(()=>{
  setSuccess(1000+1)
  },[])

  useEffect(()=>{
 let isAany = checkAnyPrivilage('Ride Service')
 setAny(isAany)

 let isWrite = checkPrivilage('Ride Service','CREATE')
 setWrite(isWrite)
let isEdit = checkPrivilage('Ride Service','UPDATE')
  setEdit(isEdit)

  let isDelete = checkPrivilage('Ride Service','DELETE')
  setDeletePer(isDelete)
  

  },[])

  

// api call
useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage

  ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
    const serviceData = data?.result
    setTotalRows(serviceData?.length)
    if(searchInput?.length <= 0){

   const filteredData =  serviceData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
     
    const rowVehicles =  eachItem?.VehicleTypeIDS?.map((eachVehicle)=>{
      return eachVehicle.Type;
    })
    const rowAdOnFeatures = eachItem?.AdOnFeaturesIDS?.map((eachFeature)=>{
      return eachFeature.Type;
    })

    const vehicles = rowVehicles?.filter((str) => str !== "");
    const adOnFeatures = rowAdOnFeatures?.filter((str) => str !== "");
    let items = {
          _id:eachItem._id,
          id:index+1+currentOffset,
          rideService:eachItem?.ServiceName,
          description:eachItem?.Description,
          vehicleType: vehicles?.join(", ") || 'N/A',
          vehicleCatogery: eachItem.VehicleCategory ?eachItem.VehicleCategory.join(", "):"N/A",
          features:adOnFeatures.length > 0 ? adOnFeatures?.join(", "): "N/A",
          status:eachItem?.IsEnabled
         }

         return items;
   })
   setRows(filteredData)
  }

   const filteredRowData =  serviceData?.map((eachItem,index)=>{
     
    const rowVehicles =  eachItem?.VehicleTypeIDS?.map((eachVehicle)=>{
      return eachVehicle.Type;
    })
    const rowAdOnFeatures = eachItem?.AdOnFeaturesIDS?.map((eachFeature)=>{
      return eachFeature.Type;
    })

    const vehicles = rowVehicles?.filter((str) => str !== "");
    const adOnFeatures = rowAdOnFeatures?.filter((str) => str !== "");
    let items = {
          _id:eachItem._id,
          id:index+1+currentOffset,
          rideService:eachItem?.ServiceName,
          description:eachItem?.Description,
          vehicleType: vehicles?.join(", ") || 'N/A',
          vehicleCatogery: eachItem.VehicleCategory ?eachItem.VehicleCategory.join(", "):"N/A",
          features:adOnFeatures.length > 0 ? adOnFeatures?.join(", "): "N/A",
          status:eachItem?.IsEnabled
         }

         return items;
   })

 
   setTableData(filteredRowData);
 } })
},[success,CountryID,searchInput,rowsPerPage])

// searching items

 useEffect(()=>{
  setRows(
    tableData?.filter((item) =>
    item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
    item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
      ))
   },[searchInput])



// update service status

  const handleStatusChange =(id, newData)=>{
      //  console.log(`Switch with id ${id} changed. New data:`, newData);
       const paramsData = {valueServiceId:id,status:newData?.status}
       ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
    }




// delete status  
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);
  setDeleteStatus(true)
  const paramsData ={id,name:newData?.rideService}
  setDeleteData(paramsData)
 
}

const onDeleteYes =(yesData)=>{
 ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
})
} 


const onCloseNotification = ()=>{
  setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
  if(id){
    navigate(`/edit-service/${id}`)
  }
 

}

// handle search change

const handleChangeInSearch = (value)=>{
   setSearchInput(value)
   console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
}





// const handleColumnFilter = (items)=>{
// console.log(items,"<------------")
// }











// const handleColumnFilter = (items) => {
//   setSelectedItems(items); // Update selected filter items

//   // If no items are selected, show all rows
//   if (items.length === 0) {
//     setRows(tableData);
//     return;
//   }

//   // Filter the rows based on the selected items
//   const filteredRows = tableData.filter((row) => {
//     // Check if any of the selected items are present in the row's features
//     const featuresMatch = items.every((item) => row.features.includes(item));

//     // Check if the row matches any of the selected vehicleCatogery items
//     const vehicleCatogeryMatch = items.includes(row.vehicleCatogery);

//     // Check if the row matches all of the selected vehicleType items
//     const vehicleTypeMatch = items.every((item) =>
//       row.vehicleType.includes(item)
//     );

//     // Return true if any of the filters match the row, otherwise return false
//     // return featuresMatch || vehicleCatogeryMatch || vehicleTypeMatch;
//     return featuresMatch || (vehicleCatogeryMatch && items.length === 1) || vehicleTypeMatch;
//   });

//   // Update the filtered rows
//   setRows(filteredRows);
// };


const handleColumnFilter = (items, searchText) => {

  console.log(items,"<---trhe itemssss")



  setSelectedItems(items); // Update selected filter items

  // If no items are selected and no search text, show all rows
  if (items.length === 0 && searchText === "") {
    setRows(tableData);
    return;
  }

 

  // Filter the rows based on the selected items and search text
  const filteredRows = tableData?.filter((row) => {
    // Check if any of the selected items are present in the row's features
    const featuresMatch = items.every(
      (item) => row.features && row.features.includes(item)
    );



    // Check if the row matches any of the selected vehicleCategory items
    const vehicleCategoryMatch = items.includes(row.vehicleCatogery);

    // Check if the row matches all of the selected vehicleType items
    const vehicleTypeMatch = items.every(
      (item) => row.vehicleType && row.vehicleType.includes(item)
    );



    // Check if the row contains the search text in any of its values
    const searchTextMatch = Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    // Return true if any of the filters match the row, otherwise return false
    return (
      (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
      searchTextMatch
    );
  });

  // Update the filtered rows
  setRows(filteredRows);
};



// return featuresMatch || (vehicleCatogeryMatch && items.length === 1) || vehicleTypeMatch;







// const applyFilter = (items) => {
//   // Check if the selected items array is empty
//   if (items.length === 0) {
//     // If no items are selected, display all rows
//     setRows(rows);
//   } else {
//     // Filter the rows based on the selected items
//     const filteredRows = rows.filter((row) => {
//       // Check if any of the selected items are present in the row's features
//       const featuresMatch = items.every((item) => row.features.includes(item));

//       // Check if the row matches any of the selected items in the "vehicleCatogery" column
//       const vehicleCatogeryMatch = items.includes(row.vehicleCatogery);

//       // Check if the row matches any of the selected items in the "vehicleType" column
//       const vehicleTypeMatch = items.some((item) => row.vehicleType.includes(item));

//       // Return true if any of the filters match the row, otherwise return false
//       return featuresMatch || vehicleCatogeryMatch || vehicleTypeMatch;
//     });

//     // Update the filtered rows
//     setRows(filteredRows);
//   }
// };



const handleFilterBar = (status)=>{
  setIsFilterOn(status)
}




    
    

    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };


 
const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}




  return (
    any === true ?
(<Box>
<TableHead title={'Ride Service Management'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
      handlingPage={handlePageChange}
      totalCount={totalRows}
      DeletePermission={deletePer}
      ButtonPermission={write}
      EditPermission={edit}
      isFilterOn={isFilterOn}
      openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-service'}
        ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        handleRowSelection={(noOfrows) => setRowsPerPage(noOfrows) }
        filterComponent={<RideFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      />
    </Box>

</Box>
</Box>): any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default RideServiceManagement
