import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ApiCall from '../../../Helper/ApiCalls';
import DataTable from '../../Components/Table/DataTable';
import TableHead from '../../Components/Table/TableHead';
import RideFilter from '../RideServiceManagement/RideFilter';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import DriverFIlters from './DriverFIlters';
import { da } from 'date-fns/locale';
import { baseUrl } from '../../RequestMethord';
import axios from 'axios';
import { toast } from 'react-toastify';




const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
    {
        id: "driverId",
        label: "Id",
        minWidth: 100,
        sort: false,
      },
  
    {
      id: "name",
      label: "Driver Name",
      minWidth: 170,
      align: "left",
      sort: true,
      isLink:true,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "isOnline",
      label: "Status",
      minWidth: 170,
      align: "center",
      sort: false,
      title: {
        onTrue: "Online",
        onFalse: "Offline",
      },
    },
    {
      id: "type",
      label: "Type",
      minWidth: 130,
      align: "left",
      sort: true,
    },
    {
      id: "vehicle",
      label: "Vehicle",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "bookings",
      label: "Bookings",
      minWidth: 130,
      align: "left",
      sort: true,
    },
    {
        id: "cancelled",
        label: "Cancelled",
        minWidth: 130,
        align: "left",
        sort: true,
      },
      // {
      //   id: "reviews",
      //   label: "Reviews",
      //   minWidth: 170,
      //   align: "left",
      //   sort: true,
      // },
      // {
      //   id: "request",
      //   label: "Request",
      //   minWidth: 170,
      //   align: "left",
      //   sort: true,
      // },

    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];






function DriverModule() {
  useTitle("Driver - Geolah");
  
//  STATES   
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [onlineStatus , setOnlineStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [onlineData , setOnlineData] = useState({})
    const [totalRows,setTotalRows] = useState(0)
    const [order, setOrder] = useState("ASC");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [showPending,setShowPending] = useState(false)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    
    dispatch(insertNavigationDataAction('Drivers'))
    const [any,setAny] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Drivers')
      setAny(isAany)
      let isWrite = checkPrivilage('Drivers','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Drivers','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Drivers','DELETE')
       setDeletePer(isDelete)
       },[])




// RENDERS AND API-CALLS

useEffect(()=>{

   let currentLimit = rowsPerPage
   let currentOffset = (currentPage-1) * rowsPerPage
  



    ApiCall("GET",`/trip-service/v1/admin/driver/profile/details/?Limit=${currentLimit}${showPending === true ? `&&changeStatus=${showPending}`:``}&&Offset=${currentOffset}&&countryId=${CountryID}${searchInput && `&&search=${searchInput}`}`, null, "uui").then((data)=>{
      if(data?.result){
       const driverData = data?.result?.driverDetails
       setTotalRows(data?.result?.totalCount)
       const filteredData = driverData?.map((eachItem,index)=>{
       let items={
        id:index+1+currentOffset,
        profileId:eachItem?._id,
        userID:eachItem?.UserID,
        driverId:eachItem?.UserID,
        name:eachItem?.FirstName+" "+eachItem?.LastName,
        phone:eachItem?.PhoneNumber,
        type:eachItem?.VehicleType?.[0]?.Type || 'N/A',
        vehicle:eachItem?.VehicleBrandModel?.[0]?.Name || 'N/A',
        bookings:eachItem?.TotalBooking,
        cancelled:eachItem?.TotalCancel,
        reviews:eachItem?.DriverRating?.TotalReviews || 'N/A',
        request:eachItem?.RequestCount,
        isOnline:eachItem?.OnlineStatus ? 'Online': 'Offline',
}

return items;

       })

       setRows(filteredData)
       setTableData(filteredData);

   } })
  },[success,CountryID,currentPage,rowsPerPage,searchInput,showPending])

  // useEffect(()=>{
  //   setRows(
  //     tableData.filter((item) =>
  //     item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.bookings?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.isOnline?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.driverId?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.phone?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.type?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.reviews?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.vehicle?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     item?.cancelled?.toString().toLowerCase().includes(searchInput.toLowerCase()) 
  //       ))
  //    },[searchInput])




//FUNCTIONS


//UPDATE STATUS
const handleStatusChange =(id, newData)=>{
    //  console.log(`Switch with id ${id} changed. New data:`, newData);
    //  const paramsData = {valueServiceId:id,status:newData?.status}
    //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
  }

//CHANGE ONLINE STATUSD

const handleOnlineStatus =(newData,status)=>{
  setOnlineStatus(true)
  const paramsData ={driverId:newData?.userID,status:status}
  setOnlineData(paramsData)
  } 



// DELETE STATUS 
const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={driverId:newData?.userID}
    setDeleteData(paramsData)
    }  
 
//DELETE
const onDeleteYes =(yesData)=>{
  
    ApiCall("PUT", `/user-service/v1/admin/driver/delete/driver-acc`,yesData,'BODY').then((data)=>{
      if(data?.status === 'success'){
      setSuccess(data) 
      setDeleteStatus(false)
      toast.success("Successfully deleted.");
     }else{
      toast.error("Something went wrong.!");
     }
       })
   }


  
// const onDeleteYes =(yesData)=>{
//   const apiUrl = `${baseUrl}/user-service/v1/app/auth/delete/account`;  
//   const TOKEN = localStorage.getItem("jwtToken");
//   const config = {
//     headers: {
//      'user_id':yesData?.driverId
//     }
//   };

//   axios.patch(apiUrl,config).then(res=>setSuccess(res))
//    }


//ONLINE

const onOnlineYes =(yesData)=>{
  
  ApiCall("PATCH", `/trip-service/v1/admin/driver/update/online/status`,yesData,'BODY').then((data)=>{
   setSuccess(data) 
  setOnlineStatus(false)
     })
 }

 //handle page change

 const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
}



//CLOSE DELETE NOTIFICATION

const onCloseNotification = ()=>{   
        setDeleteStatus(false)
        setOnlineStatus(false)

   //   }
  //   const handleServiceEdit =(id,newData)=>{
 //     if(id){
//       navigate(`/edit-service/${id}`)
//     }
   }
   
 //HANDLE SEARCH CHANGE
const handleChangeInSearch = (value)=>{
    setSearchInput(value)
   
 }

 
// COLUMN FILTER 
const handleColumnFilter = (items, searchText) => {



    setSelectedItems(items); // Update selected filter items
  
    // If no items are selected and no search text, show all rows
    if (items.length === 0 && searchText === "") {
      setRows(tableData);
      return;
    }
  
   
  
    // Filter the rows based on the selected items and search text
    const filteredRows = tableData.filter((row) => {
      // Check if any of the selected items are present in the row's features
      const featuresMatch = items.every(
        (item) => row.features && row.features.includes(item)
      );
  
  
  
      // Check if the row matches any of the selected vehicleCategory items
      const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
  
      // Check if the row matches all of the selected vehicleType items
      const vehicleTypeMatch = items.every(
        (item) => row.vehicleType && row.vehicleType.includes(item)
      );
  
  
  
      // Check if the row contains the search text in any of its values
      const searchTextMatch = Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
  
      // Return true if any of the filters match the row, otherwise return false
      return (
        (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
        searchTextMatch
      );
    });
  
    // Update the filtered rows
    setRows(filteredRows);
  };


// FILTERBAR
const handleFilterBar = (status)=>{
    setIsFilterOn(status)
    setShowPending(false)
  }

//NAVIGATE EDIT 
const handleServiceEdit =(id,newData)=>{
  console.log(id,"-rrr--r--r")
    if(newData?.userID){
      navigate(`/driver-details/${newData?.userID}`)
    }
   } 

 //NAVIGATE PROFILE DETAILS
 
 const handleProfileEdit = (id,newData)=>{
  const newTab = window?.open(`/driver-details/${id}`, '_blank');
  if (newTab) {
    newTab?.focus(); // Optionally, you can focus on the new tab
  }
    // if(id){
    //   navigate(`/driver-details/${id}`)
    // }
 }


//COLUMN FILTER
const columnSortingFn = (id) => {
  if (order === "ASC") {
    const sorted = [...rows].sort((a, b) =>
      id !== "id"
        ? a[id].toLowerCase() > b[id].toLowerCase()
          ? 1
          : -1
        : a[id] > b[id]
        ? 1
        : -1
    );
    setRows(sorted);
    setOrder("DSC");
  }

  if (order === "DSC") {
    const sorted = [...rows].sort((a, b) =>
      id !== "id" && id !== "ride_fare"
        ? a[id].toLowerCase() < b[id].toLowerCase()
          ? 1
          : -1
        : id === "date"
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : a[id] < b[id]
        ? 1
        : -1
    );
    setRows(sorted);
    setOrder("ASC");
  }
};

const handleColumnDateFilter =(data,status)=>{
   console.log(data , "<---checkedd")
  setShowPending(data)

}




  return (
any ? (   
<Box>
<TableHead title={'Drivers'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<NotifyAlertWhileDelete AlertNote={'Are you sure you want to change status.?'} OnDeleteYes={onOnlineYes} Info={onlineData} onClosing={onCloseNotification} openStatus={onlineStatus} />
<DataTable
    handlingPage={handlePageChange}
    totalCount={totalRows}
 DeletePermission={deletePer}
 ButtonPermission={write}
 EditPermission={edit} 
       handleClickProfileDetails={handleProfileEdit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-driver'}
        ButtonLabel={'Create Driver'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleOnline={handleOnlineStatus}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={ <DriverFIlters CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
</Box>
</Box>
</Box>) : any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default DriverModule
