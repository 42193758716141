import { Box, Button, FormControl, FormLabel, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField'
import ImageSelectorUpdate from '../../../Components/Forms/ImageSelectorUpdate'
import ApiCall from '../../../../Helper/ApiCalls';
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate';
import { NO_OF_SEATS, NO_OF_SEATS_CAT } from '../../../FakeData/FakeData';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import SnackBars from '../../../Components/Forms/SnackBars';
import { VehicleCategory } from '../../../FakeData/FakeData'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import InputField from '../../../Components/Forms/InputField';
import { useNavigate, useParams } from 'react-router-dom';
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate';
import { useSelector } from 'react-redux';
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate';
import { checkPrivilage } from '../../../../Sessions/Permisssions';

function EditCategoryForm() {

    const [submitHit,setSubmitHit] = useState(false)
    const [enable , setEnable] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const [vehicleType , setVehicleType] = useState([])
    const [serviceData , setServiceData] = useState([])
    const [selectedService , setSelectedService] = useState('')
    const [selectedSeats , setSelectedSeats] = useState([])
    const [selectedSeatNames , setSelectedSeatNames] = useState([])
    const [selectedVehicleType , setSelectedVehicleType] = useState([])
    const [selectedVehicleTypeNames , setSelectedVehicleTypeNames] = useState([])
    const [selectedCategory , setSelectedCategory] = useState([])
    const [selectedCategoryName , setSelectedCategoryName] = useState([])
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [imageFile, setImageFile] = useState({})
    const [ImageLink , setImageLink] = useState('')
    const [currentVehicleName , setCurrentVehicleName] = useState('')
    const [serviceName,setServiceName] = useState('')
    const [vehicleCategory,setVehicleCategory] = useState([])
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const navigate = useNavigate()

    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Ride Category','UPDATE')
      setEdit(isEdit)
  
       },[])
    
    const { id:categoryID } = useParams();


    useEffect(()=>{

      ApiCall("GET", "/trip-service/v1/admin/value-service/vehicle/type", null,).then((data)=>{
        if(data.result){
         const rowData = data?.result;
         const filteredData = rowData?.map((eachType)=>{
             return{id:eachType?._id,name:eachType?.Type}
         })
  
         setVehicleType(filteredData)
        }})
  
        ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
          if(data?.result){
            const serviceData = data?.result
            const foundObject = serviceData.find(item =>
              item.RideCategoryIDS.some(category => category._id === categoryID)
            );

      
            let vehicleType = foundObject?.VehicleTypeIDS
            console.log(vehicleType , "<======Serviceee")
            let vehicleCat = foundObject?.VehicleCategory
            const filteredVehicle = vehicleType?.map((eachType)=>{
              return{id:eachType?._id,name:eachType?.Type}
          })

          const filteredCat = vehicleCat?.map((eachType)=>{
            return{name:eachType}
        })

        setVehicleType(filteredVehicle)
        setVehicleCategory(filteredCat)
            


            setServiceName(foundObject?.ServiceName)

          setSelectedService(foundObject?._id)

     
            const filteredData =  serviceData?.map((eachItem,index)=>{
              return {id:eachItem?._id, name:eachItem?.ServiceName}
            })
      
       
           setServiceData(filteredData)
  
          }
        })
        },[])

  





    useEffect(()=>{

      if(categoryID){
     
        ApiCall("GET", `/trip-service/v1/admin/ride-category/${categoryID}`,null,).then((data)=>{
             if(data.result){

             

              const rowData = data?.result
       
              const seatss = rowData?.Seats
              const vehiclesTypes = rowData?.VehicleTypeIDS
            
              const vehiclesCat = rowData?.VehicleCategory
            
              const filterdSeats = seatss?.map((eachSeat , index)=>{
                return {name:eachSeat.toString()}
        
              }) 
              const filterdSeatSend = seatss?.map((eachSeat , index)=>{
                return eachSeat.toString()
        
              }) 

              const filteredVehicleId = vehiclesTypes?.map((eachVehicle,index)=>{
                return eachVehicle
              })

              const filteredVehicleNames = vehiclesTypes?.map((eachVehicle,index)=>{
                console.log(eachVehicle , "<---the achee vehicleeeeeaaaaaaa")
                console.log(vehicleType , "<--vehicleTypeee====")
                 let temp = vehicleType?.map((eachType)=>{
                    if(eachType?.id === eachVehicle){
                      return {name:eachType?.name,id:eachVehicle}
                    }
                  })
                   console.log(temp , "<=-----ffff")
                  return temp
                
              })

              console.log(filteredVehicleNames , " <-----vehicle nameeesssss")

              const filteredVehicleCatNames = vehiclesCat?.map((eachCat,index)=>{
                return {name : eachCat}
              })

              console.log(rowData?.Name,"<--====ooo")
        
              setCategoryName(rowData?.Name)
              setSelectedCategory(vehiclesCat)
              setSelectedCategoryName(filteredVehicleCatNames)
            
            setSelectedSeatNames(filterdSeats)
            setSelectedSeats(filterdSeatSend)
              setSelectedVehicleType(filteredVehicleId)
              setSelectedVehicleTypeNames(filteredVehicleNames?.[0])
              setImageLink(rowData?.IconUrl)
              setCurrentVehicleName(rowData?.VehicleTypeIDS?.[0].Type)
   
              
  
  
             
                 
             }
         })
         .catch((error) => {
           if(error.message){
             console.log(`Error -> ${error.message}`)
             }else{
               console.log('something went wrong [ridecategory - get]')
             }
         
         
         });
     
     
      }
     
     
      },[categoryID,vehicleType])
  

   console.log(selectedVehicleTypeNames , " ,-----tttttt")






    

    useEffect(()=>{

        const hasErrors =
        imageFile ? !imageFile : ImageLink.length < 1 ||
        categoryName?.length < 3 ||
        selectedVehicleType?.length < 1 ||
        selectedCategory?.length < 1 ||
        selectedService?.length < 1 
        // selectedSeats?.length < 1;
      
      setEnable(!hasErrors);
       },[imageFile,categoryName,selectedVehicleType,selectedCategory,selectedService])


       const handleSelect = (label,data)=>{
          
        if(label === 'Select Service'){
               setSelectedService(data?.id)
        }else if(label === 'Vehicle Category'){
                setSelectedCategory(data?.name)
        }
     
    
      }
 

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));



 


  


          const handleFormSubmit =()=>{

            setSubmitHit(true)
            if(enable){
              const formData = new FormData();
            imageFile && formData.append('RideCategoryIconImage',imageFile.file);
              formData.append('rideCategoryName',categoryName);
              formData.append('countryId',CountryID); 
              // formData.append('seats', selectedSeats);
              // formData.append('vehicletypeId', selectedVehicleType);
              // formData.append('vehicleCategory', selectedCategory);
              formData.append('valueServiceId',selectedService); 
              formData.append('rideCategoryId',categoryID); 


              selectedSeats.forEach((Seat, index) => {
                formData.append(`seats[${index}]`, Seat);
              });

              selectedVehicleType.forEach((Vehicle, index) => {
                formData.append(`vehicletypeId[${index}]`, Vehicle);
              });

              selectedCategory.forEach((category, index) => {
                formData.append(`vehicleCategory[${index}]`, category);
              });
              
      
              ApiCall("PUT", "/trip-service/v1/admin/ride-category/update", formData,).then((data)=>{
                if(data.result === 'success'){
                  setOnSuccess((i) => !i )
                  setOnApiSuccess("Succesfully edited category")
                  
            
                }
            })
            .catch((error) => {
               setOnFailed((i) => !i)
              
                if(error.message){
                  setOnApiFailed(error.message)
                  console.log(error , "<----errrrrrr")
                }else{
                  setOnApiFailed("Something went wrong.!")
                }
            
            
            });
      
            }
          }

          const handleImageFiles =(files)=>{
            setImageFile(files)
           }

      const handleDiscard =()=>{
            navigate(-1)
          }


          const handleMultipleSelect = (label,data)=>{

            if(label === 'Seat Number'){
     
             let filteredData = data?.map((item)=>{
               return item?.name
             })
         
             setSelectedSeats(filteredData)
         
           }else if(label === 'Vehicle Type'){
     
             let filteredData = data?.map((item)=>{
               return item?.id
             })
         setSelectedVehicleType(filteredData)
         
         
           }
           else if(label === 'Vehicle Category'){
             let filteredData = data?.map((item)=>{
               return item?.name
             })
             setSelectedCategory(filteredData)
         }
         
         }







  return (
    <Box>
    <Stack spacing={3} >
    <Stack spacing={2}>
    {/* <ImageSelector isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} /> */}
    <Box width={'30%'}>
     <ImageSelectorUpdate imageLink={ImageLink} isError={submitHit && !imageFile && ImageLink?.length < 1} handleFileChange ={handleImageFiles} />
     </Box>

    <Stack direction={isMatch ? 'column':'row'} spacing={3} >

    <Box width={'50%'}>
        {/* <InputField  isError={submitHit && categoryName?.length < 3 } ErrNote={""} InChange={(e) => setCategoryName(e.target.value)}   sideLabel={"Ride Category Name"} placeHolder={"Ride Category Name"} /> */}
        <InputFieldUpdate Value={categoryName} isError={submitHit && categoryName?.length < 3} ErrNote={""} InChange={(e) => setCategoryName(e.target.value)}   sideLabel={"Ride Category Name"} placeHolder={"Ride Category Name"} />
        </Box>
        <Box width={isMatch ? "43%" : "50%"} >
           
        <Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Service Name</FormLabel>
    
         </Stack>
        
         <OutlinedInput
         value={serviceName}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>

        </Box>

    </Stack>

    <Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
{/* <SelectFieldUpdate currentSelect={selectedSeats} isError={ submitHit && selectedSeats === undefined || selectedSeats === ''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Seat Number'} placeHolder={'choose'} /> */}
<MultipleSelectUpdate currentSelect={selectedSeatNames} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Seat Number"} Data={NO_OF_SEATS_CAT} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    {/* <SelectFieldUpdate currentSelect={currentVehicleName} isError={ submitHit && selectedVehicleType === undefined || selectedVehicleType === ''} onSelecting={handleSelect} Data={vehicleType} sideLabel={'Vehicle Type'} placeHolder={'choose'} /> */}
    <MultipleSelectUpdate currentSelect={selectedVehicleTypeNames|| []} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Type"} Data={vehicleType} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    {/* <SelectFieldUpdate currentSelect={selectedCategory} isError={ submitHit && selectedCategory === undefined || selectedCategory === ''} onSelecting={handleSelect} Data={vehicleCategory} sideLabel={'Vehicle Category'} placeHolder={'choose'} /> */}
    <MultipleSelectUpdate currentSelect={selectedCategoryName} isError={submitHit && selectedCategory === undefined || selectedCategory === ''} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} Data={vehicleCategory} />
    </Box>

</Stack>

    </Stack>
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
      color: 'black',
    },
    }}>Discard</Button>
   </Stack>
    </Stack>
  
  </Box>
  )
}

export default EditCategoryForm
