import { Box, Typography } from "@mui/material"
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs"


function TableHead({title , BreadList}) {
 


  return (
    <Box width='100%' display={'flex'} justifyContent={"space-between"}>
      <Typography variant='h6'>{title}</Typography>
      <Box marginRight={5}>
      <BreadCrumbs Breadcrumbz={BreadList} />
      </Box>
      </Box>
  )
}

export default TableHead
