import {
  Box,
  Grid,
  Skeleton,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableSearchExport from "./TableSearchExport";



function TableNav({ title }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        sxl: 1430,
        xl: 1536,
      },
    },
  });
  const isSxlMatch = useMediaQuery(theme?.breakpoints?.down("sxl"));
  const isMatch = useMediaQuery(theme?.breakpoints?.down("lg"));
  const isSmMatch = useMediaQuery(theme?.breakpoints?.down("sm"));
  const isMdMatch = useMediaQuery(theme?.breakpoints?.down("md"));

  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000)
  },[])

  return (
    <Box display='flex' justifyContent='space-between' >
    
    
          <Typography variant="h6">
           {
            loadingData ? 
            <Skeleton width={'70px'} variant="text" sx={{ fontSize: '1rem' }} /> :
          <strong>{title}</strong>  }
          </Typography>
     
    
       <Box marginRight='44px' >
    {
      loadingData ? <Box display='flex' justifyContent='space-between' >
        
       <Skeleton  variant="rounded" width={200} height={40} />
       <Skeleton sx={{marginLeft:'10px' , marginRight : '10px'}} variant="rounded" width={70} height={40} />
       <Skeleton variant="rounded" width={40} height={40} />       

      </Box>


      :
      <TableSearchExport />
    }
     

       </Box>
         
       
     
    </Box>
  );
}

export default TableNav;
