import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSelectBar from '../../../Components/Forms/CustomSelectBar'
import DashChart from '../../../Components/Graphs/DashChart'
import { MONTH_LIST, YEAR_LIST } from '../../../FakeData/FakeData'
import { getDayDates, getMonthDate, getWeekDate, getYearDate, processData, transformDataWithDays, transformDataWithYearRange, transformMonthData, transformSingleDay } from '../../../Components/MultiFunctions/MultiFunctions'
import ApiCall from '../../../../Helper/ApiCalls'
import { useDispatch, useSelector } from 'react-redux'
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { insertDashDataAction } from '../../../Redux/Actions/DashboardActions'

function TripDetailsOverview() {
  const dispatch = useDispatch()
const [selectedYear , setSelectedYear] = useState('')
const [selectedMonth , setSelectedMonth] = useState('')
const [selectedWeek,setSelectedWeek] = useState('')
const [noOfWeeks,setNoOfWeeks] = useState(0)
const [fromDate , setFromDate] = useState('2023-10-20T10:10:05.833Z')
const [toDate , setToDate] = useState('2023-10-20T10:10:05.833Z')
const [dayData , setDayData] = useState([])
const [selectDay,setSelectedDay] = useState(0)
const [selectedStatus , setSelectedStatus] = useState('Year')
const {currentCountrydata:CountryID} = useSelector((state) => state.country)
const [chartData , setChartData] = useState([])
const [wholeData , setWholeData] = useState({})
const [clearStatus , setClearStatus] = useState(false)


//HANDLING THE SELECTION AND SETTING VALUE 

const handleFilter =(field,title)=>{

  //YEAR

  if(title === 'Year'){
   setClearStatus(false)
    

   setSelectedYear(field)
   if(selectedYear?.length > 2 && selectedMonth?.length > 2 && selectedWeek?.length > 2 && selectDay){
    setSelectedStatus('')
    const singleDayRange = getDayDates(field,selectedMonth,selectDay)
    setFromDate(singleDayRange)
    setToDate(singleDayRange)
    
   }

  else if(selectedYear?.length > 2 && selectedMonth?.length > 2 && selectedWeek?.length > 2 ){
    setSelectedStatus('Week')
    const dateRange = getWeekDate(field,selectedMonth,selectedWeek);
    setDayData(dateRange?.daysRange)
     setFromDate(dateRange?.startDate)
     setToDate(dateRange?.endDate) 

   }else if(selectedYear?.length > 2 && selectedMonth?.length > 2){
    setSelectedStatus('')
    const { startDate, endDate } =  getMonthDate(field,selectedMonth) 
    setFromDate(startDate)
    setToDate(endDate) 
   }

   else{
    const { startDate, endDate } = getYearDate(field);
    setSelectedStatus('Month')
    setFromDate(startDate)
    setToDate(endDate) 
   }
   
  }

  //MONTH

  else if(title === 'Month'){

   setSelectedMonth(field)
   if(selectedYear?.length > 2 && selectedMonth?.length > 2 && selectedWeek?.length > 2 && selectDay){
    setSelectedStatus('')
    const singleDayRange = getDayDates(selectedYear,field,selectDay)
    setFromDate(singleDayRange)
    setToDate(singleDayRange)

   }

   else if(selectedYear?.length > 2 && selectedMonth?.length > 2 && selectedWeek?.length > 2 ){
    setSelectedStatus('')
    const dateRange = getWeekDate(selectedYear,field,selectedWeek);
    setDayData(dateRange?.daysRange) 
    setFromDate(dateRange?.startDate)
    setToDate(dateRange?.endDate)

   }
   else if(selectedYear?.length > 3){
    setSelectedStatus('')
    const { startDate, endDate , numWeeks } =  getMonthDate(selectedYear,field) 
    setFromDate(startDate)
    setToDate(endDate)    
    setNoOfWeeks(numWeeks)
    
   }


  }
  //WEEK

  else if(title === 'Week'){
 
    setSelectedWeek(field)
    const dateRange  = getWeekDate(selectedYear,selectedMonth,field);
  
    setDayData(dateRange?.daysRange)
    setFromDate(dateRange?.startDate)
    setToDate(dateRange?.endDate)
    setSelectedStatus('Week')
  }
   
 //DAYS

  else if(title === 'Days'){
  
    setSelectedDay(field)
    if(field){
      const singleDayRange = getDayDates(selectedYear,selectedMonth,field)
      setFromDate(singleDayRange?.startDate)
      setToDate(singleDayRange?.endDate)
      setSelectedStatus('Days')

     
    }
  



  }
}

const onClear=()=>{

  setSelectedWeek('')
  setSelectedMonth('')
  setSelectedYear('')
  setSelectedDay(0)
  setSelectedStatus('Year')
  setFromDate('2023-10-20T10:10:05.833Z')
  setToDate('2023-10-20T10:10:05.833Z')
  setDayData([])
  setClearStatus(true)




}


//API CALLS


useEffect(()=>{
 
  if(fromDate?.length > 0 && toDate?.length > 0){
  
    dispatch(insertDashDataAction({startDate:fromDate,endDate:toDate,status:selectedStatus}))

    ApiCall("GET", `/trip-service/v1/admin/dashboard/trip/details/?countryId=${CountryID}&&startDate=${fromDate}&&endDate=${toDate}&&${ selectedStatus && `status=${selectedStatus}`}`,null).then((data)=>{
      if(data.status === 'success'){
        const rowData = data?.result
        setWholeData(rowData)
        const tripDetails = rowData?.details
    
        if(selectedStatus === 'Month'){
          const filteredData = transformMonthData(tripDetails)
          setChartData(filteredData)
           
        }else if(selectedStatus === 'Year'){
          const filteredData = transformDataWithYearRange(tripDetails)
          setChartData(filteredData)
        
        }else if(selectedStatus === 'Week'){
    const filteredData = processData(tripDetails,dayData)
    setChartData(filteredData)
  
        }else if(selectedStatus === 'Days'){
          const filteredData = transformSingleDay(tripDetails)
      
          setChartData(filteredData)
        }
        else{

          const filteredData = transformDataWithDays(tripDetails)
          setChartData(filteredData)
    
        }   
    
      }
  }).catch((error) => {
     console.log(error,"<---the errrorr")
  });

  }

},[fromDate,toDate,selectedStatus])







  return (
<Box p={2} mb={2}  borderRadius={'8px'} bgcolor={'white'} width={ dayData?.length > 0 ?'680px' : '550px'} height={'513px'}>
{/* HEADER     */}

    <Box display={'flex'} justifyContent={'space-between'}>
     <Typography sx={{fontSize:"18px"}} variant='body1' >Trip Details</Typography>
     <Stack direction={'row'} spacing={1}>
     <CustomSelectBar clearState={clearStatus} handleSelection={handleFilter} Selectdata={YEAR_LIST} initialText={'Year'} />
     { selectedYear && <CustomSelectBar handleSelection={handleFilter} Selectdata={MONTH_LIST} initialText={'Month'}  />}
    { selectedMonth && <CustomSelectBar handleSelection={handleFilter} Selectdata={selectedMonth === 'Feb' ? ['week 1','week 2','week 3','week 4' ] : ['week 1','week 2','week 3','week 4' , 'week 5']} initialText={'Week'}  /> }
    { dayData?.length > 0 && <CustomSelectBar handleSelection={handleFilter} Selectdata={dayData} initialText={'Days'}  />}
    <IconButton onClick={() => onClear()} aria-label="delete" size="small">
        <ClearAllIcon fontSize="inherit" />
      </IconButton>

     </Stack>

    </Box>

{/* GRAPH     */}

<Box mt={2}>
<DashChart cardData={chartData?.[0]} cardLabel={chartData?.[1]}/>
</Box>

{/* DETAILS */}

<Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption' >Trip Completed</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >{wholeData?.tripCompleted}</Typography>
   </Box>

   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption' >Trip Ongoing</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >{wholeData?.tripOngoingCount}</Typography>
   </Box>


   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption' >Cancelled</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >{wholeData?.tripCancelled}</Typography>
   </Box>


   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption' >Drivers in Transits</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >{wholeData?.tripOngoingCount}</Typography>
   </Box>

   <Box p={1} borderBottom={'1px solid rgba(198, 198, 198, 0.5)'} mt={1}  display={'flex'} justifyContent={'space-between'}>
     <Typography  variant='caption' >Passengers in Transits</Typography>
     <Typography sx={{fontWeight:600}} variant='caption' >{wholeData?.tripOngoingCount}</Typography>
   </Box>




</Box>
  )
}

export default TripDetailsOverview
