import { Box } from '@mui/material'
import React from 'react'
import SingleCard from './SingleCard'
import { cardData } from '../../FakeData/FakeData'

function CardCollection() {
  return (
<Box display="flex" justifyContent="space-between" flexWrap="wrap"  >
{cardData?.map((eachItem , index)=>(
    <SingleCard cardName={eachItem?.name} cardImage={eachItem?.image} />

))}



</Box>
  )
}

export default CardCollection
