import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import SelectField from '../../../../Components/Forms/SelectField';
import { NO_OF_SEATS, licenceType, vocationalLicenceType } from '../../../../FakeData/FakeData';
import ImageSelectorUpdate from '../../../../Components/Forms/ImageSelectorUpdate';
import InputField from '../../../../Components/Forms/InputField';
import SaveAndVerify from '../../../../Components/Forms/SaveAndVerify';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate';
import SelectFieldUpdate from '../../../../Components/Forms/SelectFieldUpdate';
import NotifiProgress from '../../../../Components/Forms/NotifiProgress';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';
import MultipleSelectUpdate from '../../../../Components/Forms/MultipleSelectUpdate';
import { useSelector } from 'react-redux';

function DrivingLicenceVerification() {
  const [onSuccess , setOnSuccess] = useState(false)
  const [onSuccessv , setOnSuccessv] = useState(false)
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });

// STATES AND VARIABLE

const { id:driverId } = useParams(); 
const [submitHit,setSubmitHit] = useState(false)
const [submitHitv,setSubmitHitv] = useState(false)
const [currentDetails , setCurrentDetails] = useState({})
const [selectedIssueDate,setSelectedIssueDate] = useState('')
const [licenceNumber , setLicenceNumber] = useState('')
const [licenceFrontLink , setLicenceFrontLink] = useState('')
const [licenceBackLink , setLicenceBackLink] = useState('')
const [vocationalIssueDate , setVocationalIssueDate ] = useState('')
const [vocationalLicenceNumber , setVocationalLicenceNumber] = useState('')
const [vocationallicenceFrontLink , setVocationalLicenceFrontLink] = useState('')
const [vocationallicenceBackLink , setVocationalLicenceBackLink] = useState('')
const [frontImageFile , setFrontImageFile] = useState()
const [selectedLicenseType , setSelectedLicenseType] = useState([])
const [selectedVLicenseType , setSelectedVLicenseType] = useState('')
const [licenseClassData , setLicenceClassData] = useState([])
const [vLicenseClassData , setVlicenceClassData] = useState([])
const [selectedVid,setSelectedVid] = useState('')
const [selectedLid,setSelectedLid] = useState([])
// const { currentUserdata } = useSelector((state) => state.user);
    const[licenseFrontFile, setLicenseFrontFile] = useState({}) 
    const[licenseBackFile , setLicenseBackFile ] = useState({})
    const[vLicenseFrontFile, setVLicenseFrontFile] = useState({}) 
    const[vLicenseBackFile, setVLicenseBackFile] = useState({})
    const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false) 
const [remarkDetails,setRemarkDetails] = useState('')
const [isVerified,setIsVerified] = useState(false)
const [changedData , setChangedData] = useState({})
const [edit,setEdit] = useState()
const {currentUserdata} = useSelector((state) => state.user)


useEffect(()=>{
let isEdit = checkPrivilage('Driver Details','UPDATE')
setEdit(isEdit)
 },[])









//RENDERS AND APICALLS

useEffect(() => {

  const apiUrl = `${baseUrl}/trip-service/v1/driver/licence/activity`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

 axios.get(apiUrl, config).then(response => {

  if(response?.data?.result){
    const personalData =response?.data?.result
    setChangedData(personalData?.ChangingFeild)
   setCurrentDetails(response?.data?.result)
  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });




    const apiUrlTwo = `${baseUrl}/trip-service/v1/app/vehicle/license/class`;  
 
  
  
    axios.get(apiUrlTwo, config).then(response => {
   
     if(response?.data?.result){
      const classData = response?.data?.result
        let filtered = classData?.map((items)=>{
          return{id:items?._id,name:items?.Name}
        })

        setLicenceClassData(filtered)
     } 
       })
       .catch(error => {
         console.error(error,"GET API ERROR");
       });

const apiUrlThree = `${baseUrl}/trip-service/v1/app/vehicle/vocational/license`;  


axios.get(apiUrlThree, config).then(response => {
   
  if(response?.data?.result){

   const classData = response?.data?.result


     let filtered = classData?.map((items)=>{
       return{id:items?._id,name:items?.Type}
     })

     setVlicenceClassData(filtered)
  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });






}, [driverId,onSuccess,onSuccessv]);







useEffect(()=>{

  if(currentDetails){
    const licenceClass = currentDetails?.LicenceClassIDs
  
    
  let issueDate = new Date(currentDetails?.IssueDate)
  let vIssueDate = new Date(currentDetails?.VocationalLicenceDetails?.[0]?.VocIssueDate)

  let formattedDate = formatteDate(issueDate)
  let vFormattedDate = formatteDate(vIssueDate)
  setRemarkDetails(currentDetails?.Remarks || '')
  setIsVerified(currentDetails?.IsVerified)

  const filteredIds = licenceClass?.map((eachData)=>{
    return eachData?._id
  })
  setSelectedLid(filteredIds)

  setSelectedVid(currentDetails?.VocationalLicenceDetails?.[0]?.VocationalLicenceTypeID?._id)
  setSelectedIssueDate(formattedDate)
  setVocationalIssueDate(vFormattedDate)
  setLicenceNumber(currentDetails?.LicenceNumber)
  setVocationalLicenceNumber(currentDetails?.VocationalLicenceDetails?.[0]?.VocationalLicenceNumber)
  setLicenceFrontLink(currentDetails?.LicenceFrontImagePaths)
  setLicenceBackLink(currentDetails?.LicenceBackImagePaths)
  setVocationalLicenceFrontLink(currentDetails?.VocationalLicenceDetails?.[0]?.VocLicenceFrontImagePaths)
  setVocationalLicenceBackLink(currentDetails?.VocationalLicenceDetails?.[0]?.VocLicenceBackImagePaths)
  const filteredNames = licenceClass?.map((eachData)=>{
    return {id:eachData?._id,name:eachData?.Name}
  })
  setSelectedLicenseType(filteredNames)

  setSelectedVLicenseType(currentDetails?.VocationalLicenceDetails?.[0]?.VocationalLicenceTypeID?.Type)

  }

},[currentDetails])






//FUNCTIONS

const handleDateSelection =(date)=>{
  setSelectedIssueDate(date)
 }

 const handleDateSelectionVocational =(date)=>{
  setVocationalIssueDate(date)
 }


 const formatteDate = (issueDate)=>{
  let year = issueDate.getFullYear();
  let month = (issueDate.getMonth() + 1).toString().padStart(2, "0");
  let day = issueDate.getDate().toString().padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate
 }

 const handleImageFiles = (files)=>{
 
  setLicenseFrontFile(files)

  }

   
  const handleImageFilesTwo = (files)=>{
    setLicenseBackFile(files)
  
    }

    const handleImageFilesThree = (files)=>{
      setVLicenseFrontFile(files)
    
      }
      const handleImageFilesFour = (files)=>{
        setVLicenseBackFile(files)
      
        }

    



        const handleMultipleSelect = (label,data)=>{

          if(label === 'License Type'){
      
            let filteredData = data?.map((item)=>{
              return item?.id
            })
      
            setSelectedLid(filteredData)
      
          }
      
        }


  const handleSelect = (label,data)=>{
          
    // if(label === 'License Type'){
    //        setSelectedLid(data?.id)
    // }
    if(label === 'Vocational License Type'){
      setSelectedVid(data?.id)
                  }
  
  }




  const handleSubmit = ()=>{
    setSubmitHit(true);
    const formData = new FormData();
  
    
    formData.append('driverId',driverId);
    formData.append('adminUserId', currentUserdata?.ID);
    // formData.append('licenceClassIds', selectedLid);       
    selectedLid.forEach((classId, index) => {
      formData.append(`licenceClassIds[${index}]`, classId);
    });
    formData.append('licenceNumber', licenceNumber);
    formData.append('issueDate', selectedIssueDate);
    formData.append('vocationalLicenceTypeID',selectedVid); 
    formData.append('vocationalLicenceNumber', vocationalLicenceNumber);
    formData.append('vocIssueDate',vocationalIssueDate );
    remarkDetails?.length > 0 && formData.append('remarks', remarkDetails.toString());
   
    licenseFrontFile && formData.append('LicenceFrontImage',licenseFrontFile.file);
    licenseBackFile && formData.append('LicenceBackImage',licenseBackFile.file);
    vLicenseBackFile && formData.append('VocLicenceFrontImage',vLicenseBackFile.file);
    vLicenseFrontFile && formData.append('VocLicenceBackImage',vLicenseBackFile.file);
  
  
    const apiUrl = `${baseUrl}/trip-service/v1/driver/update/licence/details`;  
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
        'Content-Type': 'multipart/form-data',
  
      },
    };
  
    axios.put(apiUrl, formData, config)
    .then(response => {
      if(response?.data?.status === 'success'){
        setOnSuccess(true)
      }
    })
    .catch(error => {
      setOnFailed((i) => !i)
      
      if(error.message){
        setOnApiFailed(error.message)
      }else{
        setOnApiFailed("Something went wrong.!")
      }
      console.error(error, "PUT API ERROR");
    });
  
  }

  const handleVerify =()=>{
    setSubmitHitv(true);
    const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/verify/license/details`; 
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
        'Content-Type': 'application/json', // Set the content type for JSON data
      },
    };
  
    let verifyData={driverId:driverId}
  
    axios.put(apiUrl,verifyData,config)
    .then(response => {
  
      if(response?.data?.status === 'success'){
        setOnSuccessv(true)
      }
    })
    .catch(error => {
      setOnFailed((i) => !i)
      
      if(error.message){
        setOnApiFailed(error.message)
      }else{
        setOnApiFailed("Something went wrong.!")
      }
      console.error(error, "PUT API ERROR");
    });
    
  }



 




    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
<Stack spacing={2}>
 {/* FIRST ROW    */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'}>

{selectedLicenseType?.length > 0 && <MultipleSelectUpdate currentSelect={selectedLicenseType}   isError={changedData?.LicenceClassIDs? true : false} onMultiSelect={handleMultipleSelect} sideLabel={"License Type"} Data={licenseClassData} />}
{/* <SelectFieldUpdate currentSelect={selectedLicenseType} isError={changedData?.LicenceClassID? true : false} onSelecting={handleSelect} Data={licenseClassData} sideLabel={'License Type'} placeHolder={'choose'} /> */}
    </Box>
    <Box width={isMatch?'100%':'25%'} >

<InputFieldUpdate Note={licenceNumber?.length > 9 && 'Invalid number' || licenceNumber?.length < 5 && 'Invalid number'  } Value={licenceNumber} isError={changedData?.LicenceNumber? true : false} ErrNote={""} InChange={(e) => setLicenceNumber(e.target.value)}   sideLabel={"License Number"} placeHolder={"License Number"} />
</Box>
<Box width={isMatch ? "100%" : "25%"} >
    {/* <DatePickerComponent sideLabel={'Issue Date'}/> */}
    <DatePickerComponent disableFuture={true} isError={changedData?.IssueDate? true : false} initialValue={selectedIssueDate} handleSelection={handleDateSelection} sideLabel={'Issue Date'}/>
    </Box>
</Stack>

{/* SECOND ROW */}


{/* THIRD ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelectorUpdate sideLabel={'Front Picture'} imageLink={licenceFrontLink} isError={changedData?.LicenceFrontImagePaths? true : false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelectorUpdate sideLabel={'Back Picture'} imageLink={licenceBackLink} isError={changedData?.LicenceBackImagePaths? true : false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>

{/* VOCATIONAL SECTION */}


<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'}>
{/* <SelectField isError={''} onSelecting={()=>{}} Data={NO_OF_SEATS} sideLabel={'Vocational License Type'} placeHolder={'choose'} /> */}
<SelectFieldUpdate currentSelect={selectedVLicenseType} isError={changedData?.VocationalLicenceDetails?.VocationalLicenceTypeID?.valueInObj1? true : false} onSelecting={handleSelect} Data={vLicenseClassData} sideLabel={'Vocational License Type'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'} >
{/* <InputField isError={''} ErrNote={""} InChange={(e) => {}}  sideLabel={"Vocational License Number"} placeHolder={"Vocational License Number * "} /> */}
<InputFieldUpdate Note={vocationalLicenceNumber?.length > 9 && 'Invalid number' || vocationalLicenceNumber?.length < 5 && 'Invalid number'  } Value={vocationalLicenceNumber} isError={changedData?.VocationalLicenceDetails?.VocationalLicenceNumber?.valueInObj1 ? true : false} ErrNote={""} InChange={(e) => setVocationalLicenceNumber(e.target.value)}   sideLabel={"Vocational License Number"} placeHolder={"Vocational License Number"} />
</Box>
<Box width={isMatch ? "100%" : "25%"} >
    {/* <DatePickerComponent sideLabel={'Issue Date'}/> */}
    <DatePickerComponent disableFuture={true} isError={changedData?.VocationalLicenceDetails?.VocIssueDate?.valueInObj1 ? true : false} initialValue={vocationalIssueDate} handleSelection={handleDateSelectionVocational} sideLabel={'Issue Date'}/>
    </Box>
</Stack>

{/* SECOND ROW */}

{/* THIRD ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
{/* <ImageSelectorUpdate sideLabel={'Front Picture'} imageLink={'https://www.fakeidscard.com/wp-content/uploads/2023/templates/driver-license/singapore-driver-license-template/cropped-singapore-driver-license-template_0_cp-570x342.jpg'} isError={''} handleFileChange ={()=>{}} /> */}
<ImageSelectorUpdate sideLabel={'Front Picture'} imageLink={vocationallicenceFrontLink} isError={changedData?.VocationalLicenceDetails?.VocLicenceFrontImagePaths?.valueInObj1 ? true : false} handleFileChange ={handleImageFilesThree} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
{/* <ImageSelectorUpdate sideLabel={'Back Picture'} imageLink={''} isError={''} handleFileChange ={()=>{}} /> */}
<ImageSelectorUpdate sideLabel={'Back Picture'} imageLink={vocationallicenceBackLink} isError={changedData?.VocationalLicenceDetails?.VocLicenceBackImagePaths?.valueInObj1 ? true : false} handleFileChange ={handleImageFilesFour} />
</Box>
</Stack>

<Box width={isMatch?'100%':'32.2%'} >
<InputFieldUpdate notRequired={true} Value={remarkDetails} isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
</Box>

</Stack>
<NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
<NotifiProgress closeNotification={() => setOnSuccessv(false)} submitStatus={submitHitv} successNote={'Success'} Status={onSuccessv} />
<ErrorNotify submitStatus={submitHit || submitHitv} Status={submitHit && onFailed} errorNote={onApiFailed} />
<SaveAndVerify buttonStatus={edit} verificationStatus={isVerified} handleYesClick={handleVerify}  handleSubmitHere={handleSubmit} />
  </Box>
  )
}

export default DrivingLicenceVerification
