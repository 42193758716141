import { Avatar, Box, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

function ActivityCard({data}) {
  return (
  <Box mt={3}>
    <Stack spacing={2} direction={'row'}>
    <Avatar alt="Remy Sharp" src={data?.driverProfileDetails?.[0]?.DriverProfilePicPath||"https://w7.pngwing.com/pngs/481/915/png-transparent-computer-icons-user-avatar-woman-avatar-computer-business-conversation-thumbnail.png"} sx={{ width: 50, height: 50 }}/>
    <Stack spacing={1}>
    <Typography sx={{fontWeight:600}} variant='body1'>{data?.Activity}</Typography>
    {data?.DateTime && <Typography sx={{color:"#c6c6c6"}}  variant='caption'>{`${dayjs(data?.DateTime).format('YYYY-MM-DD')} - ${data?.DateTime?.slice(11, 16)} `}</Typography>}
    {/* <Typography sx={{color:"#000000"}}  variant='caption'>100</Typography> */}
    </Stack>
    </Stack>


  </Box>
  )
}

export default ActivityCard
