import {
 
    Box,
 
    Paper,
    Stack,
 
    Typography,
    createTheme,
    useMediaQuery,
   
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { Link as Link1, useNavigate, useParams } from "react-router-dom";
  import { loginApiCall } from "../Helper/ApiCalls";

  import mainLogo from "../Assets/Group 10.png";
  import { LoadingButton } from "@mui/lab";

  import PasswordField from "../App/Components/Forms/PasswordField";
import Confirmation from "../App/Components/ConfirmationPage/Confirmation";

  
  function ResetPassword() {
    
  
    const [loadingData, setLoadingData] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword , setConfirmPassword] = useState("")
    const [isNotValidate, setIsNotValidate] = useState(false);
    const [errorNote, setErrorNote] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);
    const { id:token } = useParams(); 
  
  
  
    useEffect(() => {
      function delayedFunction() {
        setLoadingData(false);
      }
      const timeoutId = setTimeout(delayedFunction, 500);
  
      return () => clearTimeout(timeoutId); // Cleanup the timeout
    }, []);
  
    // useEffect(()=>{
    //   if(currentUserdata?.ID){
    //      navigate('/')
    //   }
  
    // },[currentUserdata])
  

  
  
  
    const handleLogin = () => {
    const sendData ={
    password,
    token
    }
      if (password === confirmPassword) {
        setIsNotValidate(false);
        loginApiCall("POST", "/gateway/general/reset/password",sendData, "uui")
          .then((Data) => {
      
            if (Data?.result === 'success') { 
              setResetSuccess(true)    
            }
          })
          .catch((error) => {
            setIsNotValidate(true);
            
              if(error.message){
                setErrorNote(error.message);
              }else{
                setErrorNote("error occured");
              }
          
         
          });
      } else {
        setIsNotValidate(true);
        setErrorNote("Password missmatch");
      }
    };
  
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          smd: 855,
          md: 900,
          fmd: 950,
          slg: 1098,
          lg: 1200,
          sxl: 1430,
          xl: 1836, 
        },
      },
    });
  
    const navigate = useNavigate();
    const isMatch = useMediaQuery(theme.breakpoints.down("lg")); //sets true below breakpoint
    const secMatch = useMediaQuery(theme.breakpoints.down("md")); //sets true below breakpoint
    const smMatch = useMediaQuery(theme.breakpoints.down("sm")); //sets true below breakpoint
    const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  
    return !resetSuccess ? (
      <>
        <Box
          bgcolor
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            "& > :not(style)": {
              m: 1,
              width:
                isMatch === true && secMatch === false
                  ? "50%"
                  : secMatch === true && smMatch === false
                  ? "80%"
                  : smMatch
                  ? "100%"
                  : "35%",
              height: xlMatch ? 650 : 550,
            },
            minHeight:
              secMatch === true && smMatch === false
                ? "100vh"
                : smMatch
                ? "85vh"
                : "100vh",
          }}
        >

            

            
          <Paper elevation={3}>
          <Stack
            // display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            spacing={4}
          >
              <img
                width={53}
                height={96}
                sizes="small"
                src={mainLogo}
                alt="logo"
              />
              <Typography fontWeight={700} variant="h6">
                Admin Reset Password
              </Typography>
              
              <Box pl={13} alignItems={'center'} width={"70%"}>
              <PasswordField isError={isNotValidate} ErrNote={errorNote} InChange={(e) => setPassword(e.target.value)} sideLabel={"Password"} placeHolder={"Password"} />
              </Box>
              <Box  pl={13} width={"70%"}>
              <PasswordField isError={isNotValidate || confirmPassword?.length > 0 && password !== confirmPassword  } ErrNote={errorNote} InChange={(e) => setConfirmPassword(e.target.value)} sideLabel={"Confirm Password"} placeHolder={"Confirm Password"} />
              </Box>
             { isNotValidate &&
        <Typography variant="caption" color="error">{errorNote}</Typography>
             }
           
  
          
  
              <LoadingButton
                onClick={handleLogin}
                loadingPosition="end"
                sx={{
                  width: "50%",
                  fontWeight: 800,
                  textTransform: "inherit",
                  backgroundColor: "#021640",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#2E2D33",
                  },
                }}
                variant="contained"
              >
                Reset Password
              </LoadingButton>
            </Stack>
          </Paper>
        </Box>
      </> 
    ):(
  <Confirmation Link={"signin"} title={'Password Reset Successfull'} description={" Your password has been successfully reset.You can now log in with your new password. "} />
    )
  }
  
  export default ResetPassword;