import { Box } from "@mui/material";
import React, { useState } from "react";
import TableNav from "../../Components/TableSearchExport/TableNav";
import DataTable from "../../Components/Table/DataTable";

import couponMockData from "../../FakeData/MOCK_DATA2.json";
import CreateTab from "../../Components/Forms/CreateTab";
import FormModal from "../../Components/Modal/FormModal";
import AddCoupon from "../../Components/Coupons/AddCoupon";

const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },

  {
    id: "code_name",
    label: "Code_Name",
    minWidth: 100,
    align: "left",
    sort: false,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "value",
    label: "Value",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "usage_limit",
    label: "Usage Limit",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "user_used",
    label: "User Used",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "start_date",
    label: "Start Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "end_date",
    label: "End Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Active",
      onFalse: "InActive",
    },
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];

function Coupons() {
  const [rows, setRows] = useState(couponMockData);
  const [order, setOrder] = useState("ASC");
  const [modalStatus, setModalStatus] = useState(false);
  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };

  const onClick = () => {
    setModalStatus(true);
  };

  const onClose = () => {
    setModalStatus(false);
  };

  return (
    
    <Box>
      <TableNav title={"Coupon Code"} />
      <CreateTab handleClick={onClick} title={"Create Coupon Code"} />
      <FormModal
        handleclose={onClose}
        status={modalStatus}
        component={<AddCoupon />}
      />

      <Box sx={{ width: "100%", height: "100%" }} pt={1}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box>
    </Box>
  );
}

export default Coupons;
