import { Box, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../Hooks/UseTitle';
import { useNavigate, useParams } from 'react-router-dom';
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import VehicleInformationCard from '../../DriverModule/DriverDetails/VehicleInformationCard';
import Tabs from '../../../Components/Tabs/Tabs';
import VehicleDetailsDriver from './Pages/VehicleDetailsDriver';
import VehicleDetailsHistory from './Pages/VehicleDetailsHistory';
import VehicleDetailsDocuments from './Pages/VehicleDetailsDocuments';
import UserLicense from '../../User/UserDetails/Pages/UserLicense';
import VehicleDetailsService from './Pages/VehicleDetailsService';
import Activity from '../../DriverModule/DriverDetails/GeneralDetails/Pages/Activity';
import ApiCall from '../../../../Helper/ApiCalls';
import dayjs from 'dayjs';
import VehicleDocumentsList from './Pages/VehicleDocumentsList';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';





  const TabItems = [
    { name: "Driver",
     value: "Driver" },
    {
      name: "History",
      value: "History",
    },
    {
      name: "Documents",
      value: "Documents",
    },
    {
      name: "Service",
      value: "Service",
    },
  
    {
      name: "Activity",
      value: "Activity",
      },

  ];




function VehicleMainDetails() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate('/vehicles')}  key="3" color="text.primary">
      Vehicles
      </Typography>,
  <Typography key="3" color="text.primary">
     Vehicle Details
      </Typography>,
  ];
    useTitle("Vehicle Details - Geolah");
    const theme = createTheme({
        breakpoints: {
          values: {
            ht: 1800,
        
          },
        },
      });

// STATES AND VARIABLES 
const [vehicleDetails,setVehicleDetails] = useState({})
const { id:vehicleId } = useParams(); 
const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
const [driverDetails,setDriverDetails] = useState({})
const [regNo , setRegNo] = useState('')
const [activityData , setActivityData] = useState([])
const [cardList,setCardList] = useState([])
const [any,setAny] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Vehicle Details')
  setAny(isAany)
   },[])


// RENDERS AND API CALLS

useEffect(()=>{
    if(vehicleId){

      ApiCall("GET", `/trip-service/v1/admin/vehicle/information/?driverVehicleId=${vehicleId}`,null).then((data,error)=>{
        if(data.result){
        const driverDetails  = data.result?.[0]
        setRegNo(driverDetails?.RegistrationNumber)


         let vehicleData={
          vehicleImage:driverDetails?.VehicleFrontUrl          ,
           brandName:driverDetails?.VehicleBrand?.[0]?.Name,
           modelName:driverDetails?.VehicleBrandModel?.[0]?.Name,
           vehicleColor:driverDetails?.VehicleColor,
           vehicleSeats:driverDetails?.Seats,
           regNo:driverDetails?.RegistrationNumber,
           addOnFeature:driverDetails?.AdOnFeatures,
           insuranceExp: dayjs(driverDetails?.VehicleInsuranceDetails?.[0]?.ExpiryDate).format('DD-MM-YYYY') || '12/06/2024'
          }

          let driverData = {
            driverName:driverDetails?.DriverProfileDetails?.[0]?.FirstName+" "+driverDetails?.DriverProfileDetails?.[0]?.LastName,
            driverEmail:driverDetails?.DriverProfileDetails?.[0]?.Email,
            driverPhone:driverDetails?.DriverProfileDetails?.[0]?.PhoneNumber,
            driverReview:driverDetails?.DriverProfileDetails?.[0]?.DriverRating?.TotalReviews,
            driverRating:(driverDetails?.DriverProfileDetails?.[0]?.DriverRating?.TotalRating/100)*5,
            driverPic:driverDetails?.DriverProfileDetails?.[0]?.DriverProfilePicPath
          }
         setVehicleDetails(vehicleData)
         setDriverDetails(driverData)

              
        }
    })
    .catch((error) => {
      if(error.message){
      console.log(`Error -> ${error.message}`)
        }else{
          console.log('something went wrong [ridecategory - get]')
        }});

    }

},[])


useEffect(()=>{
  if(regNo?.length > 0){
    ApiCall("GET", `/trip-service/v1/admin/vehicle/activity/?vehicleRegistrationNumber=${regNo}`,null).then((data)=>{
      if(data.result){
        const activityData = data?.result
        const filteredData = activityData?.map((eachData,index)=>{

          let dateString = eachData?.DateTime
          const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
          const currentStartTime  =  dateString?.slice(11, 16);      

          let Items ={
           Title:eachData?.Activity,
           Date:formattedDate,
           Time:currentStartTime,
          }

          return Items
        })
        setActivityData(filteredData)    
      }})
      .catch((error) => {
    if(error.message){
    console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong [ridecategory - get]')
      }});

//DOCUMENTSSS

ApiCall("GET", `/trip-service/v1/admin/vehicle/docs/?vehicleRegistrationNumber=${regNo}&&driverVehicleId=${vehicleId}`,null).then((data)=>{
  if(data.result){
    const documentData = data?.result
    const vehicleDocs = documentData?.getvehicleDocs
    const insuranceImage = documentData?.DriverInsuranceDocs
    console.log(insuranceImage,"<--#EE##EE##EE##EE##EE")
    const issueDate = vehicleDocs?.createdAt
    const formattedDate = dayjs(issueDate).format('DD-MM-YYYY')
    const currentStartTime  =  issueDate?.slice(11, 16); 

    const docArray = [
      {
        title:"LTA Vehicle Log",
        image:vehicleDocs?.[0]?.LTAVehicleLog,
        date:formattedDate
      },{
        title:"Phv Decal image",
        image:vehicleDocs?.[0]?.PhvDecalPath,
        date:formattedDate
      },{
        title:"Mdt Screen image",
        image:vehicleDocs?.[0]?.MdtScreenUpload,
        date:formattedDate
      },
       {
          title:"Letter Of Employment",
          image:vehicleDocs?.[0]?.LetterOfEmployment,
          date:formattedDate
        },
        {
          title:"Insurance Front Image",
          image:insuranceImage?.[0]?.InsuranceFrontPath,
          date:formattedDate
        },
        
      
      
    ]


    const filteredData = docArray.filter(item => {
      // Keep items where all values are defined
      return (
        item.title !== undefined &&
        item.image !== undefined &&
        item.date !== undefined
      );
    });

    setCardList(filteredData)
        
  }
})
.catch((error) => {
if(error.message){
console.log(`Error -> ${error.message}`)
  }else{
    console.log('something went wrong [ridecategory - get]')
  }});











  }

},[regNo])


const Components = [

    {
      page: <VehicleDetailsDriver ProfileData={driverDetails}/>,
      value: "Driver",
    },
    {
        page: <VehicleDetailsHistory/>,
        value: "History",
      },
      {
        page: <VehicleDocumentsList DocList={cardList}/>,
        value: "Documents",
      },
      {
        page: <VehicleDetailsService/>,
        value: "Service",
      },
      {
        page: <Activity AllActivity={activityData}/>,
        value: "Activity",
      },
      
    //   {
    //     page: <InsuranceDetailsVerification/>,
    //     value: "Insurance Details",
    //   },
 
 
  ];

      
  return (
  any ? (

 
  <Box>
    <CreatePageHead title={'Vehicle Details'} BackPath={'/vehicles'} BreadList={breadcrumbs}/>
    <Box p={3} display={'flex'} flexDirection={isMatch?'column':'row'} justifyContent={isMatch?'center':'space-between'}>
    <VehicleInformationCard isVehicle={true} VehicleData={vehicleDetails} />
    </Box>
    <Box p={2}  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100%' >
    <Tabs current={'Driver'} List={TabItems} component={Components} />
    </Box>
  </Box>  ) :any === false ? (
    <RestrictedUsage/>
  ) : null

  )
}

export default VehicleMainDetails
