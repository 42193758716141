import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import DetailsNavSkelton from './DetailsNavSkelton';

function DetailsNav({title}) {
  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])



  const navigate = useNavigate()
  const onBackClick = ()=>{
       navigate(-1)
  }
  return (
   loadingData ? (
    <DetailsNavSkelton/>
   ): (

   

    <Box alignItems='center' display='flex' justifyContent='flex-start' >
    
    <Stack alignItems='center' direction='row' spacing={2}  >
    <IconButton onClick={onBackClick} color="inherit" sx={{ border: 0.001, borderColor: "GrayText" }}>
    <KeyboardBackspaceIcon />
    </IconButton>

   
    <Typography variant="h6">
      <strong>{title}</strong>
    </Typography>
    </Stack>



   
 

</Box>
)
  )
}

export default DetailsNav
