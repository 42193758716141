import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import DayFairForm from './DayFairForm'

function DayFair() {
  return (
    
   <Box width={'49.5%'} height={'670px'} >
    <Stack spacing={2} >
    <Typography fontWeight={600} >Day Fair management</Typography>
  <Paper  sx={{width:'100%',height:'670px',padding:'20px'}} elevation={3} >
<DayFairForm/>
</Paper>
    </Stack>

   </Box>
  )
}

export default DayFair
