import { Avatar, Box, IconButton, Rating, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import emailIcon from '../../../../../Assets/Forms/Visual.svg'
import phoneIcon from '../../../../../Assets/Forms/Visual (1).svg'

function VehicleDetailsDriver({ProfileData}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            ht: 1268,
        
          },
        },
      });
      const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
  return (
<Box sx={{border:"1px solid #c6c6c6"}} mt={2} flexDirection={isMatch ? 'column' : 'row'} borderRadius={'8px'} width={'50%'} height={isMatch ? '360px':'180px'} bgcolor={'white'} display={'flex'} justifyContent={'space-between'}>

{/* BOX ONE */}
    <Box display={'flex'} justifyContent={'space-between'} width={'30%'} >
    <Stack justifyContent={'center'} padding={3} spacing={3}>
    <Stack direction={'row'} spacing={3}>
    <Avatar src={ProfileData?.driverPic} sx={{ width: 64, height: 64 }}/>
    <Stack spacing={0.3}>
    <Typography fontWeight={'bold'} variant='p'>{ProfileData?.driverName}</Typography>
    <Typography variant='caption'>Driver</Typography>
    </Stack>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2} >
 <Typography fontWeight={'bold'} variant='p'>{parseInt(ProfileData?.driverRating) || 0}</Typography>
 <Rating name="read-only" value={parseInt(ProfileData?.driverRating)} readOnly />
 <Typography variant='caption'>{`${ProfileData?.driverReview || 0} reviews`}</Typography>
 </Stack>
    {/* <IconButton> <img alt='edit' src={editIcon}/> </IconButton> */}
 
     </Stack>

     <Stack justifyContent={'center'} padding={3} spacing={3}>
      
     <Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='email' src={emailIcon}/> </IconButton>
<Stack spacing={0.2}>
<Typography variant='caption'>Email</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{ProfileData?.driverEmail}</Typography>
</Stack>
</Stack>



<Stack alignItems={'center'} direction={'row'} spacing={1} >

  <IconButton> <img alt='phone' src={phoneIcon}/> </IconButton>
  <Stack spacing={0.2}>
<Typography variant='caption'>Phone</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{ProfileData?.driverPhone}</Typography>
</Stack>
</Stack>

     </Stack>
</Box>


{/* BOX TWO */}
  <Box  display={'flex'} justifyContent={'space-between'} width={'30%'} >
 
  </Box>    

</Box>
  )
}

export default VehicleDetailsDriver
