

import {  RouterProvider} from "react-router-dom";
import { routerList } from "./configs/RouteConfig";
import "./Styles/App.css";
import { Box } from "@mui/material";

import { Notifications } from 'react-push-notification';


function App() {

 
  return (
    
<Box  component='div' style={{backgroundColor:'#F2F6FA'}} > 
<Notifications />
<RouterProvider router={routerList} />
</Box>

   );
}

export default App;
