import { allCoordinatesConstants } from "../Constants/Constants"; 

const initialAllCoordinatesState = {
    currentCoordinatesData: {},
};



export const AllCoordinatesReducer = (state = initialAllCoordinatesState, action) => {
    
    switch (action.type) {
        case allCoordinatesConstants.INSERT_ALLCOORDINATES_DATA:
            return { ...state,currentCoordinatesData: action.payload };
        default:
            return state;
    }
};