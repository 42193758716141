import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import DataTable from '../../../Components/Table/DataTable';
import { useTitle } from '../../../../Hooks/UseTitle';
import ApiCall from '../../../../Helper/ApiCalls';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import { useSelector } from 'react-redux';
import RideFilter from '../../RideServiceManagement/RideFilter';
import LtaFilter from './LtaFilter';
import { getUtcTime } from '../../../Components/MultiFunctions/MultiFunctions';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import { convertToCurrentCountryTime } from '../../../Components/MultiFunctions/MultiFunctions';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "requestId",
      label: "Request Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "dateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "category",
      label: "Category",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "levy",
        label: "Levy",
        minWidth: 170,
        align: "left",
        sort: true,
      },
   
    // {
    //   id: "actionPending",
    //   label: "Actions",
    //   minWidth: 170,
    //   align: "left",
    //   sort: false,
    // },
  ]; 






function LtaReports() {
    useTitle("LTA Reports - Geolah");

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [startUtc,setStartUtc] = useState()
    const [endUtc,setEndUtc] = useState()
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0)
    const [initialStart,setInitialStart] = useState()
    const [initialEnd , setInitialEnd] =useState()
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [initialRangeData , setInitialRangeData] = useState([])
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      
      let isWrite = checkPrivilage('Reports','READ')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Reports','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Reports','DELETE')
       setDeletePer(isDelete)
       },[])
  
    const navigate = useNavigate()




    useEffect(() => {
      // Calculate the current UTC time and set it in the state
      const currentDate = new Date();
      const currentUtc = getUtcTime(currentDate);
      setStartUtc(currentUtc);
      setEndUtc(currentUtc);
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      // const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      console.log(startOfMonth , " <startOfmO")

      setInitialStart(startOfMonth)
      setInitialEnd(currentDate)
      

    }, []);


    useEffect(()=>{

      if(initialStart&& initialEnd){
        setInitialRangeData([dayjs(initialStart),dayjs(initialEnd)])
      }

    },[initialStart,initialEnd])

    // api call

    
useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage
  if(startUtc && endUtc){

    ApiCall("GET", `/trip-service/v1/admin/report/?countryId=${CountryID}&&startDate=${startUtc}&&endDate=${endUtc}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const reportData = data?.result
      setTotalRows(reportData?.length)
     const filteredData =  reportData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
      let dateString = eachItem?.createdAt
      let testT = convertToCurrentCountryTime(dateString)
      const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
   
      const currentStartTime  =  dateString?.slice(11, 16);
      const flatFare = parseFloat(eachItem?.FlatFare)
      const leviAmount = parseFloat(eachItem?.Levy) 
     
      let items = {
            _id:eachItem._id,
            id:index+1+currentOffset,
            requestId:eachItem?.TripRequestID,
            name:eachItem?.OperatorName,
            category:eachItem?.ProductName,
            amount:isNaN(flatFare) ? "N/A" : flatFare.toFixed(2) || "N/A",
            levy:isNaN(leviAmount) ? "N/A" : leviAmount.toFixed(2) || "N/A",
            dateTime:testT
           }
  
           return items;
     })
  
     
  
     setRows(filteredData)
     setTableData(filteredData);
    
   } })}
  },[success,startUtc,endUtc,currentPage,searchInput])

  console.log(startUtc , " <----kkkkkkkkk")


    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.requestId?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.levy?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.amount?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.dateTime?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/wallet-details/${id}`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
     console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
  }


    const handleColumnDateFilter = (startsUtc, endsUtc) => {
      setStartUtc(startsUtc)
      setEndUtc(endsUtc)
     setInitialRangeData([])
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };
      

   const handleExport =()=>{
    if(rows?.length > 0){

      console.log(rows)

      const blob = new Blob([Papa?.unparse(rows)], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'LTP_EXPORT';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    }
   
  
   }   


   const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)   
     }





  return (
<Box>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       needButton={true}
       handlingPage={handlePageChange}
       totalCount={totalRows}
          DeletePermission={deletePer}
          ButtonPermission={write}
          EditPermission={edit} 
          modalClicked={handleExport}
          isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/export'}
        ButtonLabel={'Export'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnDateFilter}
        searchInput={handleChangeInSearch}
        filterComponent={ <LtaFilter currentDateRange={initialRangeData} removerIcon={true} CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
</Box>

  )
}

export default LtaReports