import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import SelectField from '../../Components/Forms/SelectField'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import MyDateRangePicker from '../../Components/Forms/MyDateRangePicker'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import ClearAllIcon from '@mui/icons-material/ClearAll';
function TransferFilter({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)
    const userTypeData = [
        {name:   "Driver"},
        {name:  "Passenger"},
        {name:  "Admin"},
        {name:  "Geolah"},
           ] 

    const transferStatusData = [
            {name:   "authorised"},
            {name:  "received"},
            {name:  "booked"},
               ] 

           
    const handleMultipleSelect = (label,data)=>{    
          if(label === 'Sender Type'){
        
             let filteredData = data?.map((item)=>{
             return item?.name
            })
              FilterColumn(filteredData.join(','),"senderType")
            }else if(label === 'Receiver Type'){

                  let filteredData = data?.map((item)=>{
                    return item?.name
                   })
                   
                     FilterColumn(filteredData.join(','),"receiverType")

            }else if(label === 'Status'){

                let filteredData = data?.map((item)=>{
                    return item?.name
                   })
                     FilterColumn(filteredData.join(','),"status")
            }


              }    
                  
              const HandleClearFilter =()=>{
                setIsClear((i)=> !i)
              }    
           
           

  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
        
      <Box width={'500px'}>
   <MyDateRangePicker clearStatus={isClear} isLta={false} handleUtc={FilterColumn}/>
      </Box>
    <Box pb={2} width={'150px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Sender Type"} Data={userTypeData} />
    </Box>
    <Box pb={2} width={'150px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Receiver Type"} Data={userTypeData} />
    </Box>
    <Box pb={2} width={'150px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Status"} Data={transferStatusData} />
    </Box>
    {/* <Box pb={2} width={'150px'}>
    <SelectField  isError={false} onSelecting={handleSelect} Data={method} sideLabel={'Method'} placeHolder={'select'} />
    </Box> */}

<Stack direction={'row'} spacing={1}>

<IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
         <ClearAllIcon/>
</IconButton>

<IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
         <img src={closeIcon} alt="filter"/>
</IconButton>

</Stack>
      </Stack>
      
    </Box>
  )
}

export default TransferFilter
