import { permissionConstants } from "../Constants/Constants"; 

const initialPermissionState = {
    currentPermissiondata: {},
};

export const PermissionReducer = (state = initialPermissionState, action) => {
    switch (action.type) {
        case permissionConstants.INSERT_PERMISSION_DATA:
            return { ...state, currentPermissiondata: action.payload };
        default:
            return state;
    }
};
