import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DashChart = ({cardData,cardLabel}) => {
 const [options , setOptions] = useState({
  series: [
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
  //   {
  //     name: 'series2',
  //     data: [11, 32, 45, 32, 34, 52, 41]
  //   }
  ],
  chart: {
    height: 250,
    type: 'area',
    toolbar: {
      show: false // Hide the toolbar (including zoom-in and zoom-out buttons)
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'month',
    categories: [
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024"
    ]
  },
  yaxis:{
      labels: {
          show: false // Hide the x-axis labels
        },
  },
  tooltip: {
    x: {
      format: 'MM'
    }
  },

})

useEffect(()=>{
  if(cardData && cardLabel){
setOptions({
  series: [
    {
      name: 'Trip Completed',
      data: cardData
    },

  ],
  chart: {
    height: 250,
    type: 'area',
    toolbar: {
      show: false // Hide the toolbar (including zoom-in and zoom-out buttons)
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'month',
    categories: cardLabel 
  },
  yaxis:{
      labels: {
          show: false // Hide the x-axis labels
        },
  },
  tooltip: {
    x: {
      format: 'MM'
    }
  },

})

  }

},[cardData,cardLabel])
  

  return (
    <div id="chart">
      <ReactApexChart options={options} series={options.series} type="area" height={200} />
    </div>
  );
};

export default DashChart;
