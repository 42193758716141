import { modalConstants } from "../Constants/Constants"; 

const initialModalState = {
    currentModaldata: {},
};

export const ModalReducer = (state = initialModalState, action) => {
    switch (action.type) {
        case modalConstants.INSERT_MODAL_DATA:
            return { ...state, currentModaldata: action.payload };
        default:
            return state;
    }
};