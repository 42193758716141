import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../../../Hooks/UseTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import DataTable from '../../../../../Components/Table/DataTable';
import RideFilter from '../../../../RideServiceManagement/RideFilter';
import ApiCall from '../../../../../../Helper/ApiCalls';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { checkPrivilage } from '../../../../../../Sessions/Permisssions';
import NotifyAlertWhileDelete from '../../../../../Components/Forms/NotifyAlertWhileDelete';
import { convertToCurrentCountryTime } from '../../../../../Components/MultiFunctions/MultiFunctions';



const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "userName",
      label: "UserId",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    // {
    //   id: "issueType",
    //   label: "App Type",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "agentName",
      label: "Priority",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "dateAndTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
  
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];


function DriverTickets() {

      // useTitle("Tickets- Geolah");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const { id:driverId } = useParams(); 
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      let isWrite = checkPrivilage('Driver Details','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Driver Details','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Driver Details','DELETE')
       setDeletePer(isDelete)
       },[])
  
    const navigate = useNavigate()

           // api call
           useEffect(()=>{
            const currentLimit = rowsPerPage
            const currentOffset = (currentPage-1) * rowsPerPage
            ApiCall("GET", `/user-service/v1/admin/driver/tickets/?driverId=${driverId}&&countryId=${CountryID}&&appType=DriverApp`, null, "uui").then((data)=>{
              if(data?.result){
          
              const serviceData = data?.result
              setTotalRows(serviceData?.length)
             
              const filteredData = serviceData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
        
                const formattedDate = dayjs(eachItem?.CreatedAt).format('YYYY-MM-DD')
                let dateString = eachItem?.CreatedAt
                let testT = convertToCurrentCountryTime(dateString)
                const currentStartTime  =  dateString?.slice(11, 16);
        
               let items = {
                _id:eachItem?.ID,      
                id:index+1,
                userName:eachItem?.UserID,
                issueType:eachItem?.AppType,
                description:eachItem?.Description,
                agentName:eachItem?.Priority,
                dateAndTime:testT,
                status:eachItem?.Status,
                
               }
               return items
        
        
              })
        
          
            
          
             setRows(filteredData)
             setTableData(filteredData);
           }
        
           })
          },[success,currentPage])
        



          useEffect(()=>{
            setRows(
              tableData.filter((item) =>
              item?.userName?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
              item?.issueType?.toLowerCase().includes(searchInput.toLowerCase()) ||
              item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
              item?.agentName?.toLowerCase().includes(searchInput.toLowerCase()) ||
              item?.dateAndTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
              item?.status?.toLowerCase().includes(searchInput.toLowerCase()) 
            
                ))
             },[searchInput])



    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={userId:newData?.userName,ticketId:newData?._id}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
  
   ApiCall("PATCH", `/user-service/v1/admin/driver/tickets/delete`,yesData,'BODY').then((data)=>{
    setSuccess(data) 
    setDeleteStatus(false)
  })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-tickets/${id}/${newData?.userName}`)
    }
    }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)

  }


    const handleColumnFilter = (items, searchText) => {



        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }





    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

 






      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }






  return (

    <Box borderRadius={'8px'} marginTop={2}  bgcolor='white' width='1200px' height='100%' >
      <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
    <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
        DeletePermission={deletePer}
        ButtonPermission={write}
        EditPermission={edit} 
           isFilterOn={isFilterOn}
           openFilter={handleFilterBar}
            columns={columns}
            tableData={rows}
            columnSorting={columnSortingFn}
            // ButtonPath={'/create-service'}
            // ButtonLabel={'Create Service'}
            handleSwitchChange={handleStatusChange}
            handleDelete={handleServiceDelete}
            handleEdit={handleServiceEdit}
            columnFilter={handleColumnFilter}
            searchInput={handleChangeInSearch}
            filterComponent={''}
          />


    </Box>
  )
}

export default DriverTickets
