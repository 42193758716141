import { Box, Stack } from '@mui/material'
import React from 'react'
import FormSubmitButtons from '../../Components/Forms/FormSubmitButtons'
import TicketForm from './TicketForm'


function AddNewTicket() {
  return (
      
   <Box>
   <Stack spacing={3} >
   {/* <VoucherForm/> */}
  <TicketForm/>

   <FormSubmitButtons/>
   </Stack>
   
  </Box>
  )
}

export default AddNewTicket
