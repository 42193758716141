import { allZoneConstants } from "../Constants/Constants"; 

const initialAllZoneState = {
    currentAllZonedata: {},
};



export const AllZoneReducer = (state = initialAllZoneState, action) => {
    
    switch (action.type) {
        case allZoneConstants.INSERT_ALLZONE_DATA:
            return { ...state,currentAllZonedata: action.payload };
        default:
            return state;
    }
};