import { Box, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DriverPersonalCard from './DriverPersonalCard'
import VehicleInformationCard from './VehicleInformationCard'
import WalletManagement from '../../Wallet/WalletManagement/WalletManagement'
import DriverWalletCard from './DriverWalletCard'
import DriverVehicleDeatils from '../../../Components/Driver/DriverVehicleDeatils'
import Vehicle from '../../User/UserDetails/Pages/Vehicle'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import ToggleNav from '../../../Components/Forms/ToggleNav'
import VerificationDetails from './VerificationDetails/VerificationDetails'
import GeneralDetails from './GeneralDetails/GeneralDetails'
import { useNavigate, useParams } from 'react-router-dom'
import ApiCall from '../../../../Helper/ApiCalls'
import { useTitle } from '../../../../Hooks/UseTitle'
import dayjs from 'dayjs'
import { baseUrl } from '../../../RequestMethord'
import axios from 'axios'
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions'
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage'
import { convertToCurrentCountryTime } from '../../../Components/MultiFunctions/MultiFunctions'






function DriverMainDetails() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate('/driver')}  key="3" color="text.primary">
      Driver
      </Typography>,
  <Typography key="3" color="text.primary">
     Driver Details
      </Typography>,
  ];
const theme = createTheme({
    breakpoints: {
      values: {
        ht: 1800,
    
      },
    },
  });
  
// STATES AND VARIABLES 
const { id:driverId } = useParams(); 
const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
const [vehicleDetails,setVehicleDetails] = useState({})
const [profileDetails , setProfileDetails] = useState({})
const [tab , setTab] = useState('left')
const [approvedStatus , setApprovedStatus] = useState()
const [isWalletVerified , setIsWalletVerified] = useState(false)
const [success, setSuccess] = useState()
const [any,setAny] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Driver Details')
  setAny(isAany)
   },[])


const ChangedStatus=(data)=>{
setSuccess(data)
}

const activityApiCall = ()=>{
  const apiUrl = `${baseUrl}/trip-service/v1/driver/profile/activity`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

 axios.get(apiUrl, config).then(response => {

  if(response?.data?.result){
    const personalData = response?.data?.result
    console.log(personalData,":::::::::::")

    let profileData = {
      driverEmail:personalData?.Email,
      driverPhone:personalData?.PhoneNumber,
      // driverPrioritySticker:driverDetails?.HasPrioritySticker,
      driverApprove:false,
      driverProfileImage:personalData?.DriverProfilePicPath,
      driverRating:personalData?.DriverRating?.TotalRating,
      driverReview:personalData?.DriverRating?.TotalReviews,
      driverLastOnline:'N/A',
      driverName:personalData?.FirstName +' '+ personalData?.LastName,
      // driverIsOnline:driverDetails?.OnlineStatus,
      // isPriority:driverDetails?.HasPrioritySticker,
     }

         
 setProfileDetails(profileData)

  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });

}

// RENDERS AND API CALLS

useEffect(()=>{
  // /trip-service/v1/admin/driver/details/{driverId}

  if(driverId){   
    ApiCall("GET", `/trip-service/v1/admin/driver/details/${driverId}`,null).then((data,error)=>{
    
      if(data.result){

      const driverDetails  = data.result?.[0]
      console.log(driverDetails , "<---######################################33")
      let expDate = dayjs(driverDetails?.VehicleInsuranceDetails?.[0]?.ExpiryDate).format('DD-MM-YYYY') || '12/06/2024'
      console.log(expDate , ",---expp dateeee")
      let testT = convertToCurrentCountryTime(expDate)
       let vehicleData={
        vehicleImage:driverDetails?.DriverVehicleDetails?.[0]?.VehicleFrontUrl,
         brandName:driverDetails?.VehicleBrand?.[0]?.Name,
         modelName:driverDetails?.VehicleBrandModel?.[0]?.Name,
         vehicleColor:driverDetails?.DriverVehicleDetails?.[0]?.VehicleColor,
         vehicleSeats:driverDetails?.DriverVehicleDetails?.[0]?.Seats,
         regNo:driverDetails?.DriverVehicleDetails?.[0]?.RegistrationNumber,
         addOnFeature:driverDetails?.AdOnFeatures,
         insuranceExp:testT
        }
       let dateString = driverDetails?.LastOnlineTime
       const currentStartTime  =  dateString?.slice(11, 16);

       let profileData = {
        driverEmail:driverDetails?.Email,
        driverBlocked:driverDetails?.IsBlocked,
        driverPhone:driverDetails?.PhoneNumber,
        driverPrioritySticker:driverDetails?.HasPrioritySticker,
        driverApprove:driverDetails?.IsApprovedByAdmin,
        driverProfileImage:driverDetails?.DriverProfilePicPath,
        driverRating: driverDetails?.DriverRating?.TotalRating,
        driverReview:driverDetails?.DriverRating?.TotalReviews,
        driverLastOnline:currentStartTime,
        driverName:driverDetails?.FirstName +' '+ driverDetails?.LastName,
        driverIsOnline:driverDetails?.OnlineStatus,
        isPriority:driverDetails?.HasPrioritySticker,
       }
      
       setApprovedStatus(driverDetails?.IsApprovedByAdmin)
       
       setProfileDetails(profileData)
       setVehicleDetails(vehicleData)
            
      }
  })
  .catch((error) => {
    if(error.message){

      activityApiCall()
      
      console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong [ridecategory - get]')
      }
  
  
  });
  }


},[driverId,success])

useTitle(`${profileDetails?.driverName || 'Driver Details'} - Geolah`);


// FUNCTIONS
const tabRender = (alignment)=>{
  setTab(alignment)
                          }


const handleWalletVerify = (status)=>{
setIsWalletVerified(status)
}



  return (
    any ? (
    <>
<CreatePageHead BackPath={'/driver'} title={'Driver Details'} BreadList={breadcrumbs}/>
<Box p={3} display={'flex'} flexDirection={isMatch?'column':'row'} justifyContent={isMatch?'center':'space-between'}>
{/*PERSONAL DETAILS CARD*/}
<DriverPersonalCard chengedSuccess={ChangedStatus} walletStatus={isWalletVerified} ProfileData={profileDetails} />
{/* VEHICLE DETAILS CARD */}
<Box marginTop={isMatch &&'10px'} marginBottom={isMatch &&'10px'}>
<VehicleInformationCard VehicleData={vehicleDetails} />
</Box> 
{/* WALLET CARD */}
<DriverWalletCard walletStatus={handleWalletVerify} CardStatus={approvedStatus}/>
</Box>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100%' >
<ToggleNav lName={'Verification'} rName={'General'} toggleChange={tabRender} />  
    <Box  sx={{ width: "100%", height: "100%" }} pt={1}>

    {tab === 'left' ? <VerificationDetails/>:<GeneralDetails/>}

    </Box>
</Box>
</>) : any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default DriverMainDetails
