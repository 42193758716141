

import { Store } from "../../src/App/Redux/store";

export const checkPrivilage = (module, operation) => {

    const authState = Store.getState();
    const { adminRole:privilages } = authState?.user?.currentUserdata
    ;

    const permission = privilages?.find( (p) => p.module === module && p.permission !== "0000" 
    );


  
    if (!permission) {
      return false;
    }

    /**
     * Checking permission
     * permission is a string Eg: '1111' or '1001'
  */

    const pList = ["CREATE", "READ", "UPDATE", "DELETE"];
  
    const myPer = permission?.permission.split("");
  
    const i = pList.indexOf(operation);
  
    /**
     * value of operation, It might be '1' or '0'
     */

    const v = myPer[i];
  
    if (v === "1") {
      return true;
    } else {
      return false;
    }
  };
  
  
  export const checkAnyPrivilage = (module) => {
  
    const authState = Store.getState();
    const { adminRole:privilages } = authState?.user?.currentUserdata;
   
    const permission = privilages?.find(
      (p) => p.module === module && p.permission !== "0000"
    );
  
    if (!permission) {
      return false;
    } else {
      return true;
    }
  };