import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import phoneIcon from '../../../../Assets/Icon.svg'
import emailIcon from '../../../../Assets/Icon2.svg'


function UserContactDetails() {
  return (
 <Box mt={3} pl={2}>
    <Typography fontWeight={500} variant='h7'>Contact</Typography>
    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={emailIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography fontSize={'13px'} color={'#C6C6C6'} fontWeight={600}>Email</Typography>
        <Typography fontSize={'12px'}  variant='subtitle2'>alwissuryatmaja@gmail.com</Typography>
     </Stack>  
    </Stack>

    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={phoneIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography color={'#C6C6C6'} fontSize={'13px'} fontWeight={600}>Phone</Typography>
        <Typography fontSize={'12px'}  variant='subtitle2'>+6282283386756</Typography>
     </Stack>  
    </Stack>

    <Stack direction={'row'} mt={2} spacing={2} >
     <img src={phoneIcon} alt='icon'/>
     <Stack spacing={0.5} >
        <Typography fontSize={'13px'} color={'#C6C6C6'} fontWeight={600}>Phone</Typography>
        <Typography fontSize={'12px'}  variant='subtitle2'>+6282283386756</Typography>
     </Stack>  
    </Stack>


    
 </Box>
  )
}

export default UserContactDetails