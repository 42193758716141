import React, { Fragment } from "react";
// import Button from "@atlaskit/button";
import EmptyState from "@atlaskit/empty-state";

//@ts-ignore
import ErrorWindowImage from "../../../Assets/other-images/download.png";

export default ({Title}) => (
  <EmptyState
    header={Title}
    description={
      <Fragment>
        {/* <p>
          As of <strong>March 31, 2019</strong> new versions of Atlassian
          products are incompatible with Internet Explorer.
        </p>
        <p>
          Please visit our{" "}
          <a href="https://confluence.atlassian.com/cloud/supported-browsers-744721663.html">
            Supported browsers page
          </a>{" "}
          to see a list of supported apps and browsers.
        </p> */}
      </Fragment>
    }
    imageUrl={ErrorWindowImage}
  />
);
