import { Box, FormControl, FormLabel, OutlinedInput, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../../../Components/Forms/InputField'
import SelectField from '../../../../Components/Forms/SelectField';
import { NO_OF_SEATS } from '../../../../FakeData/FakeData';
import SaveAndVerify from '../../../../Components/Forms/SaveAndVerify';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../../RequestMethord';

function ServiceFormVerification() {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
//  STATES AND VARIABLES   
const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
const [currentVehicle , setCurrentVehicle] = useState('')
const [currentServiceName , setCurrentServiceName] = useState('')
const { id:driverId } = useParams(); 

//RENDERS AND API CALLS

useEffect(()=>{
  const apiUrl = `${baseUrl}/trip-service/v1/driver/ride/service`;
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  axios.get(apiUrl, config).then(response => {

      if(response?.data?.result){
    const vehicleData = response?.data?.result    
        setCurrentVehicle(vehicleData?.VehicleTypeID?.Type)
        setCurrentServiceName(vehicleData?.RideServiceID?.Name)
   
      
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });

},[driverId])


//FUNCTION 


const handleSelect=()=>{

}

  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch?'100%':'50%'}>
{/* <SelectField isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'What would you like to do?'} placeHolder={'choose'} /> */}
<Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >What would you like to do</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={currentServiceName}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>
    <Box width={isMatch ? "100%" : "50%"} >
    {/* <SelectField isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'What vehicle are you driving?'} placeHolder={'choose'} /> */}
    <Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >What vehicle are you driving?</FormLabel>
    
         </Stack>
        
         <OutlinedInput
         disabled={true}
         value={currentVehicle}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>


</Stack>

</Box>
  )
}

export default ServiceFormVerification
