import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'




import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../Components/Table/DataTable';
import ApiCall from '../../../../Helper/ApiCalls';
import TableHead from '../../../Components/Table/TableHead';
import dayjs from 'dayjs';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import { convertToCurrentCountryTime } from '../../../Components/MultiFunctions/MultiFunctions';


const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },
  // {
  //     id: "rideId",
  //     label: "Ride Id",
  //     minWidth: 180,
  //     sort: false,
  //   },

  {
    id: "userName",
    label: "User Name",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "driverName",
    label: "Driver Name",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "address",
    label: "Pick / Drop Address",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "fair",
    label: "Fair",
    minWidth: 200,
    align: "left",
    sort: false,
  },
  {
    id: "rideStatus",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Online",
      onFalse: "Offline",
    },
  },

  {
    id: "dateTime",
    label: "Date & Time",
    minWidth: 170,
    align: "left",
    sort: true,
  },

  {
    id: "actionCancel",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];




function UserBookingTable() {
  //  STATES   
      const [rows, setRows] = useState();
      const [success, setSuccess] = useState()
      const [selectedItems, setSelectedItems] = useState([]);
      const [tableData, setTableData] = useState([]);
      const [searchInput , setSearchInput] = useState('')
      const [isFilterOn , setIsFilterOn] = useState(false)
      const [deleteStatus , setDeleteStatus] = useState(false)
      const [deleteData , setDeleteData] = useState({})
      const [order, setOrder] = useState("ASC");
      const navigate = useNavigate()
      const { id:userId } = useParams(); 
      const [totalRows,setTotalRows] = useState(0)
      const [rowsPerPage,setRowsPerPage] = useState(10)
      const [currentPage,setCurrentPage] = useState(1)
      
      const [edit,setEdit] = useState()
  

      useEffect(()=>{
     
        
        let isEdit = checkPrivilage('User Details','UPDATE')
        setEdit(isEdit)
        
    
         },[])

  
      const removeUnderscoresAndAddSpaces = (inputString)=> {
        // Use a regular expression to replace underscores with spaces globally
        return inputString?.replace(/_/g, ' ');
      }
  // RENDERS AND API-CALLS
  
  useEffect(()=>{
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
      ApiCall("GET", `/user-service/v1/admin/user/customer/bookings/${userId}/?limit=100&&offset=0`, null, "uui").then((data)=>{

        if(data?.result){
          const userData = data?.result?.BookingData
          setTotalRows(data?.result?.totalBookingsCount)
          console.log(userData,"<----uSERR_Dataaaaaa")
          const filteredData = userData?.map((eachItem,index)=>{
            const currentStatus = eachItem?.TripStatus
            let tempStatus = false;
            const formattedDate = dayjs(eachItem?.BookingDateTime);
            const formattedTime = dayjs(eachItem?.DateTime).format('HH:mm');
            let testT = convertToCurrentCountryTime(formattedDate)
            const Fares = parseFloat(eachItem?.TotalFare )

            if(currentStatus === 'RIDE_CANCELLED_BY_ADMIN' ||currentStatus === 'RIDE_CANCELLED_BY_PASSENGER'|| currentStatus === 'RIDE_CANCELLED_BY_DRIVER'|| currentStatus === 'RIDE_COMPLETED'|| currentStatus === 'RIDE_COMPLETE'  ){
              tempStatus = true;   
           }


            let items={
 
              id:index+1,
              tripDetailsId:eachItem?.TripDetailsID,
              // rideId:eachItem?.CollectionID,
              rideId:1000+index,
              address:eachItem?.DestinationAddress,
              userName:eachItem?.PassengerTrips?.FirstName,
              driverName:eachItem?.DriverTrips?.FirstName,
              fair:isNaN(Fares) ? "N/A" : Fares.toFixed(2) || "N/A",
              rideStatus:removeUnderscoresAndAddSpaces(eachItem?.TripStatus),
              dateTime:testT,
              disable:tempStatus,

            }

            return items
          })


          setRows(filteredData)
          setTableData(filteredData);
   
        }

       })
    },[success,currentPage])


    console.log(rows,"<><><<->><><>")



    useEffect(()=>{
      setRows(
        tableData.filter((item) =>
        item?.rideId?.toString().toLowerCase()?.includes(searchInput.toLowerCase()) ||
        item?.address?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.userName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.driverName?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.fair?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.isOnline?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.dateTime?.toLowerCase().includes(searchInput.toLowerCase())
          ))
       },[searchInput])
  
  
  
  
  //FUNCTIONS
  
  
  //UPDATE STATUS
  const handleStatusChange =(id, newData)=>{
      //  console.log(`Switch with id ${id} changed. New data:`, newData);
      //  const paramsData = {valueServiceId:id,status:newData?.status}
      //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
    }
  
  // DELETE STATUS 
  const handleServiceDelete =(newData)=>{

    setDeleteStatus(true)
    const paramsData ={
      tripId:newData?.tripDetailsId,
    }
    setDeleteData(paramsData)
   
  } 
   
  //DELETE
  const onDeleteYes =(yesData)=>{
    ApiCall("POST", `/cab-alloc-service/v1/admin/trip/cancel/ride`,yesData).then((data)=>{
     setSuccess(data) 
     // setDeleteStatus(false)
   })
   } 
  
  //CLOSE DELETE NOTIFICATION
  const onCloseNotification = ()=>{
      //     setDeleteStatus(false)
     //   }
    //   const handleServiceEdit =(id,newData)=>{
   //     if(id){
  //       navigate(`/edit-service/${id}`)
  //     }
     }
     
   //HANDLE SEARCH CHANGE
  const handleChangeInSearch = (value)=>{
      setSearchInput(value)
      // console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
   }
  
   
  // COLUMN FILTER 
  const handleColumnFilter = (items, searchText) => {
  
  
  
      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    
        // Check if the row matches all of the selected vehicleType items
        const vehicleTypeMatch = items.every(
          (item) => row.vehicleType && row.vehicleType.includes(item)
        );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };
  
  
  // FILTERBAR
  const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }
  
  //NAVIGATE EDIT 
  const handleServiceEdit =(id,newData)=>{
      // if(id){
      //   navigate(`/edit-service/${id}`)
      // }
     } 
  
   //NAVIGATE PROFILE DETAILS
   
   const handleProfileEdit = (id,newData)=>{
      if(id){
        navigate(`/user-details/${id}`)
      }
   }
  
  
  //COLUMN FILTER
  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }
  
    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };



  //handle page change

  const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)
  }

  
    return (
  <Box mt={5}>
  <TableHead title={'Bookings'}/>
  <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
  <Box sx={{ width: "100%", height: "100%" }} pt={1}>
  <NotifyAlertWhileDelete AlertNote={'Are you sure you want to cancel ride ?'} OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
  <DataTable
    handlingPage={handlePageChange}
    totalCount={totalRows}
    EditPermission={edit} 
         handleClickProfileDetails={handleProfileEdit}
         isFilterOn={isFilterOn}
         openFilter={handleFilterBar}
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        //   ButtonPath={'/create-driver'}
        //   ButtonLabel={'Create User'}
          handleSwitchChange={handleStatusChange}
          handleDelete={handleServiceDelete}
          handleEdit={handleServiceEdit}
          columnFilter={handleColumnFilter}
          searchInput={handleChangeInSearch}
          filterComponent={''}
       
        />
  </Box>
  </Box>
  </Box>
    )
  }
  
  export default UserBookingTable
  