import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import walletData from "../../../FakeData/MOCK_DATA9.json";
import DataTable from "../../../Components/Table/DataTable";
const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },

  {
    id: "total_amount",
    label: "Total Amount",
    minWidth: 130,
    align: "left",
    sort: true,
  },
  {
    id: "username",
    label: "User Name",
    minWidth: 170,
    align: "left",
    sort: false,
  },

  {
    id: "payment_mode",
    label: "Payment Mode",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "status",
    label: "Pyment Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Paid",
      onFalse: "Unpaid",
    },
  },

  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];
function UserWalletDetails() {
  const [rows, setRows] = useState(walletData);
  const [order, setOrder] = useState("ASC");

  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "reviews" && id !== "bookings"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "reviews" && id !== "bookings"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      
      setOrder("ASC");
    }
  };

  return (
    <>
      <Box display="flex"
           alignItems="center"
           p={2}
           justifyContent="space-between"
           bgcolor="#F8F8F8"
           borderRadius="8px"
           height="108px"
           width="100%"
      >
        <Stack spacing={1}>
<Typography sx={{ opacity: "50%" }} variant="caption">Balance</Typography>
          <Typography variant="h4">S$150</Typography>
        </Stack>

        <Button
          sx={{
            width: "114px",
            height: "40px",
            borderRadius: "8px",
            backgroundColor: "#F8F8F8",
            color: "black",
          }}
          variant="contained"
        >
          {" "}
          Report
        </Button>
      </Box>

      <Box sx={{ width: "100%", height: "100%" }} pt={2}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box>
    </>
  );
}

export default UserWalletDetails;
