import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import AddCategoryForm from './AddCategoryForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';



function CreateRideCategory() {
  const navigate = useNavigate()

  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Ride Category
      </Typography>,
 <Typography key="3" color="text.primary">
      Create Category
      </Typography>,
  ];




  useTitle("Create Category - Geolah");
  const [any,setAny] = useState()
  
  useEffect(()=>{
    let isAany = checkAnyPrivilage('Ride Category')
    setAny(isAany)
     },[])

  return (
    any ? (
  <Box>
     <CreatePageHead title={'Create Ride Category'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
       <AddCategoryForm/>
    </Box>
    </Box>
  </Box>): any === false ?(
     <RestrictedUsage/>
  ) : null
  )
}

export default CreateRideCategory
