import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField'
import { DiscountTypeData } from '../../../FakeData/FakeData'
import InputField from '../../../Components/Forms/InputField'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import ApiCall from '../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../../Sessions/Permisssions'

function CreateTipForm() {

const [selectedType,setSelectedType] = useState('')    
const [tipAmount,setTipAmount] = useState('')
const [geolahSplit,setGeolahSplit] = useState(0)
const [driverSplit,setDriverSplit] = useState(0)
// const [tipAmount,setTipAmount] = useState('')
const [write,setWrite] = useState(true)
const [submitHit,setSubmitHit] = useState(false)
const [onApiSuccess , setOnApiSuccess] = useState('')
const [onApiFailed , setOnApiFailed] = useState('')
const [onSuccess, setOnSuccess] = useState(false)
const [onFailed , setOnFailed] = useState(false)
const {currentCountrydata:CountryID} = useSelector((state) => state.country)

useEffect(()=>{   
  let isWrite = checkPrivilage('Tip','CREATE')
  setWrite(isWrite)
   },[])

    const handleSelect = (label,data)=>{
        if(label === 'Type'){
          setSelectedType(data?.name)          
        }     
      }

      const handleFormSubmit =()=>{
        setSubmitHit(true)

      let sendData={
        countryId:CountryID,
        type:selectedType,
        tip:parseFloat(tipAmount),
        driverSplit,
        geolahSplit
      }

      ApiCall("POST", "/trip-service/v1/admin/tip-charges/add", sendData).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully created")      
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });
      }



      useEffect(()=>{
        if(tipAmount?.length>0 && selectedType?.length > 0){
                const tipSplit = parseInt(tipAmount)/2
                setGeolahSplit(tipSplit)
                setDriverSplit(tipSplit)    
        }else{
            setGeolahSplit(0)
            setDriverSplit(0) 
        }
      },[tipAmount,selectedType])



      const handleGeolahSplitChange = (e) => {
        const newGeolahSplit = parseFloat(e.target.value);
        setGeolahSplit(newGeolahSplit);
    
        // Update driverSplit accordingly
        const remainingTip = parseFloat(tipAmount) - newGeolahSplit;
        setDriverSplit(isNaN(remainingTip) ? 0 : remainingTip);
      };
    
      const handleDriverSplitChange = (e) => {
        const newDriverSplit = parseFloat(e.target.value);
        setDriverSplit(newDriverSplit);
    
        // Update geolahSplit accordingly
        const remainingTip = parseFloat(tipAmount) - newDriverSplit;
        setGeolahSplit(isNaN(remainingTip) ? 0 : remainingTip);
      };

      
     





console.log(geolahSplit , "<---- geolah SPlittts")

  return (
    <Box>     
    <Stack spacing={3} >

    <Stack alignItems={'center'} direction={'row'} spacing={3} >
    <Box width={'25%'}>
        <SelectField
              onSelecting={handleSelect}
              sideLabel={"Type"}
              placeHolder={"select"}
              Data={DiscountTypeData}
            />
    </Box>  
    <Box width={'25%'}>
    <InputField disable={selectedType?.length < 1}  sideLabel={"Tip Amount"} placeHolder={selectedType === 'percent' ? 'Percentage %' : "Amount S$"} InChange={(e)=> setTipAmount(e.target.value)} />
    </Box>
    <Box width={'25%'}>
    <InputFieldUpdate Value={geolahSplit} Type={'number'} disable={tipAmount?.length < 1}  sideLabel={"Geolah Split"} placeHolder={selectedType === 'percent' ? 'Percentage %' : "Amount S$"}  InChange={(e)=> handleGeolahSplitChange(e)} />
    </Box>
    <Box width={'25%'}>
    <InputFieldUpdate Value={driverSplit}  Type={'number'} disable={tipAmount?.length < 1}  sideLabel={"Driver Split"} placeHolder={selectedType === 'percent' ? 'Percentage %' : "Amount S$"}  InChange={(e)=> handleDriverSplitChange(e)} />
    </Box>
    
    </Stack>   
    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>

<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
<ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </Stack>
    </Box>
  )
}

export default CreateTipForm
