import { Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import MyDateRangePicker from '../../Components/Forms/MyDateRangePicker'

function AdminFilter({CloseModal,FilterColumn}) {

  return (
    <Box >
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
        
      <Box  width={'600px'}>
   <MyDateRangePicker isLta={false} handleUtc={FilterColumn}/>
      </Box>
      
  
      <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="large">
               <img src={closeIcon} alt="filter"/>
              </IconButton> 
      </Stack>
      
    </Box>
  )
}

export default AdminFilter
