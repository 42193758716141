import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Nodatafound from '../../../Components/NoDataFound/Nodatafound';

function TripTimelineCard({cardDetails}) {


let totalItems = cardDetails?.length


  return (
  <Box sx={{overflowY:'scroll', '&::-webkit-scrollbar': {
    width: '5px',  // Adjust the width as needed
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'lightgray',  // Change the thumb color
    borderRadius: '5px',     // Add rounded corners to the thumb
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',  // Change the track color
  },
  scrollbarWidth: 'thin',  // Firefox scrollbar width
  scrollbarColor: 'lightgray transparent',}} padding={3} borderRadius={'8px'} bgcolor={'white'} width={'500px'} height={'750px'}>
    <Stack spacing={3}>
    <Typography sx={{textTransform:"none",fontWeight:550}} variant='h6' >Trip Timeline</Typography>
    <Box  width={'100%'}  >
    <Timeline  position='left' >

      {totalItems ? 
cardDetails?.map((eachData,index)=>(

<TimelineItem>
<TimelineSeparator>
  <TimelineDot color="success">
    <AdsClickIcon fontSize='small'/>
  </TimelineDot>
 {index+1 < totalItems && <TimelineConnector />}
</TimelineSeparator>
<TimelineContent>
  <Stack width={'100%'}>
  {eachData?.Description}
  <Typography sx={{textTransform:"none",opacity:'50%'}} variant='caption' >{`${eachData?.OnlyDate},${eachData?.OnlyTime} `}</Typography>
  </Stack>
 
  </TimelineContent>
</TimelineItem>

)) : <Nodatafound Title={'No Data Found'}/>

}

      
    </Timeline>  
    </Box>
    </Stack>

  </Box>
  )
}

export default TripTimelineCard
