import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react';
import InputField from '../../../Components/Forms/InputField';
import SelectField from '../../../Components/Forms/SelectField';
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import ImageSelector from '../../../Components/Forms/ImageSelector'
import { NO_OF_SEATS, NO_OF_SEATS_CAT } from '../../../FakeData/FakeData';
// import { VehicleCategory } from '../../../FakeData/FakeData'
import ApiCall from '../../../../Helper/ApiCalls';
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import { useSelector } from 'react-redux';
import MultipleSelect from '../../../Components/Forms/MultipleSelect';
import { filter } from 'lodash-es';
import { checkPrivilage } from '../../../../Sessions/Permisssions';


function AddCategoryForm() {
    const [submitHit,setSubmitHit] = useState(false)
    const [enable , setEnable] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const [vehicleType , setVehicleType] = useState([])
    const [serviceData , setServiceData] = useState([])
    const [selectedService , setSelectedService] = useState('')
    const [selectedSeats , setSelectedSeats] = useState([])
    const [selectedVehicleType , setSelectedVehicleType] = useState([])
    const [selectedCategory , setSelectedCategory] = useState([])
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [imageFile, setImageFile] = useState({})
    const [vehicleCategory , setVehicleCategory] = useState([])
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
  

    useEffect(()=>{ 
      let isWrite = checkPrivilage('Ride Category','CREATE')
      setWrite(isWrite)
  
       },[])


    useEffect(()=>{

      const hasErrors =
      !imageFile ||
      categoryName?.length < 3 ||
      selectedVehicleType?.length < 1 ||
      selectedCategory?.length < 1 ||
      selectedService?.length < 1 ||
      selectedSeats?.length < 1;
    
    setEnable(!hasErrors);
     },[imageFile,categoryName,selectedVehicleType,selectedCategory,selectedSeats,selectedService])


   useEffect(()=>{

    // ApiCall("GET", "/trip-service/v1/admin/value-service/vehicle/type", null,).then((data)=>{
    //   if(data.result){
    //    const rowData = data?.result;
    //    const filteredData = rowData?.map((eachType)=>{
    //        return{id:eachType?._id,name:eachType?.Type}
    //    })

    //    setVehicleType(filteredData)
    //   }})

      ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
          const serviceData = data?.result
          const filteredData =  serviceData?.map((eachItem,index)=>{
            return {id:eachItem?._id, name:eachItem?.ServiceName,vehicles:eachItem?.VehicleTypeIDS,vehicleCat:eachItem?.VehicleCategory}
          })
         setServiceData(filteredData)

        }
      })
      },[])


      const handleSelect = (label,data)=>{
          
        if(label === 'Select Service'){
               setSelectedService(data?.id)
               let vehicles = data?.vehicles
               let Cat = data?.vehicleCat
             
               const filteredData = vehicles?.map((eachType)=>{
                return{id:eachType?._id,name:eachType?.Type}
            })
            const filteredCat = Cat?.map((eachType)=>{
              return{name:eachType}
          })
            setVehicleCategory(filteredCat)
     
            setVehicleType(filteredData)

        }else if(label === 'Seat Number'){
                setSelectedSeats(data?.name)
        }else if(label === 'Vehicle Type'){
                setSelectedVehicleType(data?.id)
        }else if(label === 'Vehicle Category'){
                setSelectedCategory(data?.name)
        }
     
    
      }







    const handleFormSubmit =()=>{

      setSubmitHit(true)
      if(enable){
        const formData = new FormData();
        formData.append('RideCategoryIconImage', imageFile?.file);
        formData.append('rideCategoryName',categoryName);
        // formData.append('seats', selectedSeats);
        // formData.append('vehicletypeId', selectedVehicleType);
        // formData.append('vehicleCategory', selectedCategory);
        formData.append('valueServiceId',selectedService);
        formData.append('countryId',CountryID); 

        selectedCategory.forEach((category, index) => {
          formData.append(`vehicleCategory[${index}]`, category);
        });

        selectedVehicleType.forEach((Vehicle, index) => {
          formData.append(`vehicletypeId[${index}]`, Vehicle);
        });

        selectedSeats.forEach((Seat, index) => {
          formData.append(`seats[${index}]`, Seat);
        });

 
        

        ApiCall("POST", "/trip-service/v1/admin/ride-category/create", formData,).then((data)=>{
          if(data.result === 'success'){
            setOnSuccess((i) => !i )
            setOnApiSuccess("Succesfully created category")  
          }
      })
      .catch((error) => {
         setOnFailed((i) => !i)
        
          if(error.message){
            setOnApiFailed(error.message)
            console.log(error , "<----errrrrrr")
          }else{
            setOnApiFailed("Something went wrong.!")
          }
      
      
      });

      }
    }



    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
    
 const handleImageFiles =(files)=>{
  setImageFile(files)
 }

 const handleMultipleSelect = (label,data)=>{

   if(label === 'Seat Number'){

    let filteredData = data?.map((item)=>{
      return item?.name
    })

    setSelectedSeats(filteredData)

  }else if(label === 'Vehicle Type'){

    let filteredData = data?.map((item)=>{
      return item?.id
    })

    setSelectedVehicleType(filteredData)


  }
  else if(label === 'Vehicle Category'){
    let filteredData = data?.map((item)=>{
      return item?.name
    })
    setSelectedCategory(filteredData)
}

}






  return (
  <Box>
    <Stack spacing={3} >
    <Stack spacing={2}>
    <Box width={isMatch?'100%':'30.2%'} >
    <ImageSelector isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} />
    </Box>

    <Stack direction={isMatch ? 'column':'row'} spacing={3} >

    <Box width={'50%'}>
        <InputField isError={submitHit && categoryName?.length < 3 } ErrNote={""} InChange={(e) => setCategoryName(e.target.value)}   sideLabel={"Ride Category Name"} placeHolder={"Ride Category Name"} />
        </Box>
        <Box width={isMatch ? "43%" : "50%"} >
        <SelectField isError={ submitHit && selectedService === undefined || selectedService === '' } onSelecting={handleSelect} Data={serviceData}  sideLabel={'Select Service'} placeHolder={'choose'} />
        </Box>

    </Stack>

    <Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
{/* <SelectField isError={ submitHit && selectedSeats === undefined || selectedSeats === ''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Seat Number'} placeHolder={'choose'} /> */}
<MultipleSelect isError={ submitHit && selectedSeats === undefined || selectedSeats === ''} onMultiSelect={handleMultipleSelect} sideLabel={"Seat Number"} Data={NO_OF_SEATS_CAT} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    {/* <SelectField isError={ submitHit && selectedVehicleType === undefined || selectedVehicleType === ''} onSelecting={handleSelect} Data={vehicleType} sideLabel={'Vehicle Type'} placeHolder={'choose'} /> */}
    <MultipleSelect isError={ submitHit && selectedVehicleType === undefined || selectedVehicleType === ''} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Type"} Data={vehicleType} />

    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    {/* <SelectField isError={ submitHit && selectedCategory === undefined || selectedCategory === ''} onSelecting={handleSelect} Data={vehicleCategory} sideLabel={'Vehicle Category'} placeHolder={'choose'} /> */}
    <MultipleSelect isError={submitHit && selectedCategory === undefined || selectedCategory === ''} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} Data={vehicleCategory} />
    </Box>

</Stack>

    </Stack>
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <FormSubmitButtons buttonStatus={write}  handleSubmit={handleFormSubmit}/>
    </Stack>
  
  </Box>
  )
}

export default AddCategoryForm
