import { Box, IconButton, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import ApiCall from '../../../Helper/ApiCalls'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import DatePickerComponent from '../../Components/Forms/DatePickerComponent'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import { useSelector } from 'react-redux'
import ClearAllIcon from '@mui/icons-material/ClearAll';


function SurChargeFilter({CloseModal,FilterColumn,isSpecial}) {
  const [allZoneName , setAllZoneName] = useState([])
  const [isClear,setIsClear] = useState(false)
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const Seats = [
        2,4,6
    ]

      const daysData = [
       {name:   "Monday"},
       {name:  "Tuesday"},
       {name:"Wednesday"},
       {name: "Thursday"},
       {name:   "Friday"},
       {name: "Saturday"},
       {name: "Sunday"},
         ]



         useEffect(()=>{

  
            ApiCall("GET", `/trip-service/v1/admin/zone/get-all/zones/?countryId=${CountryID}`, null, "uui").then((data)=>{
              if(data?.status === 'success'){
          
                let rowZones = data?.result
          
             
             
                const filterdName = rowZones?.map((eachZone , index)=>{
                  return {name:eachZone?.Name}
          
                })

                setAllZoneName(filterdName)
           
                 
              
              } })
          
          },[])


          const handleMultipleSelect = (label,data)=>{

            if(label === 'Days'){
                
              let filteredData = data?.map((item)=>{
                return item?.name
              })
          
           FilterColumn(filteredData,"")
          
            }else if(label === 'Zone'){

              let filteredData = data?.map((item)=>{
                return item?.name
              })

              FilterColumn(filteredData,"")
            }
          
          }



          const handleDateSelection =(date)=>{
            FilterColumn(date,"") 
   
            
           }

           const HandleClearFilter =()=>{
            setIsClear((i)=> !i)
          }

             
   

  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={daysData} fieldName={'Days'}/> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Days"} Data={daysData} />
    </Box>
    
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={allZoneName} fieldName={'Zone'} /> */}
    <Box width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={allZoneName} />
    </Box>
  
   { isSpecial &&<Box> <DatePickerComponent clearStatus={isClear}  sideLabel={"Date"} filter={true}  handleSelection={handleDateSelection} /> </Box>}
   <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
    </Stack>
    
  </Box>
  )
}

export default SurChargeFilter
