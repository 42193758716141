import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import DataTable from '../../Components/Table/DataTable'
import RideFilter from '../RideServiceManagement/RideFilter'
import ApiCall from '../../../Helper/ApiCalls'
import { useTitle } from '../../../Hooks/UseTitle'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBalance } from '../../Components/MultiFunctions/MultiFunctions'
import LinearIndeterminate from '../../Components/Loading/LoadingPage'
import FormModal from '../../Components/Modal/FormModal'
import AddAmount from './WalletDetails/AddAmount'
import { checkPrivilage } from '../../../Sessions/Permisssions'
import { insertModalDataAction } from '../../Redux/Actions/ModalActions'
import { toast } from 'react-toastify'


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "name",
      label: "Driver Name",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    // {
    //   id: "category",
    //   label: "Category",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    {
      id: "accountHolderId",
      label: "Account Holder ID",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "walletAmount",
      label: "Wallet Amount",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "totalWithdrawal",
      label: "Total Withdrawal",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "actionWallet",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ]; 




//   {
//     id: "actionCancel",
//     label: "Actions",
//     minWidth: 170,
//     align: "left",
//     sort: false,
//   },



function WalletMangementModule() {
    useTitle("Wallet Management - Geolah");

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [loading ,setLoading] = useState(true)
    const [modalStatus, setModalStatus] = useState(false);
    const [modalData,setModalData] = useState({})
    const [deductStatus , setDeductStatus] = useState(false)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const {currentModaldata} = useSelector((state) => state.modal)
    const dispatch = useDispatch()
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)

    useEffect(()=>{
   
      let isWrite = checkPrivilage('Wallet','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Wallet','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Wallet','DELETE')
       setDeletePer(isDelete)
       },[])

  
    const navigate = useNavigate()






    // api call
useEffect(()=>{

  (async () => {
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
    try {
      const data = await ApiCall("GET", `/pay-service/v1/admin/driver/wallet/management/?countryId=${CountryID}`, null, "uui");
      const walletData =data?.result
      setTotalRows(walletData?.length)
  
      console.log(walletData,"<---resultsssss herreeeeee")
      
     let filteredData = await walletData.slice(currentOffset, currentOffset + currentLimit).map((eachItem, index) => {
      const walletAmount = parseFloat(eachItem?.PaymentBalanceAccount?.[0]?.AvailableBalance/100)
      const totalWithdrawals = parseFloat(eachItem?.TotalWithdrawAmount)
      const lastTransactions = parseFloat(eachItem?.LastTransaction)

        let items = {
          _id: eachItem?.UserID,
          userID:eachItem?.UserID,
          id: index + 1,
          name: eachItem?.firstName + " " + eachItem?.lastName,
          lastTransaction:  isNaN(lastTransactions) ? "N/A" : lastTransactions.toFixed(2) || "N/A",
          totalWithdrawal: isNaN(totalWithdrawals) ? "N/A" : totalWithdrawals.toFixed(2) || "N/A" ,
          accountHolderId:eachItem?.AccountHolderID,
          walletAmount:isNaN(walletAmount) ? "N/A" : walletAmount.toFixed(2) || "N/A" ,
          // description: eachItem?.Description,
          // vehicleType: vehicles?.join(", "),
          // vehicleCatogery: eachItem.VehicleCategory ? eachItem.VehicleCategory.join(", ") : "N/A",
          // features: adOnFeatures.length > 0 ? adOnFeatures?.join(", ") : "N/A",
          // status: eachItem?.IsEnabled
        };

        return items;
      })
      setLoading(false)

      setRows(filteredData);
      setTableData(filteredData);






      // console.log(allBalances,"<---resultsssss herreeeeee")
      // Handle the data here
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  })();

  },[success,CountryID,currentPage])



    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.userID?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.lastTransaction?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.totalWithdrawal?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.walletAmount?.toString()?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  // handle add amount 

  const handleAddAmount =(data,status)=>{
   status && setDeductStatus(true)
    let modalMainData = {

      driverId:data?._id,
      countryId: CountryID,
      amount: 0
    }

    setModalData(modalMainData)
    setModalStatus(true);
console.log(data,"<--the add adataaa")
  }

  const onClose = (isFlag) => {
    if(isFlag){
      dispatch(insertModalDataAction(!currentModaldata))
      toast.success("Transaction success");
    }
    setModalStatus(false);
    setDeductStatus(false)
  };
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 

  const handleProfileEdit = (id,newData)=>{
    const newTab = window?.open(`/wallet-details/${id}`, '_blank');
    if (newTab) {
      newTab?.focus(); // Optionally, you can focus on the new tab
    }
  
   }
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/wallet-details/${id}`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
     console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
  }


    const handleColumnFilter = (items, searchText) => {



        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }

    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };


      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }

  return (
<Box>
<FormModal Type={'Small'} handleclose={onClose} status={modalStatus} component={<AddAmount isDuduct={deductStatus} Success={onClose} sendData={modalData} />} />
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
   {!loading ?  <DataTable
     handlingPage={handlePageChange}
     totalCount={totalRows}
        DeletePermission={deletePer}
        ButtonPermission={write}
        EditPermission={edit}
     handleClickProfileDetails={handleProfileEdit}
      addWalletMoney={handleAddAmount}
      isLoading={loading}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      /> : <LinearIndeterminate/>
    }
</Box>
  )
}

export default WalletMangementModule
