import { Box, Checkbox, Paper, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

function SingleCardUpdate({ cardName, cardImage , handleCardData,ID,currentData}) {
    const [checked, setChecked] = useState(false)
    const [readValue,setReadValue] = useState(false)
    const [createValue , setCreateValue] = useState(false)
    const [editValue,setEditValue] = useState(false)
    const [deleteValue , setDeleteValue] = useState(false)

useEffect(()=>{

  if(currentData?.length > 0){
    setCreateValue(currentData?.[0])
    setReadValue(currentData?.[1])
    setEditValue(currentData?.[2])
    setDeleteValue(currentData?.[3])
  }


},[currentData])





    const PinkSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: grey[600],
          "&:hover": {
            backgroundColor: alpha(grey[600], theme.palette.action.hoverOpacity),
          },
          transition: "background-color 0.5s", // Change the duration to make the transition faster
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: grey[600],
          transition: "background-color 0.5s", // Change the duration to make the transition faster
        },
      }));
      
      const label = { inputProps: { "aria-label": "Color switch demo" } };
      const labelTwo = { inputProps: { "aria-label": "Checkbox demo" } };

      useEffect(() => {
        const areAllChecked = readValue && createValue && editValue && deleteValue;
        setChecked(areAllChecked); 
         
        let currentState={
          create:createValue,
          read:readValue,
          update:editValue,
          delete:deleteValue,
        }
    
        handleCardData(ID, cardName, currentState)   
      }, [readValue, createValue, editValue, deleteValue]);
    
      const handleCheckAllChange = () => {
        const allChecked = !checked;
        setChecked(allChecked);
        setReadValue(allChecked);
        setCreateValue(allChecked);
        setEditValue(allChecked);
        setDeleteValue(allChecked);
      };
    
      const handleCheckboxChange = (property) => (event) => {
        const isChecked = event.target.checked;
        if (property === "readValue") {
          setReadValue(isChecked);
        } else if (property === "createValue") {
          setCreateValue(isChecked);
        } else if (property === "updateValue") {
          setEditValue(isChecked);
        } else if (property === "deleteValue") {
          setDeleteValue(isChecked);
        }
      };


  return (
<Box marginTop={"10px"} width="300px" height="300px">
    <Paper sx={{ height: "100%", borderRadius: "20px", padding: "10px" }} elevation={3}>
      <Box display="flex" justifyContent="space-between">
        <Stack alignItems="center" direction="row" spacing={2}>
          <img width={'24px'} height={'24px'} src={cardImage} alt="icons" />
          <Typography>
            <strong>{cardName}</strong>
          </Typography>
        </Stack>
        <PinkSwitch color="primary" {...label} checked={checked} onChange={handleCheckAllChange} />
      </Box>

      <Stack marginTop="30px" spacing={1}>
        {[
          { label: "Create", value: createValue },
          { label: "Read", value: readValue },
          { label: "Update", value: editValue },
          { label: "Delete", value: deleteValue },
        ].map((action) => (
          <Box
            key={action.label}
            width={"100px"}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography variant="subtitle2" sx={{ opacity: "40%" }}>
              {action.label}
            </Typography>
            <Checkbox
              checked={action?.value}
              onChange={handleCheckboxChange(`${action.label.toLowerCase()}Value`)}
              {...labelTwo}
              color="default"
            />
          </Box>
        ))}
      </Stack>
    </Paper>
  </Box>
  )
}

export default SingleCardUpdate
