import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import whiteLogo from '../../../Assets/Group 10.png'
import serviceLogo from '../../../Assets/dashCar.svg'
import { makeStyles } from '@material-ui/core/styles';

import { useState } from "react";
import {
  Collapse,
  Skeleton,
  Stack,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import UserMenu from "../../../Layout/TopBar/UserMenu";
import logo from "../../../Assets/Outlook-zfx3n5fs_1.png";
import { useEffect } from "react";
import { SidevaLinks } from "./SideNavLinks";
import { CookieSharp, ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import notificationIcon from "../../../Assets/Group 4336.svg";
import menuIconDash from '../../../Assets/menu 1.svg'
import TableSearchExport from "../TableSearchExport/TableSearchExport";
import SearchBar from "../Forms/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { insertUserDataAction } from "../../Redux/Actions/UserActions";
import ApiCall from "../../../Helper/ApiCalls";
import { USERRequest } from "../../RequestMethord";
import logOutNewIcon from '../../../Assets/logout 1.svg'
import DropDown from "../Forms/DropDown";
import { insertNavigationDataAction } from "../../Redux/Actions/NavigationActions";
import { filterMenuItems, formatRolePermissions, newformatRolePermissions } from "../MultiFunctions/MultiFunctions";
import { warning } from "framer-motion";

const drawerWidth = 215;


const openedMixin = (theme) => ({
  minWidth: drawerWidth, // Set the width when the drawer is open
  maxWidth: drawerWidth, // Set the width when the drawer is open
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen * 2,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  minWidth: `calc(${theme.spacing(7)} + 1px)`, // Set the width when the drawer is closed
  maxWidth: `calc(${theme.spacing(7)} + 1px)`, // Set the width when the drawer is closed
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen * 2,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      "&.css-11630a2": {
        // Your custom styles here
        ...closedMixin(theme),
      },
    },
  }),
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      "&.css-11630a2": {
        // Your custom styles here
        ...openedMixin(theme),
      },
    },
  }),
}));





const useStyles = makeStyles(() => ({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#DEEAED',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A5A5A5',
      borderRadius: '4px',
    },
  },
}))


export default function MiniDrawer({ children }) {

const [selectedIndex,setSelectedIndex] = useState('Dashboard')

const {currentNavigationdata} = useSelector((state) => state.navigation)
const {currentCountrydata:CountryID} = useSelector((state) => state.country)


  const TOKEN = localStorage.getItem("jwtToken");
  const navigate = useNavigate()
  const {currentUserdata} = useSelector((state) => state.user)
  const currentCountries = currentUserdata?.CountryIDS


  const [expand, setExpand] = useState(false);
  const [expands, setExpands] = useState([false,false,false]);
  const dispatch = useDispatch()
  const [loadingData, setLoadingData] = useState(false);
  const [naviLinks,setNaviLinks] = useState([])
  const [allCountry , setAllCountry] = useState([])
  const [alteredPermission , setAlteredPermission] = useState([])

  useEffect(()=>{
    if(TOKEN){

      if(!currentUserdata?.ID){
        navigate('/')
     }

    }else{
  
      dispatch(insertUserDataAction())
      navigate('/')
    
    }
 

  },[currentUserdata,TOKEN])


 useEffect(()=>{

  if(!currentNavigationdata){
    dispatch(insertNavigationDataAction('Dashboard'))
  }

 },[])

//  const alteredData = newformatRolePermissions(currentUserdata?.AdminUserRole,1)
// console.log(currentUserdata,"<---the alterationnnssss")
//  console.log(alteredData,"#@#@#@@@@@####@@@@@@##")



 useEffect(()=>{
  if(currentNavigationdata){
  
  }

 },[currentNavigationdata])






  useEffect(()=>{
if(SidevaLinks){
 
  const permissions = currentUserdata?.AdminUserRole
  const alteredData = newformatRolePermissions(permissions,CountryID)
  setAlteredPermission(alteredData)
  // setNaviLinks(SidevaLinks)
  // dispatch(insertUserDataAction({...currentUserdata,adminRole:alteredData}));
 const filteredNavLinks =  filterMenuItems(SidevaLinks,alteredData)
   



 setNaviLinks(filteredNavLinks)
}    
  },[SidevaLinks,CountryID])


  useEffect(()=>{

    if(alteredPermission?.length > 0){
      dispatch(insertUserDataAction({...currentUserdata,adminRole:alteredPermission}))
     
       }

  },[alteredPermission])



  useEffect(() => {
    function delayedFunction() {
      setLoadingData(false);
    }
    const timeoutId = setTimeout(delayedFunction, 2000);
  }, []);


  const handleExpandClick = (id) => {
// here the id is expanded field in sidenavlink array it represent the order of the expandable list 
//CHANGE THE IF ACCORDINGLY
    let changed = !expands[id]
    if(id === 0){
      setExpands([changed,expands[1],expands[2]])
    }else if(id === 1){
      setExpands([expands[0],changed,expands[2]])
    }else{
      setExpands([expands[0],expands[1],changed])
    }

    // expands[id] = 
    // setExpands([true,false])
 };




 



  useEffect(()=>{

    ApiCall("GET", "/user-service/v1/app/auth/country-codes", null, "uui").then((data)=>{
      if(data?.result){
  
      const countryData = data?.result

      let filteredItems = countryData?.map((eachItems)=>{
        let items={
          name:eachItems?.CountryName,
             ID:eachItems?.ID,
             code:eachItems?.CountryCode

        }

        return items
      })

     

      setAllCountry(filteredItems?.filter((country) => currentCountries.includes(country?.ID)))
  

   } })
  },[])









  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        slg:1000,
        sxl: 1430,
        xl: 1536,
        spl:782,
      },
    },
  });
  // const isLgMatch = useMediaQuery(theme?.breakpoints?.down("lg"));
  // const isSxlMatch = useMediaQuery(theme?.breakpoints?.down("sxl"));
  const isMdMatch = useMediaQuery(theme?.breakpoints?.down("md"));
  const isSmMatch = useMediaQuery(theme?.breakpoints?.down("sm"));
  const isLgMatch = useMediaQuery(theme?.breakpoints?.down("lg"));
  const midMatch = useMediaQuery(theme.breakpoints.down("slg"))
  const splMatch = useMediaQuery(theme.breakpoints.down("spl"))
  const [open, setOpen] = useState(true);

  // useEffect(() => {
  //   if (isMdMatch || isLgMatch) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [isMdMatch, isLgMatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  const classes = useStyles();

  const handleSelection =(index)=>{
    dispatch(insertNavigationDataAction(index))
      //  setSelectedIndex(index)
  }




  return (
    // <Box  sx={{ display:"flex" , height:"180vh" }}>
    <Box  sx={{ display:"flex" }}>
      <CssBaseline />
      <Drawer
       classes={{ paper: classes.customScrollbar }}
        anchor="top"
        
        PaperProps={{
          sx: {
         
            backgroundColor: "#DEEAED",
            color: "black",
            paddingTop:open && "25px",
            // margin: "10px",
            // marginTop: "10px",
            width: "50px",
            // borderRadius: "20px",
            minHeight: "100%",
          },
        }}
        // onMouseEnter={handleDrawerOpen}
        // onMouseLeave={handleDrawerOpen}
        variant="permanent"
        open={open}
      >
        <DrawerHeader sx={{justifyContent:'center'}} >
          {
            open ? <img src={whiteLogo} alt="logo" /> :
            <IconButton
            
             sx={{backgroundColor:'grey' , textDecorationColor:'ThreeDShadow',}}
         
            size="small"
            onClick={handleDrawerOpen}
          >
           
                <img  src={menuIconDash} alt="logo" />
           
           
          </IconButton>

          }
         
 
        </DrawerHeader>
        <List>
 

        </List>

        <List >


      {open && <Box p={2} ml={2} mb={1} sx={{bgcolor:"#BAD9E0",width:"180px",height:"68px",borderRadius:"8px",alignSelf:"center"}}>

          <Stack alignItems={'center'} direction={'row'} spacing={2}>
            <img height={'32px'} width={'32px'} src={serviceLogo} alt="logo"/>
            <Stack >
            <Typography sx={{color:"#0C1116"}}  variant='caption'>Service</Typography>
            <Typography sx={{fontWeight:600}} variant='body2'>Transport</Typography>
            </Stack>
            <Stack spacing={-0.5}>
              <KeyboardArrowUpIcon sx={{width:15,height:15,cursor:'pointer'}} fontSize="small"/>
              <KeyboardArrowDownIcon sx={{width:15,height:15,cursor:'pointer'}} fontSize="small"/>
            </Stack>

          </Stack>

          </Box> }
          {naviLinks.map((eachItem, index) => {
            return eachItem.childrens ? (
              <ListItem
                key={eachItem.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleExpandClick(eachItem.expanded)}
                  
                >
                  <ListItemIcon
                    sx={{
                      color: "black",
                    
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {
                      eachItem.icon
                    }
                  </ListItemIcon>

                  <ListItemText
                      
                    primary={
                     
                      <Typography variant="body1" fontWeight="bold">
                        {eachItem.name}
                      </Typography>
                      
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {open ? (
                    
                      expands[eachItem?.expanded] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                  
                  ) : (
                    ""
                  )}
                </ListItemButton>
                <Collapse in={expands[eachItem?.expanded] && open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {eachItem.childrens?.map((child, index) => (
                      <Link
                        to={child.path}
                        style={{ textDecoration: "none", color: "black",fontWeight: "bold" }}
                      >
                        <ListItemButton   onClick={() => handleSelection(child.name)} key={child.name} sx={{ pl: 4,backgroundColor:currentNavigationdata === child.name && '#B0D6D5' }}>
                          
                          <ListItemIcon>
                            {
                        
                              child.icon
                            }
                          </ListItemIcon>
                          <ListItemText
                     
                            primary={
                             
                                <Typography variant="caption" fontWeight="bold">
                                {child.name}
                              </Typography>
                          
                            }
                          />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </ListItem>
            ) : (
              <ListItem
                 
                key={eachItem.name}
                disablePadding
                sx={{ display: "block",backgroundColor:currentNavigationdata === eachItem.name && '#B0D6D5'}}
              >
                <Link
                  to={eachItem.path}
                  style={{ textDecoration: "none", color: selectedIndex === eachItem.name ? 'black' : "black" }}
                >
                  <ListItemButton
                  onClick={() => handleSelection(eachItem.name)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                      
                        color: selectedIndex === eachItem.name ?"black" : "black",
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        eachItem.icon
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        
                          <Typography variant="body1" fontWeight="bold">
                          {eachItem.name}
                        </Typography>
                      
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            );
          })}
        </List>

        {/* <List>
          {["Logout"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <Link
                to="/signin"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                onClick={handleLogOut}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "black",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        style={{ backgroundColor: "#404040" }}
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    ) : (
                      <LogoutIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      loadingData ? (
                        <Skeleton
                          style={{ backgroundColor: "#404040" }}
                          animation="wave"
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                        />
                      ) : (
                        text
                      )
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      <Box component="main" sx={{ width: "110%", flexGrow: 5, pt: 0,bgcolor:'#ecf0f1' }}>
        <Box
          sx={{
            position: "sticky",
            top: "-0.1px",
            zIndex: 999,
            backgroundColor: "white",
            paddingLeft: "0.1vh",
          }}
        >
          <Stack display="flex" justifyContent="space-between" direction="row">
            <Stack alignItems="center"  direction='row' spacing={1}  >
            {
              open ?
              <IconButton
              sx={{ marginLeft: open ? "15px" : "50px" }}
              size="small"
              onClick={handleDrawerClose}
            >
              {
                loadingData ? (
                  <Skeleton variant="rectangular" width={210} height={60} />
                ):(
                  <img src={menuIconDash} alt="logo" />
                )
              }
             
            </IconButton>: null

            }

          <SearchBar/>

            


            </Stack>
        

            <Stack alignItems={'center'}  paddingRight="44px" direction="row">

            <DropDown countryData={allCountry} />



              {/* <IconButton  size="large">
            
                    <img src={notificationIcon} alt="notifi" /> */}
               
                
                {/* <CircleNotificationsIcon fontSize="large" /> */}
              {/* </IconButton> */}
          

         
                  <UserMenu />
             

              <Stack justifyContent="center" spacing='1'>

                <Typography sx={{fontWeight:'600',fontSize:'12px'}} >{currentUserdata?.FullName}</Typography>
                <Typography sx={{opacity:'50%',fontSize:'10px'}}  variant="caption">{currentUserdata?.UserRole?.[0]?.Role?.Name}</Typography>
               
              </Stack>

              {/* <IconButton size="large">
              <img src={logOutNewIcon} alt="logout-newicon" />
              </IconButton> */}
           

              
            </Stack>
          </Stack>
        </Box>

        <Box
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            p: 3,
            marginTop: "1vh",
            paddingLeft:
              isMdMatch === true && isSmMatch === false
                ? "4vh"
                : isSmMatch
                ? "2vh"
                : open
                ? "5vh."
                : "8.5vh",
          }}
        >
          
          {children}
        </Box>
      </Box>
    </Box>
  );
}
