import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import SelectTabButtons from '../../../Components/Forms/SelectTabButtons'

function AddDayFair() {
    const [activeTab , setActiveTab] = useState('6 AM - 7 AM')
    
    const onTabChange = (tabName)=>{
         setActiveTab(tabName)
    }

  return (
    
<Box>
<Stack spacing={3}>
<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Minimum Fair"} placeHolder={"Minimum Fair"} />
<SelectField sideLabel={"Maximum distance for minimum fair"} placeHolder={"Select"} />
</Stack>

<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Additional Fair per KM"} placeHolder={"Type amount"} />
<InputField sideLabel={"Powerlah Fair"} placeHolder={"Type amount"} />
</Stack>


<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Surge charge for peak hours"} placeHolder={"Type amount"} />
<InputField sideLabel={"Cancellation charge"} placeHolder={"Type amount"} />
</Stack>

<SelectTabButtons ActiveButton={activeTab} OnChange={onTabChange} />


<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Child Seat"} placeHolder={"Type amount"} />
<InputField sideLabel={"Booster Seat"} placeHolder={"Type amount"} />
</Stack>




<Stack direction={'row'} spacing={3} >
<InputField sideLabel={"Bicycle"} placeHolder={"Type amount"} />
<InputField sideLabel={"Pets"} placeHolder={"Type amount"} />
</Stack>


</Stack>

        </Box>


    
  )
}

export default AddDayFair
