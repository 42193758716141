
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';
import { useTitle } from '../../../../Hooks/UseTitle';
import AddAdmin from '../../Administration/AddAdmin';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';





function CreateEmplyee() {
  const navigate = useNavigate()
  
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Administration
      </Typography>,
 <Typography key="3" color="text.primary">
     Create Employee
      </Typography>,
  ];

  useTitle("Create Employee - Geolah");
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [any,setAny] = useState()

useEffect(()=>{
  
  let isAany = checkAnyPrivilage('Employee')
  console.log(isAany,"<---IS ANYYY")
  setAny(isAany)
   },[])

   console.log(any,"<)))--ANY STATEEE")
  return (
    any ? ( 
   <Box>
    <CreatePageHead title={'Create Employee'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
       {/* <AddServiceForm/>   */}
       <AddAdmin/>
    </Box>
    </Box>
   </Box>
   ): any === false ? (
    <RestrictedUsage/>
   ) : null
  )
}

export default CreateEmplyee



