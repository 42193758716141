import { Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../../../Components/Forms/InputField'
import SnackBars from '../../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons'
import ApiCall from '../../../../../Helper/ApiCalls'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate'
import { checkPrivilage } from '../../../../../Sessions/Permisssions'

function CreateRadiusForm({currentZoneIndex}) {
    const [standerdRadius , setStanderdRadius] = useState(0)
    const [powerlahRadius , setPowerlahRadius] = useState(0)
    const [powerlahCost , setPowerlahCost] = useState(0)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [submitHit,setSubmitHit] = useState(false)
    const { zoneId } = useParams();
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Zone','UPDATE')
      setEdit(isEdit)
      
  
       },[])


    useEffect(()=>{

      ApiCall("GET", `/trip-service/v1/admin/zone/get-all/zones/?countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
    
          const zoneData = data?.result
          let currentZoneDetails = zoneData[currentZoneIndex]
          console.log(zoneData[currentZoneIndex],"<in detailsssssss")
           setPowerlahRadius(currentZoneDetails?.PowerLahRadius)
           setPowerlahCost(currentZoneDetails?.PowerLahCharge)
           setStanderdRadius(currentZoneDetails?.NormalSearchRadius)
          } })
    
    },[CountryID,currentZoneIndex,zoneId])











    const handleFormSubmit =()=>{
        setSubmitHit(true)
        let sendData = {
            zoneId: zoneId,
            powerlahRadius: parseFloat(powerlahRadius),
            powerlahCost: powerlahCost,
            normalSearchRadius:standerdRadius 
        }

        ApiCall("PUT", "/trip-service/v1/admin/zone/update/radius", sendData).then((data)=>{
            if(data.result === 'success'){
          
              setOnSuccess((i) => !i )
              setOnApiSuccess("Success")
              
        
            }
        })
        .catch((error) => {
           setOnFailed((i) => !i)
          
            if(error.message){
              setOnApiFailed(error.message)
              console.log(error , "<----errrrrrr")
            }else{
              setOnApiFailed("Something went wrong.!")
            }
        
        
        });
       

    }

  return (
    <>
    <Stack pt={5.5} spacing={3} >
    <Box width={'100%'}>
        <InputFieldUpdate Value={standerdRadius} Type={'number'} isError={false} ErrNote={""} InChange={(e) => setStanderdRadius(e.target.value)}   sideLabel={"Standard Radius"} placeHolder={"Standard Radius"} />
        </Box>
        <Box width={'100%'}>
        <InputFieldUpdate Value={powerlahRadius} Type={'number'} isError={false} ErrNote={""} InChange={(e) => setPowerlahRadius(e.target.value)}   sideLabel={"Powerlah Radius"} placeHolder={"Powerlah Radius"} />
        </Box>    <Box width={'100%'}>
        <InputFieldUpdate Value={powerlahCost} Type={'number'} isError={false} ErrNote={""} InChange={(e) => setPowerlahCost(e.target.value)}   sideLabel={"Powerlah Cost"} placeHolder={"Powerlah Cost"} />
        </Box>

        <Stack direction={'row'} spacing={0}>
        <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />

        </Stack>
        <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
        <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />

    </Stack>
    </>
  )
}

export default CreateRadiusForm
