
import { USERRequest, publicRequest } from "../App/RequestMethord";

import jwtDecode from "jwt-decode";

// a common function to make Get and post api request

const ApiCall = async (type, url, params,isIn) => {
  const TOKEN = localStorage.getItem("jwtToken");
  if (TOKEN) {
    const tokenData = jwtDecode(TOKEN);
    const currentTime = Date.now() / 1000; // Convert to seconds

    if (tokenData.exp < currentTime) {
      localStorage.removeItem("jwtToken");
      // Token has expired, navigate to the login page
      window.location.href = "/"; // Adjust the URL as needed
      return; // Stop further API processing
    }
  } else {
    // Token doesn't exist, navigate to the login page
    window.location.href = "/"; // Adjust the URL as needed
    return; // Stop further API processing
  }








  if (type === "GET") {

  try {
      const result = await USERRequest.get(url)

            if (result) {
               return result.data
            }

      }     catch (error) { 
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
    
          // You can perform any necessary error handling here
          throw new Error(errorMessage);
        } else {
          // Handle other errors
          throw new Error("An error occurred during the API call.");
        }
            }

  } else if (type === "POST") {

  try {
      const result = await USERRequest.post(url, params)

            if (result) {
               return result.data
            }
      }     catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
    
          // You can perform any necessary error handling here
          throw new Error(errorMessage);
        } else {
          // Handle other errors
          throw new Error("An error occurred during the API call.");
        }
              
            }
  }
  else if (type === "PATCH") {
   try {
     const result = await USERRequest.patch(url, isIn === 'BODY' && params, { params: isIn !== 'BODY' && params });
     if (result) {
       return result.data;
     }
   } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      const errorMessage = error.response.data.message;

      // You can perform any necessary error handling here
      throw new Error(errorMessage);
    } else {
      // Handle other errors
      throw new Error("An error occurred during the API call.");
    }
   }
 }
 else if (type === "PUT") {
  try {
    const result = await USERRequest.put(url, params);
    if (result) {
      return result.data;
    }
  } catch (error) {
   if (error.response && error.response.data && error.response.data.message) {
     const errorMessage = error.response.data.message;

     // You can perform any necessary error handling here
     throw new Error(errorMessage);
   } else {
     // Handle other errors
     throw new Error("An error occurred during the API call.");
   }
  }
}else if (type === "DELETE") {
  try {
    const result = await USERRequest.delete(url, { params: params });

    if (result) {
      return result.data;
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      const errorMessage = error.response.data.message;

      // You can perform any necessary error handling here
      throw new Error(errorMessage);
    } else {
      // Handle other errors
      throw new Error("An error occurred during the API call.");
    }
  }

  }

};

export default ApiCall;

// dedicated login api function

export const loginApiCall = async (type, url, params, requestMethord) => {
  try {
   
 

     const result = await publicRequest.post(url, params)

           if (result) {
              return result.data
           }

           } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
              const errorMessage = error.response.data.message;
        
              // You can perform any necessary error handling here
              throw new Error(errorMessage);
            } else {
              // Handle other errors
              throw new Error("An error occurred during the API call.");
            }
           
           }
};















// eg : how to call this function

//   import apiCall from './apiCall';

// const MyComponent = () => {
//   const fetchData = async () => {
//     try {
//       const data = await apiCall('GET', 'https://example.com/api/data', { limit: 10 });
//       console.log(data);
//     } catch (error) {
//       console.error(error);
//     }
//   };
