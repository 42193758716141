import { basicConstants } from "../Constants/Constants"; 

const initialBasicState = {
    currentBasicdata: {},
};

export const BasicReducer = (state = initialBasicState, action) => {
    switch (action.type) {
        case basicConstants.INSERT_BASIC_DATA:
            return { ...state, currentBasicdata: action.payload };
        default:
            return state;
    }
};