import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import imageIcon from "../../../Assets/Group 4719.svg";
import browseFrame from "../../../Assets/Forms/Frame 47916 (2).svg";
import { useFileUpload } from "use-file-upload";

function ImageSelector({ handleFileChange, isError, sideLabel,disableReq }) {
  const [files, selectFiles] = useFileUpload();
  const [isExeded,setIsExeded] = useState(false)

  useEffect(() => {
   !isExeded && handleFileChange(files);
  }, [files]);

  const handleFileSelect = ({ name, size, source, file }) => {
    console.log(size , " <-----")
    if (size <= 1000000) {
      selectFiles({ accept: "image/*" }, { name, size, source, file });
      setIsExeded(false)
    }else {
      setIsExeded(true)
      selectFiles({})
      console.log("File size exceeds the limit (1 MB).");
    }
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction={"row"}>
        <Typography color={"black"}>{sideLabel || "Profile Picture"}</Typography>
       {!disableReq && <Typography color={"red"}>*</Typography>}
      </Stack>

      <Box
        pl={2}
        display={"flex"}
        alignItems={"center"}
        border={isError ? "1px dashed #c0392b" : "1px dashed #c6c6c6"}
        width={"100%"}
        height={"120px"}
      >
        <Box
          alignItems={"center"}
          width={"99%"}
          display={"flex"}
          justifyContent={"space-evenly"}
        >
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <img
              style={{ width: "80px", height: "80px" }}
              src={isExeded ? imageIcon : files?.source || imageIcon}
              alt="profile"
            />
          </Stack>
          <Stack>
            <IconButton
              onClick={() =>
                selectFiles({ accept: "image/*" }, handleFileSelect)
              }
            >
              <img
                style={{ width: "79px", height: "44px" }}
                src={browseFrame}
                alt="profile"
              />
            </IconButton>
            <Typography  fontSize={13} color={isExeded ?"red" : "grey"}>
              Upload an image. Max size 1 MB
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default ImageSelector;

