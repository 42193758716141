import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';

import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import ConvenienceForm from './ConvenienceForm';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import { useNavigate } from 'react-router-dom';





function CreateConvenienceFee() {

  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
        Convenience Fee
        </Typography>,
   <Typography key="3" color="text.primary">
       Create Convenience Fee
        </Typography>,
    ];

  useTitle("Create Fee - Geolah");
  const [any,setAny] = useState(true)

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Convenience Fee')
    setAny(isAany)
     },[])


  return (
    any ? (
   <Box>
    <CreatePageHead  title={'Create Convenience Fee'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
{/* {} */}
<ConvenienceForm/>
    </Box>
    </Box>
   </Box>
   ) : any === false ? (
    <RestrictedUsage/>
   ): null
  )
}

export default CreateConvenienceFee