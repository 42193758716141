import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ApiCall from '../../../../Helper/ApiCalls';
import TableHead from '../../../Components/Table/TableHead';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../../Components/Table/DataTable';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PaymentFilterDriver from '../../DriverModule/DriverDetails/GeneralDetails/Pages/PaymentFilterDriver';
import { convertToCurrentCountryTime, formatDates } from '../../../Components/MultiFunctions/MultiFunctions';


const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },
  {
    id: "cardType",
    label: "Transaction Medium",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "method",
    label: "Method",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "transactionId",
    label: "Transaction Id",
    minWidth: 200,
    align: "left",
    sort: true,
  },
  {
    id: "transactionRef",
    label: "Referance Id",
    minWidth: 180,
    align: "left",
    sort: true,
    
  },
  {
    id: "type",
    label: "Type",
    minWidth: 150,
    align: "left",
    sort: false,
    
  },
  {
    id: "tripId",
    label: "TripId",
    minWidth: 150,
    align: "left",
    sort: false,
    
  },
  {
      id: "dateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
  // {
  //   id: "minimumFair",
  //   label: "Minimum Fair",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "extraFair",
  //   label: "Extra Fair",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "amentiasFair",
  //   label: "Amentias Fair",
  //   minWidth: 200,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //   id: "surgeCharge",
  //   label: "Surge Charge",
  //   minWidth: 170,
  //   align: "left",
  //   sort: true,
  // },
  // {
  //     id: "powerlah",
  //     label: "Powerlah",
  //     minWidth: 170,
  //     align: "left",
  //     sort: true,
  //   },
    {
      id: "totalFair",
      label: "Authorised Amount",
      minWidth: 180,
      align: "left",
      sort: true,
    },
    {
      id: "capturedAmount",
      label: "Captured Amount",
      minWidth: 180,
      align: "left",
      sort: true,
    },
    {
      id: "geolahAmount",
      label: "Geolah Amount",
      minWidth: 180,
      align: "left",
      sort: true,
    },
    {
      id: "driverAmount",
      label: "Driver Amount",
      minWidth: 180,
      align: "left",
      sort: true,
    },
    {
      id: "pendingAmount",
      label: "Pending Amount",
      minWidth: 180,
      align: "left",
      sort: true,
    },
    // {
    //   id: "method",
    //   label: "Method",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },


    {
      id: "isOnline",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: "Online",
        onFalse: "Offline",
      },
    },
]; 
 







function TransactionHistory() {
    const [order, setOrder] = useState("ASC");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0)
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [typeFilter,setTypeFilter] = useState('')
    const [methodFilter,setMethodTypeFilter] = useState('')
  const navigate = useNavigate()
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const { id:driverId } = useParams(); 

    // api call

    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
  
      ApiCall("GET", `/pay-service/v1/admin/payment/all/driver-transaction/byId/?driverId=${driverId}&&limit=${currentLimit}&&offset=${currentOffset}${searchInput && `&&search=${searchInput}`}${startDate && `&&fromDate=${startDate}`}${endDate && `&&toDate=${endDate}`}${typeFilter && `&&transactionType=${typeFilter}`}${methodFilter && `&&category=${methodFilter}`}`, null, "uui").then((data)=>{
        if(data?.result){  
          const paymentData = data?.result?.DriverTransactionData  
          setTotalRows(data?.result?.totalCount)
          const filteredData =  paymentData?.map((eachItem,index)=>{
           let dateString = eachItem?.CreatedAt
           let testT = convertToCurrentCountryTime(dateString)
           const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
           const currentStartTime  =  dateString?.slice(11, 16);
           const rideFares = parseFloat(eachItem?.Amount);
           const capAmount = parseFloat(eachItem?.CapturedAmount)
           const surgeAmount = parseFloat(eachItem?.SurgeAmount)
           const geolahAmount = parseFloat(eachItem?.GeolahAmount)
           const driverAmount = parseFloat(eachItem?.DriverAmount)
           const pendingAmount = parseFloat(eachItem?.PendingAmount)
     
           let items = {
                 _id:eachItem._id,
                 id:index+1+currentOffset,
                 method:eachItem?.TransactionCategory,              
                 transactionId:eachItem?.TransactionId || 'N/A',
                 dateTime:testT,
                 type:eachItem?.TransactionType,
                 tripId:eachItem?.TripId || 'N/A',
                 cardType:eachItem?.TransactionMethod,
                //  surgeCharge:isNaN(surgeAmount) ? "N/A" : surgeAmount.toFixed(2) || "N/A",
                 totalFair:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
                 capturedAmount:isNaN(capAmount) ? "N/A" : capAmount.toFixed(2) || "N/A",
                 geolahAmount:isNaN(geolahAmount) ? "N/A" : geolahAmount.toFixed(2) || "N/A",
                 driverAmount:isNaN(driverAmount) ? "N/A" : driverAmount.toFixed(2) || "N/A",
                 pendingAmount:isNaN(pendingAmount) ? "N/A" : pendingAmount.toFixed(2) || "N/A",
  
                 transactionRef:eachItem?.TransactionRef || 'N/A',
                 isOnline:eachItem?.Status || 'N/A'
    
                }
       
                return items;
          })
       
         
       
          setRows(filteredData)
          setTableData(filteredData);
        } })
    },[success,searchInput,startDate,endDate,typeFilter,methodFilter])



    // useEffect(()=>{
    //     setRows(
    //       tableData.filter((item) =>
    //       item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
    //         ))
    //      },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-service/${id}`)
    }
   
  
  }

    const handleChangeInSearch = (value)=>{
        setSearchInput(value)
        console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
     }

    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };

      const handleFilterBar = (status)=>{
        setIsFilterOn(status)
        setStartDate('')
        setEndDate('')
        setTypeFilter('')
        setMethodTypeFilter('')
      }
      


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };


      const handleColumnDateFilter = async(startsUtc, endsUtc) => {
        try{
          if(endsUtc === 'type'){
              setTypeFilter(startsUtc)
          }else if(endsUtc === 'method'){
                setMethodTypeFilter(startsUtc)
          }
          else{
    
            const filteredDates = await formatDates(startsUtc,endsUtc)
            console.log(filteredDates,"<su--rt--tt")
        
            setStartDate(filteredDates?.formattedStartDate)
            setEndDate(filteredDates?.formattedEndDate)
    
          }
    
         
    
        }catch(error){
    
        }
      
        };

        const handlePageChange=(no,rowsCount)=>{
          setCurrentPage(no)
          setRowsPerPage(rowsCount)
          
        }  


  return (
<Box mt={4}>
<TableHead title={'Transaction History'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <PaymentFilterDriver CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
    </Box>

</Box>
</Box>
  )
}

export default TransactionHistory
