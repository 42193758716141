import walletIcon from "../../Assets/SideNav/wallet 1.png";
import userIcon from "../../Assets/SideNav/user (7) 1.svg";

import homeIcon from "../../Assets/SideNav/home (2) 1.svg";

import ticketIcon from "../../Assets/SideNav/ticket 1.png";

import voucherIcon from "../../Assets/SideNav/coupon 1.png";

import fareIcon from "../../Assets/SideNav/price-tag 1.svg";

import paymentIcon from "../../Assets/SideNav/credit-card 1.png";

import newServiceIcon from "../../Assets/icons8-service-30.png";

export const ActivityData = [
  {
    url: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    note: "Booking accepted by driver Miguel",
    time: "Just now",
  },
  {
    url: "https://buffer.com/library/content/images/2022/03/sigmund-MQ2xYBHImKM-unsplash--1--1.jpg",
    note: "Ride starts, customer onboard",
    time: "Just now",
  },
  {
    url: "https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_11368.png",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    note: "Booking accepted by driver Miguel",
    time: "Just now",
  },
  {
    url: "https://buffer.com/library/content/images/2022/03/sigmund-MQ2xYBHImKM-unsplash--1--1.jpg",
    note: "Ride starts, customer onboard",
    time: "Just now",
  },
  {
    url: "https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
  {
    url: "https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_11368.png",
    note: "Miguel Marie requested for a 4 seater",
    time: "Just now",
  },
];

export const ActivityDataTwo = [
  {
    url: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg",
    note: "Angelo Jane",
  },
  {
    url: "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    note: "Patrik Evra",
  },
  {
    url: "https://buffer.com/library/content/images/2022/03/sigmund-MQ2xYBHImKM-unsplash--1--1.jpg",
    note: "Ebolo Lam",
  },
  {
    url: "https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944",
    note: "Boya Bin",
  },
  {
    url: "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
    note: "Arsalex",
  },
  {
    url: "https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_11368.png",
    note: "Alex Sam",
  },
  {
    url: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg",
    note: "Vipin Das",
  },
  {
    url: "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    note: "Vijay",
  },
  {
    url: "https://buffer.com/library/content/images/2022/03/sigmund-MQ2xYBHImKM-unsplash--1--1.jpg",
    note: "Robert Heisenburg",
  },
  {
    url: "https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944",
    note: "Park Kwang",
  },
  {
    url: "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
    note: "Angel",
  },
  {
    url: "https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_11368.png",
    note: "Miguel Marie",
  },
];

export const ActivityDataThree = [
  {
    url: "https://images.unsplash.com/photo-1616455579100-2ceaa4eb2d37?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Ym13JTIwY2FyfGVufDB8fDB8fHww&w=1000&q=80",
    note: "BMW M5",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://images.unsplash.com/photo-1605559424843-9e4c228bf1c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWVyY2VkZXMlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
    note: "Marcedes AMG",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://cdn.motor1.com/images/mgl/g440ng/s3/rimac-nevera.jpg",
    note: "Navera Ev",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://imgd.aeplcdn.com/370x208/n/cw/ec/141867/nexon-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80",
    note: "Tata Nexon",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://img.freepik.com/free-photo/view-car-running-high-speed_23-2150635415.jpg",
    note: "DODGE",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://stimg.cardekho.com/images/carexteriorimages/630x420/BMW/X7/10571/1689673096346/front-left-side-47.jpg?impolicy=resize&imwidth=480",
    note: "BMW X6",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://car-images.bauersecure.com/wp-images/2697/bmwi4_029.jpg",
    note: "BMW EV7",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://images.unsplash.com/photo-1616455579100-2ceaa4eb2d37?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Ym13JTIwY2FyfGVufDB8fDB8fHww&w=1000&q=80",
    note: "BMW M5",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://images.unsplash.com/photo-1605559424843-9e4c228bf1c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWVyY2VkZXMlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
    note: "Marcedes AMG",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://cdn.motor1.com/images/mgl/g440ng/s3/rimac-nevera.jpg",
    note: "Navera Ev",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://imgd.aeplcdn.com/370x208/n/cw/ec/141867/nexon-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80",
    note: "Tata Nexon",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://img.freepik.com/free-photo/view-car-running-high-speed_23-2150635415.jpg",
    note: "DODGE",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://stimg.cardekho.com/images/carexteriorimages/630x420/BMW/X7/10571/1689673096346/front-left-side-47.jpg?impolicy=resize&imwidth=480",
    note: "BMW X6",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
  {
    url: "https://car-images.bauersecure.com/wp-images/2697/bmwi4_029.jpg",
    note: "BMW EV7",
    spec: "Silver  |  SMX 938 P  |  4 seater",
  },
];

export const couponType = [
  {
    name: "Time",
    id: "TIME",
  },
  {
    name: "Users",
    id: "USERS",
  },
];

export const DiscountTypeData = [
  {
    name: "Percent",
    id: "percent",
  },
  {
    name: "Amount",
    id: "amount",
  },
];

export const CouponStatus = ["INACTIVE", "ACTIVE"];

export const TypeReason = ["User", "Admin"];

export const VehicleCategory = [
  {
    name: "Premium",
  },
  {
    name: "Economy",
  },
  {
    name: "N/A",
  },
];

export const PH_CODE = [{ name: "+91" }, { name: "+65" }];

export const NO_OF_SEATS = [
  {
    name: "2",
  },
  {
    name: "3",
  },
  {
    name: "4",
  },
  {
    name: "6",
  },
];
export const NO_OF_SEATS_CAT = [
  {
    name: "4",
  },
  {
    name: "6",
  },
];

export const HOUR_DATA = [
  {
    name: "1",
  },
  {
    name: "2",
  },
  {
    name: "3",
  },
];

export const MINUTES_DATA = [
  {
    name: "5",
  },
  {
    name: "10",
  },
  {
    name: "15",
  },
  {
    name: "20",
  },
  {
    name: "25",
  },
  {
    name: "30",
  },
];

export const App_Type = [
  {
    name: "User App",
    id: 1,
  },
  {
    name: "Driver App",
    id: 2,
  },
];

export const AD_TYPE = [
  {
    name: "OFFER",
    id: "OFFER"
  },
  {
    name: "ALERT",  
    id: "ALERT"
  },
  {
    name: "WHATS NEW",
    id: "WHATS_NEW"
  },
  {
    name: "HEADER OFFERS",
    id: "HEADER_OFFERS"
  },
  
];

export const USERS_TYPE = [
  {
    name: "Driver",
    id: 2,
  },
  {
    name: "User",
    id: 1,
  },
];

export const MIN_BOOKING_TIME = [
  {
    name: "30 min",
  },
  {
    name: "1 hr",
  },
  {
    name: "1.5 hr",
  },
  {
    name: "2 hr",
  },
];

export const MAX_BOOKING_TIME = [
  {
    name: "5 hr",
  },
  {
    name: "10 hr",
  },
  {
    name: "1 day",
  },
  {
    name: "2 day",
  },
];
export const HOURLY_BOOKING = [
  {
    name: "2",
  },
  {
    name: "4",
  },
  {
    name: "6",
  },
];

export const FARE_CONDITION = [
  {
    name: "Greater",
  },
];

export const BONUS_TYPE = [
  {
    name: "Percentage",
  },
  {
    name: "Amount",
  },
];

export const PRIORITY_LIST = [
  {
    name: "normal",
  },
  {
    name: "high",
  },
];

export const ISSUE_STATUS = [
  {
    name: "CLOSED",
  },
  {
    name: "OPEN",
  },
];

export const COLORS = [
  {
    name: "White",
  },
  {
    name: "Black",
  },
  {
    name: "Violet",
  },
  {
    name: "Blue",
  },
  {
    name: "Orange",
  },
  {
    name: "Red",
  },
  {
    name: "Yellow",
  },
  {
    name: "Green",
  },
  {
    name: "Silver",
  },
];

export const ENGINE_TYPE = [
  {
    name: "EV",
  },
  {
    name: "Hybrid",
  },
  {
    name: "Petrol",
  },
  {
    name: "Diesel",
  },
];

export const OWNERSHIP = [
  {
    name: "Self Owned",
  },
  {
    name: "Rental",
  },
];

export const VEHICLE_CATEGORY = [
  {
    name: "Economy",
  },
  {
    name: "Premium",
  },
];

export const licenceType = [
  {
    name: "class 1",
  },
  {
    name: "class 2",
  },
  {
    name: "class 3",
  },
];

export const vocationalLicenceType = [
  {
    name: "PDVL",
  },
  {
    name: "ODVL",
  },
  {
    name: "TDVL",
  },
  {
    name: "BDVL",
  },
];

export const METHORD= [
  {
    id:"CARD",
    name:'Card',

  },
  {
    id:"CASH",
    name:'Cash',
    
  }
]

export const cardData = [
  {
    name: "Dashboard",
    image: homeIcon,
  },
  {
    name: "Ride Service",
    image: newServiceIcon,
  },
  {
    name: "Ride Category",
    image: newServiceIcon,
  },
  {
    name: "Payment",
    image: paymentIcon,
  },
  {
    name: "Voucher",
    image: voucherIcon,
  },
  {
    name: "Tickets",
    image: ticketIcon,
  },
  {
    name: "Admin",
    image: userIcon,
  },
  {
    name: "Wallet",
    image: walletIcon,
  },
  {
    name: "Surcharge",
    image: fareIcon,
  },
];

export const DashTabData = [
  {
    number: 845,
    subtitle: "Bookings",
    color: "#FFEFE2",
  },
  {
    number: 32654,
    subtitle: "Revenue",
    color: "#EFFCEF",
  },
  {
    number: 324,
    subtitle: "Customers",
    color: "#E6F5F9",
  },
  {
    number: 154,
    subtitle: "Drivers",
    color: "#EDF3FF",
  },
  {
    number: 431,
    subtitle: "Reviews",
    color: "#FFE2E2",
  },
  {
    number: 13,
    subtitle: "Cancelled",
    color: "#F2E2FF",
  },
];

export const zoneData = [
  {
    name: "Zone A",
  },
  {
    name: "Zone B",
  },
  {
    name: "Zone C",
  },
];

export const daysData = [
  {
    name: "Monday",
  },
  {
    name: "Tuesday",
  },
  {
    name: "Wednesday",
  },
  {
    name: "Thursday",
  },
  {
    name: "Friday",
  },
  {
    name: "Saturday",
  },
  {
    name: "Sunday",
  },
];

export const USER_TYPES = [{ name: "User" }, { name: "Driver" }];

export const YEAR_LIST = ["2021", "2022", "2023", "2024", "2025", "2026"];

export const MONTH_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];



export const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];


export const monthNamesData = [
  {
    name: 'January',
    id: 'January',
  },
  {
    name: 'February',
    id: 'February',
  },
  {
    name: 'March',
    id: 'March',
  },
  {
    name: 'April',
    id: 'April',
  },
  {
    name: 'May',
    id: 'May',
  },
  {
    name: 'June',
    id: 'June',
  },
  {
    name: 'July',
    id: 'July',
  },
  {
    name: 'August',
    id: 'August',
  },
  {
    name: 'September',
    id: 'September',
  },
  {
    name: 'October',
    id: 'October',
  },
  {
    name: 'November',
    id: 'November',
  },
  {
    name: 'December',
    id: 'December',
  },
];


 export const yearsData = [
  {
    name: '2023',
    id: '2023',
  },
  {
    name: '2024',
    id: '2024',
  },
  {
    name: '2025',
    id: '2025',
  },
];