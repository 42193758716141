
import { Box, Button, FormLabel, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import maleIcon from '../../../Assets/Male.svg'
import femaleIcon from '../../../Assets/female.svg'

function GenderSelect({currentGender,changeGender}) {
const [isSelected , setIsSelected] = useState(currentGender)
const defaultStyle = {bgcolor:'white' ,color:'black' , border:'1px solid #c6c6c6' , width:'115px',height:'46px'}
const selectedStyle = {bgcolor:'#bdc3c7' ,color:'black' , border:'1px solid #c6c6c6' , width:'115px',height:'46px', '&:hover': {backgroundColor: 'grey',color: 'black',},}

useEffect(()=>{
setIsSelected(currentGender)
},[currentGender])

const handleChange = (gender)=>{
 setIsSelected(gender)
 changeGender(gender)
}

  return (
    <Box>

   
<Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{'Gender'}</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
<Stack  direction={'row'} spacing={2} >
<Button onClick={() => handleChange('Male')}  sx={ isSelected === 'Male' ? selectedStyle : defaultStyle} startIcon={<img src={maleIcon} alt='icon' />}>Male</Button>
<Button onClick={() => handleChange('Female')}  sx={isSelected === 'Female' ? selectedStyle : defaultStyle}     startIcon={<img src={femaleIcon} alt='icon' />}>Female</Button>
</Stack>

</Box>

  )
}

export default GenderSelect
