import { Box,  createTheme, useMediaQuery } from "@mui/material";
import { cloneElement, forwardRef, useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";

import { useSelector } from "react-redux";






const Fade = forwardRef(function Fade(props, ref) {

  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {

  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};



function FormModal({ status, component, handleclose,Type,isZone }) {

  const {currentModaldata} = useSelector((state) => state.modal)



  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd:855,
        md: 900,
        fmd:950,
        slg:1098,
        lg: 1200,
        sxl: 1430,
        xl: 1536,
      },
    },
  });
  const islgMatch = useMediaQuery(theme?.breakpoints?.down("lg"));
  const isSlgMatch = useMediaQuery(theme?.breakpoints?.down("slg"));
  const isFmdMatch = useMediaQuery(theme?.breakpoints?.down("fmd"));
  const isSmdMatch = useMediaQuery(theme?.breakpoints?.down("smd"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:Type ?  '500px' : isSlgMatch === true && isFmdMatch === false ? '950px' : isFmdMatch === true && isSmdMatch === false ? '850px' :isSmdMatch ? '700px'  : "500px" ,
    height:Type ? '230px' : islgMatch === true && isSlgMatch === false? '650px' :   isSlgMatch === true && isSmdMatch === false ? "600px" : isSmdMatch ? "750px" :isZone ? "600px":"700px" ,
  //   width: isSlgMatch === true && isFmdMatch === false ? '950px' : isFmdMatch === true && isSmdMatch === false ? '850px' :isSmdMatch ? '700px'  : "500px",
  // height:  islgMatch === true && isSlgMatch === false? '650px' :   isSlgMatch === true && isSmdMatch === false ? "600px" : isSmdMatch ? "750px" : "230px",
  
    bgcolor: "background.paper",
  
    boxShadow: 24,
    p: 4,
  };


  useEffect(() => {
    if (status) {
      setOpen(true);
    }
  }, [status]);

  useEffect(()=>{

    handleClose()

  },[currentModaldata])

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    handleclose();
  };
  return (
    <Box>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box borderRadius="16px" sx={style}>
            {component}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default FormModal;
