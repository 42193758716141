import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import ClearAllIcon from '@mui/icons-material/ClearAll';

function RideFilter({CloseModal,FilterColumn}) {
     
    const [isClear,setIsClear] = useState(false)
   
    const VehicleType = [
        {name:'Car'},{name:'Bus'},{name:'Scooter'},{name:'Taxi'}
    ]
    const VehicleCategory = [
        {name:'Economy'},{name:'Premium'},{name:'N/A'},
    ]
    const Features = [
        {name:"Booster"}, {name:"Child"}, {name:"Pets"}, {name:"Bicycle"}, {name:"Wheal chair"},{name:"N/A"}
    ]

    const HandleClearFilter =()=>{
      setIsClear((i)=> !i)
    }


    const handleMultipleSelect = (label,data)=>{

      if(label === 'Vehicle Type'){
          
        let filteredData = data?.map((item)=>{
          return item?.name
        })
    
     FilterColumn(filteredData,"")
    
      }else if(label === 'Vehicle Category'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }

      else if(label === 'Features'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }
    
    }


  return (
  <Box  width={'100%'}>
  <Stack alignItems={'center'} spacing={2} direction={'row'}>
      
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={VehicleType} fieldName={'Vehicle Type'}/> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Type"} Data={VehicleType} />
    </Box>
    
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={VehicleCategory} fieldName={'Vehicle Category'} /> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} Data={VehicleCategory} />
    </Box>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={Features}  fieldName={'Features'}  /> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Features"} Data={Features} />
    </Box>
    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>

    </Stack>
    
  </Box>
  )
}

export default RideFilter
