import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import { BONUS_TYPE, zoneData } from '../../../FakeData/FakeData'
import DatePickerComponent from '../../../Components/Forms/DatePickerComponent'
import InputField from '../../../Components/Forms/InputField'
import TimePickers from '../../../Components/Forms/TimePickers'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate'
import ApiCall from '../../../../Helper/ApiCalls'
import SelectField from '../../../Components/Forms/SelectField'
import { useSelector } from 'react-redux'
import { truncate } from 'lodash-es'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { toast } from 'react-toastify'

function AddSpecialChargeForm() {
  const [submitHit,setSubmitHit] = useState(false)
const [selectedZone,setSelectedZone] = useState('')
const [remarks,setRemarks] = useState('')
const [onSuccess , setOnSuccess] = useState(false)
const [onFailed , setOnFailed] = useState(false)
const [onApiSuccess , setOnApiSuccess] = useState('')
const [onApiFailed , setOnApiFailed] = useState('')
const [startTime , setStartTime] = useState('')
const [endTime , setEndTime] = useState('')
const [surgeAmount , setSurgeAmount] = useState('')
const [isSingleZone,setIsSingleZone] = useState(false)
const [zoneData , setZoneData] = useState([])
const [allZoneName , setAllZoneName] = useState([])
const [allZoneIds , setAllZoneIds] = useState([])
const [selectedDate , setSelectedDate] = useState('')
const [selectedDay , setSelectedDay] = useState('')
const [surgeType , setSurgeType] = useState('')
const {currentCountrydata:CountryID} = useSelector((state) => state.country)

const [write,setWrite] = useState()
  

useEffect(()=>{   
  let isWrite = checkPrivilage('Surcharges','CREATE')
  setWrite(isWrite)

   },[])

//RENDERS


useEffect(()=>{

  
  ApiCall("GET", `/trip-service/v1/admin/zone/get-all/zones/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.status === 'success'){

      let rowZones = data?.result

      const filterdZOne = rowZones?.map((eachZone , index)=>{
        return {id:eachZone?._id , name:eachZone?.Name}

      })
      const filterdNames = rowZones?.map((eachZone , index)=>{
        return {name:eachZone?.Name}

      })
      const filterdId = rowZones?.map((eachZone , index)=>{
        return eachZone?._id

      })

      setAllZoneIds(filterdId)

      setZoneData(filterdZOne)
      setAllZoneName(filterdNames)
       
      console.log(data?.result,"<---------zonessssss")
    } })

},[])











//FUNCTIONS

const handleMultipleSelect = (label,data)=>{

  if(label === 'Zone'){
      
    let filteredData = data?.map((item)=>{
      return item?.id
    })

    setSelectedZone(filteredData)

  }

}









const handleTimeChange = (label,data)=>{

  if(label === 'Start Time'){
   setStartTime(data)
  }else if(label === 'End Time'){
   setEndTime(data)
  }


}


const handleDateSelection =(date)=>{
  
  setSelectedDate(date.format('YYYY-MM-DD'))
  const dayOfWeek = date.$d.toLocaleDateString(date.$L, { weekday: 'long' });
  setSelectedDay(dayOfWeek)
  
 }



 const handleFormSubmit =()=>{
  setSubmitHit(true)

  let sendData = {

    zoneIds: isSingleZone ? allZoneIds: selectedZone,
    days:[selectedDay],
    specialDate:selectedDate,
    startTime:startTime,
    endTime:endTime,
    surgeType:surgeType,
    amount:surgeAmount,
    isSingleZone:!isSingleZone,
    countryId:CountryID,
    isSpecialDay:true,
    type:"Special",

  }

  console.log(sendData,"sendeeeeeeeeeee dataaaaaaaaaaa")

if(selectedDay && selectedDate && startTime && endTime && surgeType && surgeAmount){

  ApiCall("POST", "/trip-service/v1/admin/sur-charges/add/surge",sendData).then((data)=>{
    if(data?.status === 'success'){
      setOnSuccess(true)
    }
})
.catch((error) => {
  setOnFailed((i) => !i)
    
  if(error.message){
    setOnApiFailed(error.message)
  }else{
    setOnApiFailed("Something went wrong.!")
  }
  console.error(error, "PUT API ERROR");


});

}else{
  toast.error("Fill all mandatory fields")
}


    
  }


  const handleSelect = (label,data,index)=>{
          
    if(label === 'Type'){
      setSurgeType(data?.name)
    } 
  
  
  }
  



  return (
    <Box>
<Stack spacing={3} >

{/* ZONE AND DAY */}

<Stack alignItems={"center"} direction={'row'} spacing={3} >
<Box width={'30%'}>
          {
            !isSingleZone ? (
              <MultipleSelect isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            ):(
              <MultipleSelectUpdate currentSelect={allZoneName} isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            )
          }
       
         </Box>
         <Box width={'30%'}>
        <DatePickerComponent disablePast={true} Special={true} handleSelection={handleDateSelection} sideLabel={'Special Day'}/>
         </Box>
        </Stack>

{/* REMARKS */}

{/* <Stack direction={'row'} spacing={3} >
         <Box width={'100%'}>
         <InputField isError={submitHit && remarks.length < 5} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}  sideLabel={"Remarks"} placeHolder={"Remarks"} />
         </Box>
         </Stack> */}

{/* CHECKBOX */}

<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsSingleZone(e.target.checked)}  control={<Checkbox  color="success" />} label="All zones ?" />

</Stack>
</Box> 



{/* TIMEPICKER */}


<Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
        <TimePickers TimeChange={handleTimeChange} sideLabel={"Start Time"} />
         </Box>
         <Box width={'30%'}>
         <TimePickers TimeChange={handleTimeChange} sideLabel={"End Time"}/>
         </Box>
        </Stack>

{/* SURGE AMOUNT */}

<Stack direction={'row'} spacing={3} >
<Box width={"30%"} >
    <SelectField   isError={''} onSelecting={handleSelect} Data={BONUS_TYPE} sideLabel={'Type'} placeHolder={'choose'} />
    </Box>
         <Box width={'30%'}>
         <InputField Type={'number'} isError={submitHit && surgeAmount.length < 1} ErrNote={""} InChange={(e) => setSurgeAmount(e.target.value)}  sideLabel={"Surge Amount"} placeHolder={`Surge Amount in ${surgeType === 'Amount' ? 'S$':surgeType === 'Percentage' ? '%' : 'S$'}`} />
         </Box>
         </Stack>  

 {/* <AlertPopUp/> */}

 <SnackBars submitStatus={submitHit} successNote={'Successfully Created'} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />

{/* SAVE FORM */}        


</Stack>
    </Box>
  )
}

export default AddSpecialChargeForm
