import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ApproveButton({Label,buttonColor,DialogText,TitleText,handleConfirm,isVerified,disableStatus}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      
    };
    const handleYes =()=>{
      setOpen(false)
      handleConfirm()
    }
  return (
    <div>
      {
        disableStatus ?
      <Button disabled={isVerified} color={buttonColor} sx={{textTransform:"none"}} variant="outlined" onClick={handleClickOpen}>
        {Label}
      </Button> : 
       <Button disabled={true} color={buttonColor} sx={{textTransform:"none"}} variant="outlined" onClick={handleClickOpen}>
       {Label}
     </Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {TitleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
      {DialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ApproveButton
