import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import NightFairForm from './NightFairForm'

function NightFair() {
  return (
    <Box width={'49.5%'} height={'670px'} >
    <Stack spacing={2} >
    <Typography fontWeight={600} >Night Fair management</Typography>
    <Paper  sx={{width:'100%',height:'670px',padding:'20px'}} elevation={3} >
<NightFairForm/>
</Paper>
    </Stack>

   </Box>
  )
}

export default NightFair
