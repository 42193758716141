import { Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import FormNav from '../../../Components/Forms/FormNav'
import DatePickerComponent from '../../../Components/Forms/DatePickerComponent'
import { convertToUtcLastMinute } from '../../../Components/MultiFunctions/MultiFunctions'

function PrioritySticker({handleFormSubmit}) {
const [selectedDate,setSelectedDate] = useState()
    const handleDateSelection =(date)=>{
      console.log(date , "<--rowdate")
        let utcTime = convertToUtcLastMinute(date)
        console.log(utcTime,"<--- the date ")
        setSelectedDate(utcTime)
       }
  return (
    <Box>
    <Stack spacing={4}>

  <FormNav title={'Enable priority Sticker'}/>
<Stack alignItems={'center'} direction={'row'} spacing={4}>
<Box width={'50%'}>
  <DatePickerComponent  disablePast={true} isError={false}  handleSelection={handleDateSelection} sideLabel={'Select End Date'}/>

{/* <InputField Type={'number'} isError={''} ErrNote={""} InChange={(e) => setAmount(e.target.value)}   sideLabel={isDuduct ? 'Amount to be deducted' :"Amount to be add"} placeHolder={"Amount"} /> */}
</Box>
<Box pt={2} width={'30%'}>
<Button onClick={() => handleFormSubmit(selectedDate,true)}  sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >{'Enable'}</Button>
</Box>
</Stack>


    </Stack>
    

   </Box>
  )
}

export default PrioritySticker
