import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '15%',
  left: '85%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  borderRadius: 2,
  height: 108,
  boxShadow: 24,
  p: 2,
};

export default function NotifyAlertWhileDelete({openStatus,onClosing,Info,OnDeleteYes,AlertNote,isCancel}) {


  const [open, setOpen] = React.useState(false);
  const [infoData,setInfoData] = useState({})
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    onClosing()
  } 

  useEffect(()=>{
  setInfoData(Info)
  },[Info])

  useEffect(()=>{

  setOpen(openStatus)

  },[openStatus])




  return (
    <div>
   
      <Modal
   
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Stack spacing={1}>
            <Typography sx={{}} id="modal-modal-title" variant="p" >{AlertNote || `Are you sure you want to delete ?` }</Typography>


          <Stack spacing={1} direction={'row'}>
          <Button onClick={handleClose} sx={{textTransform:"none"}} color='inherit' size='small' variant="contained" >Cancel</Button>
         <Button onClick={() => {
            OnDeleteYes(infoData)  
            handleClose()
            }}  sx={{textTransform:"none"}} color='error' size='small' variant="contained">Yes</Button>
          </Stack>
       

            </Stack>
      
       
        </Box>
      </Modal>
    </div>
  );
}