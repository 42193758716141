import { regConstants } from "../Constants/Constants"; 

const initialRegState = {
    currentRegdata: {},
};

export const RegReducer = (state = initialRegState, action) => {
    switch (action.type) {
        case regConstants.INSERT_REG_DATA:
            return { ...state, currentRegdata: action.payload };
        default:
            return state;
    }
};