import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../Hooks/UseTitle';
import { Box } from '@mui/material';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../../Components/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import { monthNames } from '../../../FakeData/FakeData';
import LtaFilterTwo from './LtaFilterTwo';
import dayjs from 'dayjs';
import { convertToCurrentCountryTime } from '../../../Components/MultiFunctions/MultiFunctions';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
    {
      id: "year",
      label: "Year",
      minWidth: 150,
      align: "left",
      sort: true,
    },
  
    {
      id: "month",
      label: "Month",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "driverId",
        label: "Driver ID",
        minWidth: 150,
        align: "left",
        sort: true,
      },
    {
        id: "vehicleId",
        label: "Vehicle ID",
        minWidth: 150,
        align: "left",
        sort: true,
      },
    
    {
      id: "dateTime",
      label: "Date joined",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "dateLeft",
        label: "Date left",
        minWidth: 170,
        align: "left",
        sort: true,
      },
    {
        id: "engagedHours",
        label: "Hours engaged",
        minWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "operatorName",
        label: "Operator Name",
        minWidth: 170,
        align: "left",
        sort: false,
      },
      {
        id: "pmlStatus",
        label: "PML status",
        minWidth: 170,
        align: "left",
        sort: false,
      },
       
   
  ]; 


function LtaReportsTwo() {
    useTitle("LTA Reports 2- Geolah");


    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0)
    const [filterYear, setFilterYear] = useState('2023')
    const [filterMonth, setFilterMonth] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()


    useEffect(()=>{
      
        let isWrite = checkPrivilage('Reports','READ')
        setWrite(isWrite)
       let isEdit = checkPrivilage('Reports','UPDATE')
         setEdit(isEdit)
       
         let isDelete = checkPrivilage('Reports','DELETE')
         setDeletePer(isDelete)
         },[])
    
      const navigate = useNavigate()


      useEffect(()=>{
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear(); 
      const currentMonth = monthNames[currentDate.getMonth()]; 
      // setFilterYear(currentYear.toString());  
      setFilterMonth(currentMonth);
      },[])
  
  
      
  useEffect(()=>{
    
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
    if(filterYear && filterMonth){
  
      ApiCall("GET", `/trip-service/v1/admin/report/monthly/?currentYear=${filterYear}&&currentMonth=${filterMonth}&&countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
    
        const reportData = data?.result
        setTotalRows(reportData?.length)
        console.log(reportData , "<------R-E-P-O-R-T")
        if(searchInput?.length <= 0){
       const filteredData =  reportData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
        let dateString = eachItem?.createdAt
        let endDateString = eachItem?.updatedAt
      
        const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
        let testT = convertToCurrentCountryTime(dateString)
        const formattedDateTwo = dayjs(endDateString).format('YYYY-MM-DD')
     
        const currentStartTime  =  dateString?.slice(11, 16);
        const currentStartTimeTwo  =  endDateString?.slice(11, 16);
        let activeState = eachItem?.IsActive

       
        let items = {
              id:index+1,
              year:filterYear,
              month:filterMonth,
              driverId:eachItem?.UserID,
              vehicleId:eachItem?.driverVehicleDetails?.[0]?.RegistrationNumber,
              engagedHours:eachItem?.engagedHours,
              operatorName:eachItem?.operatorName,
              pmlStatus:eachItem["PML Status"],
              dateTime:testT,
              dateLeft: !activeState ? formattedDateTwo+" "+currentStartTimeTwo : "N/A"
             }
    
             return items;
       })
       setRows(filteredData)
      }



       const filteredRowData =  reportData?.map((eachItem,index)=>{
        let dateString = eachItem?.createdAt
        let endDateString = eachItem?.updatedAt
      
        const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
        const formattedDateTwo = dayjs(endDateString).format('YYYY-MM-DD')
     
        const currentStartTime  =  dateString?.slice(11, 16);
        const currentStartTimeTwo  =  endDateString?.slice(11, 16);
        let activeState = eachItem?.IsActive

       
        let items = {
              id:index+1+currentOffset,
              year:filterYear,
              month:filterMonth,
              driverId:eachItem?.UserID,
              vehicleId:eachItem?.driverVehicleDetails?.[0]?.RegistrationNumber,
              engagedHours:eachItem?.engagedHours,
              operatorName:eachItem?.operatorName,
              pmlStatus:eachItem["PML Status"],
              dateTime:formattedDate+" "+currentStartTime,
              dateLeft: !activeState ? formattedDateTwo+" "+currentStartTimeTwo : "N/A"
             }
    
             return items;
       })
    
       
       setTableData(filteredRowData);
      
     } })}

     










    },[success,filterMonth,filterYear,currentPage])
  
  
  
      useEffect(()=>{
          setRows(
            tableData.filter((item) =>
            item?.id?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.month?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.driverId?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.vehicleId?.toString().toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.engagedHours?.toLowerCase().includes(searchInput.toLowerCase()) || 
            item?.operatorName?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.pmlStatus?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.dateTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item?.dateLeft?.toLowerCase().includes(searchInput.toLowerCase())
              ))
           },[searchInput])
  
  
      const handleStatusChange =(id, newData)=>{
          //  console.log(`Switch with id ${id} changed. New data:`, newData);
           const paramsData = {valueServiceId:id,status:newData?.status}
          //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
        }
    
    
    
    
    // delete status  
    const handleServiceDelete =(id, newData)=>{
      // console.log(`Delete with id ${id}. New data:`, newData);
      setDeleteStatus(true)
      const paramsData ={id,name:newData?.rideService}
      setDeleteData(paramsData)
     
    }
    
    const onDeleteYes =(yesData)=>{
  //    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
  //     setSuccess(data) 
  //     // setDeleteStatus(false)
  //   })
    } 
    
    
    const onCloseNotification = ()=>{
      setDeleteStatus(false)
    }
    
    const handleServiceEdit =(id,newData)=>{
      if(id){
        navigate(`/wallet-details/${id}`)
      }
     
    
    }
    
    // handle search change
    
    const handleChangeInSearch = (value)=>{
       setSearchInput(value)
       console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
    }
  
  
      const handleColumnDateFilter = (data,type) => {
         if(type === 'year'){
           setFilterYear(data)
         }

         if(type === 'month'){
           setFilterMonth(data)
         }
        };
  
  
      const handleFilterBar = (status)=>{
          setIsFilterOn(status)
        }
  
  
      const columnSortingFn = (id) => {
          if (order === "ASC") {
            const sorted = [...rows].sort((a, b) =>
              id !== "id"
                ? a[id].toLowerCase() > b[id].toLowerCase()
                  ? 1
                  : -1
                : a[id] > b[id]
                ? 1
                : -1
            );
            setRows(sorted);
            setOrder("DSC");
          }
      
          if (order === "DSC") {
            const sorted = [...rows].sort((a, b) =>
              id !== "id" && id !== "ride_fare"
                ? a[id].toLowerCase() < b[id].toLowerCase()
                  ? 1
                  : -1
                : id === "date"
                ? new Date(a.date).getTime() - new Date(b.date).getTime()
                : a[id] < b[id]
                ? 1
                : -1
            );
            setRows(sorted);
            setOrder("ASC");
          }
        };
        
  
     const handleExport =()=>{
      if(rows?.length > 0){

  
        const blob = new Blob([Papa?.unparse(rows)], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'LTP_EXPORT';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
  
      }
     
    
     }   
  
  
     const handlePageChange=(no,rowsCount)=>{
      setCurrentPage(no)
      setRowsPerPage(rowsCount)   
       }
  



  return (
<Box>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
      needButton={true}
       handlingPage={handlePageChange}
       totalCount={totalRows}
          DeletePermission={deletePer}
          ButtonPermission={write}
          EditPermission={edit} 
          modalClicked={handleExport}
          isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/export'}
        ButtonLabel={'Export'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnDateFilter}
        searchInput={handleChangeInSearch}
        filterComponent={<LtaFilterTwo currentYear={filterYear} currentMonth={filterMonth} removerIcon={true} CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
</Box>
  )
}

export default LtaReportsTwo
