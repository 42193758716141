import { Alert, Box, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'

function NotifiProgress({closeNotification,Status,successNote,submitStatus}) {
  const [open, setOpen] = React.useState(false);




useEffect(()=>{
  
 submitStatus && setOpen(true);
},[Status])

 

  const handleClose = (event, reason) => {
   
    if (reason === 'clickaway') {
        setOpen(false);
      return;
    }
    
    setOpen(false);
    closeNotification()

  };


  return (
  <Box>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {successNote}
        </Alert>
      </Snackbar>

  </Box>
  )
}

export default NotifiProgress