import React, { useState } from "react";
import TableNav from "../../Components/TableSearchExport/TableNav";
import { Box } from "@mui/material";
import DataTable from "../../Components/Table/DataTable";
import ReviewData from "../../FakeData/MOCK_DATA6.json";

const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },

  {
    id: "customer",
    label: "Customer",
    minWidth: 100,
    align: "left",
    sort: true,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "driver",
    label: "Driver",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "rating",
    label: "Rating",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "comments",
    label: "Comments",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
];

function Review() {
  const [rows, setRows] = useState(ReviewData);
  const [order, setOrder] = useState("ASC");
  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };
  return (
    <Box>
      {/* <TableNav title={"Review"} />
      <Box sx={{ width: "100%", height: "100%" }} pt={3}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box> */}
    </Box>
  );
}

export default Review;
