import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({chartLable,chartValue,isDash}) => {
  const customColors = [
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
    '#FF5733', '#33FF57', '#5733FF', '#FF33EA', '#FF5733'
  ];
  const [chartData, setChartData] = useState({
    series: [44, 55, 41, 17, 15],
    options: {
        
      chart: {
        type: 'donut',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5']
    },
  });


  useEffect(()=>{
     
    if(chartValue && chartLable){


        setChartData({
            series: chartValue,
            options: {
             
              chart: {
                type: 'donut',
                
              },
              colors:customColors,
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: isDash ? 200:100,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
              labels: chartLable
            
            },
          })
    }

  },[chartValue,chartData])

  return (
    <div id="chart">
      <ReactApexChart width={isDash && 300} height={isDash && 180} options={chartData.options} series={chartData.series} type="donut" />
    </div>
  );
};

export default ApexChart;