import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import ClearAllIcon from '@mui/icons-material/ClearAll';

// import closeIcon from '../../../Assets/Table/Group 4336.svg'
import closeIcon from '../../../../Assets//Table/Group 4336.svg'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import MyDateRangePicker from '../../../Components/Forms/MyDateRangePicker'

function LtaFilter({CloseModal,FilterColumn,removerIcon,currentDateRange}) {
  const [isClear,setIsClear] = useState(false)
    const handleMultipleSelect = (label,data)=>{

      if(label === 'Vehicle Type'){
          
        let filteredData = data?.map((item)=>{
          return item?.name
        })
    
     FilterColumn(filteredData,"")
    
      }else if(label === 'Vehicle Category'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }

      else if(label === 'Features'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }
    
    }

    const HandleClearFilter =()=>{
      setIsClear((i)=> !i)
    }


  return (
  <Box >
  <Stack alignItems={'center'} spacing={2} direction={'row'}>
      
    <Box pb={0.5}  width={'350px'}>
 <MyDateRangePicker clearStatus={isClear} currentRange={currentDateRange} isLta={true} handleUtc={FilterColumn}/>
    </Box>

  <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>  
{
  !removerIcon &&
    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="large">
    <img src={closeIcon} alt="filter"/>
   </IconButton> 
}


    </Stack>
    
  </Box>
  )
}

export default LtaFilter
