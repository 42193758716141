import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { FormLabel, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';





function DatePickerComponent({clearStatus,sideLabel,handleSelection,initialValue,isError,Special,filter,disableFuture,isAdult,disablePast}) {
const [currentDate , setCurrentDate] = useState()
function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}





// initial value is in 2023-08-15 format

useEffect(()=>{
initialValue && setCurrentDate(dayjs(initialValue))
},[initialValue])

 const today = dayjs();
 const yesterday = dayjs(currentDate);
  console.log(currentDate , "<---crrrrrurrentDate")

  const handleDateChange = (newDate) => {
  //  console.log(newDate.format('YYYY-MM-DD'),"<------N E W")
   handleSelection( Special ? newDate : newDate.format('YYYY-MM-DD'),sideLabel)
  };

  const redBorderStyle = {
    borderColor: 'red',
    borderWidth: '2px',
    borderStyle: 'solid'
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
 {/* Adjust the below margin bottom to align the entire component currently its set for certain component if you want to change use props       */}
    <DemoContainer
    
    sx={{marginBottom:"10px"}}
      components={[
        'DatePicker',
        'DateTimePicker',
        'TimePicker',
        'DateRangePicker',
      ]}
    >

      <DemoItem >
 {/* current margin botton set for certain component what to change use props */}
      <Stack direction={'row'} sx={{ marginBottom: '-9px' }} >   
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
       { !filter && <Typography color={'red'}>*</Typography>}
        </Stack>
        <DatePicker
        maxDate={isAdult && dayjs(subtractYears(new Date(), 18))}
        disableFuture={disableFuture}
        disablePast={disablePast}
        format='DD/MM/YYYY'
        sx={{backgroundColor:isError && '#FFCCCB'}}
        onChange={handleDateChange}
          value={currentDate}
          views={['year', 'month', 'day']}
         
        
        />
        
      </DemoItem>
      </DemoContainer>
      </LocalizationProvider>
  )
}

export default DatePickerComponent
