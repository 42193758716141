import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import ToggleNav from '../../Components/Forms/ToggleNav'
import AdminForm from './AdminForm'
import employeeData from '../../FakeData/MOCK_DATA3.json'
import DataTable from '../../Components/Table/DataTable'

const columns = [
    {
      id: "id",
      label: "Id",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "left",
      sort: true,
    },
    {
      id: "job",
      label: "Job",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 170,
      align: "left",
      sort: true,
    },
 {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];









const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Administration
      </Typography>,
 <Typography key="3" color="text.primary">
      Employee List
      </Typography>,
 

  ]
function Admin() {
    const [tab , setTab] = useState('left')
    const [rows, setRows] = useState(employeeData);
    const [order, setOrder] = useState("ASC");

    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };



const tabRender = (alignment)=>{
  setTab(alignment)
}
 



  return (
 <Box>
<TableHead title={'Administration'} BreadList={breadcrumbs} />
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='100%' height='100vh' >
<ToggleNav lName={' Employee List'} rName={'New List'} toggleChange={tabRender} />
<Box  sx={{ width: "100%", height: "100%"}} p={4}>

{
        tab === 'left' ?  
        <DataTable
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
      /> :  
      <AdminForm/>


    }
</Box>


</Box>
 </Box>
  )
}

export default Admin
