import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashBox from './DashBox'
import ApiCall from '../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import { CountryCodes } from 'validator/lib/isISO31661Alpha2'

function TabContainer() {
const [revData,setRevData] = useState({})
const [driversData,setDriversData] = useState({})
const [tripData,setTripData] = useState({})
const {currentCountrydata:CountryID} = useSelector((state) => state.country)
const [tabDetails,setTabDetails] = useState([])
// const tabDeatils = [
//     {
//         name:'Revenue',
//         numeric:'S$ 8854.25',
//         side_line : true
//     },
//     {
//         name:'Drivers',
//         numeric:'346',
//         side_line : true
//     },
//     {
//         name:'Total Trips',
//         numeric:'1585',
//         side_line : false
//     },
//     // {
//     //     name:'Reviews',
//     //     numeric:'26',
//     //     side_line : false
//     // },
// ]


useEffect(()=>{

    ApiCall("GET", `/trip-service/v1/admin/dashboard/total/revenue/?countryId=${CountryID}`, null).then((data)=>{
      if(data.status === 'success'){
       const rowData = data?.result;
       const revenueNum = rowData?.totalAmount 

   console.log(revenueNum,"<---getpending")

       const revenue = {
        name:'Revenue',
        numeric:`S$ ${revenueNum === null ?  0.00: revenueNum.toFixed(2)}`,
        data:[
            {
                title:"card",
                value:`S$ ${rowData?.totalCardAmount?.toFixed(2) || 0.00}`
            },
            {
             
                title:"cash",
                value:`S$ ${rowData?.totalCashamount?.toFixed(2) || 0.00}`

            },
            {

                
                title:"pending",
                value:`S$ ${(rowData?.getPengingAmount ||  0)?.toFixed(2)}` 

            }
        ],
        side_line : true                  
       }

         setRevData(revenue)
      }

    }).catch((error) => {
      console.log(error,"<---the errrorr")
   });




   ApiCall("GET", `/trip-service/v1/admin/dashboard/total/drivers/?countryId=${CountryID}`, null).then((data)=>{
    if(data.status === 'success'){
     const rowData = data?.result;

     const driverDetails = {
      name:'Drivers',
      numeric:rowData?.totalDrivers,
      data:[
        {
            title:"online",
            value:rowData?.totalOnlineDrivers
        },
        {
         
            title:"approved",
            value:rowData?.totalApprovedDrivers

        },
        {

            
            title:"offline",
            value:rowData?.totalOfflineDrivers

        }
    ],
   
      side_line : true                  
     }
       setDriversData(driverDetails)
    }

  }).catch((error) => {
    console.log(error,"<---the errrorr")
 });




 ApiCall("GET", `/trip-service/v1/admin/dashboard/total/Trips/?countryId=${CountryID}`, null).then((data)=>{
    if(data.status === 'success'){
     const rowData = data?.result;
   console.log(rowData,"<------d-d-d-d-d-d")
     const tripDetails = {
      name:'Total Trips',
      numeric:rowData?.totalTrip,
      data:[
        {
            title:"completed",
            value:rowData?.completeTrip
        },
        {
         
            title:"cancelled",
            value:rowData?.cancelledTrip

        },
    
    ],
   
      side_line : false                 
     }
       setTripData(tripDetails)
    }

  }).catch((error) => {
    console.log(error,"<---the errrorr")
 });

  },[])




  useEffect(()=>{
    if(Object.keys(tripData)?.length !== 0 && Object.keys(driversData)?.length !== 0 && Object.keys(revData)?.length !== 0){

      setTabDetails([revData,driversData,tripData])

    }

  },[tripData,driversData,revData])







  return (
 <Box
 p={2}
 borderRadius={"8px"}
 display={'flex'}
 flexWrap="wrap" // Enable wrapping of child elements
 justifyContent={'space-between'}
 mt={1}
 width={'100%'}
 height={'145px'}
 bgcolor={'white'}
  >

    {
        tabDetails?.map((item,index)=>(
            <DashBox Subs={item?.data} Name={item?.name} Amount={item?.numeric} LineDetails={item?.side_line} />
        ))
    }
    


 </Box>
  )
}

export default TabContainer
