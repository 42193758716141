import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ImageSelector from '../../../Components/Forms/ImageSelector'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import { AD_TYPE, App_Type, NO_OF_SEATS } from '../../../FakeData/FakeData'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import ApiCall from '../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { ConstructionOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify'

function CreateAdsForm() {
  
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)

    const [imageFile , setImageFile] = useState({})
    const [headingName , setHeadingName] = useState('')
    const [description,setDescription] = useState('')
    const [selectedAdType , setSelectedAdType] = useState('')
    const [urlName , setUrlName] = useState('')
    const [selectedAppType,setSelectedAppType] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [filteredAd , setFilteredAd] = useState([])

    const [write,setWrite] = useState()
  
console.log(imageFile , " <IMage filessss")
    useEffect(()=>{   
      let isWrite = checkPrivilage('App Ads','CREATE')
      setWrite(isWrite)
  
       },[])

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));






// FUNCTIONS

const handleImageFiles = (files)=>{
    setImageFile(files)
  
    }

    useEffect(()=>{
    if(selectedAppType === 2){
       let temp = AD_TYPE?.filter((f) => f.id !== 'OFFER' && f.id !== 'HEADER_OFFERS')
       setFilteredAd(temp)
    }else if(selectedAppType === 1){
      let temp = AD_TYPE?.filter((f) => f.id !== 'ALERT' && f.id !== 'WHATS_NEW')
      setFilteredAd(temp)
    }
    },[selectedAppType])


const handleSelect = (label,data)=>{
          
        if(label === 'App Type'){
               setSelectedAppType(data?.id)
        }else if(label === 'Ad Type'){
               setSelectedAdType(data?.id)
        }
     
    
      }  





    // handling form submittion 

const handleFormSubmit =()=>{
    setSubmitHit(true)    
    const formData = new FormData();
    formData.append('appAdvertisementImage', imageFile?.file);
    formData.append('name', headingName);
    formData.append('description', description);
    formData.append('appId', selectedAppType);
    formData.append('appPath', 'app/path/type');
    formData.append('countryId',CountryID);
    formData.append('url', urlName);
    formData.append('type', selectedAdType);  

    if(imageFile?.file && headingName && description && selectedAppType && urlName && selectedAdType){

      ApiCall("POST", "/user-service/v1/admin/promo/create", formData,).then((data)=>{
        if(data.result === 'success'){
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully created Ad")      
        }})
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        } 
    });
    }else{
      toast.error("Fill all mandatory fields")
    }

   
   
   
   
  
   
   
   }



  return (
<Box>
<Stack spacing={3}>
<Stack spacing={2}>

{/* FIRST ROW */}
<Box width={isMatch?'100%':'29.1%'} >
<ImageSelector sideLabel={'Image'} isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} />
</Box>

<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'43%'}>
        <InputField isError={submitHit && headingName.length < 3} ErrNote={""} InChange={(e) => setHeadingName(e.target.value)}   sideLabel={"Heading"} placeHolder={"Heading"} />
</Box>
<Box width={isMatch ? "43%" : "100%"} >
        <InputField isError={false} ErrNote={""} InChange={(e) => setDescription(e.target.value)}  sideLabel={"Description"} placeHolder={"Description"} />
</Box>


</Stack>

{/* SECOND ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'30%'}>
<SelectField isError={false} onSelecting={handleSelect} Data={App_Type} sideLabel={'App Type'} placeHolder={'choose'} />
    </Box>
    <Box width={'30%'}>
<SelectField isError={false} onSelecting={handleSelect} Data={filteredAd} sideLabel={'Ad Type'} placeHolder={'choose'} />
    </Box>
<Box width={'30%'}>
        <InputField isError={submitHit && urlName.length < 3} ErrNote={""} InChange={(e) => setUrlName(e.target.value)}   sideLabel={"URL"} placeHolder={"URL"} />
</Box>    
    </Stack>



</Stack>

<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />

</Stack>

</Box>
  )
}

export default CreateAdsForm
