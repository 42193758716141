import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import carImage from '../../../../Assets/other-images/BMW.jpg'
import childSeatIcon from '../../../../Assets/Group.svg'
import featIcon from '../../../../Assets/pawprint 1.svg'
import seatIcon from '../../../../Assets/seat 1.svg'
import bicycleIcon from '../../../../Assets/bicycle 1.svg'
import wheelChairIcon from '../../../../Assets/icons8-wheelchair-64.png'
import Nodatafound from '../../../Components/NoDataFound/Nodatafound'

function VehicleInformationCard({VehicleData,isVehicle}) {

 

// STATES

const [vehicleDetails , setVehicleDetails] = useState({})


//RENDERS AND API CALLS

useEffect(()=>{
  
  if(VehicleData){
  setVehicleDetails(VehicleData)
  }

},[VehicleData])





  return (
<Box  width={'650px'} height={'341px'} borderRadius={'8px'} bgcolor={'#FFFFFF'} >
  {vehicleDetails?.modelName ? (
  <Stack p={3} spacing={3.5}>
 {/* FIRST ROW    */}
  <Typography fontWeight={'bold'} variant='p'>Vehicle Information</Typography>
{/* SECOND ROW */}
  <Stack spacing={4} direction={'row'} >
  {/* vehicleDetails?.vehicleImage */}
  <img style={{width:"217px",height:'123px',borderRadius:'4px'}} src={vehicleDetails?.vehicleImage || 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Toll_information_car_icon.svg/1280px-Toll_information_car_icon.svg.png'} alt='carimage'/>
  {/* <img style={{width:"217px",height:'123px',borderRadius:'4px'}} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Toll_information_car_icon.svg/1280px-Toll_information_car_icon.svg.png'} alt='carimage'/> */}
          <Stack spacing={1} >
            <Typography fontWeight={600} variant='h5' >{vehicleDetails?.brandName}</Typography>
            <Typography sx={{ opacity: "50%" }} variant='p' >{vehicleDetails?.modelName}</Typography>
            <Stack direction="row" spacing={5}>
              <Stack >
                <Typography sx={{ opacity: "50%" }} variant="caption">
                  Color
                </Typography>
                <Typography fontWeight={450}>{vehicleDetails?.vehicleColor}</Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                  No. of seats
                </Typography>
                <Typography fontWeight={450}>{`${vehicleDetails?.vehicleSeats} seater`}</Typography>
              </Stack>

              <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                  Registration Plate
                </Typography>
                <Typography fontWeight={450}>{vehicleDetails?.regNo}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
{/* THIRD ROW */}

<Stack direction={'row'} spacing={5} >


  {  vehicleDetails?.addOnFeature?.map((eachFeature,index)=> {
   return eachFeature?.Type === 'Booster Seat' ? (
      <Stack direction={'row'} spacing={1} >
      <img height={'20px'} width={'20px'} src={seatIcon} alt='icons'/>
      <Typography variant='body2' sx={{color:'#C6C6C6'}}>Booster Seat</Typography>
    </Stack>
    ):
     eachFeature?.Type === 'Pets' ? (

      <Stack direction={'row'} spacing={1} >
      <img height={'20px'} width={'20px'} src={featIcon} alt='icons'/>
      <Typography variant='body2' sx={{color:'#C6C6C6'}}>Pets</Typography>
    </Stack>

    ):eachFeature?.Type === 'Child Seat'? (

      <Stack direction={'row'} spacing={1} >
                <img height={'20px'} width={'20px'} src={childSeatIcon} alt='icons'/>
                <Typography variant='body2' sx={{color:'#C6C6C6'}} >Child Seat</Typography>
              </Stack>
      
    ):
    eachFeature?.Type === 'Bicycle'? (

      <Stack direction={'row'} spacing={1} >
                <img height={'20px'} width={'20px'} src={bicycleIcon} alt='icons'/>
                <Typography variant='body2' sx={{color:'#C6C6C6'}} >Bicycle</Typography>
              </Stack>
      
    ):
    eachFeature?.Type === 'Foldable Wheel Chair'? (

      <Stack direction={'row'} spacing={1} >
                <img height={'30px'} width={'30px'} src={wheelChairIcon} alt='icons'/>
                <Typography variant='body2' sx={{color:'#C6C6C6'}} >Wheel Chair</Typography>
              </Stack>
      
    ) : null
  })}
            

           </Stack>
{/* LAST ROW */}

<Box display={'flex'} justifyContent={'space-between'} p={2} width={'100%'} sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
<Typography sx={{ opacity: "50%" }} variant="caption">{`Insurance expires on ${vehicleDetails?.insuranceExp}`}</Typography>
</Box>

  </Stack>): !isVehicle && (
     <Stack p={3} spacing={3.5}>
      <Typography fontWeight={'bold'} variant='p'>Vehicle Information</Typography>
      <Stack pl={3} spacing={1}>
     <Nodatafound Title={'No Approved Vehicles'}/>
     </Stack>
     </Stack>
  )}

</Box>
  )
}

export default VehicleInformationCard
