import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme,pColor }) => ({
  height: 7,
  borderRadius: 5,
  
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? pColor : '#308fe8',
  },
}));

// Inspired by the former Facebook spinners.


export default function DashProgressBars({barColor,barValue}) {
   
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <FacebookCircularProgress /> */}
    
      <BorderLinearProgress  pColor={barColor} variant="determinate" value={barValue} />
    </Box>
  );
}
