import {
  Box,
  Button,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CarImg from "../../../../Assets/pexels-trace-constant-707046.jpg";
import SelectField from "../../../Components/Forms/SelectField";
import { TypeReason } from "../../../FakeData/FakeData";
import VehicleDetailsSkeleton from "./VehicleDetailsSkeleton";

function VehicleDetails() {

  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 10000);
  },[])




  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
       smd: 855,
        md: 900,
       fmd: 950,
       slg: 1098,
        lg: 1200,
       sxl: 1430,
        xl: 1536,
      },
    },
  });

  const islgMatch = useMediaQuery(theme?.breakpoints?.down("lg"));

  return (
    loadingData ? (
      <VehicleDetailsSkeleton/>
    ):(

    
    <Box
      sx={{
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
          height: "398px",
          borderRadius: "2%",
        },
      }}
    >
      <Paper sx={{ padding: "16px" }} elevation={3}>
        <Stack direction="row" spacing={4}>
          {/* column item one */}
          <CardMedia
            component="img"
            sx={{ width: "45%", borderRadius: "16px" }}
            height="334px"
            image={CarImg}
            alt="green iguana"
          />

          {/* column item two */}
          <Stack spacing={islgMatch ? 0.5 : 4.5}>
            {/* inside row 1 */}
            <>
              <Stack spacing={1}>
                <Typography fontWeight={600} variant="h6">
                  BMW Adds New Diesel Variants For The X3
                </Typography>
                <Typography fontWeight={450} variant="h7">
                  BMW
                </Typography>
              </Stack>
            </>
            {/* inside row 2 */}
            <>
              <Stack direction="row" spacing={islgMatch ? 3 : 14}>  
              
                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    Color
                  </Typography>
                  <Typography fontWeight={450}>White</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    License plate No.
                  </Typography>
                  <Typography fontWeight={450}>SG M 369</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    Ownership
                  </Typography>
                  <Typography fontWeight={450}>Rental</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    Rental Company Name
                  </Typography>
                  <Typography fontWeight={450}>CityCab</Typography>
                </Stack>
              </Stack>
            </>
            {/* inside row 3 */}

            <>
              <Stack direction="row" spacing={islgMatch ? 4 : 9.5}>
                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    First Registration Date
                  </Typography>
                  <Typography fontWeight={450}>15-05-2023</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    COE Date
                  </Typography>
                  <Typography fontWeight={450}>15-05-2023</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    Number of Seats
                  </Typography>
                  <Typography fontWeight={450}>4</Typography>
                </Stack>

                <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                    Vaccination Detail
                  </Typography>
                  <Typography fontWeight={450}>Fully Vaccinated</Typography>
                </Stack>
              </Stack>
            </>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={islgMatch ? 7.5 : 9.5}>
                <SelectField
                  sideLabel={"Vehicle Service"}
                  placeHolder={"select"}
                  Data={TypeReason}
                />
              </Grid>
              <Grid item xs={islgMatch ? 3.5 : 2}>
                <Button
                  sx={{
                    marginTop: "20px",
                    width: "114px",
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#1C1B20",
                  }}
                  variant="contained"
                >
                  {" "}
                  Save
                </Button>
              </Grid>
            </Grid>

            <></>
          </Stack>
        </Stack>
      </Paper>
    </Box>
    )
  );
}

export default VehicleDetails;
