import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import stearingIcon from '../../../../../Assets/Forms/driver 1.svg'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

export const ButtonuseStyles = makeStyles((theme) => ({
  container: {
    cursor:'pointer',
    width: '350px',
    height: '100px',
    borderRadius: '8px',
    backgroundColor: 'white',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

function AddDriverFleetCard() {
  const classes = ButtonuseStyles();
  const navigate = useNavigate()
const onTabClick = ()=>{
  navigate('/fleet-add-driver')
}

  return (
<Box onClick={onTabClick}  alignContent={'center'} p={2} className={classes.container}>
<Stack alignItems={'center'} direction={'row'} spacing={6}>
<img src={stearingIcon} alt='icon'/>
<Typography sx={{fontWeight:600}} variant='body1'>Add new driver</Typography>
</Stack>
</Box>
  )
}

export default AddDriverFleetCard
