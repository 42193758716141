import { Box,Grid,Stack, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import InputField from "../Forms/InputField";
import SelectField from "../Forms/SelectField";
import { couponType } from "../../FakeData/FakeData";
import { CouponStatus } from "../../FakeData/FakeData";
import DaterRangePicker from "../Forms/DaterRangePicker";
import FormSubmitButtons from "../Forms/FormSubmitButtons";

function CouponForm() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd:855,
        md: 900,
        fmd:950,
        slg:1098,
        lg: 1200,
        sxl: 1430,
        xl: 1836,
      },
    },
  });
  
  const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl"));


  return (
    <Box >
      
    <Box overflow='auto' height={isXlMatch ? "25vh" : '40vh'}  >  
      <Stack spacing={3}>
        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Code Name"} placeHolder={"value"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <SelectField
              sideLabel={"Type"}
              placeHolder={"select"}
              Data={couponType}

            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Value"} placeHolder={"value"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Usage Limit"} placeHolder={"Usage limit"} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={5.75} item>
            <DaterRangePicker sideLabel={"Select Date Range"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <SelectField
              sideLabel={"Select Status"}
              placeHolder={"select"}
              Data={CouponStatus}
            />
          </Grid>
        </Grid>


     

      </Stack>
    </Box>
    <Box paddingTop={5} >
      <FormSubmitButtons/>
    </Box>
    </Box>
  );
}

export default CouponForm;
