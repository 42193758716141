import { Box, Stack } from '@mui/material'
import React from 'react'
import FormNav from '../Forms/FormNav'
import DropOffForm from './DropOffForm'

function DropOffLocation() {
  return (
    <Box>
    <Stack spacing={4}>

    <FormNav title={'Change Drop Off Location'}/>
  <DropOffForm/>

    </Stack>
    

   </Box>
  )
}

export default DropOffLocation
