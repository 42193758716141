
import React from 'react'
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function MultistepProgressBar({ page, onPageNumberClick,Pone,Ptwo,Pthree,Pfour,Pfive }) {
  
  var stepPercentage = 0;
  if (page === "pageone") {
    stepPercentage = 20;
  } else if (page === "pagetwo") {
    stepPercentage = 40;
  } else if (page === "pagethree") {
    stepPercentage = 60;
  } else if (page === "pagefour") {
    stepPercentage = 80;
  } else if (page === "pagefive") {
    stepPercentage = 100;
  }
  else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
    

      
    <Step>
      {({ accomplished, index }) => (
       <Box    alignItems="center" >

       
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          // onClick={() => {Pone  && onPageNumberClick("1")}}
        >
        {Pone ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
          
          
         
        </div>
     
      
        </Box>
          
      
      )}
    </Step>
   
  

    <Step transition="scale">
      {({ accomplished, index }) => (
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          // onClick={() =>{Ptwo && onPageNumberClick("2")}}
        >
         {Ptwo ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
        </div>
      )}
    </Step>
    <Step transition="scale" >
      {({ accomplished, index }) => (
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          // onClick={() =>{ Pthree && onPageNumberClick("3")}}
        >
          {Pthree ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
        </div>
      )}
    </Step>
    <Step transition="scale" >
      {({ accomplished, index }) => (
       
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          // onClick={() =>{ Pfour && onPageNumberClick("4")}}
        >
             {Pfour ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
        </div>
      
     
      )}
    </Step>

    <Step transition="scale" >
      {({ accomplished, index }) => (
       
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          // onClick={() =>{ Pfive && onPageNumberClick("5")}}
        >
           {Pfive ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
        </div>
      
     
      )}
    </Step>
  </ProgressBar>
  )
}

export default MultistepProgressBar
