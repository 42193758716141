import { Box, Grid, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { FilePond } from 'react-filepond'
import ProgressionSubmit from '../Forms/ProgressionSubmit'
import InputField from '../Forms/InputField';
import DatePickerComponent from '../Forms/DatePickerComponent';

function DriverDocuments({onButtonClick}) {
    // const [selectedValue, setSelectedValue] = useState('j');

    // const handleChange = (event) => {
    //   setSelectedValue(event.target.value);
    // };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd:855,
            md: 900,
            fmd:950,
            slg:1098,
            lg: 1200,
            sxl: 1430,
            xl: 1836,
          },
        },
      });
      
      const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl"));
      const [files, setFiles] = useState([])


  return (
    <Box>
      
    <Box overflow='auto' height={isXlMatch ? "25vh" : '28vh'}  >  
      <Stack spacing={3}>


         <Grid container>
          <Grid xs={12} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
        </Grid>
        </Grid>


  
        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Insurance Company"} placeHolder={"Insurance Company"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Insurance Number"} placeHolder={"Insurance Number"} />
          </Grid>
        </Grid>
      

        <Grid container>
          <Grid xs={5.75} item>
        <DatePickerComponent/>
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
        
          </Grid>
        </Grid>







        <Grid container>
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
        </Grid>




        <Grid container>
          <Grid xs={5.75} item>
        <DatePickerComponent/>
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
        
          </Grid>
        </Grid>





        <Grid container>
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
        </Grid>
      

        <Grid container>
          <Grid xs={5.75} item>
        <DatePickerComponent/>
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
        
          </Grid>
        </Grid>


        <Grid container>
          <Grid xs={12} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
        </Grid>
        </Grid>
      
     

      </Stack>
    </Box>
    <Box paddingTop={5} >
      <ProgressionSubmit Clicking={onButtonClick} toPage={"pagethree"} goBack={"pageone"} />
    </Box>
    </Box>
  )
}

export default DriverDocuments
