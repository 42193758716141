import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import ImageSelector from '../../../Components/Forms/ImageSelector'
import InputField from '../../../Components/Forms/InputField'
import { VehicleCategory } from '../../../FakeData/FakeData'
import SelectField from '../../../Components/Forms/SelectField'
import AddIcon from '@mui/icons-material/Add';
import ApiCall from '../../../../Helper/ApiCalls'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import AlertPopUp from '../../../Components/Forms/AlertPopUp'
import SnackBars from '../../../Components/Forms/SnackBars'
import Error404 from '../../404 Error page/Error404'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import { useNavigate, useParams } from 'react-router-dom'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate'
import ImageSelectorUpdate from '../../../Components/Forms/ImageSelectorUpdate'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../../Sessions/Permisssions'

function EditServiceForm() {
    const [selectedChip, setSelectedChip] = useState([]);
    const [adOnFeatures , setAdOnFeatures] = useState([])
    const [vehicleType , setVehicleType] = useState([])
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [currentDetails , setCurrentDetails] = useState({})
    const { id:serviceID } = useParams();
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Ride Service','UPDATE')
      setEdit(isEdit)
      
  
       },[])

 





      //forms
  const [serviceName , setServiceName] = useState('')
  const [description , setDescription] = useState('')
  const [isShowService , setIsShowService] = useState(false)
  const [selectedVehicle , setSelectedVehicle] = useState([])
  const [selectedCategory , setSelectedCategory] = useState([])
  const [featureData , setFreatureData] = useState([])
  const [imageFile , setImageFile] = useState({})
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [submitHit,setSubmitHit] = useState(false)
  const [enable , setEnable] = useState(false)
  const [ImageLink , setImageLink] = useState('')
  const [currentVehicle, setCurrentVehicle] = useState([])
  const [currentCat, setCurrentCat] = useState([])
  const navigate = useNavigate()
console.log(currentVehicle,",---curreentt vehilesss")

  useEffect(()=>{

    if(serviceID){
   
      ApiCall("GET", `/trip-service/v1/admin/value-service/${serviceID}`,null,).then((data)=>{
           if(data.result){
            const rowData = data?.result
            setServiceName(rowData?.ServiceName)
            setDescription(rowData?.Description)
            setImageLink(rowData?.IconUrl)
            setCurrentDetails(data.result)
            let chipData = rowData?.AdOnFeaturesIDS?.map((each)=>{
              return each?.Type
            })
            let featureData = rowData?.AdOnFeaturesIDS?.map((each)=>{
              return {id:each?._id , name:each?.Type}
            })
            setFreatureData(featureData)
            setSelectedChip(chipData)
            setIsShowService(rowData?.ShowInValueServices)
            let vehicleData = rowData?.VehicleTypeIDS?.map((each)=>{
              return {id:each?._id , name:each?.Type}
            })
            let vehicleIds = rowData?.VehicleTypeIDS?.map((each)=>{
              return each?._id 
            })
            setCurrentVehicle(vehicleData)
            setSelectedVehicle(vehicleIds)
            let cat = rowData?.VehicleCategory?.map((itm,index)=>{
              return {name:itm}
            })

        
            setCurrentCat(cat)
            setSelectedCategory(rowData?.VehicleCategory)
            


           
               
           }
       })
       .catch((error) => {
         if(error.message){
           console.log(`Error -> ${error.message}`)
           }else{
             console.log('something went wrong [valueservice - get]')
           }
       
       
       });
   
   
    }
   
   
    },[serviceID])









  useEffect(()=>{

    const hasErrors =
    ImageLink.length < 1 ||
    serviceName?.length < 3 ||
    description?.length < 5 ||
    selectedVehicle?.length < 1 ||
    selectedCategory?.length < 1 
 
  
  setEnable(!hasErrors);
   },[imageFile,serviceName,description,selectedVehicle,selectedCategory,selectedChip])


   
   const handleAddChip = (chipName) => {
    if (!selectedChip.includes(chipName)) {
        setSelectedChip([...selectedChip, chipName?.name]);
        setFreatureData([...featureData, chipName])
      }
  };

  const handleDiscard =()=>{
    navigate(-1)
  }

  const handleMultipleSelect = (label,data)=>{

    if(label === 'Vehicle Type'){

      let filteredData = data?.map((item)=>{
        return item?.id
      })

      setSelectedVehicle(filteredData)

    }else if(label === 'Vehicle Category'){


      let filteredData = data?.map((item)=>{
        return item?.name
      })

      setSelectedCategory(filteredData)

    }

  }

  const handleImageFiles = (files)=>{
  setImageFile(files)

  }









 






  useEffect(()=>{
    ApiCall("GET", "/trip-service/v1/admin/value-service/adOn/features", null,).then((data)=>{
        if(data.result){
            const rowData = data?.result
        
            const filteredData = rowData?.map((eachData)=>{
                return {name:eachData?.Type,id:eachData?._id}

            })

            setAdOnFeatures(filteredData)
        }
     
    })


    ApiCall("GET", "/trip-service/v1/admin/value-service/vehicle/type", null,).then((data)=>{
      if(data.result){
       const rowData = data?.result;
       const filteredData = rowData?.map((eachType)=>{
         if(eachType?.Type !== 'Scooter' && eachType?.Type !== 'MotorCycle' && eachType?.Type !== 'Lorry' ){
           return{id:eachType?._id,name:eachType?.Type}
         }
         
       })


       console.log(filteredData,"<---filteredddd")

       setVehicleType(filteredData.filter(item => item !== undefined))
      }


})

  },[])

console.log(ImageLink,"THE THE LLIINNKKK")

// handling form submittion 

const handleFormSubmit =()=>{
    setSubmitHit(true)
   
    if(enable){

     
        
     const adOnIds = featureData?.map(item => item.id).join(',');
     const vehicleTypes = selectedVehicle?.join(",")


     let appData={
      ValueServiceIconImage:ImageLink,
      serviceName:serviceName,
      description:description,
      adOnFeaturesIds:adOnIds,
      showInValueServices:isShowService,
      vehicleTypeIds:vehicleTypes,
      vehicleCategory:selectedCategory
     }
    
     const formData = new FormData();
     imageFile && formData.append('ValueServiceIconImage',imageFile.file);
     formData.append('serviceName', serviceName);
     formData.append('description', description);
     formData.append('adOnFeaturesIds', adOnIds);
     formData.append('showInValueServices', isShowService);
     formData.append('vehicleTypeIds', vehicleTypes); 
     formData.append('countryId', CountryID);
    
    
    selectedCategory.forEach((category, index) => {
      formData.append(`vehicleCategory[${index}]`, category);
    });
    
    ApiCall("PUT", `/trip-service/v1/admin/value-service/update/${serviceID}`, formData,).then((data)=>{
      
        if(data.result === 'success'){
       
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully edited service")
    
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });
   
   
   
    }
   
   
   }

   const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

  const handleDeleteChip = (chipName) => {
    setSelectedChip(
       selectedChip.filter((each) => each !== chipName)
    )
    setFreatureData(featureData.filter((each) => each.name !== chipName ))
 };




 const chipAdornment = selectedChip ? (
<InputAdornment position="start">
 {selectedChip?.map(eachItem => (
   <Chip label={eachItem} onDelete={() => handleDeleteChip(eachItem)} />
 ))}
</InputAdornment>
 ) : null;




  return (
   <Box>   
     <Stack spacing={3} >
     {/* form   */}
     <Stack spacing={2}>
      <Box width={'30%'}>
     <ImageSelectorUpdate imageLink={ImageLink} isError={submitHit && !imageFile && ImageLink?.length < 1} handleFileChange ={handleImageFiles} />
     </Box>
 
     <Stack direction={isMatch ? 'column':'row'} spacing={3} >
     <Box width={'43%'}>
         <InputFieldUpdate Value={serviceName} isError={submitHit && serviceName?.length < 3} ErrNote={""} InChange={(e) => setServiceName(e.target.value)}   sideLabel={"Ride Service Name"} placeHolder={"Ride Service Name"} />
         </Box>
         <Box width={isMatch ? "43%" : "100%"} >
         <InputFieldUpdate Value={description} isError={submitHit && description?.length < 5} ErrNote={""} InChange={(e) => setDescription(e.target.value)}  sideLabel={"Description"} placeHolder={"Description"} />
         </Box>
          
         </Stack>
 
 
 
         <Stack>
         <FormControlLabel onChange={(e) => setIsShowService(e.target.checked)}  control={<Checkbox checked={isShowService}  color="success" />} label="List in value service?" />
         </Stack>
 
 
 
         <Stack direction={'row'} spacing={3} >
          <Box width={'30%'}>
          <MultipleSelectUpdate currentSelect={currentVehicle}   isError={ submitHit && selectedVehicle?.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Type"} Data={vehicleType} />
          </Box>
          <Box width={'30%'}>
          <MultipleSelectUpdate currentSelect={currentCat} isError={submitHit && selectedCategory?.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} placeHolder={"choose"} Data={VehicleCategory}/>
          </Box>
         </Stack>
 
 
 
 {/* readOnly input field */}
 
         <Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Features</FormLabel>
         <Typography color={'red'}>*</Typography>
         </Stack>
        
         <OutlinedInput
         error={submitHit && selectedChip?.length < 1 }
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
     startAdornment={chipAdornment}
   />
       </FormControl>
         </Stack>
 
 
 
         <Stack direction={'row'} spacing={2}>
         
             {
               adOnFeatures ? 
                 adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && (
 
                     <Button
                     onClick={() => handleAddChip(eachFeature)}
                     sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',
                       '&:hover': {
                         backgroundColor: '#FFFFFF',
                         color: '#0B2863',
                       },
                     }}
                     variant="contained"
                     startIcon={<AddIcon />}
                   >
                 {eachFeature?.name}
                   </Button>
 
                 )) : 
                 (
                     <Typography color={'red'}>No Features available</Typography>
                 )
             }
      
       </Stack>
 
     </Stack>
     {/* <AlertPopUp/> */}
     <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
     <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
     
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}  handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
      color: 'black',
    },
    }}>Discard</Button>
   </Stack>
   </Stack>
  

   </Box>
  )
}

export default EditServiceForm