import React from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';

import FleetProgressionVehicles from './FleetProgressionVehicles';


const breadcrumbs = [
    <Typography  key="3" color="text.primary">
      Fleet
      </Typography>,
 <Typography key="3" color="text.primary">
     Add Vehicle
      </Typography>,
  ];


function CreateFleetVehicle() {
  useTitle("Add Vehicle - Geolah");
  return (
   <Box>
    <CreatePageHead title={'Add Vehicle'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
  <FleetProgressionVehicles/>
    </Box>
    </Box>
   </Box>
  )
}

export default CreateFleetVehicle