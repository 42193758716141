import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DataTable from '../../Components/Table/DataTable';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import SnackBars from '../../Components/Forms/SnackBars';
import ErrorNotify from '../../Components/Forms/ErrorNotify';
import ApiCall from '../../../Helper/ApiCalls';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPrivilage } from '../../../Sessions/Permisssions';
import dayjs from 'dayjs';
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "transcationId",
      label: "Transaction Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },

    {
      id: "referanceId",
      label: "Referance Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },

    
    {
      id: "dateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    // {
    //   id: "category",
    //   label: "Category",
    //   minWidth: 200,
    //   align: "left",
    //   sort: true,
    // },
    {
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "status",
        label: "Status",
        minWidth: 170,
        align: "left",
        sort: false,
      },
   
    // {
    //   id: "actionPending",
    //   label: "Actions",
    //   minWidth: 170,
    //   align: "left",
    //   sort: false,
    // },
  ]; 


function OtherWithdrawal() {
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC")
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const navigate = useNavigate()
    const [submitHit,setSubmitHit] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)


    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
   
      let isWrite = checkPrivilage('Wallet','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Wallet','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Wallet','DELETE')
       setDeletePer(isDelete)
       },[])



    // api call
useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/pay-service/v1/admin/payout/all/request/?countryId=${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const walletData = data?.result
      setTotalRows(walletData?.length)
      console.log(walletData,"<--the wallet dataaaasssss")
     const filteredData =  walletData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
       
      const totalWithdrawals = parseFloat(eachItem?.WithdrawAmount)
      const issueDate = eachItem?.CreatedAt
      const formattedDate = dayjs(issueDate).format('DD-MM-YYYY')
      const currentStartTime  =  issueDate?.slice(11, 16); 
      let testT = convertToCurrentCountryTime(issueDate)
      // PayoutRequestID

      let items = {
            _id:eachItem._id,
            id:index+1,
            ID:eachItem?.ID,
            name:eachItem?.DriverName,
            amount:isNaN(totalWithdrawals) ? "N/A" : totalWithdrawals.toFixed(2) || "N/A",
            dateTime:testT,
            transcationId:eachItem?.AdyenTransactionID || 'waiting..',
            referanceId:eachItem?.Reference,
            status:eachItem?.Status,
        
           }
  
           return items;
     })
  
     
  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,currentPage])



    // useEffect(()=>{
    //     setRows(
    //       tableData.filter((item) =>
    //       item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
    //         ))
    //      },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/wallet-details/${id}`)
    }
  
  }


  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
     console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
  }


    const handleColumnFilter = (items, searchText) => {



        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }

      const clickConfirm =(data)=>{
        setSubmitHit(true)
         let sendData = {
          requestId:data?.ID
         }

         ApiCall("POST", "/pay-service/v1/admin/payout/request/approve",sendData).then((data)=>{
          if(data.status === 'success'){
            setOnSuccess((i) => !i )
            setOnApiSuccess("Confirmed")
          }})
      .catch((error) => {
       setOnFailed((i) => !i)  
       if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
      });


      }

      const clickCancel =(data)=>{
        setSubmitHit(true)
            let sendData = {
          requestId:data?.ID
         }

         ApiCall("POST", "/pay-service/v1/admin/payout/request/cancel",sendData).then((data)=>{
          if(data.status === 'success'){
            setOnSuccess((i) => !i )
            setOnApiSuccess("Cancelled")
          }})
      .catch((error) => {
       setOnFailed((i) => !i)  
       if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
      });
      }




    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };



      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }



  return (
 <>
 <Box>

<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
    <DataTable
      handlingPage={handlePageChange}
      totalCount={totalRows}
      handleCancelClick={clickCancel}
      handleConfirmClick={clickConfirm}
       DeletePermission={deletePer}
       ButtonPermission={write}
       EditPermission={edit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
        </Box>
        </Box>
 </>
  )
}

export default OtherWithdrawal
