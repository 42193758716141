import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Tabs from '../../../../Components/Tabs/Tabs';
import ServiceFormVerification from './ServiceFormVerification';
import PersonlaDetailsVerification from './PersonlaDetailsVerification';
import DrivingLicenceVerification from './DrivingLicenceVerification';
import VehicleDetailsVerification from './VehicleDetailsVerification';
import InsuranceDetailsVerification from './InsuranceDetailsVerification';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TabItems = [
    { name: "Service Details",
     value: "Service Details" },
    {
      name: "Personal Details",
      value: "Personal Details",
    },
    {
      name: "Driving License",
      value: "Driving License",
    },
    {
      name: "Vehicle Details",
      value: "Vehicle Details",
    },
    {
      name: "Insurance Details",
      value: "Insurance Details",
    },

  ];




function VerificationDetails() {
  const { id:driverId } = useParams(); 
  const [vehicleDetails,setVehicleDetails] = useState('')
  useEffect(()=>{
    const apiUrl = `${baseUrl}/trip-service/v1/driver/ride/service`;
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
  
      },
    };
  
    axios.get(apiUrl, config).then(response => {
  
        if(response?.data?.result){
      const vehicleData = response?.data?.result  
      
  
       setVehicleDetails(vehicleData?.VehicleTypeID?._id)
     
        
        }
        
          })
          .catch(error => {
            console.error(error,"GET API ERROR");
          });
  
  },[driverId])


  const Components = [
    {
      page: <ServiceFormVerification/>,
      value: "Service Details",
    },
    {
        page: <PersonlaDetailsVerification/>,
        value: "Personal Details",
      },
      {
        page: <DrivingLicenceVerification/>,
        value: "Driving License",
      },
      {
        page: <VehicleDetailsVerification VehicleTypeID={vehicleDetails}/>,
        value: "Vehicle Details",
      },
      {
        page: <InsuranceDetailsVerification/>,
        value: "Insurance Details",
      },
 
 
  ];

  return (
  <Box>
<Tabs List={TabItems} component={Components} />
  </Box>
  )
}

export default VerificationDetails
