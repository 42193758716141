import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import DetailsNav from "../../../Components/DetailsPage/DetailsNav";
import Tabs from "../../../Components/Tabs/Tabs";

import MapContainer from "../../../Components/Maps/MapContainer";
import VehicleDetails from "./VehicleDetails";
import BankDetails from "./BankDetails";
import DriverPersonalDetails from "./DriverPersonalDetails/DriverPersonalDetails";

function DriverDetails() {
  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])


  const TabItems = [
    { name: "Personal Details", value: "Personal Details" },
    {
      name: "Activity",
      value: "Activity",
    },
    {
      name: "Documents",
      value: "Documents",
    },
    {
      name: "Vehicle Details",
      value: "Vehicle Details",
    },
    {
      name: "Bank Details",
      value: "Bank Details",
    },
    {
      name: "Wallet",
      value: "Wallet",
    },
    {
      name: "Reviews",
      value: "Reviews",
    },
  ];

  const TabItems_two = [
    { name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />
    , value: "Personal Details" },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Activity",
    },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Documents",
    },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Vehicle Details",
    },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Bank Details",
    },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Wallet",
    },
    {
      name: <Skeleton width={'80px'} variant="text" sx={{ fontSize: '1rem' }} />,
      value: "Reviews",
    },
  ];






  const Components = [
    {
      page: <MapContainer width={"70%"} title={"Driver Location"} />,
      value: "Activity",
    },
    {
      page: <VehicleDetails />,
      value: "Vehicle Details",
    },
    {
      page: <BankDetails />,
      value: "Bank Details",
    },
    
    {
      page: <DriverPersonalDetails />,
      value: "Personal Details",
    },
  ];
  return (
    <Box>
      <DetailsNav title={"Driver Details"} />
      <Tabs List={ loadingData ? TabItems_two : TabItems} component={Components} />
    </Box>
  );
}

export default DriverDetails;
