import React from 'react'
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";

function DriverBankDetailsSkelton() {
  return (
    <Box
      sx={{
        flexWrap: "wrap",
        "& > :not(style)": {
          mt: "3%",
          width: "100%",
          height: "216px",
          borderRadius: "4px",
        },
      }}
    >
      <Paper sx={{ padding: "16px" }} elevation={3}>
        <Box>
          <Box display="flex" justifyContent="space-between" padding={3}>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
              <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
              <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /></Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
              <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
              <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /></Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
              <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
              <Typography fontWeight={450}><Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /></Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
              <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} />
              </Typography>
              <Typography fontWeight={450}>  <Skeleton width={'90px'} variant="text" sx={{ fontSize: '1rem' }} /></Typography>
            </Stack>
          </Box>

          <Box display="flex" justifyContent="space-between" padding={3}>
            <Box
              sx={{
                backgroundColor: "#EFF0F2",
                height: "40px",
                width: "229.5px",
              }}
            >
       
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default DriverBankDetailsSkelton
