import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import vehicleIcon from '../../../../../Assets/Forms/fi_3774278.svg'
import { ButtonuseStyles } from './AddDriverFleetCard';
import { useNavigate } from 'react-router-dom';

function AddVehicleFleetCard() {
  const classes = ButtonuseStyles();
  const navigate = useNavigate()
  const onTabClick = ()=>{
    navigate('/fleet-add-vehicle')
  }
  
  return (
<Box onClick={onTabClick} className={classes.container} p={2}>
<Stack alignItems={'center'} direction={'row'} spacing={6}>
<img src={vehicleIcon} alt='icon'/>
<Typography sx={{fontWeight:600}} variant='body1'>Add new Vehicle</Typography>
</Stack>
</Box>
  )
}

export default AddVehicleFleetCard
