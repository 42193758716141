
import React from 'react'
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function MultiProgressionEditVehicle ({ page, onPageNumberClick,Pone,Ptwo,Pthree,Pfour,Pfive }) {

  var stepPercentage = 0;
  
  if (page === "pageone") {
    stepPercentage = 50;
  } else if (page === "pagetwo") {
    stepPercentage = 100;
  } 



  return (
    <ProgressBar percent={stepPercentage}>
    

      
    <Step transition="scale">
      {({ accomplished, index }) => (
       <Box    alignItems="center" >

       
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          onClick={() => {onPageNumberClick("1")}}
        >
        {Pone ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
          
          
         
        </div>
     
      
        </Box>
          
      
      )}
    </Step>
   
  

    <Step transition="scale">
      {({ accomplished, index }) => (
        <div
          className={`indexedStep ${accomplished ? "accomplished" : null}`}
          onClick={() =>{onPageNumberClick("2")}}
        >
         {Ptwo ? <CheckCircleIcon sx={{width:"20px",height:'20px'}} fontSize='small' /> : index + 1}
        </div>
      )}
    </Step>

   
    

  </ProgressBar>
  )
}

export default MultiProgressionEditVehicle