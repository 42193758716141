
import React, { useEffect, useState } from 'react'
import TabList from './Tabs/TabList'
import LoadingPage from '../../Components/Loading/LoadingPage'
import {  Typography } from '@mui/material'
import TableHead from '../../Components/Table/TableHead';
import TabContainer from './Tabs/TabContainer';
import OverView from './OverView/OverView';
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';

function Dashboard() {
const { currentUserdata } = useSelector((state) => state.user);
useTitle("Dashboard - Geolah");
const [loading , setLoading] = useState(true)

const dispatch = useDispatch()
const [any,setAny] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Dashboard')
  setAny(isAany)
   },[])







useEffect(()=>{
  if(currentUserdata?.AccessToken){
    const timer = setTimeout(() => {
      // Dispatch your action here
      dispatch(insertNavigationDataAction('Dashboard'));
    }, 900); 
//  dispatch(insertNavigationDataAction('Dashboard'))
  }
},[currentUserdata])




const breadcrumbs = [
  <Typography key="3" color="text.primary">
    Dashboard
    </Typography>,
<Typography key="3" color="text.primary">
    Dashboard
    </Typography>,


]

// useEffect(()=>{

// },[])


  return (
any ? (
<>
<TableHead title={'Dashboard'} BreadList={breadcrumbs} />
 <TabContainer/>
<OverView/>
</>
): any === false ? (
  <RestrictedUsage/>
):null
  )
}

export default Dashboard
