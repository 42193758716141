import { Box,FormLabel, Grid, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react'
import ProgressionSubmit from '../Forms/ProgressionSubmit';
import { FilePond} from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import InputField from '../Forms/InputField';
import SelectField from '../Forms/SelectField';
import { PH_CODE } from '../../FakeData/FakeData'
import Radio from '@mui/joy/Radio';
import { couponType } from "../../FakeData/FakeData";

import DatePickerComponent from '../Forms/DatePickerComponent';
import Scrollbars from 'react-custom-scrollbars';

function PersonalDeatils({onButtonClick}) {
  let renderCustomHorizontalThumb = ({ style, ...props }) => {
                        
    const thumbStyle = {
        backgroundColor: `#ecf0f1`,
        width:'10px',
        height:'200px',
        right:'-4px',
     
    };
    return (
        <div
       
            style={{ ...style, ...thumbStyle }}
            {...props}/>
    );
    }


    const [selectedValue, setSelectedValue] = useState('j');

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd:855,
            md: 900,
            fmd:950,
            slg:1098,
            lg: 1200,
            sxl: 1430,
            xl: 1836,
          },
        },
      });
      
      const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl"));
      const [files, setFiles] = useState([])


  return (
    <Box >
      <Scrollbars style={{ width: "100%", height: isXlMatch ? "25vh" : '28vh'}}  renderThumbVertical={ renderCustomHorizontalThumb } >
    <Box  height={isXlMatch ? "25vh" : '28vh'}  >  
      <Stack spacing={3}>


         <Grid container>
          <Grid xs={12} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
        </Grid>
        </Grid>


  
        <Grid container>
        <Grid xs={5.5} item>
            <InputField sideLabel={"Job Name"} placeHolder={"Job Name"} />
          </Grid>
          <Grid xs={0.5} item />
       

          <Grid xs={5.5} item>
           
           <Grid container>
            <Grid item xs={2} >
            <SelectField
              sideLabel={"Code"}
              placeHolder={"+67"}
              Data={PH_CODE}
            />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={9.5} >
            <InputField sideLabel={"Phone"} placeHolder={"Phone"} />
            </Grid>

           </Grid>
           </Grid>
           </Grid>


           <Grid container>
          <Grid xs={5.5} item>
          <InputField sideLabel={"Email-Id"} placeHolder={"Mail id"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.5} item>
            <Stack spacing={3} >

         
          <FormLabel>Gender *</FormLabel>

          <Box sx={{ display: 'flex', gap: 2 }}>
      <Radio
        checked={selectedValue === 'a'}
        label="Male"
        onChange={handleChange}
        value="a"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
         <Radio
        checked={selectedValue === 'b'}
        label="Female"
        onChange={handleChange}
        value="b"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />

       <Radio 
        checked={selectedValue === 'c'}
        label="Other"
        onChange={handleChange}
        value="c"
        name="radio-buttons"
        slotProps={{ input: { 'aria-label': 'A' } }}
      />
    
    </Box>
    </Stack>
  


          </Grid>
        </Grid>
     

   
        <Grid container>
          <Grid xs={5.75} item>
        <DatePickerComponent/>
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <SelectField
              sideLabel={"Postal Code"}
              placeHolder={"Amount"}
              Data={couponType}

            />
          </Grid>
        </Grid>





        <Grid container>
          <Grid xs={12} item>
          <InputField sideLabel={"Address"} placeHolder={"Address"} />
        </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} item>
          <InputField sideLabel={"Block floor unit"} placeHolder={"Floor Unit"} />
        </Grid>
        </Grid>


        <Grid container>
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
          </Grid>
        </Grid>




        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"NRIC Number"} placeHolder={"NRIC Number"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Vaccination Details"} placeHolder={"Vaccination Details"} />
          </Grid>
        </Grid>



        <Grid container>
        <Grid xs={5.5} item>
            <InputField sideLabel={"Kin 1 Name "} placeHolder={"Job Name"} />
          </Grid>
          <Grid xs={0.5} item />
       

          <Grid xs={5.5} item>
           
           <Grid container>
            <Grid item xs={2} >
            <SelectField
              sideLabel={"Code"}
              placeHolder={"+67"}
              Data={PH_CODE}
            />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={9.5} >
            <InputField sideLabel={"Kin 1 Phone"} placeHolder={"Phone"} />
            </Grid>

           </Grid>
           </Grid>
           </Grid>





           <Grid container>
        <Grid xs={5.5} item>
            <InputField sideLabel={"Kin 2 Name "} placeHolder={"Name"} />
          </Grid>
          <Grid xs={0.5} item />
       

          <Grid xs={5.5} item>
           
           <Grid container>
            <Grid item xs={2} >
            <SelectField
              sideLabel={"Code"}
              placeHolder={"+67"}
              Data={PH_CODE}
            />
            </Grid>
            <Grid item xs={0.5} />

            <Grid item xs={9.5} >
            <InputField sideLabel={"Kin 2 Phone"} placeHolder={"Phone"} />
            </Grid>

           </Grid>
           </Grid>
           </Grid>
      



     

      </Stack>
    </Box>
    </Scrollbars>
    <Box paddingTop={5} >
      <ProgressionSubmit Clicking={onButtonClick} toPage={"pagetwo"} goBack={"pageone"} />
    </Box>
    </Box>
  )
}

export default PersonalDeatils
