import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Tabs from '../../Components/Tabs/Tabs';
import TableHead from '../../Components/Table/TableHead';
import { useTitle } from '../../../Hooks/UseTitle';
import WalletMangementModule from './WalletMangementModule';
import PendingWithdrawal from './PendingWithdrawal';
import KycRequest from './WalletDetails/KycRequest';
import { checkAnyPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';

                                                                                                                                                                                                                                                                                                
const TabItems = [
    
    { name: "Wallet Management", value: "Wallet Management" },
    {
      name: "Pending Withdrawal",
      value: "Pending Withdrawal",
    },
    {
      name: "KYC Request",
      value: "KYC Request",
    },
  
  ];

  const Components = [
    {
      page: <WalletMangementModule />,
      value: "Wallet Management",
    },
    {
        page: <PendingWithdrawal />,
        value: "Pending Withdrawal",
      },
      {
        page: <KycRequest />,
        value: "KYC Request",
      },
];




function WalletModule() {
    useTitle("Wallet- Geolah");
    const [any,setAny] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Wallet')
      setAny(isAany)
       },[])
    
  return (
    any ? (
<Box>
<TableHead title={'Driver Wallet'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<Tabs current={'Wallet Management'} List={TabItems} component={Components}  />
</Box>

</Box>
</Box>
): any === false ? (
  <RestrictedUsage/>
): null
  )
}

export default WalletModule
