import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NotifyAlertWhileDelete from '../../../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../../../Components/Table/DataTable';
import RideFilter from '../../../RideServiceManagement/RideFilter';
import ApiCall from '../../../../../Helper/ApiCalls';
import dayjs from 'dayjs';
import { convertToCurrentCountryTime } from '../../../../Components/MultiFunctions/MultiFunctions';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "rideId",
      label: "Ride Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "dateTime",
        label: "Date & Time",
        minWidth: 170,
        align: "left",
        sort: true,
      },
    {
      id: "bookingType",
      label: "Booking Type",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "address",
      label: "Drop Address",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "fare",
      label: "Fair",
      minWidth: 200,
      align: "left",
      sort: true,
    },


    {
      id: "rideStatus",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    // {
    //     id: "actions",
    //     label: "Actions",
    //     minWidth: 170,
    //     align: "left",
    //     sort: false,
    //   },
  ]; 





function VehicleDetailsHistory() {
    const [order, setOrder] = useState("ASC");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const navigate = useNavigate()
    const { id:vehicleId } = useParams(); 
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)

  // api call
  useEffect(()=>{
    const currentLimit = rowsPerPage
    const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/trip-service/v1/admin/vehicle/trip/history/?cabId=${vehicleId}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const rideData = data?.result
      setTotalRows(rideData?.length)
      console.log(rideData,"<----septtt")
      if(searchInput?.length <= 0){
     const filteredData =  rideData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
      let dateString = eachItem?.Date
      let testT = convertToCurrentCountryTime(dateString)
      const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
   
      const currentStartTime  =  dateString?.slice(11, 16);
      const allDestination = eachItem?.UserDestinationInfo
      const totalDest = allDestination?.length 
      const rideFares = parseFloat(eachItem?.CabDurationFareInfo?.TotalFare)
     
      let items = {
            _id:eachItem._id,
            id:index+1+currentOffset,
            rideId:eachItem?.CabTripID,
            dateTime:testT,
            bookingType:eachItem?.RideType,
            address:eachItem?.UserDestinationInfo?.[totalDest-1]?.Address,
            fare:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
            rideStatus:eachItem?.TripStatus

    
           }
  
           return items;
     })

     setRows(filteredData)
    }
  
   
     const filteredRowData =  rideData?.map((eachItem,index)=>{
      let dateString = eachItem?.Date
      let testT = convertToCurrentCountryTime(dateString)
      console.log(testT,"<---f-f-f-f")
      const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
   
      const currentStartTime  =  dateString?.slice(11, 16);
      const allDestination = eachItem?.UserDestinationInfo
      const totalDest = allDestination?.length 
      const rideFares = parseFloat(eachItem?.CabDurationFareInfo?.TotalFare)

     
      let items = {
            _id:eachItem._id,
            id:index+1+currentOffset,
            rideId:eachItem?.CabTripID,
            dateTime:testT,
            bookingType:eachItem?.RideType,
            address:eachItem?.UserDestinationInfo?.[totalDest-1]?.Address,
            fare:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
            rideStatus:eachItem?.TripStatus

    
           }
  
           return items;
     })
    
     setTableData(filteredRowData);
   } })
  },[success,currentPage,searchInput])



    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.rideId?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.dateTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.bookingType?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.address?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.fare?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.rideStatus?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-service/${id}`)
    }
   
  
  }

    const handleChangeInSearch = (value)=>{
        setSearchInput(value)
        console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
     }

    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }
      


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }





  return (
<Box>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='1200px' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/export'}
        // ButtonLabel={'Export'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
    </Box>

</Box>
</Box>
  )
}

export default VehicleDetailsHistory
