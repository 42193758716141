
import axios from "axios"
import { useSelector } from "react-redux";
// import 'dotenv/config'
// const baseUrl = "http://192.168.29.241:7000"
// export const baseUrl = "http://3.1.252.211:7000"

// export const baseUrl = "https://dev.geolah.lilacinfotech.com"

export const baseUrl = process.env.REACT_APP_Base_Url;


// the base url change below if needed
const BASE_URL = baseUrl
// saved token
const TOKEN = localStorage.getItem("jwtToken");

// public requestMethord

export const publicRequest = axios.create({
    baseURL:BASE_URL,
})

// user RequestMethord

export const
 USERRequest = axios.create({
    baseURL:BASE_URL,
    headers:{Authorization:`Bearer ${TOKEN}`,
    ['x-app-type']: 'AdminApp',
      },
   
})

export const SPECIALRequest = axios.create({
    baseURL:BASE_URL,
})