import { Box } from '@mui/material'
import React from 'react'
import SingleDashCard from './SingleDashCard'
import { DashTabData } from '../../../FakeData/FakeData'

function TabList() {
  return (
<Box width="100%" display="flex" justifyContent="space-around" flexWrap="wrap" >
{
  DashTabData?.map((items,index)=>(
    <SingleDashCard key={index} Title={items.number} Color={items.color} Caption={items.subtitle}  />
  ))
}


</Box>
  )
}

export default TabList
