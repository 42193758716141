import { Box, Stack } from '@mui/material'
import React from 'react'
import FormNav from '../Forms/FormNav'
import TicketForm from './TicketForm'


function AddTickets() {
  return (
<Box>
<Stack spacing={4}>

<FormNav title={'Add Ticket'}/>
<TicketForm/>

</Stack>
</Box>
  )
}

export default AddTickets
