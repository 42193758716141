import React, { useState } from "react";
import TableNav from "../../Components/TableSearchExport/TableNav";
import { Box, Button } from "@mui/material";
import DataTable from "../../Components/Table/DataTable";
import DriverMockData from '../../FakeData/MOCK_DATA7.json'
import CreateTab from "../../Components/Forms/CreateTab";
import FormModal from "../../Components/Modal/FormModal";
import AddDriver from "../../Components/Driver/AddDriver";
import { useSelector } from "react-redux";

const columns = [
  {
    id: "id",
    label: "Id",
    minWidth: 100,
    sort: true,
  },

  {
    id: "name",
    label: "Name",
    minWidth: 130,
    align: "left",
    sort: true,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
    align: "left",
    sort: false,
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "bookings",
    label: "Bookings",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "reviews",
    label: "Reviews",
    minWidth: 170,
    align: "left",
    sort: true,
   
  },
  {
    id: "createdBy",
    label: "Created By",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "registeredOn",
    label: "Registered On",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: "Online",
      onFalse: "Offline",
    },},
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
];






function Driver() {
  const {currentPermissiondata} = useSelector((state) => state.permission)
  console.log(currentPermissiondata,"ddrrriiivveerrrr")
  const [rows, setRows] = useState(DriverMockData);
  const [order, setOrder] = useState("ASC");
  const [modalStatus, setModalStatus] = useState(false);

  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "reviews" && id !== "bookings"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "reviews" && id !== "bookings"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };


  const onClick = () => {
    setModalStatus(true);
  };

  const onClose = () => {
    setModalStatus(false);
  };

  return (
    <Box>
    <TableNav title={"Drivers"} />
 
  {/* <CreateTab handleClick={onClick} title={"Create New Driver"} /> */}

  <Button onClick={onClick}>test</Button>

 
     <FormModal
      handleclose={onClose}
    status={modalStatus}
      component={<AddDriver />}
       />


    <Box sx={{ width: "100%", height: "100%" }} pt={3}>
      {/* <DataTable
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
      /> */}
    </Box>
  </Box>
  );
}

export default Driver;
