import { useEffect } from "react";



/** Hook for changing title of the Pages */

export const useTitle = (title) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    const oldTitle = document.title;
    title && (document.title = title);
    // following line is optional, but will reset title when component unmounts
    return () => (document.title = oldTitle);
  }, [title]);
};
