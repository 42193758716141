import { Avatar, Box, IconButton, Rating, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import editIcon from '../../../../Assets/Forms/Frame 48077.svg'
import emailIcon from '../../../../Assets/Forms/Visual.svg'
import phoneIcon from '../../../../Assets/Forms/Visual (1).svg'
import ApproveButton from '../../../Components/Forms/ApproveButton'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from '../../../RequestMethord'
import NotifiProgress from '../../../Components/Forms/NotifiProgress'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import { useSelector } from 'react-redux'

function UserPersonalCard({}) {

// STATES

const [profileDetails , setProfileDetails] = useState({})
const { id:userId } = useParams(); 
const [submitHit,setSubmitHit] = useState(false)
const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false)
const [onSuccess , setOnSuccess] = useState(false)
const {currentUserPersonaldata} = useSelector((state) => state.userPersonal)

console.log(currentUserPersonaldata , "<---personal current")

//RENDERS AND APICALLS

useEffect(()=>{

  if(currentUserPersonaldata){
setProfileDetails(currentUserPersonaldata?.allUserData)


  }

},[currentUserPersonaldata])





// handle yes click 

const handleYesClick = ()=>{
  setSubmitHit(true)
  const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/approve/details`; 
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: userId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'application/json', // Set the content type for JSON data
    },
  };

  let verifyData={userId:userId,status: true}

  axios.put(apiUrl,verifyData,config)
  .then(response => {
    if(response?.data?.status === 'success'){
      setOnSuccess(true)
      window.location.reload(true);
    }
   
  })
  .catch(error => {

    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });

}



const handleYesClickReject = ()=>{
  setSubmitHit(true)
//   const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/approve/details`; 
//   const TOKEN = localStorage.getItem("jwtToken");
//   const config = {
//     headers: {
//       ['x-user-id']: driverId,
//       'Authorization': `Bearer ${TOKEN}`,
//       ['x-app-type']: 'AdminApp',
//       'Content-Type': 'application/json', // Set the content type for JSON data
//     },
//   };

//   let verifyData={driverId:driverId,status: false}

//   axios.put(apiUrl,verifyData,config)
//   .then(response => {
//     if(response?.data?.status === 'success'){
//       setOnSuccess(true)
//       window.location.reload(true);
//     }
   
//   })
//   .catch(error => {

//     setOnFailed((i) => !i)
      
//     if(error.message){
//       setOnApiFailed(error.message)
//     }else{
//       setOnApiFailed("Something went wrong.!")
//     }
//     console.error(error, "PUT API ERROR");
//   });

}

  return (
  <Box  width={'382px'} height={'341px'} borderRadius={'8px'} bgcolor={'#FFFFFF'} > 
  <Stack  justifyContent={'center'} padding={3} spacing={2}>

  {/* FIRST ROW */}
    <Stack direction={'row'} spacing={4}>
    <Avatar alt={profileDetails?.FirstName} src={profileDetails?.ImageUrl} sx={{ width: 64, height: 64 }}/>
    <Stack spacing={0.5}>
    <Typography fontWeight={'bold'} variant='p'>{profileDetails?.FirstName +" "+profileDetails?.LastName}</Typography>
    <Typography variant='caption'>{`Last active: ${profileDetails?.LastActiveTime?.slice(11, 16) || 'N/A'}`}</Typography>
    </Stack>
    {/* <IconButton> <img alt='edit' src={editIcon}/> </IconButton> */}
    </Stack>


 {/* SECOND ROW    */}
<Stack alignItems={'center'} direction={'row'} spacing={1} >
 <Typography fontWeight={'bold'} variant='p'>{profileDetails?.driverRating}</Typography>
 <Rating name="read-only" value={parseFloat(profileDetails?.driverRating)} readOnly />
 <Typography variant='caption'>{`${profileDetails?.reviewscount || 0} reviews`}</Typography>
 </Stack>

  {/* THIRD ROW */}


  <Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='email' src={emailIcon}/> </IconButton>
<Stack spacing={0.2}>
<Typography variant='caption'>Email</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileDetails?.Email}</Typography>
</Stack>
</Stack>

{/* FOURTH ROW */}

<Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='phone' src={phoneIcon}/> </IconButton>
  <Stack spacing={0.2}>
<Typography variant='caption'>Phone</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileDetails?.PhoneNumber}</Typography>
</Stack>
</Stack>

{/* LAST ROW */}

{/* <Box display={'flex'} justifyContent={'space-between'} p={1} width={'100%'} sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
<ApproveButton isVerified={profileDetails?.driverApprove}  handleConfirm={handleYesClick} DialogText={'Are you sure You want approve the Driver .?'} TitleText={'Approve Driver'} buttonColor={'success'} Label={'Approve'}/>
<ApproveButton   handleConfirm={handleYesClickReject} DialogText={'Are you sure You want to reject the Driver .?'} TitleText={'Reject Driver'} buttonColor={'error'} Label={'Reject'}/>
{/* <ApproveButton DialogText={'Are you sure You want enable priority sticker .?'} TitleText={'Enable priority sticker'} buttonColor={'error'} Label={'Enable Priority sticker'} /> */}
{/* </Box> */} 


{/* <NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
<ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} /> */}
 </Stack>

  </Box>
  )
}

export default UserPersonalCard