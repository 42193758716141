import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ApiCall from '../../../../Helper/ApiCalls';
import { Box } from '@mui/material';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../../Components/Table/DataTable';
import TableHead from '../../../Components/Table/TableHead';
import { useDispatch, useSelector } from 'react-redux';
import { insertModalDataAction } from '../../../Redux/Actions/ModalActions';
import FormModal from '../../../Components/Modal/FormModal';

import CreatePopularPlaceFormUpdate from './CretePopularPlace/CreatePopularPlaceFormUpdate';
import { checkPrivilage } from '../../../../Sessions/Permisssions';



const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "placeName",
      label: "Popular Place Name",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "address",
        label: "Address",
        minWidth: 150,
        align: "left",
        sort: true,
      },
    {
        id: "placeCat",
        label: "Place Category",
        minWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "actionPopular",
        label: "Actions",
        maxWidth: 170,
        align: "left",
        sort: false,
      },
    
  ]; 



function PopularPlaceTable({clickModal}) {
    const [order, setOrder] = useState("ASC");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [zoneINdex , setZoneIndex] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [modalStatus, setModalStatus] = useState(false);
    const { id:zoneName,zoneId } = useParams();
    const {currentModaldata} = useSelector((state) => state.modal)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [selectedDestination , setSelectedDestination] = useState({})
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      let isWrite = checkPrivilage('Zone','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Zone','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Zone','DELETE')
       setDeletePer(isDelete)
       },[])



    const handleCancel=()=>{
        dispatch(insertModalDataAction(!currentModaldata))
      }
    
    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage

        ApiCall("GET",`/trip-service/v1/admin/zone/all/popular-destination/?zoneId=${zoneId}&&limit=40000&&offset=0`, null, "uui").then((data)=>{
          if(data?.result){
      
          const rideData = data?.result
          setTotalRows(rideData?.length)
          if(searchInput?.length <= 0){
         const filteredData =  rideData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{

          let items = {
                _id:eachItem._id,
                id:index+1,
                placeName:eachItem?.PlaceName,
                placeCat:eachItem?.PlaceCategory,
                address:eachItem?.Address
         
               }
      
               return items;
         })
         setRows(filteredData)
        }

        const filteredRowData =  rideData?.map((eachItem,index)=>{

          let items = {
                _id:eachItem._id,
                id:index+1,
                placeName:eachItem?.PlaceName,
                placeCat:eachItem?.PlaceCategory,
                address:eachItem?.Address
         
               }
      
               return items;
         })
      
        
      
        
         setTableData(filteredRowData);
       } })
      },[success,searchInput])

        useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])


       


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-service/${id}`)
    }
   
  
  }

    const handleChangeInSearch = (value)=>{
        setSearchInput(value)
  
     }

    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }
      


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

      const onClick = (data) => {
        const destinationId = data?._id
        ApiCall("GET", `/trip-service/v1/admin/zone/single/popular-destination/ByID/?popularDestinationId=${destinationId}`,null,).then((data)=>{
          if(data.result){
      
            const rowData = data?.result
             setSelectedDestination(rowData)
      
                
          }
      })
      .catch((error) => {
      
        if(error.message){
          console.log(`Error -> ${error.message}`)
          }else{
            console.log('something went wrong')
          }
      
      });
      
        setModalStatus(true);

      }


      const onClose = () => {
        setModalStatus(false);
      };

      useEffect(()=>{
        setZoneIndex(zoneName === 'ZoneA' ? 0 : zoneName === 'ZoneB' ? 1 : zoneName === 'ZoneC' ? 2 : zoneName === 'ZoneD' ? 3: 0 )
        
        },[zoneName])

         
const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}



  return (
<Box mt={4}>
<TableHead title={'Popular Places'}/>
<FormModal isZone={true} handleclose={onClose} status={modalStatus} component={<CreatePopularPlaceFormUpdate currentData={selectedDestination} zoneIndex={zoneINdex}  CancelSubmit={handleCancel}/>} />
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
           DeletePermission={deletePer}
           ButtonPermission={write}
           EditPermission={edit} 
      modalClicked={clickModal}
      addDestination={onClick}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-popular'}
        ButtonLabel={'Add popular place'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
    </Box>

</Box>
</Box>
  )
}

export default PopularPlaceTable
