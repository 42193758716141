import { Box, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../Components/Forms/SelectField';
import InputField from '../../../../Components/Forms/InputField';
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import ImageSelector from '../../../../Components/Forms/ImageSelector';
import SnackBars from '../../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import axios from 'axios';
import { baseUrl } from '../../../../RequestMethord';
import { useSelector } from 'react-redux';
import ApiCall from '../../../../../Helper/ApiCalls';
import { Data } from '@react-google-maps/api';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function InsuranceForm({onButtonClick,onSectionComplete}) {
   //  STATES AND VARIABLES
 const [submitHit,setSubmitHit] = useState(false)
 const VehicleRegNo = localStorage.getItem("RegNo");
 const [onApiSuccess , setOnApiSuccess] = useState('')
 const [onApiFailed , setOnApiFailed] = useState('')
 const [onSuccess, setOnSuccess] = useState(false)
 const [onFailed , setOnFailed] = useState(false)
 const theme = createTheme({
  breakpoints: {
    values: {
      xs: 900,
  
    },
  },
});
const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


const [insuranceNumber , setInsuranceNumber] = useState('')
const [selectedExp , setSelectedExp] = useState('')

// const { currentRegdata } = useSelector((state) => state.regno);
const [currentDetails , setCurrentDetails] = useState({})
const [selectedCompany , setSelectedCompany] = useState('')
const [insuranceId , setInsuranceId] = useState(0)
const [imageLink,setImageLink] = useState('')
const [allInsurance , setAllInsurance] = useState([])
const [remarkDetails,setRemarkDetails] = useState('')
const [insuranceFrontFile , setInsuranceFrontFile] = useState({})
  const {currentDriverPersonaldata} = useSelector((state) => state.driverPersonal)
  const [driverId,setDriverId] = useState(0)

  const [write,setWrite] = useState()
  

useEffect(()=>{   
  let isWrite = checkPrivilage('Drivers','CREATE')
  setWrite(isWrite)

   },[])


  useEffect(()=>{
    setDriverId(currentDriverPersonaldata?.driverId)
    },[currentDriverPersonaldata])


const handleFormSubmit =()=>{
  setSubmitHit(true);
    const formData = new FormData();

    formData.append('registrationNumber',VehicleRegNo);
    formData.append('insuranceCompanyName', selectedCompany);
    formData.append('insuranceNumber', insuranceNumber);
    formData.append('expiryDate', selectedExp);
    formData.append('driverId', driverId);
    formData.append('insuranceId', insuranceId);
    // formData.append('insuranceId',selectedInsuranceId); 
    // formData.append('vocationalLicenceNumber', vocationalLicenceNumber);
    // formData.append('vocIssueDate',vocationalIssueDate );
    insuranceFrontFile && formData.append('InsuranceFrontImage',insuranceFrontFile.file);


    ApiCall("POST", "/trip-service/v1/admin/insurance/create/driver/insurance", formData).then((data)=>{
      if(data.status === 'success'){
   
        setOnSuccess((i) => !i )
        setOnApiSuccess("Success")

        
        onSectionComplete(true,'insurance')
        onButtonClick('pagefive')
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
    
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });


}


const handleSelect = (label,data)=>{
          
    if(label === 'Insurance Company'){
           setSelectedCompany(data?.name)
           setInsuranceId(data?.id)
    }
  
  }


  useEffect(()=>{
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
  
      },
    };


//   /pay-service/v1/common/insurance/list     

const apiUrlTwo = `${baseUrl}/pay-service/v1/common/insurance/list`;
axios.get(apiUrlTwo, config).then(response => {

    if(response?.data?.result){
      const insuranceData = response?.data?.result;

    const filterData = insuranceData?.map((eachItem)=>{
        return {id:eachItem?.ID,name:eachItem?.Name}
    })
    setAllInsurance(filterData)
  
    
    }
    
      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });



},[driverId,onSuccess])










//FUNCTIONS

const handleDateSelection =(date)=>{
    setSelectedExp(date)
   }

   const handleImageFiles = (files)=>{
   setInsuranceFrontFile(files)
  
    }




  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
    <Stack spacing={2}>
   
   {/* FIRST ROW */}
   
   <Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
   <Box width={isMatch?'100%':'32.2%'}>
   <SelectField  isError={false} onSelecting={handleSelect} Data={allInsurance} sideLabel={'Insurance Company'} placeHolder={'choose'} />
   </Box>
   <Box width={isMatch?'100%':'32.2%'} >
   
   <InputField  isError={false} ErrNote={""} InChange={(e) => setInsuranceNumber(e.target.value)}   sideLabel={"Insurance Number"} placeHolder={"Insurance Number"} />
   </Box>
   </Stack>
   
   {/* SECOND ROW */}
   
   <Box width={isMatch?'100%':'32.2%'} >
   <DatePickerComponent disablePast={true} isError={false}  handleSelection={handleDateSelection} sideLabel={'Expiry Date'}/>
   </Box>
   
   {/* THIRD ROW */}
   
   <Box width={isMatch?'100%':'32.2%'} >
   <ImageSelector sideLabel={'Commercial Insurance'}  isError={false} handleFileChange ={handleImageFiles} />
   </Box>
   
   {/* <Box width={isMatch?'100%':'32.2%'} >
   <InputField  isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
   </Box> */}


   <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Stack alignItems={'center'} spacing={1} direction={'row'}>
    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>
    {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagefive"} goBack={"pagefour"} /> */}
    </Stack>
   
    </Stack>

   </Box>
  )
}

export default InsuranceForm
