import { FormControl, FormLabel, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react'

function PasswordField({sideLabel,placeHolder,InChange,isError,ErrNote}) {
const [show,setShow] = useState(false)

{/* <TextField
required
error={isNotValidate}
helperText={isNotValidate ? errorNote : null}
onChange={(e) => setPassword(e.target.value)}
variant="filled"
margin="none"
size="small"
sx={{
  width: "70%",
}}
label="Password"
/> */}

  return (
    <FormControl
      required
      
      component="fieldset"
      sx={{ width: "70%"}}
      variant="standard"
    >
        
      <FormLabel component="legend">{sideLabel}</FormLabel>
      <OutlinedInput 
      sx={{borderRadius:'4px',height:'46px'}}
      error={isError}
      helperText={isError ? ErrNote : null}
       type={show ? 'text' : 'password' }
        fullWidth 
        onChange={(e) => InChange(e)}
        placeholder={placeHolder}
        endAdornment={
            <InputAdornment position='end' >
                <IconButton size='small' onClick={() => setShow((i) => !i)} >
             {show ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                </IconButton>
            </InputAdornment>
        }
         />
      {/* <MyFormHelperText />   use when a helper text is required */}
    </FormControl>
  )
}

export default PasswordField
