import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { ModalPopup } from '../Forms/ImageSelectorUpdate';

export default function DocumentSingleCard({singleData}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const showModal = () => setIsOpen(true)

  const handleDownload = () => {
    // Create a hidden anchor element
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    
    // Set the href and download attributes
    anchor.href = singleData?.image; // Replace with the image URL
    anchor.download = "image.jpg"; // Specify the default filename

    // Append the anchor element to the document
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the document
    document.body.removeChild(anchor);
  };
  
  return (
    <>
    <Card sx={{ minWidth: 345  , marginRight: '15px'  }}>
      <CardActionArea>
        <CardMedia
        onClick={showModal}
          component="img"
          height="120"
          image={singleData?.image}
          alt="card image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {singleData?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">{`Issue date  ${singleData?.date}`}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button sx={{textTransform:'none'}} onClick={handleDownload} on size="small" color="primary">
          Download
        </Button>
      </CardActions>
    </Card>
        {isOpen && (
          <ModalPopup
            src={singleData?.image}
            alt="snow"
            caption={singleData?.title}
            onClose={() => setIsOpen(false)}
            label={singleData?.title}
          />
        )}
        </>
  );
}