import { driverPersonalConstants } from "../Constants/Constants"; 

const initialDriverPersonalState = {
    currentDriverPersonaldata: {},
};

export const DriverPersonalReducer = (state = initialDriverPersonalState, action) => {
    switch (action.type) {
        case driverPersonalConstants.INSERT_DRIVER_PERSONAL_DATA:
            return { ...state, currentDriverPersonaldata: action.payload };
        default:
            return state;
    }
};