import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

function CreatePageHead({title,BreadList,BackPath}) {

  const navigate = useNavigate()

  return (
    <Box alignItems={"center"} width='100%' display={'flex'} justifyContent={"space-between"}>
    <Stack direction={'row'} spacing={1} alignItems={'center'} >
        <IconButton onClick={() =>{BackPath ?  navigate(BackPath) : navigate(-1)}} > <KeyboardBackspaceIcon/></IconButton>

    <Typography variant='h6'>{title}</Typography>
    </Stack>
   
    <Box marginRight={5}>
    <BreadCrumbs Breadcrumbz={BreadList} />
    </Box>
    </Box>
  )
}

export default CreatePageHead
