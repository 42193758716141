import { TextField } from '@material-ui/core'
import { FormControl, FormLabel, OutlinedInput, Stack, Typography } from '@mui/material'
import React from 'react'

function TextArea({sideLabel,placeHolder,InChange,isError,ErrNote,disable,Type,Note}) {
    const handleKeyDown = (event) => {
        // Prevent digits (0-9) from being entered
        if (/^\d+$/.test(event.key)) {
          event.preventDefault();
        }
      };
  return (
    <FormControl
    required
    component="fieldset"     
    sx={{ width: "100%"}}
    variant="standard"
  >
      <Stack direction={'row'}>
      <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
     { <Typography color={'red'}>*</Typography>}
      </Stack>
   
      <TextField
       multiline
       variant='outlined'
     //  rows={6}
       maxRows={10}
       onKeyDown={Type === 'text' ? handleKeyDown : null}
       type={Type}
       disabled={disable}
       error={isError}
       helperText={isError ? ErrNote : null}
       onChange={(e) => InChange(e)}
       sx={{
       borderRadius: '4px',
       height: '46px',
       "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
       WebkitAppearance: "none",
       margin: 0,
  },
}}
fullWidth
placeholder={placeHolder}
/>
    {/* <MyFormHelperText />   use when a helper text is required */}
    {<Typography variant="caption" color={'red'}>{Note}</Typography>}
  </FormControl>
  )
}

export default TextArea
