import axios from "axios";
import { baseUrl } from "../../RequestMethord";
import moment from 'moment';
import { startOfWeek, addWeeks, endOfWeek, startOfMonth, endOfMonth, isAfter, format } from 'date-fns';

export const getBalance = async (driverId) => {
    try {
      const apiUrlTwo = `${baseUrl}/pay-service/v1/admin/driver/account/balance`;
      const TOKEN = localStorage.getItem("jwtToken");
  
      const config = {
        headers: {
          ['x-user-id']: driverId,
          'Authorization': `Bearer ${TOKEN}`,
          ['x-app-type']: 'AdminApp',
        },
      };
  
      const response = await axios.get(apiUrlTwo, config);
  
      const personalData = response?.data?.result;
   
      
      if (response?.data?.result) {
        if (personalData?.status === 'active') {
          return { balance: personalData?.balances[0]?.balance, ID: driverId };
        } else {
          return { balance: personalData, ID: driverId };
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the API request here
      console.error(error);
      throw error; // Rethrow the error to let the caller handle it
    }
  };


  export function convertToUtcLastMinute(inputDate) {
    // Parse input date
    const dateObject = new Date(inputDate);
  
    // Set time to the last minute of the day
    const utcDateTime = new Date(
      Date.UTC(
        dateObject.getUTCFullYear(),
        dateObject.getUTCMonth(),
        dateObject.getUTCDate(),
        23, 59, 59
      )
    );
  
    // Format as ISO 8601 string
    const utcString = utcDateTime.toISOString();
  
    return utcString;
  }
  
  // Example usage
  const inputDate = "2023-12-06";
  const utcDateString = convertToUtcLastMinute(inputDate);
  console.log("UTC Date and Time:", utcDateString);
  




  export const getUtcTime = (currentDate) => {
    // India Standard Time (IST) offset: UTC+5:30
    const utcOffset = 5.5 * 60 * 60 * 1000; // UTC+5:30
  
    // Convert to UTC by adding the UTC offset
    const utcDate = new Date(currentDate?.getTime() + utcOffset);
    const formattedUtcDate = utcDate && utcDate?.toISOString();
  

    
    // Print the UTC date
    return formattedUtcDate;
  }


  export const getYearDate = (year) => {
    const startDate = new Date(year, 0, 1); // January 1st of the given year
    const endDate = new Date(year, 11, 31); // December 31st of the given year
  
    return {startDate: getUtcTime(startDate), endDate:getUtcTime(endDate) };
  };

  export const getMonthDate = (year, monthShort) => {
    // Convert the short month name to a numerical month (0-11)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = monthNames.indexOf(monthShort);
  
    if (monthIndex === -1) {
      return null; // Invalid month short form
    }
  
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0); // Setting the day to 0 gives the last day of the previous month
    const numWeeks = Math.ceil((endDate.getDate() + startDate.getDay()) / 7);
  
    return {startDate: getUtcTime(startDate), endDate:getUtcTime(endDate) ,numWeeks };
  };



 export function getWeekDate(year, month, week) {
if(year?.length > 2 && month?.length > 2 && week?.length > 2){
// console.log(`year : ${year} -- month : ${month} -- week : ${week}`)

const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',
  'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const monthIndex = months.indexOf(month);

if (monthIndex === -1 || !year || !week) {
  return "Invalid input";
}

const firstDay = new Date(year, monthIndex, 1);
const lastDay = new Date(year, monthIndex + 1, 0);

const totalDaysInMonth = lastDay.getDate();

const weekNumber = parseInt(week.split(' ')[1]);

if (weekNumber < 1 || weekNumber > 6) {
  return "Invalid week number";
}

const daysInWeek = 7;
const weekStartDate = 1 + (weekNumber - 1) * daysInWeek;
let weekEndDate = weekStartDate + daysInWeek - 1;

// Adjust for the last week of the month
if (weekEndDate > totalDaysInMonth) {
  weekEndDate = totalDaysInMonth;
}


const datesRange = [];
for (let day = weekStartDate; day <= weekEndDate; day++) {
  datesRange.push(day);
}


return {
  startDate: getUtcTime(new Date(`${year}-${monthIndex + 1}-${weekStartDate}`)),
  endDate: getUtcTime(new Date(`${year}-${monthIndex + 1}-${weekEndDate}`)),
  daysRange: datesRange
};

  
}
}



export function getDayDates(year, months, day) {

  const monthAbbreviations = {
    'Jan': 0,
    'Feb': 1,
    'Mar': 2,
    'Apr': 3,
    'May': 4,
    'Jun': 5,
    'Jul': 6,
    'Aug': 7,
    'Sep': 8,
    'Oct': 9,
    'Nov': 10,
    'Dec': 11
  };
  

  const monthAbbreviation = months; // Change this to the desired month abbreviation
 
  
  // Convert the month abbreviation to a numeric value
  const month = monthAbbreviations[monthAbbreviation];
  
  if (month !== undefined) {
    const date = new Date(parseInt(year), month, day);
    const endDate = new Date (date)
    endDate.setHours(23, 59, 59, 999)

  
    return {startDate:getUtcTime(date),endDate:getUtcTime(endDate)}
  } else {
    console.error('Invalid month abbreviation:', monthAbbreviation);
  }


}






export function transformMonthData(inputArray) {
  // Initialize an array to store the counts and months
  const counts = new Array(12).fill(0);
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Loop through the inputArray
  for (const item of inputArray) {
    const dateParts = item.date.split('-');
    const monthIndex = parseInt(dateParts[1], 10) - 1;
    const count = item.count;

    counts[monthIndex] = count;
  }

  // Return the nested array
  return [counts, months];
}



export function transformDataWithYearRange(inputArray) {
  // Initialize an array to store the counts and years
  const counts = new Array(7).fill(0);
  const yearRange = [];

  // Extract the year from the date in the inputArray
  const currentYear = new Date().getFullYear();
  for (let i = currentYear - 2; i <= currentYear + 4; i++) {
    yearRange.push(String(i));
  }

  // Loop through the inputArray
  for (const item of inputArray) {
    const year = item.date;
    const count = item.count;

    const yearIndex = yearRange.indexOf(year);
    if (yearIndex !== -1) {
      counts[yearIndex] += count; // Aggregate the counts for the same year
    }
  }

  // Return the nested array
  return [counts, yearRange];
}

export const transformSingleDay =(mydata)=>{

const counts = mydata?.map((eachData)=>{
  return eachData?.count
})

const dates = mydata?.map((eachData)=>{
  const myDate = new Date(eachData?.date);
const time = myDate.toLocaleTimeString('en-US', { hour12: false })
  return time
})

return [counts,dates];

}


export function transformDataWithDays(inputArray) {
  // Initialize an array to store the counts and dates
  const counts = new Array(31).fill(0); // Assuming a maximum of 31 days
  const dates = [];

  // Loop through the inputArray
  for (const item of inputArray) {
    const dateParts = item.date.split('-');
    const day = parseInt(dateParts[2], 10);
    const count = item.count;
    const monthIndex = parseInt(dateParts[1], 10) - 1;

    if (day >= 1 && day <= 31) {
      counts[day - 1] = count;
      dates.push(day);
    }
  }

  // Create a range of dates from 1 to 31
  const dateRange = Array.from({ length: 31 }, (_, i) => i + 1);

  // Return the nested array
  return [counts, dateRange];
}


export function processData(dateArray, numbersArray) {
  // Create an object to map dates to their counts
  const dateCountMap = {};
  
  // Populate the map using the first argument (dateArray)
  for (const item of dateArray) {
    dateCountMap[item.date] = item.count;
  }
  
  // Initialize the result array with zeros
  const result = Array.from({ length: numbersArray.length }, () => 0);

  // Populate the result array with counts based on matching dates
  for (let i = 0; i < numbersArray.length; i++) {
    const date = `2023-10-${numbersArray[i] < 10 ? '0' : ''}${numbersArray[i]}`;
    if (dateCountMap[date] !== undefined) {
      result[i] = dateCountMap[date];
    }
  }

  return [result,numbersArray];
}




export function processNestedObject(nestedObject) {
  const resultArray = [];

  for (const moduleID in nestedObject) {
    
    if (nestedObject.hasOwnProperty(moduleID)) {
      const moduleData = nestedObject[moduleID];
      const moduleName = moduleData?.name;
      const create = moduleData?.data?.create ? '1' : '0';
      const read = moduleData?.data?.read ? '1' : '0';
      const update = moduleData?.data.update ? '1' : '0';
      const deletePermission = moduleData?.data?.delete ? '1' : '0';

      const permissions = create + read + update + deletePermission;

      resultArray.push({
        moduleName: moduleName,
        moduleID: parseInt(moduleID),
        permission: permissions,
      });
    }
  }

  return resultArray;
}



export function matchAndAddDataField(firstArray, secondArray) {
  return secondArray.map((secondItem) => {
    const matchedItem = firstArray.find((firstItem) => firstItem.ModuleID === secondItem.ID);

    if (matchedItem) {
      // Map the permission string to an array of true/false values
      const permissions = matchedItem.Permission.split('').map((char) => char === '1');
      
      return {
        ...secondItem,
        data: permissions,
      };
    }

    return secondItem;
  });
}

export function formatRolePermissions(roleData) {
  if (!Array.isArray(roleData) || roleData.length === 0) {
    return [];
  }

  const formattedPermissions = roleData[0].Role.RolePermissions.map((permission) => ({
    module: permission.Modules.Name,
    permission: permission.Permission.split('').join(''),
  }));

  return formattedPermissions;
}

export function filterMenuItems(menuItems, permissions) {
  if(permissions?.length > 0){
  return menuItems
    .map((menuItem) => {
      if (menuItem.childrens && menuItem.childrens.length > 0) {
        menuItem.childrens = menuItem.childrens
          .filter((child) => {
            const childPermission = permissions.find(
              (permission) => permission.module === child.name
            );
            return !childPermission || childPermission.permission !== '0000';
          });

        return menuItem.childrens.length > 0 ? menuItem : null;
      } else {
        const permission = permissions.find(
          (perm) => perm.module === menuItem.name
        );

        return !permission || permission.permission !== '0000' ? menuItem : null;
      }
    })
    .filter((item) => item !== null);
  }else {
    return [];
  }
}




export function newformatRolePermissions(roleData, countryId) {
  if (!Array.isArray(roleData) || roleData.length === 0 || countryId == null) {
    // Return an array with all modules having permission "0000"
    const allModules = roleData[0].Role.RolePermissions.map((permission) => ({
      module: permission.Modules.Name,
      permission: "0000",
    }));
    return allModules;
  }

  const countryRoles = roleData.filter((role) => role.Role.CountryID === countryId);

  if (countryRoles.length === 0) {
    // If no roles match the specified countryId, return an array with all modules having permission "0000"
    const allModules = roleData[0].Role.RolePermissions.map((permission) => ({
      module: permission.Modules.Name,
      permission: "0000",
    }));
    return allModules;
  }

  const formattedPermissions = countryRoles.reduce((acc, role) => {
    role.Role.RolePermissions.forEach((permission) => {
      const existingPermission = acc.find((formatted) => formatted.module === permission.Modules.Name);

      if (existingPermission) {
        // Perform a bitwise OR operation to combine permissions
        existingPermission.permission = (parseInt(existingPermission.permission, 2) | parseInt(permission.Permission, 2)).toString(2).padStart(4, '0');
      } else {
        // If the module doesn't exist in the accumulator, add it
        acc.push({
          module: permission.Modules.Name,
          permission: permission.Permission,
        });
      }
    });

    return acc;
  }, []);

  // Convert permissions back to a string with 4 digits
  formattedPermissions.forEach((permission) => {
    permission.permission = permission.permission.padStart(4, '0');
  });

  return formattedPermissions;
}



export const formatteStringDate=async(rowDate)=>{
  try{

    const year = await rowDate.getFullYear();
    const month =await (rowDate.getMonth() + 1).toString().padStart(2, '0');
    const day =await rowDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate

  }catch(error){

  }

}


export function  formatDates (startsDate, endsDate) {
  // Convert strings to Date objects
  const startDate = new Date(startsDate);
  const endDate = new Date(endsDate);

  // Set time to the beginning of the day for startDate
  startDate.setHours(0, 0, 0, 0);

  // Set time to the end of the day for endDate
  endDate.setHours(23, 59, 59, 999);

  // Format startDate
  const formattedStartDate = formatDate(startDate);

  // Format endDate
  const formattedEndDate = formatDate(endDate);

  return { formattedStartDate, formattedEndDate };
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function padZero(value) {
  return value < 10 ? `0${value}` : value;
}





export const convertToCurrentCountryTime = (utcDateTime) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = new Date(utcDateTime);
  const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));

  // Format date and time without seconds in 24-hour format
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
  const formattedDate = localDate.toLocaleString('en-US', options);

  return formattedDate;
}




