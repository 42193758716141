import { Box, Button, Stack, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react'
import FormNav from '../../../Components/Forms/FormNav'
import changeIcon from '../../../../Assets/Forms/icons8-change-48.png'
import LoadingButton from '@mui/lab/LoadingButton';

function KycDetails({WalletData,confirmSubmit,CancelSubmit,changedItems,loadingStatus}) {

console.log(changedItems , "<-##changeddd itemssss ]")
  const useStyles = makeStyles(() => ({
    customScrollbar: {
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#DEEAED',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#A5A5A5',
        borderRadius: '4px',
      },
    },
  }))



  console.log(WalletData.EntityData,"<---walleetdataaaaaaaaaaa-------////")
  // WalletData?.EntityData && 
  return  (
    <Box>
    <Stack spacing={4}>

    <FormNav warning={true} Description={WalletData?.EntityData ? 'Confirm the following details .':'Are you sure you want to confirm.?'} title={'KYC Request'}/>
{/* FORMS */}
{ WalletData?.EntityData && <Stack  height={'450px'}   sx={{
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',  // Adjust the width as needed
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',  // Change the thumb color
      borderRadius: '5px',     // Add rounded corners to the thumb
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',  // Change the track color
    },
    scrollbarWidth: 'thin',  // Firefox scrollbar width
    scrollbarColor: 'lightgray transparent',  // Firefox scrollbar colors
  }} spacing={1}>
{/* ROW ONE */}

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>First Name</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.name?.firstName}</Typography>
    {

changedItems?.firstName &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.firstName?.valueInObj2}</Typography>
    </>
      }
    </Stack>
   

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Last Name</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.name?.lastName}</Typography>
    {

changedItems?.lastName &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.lastName?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>DOB</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.birthData?.dateOfBirth}</Typography>
    {

changedItems?.dateOfBirth &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.dateOfBirth?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Email</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.email}</Typography>
    {

changedItems?.email &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.email?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>NRIC No</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.identificationData?.number}</Typography>
    {

changedItems?.identificationNumber &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.identificationNumber?.valueInObj2}</Typography>
    </>
      }
    </Stack>



    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Account Holder ID</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.AccountHolderID}</Typography>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Legal Entity ID</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.LegalEntityID}</Typography>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Phone</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.phone?.number}</Typography>
    {

changedItems?.number &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.number?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>City</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.residentialAddress?.city}</Typography>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Country</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.residentialAddress?.country}</Typography>
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Postal Code</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.residentialAddress?.postalCode}</Typography>
    {

changedItems?.postalCode &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.postalCode?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Street</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.individual?.residentialAddress?.street}</Typography>
    {

changedItems?.street &&

    <>
    <img style={{transform: 'rotate(90deg)'}} width={20} height={20} src={changeIcon} alt='icon'/>
    <Typography color={'red'} variant='caption'>{changedItems?.street?.valueInObj2}</Typography>
    </>
      }
    </Stack>

    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>Reference</Typography>
    <Typography color={'grey'} variant='caption'>{WalletData?.EntityData?.reference}</Typography>
    </Stack>

{/* walletData?.NricBackImagePaths  walletData?.NricFrontImagePaths   */}


    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>NRIC Front Image</Typography>
    <a href={WalletData?.NricFrontImagePaths ||"https://api.arya.ai/images/samples/nric/2.jpg"} target="_blank" rel="noopener noreferrer">
   <img style={{borderRadius:'8px'}} width={'200px'} height={'100px'} alt='nricimage' src={WalletData?.NricFrontImagePaths ||"https://api.arya.ai/images/samples/nric/2.jpg"}/>
   </a>
    </Stack>


    <Stack alignItems={'center'} direction={'row'} spacing={2}>
    <Typography fontWeight={'bold'} variant='p'>NRIC Back Image</Typography>
    <a href={WalletData?.NricBackImagePaths ||"https://api.arya.ai/images/samples/nric/2.jpg"} target="_blank" rel="noopener noreferrer">
   <img style={{borderRadius:'8px'}} width={'200px'} height={'100px'} alt='nricimage' src={WalletData?.NricBackImagePaths ||"https://api.arya.ai/images/samples/nric/2.jpg"}/>
   </a>
    </Stack>

    


    </Stack>}

<Stack alignItems={'center'} direction={'row'} spacing={2}>
<Box pt={2} >

<LoadingButton loading={loadingStatus} sx={{textTransform:"none",backgroundColor:!loadingStatus && "#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} onClick={confirmSubmit}   variant="outlined">
        Confirm
      </LoadingButton>
{/* <Button onClick={confirmSubmit}  sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Confirm</Button> */}
</Box> 
<Box pt={2} >
<Button onClick={CancelSubmit}  sx={{textTransform:"none",backgroundColor:"#e84118",color:'white',borderRadius:"6px",'&:hover': {color: 'black'}}} >Cancel</Button>
</Box> 
</Stack>




    </Stack>
    

   </Box>
  )
}

export default KycDetails
