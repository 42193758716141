import { Box, FormControl, FormLabel, OutlinedInput, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InputField from '../../../../Components/Forms/InputField';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import SnackBars from '../../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import { useSelector } from 'react-redux';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import SelectField from '../../../../Components/Forms/SelectField';
import { LinkedCamera } from '@mui/icons-material';
import ApiCall from '../../../../../Helper/ApiCalls';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function ServiceForm({onButtonClick,onSectionComplete}) {

  const [liketoDo,setLiketoDO] = useState([])
  const [selectedServiceId , setSelectedServiceId] = useState('')
  const [selectedVehicleTypeId , setSelectedVehicleTypeId] = useState('')
  const [vehicleData ,setVehicleData] = useState([])
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });

      const [write,setWrite] = useState()
  

      useEffect(()=>{   
        let isWrite = checkPrivilage('Drivers','CREATE')
        setWrite(isWrite)
    
         },[])


//RENDERS


//RENDERS AND API CALLS

useEffect(()=>{
  const apiUrl = `${baseUrl}/trip-service/v1/app/trip/rider/service`;
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      // ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  axios.get(apiUrl, config).then(response => {

      if(response?.data?.result){
    const vehicleData = response?.data?.result   
    
    let mainFilter = vehicleData?.map((eachData,index)=>{

      return{id:eachData?._id,name:eachData?.Name,vehicleData:eachData?.VehicleTypeIDS}
    })
   setLiketoDO(mainFilter)
  
      
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });

},[])



const handleSelect = (label,data)=>{
          
  if(label === 'What would you like to do?'){
         setSelectedServiceId(data?.id)
         let filtered = data?.vehicleData?.map((eachItems)=>{
             return {id:eachItems?._id , name:eachItems?.Type}
         })

         setVehicleData(filtered)
  }else if(label === 'What vehicle are you driving?'){
    setSelectedVehicleTypeId(data?.id)
    // currentVehicle(data?.id)
    localStorage.setItem("VehicleId", data?.id)
    localStorage.setItem("vehicleName",data?.name)
  }


}





const handleFormSubmit =()=>{
  setSubmitHit(true);

  let sendData = {
    driverId: driverId,
    rideServiceId: selectedServiceId,
    vehicleTypeId: selectedVehicleTypeId
  }

  ApiCall("POST", "/trip-service/v1/admin/driver/create/ride/service", sendData).then((data)=>{
    if(data.status === 'success'){
      console.log(data?.result , "<----- data resulllttttttttt <-=--------")
      setOnApiSuccess("Success")
      setOnSuccess((i) => !i )
  
      onButtonClick('pagethree')
      onSectionComplete(true,'service')
      

    }
})
.catch((error) => {
   setOnFailed((i) => !i)
  
    if(error.message){
      setOnApiFailed(error.message)
      console.log(error , "<----errrrrrr")
    }else{
      setOnApiFailed("Something went wrong.!")
    }


});

}


//  STATES AND VARIABLES
 const [submitHit,setSubmitHit] = useState(false)
 const [onApiSuccess , setOnApiSuccess] = useState('')
 const [onApiFailed , setOnApiFailed] = useState('')
 const [onSuccess, setOnSuccess] = useState(false)
 const [onFailed , setOnFailed] = useState(false)
 const [driverId,setDriverId] = useState(0)
const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
const [currentVehicle , setCurrentVehicle] = useState('')
const [currentServiceName , setCurrentServiceName] = useState('')
const {currentDriverPersonaldata} = useSelector((state) => state.driverPersonal)

useEffect(()=>{
setDriverId(currentDriverPersonaldata?.driverId)
},[currentDriverPersonaldata])

console.log(currentDriverPersonaldata , "<---current driver daaaaataaaaa")

  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
    <Stack spacing={2}>
<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch?'100%':'25%'}>
<SelectField  isError={false} onSelecting={handleSelect} Data={liketoDo} sideLabel={'What would you like to do?'} placeHolder={'What would you like to do?'} />
</Box>

    <Box width={isMatch ? "100%" : "25%"} >
    <SelectField  isError={false} onSelecting={handleSelect} Data={vehicleData} sideLabel={'What vehicle are you driving?'} placeHolder={'What vehicle are you driving?'} />
    </Box>


</Stack>
<SnackBars  isProgress={true} submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Stack alignItems={'center'} spacing={1} direction={'row'}>
    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>
    {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagethree"} goBack={"pageone"} /> */}
    </Stack>
</Stack>



</Box>
  )
}

export default ServiceForm
