import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../../Components/Table/DataTable';
import ApiCall from '../../../../Helper/ApiCalls';
import { useSelector } from 'react-redux';
import RideFilter from '../../RideServiceManagement/RideFilter';
import LtaFilter from './LtaFilter';
import { getUtcTime } from '../../../Components/MultiFunctions/MultiFunctions';
import Papa from 'papaparse';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import dayjs from 'dayjs';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "passengerId",
      label: "Passenger ID",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "passengerName",
      label: "Passenger Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "totalTrips",
      label: "Total Trips",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "cancelledTrips",
      label: "Cancelled Trips",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "cancelledByDriver",
      label: "Cancelled By Driver",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "cancelledByPassenger",
      label: "Cancelled By Passenger",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "rating",
      label: "Rating",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  
   
    // {
    //   id: "actionPending",
    //   label: "Actions",
    //   minWidth: 170,
    //   align: "left",
    //   sort: false,
    // },
  ]; 



function PassengerReports() {
  
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const navigate = useNavigate()
    const [startUtc,setStartUtc] = useState()
    const [endUtc,setEndUtc] = useState()
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [initialRangeData , setInitialRangeData] = useState([])

    const [initialStart,setInitialStart] = useState()
    const [initialEnd , setInitialEnd] =useState()

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      
      let isWrite = checkPrivilage('Reports','READ')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Reports','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Reports','DELETE')
       setDeletePer(isDelete)
       },[])



    useEffect(() => {
      // Calculate the current UTC time and set it in the state
      const currentDate = new Date();
      const currentUtc = getUtcTime(currentDate);
      setStartUtc(currentUtc);
      setEndUtc(currentUtc)
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      setInitialStart(startOfMonth)
      setInitialEnd(currentDate)

    }, []);

    useEffect(()=>{

      if(initialStart&& initialEnd){
        setInitialRangeData([dayjs(initialStart),dayjs(initialEnd)])
      }

    },[initialStart,initialEnd])

    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
      if(startUtc && endUtc){
     ApiCall("GET", `/trip-service/v1/admin/report/passenger?countryId=${CountryID}&&startDate=${startUtc}&&endDate=${endUtc}`, null).then((data)=>{
     if(data?.result){    

          const passengerData = data?.result?.tripDetails
          setTotalRows(passengerData?.length)
          console.log(passengerData,"<------|||ttt||||||||")
         const filteredData =  passengerData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
           
         let eachPassenger = eachItem?.user
         console.log(eachPassenger,"<====the main  eachh passemgerrrrrrrrrrrrrrr")
          let items = {
                _id:eachItem._id,
                id:index+1+currentOffset,
                passengerId:eachPassenger?.regNo,
                passengerName:eachPassenger?.FullName,
                totalTrips:eachPassenger?.totalRide,
                cancelledTrips:eachPassenger?.rideCancelled,
                phone:eachPassenger?.PhoneNumber,
                rating:eachPassenger?.rating,
                cancelledByPassenger:eachPassenger?.rideCancelledByPassanger,
                cancelledByDriver:eachPassenger?.rideCancelledByDriver

               }
      
               return items;
         })
      
         
      
         setRows(filteredData)
         setTableData(filteredData);
       }else{
        setRows([])
        setTableData([])
       } })


      }
      },[success,startUtc,endUtc,currentPage])




    // useEffect(()=>{
    //     setRows(
    //       tableData.filter((item) =>
    //       item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
    //         ))
    //      },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/wallet-details/${id}`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
     console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
  }


    const handleColumnFilter = (items, searchText) => {



        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }
      const handleColumnDateFilter = (startsUtc, endsUtc) => {
        setStartUtc(startsUtc)
        setEndUtc(endsUtc)
        setInitialRangeData([])
  
        };


        const handleExport =()=>{
          if(rows?.length > 0){
      
            console.log(rows)
      
            const blob = new Blob([Papa?.unparse(rows)], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'PASSENGER_REPORT';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
      
          }
         
        
         } 





    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };
    

      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)   
         }




  return (
<Box>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
      needButton={true}
      handlingPage={handlePageChange}
      totalCount={totalRows}
      DeletePermission={deletePer}
      ButtonPermission={write}
      EditPermission={edit} 
        modalClicked={handleExport}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/export'}
        ButtonLabel={'Export'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={ <LtaFilter currentDateRange={initialRangeData} removerIcon={true} CloseModal={handleFilterBar} FilterColumn={handleColumnDateFilter} />}
      />
</Box>
  )
}

export default PassengerReports
