import { Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import editIcon from '../../../Assets/Actions/Group 15.svg'
import eyeIcon from '../../../Assets/Actions/Group 16.svg'
import deleteIcon from '../../../Assets/Actions/Group 17.svg'
import saveIcon from '../../../Assets/Actions/Group 18.svg'

function ActionsButton({deleteStatus,handleServiceDelete,rowData,handleEditServices,avoidDelete,editStatus}) {

   const handleDelete = () => {
      // Perform the same action as the switch change
      handleServiceDelete(rowData._id, { ...rowData});
    };

    const handleEdit = ()=>{
      handleEditServices(rowData._id, { ...rowData})
    }


  return (
<Box>
   <Stack direction={'row'} spacing={1} >
   <IconButton  disabled={!editStatus} onClick={handleEdit}>
   {editStatus && <img src={editIcon} alt='edit'/>}
 </IconButton>
{/* <IconButton>
    <img src={eyeIcon} alt='edit'/>
 </IconButton> */}
 {
   !avoidDelete &&

<IconButton disabled={!deleteStatus}  onClick={handleDelete} >
{deleteStatus && <img src={deleteIcon} alt='delete'/>}
</IconButton>

 }


   </Stack>
   {/* <IconButton>
    <img src={editIcon} alt='edit'/>
 </IconButton> */}
{/* <IconButton>
    <img src={eyeIcon} alt='edit'/>
 </IconButton> */}
 {/* <IconButton  onClick={handleDelete} >
    <img src={deleteIcon} alt='edit'/>
 </IconButton> */}
 {/* <IconButton>
    <img src={saveIcon} alt='edit'/>
 </IconButton> */}

</Box>
  )
}

export default ActionsButton





































// import * as React from 'react';
// import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiPaper-root': {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color:
//       theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//     boxShadow:
//       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     '& .MuiMenu-list': {
//       padding: '4px 0',
//     },
//     '& .MuiMenuItem-root': {
//       '& .MuiSvgIcon-root': {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       '&:active': {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity,
//         ),
//       },
//     },
//   },
// }));

// export default function CustomizedMenus() {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <Button
//       sx={{
//         borderRadius:2,
//         backgroundColor:"#1B2124",
//         width:"70%",
//         p:'7px',
//       }}
//         id="demo-customized-button"
//         aria-controls={open ? 'demo-customized-menu' : undefined}
//         aria-haspopup="true"
//         aria-expanded={open ? 'true' : undefined}
//         variant="contained"
//         disableElevation
//         onClick={handleClick}
//         endIcon={<MoreVertIcon />}
//       >
//         Actions
//       </Button>
//       <StyledMenu
//         id="demo-customized-menu"
//         MenuListProps={{
//           'aria-labelledby': 'demo-customized-button',
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//       >
//         <MenuItem onClick={handleClose} disableRipple>
//           <EditIcon />
//           Edit
//         </MenuItem>
//         <MenuItem onClick={handleClose} disableRipple>
//           <FileCopyIcon />
//           Duplicate
//         </MenuItem>
//         <Divider sx={{ my: 0.5 }} />
//         <MenuItem onClick={handleClose} disableRipple>
//           <ArchiveIcon />
//           Archive
//         </MenuItem>
//         <MenuItem onClick={handleClose} disableRipple>
//           <MoreHorizIcon />
//           More
//         </MenuItem>
//       </StyledMenu>
//     </div>
//   );
// }