import { Alert, Box, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'

function SnackBars({Status,successNote,submitStatus,isProgress}) {
  const [open, setOpen] = React.useState(false);

useEffect(()=>{
  
 submitStatus && setOpen(true);
},[Status])

  const handleClick = () => {
  
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
   !isProgress && window.location.reload(true);
  };


  return (
  <Box>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {successNote}
        </Alert>
      </Snackbar>

  </Box>
  )
}

export default SnackBars
