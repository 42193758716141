import { Box, Grid, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveAndVerify from '../../../../Components/Forms/SaveAndVerify'
import SelectField from '../../../../Components/Forms/SelectField'
import { PH_CODE } from '../../../../FakeData/FakeData'
import InputField from '../../../../Components/Forms/InputField'
import GenderSelect from '../../../../Components/Forms/GenderSelect'
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent'
import  ImageSelectorUpdate from '../../../../Components/Forms/ImageSelectorUpdate'
import { useParams } from 'react-router-dom'
import ApiCall from '../../../../../Helper/ApiCalls'
import axios from 'axios'
import { baseUrl } from '../../../../RequestMethord'
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../../Components/Forms/SelectFieldUpdate'
import NotifiProgress from '../../../../Components/Forms/NotifiProgress'
import ErrorNotify from '../../../../Components/Forms/ErrorNotify'
import validator from 'validator';
import { checkPrivilage } from '../../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'
const nric = require('nric');

function PersonlaDetailsVerification() {
  const [onSuccess , setOnSuccess] = useState(false)
  const [onSuccessv , setOnSuccessv] = useState(false)
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

//  STATES AND VARIABLES 

const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
const { id:driverId } = useParams(); 
const [submitHit,setSubmitHit] = useState(false)
const [submitHitv,setSubmitHitv] = useState(false)
const [currentDetails , setCurrentDetails] = useState({})
const [fullName , setFullName] = useState('')
const [selectedCod , setSelectedCod] = useState('')
const [selectedDob,setSelectedDob] = useState('')
const [phoneNumber , setPhoneNumber] = useState('')
const [email,setEmail] = useState('')
const [gender , setGender] = useState('')
const [address , setAddress] = useState('')
const [floor , setFloor] = useState('')
const [unit , setUnit] = useState('')
const [postal , setPostal] = useState('')
const [nricNumber , setNricNumber] = useState('')
const [frontImageLink , setFrontImageLink] = useState('')
const [backImageLink , setBackImageLink] = useState('')
const [profileImageLink , setProfileImageLink] = useState('')
const [frontImageFile , setFrontImageFile] =useState()
const [backImageFile , setBackImageFile] = useState()
const [profileImageFile,setProfileImageFile] = useState()
const [userRegId , setUserRegId] = useState('')
const [lastName , setLastName] = useState('')
const [countryId,setCountryId] = useState('')
const [nricFrontFile , setNricFrontFile] = useState({})
const [nricBackFile , setNricBackFile] = useState({})
const [remarkDetails,setRemarkDetails] = useState('')
const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false)
const [changedData , setChangedData] = useState({})
const {currentUserdata} = useSelector((state) => state.user)


const [isVerified,setIsVerified] = useState(false)

const [edit,setEdit] = useState()

useEffect(()=>{
let isEdit = checkPrivilage('Driver Details','UPDATE')
setEdit(isEdit)
 },[])


//RENDERS AND APICALLS

useEffect(() => {

  const apiUrl = `${baseUrl}/trip-service/v1/driver/profile/activity`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

 axios.get(apiUrl, config).then(response => {

  if(response?.data?.result){
    const personalData = response?.data?.result
    setChangedData(personalData?.ChangingFeild)
 
   setCurrentDetails(response?.data?.result)
  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });

    


}, [driverId,onSuccess,onSuccessv]);


//SETTING VALUES

useEffect(()=>{

  if(currentDetails){

    let fullPhNo = currentDetails?.PhoneNumber
    if(fullPhNo?.length == 11){
      const code = fullPhNo?.substring(0, fullPhNo?.length - 8)
      const number = fullPhNo?.substring(fullPhNo?.length - 8);
      setPhoneNumber(number)
      setSelectedCod(code)

    }else{

      const code = fullPhNo?.substring(0, fullPhNo?.length - 10);
      const number = fullPhNo?.substring(fullPhNo?.length - 10);
      setPhoneNumber(number)
      setSelectedCod(code)

    }
    

    const number = fullPhNo?.substring(fullPhNo?.length - 10);
    setRemarkDetails(currentDetails?.Remarks)
    setFullName(currentDetails?.FirstName)
  
   
    setEmail(currentDetails?.Email)
    setIsVerified(currentDetails?.IsVerified)
    setSelectedDob(currentDetails?.DOB)
    setGender(currentDetails?.Gender)
    setAddress(currentDetails?.Address)
    setFloor(currentDetails?.Floor)
    setUnit(currentDetails?.Unit)
    setPostal(currentDetails?.Postal)
    setNricNumber(currentDetails?.NricNumber)
    setFrontImageLink(currentDetails?.NricFrontImagePaths)
    setBackImageLink(currentDetails?.NricBackImagePaths)
    setProfileImageLink(currentDetails?.DriverProfilePicPath)
    setUserRegId(currentDetails?.UserRegisterID)
    setLastName(currentDetails?.LastName)
    setCountryId(currentDetails?.CountryID)


  }

},[currentDetails])






// FUNCTIONS
const handleSelect = (label,data)=>{
          
  if(label === 'Code'){
         setSelectedCod(data?.name)
  }


}



const handleSubmit = ()=>{
  setSubmitHit(true);
  const formData = new FormData();

  
  formData.append('adminUserId', currentUserdata?.ID);
  formData.append('userRegId',userRegId);
  formData.append('phoneNumber',selectedCod+phoneNumber?.toString());
  formData.append('firstName', fullName);
  formData.append('lastName', lastName);
  formData.append('gender', gender);
  formData.append('email',email); 
  formData.append('dateOfBirth', selectedDob);
  formData.append('countryId', countryId);
  formData.append('address', address);
  formData.append('floor', floor?.toString());
  formData.append('unit', unit?.toString());
  formData.append('NRICNumber', nricNumber);
  formData.append('postal', postal?.toString());
  formData.append('remarks', remarkDetails?.toString());
 
  nricFrontFile && formData.append('NricFrontImage',nricFrontFile.file);
  nricBackFile && formData.append('NricBackImage',nricBackFile.file);
  profileImageFile && formData.append('DriverProfileImage',profileImageFile.file)


  const apiUrl = `${baseUrl}/trip-service/v1/driver/update/profile`;  
  const TOKEN = localStorage?.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'multipart/form-data',

    },
  };

  axios.put(apiUrl, formData, config)
  .then(response => {
    if(response?.data?.status === 'success'){
  
      setOnSuccess(true)
    }
 
  })
  .catch(error => {
    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });

}

const handleVerify =()=>{

  setSubmitHitv(true);
  const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/verify/profile/details`; 
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'application/json', // Set the content type for JSON data
    },
  };

  let verifyData={driverId:driverId}

  axios.post(apiUrl,verifyData,config)
  .then(response => {
    if(response?.data?.status === 'success'){
      setOnSuccessv(true)
    }
   
  })
  .catch(error => {

    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });
  
}




const handleDateSelection =(date)=>{
 setSelectedDob(date)
}

const handleGenderSelection = (gender)=>{
  setGender(gender)
}

const handleImageFiles = (files)=>{
  setNricFrontFile(files)

  }

  const handleProfileImageFiles = (files)=>{
    setProfileImageFile(files)
  
    }

  const handleImageFilesTwo = (files)=>{
    setNricBackFile(files)
    }

    function isValidEmail(email) {
      // Regular expression for a basic email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      
      // Test the email against the pattern
      return emailPattern.test(email);
    }



  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
<Box width={isMatch ? '100':'32%'}>
<ImageSelectorUpdate sideLabel={'Profile Image'} imageLink={profileImageLink} isError={changedData?.DriverProfileImage? true : false} handleFileChange ={handleProfileImageFiles} />
</Box>
<Stack mt={2} spacing={2}>
{/* FIRST ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch ? '100':'33%'}>
  <InputFieldUpdate Type={'text'} Value={fullName} isError={changedData?.FirstName? true : false} ErrNote={""} InChange={(e) => setFullName(e.target.value)}   sideLabel={"First Name"} placeHolder={"FullName"} />
</Box>
<Box width={isMatch ? '100':'33%'}>
  <InputFieldUpdate Type={'text'} Value={lastName} isError={changedData?.LastName? true : false } ErrNote={""} InChange={(e) => setLastName(e.target.value)}   sideLabel={"Last Name"} placeHolder={"FullName"} />
</Box>

<Box width={isMatch ? '100%':'33%'}>
<Grid container>
<Grid item xs={2}>
<SelectFieldUpdate currentSelect={selectedCod} isError={false} onSelecting={handleSelect} Data={PH_CODE} sideLabel={'Code'} placeHolder={'+67'} />
</Grid>
<Grid item xs={0.2} />

<Grid item xs={9.5} >
<InputFieldUpdate Note={ selectedCod === '+91' && phoneNumber?.length > 10 && 'invalid phone number' || selectedCod === '+65' && phoneNumber?.length > 8 && 'invalid phone number'}    Type={'number'} Value={parseInt(phoneNumber)} isError={changedData?.PhoneNumber? true : false} ErrNote={""} InChange={(e) => setPhoneNumber(e.target.value)}   sideLabel={"Phone"} placeHolder={"Phone"} />
</Grid>
</Grid>
</Box>


</Stack>


{/* SECOND ROW */}
<Stack alignItems={'center'} mt={1} direction={'row'} spacing={3} >
  <Box width={isMatch?'25%':'32.2%'}>
  <DatePickerComponent isAdult={true} disableFuture={true} isError={changedData?.DOB? true : false} initialValue={selectedDob} handleSelection={handleDateSelection} sideLabel={'Date of Birth'}/>
  </Box>



<Box width={isMatch ? "100%" : "32.5%"} >
<InputFieldUpdate Note={!validator?.isEmail(email || '') && 'Invalid email' }  Value={email} isError={submitHit && email?.length < 3} ErrNote={""} InChange={(e) => setEmail(e.target.value)}   sideLabel={"Email Id"} placeHolder={"Email Id"} />
</Box>

<GenderSelect changeGender={handleGenderSelection} currentGender={gender}/>
</Stack>

{/* THIRD ROW */}

<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'100%'} >

<InputFieldUpdate Value={address} isError={changedData?.Address? true : false} ErrNote={""} InChange={(e) => setAddress(e.target.value)}   sideLabel={"Address"} placeHolder={"Address"} />
</Box>
</Stack>

{/* FOURTH ROW */}

<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'21%'} >
<InputFieldUpdate Type={'number'} Value={floor} isError={changedData?.Floor? true : false} ErrNote={""} InChange={(e) => setFloor(e.target.value)}   sideLabel={"Floor"} placeHolder={"Floor"} />
</Box>
<Box width={isMatch?'100%':'21%'} >
<InputFieldUpdate Type={'number'} Value={unit} isError={changedData?.Unit? true : false} ErrNote={""} InChange={(e) => setUnit(e.target.value)}   sideLabel={"Unit"} placeHolder={"Unit"} />
</Box>
<Box width={isMatch?'100%':'21%'} >
<InputFieldUpdate Note={postal?.length > 6 && 'Invalid postal code'} Value={postal} Type={'number'} isError={changedData?.Postal? true : false} ErrNote={""} InChange={(e) => setPostal(e.target.value)}   sideLabel={"Postal"} placeHolder={"Postal"} />
</Box>
</Stack>

{/* FIFTH ROW */}
<Box width={isMatch?'100%':'21%'} >
<InputFieldUpdate Note={!nric.validate(nricNumber) && 'Invalid NRIC Number' } Value={nricNumber} isError={changedData?.NricNumber? true : false} ErrNote={""} InChange={(e) => setNricNumber(e.target.value)}   sideLabel={"NRIC Number"} placeHolder={"NRIC Number"} />
</Box>

{/* SIXTH ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelectorUpdate sideLabel={'Front Image of NRIC'} imageLink={frontImageLink} isError={changedData?.NricFrontImagePaths? true : false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'Back Image of NRIC'} imageLink={backImageLink} isError={changedData?.NricBackImagePaths? true : false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>
{/* //remark */}
<Box width={isMatch?'100%':'32.2%'} >
<InputFieldUpdate notRequired={true} Value={remarkDetails === undefined ? null : remarkDetails} isError={submitHit && nricNumber?.length < 3} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
</Box>


</Stack>
<NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
        <NotifiProgress closeNotification={() => setOnSuccessv(false)} submitStatus={submitHitv} successNote={'Success'} Status={onSuccessv} />
        <ErrorNotify submitStatus={submitHit || submitHitv} Status={submitHit && onFailed} errorNote={onApiFailed} />
<SaveAndVerify buttonStatus={edit} verificationStatus={isVerified} handleYesClick={handleVerify} handleSubmitHere={handleSubmit}/>
</Box>
  )
}

export default PersonlaDetailsVerification
