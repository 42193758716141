import { Avatar, Box, Button, IconButton, Rating, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import editIcon from '../../../../Assets/Forms/Frame 48077.svg'
import emailIcon from '../../../../Assets/Forms/Visual.svg'
import phoneIcon from '../../../../Assets/Forms/Visual (1).svg'
import ApproveButton from '../../../Components/Forms/ApproveButton'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from '../../../RequestMethord'
import NotifiProgress from '../../../Components/Forms/NotifiProgress'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import WarningSwitch from '../../../Components/Forms/WarningSwitch'
import ApiCall from '../../../../Helper/ApiCalls'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage'
import BlockIcon from '@mui/icons-material/Block';
import FormModal from '../../../Components/Modal/FormModal'
import PrioritySticker from './PrioritySticker'
import { convertToUtcLastMinute, formatteStringDate } from '../../../Components/MultiFunctions/MultiFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { insertModalDataAction } from '../../../Redux/Actions/ModalActions'

function DriverPersonalCard({ProfileData,walletStatus,chengedSuccess}) {


// STATES

const [profileDetails , setProfileDetails] = useState({})
const { id:driverId } = useParams(); 
const [submitHit,setSubmitHit] = useState(false)
const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false)
const [onSuccess , setOnSuccess] = useState(false)
const [success, setSuccess] = useState()
const [modalStatus, setModalStatus] = useState(false);
const dispatch = useDispatch()
const {currentModaldata} = useSelector((state) => state.modal)
const [edit,setEdit] = useState()
  

useEffect(()=>{
 
  let isEdit = checkPrivilage('Driver Details','UPDATE')
  setEdit(isEdit)
  
   },[])


//RENDERS AND APICALLS

useEffect(()=>{

  if(ProfileData){
setProfileDetails(ProfileData)
  }

},[ProfileData,success])



// handle yes click 

const handleYesClick = ()=>{
  setSubmitHit(true)
  const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/approve/details`; 
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'application/json', // Set the content type for JSON data
    },
  };

  let verifyData={driverId:driverId,status: true}

  axios.put(apiUrl,verifyData,config)
  .then(response => {
    if(response?.data?.status === 'success'){
      setOnSuccess(true)
      window.location.reload(true);
    }
   
  })
  .catch(error => {

    setOnFailed((i) => !i)
      
    if(error?.response?.data?.message){
   
      setOnApiFailed(error?.response?.data?.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });

}
const onClick = () => {
  setModalStatus(true);
};

const onClose = () => {
  console.log("closeClickeddd")
  setModalStatus(false);
};

const blockDriver=(status)=>{

  let sendData={
    driverId:parseInt(driverId),
    userTypeId:2,
    status,
  }


  ApiCall("PATCH", "/user-service/v1/admin/driver/update/status",sendData,'BODY').then((data)=>{
       if(data?.result){
        window.location.reload(true);
       }
     }).catch(error => {
        console.error(error,"GET API ERROR");
     });

}



const handleYesClickReject = ()=>{
  setSubmitHit(true)
  const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/approve/details`; 
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'application/json', // Set the content type for JSON data
    },
  };

  let verifyData={driverId:driverId,status: false}

  axios.put(apiUrl,verifyData,config)
  .then(response => {
    if(response?.data?.status === 'success'){
      setOnSuccess(true)
      window.location.reload(true);
    }
   
  })
  .catch(error => {

    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });

}


let today = new Date();
console.log(today,"<--todayyyyy")



//PRIORITY STICKER STATUS CHANGE

const handleStatusChange =async(date,status)=>{
  setSubmitHit(true)
  try{
    let  paramsData;
    if(status){
      paramsData = {driverId:driverId,endDate:date,status:status}
    }else{
      let today = new Date();
      const formattedDate  =await formatteStringDate(today)
      let utcTime = convertToUtcLastMinute(formattedDate)
      paramsData = {driverId:driverId,endDate:utcTime,status:status}
    }
       
      ApiCall("PATCH", "/trip-service/v1/admin/driver/update/priority",paramsData,'BODY').then((data)=>{
        if(data?.status === 'success'){

          console.log("IN SUCCESSS")
          setModalStatus(false);
          setSuccess(data)
          setOnSuccess(true)
          setModalStatus(false);
          chengedSuccess(data)
          dispatch(insertModalDataAction(!currentModaldata))
    
        }
    
         })




  }catch(error){

  }
}
 const rowRating = parseFloat((profileDetails?.driverRating/100)*5)
 console.log(profileDetails?.isPriority,"<------------ratinggg")

  return (
  <Box  width={'500px'} height={'341px'} borderRadius={'8px'} bgcolor={'#FFFFFF'} > 
   <FormModal Type={'Small'} handleclose={onClose} status={modalStatus} component={<PrioritySticker handleFormSubmit={handleStatusChange}/>}/>
  <Stack justifyContent={'center'} padding={3} spacing={3}>

  {/* FIRST ROW   */}
    <Stack display={'flex'} justifyContent={'space-between'}  alignItems={'center'} direction={'row'}  >
    <Avatar alt="Remy Sharp" src={profileDetails?.driverProfileImage} sx={{ width: 110, height: 110 }}/>
    <Stack spacing={0.5}>
    <Typography fontWeight={'bold'} variant='p'>{profileDetails?.driverName}</Typography>
    <Typography color={profileDetails?.driverIsOnline && 'green'} variant='caption'>{profileDetails?.driverIsOnline && 'Online' ||`Last active:${profileDetails?.driverLastOnline || 'N/A'}`}</Typography>
    </Stack>
{
profileDetails?.driverBlocked ?   
<ApproveButton disableStatus={true} isVerified={false}  handleConfirm={() => blockDriver(false)} DialogText={'Are you sure you want to unblock the driver?'} TitleText={'Unblock Driver'} buttonColor={'success'} Label={'Unblock'}/>:
<ApproveButton disableStatus={true} isVerified={false}  handleConfirm={() => blockDriver(true)} DialogText={'Are you sure you want to block the driver?'} TitleText={'Block Driver'} buttonColor={'error'} Label={'Block'}/> 
}
    


  

    </Stack> 



 {/* SECOND ROW    */}
<Stack alignItems={'center'} direction={'row'} spacing={1}>

  <Typography fontWeight={'bold'} variant='p'>{isNaN(rowRating) ? "0.0" : rowRating.toFixed(1) || "0.0"}</Typography>
   <Rating
   name="text-feedback"
   value={isNaN(rowRating) ? 0.0 : rowRating.toFixed(1) || 0.0}
   readOnly
   precision={0.5}
 
   />
 {/* <Rating name="read-only" value={} readOnly /> */}
 <Typography variant='caption'>{`${profileDetails?.driverReview || 0} reviews`}</Typography>
 </Stack>

  {/* THIRD ROW */}

<Stack  direction={'row'} spacing={1}>
  <Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='email' src={emailIcon}/> </IconButton>
<Stack spacing={0.2}>
<Typography variant='caption'>Email</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileDetails?.driverEmail}</Typography>
</Stack>
</Stack>

{/* FOURTH ROW */}

<Stack alignItems={'center'} direction={'row'} spacing={1} >
  <IconButton> <img alt='phone' src={phoneIcon}/> </IconButton>
  <Stack spacing={0.2}>
<Typography variant='caption'>Phone</Typography>
<Typography style={{ fontWeight: 800 }} variant='caption'>{profileDetails?.driverPhone}</Typography>
</Stack>
</Stack>
</Stack>

{/* LAST ROW */}

<Box display={'flex'} justifyContent={'space-between'} p={1} width={'100%'} sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
 {edit ? <>
 {
  // #44bd32'
  profileDetails?.isPriority ?
  <ApproveButton disableStatus={true} isVerified={false}  handleConfirm={() =>  handleStatusChange(null,false)} DialogText={'Are you sure you want to disable priority sticker'} TitleText={'Disable Priority sticker'} buttonColor={'error'} Label={'Disable Priority Sticker'}/> 
  // <Button onClick={() => handleStatusChange(null,false)} sx={{textTransform:'none',color:'#44bd32',borderColor:'#44bd32'}} variant="outlined">Priority Enabled </Button>
  :  <Button onClick={onClick} sx={{textTransform:'none',color:'#44bd32',borderColor:'#44bd32'}} variant="outlined">Enable priority Sticker</Button>
 }
 
{/* <Stack alignItems={'center'} direction={'row'} spacing={1}>
<Typography sx={{ opacity: "50%" }} variant="caption">priority sticker</Typography>
<WarningSwitch statusChanging={handleStatusChange} currentValue={profileDetails?.isPriority}/>
</Stack> */}
<Stack direction={'row'} spacing={1}>
<ApproveButton disableStatus={true} isVerified={profileDetails?.driverApprove }  handleConfirm={handleYesClick} DialogText={'Are you sure you want to approve the driver?'} TitleText={'Approve Driver'} buttonColor={'success'} Label={'Approve'}/>
<ApproveButton disableStatus={true}   handleConfirm={handleYesClickReject} DialogText={'Are you sure you want to reject the driver?'} TitleText={'Reject Driver'} buttonColor={'error'} Label={'Reject'}/>
</Stack>
</> : <RestrictedUsage message={'Restricted Usage'}/>
}
</Box>


<NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
<ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
 </Stack>

  </Box>
  )
}

export default DriverPersonalCard
