import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import ToggleNav from '../../Components/Forms/ToggleNav'
import DataTable from '../../Components/Table/DataTable'
import CategoryFilter from '../RideCategoryManagement/CategoryFilter'
import ApiCall from '../../../Helper/ApiCalls'
import SpecialDayCharge from './SpecialDayCharge'
import { useDispatch, useSelector } from 'react-redux'
import SurChargeFilter from './SurChargeFilter'
import { useNavigate } from 'react-router-dom'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import { useTitle } from '../../../Hooks/UseTitle';
import { insertAllZoneDataAction } from '../../Redux/Actions/AllZoneActions'
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions'
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage'
import { toast } from 'react-toastify'

const columns = [
    {
      id: "id",
      label: "No",
      maxWidth: 50,
      sort: true,
    },

    {
      id: "surgeTime",
      label: "Surge Time",
      maxWidth: 70,
      align: "left",
      sort: true,
    },
    {
      id: "days",
      label: "Days",
      maxWidth: 60,
      align: "left",
      sort: false,
    },
    {
      id: "zone",
      label: "Zone",
      maxWidth: 50,
      align: "left",
      sort: false,
    },
    {
      id: "type",
      label: "Surcharge Type",
      maxWidth: 100,
      align: "left",
      sort: true,
    },
    {
      id: "surcharge",
      label: "Surcharge",
      maxWidth: 100,
      align: "left",
      sort: true,
    },
      {
        id: "status",
        label: "Status",
        maxWidth: 170,
        align: "left",
        sort: false,
        title: {
          onTrue: true,
          onFalse: false,
        },
      },
 
    {
      id: "actions",
      label: "Actions",
      maxWidth: 170,
      align: "left",
      sort: false,
    },
  ];







function SurgeCharges() {

  useTitle("General Surch - Geolah");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [allZoneStatus, setAllZoneStatus] = useState()
    const [tab , setTab] = useState('left')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [tableData, setTableData] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0);

    const {currentAllZonedata} = useSelector((state) => state.allzone)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [any,setAny] = useState()

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Surcharges')
      setAny(isAany)
      let isWrite = checkPrivilage('Surcharges','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Surcharges','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Surcharges','DELETE')
       setDeletePer(isDelete)
       },[])


//renders

useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/trip-service/v1/admin/sur-charges/general/surcharge/${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
        const surgeData = data?.result
        setTotalRows(surgeData?.length)

           if(searchInput?.length <= 0){
        let filteredData = surgeData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
          const surgeType = eachItem?.SurgeType;
          let theSurge = eachItem?.SurgeCharge;
          let initialSurcharge = surgeType === 'Amount' ? parseFloat(theSurge)?.toFixed(2) : theSurge
          let items = {

            id:index+1+currentOffset,
            _id:eachItem._id,
            surgeTime:eachItem?.SurgeStartTime,
            days:eachItem?.Day,
            zone:eachItem?.ZoneID?.Name,
            type:surgeType,
            surcharge:initialSurcharge,
            status:eachItem?.IsEnabled,
           }

           return items

        })
        setRows(filteredData)
      }

        let filteredRowData = surgeData?.map((eachItem,index)=>{
          const surgeType = eachItem?.SurgeType;
          let theSurge = eachItem?.SurgeCharge;
          let initialSurcharge = surgeType === 'Amount' ? parseFloat(theSurge)?.toFixed(2) : theSurge
          let items = {

            id:index+1+currentOffset,
            _id:eachItem._id,
            surgeTime:eachItem?.SurgeStartTime,
            days:eachItem?.Day,
            zone:eachItem?.ZoneID?.Name,
            type:surgeType,
            surcharge:initialSurcharge,
            status:eachItem?.IsEnabled,
           }

           return items

        })
     
        setTableData(filteredRowData);
   
   } })
  },[success,CountryID,currentPage,searchInput,rowsPerPage])

  //searching data

  useEffect(()=>{
    setRows(
      tableData.filter((item) =>
      item?.surgeTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.days?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.zone?.toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.surcharge?.toLowerCase().includes(searchInput.toLowerCase())
  
        ))
     },[searchInput])


  
useEffect(()=>{

  console.log(currentAllZonedata,"<---allzone dataaaaaaaaaa")

  const allzoneData = {
    countryId:1,
    currentSurge: currentAllZonedata ? "ALL_ZONE":"SINGLE_ZONE",
  }
       
  ApiCall("POST", "/trip-service/v1/admin/sur-charges/select/surge/type",allzoneData).then((data)=>{
    if(data.status === 'success'){      
      setAllZoneStatus(data)
    }
})
.catch((error) => {
 
});

},[currentAllZonedata])




const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };


const tabRender = (alignment)=>{
      setTab(alignment)
                              }

const handleFilterBar = (status)=>{
      setIsFilterOn(status)
                             } 

const handleServiceEdit =(id,newData)=>{
 
  if(id){
    navigate(`/surcharge-edit/${id}`)
  }
}                            
                             
const handleServiceDelete =(id, newData)=>{
 // console.log(`Delete with id ${id}. New data:`, newData);                             
  setDeleteStatus(true)
  const paramsData ={surgeId:id,status:false,type:"General",action:"Delete"}
  setDeleteData(paramsData)
                               
                              }

 const onDeleteYes =(yesData)=>{
  ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",yesData,'BODY').then((data)=>{
    if(data?.status === 'success'){
      setSuccess(data) 
      setDeleteStatus(false)
      toast.success("Successfully deleted.");
     }else{
      toast.error("Something went wrong.!");
     }
  })
                               }
 
 const onCloseNotification = ()=>{
    setDeleteStatus(false)
     }                              






 const handleChangeInSearch = (value)=>{
  setSearchInput(value)
}                             

const handleStatusChange =(id, newData)=>{
 const paramsData = {surgeId:id,status:newData?.status,type:"General",action:"ChangeStatus"}
 ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",paramsData,'BODY').then((data)=>{setSuccess(data)})
                                         }                             

const handleColumnFilter = (items, searchText) => {
  setSelectedItems(items); // Update selected filter items

  // If no items are selected and no search text, show all rows
  if (items.length === 0 && searchText === "") {
    setRows(tableData);
    return;
  }
       


  // Filter the rows based on the selected items and search text
  const filteredRows = tableData.filter((row) => {
    // Check if any of the selected items are present in the row's features
    const featuresMatch = items.every(
      (item) => row.features && row.features.includes(item)
    );

    // Check if the row matches any of the selected vehicleCategory items
    const vehicleCategoryMatch = items.includes(row.zone);

    // Check if the row matches all of the selected vehicleType items
    // const vehicleTypeMatch = items.every(
    //   (item) => row.vehicleType && row.vehicleType.includes(item)
    // );
    const vehicleTypeMatch = items.includes(row.days);

    // Check if the row contains the search text in any of its values
    const searchTextMatch = Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    // Return true if any of the filters match the row, otherwise return false
    return (
      (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
      searchTextMatch
    );
  });

  // Update the filtered rows
  setRows(filteredRows);
};

const handlePageChange=(no,rowsCount)=>{
  setCurrentPage(no)
  setRowsPerPage(rowsCount)
  
}


  return (
  any ? (  
  <Box>
    <TableHead title={'Surcharge'}/>
  
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <ToggleNav lName={'General'} rName={'Special Day'} toggleChange={tabRender} />  
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    {
    tab === 'left' ?  
// render the General Table
<>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<DataTable
      handlingPage={handlePageChange}
      totalCount={totalRows}
     DeletePermission={deletePer}
     ButtonPermission={write}
     EditPermission={edit} 
zoneStatus={allZoneStatus}
        isSurcharge={false} 
        isFilterOn={isFilterOn}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        openFilter={handleFilterBar}
        ButtonPath={'/create-general-surcharge'}
        ButtonLabel={'Add Surge'}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        handleRowSelection={(noOfrows) => setRowsPerPage(noOfrows)}
        filterComponent={  <SurChargeFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      /> </> :  <SpecialDayCharge/>
     }
    </Box>
    </Box>
  </Box>
  ): any === false ? (
    <RestrictedUsage/>
  ):null
  )
}

export default SurgeCharges
