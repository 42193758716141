import { Box, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../Hooks/UseTitle';
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import WalletHeadDetails from './WalletHeadDetails';
import TransactionHistory from './TransactionHistory';
import { useNavigate, useParams } from 'react-router-dom';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';





function WalletDetailsModule() {

  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate('/wallet')}  key="3" color="text.primary">
    Wallet
    </Typography>,
<Typography key="3" color="text.primary">
    Wallet Details
    </Typography>,
];
const { id:driverId } = useParams() 

    useTitle("Wallet Details - Geolah");
    const [any,setAny] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Wallet Details')
      setAny(isAany)
       },[])
    const theme = createTheme({
      breakpoints: {
        values: {
          ht: 1787,
      
        },
      },
    });
    const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
  return (
    any ? (
<Box>
<CreatePageHead  BackPath={'/wallet'} title={'Wallet Details'} BreadList={breadcrumbs}/>
<WalletHeadDetails/>
<TransactionHistory/>

</Box>
) : any === false ? (
  <RestrictedUsage/>
):null
  )
}

export default WalletDetailsModule
