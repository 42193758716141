import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

function MapSkelton() {

const {pathname} = useLocation()

console.log(pathname,"<<-4-<<<-##")

  return (
    <Box
    sx={{
      flexWrap: "wrap",
      "& > :not(style)": {
        mt: "3%",
        width: '70%',
        height: "50%",
        borderRadius: "2%",
      },
    }}
  >
    <Paper sx={{ padding: "16px" }} elevation={3}>
      <Stack spacing={3}>
        <Typography variant="h6">
          
          {" "}
          <Skeleton width={'120px'} variant="text" sx={{ fontSize: '1rem' }} />
        </Typography>
        <Skeleton variant="rectangular" width={ pathname === '/ride-details' ? '500px' : '900px'} height={'387px'} sx={{borderRadius:'12px'}} />
      </Stack>
    </Paper>
  
  </Box>
  )
}

export default MapSkelton
