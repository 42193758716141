import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fadeInOut: {
    animation: '$fadeInOut 2s ease-in-out infinite',
  },
  '@keyframes fadeInOut': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  icon: {
    fontSize: '4rem',
    marginBottom: theme.spacing(2),
  },
}));

const NoDataAvailable = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        width: '1300px',
        paddingTop: '5px',
        display: 'flex',
        justifyContent: 'center',
      }}
      className={classes.fadeInOut}
    >
      {/* You can replace the icon with any Material-UI icon */}
      <Stack pt={5} spacing={1}>
        <Typography variant="h4" color="textSecondary">
          No Data Available
        </Typography>
        {/* Optionally, you can provide additional information or a call to action */}
        <Typography variant="body1" color="textSecondary">
          There is no data available at the moment.
        </Typography>
      </Stack>
    </div>
  );
};

export default NoDataAvailable;
