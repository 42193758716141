import { Box, Stack } from '@mui/material'
import React from 'react'
import FormSubmitButtons from '../../Components/Forms/FormSubmitButtons'
import AddAdmin from './AddAdmin'

function AdminForm() {
  return (
   <Box>
      <Stack spacing={3} >
    {/* <VoucherForm/> */}
    <AddAdmin/>
    <FormSubmitButtons/>
    </Stack>
   </Box>
  )
}

export default AdminForm
