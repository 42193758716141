import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../Hooks/UseTitle';
import { useNavigate } from 'react-router-dom';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import SurChargeFilter from '../SurgeCharge/SurChargeFilter';
import ApiCall from '../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../Sessions/Permisssions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "splitName",
      label: "Split Name",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "driversSplit",
      label: "Drivers Split",
      minWidth: 170,
      align: "left",
      sort: true,
      
    },
    {
      id: "geolahSplit",
      label: "Geolah Split",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    // {
    //   id: "insurancePartner",
    //   label: "Insurance Partner",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    // {
    //   id: "insuranceSplit",
    //   label: "Insurance Split",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    {
      id: "prioritySticker",
      label: "Priority Sticker ",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "actions",
        label: "Actions",
        maxWidth: 170,
        align: "left",
        sort: false,
      },
  
    
  ];
  












function PaymentSplitTable() {
    useTitle("Payment Split - Geolah");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [tab , setTab] = useState('left')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [tableData, setTableData] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const navigate = useNavigate()
    const [totalRows,setTotalRows] = useState(0)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()


useEffect(()=>{

  let isWrite = checkPrivilage('Payments','CREATE')
  setWrite(isWrite)
 let isEdit = checkPrivilage('Payments','UPDATE')
   setEdit(isEdit)
 
   let isDelete = checkPrivilage('Payments','DELETE')
   setDeletePer(isDelete)
   },[])

    useEffect(()=>{

        ApiCall("GET", `/trip-service/v1/admin/payments/splits/${CountryID}`, null, "uui").then((data)=>{
          if(data?.result){
           console.log(data?.result,"<---splittt detailssssssssss")
            const paymentData = data?.result
            setTotalRows(paymentData?.length)
            let filteredData = paymentData?.map((eachItem,index)=>{
              
            //  let currentDate = eachItem?.Date
            //  const formattedDate = dayjs(currentDate).format('YYYY-MM-DD');
            //  const dayOfWeek = dayjs(currentDate).format('dddd')
            //  console.log(dayOfWeek , ",0----speciall dateeeee")
            const rideFares = parseFloat(eachItem?.PriorityStickerDeduction)
              let items = {
    
                id:index+1,
                _id:eachItem._id,
                splitName:eachItem?.Name,
                driversSplit:`${eachItem?.Splits?.[1]?.percentage}%`,
                geolahSplit:`${eachItem?.Splits?.[0]?.percentage}%`,
                prioritySticker:isNaN(rideFares) ? "N/A" : rideFares.toFixed(2) || "N/A",
               }
    
               return items
    
            })
    
            setRows(filteredData)
            setTableData(filteredData);
       
       
       } })
      },[success])


//    TABLE SEARCH 


      useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.splitName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.driversSplit?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.geolahSplit?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.prioritySticker?.toLowerCase().includes(searchInput.toLowerCase()) 
    
          
      
            ))
         },[searchInput])




    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };
      const tabRender = (alignment)=>{
        setTab(alignment)
                                }
    
    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
                               } 
    
    const handleServiceEdit =(id,newData)=>{
    
    if(id){
      navigate(`/edit-split/${id}`)
    }
    }                            
                               
    const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);                             
    setDeleteStatus(true)
    const paramsData ={splitId:id}
    setDeleteData(paramsData)
                                 
                                }
    
    const onDeleteYes =(yesData)=>{
    ApiCall("PATCH", `/trip-service/v1/admin/payments/delete/split`,yesData,'BODY').then((data)=>{
      if(data?.status === 'success'){
        setSuccess(data) 
        setDeleteStatus(false)
        toast.success("Successfully deleted.");
       }else{
        toast.error("Something went wrong.!");
       }
    })
                                 }
    
    const onCloseNotification = ()=>{
      setDeleteStatus(false)
       }                              
    
    
    
    
    
    
    const handleChangeInSearch = (value)=>{
    setSearchInput(value)
    }                             
    
    const handleStatusChange =(id, newData)=>{
    // const paramsData = {surgeId:id,status:newData?.status,type:"General",action:"ChangeStatus"}
    // ApiCall("PATCH", "/trip-service/v1/admin/sur-charges/surcharge",paramsData,'BODY').then((data)=>{setSuccess(data)})
                                           }                             
    
    const handleColumnFilter = (items, searchText) => {
    setSelectedItems(items); // Update selected filter items
    
    // If no items are selected and no search text, show all rows
    if (items.length === 0 && searchText === "") {
      setRows(tableData);
      return;
    }
         
    
    
    // Filter the rows based on the selected items and search text
    const filteredRows = tableData.filter((row) => {
      // Check if any of the selected items are present in the row's features
      const featuresMatch = items.every(
        (item) => row.features && row.features.includes(item)
      );
    
      // Check if the row matches any of the selected vehicleCategory items
      const vehicleCategoryMatch = items.includes(row.zone);
    
      // Check if the row matches all of the selected vehicleType items
      // const vehicleTypeMatch = items.every(
      //   (item) => row.vehicleType && row.vehicleType.includes(item)
      // );
      const vehicleTypeMatch = items.includes(row.days);
    
      // Check if the row contains the search text in any of its values
      const searchTextMatch = Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    
      // Return true if any of the filters match the row, otherwise return false
      return (
        (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
        searchTextMatch
      );
    });
    
    // Update the filtered rows
    setRows(filteredRows);
    };



  return (
    <>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
   
    <DataTable
    totalCount={totalRows}
    DeletePermission={deletePer}
    ButtonPermission={write}
    EditPermission={edit} 
    isSurcharge={false} 
    isFilterOn={isFilterOn}
    columns={columns}
    tableData={rows}
    columnSorting={columnSortingFn}
    handleSwitchChange={handleStatusChange}
    handleDelete={handleServiceDelete}
    openFilter={handleFilterBar}
    ButtonPath={'/create-split'}
    ButtonLabel={'Create Split'}
    handleEdit={handleServiceEdit}
    // columnFilter={handleColumnFilter}
    searchInput={handleChangeInSearch}
    filterComponent={''}
  />
   </>
  )
}

export default PaymentSplitTable
