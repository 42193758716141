
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RideFilter from '../../RideServiceManagement/RideFilter';
import { Box } from '@mui/material';
import NotifyAlertWhileDelete from '../../../Components/Forms/NotifyAlertWhileDelete';
import ApiCall from '../../../../Helper/ApiCalls';
import TableHead from '../../../Components/Table/TableHead';
import DataTable from '../../../Components/Table/DataTable';


const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },

  {
    id: "driverId",
    label: "Id",
    minWidth: 150,
    align: "left",
    sort: true,
  },
  {
    id: "driverName",
    label: "Driver Name",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "bookings",
    label: "Bookings",
    minWidth: 200,
    align: "left",
    sort: true,
  },
  {
    id: "cancelled",
    label: "Cancelled",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "reviews",
    label: "Reviews",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: true,
      onFalse: false,
    },
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: true,
      onFalse: false,
    },
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "vehicle",
    label: "Vehicle",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "left",
    sort: false,
  },
]; 








function DriverFleetList() {
  const [rows, setRows] = useState();
  const [success, setSuccess] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInput , setSearchInput] = useState('')
  const [isFilterOn , setIsFilterOn] = useState(false)
  const [deleteStatus , setDeleteStatus] = useState(false)
  const [deleteData , setDeleteData] = useState({})
  const [order, setOrder] = useState("ASC");
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const navigate = useNavigate()

  useEffect(()=>{
    ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
     
         const walletData = data?.result
        const filteredData =  walletData?.map((eachItem,index)=>{
          
        
         let items = {
               _id:eachItem._id,
               id:index+1,
               // rideService:eachItem?.ServiceName,
               // description:eachItem?.Description,
               // vehicleType: vehicles?.join(", "),
               // vehicleCatogery: eachItem.VehicleCategory ?eachItem.VehicleCategory.join(", "):"N/A",
               // features:adOnFeatures.length > 0 ? adOnFeatures?.join(", "): "N/A",
               // status:eachItem?.IsEnabled
              }
     
              return items;
        })
     
        
     
        setRows(filteredData)
        setTableData(filteredData);
      } })
     },[success])




  // useEffect(()=>{
    //     setRows(
    //       tableData.filter((item) =>
    //       item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.features?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleCatogery?.toLowerCase().includes(searchInput.toLowerCase()) ||
    //       item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase())
    //         ))
    //      },[searchInput])


    const handleStatusChange =(id, newData)=>{
      //  console.log(`Switch with id ${id} changed. New data:`, newData);
       const paramsData = {valueServiceId:id,status:newData?.status}
      //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
    }




// delete status  
const handleServiceDelete =(id, newData)=>{
  // console.log(`Delete with id ${id}. New data:`, newData);
  setDeleteStatus(true)
  const paramsData ={id,name:newData?.rideService}
  setDeleteData(paramsData)
 
}

const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
} 


const onCloseNotification = ()=>{
  setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
  if(id){
    navigate(`/wallet-details/${id}`)
  }
 

}

// handle search change

const handleChangeInSearch = (value)=>{
   setSearchInput(value)
   console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
}


  const handleColumnFilter = (items, searchText) => {



      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
    
        // Check if the row matches all of the selected vehicleType items
        const vehicleTypeMatch = items.every(
          (item) => row.vehicleType && row.vehicleType.includes(item)
        );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };


  const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }





  const columnSortingFn = (id) => {
      if (order === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id"
            ? a[id].toLowerCase() > b[id].toLowerCase()
              ? 1
              : -1
            : a[id] > b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("DSC");
      }
  
      if (order === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() < b[id].toLowerCase()
              ? 1
              : -1
            : id === "date"
            ? new Date(a.date).getTime() - new Date(b.date).getTime()
            : a[id] < b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("ASC");
      }
    };
  






  return (
    <Box>
    <TableHead title={'Driver list'}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
          <DataTable
           isFilterOn={isFilterOn}
           openFilter={handleFilterBar}
            columns={columns}
            tableData={rows}
            columnSorting={columnSortingFn}
            ButtonPath={'/fleet-add-driver'}
            ButtonLabel={'Add Driver'}
            handleSwitchChange={handleStatusChange}
            handleDelete={handleServiceDelete}
            handleEdit={handleServiceEdit}
            columnFilter={handleColumnFilter}
            searchInput={handleChangeInSearch}
            filterComponent={  <RideFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
          />
        </Box>
    
    </Box>
    </Box>
  )
}

export default DriverFleetList
