import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VoucherForm from './VoucherForm'

import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';






function AddNewVoucher() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Vouchers
      </Typography>,
  <Typography key="3" color="text.primary">
      Create Voucher
      </Typography>,
  ];
  useTitle("Create Voucher - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Vouchers')
    setAny(isAany)
     },[])
  return (
  any ? (
    <Box>
    <CreatePageHead title={'Create Voucher'} BreadList={breadcrumbs}/>
   <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
   <Box  sx={{ width: "100%", height: "100%"}} p={4}>
   <VoucherForm/>
   </Box>
   </Box>
 </Box>
 ):any === false ? (
  <RestrictedUsage/>
 ) : null
    
  )
}

export default AddNewVoucher
