import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';
import EditServiceForm from './EditServiceForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import { useNavigate } from 'react-router-dom';



function EditRideService() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Service Management
      </Typography>,
 <Typography key="3" color="text.primary">
      Edit service
      </Typography>,
  ];


  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Ride Service')
    setAny(isAany)
     },[])
  useTitle("Edit Service - Geolah");
  return (
    any ? (
    <Box>
    <CreatePageHead title={'Edit Service'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
       <EditServiceForm/>  
    </Box>
    </Box>
   </Box>): any === false ?(
    <RestrictedUsage/>
   ): null
  )
}

export default EditRideService
