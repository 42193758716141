import { Box } from '@mui/material'
import React from 'react'
import DocumentSingleCard from '../../../../Components/Documents/DocumentsSingleCard'
import { makeStyles } from '@mui/styles';




export const DocListStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflowX: 'auto', // Enable horizontal scrolling
    '&::-webkit-scrollbar': {
      height: '10px', // Adjust the height as needed
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray', // Change the thumb color
      borderRadius: '5px', // Add rounded corners to the thumb
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent', // Change the track color
    },
    scrollbarWidth: 'thin', // Firefox scrollbar width
    scrollbarColor: 'lightgray transparent',
  },
}));



function VehicleDocumentsList({DocList}) {

  const classes = DocListStyles();


    console.log(DocList,"<--documentt listssss")
    
  return (
<Box
className={classes.container}
  justifyContent="space-between"
  p={2}
  width="1200px" // Use 100% to allow horizontal scrolling
  height="300px"
>
    {DocList?.map((eachData,index)=>(
   <DocumentSingleCard singleData={eachData} />
   ))}

  



</Box>
  )
}

export default VehicleDocumentsList
