import { Settings } from "@mui/icons-material";
import MiniDrawer from "../App/Components/SideNav/SideNavBar";
import Booking from "../App/Views/Booking/Booking";
import Dashboard from "../App/Views/Dashboard/Dashboard";
import Driver from "../App/Views/Driver/Driver";
import Payment from "../App/Views/Payment/Payment";
import Review from "../App/Views/Review/Review";
import Ticketing from "../App/Views/Ticketing/Ticketing";
import Signin from "../Sessions/Signin";
import Coupons from "../App/Views/Coupons/Coupons";
import EmployeeManagement from "../App/Views/Administration/EmployeeManagement/EmployeeManagement";
import CancelReason from "../App/Views/CancelReasons/CancelReason";
import DriverDetails from "../App/Views/Driver/DriverDetails/DriverDetails";
import UserDetails from "../App/Views/Administration/UserDetails/UserDetails";
import Services from "../App/Views/Services/Services";
import Charges from "../App/Views/Charges/Charges";
import ReferalProgram from "../App/Views/ReferalPrograms/ReferalProgram";
import Error404 from "../App/Views/404 Error page/Error404";
import NoInterNet from "../App/Views/404 Error page/NoInterNet";
import React from "react";
import Voucher from "../App/Views/Voucher/Voucher";
import BookingDetails from "../App/Views/Booking/BookingDetails/BookingDetails";
import Admin from "../App/Views/Administration/Admin";
import Drive from "../App/Views/Drive/Drive";
import ServiceManagement from "../App/Views/ServiceManagement/ServiceManagement";
import User from "../App/Views/User/User";
import Tickets from "../App/Views/Tickets/Tickets";
import ZoneManagament from "../App/Views/ZoneManagaement/ZoneManagament";
import RideServiceManagement from "../App/Views/RideServiceManagement/RideServiceManagement";
import BasicFare from "../App/Views/BasicFare/BasicFare";
import CreateRideService from "../App/Views/RideServiceManagement/CreateService/CreateRideService";
import EditRideService from "../App/Views/RideServiceManagement/EditService/EditRideService";
import RideCategoryMangement from "../App/Views/RideCategoryManagement/RideCategoryMangement";
import CreateRideCategory from "../App/Views/RideCategoryManagement/CreateCategory/CreateRideCategory";
import EditRideCategory from "../App/Views/RideCategoryManagement/EditCategory/EditRideCategory";
import CreateBasicFare from "../App/Views/BasicFare/CreateBasicFare/CreateBasicFare";
import SurgeCharges from "../App/Views/SurgeCharge/SurgeCharges";
import CreateSurcharge from "../App/Views/SurgeCharge/CreateSurcharge/CreateSurcharge";
import CreateSpecialCharge from "../App/Views/SurgeCharge/CreateSpecialSurcharge/CreateSpecialCharge";
import DriverModule from "../App/Views/DriverModule/DriverModule";
import DriverMainDetails from "../App/Views/DriverModule/DriverDetails/DriverMainDetails";
import UpdateBasicFare from "../App/Views/BasicFare/EditBasicFare/UpdateBasicFare";
import UpdateSurcharge from "../App/Views/SurgeCharge/EditSurcharge/UpdateSurcharge";
import UpdateSpecialDaySurCharge from "../App/Views/SurgeCharge/EditSpecialDayCharge/UpdateSpecialDaySurCharge";
import AddNewVoucher from "../App/Views/Voucher/CreateVoucher/AddNewVoucher";
import EditVoucher from "../App/Views/Voucher/EditVoucher/EditVoucher";
import Insurance from "../App/Views/Insurance/Insurance";
import CreatePartner from "../App/Views/Insurance/CreatePartner/CreatePartner";
import AppAds from "../App/Views/AppAdds/AppAds";
import CreateAds from "../App/Views/AppAdds/CreateAdds/CreateAds";
import EditPartner from "../App/Views/Insurance/EditPartner/EditPartner";
import EditAdds from "../App/Views/AppAdds/EditAdds/EditAdds";
import UserMainDetails from "../App/Views/User/UserModuleDetails/UserMainDetails";
import CreateTickets from "../App/Views/Ticketing/CreateTickets/CreateTickets";
import CreateSplit from "../App/Views/Payment/CreatePaymentSplit/CreateSplit";
import EditSplit from "../App/Views/Payment/EditPaymentSplit/EditSplit";
import Ride from "../App/Views/Ride/Ride";
import RideMainDetails from "../App/Views/Ride/RideDetails/RideMainDetails";
import WalletModule from "../App/Views/WalletModule/WalletModule";
import WalletDetailsModule from "../App/Views/WalletModule/WalletDetails/WalletDetailsModule";
import OtherCharges from "../App/Views/OtherCharges/OtherCharges";
import CreateOtherCharge from "../App/Views/OtherCharges/AddOtherCharge/CreateOtherCharge";
import Reports from "../App/Views/Reports/Reports";
import AdminModule from "../App/Views/AdminModule/AdminModule";
import CreateEmplyee from "../App/Views/AdminModule/CreateEmploye/CreateEmplyee";
import CreateDriver from "../App/Views/DriverModule/CreateDriver/CreateDriver";
import ZoneDetails from "../App/Views/ZoneManagaement/ZoneDetails/ZoneDetails";
import Vehicle from "../App/Views/Vehicles/Vehicle";
import VehicleMainDetails from "../App/Views/Vehicles/VehicleDetails/VehicleDetails";
import FleetManagement from "../App/Views/FleetManagement/Home/FleetManagement";
import CreateDriverFleet from "../App/Views/FleetManagement/AddFleetDriver/CreateDriverFleet";
import CreateFleetVehicle from "../App/Views/FleetManagement/AddFleetVehicle/CreateFleetVehicle";
import AssignVehicle from "../App/Views/FleetManagement/AssignVehicle/AssignVehicle";
import DriverFleetList from "../App/Views/FleetManagement/DriverList/DriverFleetList";
import VehicleFleetList from "../App/Views/FleetManagement/VehicleList/VehicleFleetList";
import EditEmplyee from "../App/Views/AdminModule/EditEmploye/EditEmployee";
import EditTickets from "../App/Views/Ticketing/EditTickets/EditTickets";
import ForgotPassword from "../Sessions/ForgotPassword";
import CreateVehicle from "../App/Views/Vehicles/CreateVehicle/CreateVehicle";
import EditVehicle from "../App/Views/Vehicles/EditVehicle.js/EditVehicle";
import RoleManagement from "../App/Views/RoleManagement/RoleManagement";
import CreateRole from "../App/Views/RoleManagement/CreateRole/CreateRole";
import UpdateRole from "../App/Views/RoleManagement/UpdateRole/UpdateRole";
import ProfileDetails from "../App/Views/ProfileDetails/ProfileDetails";
import ResetPassword from "../Sessions/ResetPassword";
import ConvenienceFee from "../App/Views/ConvenienceFee/ConvenienceFee";
import CreateConvenienceFee from "../App/Views/ConvenienceFee/CreateConvenienceFee/CreateConvenienceFee";
import Tip from "../App/Views/Tip/Tip";
import CreateTip from "../App/Views/Tip/Create-Tip/CreateTip";
import EditConvenienceFee from "../App/Views/ConvenienceFee/EditConvenienceFee/EditConvenienceFee";
import EditTip from "../App/Views/Tip/Edit-Tip/EditTip";

const TOKEN = localStorage.getItem("jwtToken");

const routeList = [
  {
    path: "/dasboard",
    element:  <Dashboard />,
  },
  {
    path: "/administration",
    element: <AdminModule />,
  },
  {
    path: "/drivers",
    element: <Driver />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/booking",
    element: <Booking />,
  },
  {
    path: "/ticketing",
    element: <Ticketing />,
  },
  {
    path: "/tickets",
    element: <Tickets />,
  },
  {
    path: "/review",
    element: <Review />,
  },
  {
    path: "/rideservicemanagement",
    element: <RideServiceManagement />,
  },
  {
    path: "/create-service",
    element: <CreateRideService />,
  },
  {
    path: "/ridecategorymanagement",
    element: <RideCategoryMangement />,
  },
  {
    path: "/create-category",
    element: <CreateRideCategory />,
  },
  {
    path: "/edit-category/:id",
    element: <EditRideCategory />,
  },
  {
    path: "/edit-service/:id",
    element: <EditRideService />,
  },

  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/coupons",
    element: <Coupons />,
  },
  {
    path: "/basicfare",
    element: <BasicFare />,
  },
  {
    path: "create-fare",
    element: <CreateBasicFare />,
  },
  {
    path: "/edit-fare/:id",
    element: <UpdateBasicFare />,
  },
  {
    path: "/edit-specialday-charge/:id",
    element: <UpdateSpecialDaySurCharge />,
  },

  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "/reset-password/:id",
    element: <ResetPassword />,
  },
  
  // {
  //   path: "/employee",
  //   element: <EmployeeManagement />,
  // },

  {
    path: "/users",
    element: <User />,
  },
  {
    path: "/driver-detailz",
    element: <DriverDetails />,
  },
  {
    path: "/user-details",
    element: <UserDetails />,
  },

  {
    path: "/cancel-reason",
    element: <CancelReason />,
  },

  {
    path: "/charges",
    element: <Charges />,
  },

  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/referal",
    element: <ReferalProgram />,
  },
  {
    path: "/nointernet",
    element: <NoInterNet />,
  },
  {
    path: "/service-management",
    element: <ServiceManagement />,
  },
  {
    path: "/voucher",
    element: <Voucher />,
  },
  {
    path: "/booking-details",
    element: <BookingDetails />,
  },
  {
    path: "/wallet",
    element: <WalletModule />,
  },

  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/drive",
    element: <Drive />,
  },
  {
    path: "/surcharge-edit/:id",
    element: <UpdateSurcharge />,
  },
  {
    path: "/driver",
    element: <DriverModule />,
  },
  {
    path: "/driver-details/:id",
    element: <DriverMainDetails />,
  },
  {
    path: "/zone-management",
    element: <ZoneManagament />,
  },
  {
    path: "/surcharge",
    element: <SurgeCharges />,
  },
  {
    path: "/create-general-surcharge",
    element: <CreateSurcharge />,
  },
  {
    path: "/create-special-surcharge",
    element: <CreateSpecialCharge />,
  },
  {
    path: "/create-voucher",
    element: <AddNewVoucher />,
  },
  {
    path: "/edit-voucher/:id",
    element: <EditVoucher />,
  },
  {
    path: "/insurance",
    element: <Insurance />,
  },
  {
    path: "/create-insurance",
    element: <CreatePartner />,
  },
  {
    path: "/edit-insurance/:id",
    element: <EditPartner />,
  },

  {
    path: "/advertisement",
    element: <AppAds />,
  },
  {
    path: "/create-app-ad",
    element: <CreateAds />,
  },
  {
    path: "/edit-add/:id",
    element: <EditAdds />,
  },
  {
    path: "/user-details/:id",
    element: <UserMainDetails />,
  },
  {
    path: "/create-ticket",
    element: <CreateTickets />,
  },
  {
    path: "/create-split",
    element: <CreateSplit />,
  },
  {
    path: "/edit-split/:id",
    element: <EditSplit />,
  },
  {
    path: "/ride",
    element: <Ride />,
  },
  {
    path: "/ride-details/:id",
    element: <RideMainDetails />,
  },
  {
    path: "/wallet-details/:id",
    element: <WalletDetailsModule />,
  },
  {
    path: "/other-charges",
    element: <OtherCharges />,
  },
  {
    path: "/edit-other-charge",
    element: <CreateOtherCharge />,
  },
  {
    path: "/Reports",
    element: <Reports />,
  },
  {
    path: "/create-employee",
    element: <CreateEmplyee />,
  },
  {
    path: "/create-driver",
    element: <CreateDriver />,
  },

  {
    path: "/vehicles",
    element: <Vehicle />,
  },
  {
    path: "/vehicle-details/:id",
    element: <VehicleMainDetails />,
  },
  {
    path: "/fleet-management",
    element: <FleetManagement />,
  },
  {
    path: "/fleet-add-driver",
    element: <CreateDriverFleet />,
  },

  {
    path: "/fleet-add-vehicle",
    element: <CreateFleetVehicle />,
  },

  {
    path: "/zone-details/:id/:zoneId",
    element: <ZoneDetails />,
  },

  {
    path: "/assign-vehicle",
    element: <AssignVehicle />,
  },
  {
    path: "/driver-list",
    element: <DriverFleetList />,
  },
  {
    path: "/fleet-add-vehicle",
    element: <VehicleFleetList />,
  },
  {
    path: "/edit-employee/:id",
    element: <EditEmplyee />,
  },
  {
    path: "/edit-tickets/:id/:userId",
    element: <EditTickets />,
  },
  {
    path: "/reset-password",
    element: <ForgotPassword />,
  },
  {
    path: "/add-vehicle",
    element: <CreateVehicle />,
  },
  {
    path: "/edit-vehicle/:id/:reg/:driverId",
    element: <EditVehicle />,
  },
  {
    path: "/role-management",
    element: <RoleManagement />,
  },
  {
    path: "/create-role",
    element: <CreateRole />,
  },
  {
    path: "/edit-role/:id",
    element: <UpdateRole />,
  },
  {
    path: "/profile-details",
    element: <ProfileDetails />,
  },
  {
    path: "/convenience-fee",
    element: <ConvenienceFee />,
  },

  {
    path: "/create-convenience-fee",
    element: <CreateConvenienceFee/>,
  },
  {
    path: "/tip",
    element: <Tip/>,
  },
  {
    path: "/create-tip",
    element: <CreateTip/>,
  },
  {
    path: "/edit-tip/:id",
    element: <EditTip/>,
  },
 
  {
    path: "/edit-convenience/:id",
    element: <EditConvenienceFee/>,
  },

  

  

  


  
  
];

export const wrappedRouterList = routeList.map(({ path, element }) => {
  return path === "/" || path === "/signinskelton" || path === "/reset-password" || path === '/reset-password/:id'
    ? {
        path,
        element,
      }
    : {
        path,
        element: <MiniDrawer>{TOKEN && element}</MiniDrawer>,
      };
});
