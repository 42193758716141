import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import { DiscountTypeData, USERS_TYPE, couponType } from "../../../FakeData/FakeData";
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import DatePickerComponent from '../../../Components/Forms/DatePickerComponent';
import TimePickers from '../../../Components/Forms/TimePickers';
import UserList from './UserList';
import ApiCall from '../../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import { useSelector } from 'react-redux';
import MyDateRangePicker from '../../../Components/Forms/MyDateRangePicker';
import { formatteStringDate } from '../../../Components/MultiFunctions/MultiFunctions';
import TextArea from '../../../Components/Forms/TextArea';
import { toast } from 'react-toastify';

function VoucherForm() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


  const [submitHit,setSubmitHit] = useState(false)
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [onSuccess, setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)

  const [VoucherName , setVoucherName] = useState('')
  const [discountType , setDiscountType] = useState('')

  const [selectedUserType , setSelectedUserType] = useState('')
  const [selectedValue , setSelectedValue] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedLimit , setSelectedLimit] = useState('')
  const [selectedLimitPerPerson,setSelectedLimitPerPerson] = useState('')
  const [expiryDate , setExpiryDate] = useState('')
  const [selectedCoupon ,setSelectedCoupon] = useState('')
  const [selectedCouponID ,setSelectedCouponID] = useState('')
  const [description,setDescription] = useState('')
  const [code,setCode] = useState('')
  const [startDate , setStartDate] = useState('')
  const [endDate , setEndDate] = useState('')
  const [startTime , setStartTime] = useState('')
  const [endTime , setEndTime] = useState('')
  const [selectedUserIDs , setSelectedUserIds] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)

  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Vouchers','CREATE')
    setWrite(isWrite)

     },[])

  const handleSelect = (label,data)=>{

    if(label === 'Discount Type'){

      setDiscountType(data?.id)
        
    }else if(label === 'Coupon Type'){
      setSelectedCoupon(data?.id)
    }else if(label === 'User Type'){
      setSelectedUserType(data?.id)
    }
          

 

  }

  const handleDateSelection =(date,label)=>{
    if(label === 'Start Date'){
      setStartDate(date)
    }else if(label === 'End Date'){
      setEndDate(date)
    }
   
   }


const handleDateRange=async(startsUtc, endsUtc)=>{
try{
  const rowDate = new Date(startsUtc);
  const rowDateTwo = new Date(endsUtc);
 
  const formattedDate  =await formatteStringDate(rowDate)
  const formattedDateTwo =await formatteStringDate(rowDateTwo)

  setStartDate(formattedDate)
  setEndDate(formattedDateTwo)

}catch(error){

}




//  const formattedDateTwo = rowDateTwo.toISOString().split('T')[0];


// rowDateTwo


  

   
   }




   const handleTimeChange = (label,data)=>{

    if(label === 'Start Time'){

      setStartTime(data)
  
    }else if(label === 'End Time'){
   
      setEndTime(data)

    }
  
  
  }

  console.log(`sttt: ${startDate} , endd:${endDate}`)

  const handleSelectedUsers = (users)=>{
   
    setSelectedUserIds(users)

  }




  const handleFormSubmit =()=>{
    setSubmitHit(true)
    
    let sendData = {
      code:code,
      couponName:VoucherName,
      description:description,
      countryId:CountryID,
      startTime: selectedCoupon === 'TIME' ? startTime : null,
      endTime:  selectedCoupon === 'TIME' ? endTime : null,
      userIds:selectedUserIDs[0] ? selectedUserIDs: null,
      usersTypeId:selectedUserType,
      value:parseFloat(selectedValue),
      couponType:selectedCoupon,
      discountType:discountType,
      usageLimit: 0,
      usePerPerson: parseInt(selectedLimitPerPerson),
      startDate:startDate,
      endDate:endDate,
}


 // Removing the null fields

 for (const key in sendData) {
  if (sendData.hasOwnProperty(key) && sendData[key] === null) {
      delete sendData[key];
  }
}


    console.log(sendData,"<--send datatatatatatat")

   if(code && VoucherName && description && selectedUserType && selectedValue && selectedCoupon && discountType && selectedLimitPerPerson && startDate && endDate ){
  console.log("<----in hereeeeeee")
    ApiCall("POST", "/trip-service/v1/admin/coupon/create",sendData,).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully Created")
          
    
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });

  }else{
    toast.error("Fill all mandatory fields")
  }


  }



  function validateCoupon(inputString) {

    // Check if the string length is between 4 and 7 characters
    if (inputString.length < 4 || inputString.length > 7) {
      return "Invalid length. The code length should be between 4 and 7 characters.";
    }
  
    // Check if all characters are uppercase letters and digits
    if (!/^[A-Z0-9]+$/.test(inputString)) {
      return "Invalid characters. The code should contain only uppercase letters and digits.";
    }
  
    // Count the number of digits in the string
    let digitCount = 0;
    for (let i = 0; i < inputString.length; i++) {
      if (/[0-9]/.test(inputString[i])) {
        digitCount++;
      }
    }
  
    // Check if the number of digits is at most 3
    if (digitCount > 3) {
      return "Too many digits. The code should contain at most 3 digits.";
    }
    // If all conditions are met, return true
    return "success";
  }


  return (
  <Box>
    <Stack spacing={3}>
{/* FIRST ROW */}

        <Stack direction={isMatch ? 'column':'row'} spacing={2} >
        <InputField sideLabel={"Voucher Name"} placeHolder={"value"} InChange={(e) => setVoucherName(e.target.value)} />
      
            <SelectField
              onSelecting={handleSelect}
              sideLabel={"Discount Type"}
              placeHolder={"select"}
              Data={DiscountTypeData}
            />
           
   
        <InputField Type={'number'} sideLabel={"value"} placeHolder={"value"} InChange={(e) => setSelectedValue(e.target.value)} />
        </Stack>



{/* SECOND ROW */}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >
        <Box width={isMatch ? '100%':'33%'}>
        <InputField Note={validateCoupon(code) === 'success' ? null : code?.length > 0 && validateCoupon(code)} sideLabel={"Code"} placeHolder={"Code"} InChange={(e) => setCode(e.target.value)} />
           </Box>
        <Box width={isMatch ? '100%':'33%'}>
        <SelectField
        onSelecting={handleSelect}
        sideLabel={"Coupon Type"}
          placeHolder={"select"}
          Data={couponType}
          />
           </Box>
        <Box width={isMatch ? '100%':'33%'}>
        <SelectField
        onSelecting={handleSelect}
              sideLabel={"User Type"}
              placeHolder={"select"}
              Data={USERS_TYPE}

            />
           </Box>


  
        </Stack>


{/* THIRD ROW*/}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >

            <Box width={isMatch ? '100%':'32.5%'}>
        <InputField Type={'number'} sideLabel={"Usage Limit"} placeHolder={"Usage Limit"} InChange={(e) => setSelectedLimit(e.target.value)} />
             </Box>
            <Box width={isMatch ? '100%':'32.5%'}>
        <InputField Type={'number'} sideLabel={"Usage Limit /Person"} placeHolder={"Usage Limit /Person"} InChange={(e) => setSelectedLimitPerPerson(e.target.value)} />
             </Box>

        </Stack>

{/* DESCRIPTION SECTION  */}

<Box width ={'100%'}>
        <TextArea sideLabel={"Description"} placeHolder={"Description"} InChange={(e) => setDescription(e.target.value)} />
           </Box>


{/* DATE ROW */}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >

        <MyDateRangePicker  handleUtc={handleDateRange}/>
        {/* <Box width={isMatch ? '100%':'32.5%'}>
        <DatePickerComponent disablePast={true}  handleSelection={handleDateSelection} sideLabel={'Start Date'}/>
         </Box>
         <Box width={isMatch ? '100%':'32.5%'}>
        <DatePickerComponent disablePast={true}  handleSelection={handleDateSelection} sideLabel={'End Date'}/>
         </Box> */}
         </Stack>

{/* CONDITIONAL RENDER */}

{selectedCoupon === 'TIME' ? (
     <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={5} >
     <Box width={'20%'}>
     <TimePickers TimeChange={handleTimeChange} sideLabel={"Start Time"} />
      </Box>
      <Box width={'20%'}>
      <TimePickers TimeChange={handleTimeChange} sideLabel={"End Time"}/>
      </Box>
     </Stack>
): selectedCoupon === 'USERS' ? (
  <UserList selectedUsers={handleSelectedUsers} UserType={selectedUserType} />
):null}
   
        <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>

        <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </Stack>

  </Box>
  )
}

export default VoucherForm
