import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import TableHead from '../../../Components/Table/TableHead'
import MyDrivers from './Cards/MyDrivers'
import MyVehicles from './Cards/MyVehicles'
import AllCards from './Cards/AllCards'

function FleetManagement() {

  //  useTitle("Create Driver - Geolah");

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 1560,
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
    
  return (
<Box>
<TableHead title={'Fleet Management '}/>  
<Stack mt={3}  direction={isMatch ? 'column':'row'} spacing={3}>
<MyDrivers/>
<MyVehicles/>
<AllCards/>
</Stack>
</Box>
  )
}

export default FleetManagement
