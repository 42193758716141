import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function PdfCard({fileName ,iconColor}) {
  return (
   <Box display="flex" justifyContent="space-between" p={1}>
    <Box
      sx={{
        backgroundColor: "#EFF0F2",
        height: "56px",
        width: "190.5px",
      }}
    >
      <IconButton
        target="_blank"
        href="https://www.africau.edu/images/default/sample.pdf"
      >
        <Stack spacing={2} alignItems="center" direction="row">
          <PictureAsPdfIcon sx={{ color:`${iconColor}` }} />
          <Typography fontWeight={500}>{fileName}</Typography>
        </Stack>
      </IconButton>
    </Box>
  </Box>
  )
}

export default PdfCard
