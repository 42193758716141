import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import PieCharts from '../../../Components/DetailsPage/PieCharts'
import { GoogleCharts } from '../../../Components/DetailsPage/GoogleCharts'
import ApexChart from '../../../Components/DetailsPage/ApexChart'
import { parseInt } from 'lodash-es'
import CanvaCharts from '../../../Components/DetailsPage/CanvaCharts'

function FeeDetailsCard({cardDetails,otherDetails}) {

  return (
  <Box padding={3}  borderRadius={'8px'} bgcolor={'white'} width={'1042px'} height={'750px'}>
 {/* FIRST BOX */}

    <Box sx={{borderBottom: '0.5px solid rgba(128, 128, 128, 0.5)'}}>
      <Stack paddingBottom={1} spacing={2}>
    <Typography sx={{textTransform:"none",fontWeight:550}} variant='h6' >Fee Details</Typography>
    <Box display={'flex'} justifyContent={'space-between'}>

      <Stack spacing={0.5}>
      <Typography sx={{textTransform:"none",fontWeight:550}} variant='body1' >Estimated amount</Typography>
      <Typography sx={{opacity:"50%"}} variant='caption'>{otherDetails?.methord === 'CASH' ?  "Cash Payment" : "Card Payment"}</Typography>
      </Stack>
      <Typography sx={{textTransform:"none",fontWeight:550}} variant='h6' >{`S$ ${parseFloat(otherDetails?.total || 0)?.toFixed(2)}`}</Typography>

    </Box>

    <Box display={'flex'} justifyContent={'space-between'}>
    <Stack spacing={0.5}>
      <Typography sx={{textTransform:"none",fontWeight:550,opacity:'50%'}} variant='caption' >Coupon</Typography>
      </Stack>
      <Typography sx={{textTransform:"none",color:"red"}} variant='caption' >{` - S$ ${parseFloat(otherDetails?.discount)?.toFixed(2)}`}</Typography>
    </Box>

    <Box display={'flex'} justifyContent={'space-between'}>
    <Stack>
      <Typography sx={{textTransform:"none",fontWeight:550,opacity:'50%'}} variant='caption' >Driver Amount</Typography>
      </Stack>
      <Typography sx={{textTransform:"none"}} variant='caption' >{`S$ ${parseFloat(otherDetails?.driverAmount)?.toFixed(2)}`}</Typography>
    </Box>

    <Box display={'flex'} justifyContent={'space-between'}>
    <Stack>
      <Typography sx={{textTransform:"none",fontWeight:550,opacity:'50%'}} variant='caption' >Geolah Amount</Typography>
      </Stack>
      <Typography sx={{textTransform:"none"}} variant='caption' >{`S$ ${parseFloat(otherDetails.geolahAmount)?.toFixed(2)}`}</Typography>
    </Box>



    </Stack>
    </Box>

   {/* FIRST TWO */}  
   <Box  width={'100%'} mt={2}>
    <Stack spacing={2}>
    <Typography sx={{textTransform:"none",fontWeight:550}} variant='h6' >Fare Breakdown</Typography> 
    {/* <PieCharts/> */}
    {/* <ApexChart chartValue={cardDetails?.values} chartLable={cardDetails?.labels} /> */}
    {/* <GoogleCharts chatDetails={cardDetails.splits}/> */}
    <CanvaCharts chartData={cardDetails}/>
    {/* <PieCharts chartData={cardDetails}/> */}
    </Stack>
   </Box>

  </Box>
  )
}

export default FeeDetailsCard