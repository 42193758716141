import React from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Stack, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';
import { COLORS } from '../../../FakeData/FakeData';
import SelectField from '../../../Components/Forms/SelectField';
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';



const breadcrumbs = [
    <Typography  key="3" color="text.primary">
      Fleet
      </Typography>,
 <Typography key="3" color="text.primary">
     AssignVehicle
      </Typography>,
  ];


function AssignVehicle() {
  useTitle("Assign Vehicle - Geolah");
  const handleSelect = (label,data)=>{
    // if(label === ''){

    // }
  }
  const handleFormSubmit =()=>{

  }


  return (
   <Box>
    <CreatePageHead title={'Assign Vehicle'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='30%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
        <Stack spacing={3}>
        <Stack spacing={1}>
        <SelectField onSelecting={handleSelect} isError={false}  Data={COLORS} sideLabel={'Select a user'} placeHolder={'choose'} />
        <SelectField onSelecting={handleSelect} isError={false}  Data={COLORS} sideLabel={'Select Type'} placeHolder={'choose'} />
        <SelectField onSelecting={handleSelect} isError={false}  Data={COLORS} sideLabel={'Select Vehicle'} placeHolder={'choose'} />
        </Stack>

        <FormSubmitButtons   handleSubmit={handleFormSubmit} />
        </Stack>
  {/* <FleetProgressionDriver/> */}
    </Box>
    </Box>
   </Box>
  )
}

export default AssignVehicle