import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHead from '../../Components/Table/TableHead'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete'
import DataTable from '../../Components/Table/DataTable'
import { useTitle } from '../../../Hooks/UseTitle'
import { useNavigate } from 'react-router-dom'
import ApiCall from '../../../Helper/ApiCalls'
import RideFilter from '../RideServiceManagement/RideFilter'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions'
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage'
import AdminFilter from './AdminFilter'
import { convertToCurrentCountryTime } from '../../Components/MultiFunctions/MultiFunctions'

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "empId",
      label: "Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      align: "left",
      sort: true,
    },

    {
      id: "job",
      label: "Job",
      minWidth: 200,
      align: "left",
      sort: true,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "phone",
        label: "Phone",
        minWidth: 170,
        align: "left",
        sort: true,
      },
      {
        id: "date",
        label: "Date",
        minWidth: 170,
        align: "left",
        sort: true,
      },
   
      {
        id: "actions",
        label: "Actions",
        minWidth: 170,
        align: "left",
        sort: false,
      },
  ]; 






function AdminModule() {

    useTitle("Administration - Geolah");

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  
    const navigate = useNavigate()
    const [any,setAny] = useState()
     
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Employee')
    setAny(isAany)

    let isWrite = checkPrivilage('Employee','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Employee','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Employee','DELETE')
     setDeletePer(isDelete)

     },[])


console.log(isFilterOn,"<---isFilterONNNNNSS--")

console.log(rows , " -}}}}}####")

    // api call

  useEffect(()=>{
    const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET",`/user-service/v1/admin/employee/get-all/?countryId=${CountryID}&&limit=1000000`, null, "uui").then((data)=>{
      if(data?.result){
  
      const empData = data?.result
      setTotalRows(empData?.length)
    
      const filteredData =  empData?.slice(currentOffset, currentOffset + currentLimit)?.map((eachItem,index)=>{

      let dateString = eachItem?.CreatedAt
      let testT = convertToCurrentCountryTime(dateString)

      const formattedDate = dayjs(dateString).format('DD-MM-YYYY')
   
      const currentStartTime  =  dateString?.slice(11, 16);
       
     
      let items = {
            _id:eachItem.ID,
            id:index+1+currentOffset,
            name:eachItem?.FirstName+" "+eachItem?.LastName,
            empId:eachItem?.AdminRegisterID,
            job:eachItem?.Designation,
            email:eachItem?.Email,
            phone:eachItem?.PhoneNumber,
            date:testT
           }
  
           return items;
     })
  
     
  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID,currentPage])

 



    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.id?.toString()?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.empId?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.job?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.phone?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.date?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
   ApiCall("PATCH", `/user-service/v1/admin/employee/delete/?employeeId=${yesData?.id}`,null).then((data)=>{
    setSuccess(data) 
    // setDeleteStatus(false)
  })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-employee/${id}`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)
  
  }


    const handleColumnFilter = (startDate, endDate,Status) => {
       console.log(`startDate: ${startDate} , endDate:${endDate}`)
       console.log(isFilterOn,",---D-D-D-D-D-D-D-D-D-D-D=====|-|-|-|")

       if(Status){  
  

        const filteredRows = rows.filter(row => {
          const rowDate = new Date(row?.date?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2-$1-$3'));
      
          return rowDate >= startDate && rowDate <= endDate;
        });
     console.log(filteredRows,"<--filteredRowssesssssssssssssssssssss")

    
       setRows(filteredRows)
       

    }
    
    else{
      console.log("its in elsee---")
      setRows(tableData);
      return;
    }


     

         // Update the filtered rows
        // setRows(filteredRows);

      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
        if(!status){
          handleColumnFilter("","",false)
        }
       
      }





    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }

  return (
any ? (
<Box>
<TableHead title={'Administration'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
      handlingPage={handlePageChange}
      totalCount={totalRows}
       DeletePermission={deletePer}
       ButtonPermission={write}
       EditPermission={edit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-employee'}
        ButtonLabel={'Create Employee'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={<AdminFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter}/>}
      />
    </Box>

</Box>
</Box>): any === false ? (
  <RestrictedUsage/>
): null

  )
}

export default AdminModule
