import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import pdfIcon from '../../../../../Assets/PDF.svg'
import downloadIcon from '../../../../../Assets/Vector.svg'

function BankInfo() {
  return (
    <Box pl={2} display={'flex'} alignItems={'center'}  width={'100%'} height={'214px'} border={'1px solid #3333'}>
    <Stack spacing={3} >
     <Typography fontWeight={550} variant='h6' >Bank Details</Typography>

     <Stack direction="row" spacing={5}>
                         <Stack >
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                  Name
                  </Typography>
                  <Typography fontWeight={450}>Mateo Miguel</Typography>
                         </Stack>

                          <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                  Bank Account Number
                  </Typography>
                  <Typography fontWeight={450}>1093393</Typography>
                        </Stack>

                          <Stack>
                  <Typography sx={{ opacity: "50%" }} variant="caption">
                  Bank Name
                  </Typography>
                  <Typography fontWeight={450}>Bank of Singapore</Typography>
                         </Stack>
               </Stack>

               <Box p={2} alignItems={'center'} display={'flex'} justifyContent={'space-between'} bgcolor={'#F8F8F8'} width={'560px'} height={'51px'} >
                <Stack width={'70%'}  direction={'row'} spacing={1}  >
                <img src={pdfIcon} alt='pdf' />
                <Typography fontSize={'15px'} variant='body2' >Bank Statement July 2024 -August 2024 </Typography>
                </Stack>
                <img src={downloadIcon} alt='download' />
               </Box>
   
    </Stack>
    </Box>
  )
}

export default BankInfo
