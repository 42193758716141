import { Box } from '@mui/material'
import React from 'react'
import DetailsCard from './DetailsCard'

function DriverPersonalDetails() {
  return (
    <Box  display="flex" justifyContent="space-between" flexWrap="wrap"  >
<DetailsCard
     CardName={"Driving License"}
     Doc1={{title:'Driving License Front' ,color:'red'} }
     Doc2={{title:'Driving License Back' ,color:'#0263D1'} }
       />
      
<DetailsCard
     CardName={"NRIC"}
     Doc1={{title:'NRIC Front' ,color:'#0263D1'} }
     Doc2={{title:'NRIC Back' ,color:'#0263D1'} }
       />

<DetailsCard
     CardName={"Vocational License"}
     Doc1={{title:'Vocational License Front' ,color:'red'} }
     Doc2={{title:'Vocational License Back' ,color:'red'} }
       />

<DetailsCard
     CardName={"Vehicle Insurance"}
     Doc1={{title:'Commercial Insurance' ,color:'red'} }
     Doc2={false}
       />


    
<DetailsCard
     CardName={"Additional Document"}
     Doc1={{title:'Additional Document Fro..' ,color:'red'} }
     Doc2={{title:'Additional Document Bac..' ,color:'red'} }
       />


<DetailsCard
     CardName={"PHV decal"}
     Doc1={{title:'PHV decal' ,color:'#50BEE8'} }
     Doc2={false}
       />


  
<DetailsCard
     CardName={"Non Singaporeans"}
     Doc1={{title:'Letter of Employement' ,color:'#0263D1'} }
     Doc2={false}
       />
    
    

   
     </Box>
  )
}

export default DriverPersonalDetails
