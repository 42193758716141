import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CreatePageHead from "../../../Components/CreatePage/CreatePageHead";

import { useTitle } from "../../../../Hooks/UseTitle";
import EditRoleForm from "./EditRoleForm";
import { checkAnyPrivilage } from "../../../../Sessions/Permisssions";
import RestrictedUsage from "../../../Components/RestrictedUsage/restrictedUsage";
import { useNavigate } from "react-router-dom";




function UpdateRole() {

  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Role Management
    </Typography>,
    <Typography key="3" color="text.primary">
      Update Role
    </Typography>,
  ];
  useTitle("Update Role - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Roles')
    setAny(isAany)
     },[])
  return (
    any ? (
    <Box>
      <CreatePageHead title={"Edit Role"} BreadList={breadcrumbs} />
      <Box
        borderRadius={"8px"}
        marginTop={2}
        bgcolor="white"
        width="97%"
        height="100%"
      >
        <Box sx={{ width: "100%", height: "100%" }} p={4}>
          <EditRoleForm />
        </Box>
      </Box>
    </Box>
    ):any === false ? (
      <RestrictedUsage/>
    ): null
  );
}

export default UpdateRole;
