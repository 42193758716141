import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLoadScript, GoogleMap } from "@react-google-maps/api";
import { MarkerF, PolylineF } from "@react-google-maps/api";
import SocketConnection from "../../../Helper/SocketConnection";
import MapSkelton from './MapSkelton'
import SearchBar from '../../Components/Forms/SearchBar'
import TableSearch from '../../Components/Forms/TableSearch'
import LocationSearch from '../../Components/LocationSearch'
import MapSearchBar from "../Forms/Map/MapSearchBar";

function MapContainer({ width, title }) {
  const [loadingData , setLoadingData] = useState(false)

  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(true)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])




  const [center] = useState({ lat: 18.559008, lng: -68.388881 });

  // const PathLines = [
  //   { lat: 18.558908, lng: -68.389916 },
  //     { lat: 18.558853, lng: -68.389922 },
  //     { lat: 18.558375, lng: -68.389729 },
  //     { lat: 18.558032, lng: -68.389182 },
  //     { lat: 18.55805, lng: -68.388613 },
  //     { lat: 18.558256, lng: -68.388213 },
  //     { lat: 18.558744, lng: -68.387929 }
  // ]

  const [zoom] = useState(16);

  const [positionIndex, setPositionIndex] = useState(0);
  const [coordinates, setCoordinates] = useState({});

  // const moveMarker = () => {
  //   setPositionIndex((prevIndex) => {
  //     if (prevIndex < PathLines.length - 1) {
  //       return prevIndex + 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(moveMarker, 200);
  //   return () => clearInterval(intervalId);
  // }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB5gb2yasQUrSvgA8D-uAcugZ1Ycaneg7A",
  });

  const CoOrdinates = (latLng) => {
    console.log(latLng, "<---000");

    let tempData = {
      lat: latLng[1],
      lng: latLng[0],
    };

    setCoordinates(tempData);
  }

  const mapStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        {
          color: "#ECECEC", // Set the color of other map features to a grayish shade
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          visibility: "off", // Set the visibility of all labels to off
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#FFFFFF", // Set the color of roads to white
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "on",
          color: "#000000", // Set the color of road labels to black
        },
      ],
    },
  ];


  const renderMap = () => {
    return (
      <Fragment>
        <GoogleMap
          center={center}
          zoom={zoom}
          mapContainerStyle={{
            height: "387px",
            width: "100%",
            borderRadius: "4px",
          }}
          options={{
            mapTypeControl: false,
            styles: mapStyles,
            }}
        >
          <Box mt={2} >
            <MapSearchBar/>
          {/* <LocationSearch/> */}
          </Box>
           
          <MarkerF
            icon={
              "https://cdn.discordapp.com/attachments/1047008846574538792/1103918908827783178/car_1.png"
            }
            position={coordinates}
          />
          {/* <PolylineF path={PathLines} options={{ strokeColor: "#FF0000 " }} /> */}
        </GoogleMap>
      
      </Fragment>
    );
  };

  return isLoaded  && loadingData ? (
    
    <Box
      sx={{
        flexWrap: "wrap",
        "& > :not(style)": {
          mt: "3%",
          width: { width },
          height: "50%",
          
        },
      }}
    >
   
        <Stack spacing={3}>
          <Typography variant="h6">
            {" "}
            <strong>{title}</strong>
          </Typography>
          { renderMap()}
        </Stack>
    
      <SocketConnection DriverData={CoOrdinates} />
    </Box>
  ): (
    <MapSkelton />
  )
}

export default MapContainer;
