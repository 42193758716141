// modal constants
export const modalConstants = {
    INSERT_MODAL_DATA: 'INSERT_MODAL_DATA',
  }
// table constants
export const tableConstants = {
  INSERT_TABLE_DATA : 'INSERT_TABLE_DATA',
}
// user constants
export const userConstants = {
  INSERT_USER_DATA : 'INSERT_USER_DATA',
}
// vehicle registration constants
export const regConstants ={
  INSERT_REG_DATA : 'INSERT_REG_DATA',
}

//basic fare constants
export const basicConstants ={
  INSERT_BASIC_DATA : 'INSERT_BASIC_DATA',
}

//all zone status handle

export const allZoneConstants  ={
  INSERT_ALLZONE_DATA : 'INSERT_ALLZONE_DATA',
}

export const allCoordinatesConstants = {
  INSERT_ALLCOORDINATES_DATA : 'INSERT_ALLCOORDINATES_DATA',
}

// handle navigation names

export const navigationConstants = {
  INSERT_NAVIGATION_DATA : 'INSERT_NAVIGATION_DATA',
}

//userPersonalConstants
export const userPersonalConstants = {
  INSERT_USERPERSONAL_DATA : 'INSERT_USERPERSONAL_DATA',
}

// country id constants

export const countryConstants = {
  INSERT_COUNTRY_DATA : 'INSERT_COUNTRY_DATA',
}

// Driver details for create driver

export const driverPersonalConstants = {
  INSERT_DRIVER_PERSONAL_DATA : 'INSERT_DRIVER_PERSONAL_DATA',
}
// Dashboard startdate and endDate

// INSERT_DASH_DATA

export const dashConstants = {
  INSERT_DASH_DATA : 'INSERT_DASH_DATA',
}


// permission constatnts 
export const permissionConstants = {
  INSERT_PERMISSION_DATA : 'INSERT_PERMISSION_DATA',
}

