import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import MyDateRangePicker from '../../../../../Components/Forms/MyDateRangePicker'
import closeIcon from '../../../../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../../../../Components/Forms/MultipleSelect'
import SelectField from '../../../../../Components/Forms/SelectField'
import ClearAllIcon from '@mui/icons-material/ClearAll';

function PaymentFilterDriver({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)
  const typeData = [
    {name:   "TOPUP"},
    {name:  "PAYRIDE"},
    {name:  "CASH_RIDE_INTERNAL_TRANSFER"},
    {name:  "INTERNAL_TRANSFER"},
    {name:  "PAYOUT"},
       ] 

       const method = [
        {name:   "Credit",id:"CREDIT"},
        {name:  "Debit",id:"DEBIT"},
  
           ]

       const handleMultipleSelect = (label,data)=>{    
        if(label === 'Type'){

          let filteredData = data?.map((item)=>{
            return item?.name
          })

          FilterColumn(filteredData.join(','),"type")
        }
      }

      const handleSelect = (label,data)=>{

        if(label === 'Method'){
           
         data?.id &&  FilterColumn(data?.id,"method") 
        }
      }

      const HandleClearFilter =()=>{
        setIsClear((i)=> !i)
      }


  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
        
      <Box width={'500px'}>
   <MyDateRangePicker clearStatus={isClear} isLta={false} handleUtc={FilterColumn}/>
      </Box>
    <Box pb={2} width={'200px'}>
    <MultipleSelect clearStatus={isClear} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Type"} Data={typeData} />
    </Box>
    {/* <Box pb={2} width={'150px'}> */}
    {/* <SelectField  isError={false} onSelecting={handleSelect} Data={method} sideLabel={'Method'} placeHolder={'select'} /> */}
    {/* <MultipleSelect isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Method"} Data={method} /> */}
    {/* </Box> */}

    <Stack direction={'row'} spacing={1}>

<IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
         <ClearAllIcon/>
</IconButton>

<IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
         <img src={closeIcon} alt="filter"/>
</IconButton>

</Stack>
      </Stack>
      
    </Box>
  )
}

export default PaymentFilterDriver
