import { Box, Button, Chip, FormControl, FormLabel, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../Components/Forms/SelectField';
import { COLORS, ENGINE_TYPE, NO_OF_SEATS, OWNERSHIP, VEHICLE_CATEGORY } from '../../../../FakeData/FakeData';
import InputField from '../../../../Components/Forms/InputField';
import ImageSelector from '../../../../Components/Forms/ImageSelector';
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import AddIcon from '@mui/icons-material/Add';
import SnackBars from '../../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ApiCall from '../../../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function AddVehicleDetails({onButtonClick,onSectionComplete,}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    //  STATES AND VARIABLES 
    const VehicleTypeID = localStorage.getItem("VehicleId");
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [submitHit,setSubmitHit] = useState(false)
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

    const [regNo,setRegNo] = useState('')
    const [remarkDetails,setRemarkDetails] = useState('')
    const [mDTlink,setMDTlink] = useState('')
    const [letterLink , setLetterLink] = useState('')
    const [selectedTaxi,setSelectedTaxi] = useState('')

const [vehicleImageLink , setVehicleImageLink] = useState('')
const [selectedMaker , setSelectedMaker] = useState('')
const [selectedModel , setSelectedModel] = useState('')
const [selectedColor , setSelectedColor] = useState('')
const [selectedSeats , setSelectedSeats] = useState('')
const [frontImageFile , setFrontImageFile] = useState()
const [featureData , setFreatureData] = useState([])
const [selectedChip, setSelectedChip] = useState([]);
const [adOnFeatures , setAdOnFeatures] = useState([])
const [allCat , setAllCat] = useState([])
const [allTaxiComp , setAllTaxiComp] = useState([])
const [selectedIND , setSelectedIND] = useState('')
const [selectedEngine , setSelectedEngine] = useState('')
const [selectedCat , setSelectedCategory] = useState('')
const [selectedOwnerShip , setSelectedOwner ] = useState('')
const [phvDecalLink , setPhvDecalLink] = useState('')
const [ltaVehicleLink , setLtaVehicleLink] = useState('')
const [vehicleType , setVehicleType] = useState('')
const [vehicleBrands , setVehicleBrands] = useState([])
const [vehicleModel, setVehicleModel] = useState([{name:"select maker"}])
const [inspectionDate , setInspectionDate] = useState('')
const [selectedMakerId , setSelectedMakerId] = useState('')
const [selectedModelId , setSelectedModelId] = useState('')
const [selectedTaxiCompId , setSelectedTaxiCompId] = useState('')
const [vehicleFrontFile , setVehicleFrontFile] = useState({})
const [phvDecalFile , setphvDecalFile] = useState({})
const [ltaVehicleLogFile , setLtaVehicleLogFile] = useState({})
const [letterOfEmploymentFile , setLetterOfEmploymentFile] = useState({})
const [MdtScreenUploadFile , setMdtScreenUploadFile] = useState({})
const currentDriverPersonaldata = localStorage.getItem("picked_driver")
const [driverId,setDriverId] = useState(0)
const [currentvehicleTypeId ,setCurrentVehicleTypeId ] = useState("")
const [write,setWrite] = useState()
  

useEffect(()=>{   
  let isWrite = checkPrivilage('Vehicles','CREATE')
  setWrite(isWrite)

   },[])


console.log(vehicleType,"<--the vehiclee typeeeeeee")
// RENDERS AND API CALLS



useEffect(()=>{
  ApiCall("GET", "/trip-service/v1/admin/value-service/adOn/features", null,).then((data)=>{
    if(data.result){
        const rowData = data?.result
    
        const filteredData = rowData?.map((eachData)=>{
            return {name:eachData?.Type,id:eachData?._id}

        })

        setAdOnFeatures(filteredData)
    }})
},[])



useEffect(()=>{
  setDriverId(currentDriverPersonaldata)
  },[currentDriverPersonaldata])

useEffect(() => {

  const apiUrl = `${baseUrl}/trip-service/v1/driver/vehicle/activity`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };


//  axios.get(apiUrl, config).then(response => {

//   if(response?.data?.result){
//     const personalData =response?.data?.result
//     setChangedData(personalData?.ChangingFeild)
//     // console.log(response?.data?.result,"<rrrrrrrrrrrrrrrrrrrrrrrrrrrRR---------")

 
//    setCurrentDetails(response?.data?.result)
//   }
  
//     })
//     .catch(error => {
//       console.error(error,"GET API ERROR");
//     });




// /trip-service/v1/app/vehicle/brand

//vehicle model and brand
const apiUrlTwo = `${baseUrl}/trip-service/v1/app/vehicle/brand`;  





    // /trip-service/v1/driver/cab/company

    const apiUrlThree = `${baseUrl}/trip-service/v1/driver/cab/company`;  


    axios.get(apiUrlThree, config).then(response => {

      if(response?.data?.result){

      const taxiCompData =  response?.data?.result
      
      let filtereditems = taxiCompData?.map((items)=>{
        return {id:items?._id,name:items?.Name}
      })

        setAllTaxiComp(filtereditems)

    
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });



// /trip-service/v1/driver/ride/service
// get vehicle type api
let currentTypeId

const apiUrlFour = `${baseUrl}/trip-service/v1/driver/ride/service`;  

axios.get(apiUrlFour, config).then(response => {

  if(response?.data?.result){

    const vehicleTypeDetails = response?.data?.result?.VehicleTypeID

    setVehicleType(vehicleTypeDetails?.Type)
   currentTypeId = vehicleTypeDetails?._id
   setCurrentVehicleTypeId(vehicleTypeDetails?._id)


   axios.get(apiUrlTwo, config).then(response => {

    if(response?.data?.result){
      const brandData = response?.data?.result 
    const filtered = brandData?.map((eachItem)=>{
      let modelArray = eachItem?.VehicleBrandModelID
  
      if(modelArray?.some((obj) => obj?.VehicleTypeID === currentTypeId)){
  
      
          return {id:eachItem?._id,name:eachItem?.Name,modelDetail:eachItem?.VehicleBrandModelID}
      }
    })
  
    // setVehicleModel()
  
    setVehicleBrands(filtered.filter((each)=> each !== undefined))
  
    }
    
      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });






  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });




      







}, [driverId,onSuccess,VehicleTypeID]);




console.log(vehicleType,"<-----the typrrrr")



















// FUNCTIONS
const handleSelect = (label,data)=>{

    if(label === 'Maker'){
      const filtered = data?.modelDetail?.map((item)=>{
           return {id:item?._id ,name:item?.Name}
      })
  
      setVehicleModel(filtered)
  
  
      setSelectedMakerId(data?.id)
    }else if(label === 'Model'){
      setSelectedModelId(data?.id)
    }else if(label === 'Color'){
        setSelectedColor(data?.name)
    } else if(label === 'Number of Seats'){
      setSelectedSeats(data?.name)
    } else if(label === 'Vehicle Category'){
        setSelectedCategory(data?.name)
    } else if(label === 'Ownership'){
       setSelectedOwner(data?.name)
    } else if( label  === 'Choose your Taxi company'){
       setSelectedTaxiCompId(data?.id)
    } else if(label === 'Engine Type'){
         setSelectedEngine(data?.name)
    }
  
    
  
  }
  
  const handleImageFiles = (files)=>{
     setVehicleFrontFile(files)
  }
  const handleImageFilesTwo = (files)=>{
    setLetterOfEmploymentFile(files)
  }
  const handleImageFilesThree = (files)=>{
    setphvDecalFile(files)
  }
  const handleImageFilesFour = (files)=>{
    setLtaVehicleLogFile(files)
  }
  const handleImageFilesFive = (files)=>{
    setMdtScreenUploadFile(files)
  }
  
    const handleDeleteChip = (chipName) => {
      setSelectedChip(
         selectedChip.filter((each) => each !== chipName)
      )
      setFreatureData(featureData.filter((each) => each.name !== chipName ))
   };
  
   const handleDateSelection =(date)=>{
    setSelectedIND(date)
   }
  
  
  
   const handleAddChip = (chipName) => {
    if (!selectedChip.includes(chipName)) {
        setSelectedChip([...selectedChip, chipName?.name]);
        setFreatureData([...featureData, chipName])
      }
  };

  const handleFormSubmit =()=>{
    setSubmitHit(true);
    const adOnIds = featureData.map(item => item.id);
  
  
    const formData = new FormData();
    formData.append('driverId',driverId);
    formData.append('vehicleBrandId',selectedMakerId);
    formData.append('vehicleBrandModelID', selectedModelId);
    formData.append('seat',selectedSeats);
    formData.append('registrationNumber', regNo);
    formData.append('vehicleColor',selectedColor);
    formData.append('rideCatagory',selectedCat )
    formData.append('inspectionDate', selectedIND )
    formData.append('ownerShip',selectedOwnerShip )
    formData.append('vehicleTypeId',currentvehicleTypeId )
    
    adOnIds.forEach((feature, index) => {
      formData.append(`adOnSeatIds[${index}]`, feature);
    });
  
  
   selectedTaxiCompId?.length > 1 && formData.append('cabCompanyId',selectedTaxiCompId)
    formData.append('vehicleEngineType',selectedEngine )
  
    vehicleFrontFile && formData.append('VehicleFrontImage', vehicleFrontFile.file);
    letterOfEmploymentFile && formData.append('LetterOfEmployment',letterOfEmploymentFile.file);
    phvDecalFile.file && formData.append('PhvDecalImage',phvDecalFile.file);
    ltaVehicleLogFile && formData.append('LtaVehicleLog',ltaVehicleLogFile.file);
    MdtScreenUploadFile && formData.append('MdtScreenUpload',MdtScreenUploadFile.file);


    ApiCall("POST", "/trip-service/v1/admin/driver/create/vehicle/details", formData).then((data)=>{
      if(data.status === 'success'){
        localStorage.setItem("RegNo",regNo)
        setOnSuccess((i) => !i )
        setOnApiSuccess("Success")

        
        onSectionComplete(true,'service')
        onButtonClick('pagethree')
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
    
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });

  }




  const chipAdornment = selectedChip ? (
    <InputAdornment position="start">
     {selectedChip.map(eachItem => (
       <Chip label={eachItem} onDelete={() => handleDeleteChip(eachItem)} />
     ))}
    </InputAdornment>
     ) : null;


  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
        <Stack spacing={2}>
{/* FIRST ROW */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'}>
<SelectField onSelecting={handleSelect}  isError={false}  Data={vehicleBrands} sideLabel={'Maker'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectField onSelecting={handleSelect}  isError={false}  Data={vehicleModel} sideLabel={'Model'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectField onSelecting={handleSelect} isError={false}  Data={COLORS} sideLabel={'Color'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectField onSelecting={handleSelect}  isError={false}  Data={NO_OF_SEATS} sideLabel={'Number of Seats'} placeHolder={'choose'} />
    </Box>

</Stack>

{/* SECOND ROW */}

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >

    <Box width={isMatch?'100%':'25%'}>
<SelectField isError={false} onSelecting={handleSelect} Data={ENGINE_TYPE} sideLabel={'Engine Type'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
    <SelectField  isError={false} onSelecting={handleSelect} Data={VEHICLE_CATEGORY} sideLabel={'Vehicle Category'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'} >
<InputField Note={regNo !== '' && regNo?.length > 8 && 'Invalid number' || regNo !== '' &&  regNo?.length < 6 && 'Invalid number'  }  isError={false} ErrNote={""} InChange={(e) => setRegNo(e.target.value)}  sideLabel={"Registration Plate"} placeHolder={"Registration Plate"} />
</Box>
{

vehicleType === 'Car' ? 
(
<Box width={isMatch?'100%':'25%'}>
<SelectField  isError={false} onSelecting={handleSelect} Data={OWNERSHIP} sideLabel={'Ownership'} placeHolder={'choose'} />
    </Box>):(
       <Box width={isMatch?'100%':'25%'}>
       <SelectField  isError={false} onSelecting={handleSelect} Data={allTaxiComp} sideLabel={'Choose your Taxi company'} placeHolder={'choose'} />
       </Box>

    )}
    </Stack>

{/* THIRD ROW */}

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >

</Stack>

{

vehicleType === 'Car' ? 
(

<>
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >


</Stack>

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3}>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector sideLabel={'PHV Decal'}  isError={false} handleFileChange ={handleImageFilesThree} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector sideLabel={'LTA Vehicle Log'}  isError={false} handleFileChange ={handleImageFilesFour} />
</Box>
</Stack>
</>
) : (


  <>
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >


</Stack>

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector sideLabel={'MDT Screen upload'}  isError={false} handleFileChange ={handleImageFilesFive} />
</Box>
</Stack>
</>)

}




{/* DATEROW */}
<Box width={isMatch?'100%':'25%'}>
<DatePickerComponent disableFuture={true} isError={false} initialValue={selectedIND} handleSelection={handleDateSelection} sideLabel={'Vehicle Inspection date'}/>
</Box>



{/* FOURTH ROW */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector sideLabel={'Vehicle front Picture'}  isError={false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector disableReq={true} sideLabel={'Letter of Employment (Optional)'}  isError={false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>

{/* FIFTH ROW */}

<Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Features</FormLabel>
         <Typography color={'red'}>*</Typography>
         </Stack>
        
         <OutlinedInput
         error={false}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder={selectedChip?.length < 1 &&"select"}
     startAdornment={chipAdornment}
   />
       </FormControl>
         </Stack>


{/* SIXTH */}

<Stack direction={'row'} spacing={2}>
         
         {
           adOnFeatures ? 
             adOnFeatures?.map((eachFeature) => !selectedChip?.includes(eachFeature?.name) && (

                 <Button
                 onClick={() => handleAddChip(eachFeature)}
                 sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',
                   '&:hover': {
                     backgroundColor: '#FFFFFF',
                     color: '#0B2863',
                   },
                 }}
                 variant="contained"
                 startIcon={<AddIcon />}
               >
             {eachFeature?.name}
               </Button>

             )) : 
             (
                 <Typography color={'red'}>No Features available</Typography>
             )
         }
  
   </Stack>

   {/* <Box width={isMatch?'100%':'32.2%'} >
<InputField  isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} />
</Box> */}

<SnackBars isProgress={true} submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    <Stack alignItems={'center'} spacing={1} direction={'row'}>
    <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>
    {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagethree"} goBack={"pageone"} /> */}
    </Stack>
</Stack>

   </Box>
  )
}

export default AddVehicleDetails