import { Avatar, Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import ActivityCard from '../../../Dashboard/Activity/ActivityCard'
import { ActivityDataTwo } from '../../../../FakeData/FakeData';

function MyDrivers() {
    const [itemsToShow, setItemsToShow] = useState(5); // Initial number of items to show
    const totalItems = ActivityDataTwo?.length;
    const remainingItems = totalItems - itemsToShow;
  
    const handleSeeMoreClick = () => {
      // Increase the number of items to show by 5 or show all if less than 5 items left
      setItemsToShow(Math.min(itemsToShow + 5, totalItems));
    };


  return (
    <Box sx={{overflowY:'scroll', '&::-webkit-scrollbar': {
        width: '5px',  // Adjust the width as needed
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'lightgray',  // Change the thumb color
        borderRadius: '5px',     // Add rounded corners to the thumb
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',  // Change the track color
      },
      scrollbarWidth: 'thin',  // Firefox scrollbar width
      scrollbarColor: 'lightgray transparent',}} p={2} borderRadius={'8px'} bgcolor={'white'} width={'300px'} height={'513px'}>
      <Typography sx={{ fontSize: '18px' }} variant='body1'>
        My Drivers
      </Typography>
      <Box mt={2}>
        {ActivityDataTwo.slice(0, itemsToShow).map((eachItem, index) => (
          <ActivityCard key={index} data={eachItem} />
        ))}
  
        {/* {remainingItems > 0 && (
          <Typography variant="body2" color="textSecondary">
            Remaining: {remainingItems}
          </Typography>
        )} */}
        {remainingItems > 0 && (
          <Box mt={5} display={'flex'} justifyContent={'space-between'}>
             <Typography variant="body2" color="textSecondary">
            {remainingItems} more drivers
          </Typography>
          <Typography onClick={handleSeeMoreClick} fontWeight={650} sx={{color:"#021640",cursor:"pointer"}}>See More</Typography>
            {/* <Button variant="outlined" onClick={handleSeeMoreClick}>
            See More
          </Button> */}
  
  
          </Box>
  
        )}
      </Box>
    </Box>
  )
}

export default MyDrivers
