import { Box, Button, IconButton, Stack} from '@mui/material'
import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import searchLens from '../../../Assets/searchBar.svg'

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    margin:'15px',
    border:'',
    borderRadius: "4px",
    border: '0.1px solid rgba(128, 128, 128, 0.3)',
    // backgroundColor: "#F2F6FA",
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
 
    marginLeft: 20,
    width: "280px",
    height:'48px'
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 30),
    
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "lightgray",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(0)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        width: "28ch",
      },
    },
  }));





function TableSearchBar({changeInSearchBar}) {
  

 return (

    <Search>
        <Stack spacing={-1} direction='row' display='revert-layer'  justifyContent="space-between">

        <SearchIconWrapper>
   
          <SearchIcon/>
        
          </SearchIconWrapper>
         
          <StyledInputBase
            placeholder={"Search"}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => changeInSearchBar(e.target.value)}
          />

        </Stack>
          
     
    
        </Search>

   



  )
}

export default TableSearchBar
