import { Alert } from "@mui/material";
import React from "react";



export default ({message}) => (
  <React.Fragment>
 <Alert onClose={()=> {}} severity={"error"}>
        {message||"Restricted Usage Alert: Unauthorized access detected. Please contact the system administrator for assistance"}
      </Alert>
  </React.Fragment>
);
