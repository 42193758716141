import { Box, Button, Checkbox, FormControlLabel, IconButton, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField';
import { couponType } from '../../../FakeData/FakeData';
import InputField from '../../../Components/Forms/InputField';
import AddIcon from '@mui/icons-material/Add';
import CotDetailsForm from './CotDetailsForm';
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate';
import deleteIcon from '../../../../Assets/Forms/Vector.svg'
import ApiCall from '../../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import { useSelector } from 'react-redux';

function CreatePartnerForm() {
    const [submitHit,setSubmitHit] = useState(false)
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [onSuccess, setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false) 
  const [isMandatory,setIsMandatory] = useState(false)
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [partnerName , setPartnerName] = useState('') 
  const [otherPartnerName , setOtherPartnerName] = useState('')   
  const [partnerId , setPartnerId] = useState(0)
  const [insuranceName , setInsuranceName] = useState('') 
  const [cotDetailsList, setCotDetailsList] = useState([]);
  const [partnerData , setPartnerData] = useState([])

  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Insurance','CREATE')
    setWrite(isWrite)

     },[])

  const [inputFields, setInputFields] = useState([
    { cotname : '', cost: '',EligibleAmount:'' }
  ]);

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

// RENDERS AND API CALLS

useEffect(()=>{
  //GETTING PARTNER DATA  
  ApiCall("GET", `/user-service/v1/admin/partner/all/?countryID=${CountryID}`, null, "uui").then((data)=>{
  if(data?.result){
    const serviceData = data?.result;
    // let other = {id:222 , name:'Other'}

    console.log(serviceData,"<------Partner Datataaa")
    let filterdService = serviceData?.map((eachItem)=>{
      return {id:eachItem?.ID , name:eachItem?.Name}
    })
    // filterdService.push(other)
    setPartnerData(filterdService)
  } })
  

  
  
  
  },[])


  useEffect(()=>{
    if (inputFields.length > 0) {
      inputFields[0].isMandatory = isMandatory;
  }

  },[isMandatory])


  console.log(inputFields,"<-----|inputt|")


  




console.log(inputFields , "<--inputfieldssssss")






// FUNCTIONS


const handleSelect = (label,data)=>{

  if(label === 'Partner Name'){
    setPartnerName(data?.name)
    setPartnerId(data?.id)
      }



}










  const handleFormSubmit =()=>{
    setSubmitHit(true)

//Converting string to float cost & eligible amount

    for (let i = 0; i < inputFields.length; i++) {
      if (inputFields[i].cost !== undefined) {
          inputFields[i].cost = parseFloat(inputFields[i].cost);
      }
      if (inputFields[i].EligibleAmount !== undefined) {
          inputFields[i].EligibleAmount = parseFloat(inputFields[i].EligibleAmount);
      }
  }


  let sendData = {
    partnerType:"Insurance",
    partnerId: partnerName !== 'Other' ?  partnerId : null,
    partnerName: partnerName === 'Other'? otherPartnerName :null,
    insuranceName:insuranceName,
    cot:inputFields,
    countryId:CountryID,

  }

 // Removing the null fields

  for (const key in sendData) {
    if (sendData.hasOwnProperty(key) && sendData[key] === null) {
        delete sendData[key];
    }
}



   if(inputFields[0]?.EligibleAmount && inputFields[0]?.cost && inputFields[0]?.cotname){

    ApiCall("POST", "/trip-service/v1/admin/insurance/create",sendData).then((data)=>{
      if(data.result === 'success'){
    
        setOnSuccess((i) => !i )
        setOnApiSuccess("Succesfully Created")
        
  
      }
  })
  .catch((error) => {
     setOnFailed((i) => !i)
    
      if(error.message){
        setOnApiFailed(error.message)
        console.log(error , "<----errrrrrr")
      }else{
        setOnApiFailed("Something went wrong.!")
      }
  
  
  });

   }
 


  }

  //DYNAMIC FORM HANDLING
  
  const handleInputChange = (index, event,name) => {
    const values = [...inputFields];
    if (name === "Cot Name") {
      values[index].cotname = event.target.value;
    } else if(name === 'Cost') {
      values[index].cost = event.target.value;
    }else if(name === 'Elegible Amount'){
      values[index].EligibleAmount = event.target.value;
    }

    setInputFields(values);
  };

  const handleAddFields = () => {
    if(inputFields?.length < 4 ){
      const values = [...inputFields];
      values.push({ cotname : '', cost: '',EligibleAmount:'' });
      setInputFields(values);
    }

  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  console.log(inputFields,"eeeeeee")


  return (
    <Box>
     
    <Stack spacing={3} >
    {/* form   */}
    <Stack spacing={2}>

{/* ROW ONE */}

    <Stack  direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'31.9%'}>
    <SelectField onSelecting={handleSelect}  sideLabel={"Partner Name"} placeHolder={"select"} Data={partnerData}/>
    </Box>
     
     { partnerName === 'Other' &&
         <Box width={'32.5%'}>
         <InputField isError={false} ErrNote={""} InChange={(e) => setOtherPartnerName(e.target.value)}   sideLabel={"Other"} placeHolder={"Other"} />
         </Box>
     }

    {/* <InputField isError={submitHit && partnerName.length < 3} ErrNote={""} InChange={(e) => setPartnerName(e.target.value)}   sideLabel={"Partner Name"} placeHolder={"Partner Name"} /> */}
    <Box width={'32.5%'}>
    <InputField isError={submitHit && insuranceName.length < 3} ErrNote={""} InChange={(e) => setInsuranceName(e.target.value)}   sideLabel={"Insurance Name"} placeHolder={"Insurance Name"} />
    </Box>
    </Stack> 




{/* DYNAMIC FORM PART */}

{inputFields.map((inputField, index) => (
  
 <Fragment key={`${inputField}~${index}`}>

<Box>

<Stack alignItems={'center'}  width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'22.5%'}>
<InputFieldUpdate Value={inputField.cotname} isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Cot Name')}   sideLabel={"Cot Name"} placeHolder={"Cot Name"} />
</Box>
<Box width={'8%'}>
<InputFieldUpdate Type={'number'} Value={inputField.cost}  isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Cost')}   sideLabel={"Cost"} placeHolder={"Cost"} />
</Box>

<Box width={'32.5%'}>
<InputFieldUpdate Type={'number'} Value={inputField.EligibleAmount} isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Elegible Amount')}   sideLabel={"Eligible Amount"} placeHolder={"Eligible Amount"} />
</Box>
{
  index === 0 ? (
<FormControlLabel onChange={(e) => setIsMandatory(e.target.checked)}  control={<Checkbox  color="success" />} label="Make Mandatory" />


  ):(
    <Box width={isMatch ? "100%" : "20%"} >
    <IconButton onClick={() => handleRemoveFields(index)}> <img src={deleteIcon} alt='delete'/> </IconButton> 
   </Box>
  )
}


</Stack>



</Box>

 </Fragment>

))}


   
     <Button disabled={inputFields?.length === 4}  onClick={() => handleAddFields()} sx={{width:'130px',height:'24px',textTransform:'none',border:'none'}} variant="outlined" startIcon={<AddIcon />}>
        Add New
      </Button>








        </Stack>
        <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit}/>

<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
<ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        </Stack>
    </Box>
  )
}

export default CreatePartnerForm
