import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import { BONUS_TYPE, COLORS, daysData} from '../../../FakeData/FakeData'
import InputField from '../../../Components/Forms/InputField'
import TimePickers from '../../../Components/Forms/TimePickers'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import ApiCall from '../../../../Helper/ApiCalls'
import MultipleSelectUpdate from '../../../Components/Forms/MultipleSelectUpdate'
import SelectField from '../../../Components/Forms/SelectField'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
function AddSurchargeForm() {

const [submitHit,setSubmitHit] = useState(false)
const [selectedZone,setSelectedZone] = useState([])
const [selectedDays,setSelectedDays] = useState([])
const [remarks,setRemarks] = useState('')
const [onSuccess , setOnSuccess] = useState(false)
const [onFailed , setOnFailed] = useState(false)
const [onApiSuccess , setOnApiSuccess] = useState('')
const [onApiFailed , setOnApiFailed] = useState('')
const [startTime , setStartTime] = useState('')
const [endTime , setEndTime] = useState('')
const [surgeAmount , setSurgeAmount] = useState('')
const [isSingleZone,setIsSingleZone] = useState(false)
const [zoneData , setZoneData] = useState([])
const [allZoneName , setAllZoneName] = useState([])
const [allZoneIds , setAllZoneIds] = useState([])
const [surgeType , setSurgeType] = useState('')
const {currentCountrydata:CountryID} = useSelector((state) => state.country)

const [write,setWrite] = useState()
  
useEffect(()=>{   
  let isWrite = checkPrivilage('Surcharges','CREATE')
  setWrite(isWrite)

   },[])


console.log(allZoneName,"<^^^^^^^^^^^66")
const handleMultipleSelect = (label,data)=>{

  if(label === 'Zone'){   
    let filteredData = data?.map((item)=>{
      return item?.id
    })
    setSelectedZone(filteredData)

  }else if(label === 'Days'){

    let filteredData = data?.map((item)=>{
      return item?.name
    })
    setSelectedDays(filteredData)
  }

}

console.log(selectedZone)

const handleTimeChange = (label,data)=>{

  if(label === 'Start Time'){
   setStartTime(data)
  }else if(label === 'End Time'){
   setEndTime(data)
  }

}


useEffect(()=>{

  ApiCall("GET", `/trip-service/v1/admin/zone/get-all/zones/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.status === 'success'){

      let rowZones = data?.result

      const filterdZOne = rowZones?.map((eachZone , index)=>{
        return {id:eachZone?._id , name:eachZone?.Name}

      })
      const filterdNames = rowZones?.map((eachZone , index)=>{
        return {name:eachZone?.Name}
      })

      const filterdId = rowZones?.map((eachZone , index)=>{
        return eachZone?._id
      })

      setAllZoneIds(filterdId)
      setZoneData(filterdZOne)
      setAllZoneName(filterdNames)
       
      console.log(data?.result,"<---------zonessssss")
    } })

},[])


const handleSelect = (label,data,index)=>{
          
  if(label === 'Type'){
    setSurgeType(data?.name)
  } 


}


      const handleFormSubmit =()=>{

      setSubmitHit(true)

      let sendData = {

        zoneIds: isSingleZone ? allZoneIds: selectedZone,
        days:selectedDays,
        startTime:startTime,
        endTime:endTime,
        surgeType:surgeType,
        amount:surgeAmount,
        isSingleZone:!isSingleZone,
        countryId:CountryID,
        isSpecialDay:false,
        type:"General",

      }

   console.log(selectedZone,"selectedzoneeeeeeeeee")

if(selectedDays.length > 0 && startTime && endTime && surgeType && surgeAmount){

  ApiCall("POST", "/trip-service/v1/admin/sur-charges/add/surge",sendData).then((data)=>{
    if(data?.status === 'success'){
      setOnSuccess(true)
    }
})
.catch((error) => {
  setOnFailed((i) => !i)
    
  if(error.message){
    setOnApiFailed(error.message)
  }else{
    setOnApiFailed("Something went wrong.!")
  }
  console.error(error, "PUT API ERROR");


});
}
     
        
      }



  return (
   <Box>
   <Stack spacing={3} >
     
   <Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
          {
            !isSingleZone ? (
              <MultipleSelect isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            ):(
              <MultipleSelectUpdate currentSelect={allZoneName} isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Zone"} Data={zoneData} />
            )
          }
       
         </Box>
         <Box width={'30%'}>
         <MultipleSelect isError={ submitHit && selectedZone.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Days"} Data={daysData} />
        
         </Box>
        </Stack>

{/* TIME SECTION */}
         <Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
        <TimePickers TimeChange={handleTimeChange} sideLabel={"Start Time"} />
         </Box>
         <Box width={'30%'}>
         <TimePickers TimeChange={handleTimeChange} sideLabel={"End Time"}/>
         </Box>
        </Stack>

 {/* REMARKS */}
{/* <Stack direction={'row'} spacing={3} >
         <Box width={'100%'}>
         <InputField isError={submitHit && remarks.length < 1} ErrNote={""} InChange={(e) => setRemarks(e.target.value)}  sideLabel={"Remarks"} placeHolder={"Remarks"} />
         </Box>
         </Stack> */}

<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsSingleZone(e.target.checked)}  control={<Checkbox  color="success" />} label="All zones ?" />

</Stack>
</Box>      

{/* SURGE AMOUNT */}

<Stack direction={'row'} spacing={3} >
<Box width={"30%"} >
    <SelectField   isError={''} onSelecting={handleSelect} Data={BONUS_TYPE} sideLabel={'Type'} placeHolder={'choose'} />
    </Box>
         <Box width={'30%'}>
         <InputField isPercentage={surgeType === 'Amount' ? false:surgeType === 'Percentage' ? true : false} Type={"number"} isError={submitHit && surgeAmount.length < 1} ErrNote={""} InChange={(e) => setSurgeAmount(e.target.value)}  sideLabel={"Surge Amount"} placeHolder={`Surge Amount in ${surgeType === 'Amount' ? 'S$':surgeType === 'Percentage' ? '%' : 'S$'}`} />
         </Box>
         </Stack>

{/* <AlertPopUp/> */}

    <SnackBars submitStatus={submitHit} successNote={'Successfully Created'} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />

{/* SAVE FORM */}

   </Stack>
   </Box>
  )
}

export default AddSurchargeForm
