import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function SelectField({onSelecting,sideLabel, placeHolder, Data,isError,disable,clearStatus}) {
 const [item, setItem] = useState({});

useEffect(()=>{
setItem({})
},[Data,clearStatus])


 useEffect(()=>{
  onSelecting(sideLabel,item)

 },[item])


    const handleChange = (event) => {
      const selectedItem = Data.find((item) => item.name === event.target.value);   
      setItem(selectedItem);
    }


  return (
    <FormControl required={sideLabel === 'Code' ? false :true} component="fieldset" sx={{ width: "100%" }}>
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
      <Select
      disabled={disable}
      error={isError}
        sx={{ borderRadius: "4px", height: "46px",backgroundColor:"white" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={item?.name || placeHolder}
        renderValue={(selected) => {
          if (!selected || selected?.length === 0) {
            return <Typography>{placeHolder}</Typography>;
          }
          return selected;
        }}
        onChange={handleChange}
      >
        {Data?.map((item) => (
          <MenuItem value={item.name}>{item.name}</MenuItem>
        ))}
      </Select>
      {/* <MyFormHelperText />   use when a helper text is required */}
    </FormControl>
  );
}

export default SelectField;
