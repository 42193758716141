import { Box, Button, Grid, Stack, createTheme, useMediaQuery } from '@mui/material';
import React from 'react'

function ProgressionSubmit({Clicking , toPage , goBack}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd: 855,
            md: 900,
            fmd: 950,
            slg: 1098,
            lg: 1200,
            sxl: 1430,
            xl: 1536,
          },
        },
      });
    
      const isSlgMatch = useMediaQuery(theme?.breakpoints?.down("slg"));
    
      const isSmdMatch = useMediaQuery(theme?.breakpoints?.down("smd"));
  return (

<Stack pb={5} spacing={2} direction={'row'}>
  
        <Button
          sx={{
            width: "114px",
            height: "40px",
            borderRadius: "8px",
            textTransform:'none',
            backgroundColor: "#F8F8F8",
            color: "black",
          }}
          variant="contained"
          onClick={() => Clicking(goBack)}
        >
          {" "}
          Back
        </Button>
      


        <Button
          sx={{
            width: "114px",
            height: "40px",
            borderRadius: "8px",
            textTransform:'none',
            backgroundColor: "#1C1B20",
          }}
          variant="contained"
          onClick={() => Clicking(toPage)}
        >
          {" "}
          Next
        </Button>

        </Stack>
     

  )
}

export default ProgressionSubmit
