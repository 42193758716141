import { Box, Button, IconButton, Stack} from '@mui/material'
import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import searchLens from '../../../Assets/searchBar.svg'

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    margin:'15px',
    border:'1px solid #ddd',
    borderRadius: "57px",
    // border: '#ffffff',
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },

  
    marginLeft: 0,
    width: "95%",
    height:'40px'
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, '96%'),
    
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#ffffff",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(0)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        width: "28ch",
      },
    },
  }));





function LongSearch() {

 return (

    <Search >
        <Stack direction='row' display='revert-layer'  justifyContent="space-between"  >

        <SearchIconWrapper>
            <IconButton wei size='small' sx={{backgroundColor:'#146ADC'}} >
            <img src={searchLens} alt='lens'/>
            </IconButton>
        
          </SearchIconWrapper>
         
          <StyledInputBase
            placeholder={"Search ride or driver"}
            inputProps={{ "aria-label": "search" }}
          />

        </Stack>
          
     
    
        </Search>

   



  )
}

export default LongSearch