import {
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  
  function SelectFieldUpdate({indexes,onSelecting,sideLabel, placeHolder, Data,isError,currentSelect }) {
   const [item, setItem] = useState({});
   const [currentItem , setCurrentItem] = useState('')
  



useEffect(()=>{
   
    setCurrentItem(currentSelect)

},[currentSelect])
  
  
   useEffect(()=>{
    if(item.name){
      onSelecting(sideLabel,item,indexes)
    }
  
  
   },[item])
  
  
      const handleChange = (event) => {
        const selectedItem = Data.find((item) => item.name === event.target.value);   
        setItem(selectedItem);
      }

  
    return (
      <FormControl required={sideLabel === 'Code' ? false :true} component="fieldset" sx={{ width: "100%" }}>
          <Stack direction={'row'}>
          <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
          <Typography color={'red'}>*</Typography>
          </Stack>
        <Select
        error={isError}
          sx={{ borderRadius: "4px", height: "46px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={!indexes && item?.name || currentItem}
          // value={currentItem}
          renderValue={(selected) => {
            if (!selected || selected?.length === 0) {
              return <Typography>{placeHolder}</Typography>;
            }
            return selected;
          }}
          onChange={handleChange}
        >
          {Data?.map((item) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
        </Select>
        {/* <MyFormHelperText />   use when a helper text is required */}
      </FormControl>
    );
  }
  
  export default SelectFieldUpdate;