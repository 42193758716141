
import React, { useEffect, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material';




import { useSelector } from 'react-redux';
import { checkAnyPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { useTitle } from '../../../Hooks/UseTitle';
import CreatePageHead from '../../Components/CreatePage/CreatePageHead';
import PersonalCard from './PersonalCard';
import AccountInfoCard from './AccountInfoCard';


const breadcrumbs = [
    <Typography  key="3" color="text.primary">
        Admin
      </Typography>,
 <Typography key="3" color="text.primary">
     Profile Details
      </Typography>,
  ];


function ProfileDetails() {
  useTitle("Profile Details - Geolah");
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const {currentUserdata} = useSelector((state) => state.user)
  const [any,setAny] = useState()
console.log(currentUserdata,"<3333333333>")
useEffect(()=>{
  
  let isAany = checkAnyPrivilage('Dashboard')
  setAny(isAany)

   },[])

  
  return (
  
   <Box>
    <CreatePageHead title={'Profile Details'} BreadList={breadcrumbs}/>
    
    <Stack mt={5} direction={'row'} spacing={2} >
     <PersonalCard/>
     {/* <AccountInfoCard/> */}
    </Stack>
 
   </Box>

  )
}

export default ProfileDetails