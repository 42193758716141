
import { Box, FormLabel, OutlinedInput, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import { useDispatch, useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'
import { insertBasicDataAction } from '../../../Redux/Actions/BasicDataActions'
import { FormControl } from '@mui/base'

function EditBasicFareForm({currentInfo}) {
// STATES AND VARIABLES
    const [submitHit,setSubmitHit] = useState(false)
    const [selectedService , setSelectedService] = useState('')
    const [selectedSplit , setSelectedSplit] = useState('')
    const [selectedCategory , setSelectedCategory] = useState('')
    const [serviceData , setServiceData] = useState([])
    const [categoryData , setCategoryData] = useState([])
    const [paymentSplitData , setPaymentSplitData] = useState([])
    const [currentSPlitName,setCurrentSPlitName] = useState('')
    const [currentCatName , setCurrentCatName] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
     const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
     const dispatch = useDispatch()  

  //RENDERS AND API CALLS




 useEffect(()=>{
  if(currentInfo){
    setSelectedSplit(currentInfo?._id)
    setCurrentSPlitName(currentInfo?.Name)
    setCurrentCatName(currentInfo?.catName)
    
  }

 },[currentInfo])



useEffect(()=>{
  //GETTING SERVICE DATA  
  ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
  if(data?.result){
    const serviceData = data?.result;
    let filterdService = serviceData?.map((eachItem)=>{
      return {id:eachItem?._id , name:eachItem?.ServiceName}
    })
    setServiceData(filterdService)
  } })
  
  // GETIING CATEGORYDATA
  ApiCall("GET", `/trip-service/v1/admin/ride-category/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
      const serviceData = data?.result;
      let filterdService = serviceData?.map((eachItem)=>{
        return {id:eachItem?._id , name:eachItem?.Name}
      })
      setCategoryData(filterdService)
    } })
  
   //GETTING PAYMENT DATA 
  
   ApiCall("GET", `/trip-service/v1/admin/ride-category/all/split/?countryId=${CountryID}`, null, "uui").then((data)=>{
    if(data?.result){
    const paymentData = data?.result;
    let filterdService = paymentData?.map((eachItem)=>{
      return {id:eachItem?._id , name:eachItem?.Name}
    })
  
    setPaymentSplitData(filterdService)
  
  
    } })
  
  
  
  },[])


  useEffect(()=>{

    let Items = {
      // valueServiceId:selectedService,
      // rideCategoryId:selectedCategory,
      paymentSplitId:selectedSplit,
     }
  
     dispatch(insertBasicDataAction(Items));
  
  
  },[selectedService,selectedCategory,selectedSplit])





// FUNCTIONS   

const handleSelect = (label,data)=>{
          
    if(label === 'Select Service'){
        setSelectedService(data?.id)
    }else if(label === 'Select Ride Category'){
         setSelectedCategory(data?.id)
    }else if(label === 'Select Payment Split'){
        setSelectedSplit(data?.id)
    }
 

  }


  return (

<Box>
<Stack spacing={3} >

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >

{/* <Box width={'50%'}>
<SelectFieldUpdate currentSelect={''} isError={ submitHit && selectedService === undefined || selectedService === ''} onSelecting={handleSelect} Data={serviceData} sideLabel={'Select Service'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectFieldUpdate currentSelect={''} isError={ submitHit && selectedCategory === undefined || selectedCategory === ''} onSelecting={handleSelect} Data={categoryData} sideLabel={'Select Ride Category'} placeHolder={'choose'} />
    </Box> */}
    <Box width={isMatch?'100%':'32%'}>

<Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Ride Category</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={currentCatName}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>
    <Box width={isMatch ? "43%" : "32%"} >
    <SelectFieldUpdate currentSelect={currentSPlitName} isError={ submitHit && selectedSplit === undefined || selectedSplit === ''} onSelecting={handleSelect} Data={paymentSplitData} sideLabel={'Select Payment Split'} placeHolder={'choose'} />
    </Box>

</Stack>

</Stack>

</Box>


  )
}

export default EditBasicFareForm
