import { Box, Stack } from '@mui/material'
import React from 'react'
import FormNav from '../Forms/FormNav'
import DriverForm from './DriverForm'

function AddDriver() {
  return (
  <Box>
       <Stack spacing={3}>

<FormNav title={'Add Driver'}/>
<DriverForm/>

</Stack>
  </Box>
  )
}

export default AddDriver
