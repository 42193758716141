import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ActivityCard from './ActivityCard'
import { ActivityData } from '../../../FakeData/FakeData';
import ApiCall from '../../../../Helper/ApiCalls';
import { useSelector } from 'react-redux';




function Activity() {
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [itemsToShow, setItemsToShow] = useState(100); // Initial number of items to show
  const [activityData , setActivityData] = useState([])
  const totalItems = activityData?.length;
  const remainingItems = totalItems - itemsToShow;

//RENDERS

useEffect(()=>{

  ApiCall("GET", `/trip-service/v1/admin/dashboard/all/activity/?countryId=${CountryID}&&offset=${0}&&limit=${20000}`, null).then((data)=>{
    if(data.status === 'success'){
     const rowData = data?.result;
     console.log(rowData,"<--- the activity datasss hereee  ")
     setActivityData(rowData)
  
  
   
    }

  }).catch((error) => {
    console.log(error,"<---the errrorr")
 });

},[itemsToShow])






  const handleSeeMoreClick = () => {
    // Increase the number of items to show by 5 or show all if less than 5 items left
    setItemsToShow(Math.min(itemsToShow + 100, totalItems));
  };




  return (

    <Box sx={{overflowY:'scroll', '&::-webkit-scrollbar': {
      width: '5px',  // Adjust the width as needed
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',  // Change the thumb color
      borderRadius: '5px',     // Add rounded corners to the thumb
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',  // Change the track color
    },
    scrollbarWidth: 'thin',  // Firefox scrollbar width
    scrollbarColor: 'lightgray transparent',}} p={2} borderRadius={'8px'} bgcolor={'white'} width={'517px'} height={'513px'}>
    <Typography sx={{ fontSize: '18px' }} variant='body1'>
      Activity
    </Typography>
    <Box mt={2}>
      {activityData.slice(0, itemsToShow).map((eachItem, index) => (
        <ActivityCard key={index} data={eachItem} />
      ))}

      {/* {remainingItems > 0 && (
        <Typography variant="body2" color="textSecondary">
          Remaining: {remainingItems}
        </Typography>
      )} */}
      {remainingItems > 0 && (
        <Box mt={5} display={'flex'} justifyContent={'space-between'}>
           <Typography variant="body2" color="textSecondary">
          {remainingItems} more activities today
        </Typography>
        <Typography onClick={handleSeeMoreClick} fontWeight={650} sx={{color:"#021640",cursor:"pointer"}}>See More</Typography>
          {/* <Button variant="outlined" onClick={handleSeeMoreClick}>
          See More
        </Button> */}


        </Box>

      )}
    </Box>
  </Box>
  )
}

export default Activity
