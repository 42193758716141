import { Box } from '@mui/material'
import React from 'react'

function VehicleDetailsDocuments() {
  return (
 <Box>
    
 </Box>
  )
}

export default VehicleDetailsDocuments
