import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import TableHead from '../../Components/Table/TableHead';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCalls';
import CategoryFilter from '../RideCategoryManagement/CategoryFilter';
import dayjs from 'dayjs';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import AdminFilter from '../AdminModule/AdminFilter';



const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
    {
        id: "roleName",
        label: "Role Name",
        minWidth: 100,
        sort: false,
      },
  
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      align: "left",
      sort: false,
      isLink:true,
    },
    {
      id: "updatedBy",
      label: "Updated By",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "lastUpdated",
      label: "Last Updated",
      minWidth: 170,
      align: "center",
      sort: false,
      title: {
        onTrue: "Online",
        onFalse: "Offline",
      },
    },
   

    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];





function RoleManagement() {

  


    useTitle("Roles - Geolah");
    const navigate = useNavigate()
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const dispatch = useDispatch()
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [any,setAny] = useState()

    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0);

    useEffect(()=>{
      let isAany = checkAnyPrivilage('Roles')
      setAny(isAany)
      let isWrite = checkPrivilage('Roles','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Roles','UPDATE')
       setEdit(isEdit)     
       let isDelete = checkPrivilage('Roles','DELETE')
       setDeletePer(isDelete)
       },[])




    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
        ApiCall("GET", `/user-service/v1/admin/role/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
          if(data?.result){
           const roleData = data?.result
           setTotalRows(roleData?.length)
           console.log(roleData,"<-----------the role data")
           const filteredData = roleData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
            let dateString = eachItem?.UpdatedAt
            const formattedDate = dayjs(dateString).format('DD-MM-YYYY')
           
            const currentStartTime  =  dateString?.slice(11, 16);

           let items={

            id:index+1,
            _id:eachItem?.ID,
            roleName:eachItem?.Name,
            description:eachItem?.Description,
            updatedBy:eachItem?.UpdatedBy,
            lastUpdated:formattedDate+" , "+currentStartTime,
            date:formattedDate,

    }
    
    return items;
    
           })
    
           setRows(filteredData)
           setTableData(filteredData);
    
       } })
      },[success,CountryID,currentPage])


      useEffect(()=>{
    
        setRows(
          tableData.filter((item) =>
          item?.roleName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.updatedBy?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.id?.toString().toLowerCase().includes(searchInput.toLowerCase())   
            ))
         },[searchInput])





      const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };
  
  
  
  
  
  
  
  
  
  
  
     // update category status
  
    const handleStatusChange =(id, newData)=>{
  
       const paramsData = {rideCategoryId:id,status:newData?.status}
       ApiCall("PATCH", "/trip-service/v1/admin/ride-category/update/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
    }
  
    // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
  
    setDeleteStatus(true)
    const paramsData ={roleId:newData?._id}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
   ApiCall("PATCH", `/user-service/v1/admin/role/delete`,yesData,'BODY').then((data)=>{setSuccess(data)})
   } 
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-role/${id}`)
    }
  }

  const handleFilterBar = (status)=>{
    setIsFilterOn(status)
    if(!status){
      handleColumnFilter("","",false)
    }
   
  }
  
  
  
  const handleChangeInSearch = (value)=>{
    setSearchInput(value)
  }
  

  
  
  
  const handleColumnFilter = (startDate, endDate,Status) => {
    console.log(`startDate: ${startDate} , endDate:${endDate}`)
    console.log(isFilterOn,",---D-D-D-D-D-D-D-D-D-D-D=====|-|-|-|")

    if(Status){  


     const filteredRows = rows.filter(row => {
       const rowDate = new Date(row.date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2-$1-$3'));
   
       return rowDate >= startDate && rowDate <= endDate;
     });
  console.log(filteredRows,"<--filteredRowssesssssssssssssssssssss")

 
    setRows(filteredRows)
    

 }
 
 else{
   console.log("its in elsee---")
   setRows(tableData);
   return;
 }


  

      // Update the filtered rows
     // setRows(filteredRows);

   };
  
  

   const handlePageChange=(no,rowsCount)=>{
    setCurrentPage(no)
    setRowsPerPage(rowsCount)
    
  }




  return (
    any ? (

<Box>
<TableHead title={'Role Management'}/> 
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
<DataTable 
     handlingPage={handlePageChange}
     totalCount={totalRows}
     DeletePermission={deletePer}
     ButtonPermission={write}
     EditPermission={edit}
        isFilterOn={isFilterOn}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        openFilter={handleFilterBar}
        ButtonPath={'/create-role'}
        ButtonLabel={'Create Role'}
        handleEdit={handleServiceEdit}
        // columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={<AdminFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter}/>}
      />

</Box>


    </Box>  

</Box> ):any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default RoleManagement
