import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import imageIcon from "../../../Assets/Group 4719.svg";
import browseFrame from "../../../Assets/Forms/Frame 47916 (2).svg";
import { useFileUpload } from "use-file-upload";
import "./Style.css"
import DownloadIcon from '@mui/icons-material/Download';




export const ModalPopup = ({ src, alt, caption, onClose , label }) => {

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = label; 
    link.click();
  }

  return (
    <div className="modal">
      <span className="close" onClick={onClose}>
        &times;
      </span>
      
      <img className="modal-content" src={src} alt={alt} />
      {<div className="caption">
      <Button onClick={downloadImage} sx={{color:'white',backgroundColor:"#021640",textTransform:'none',borderRadius:'6px',  '&:hover': {color: 'black',backgroundColor:"white"}}} component="label" variant="contained" startIcon={<DownloadIcon />}>
  Dowload file
</Button>
        </div>}

    </div>
  )
}






function ImageSelectorUpdate({handleFileChange,isError,imageLink,sideLabel,disableReq}) {
  const [files, selectFiles] = useFileUpload();
  const [isOpen, setIsOpen] = useState(false)
  const [isExeded,setIsExeded] = useState(false)
  const showModal = () => setIsOpen(true)

  useEffect(() => {
    !isExeded && handleFileChange(files);
   }, [files]);



  const handleFileSelect = ({ name, size, source, file }) => {
    console.log(size , " <-----")
    if (size <= 1000000) {
      selectFiles({ accept: "image/*" }, { name, size, source, file });
      setIsExeded(false)
    }else {
      setIsExeded(true)
      selectFiles({})
      console.log("File size exceeds the limit (1 MB).");
    }
  };


  return (
    <Stack spacing={0.5}>
      <Stack direction={'row'}>
      <Typography color={"black"}>{sideLabel||'Profile Picture'}</Typography>
      { !disableReq && <Typography color={'red'}>*</Typography>}
      </Stack>
     
      {/* <Avatar color={"black"}>Profile Picture <Avatar color={'red'}>*</Avatar> </Avatar> */}

      <Box
        pl={2}
        display={"flex"}
        alignItems={"center"}
        border={ isError ?"1px dashed #c0392b":"1px dashed #c6c6c6"}
        width={"100%"}
        height={"120px"}
      >
        <Box
          alignItems={"center"}
          width={"99%"}
          display={"flex"}
          justifyContent={"space-evenly"}
        >
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
          {/* <a href={imageLink} target="_blank" rel="noopener noreferrer"> */}
            <img
             onClick={showModal}
              style={{ width: "120px", height: "70px",cursor:"pointer" }}
              src={isExeded ? imageIcon : files?.source  ? files?.source  : imageLink || imageIcon}
              alt="profile"
            />
            {/* </a> */}
          
          </Stack>
          <Stack>
          <IconButton
              onClick={() =>
                selectFiles({ accept: "image/*" }, handleFileSelect)
              }
            >
              <img
                style={{ width: "79px", height: "44px" }}
                src={browseFrame}
                alt="profile"
              />
            </IconButton>
            <Typography  fontSize={13} color={isExeded ?"red" : "grey"}>
              Upload an image. Max size 1 MB
            </Typography>
          </Stack>
    
        </Box>
      </Box>
      {isOpen && (
        <ModalPopup
          src={imageLink}
          alt="snow"
          caption={sideLabel}
          onClose={() => setIsOpen(false)}
          label={sideLabel}
        />
      )}
    </Stack>
  );
}

export default ImageSelectorUpdate;
