import { Box, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../Components/Forms/SelectField'
import { NO_OF_SEATS } from '../../../../FakeData/FakeData'
import SelectFieldUpdate from '../../../../Components/Forms/SelectFieldUpdate';
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate';
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import ImageSelectorUpdate from '../../../../Components/Forms/ImageSelectorUpdate';
import SaveAndVerify from '../../../../Components/Forms/SaveAndVerify';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotifiProgress from '../../../../Components/Forms/NotifiProgress';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function EditInsuranceDetails({onButtonClick,onSectionComplete}) {
  const [onSuccess , setOnSuccess] = useState(false)
  const [onSuccessv , setOnSuccessv] = useState(false)

  const [edit,setEdit] = useState()
  

useEffect(()=>{  
  let isEdit = checkPrivilage('Vehicles','UPDATE')
  setEdit(isEdit)
   },[])

   


    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });

// STATES AND VARIABLES
const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
const [submitHit,setSubmitHit] = useState(false)
const [submitHitv,setSubmitHitv] = useState(false)      
const [insuranceNumber , setInsuranceNumber] = useState('')
const [selectedExp , setSelectedExp] = useState('')
const { driverId } = useParams(); 
// const { currentRegdata } = useSelector((state) => state.regno);
const [currentDetails , setCurrentDetails] = useState({})
const [selectedCompany , setSelectedCompany] = useState('')
const [imageLink,setImageLink] = useState('')
const [allInsurance , setAllInsurance] = useState([])
const [insuranceFrontFile , setInsuranceFrontFile] = useState({})
const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false)
const [remarkDetails,setRemarkDetails] = useState('')
const [insuranceId , setInsuranceId] = useState(0)
const [isVerified,setIsVerified] = useState(false)
const [changedData , setChangedData] = useState({})
const { reg:currentRegdata } = useParams(); 




//RENDERS AND API CALLS

// /trip-service/v1/app/insurance/insurance/activity/{registrationNumber}
useEffect(()=>{

    const apiUrl = `${baseUrl}/trip-service/v1/app/insurance/insurance/activity/${currentRegdata}`;
 
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
  
      },
    };

    axios.get(apiUrl, config).then(response => {

        if(response?.data?.result){
          const personalData =response?.data?.result
          setChangedData(personalData?.ChangingFeild)
            setCurrentDetails(response?.data?.result)
 
        
        }
        
          })
          .catch(error => {
            console.error(error,"GET API ERROR");
          });


//   /pay-service/v1/common/insurance/list     

const apiUrlTwo = `${baseUrl}/pay-service/v1/common/insurance/list`;
axios.get(apiUrlTwo, config).then(response => {

    if(response?.data?.result){
      const insuranceData = response?.data?.result;

    const filterData = insuranceData?.map((eachItem)=>{
        return {id:eachItem?.ID,name:eachItem?.Name}
    })
    setAllInsurance(filterData)
  
    
    }
    
      })
      .catch(error => {
        console.error(error,"GET API ERROR");
      });



},[driverId,currentRegdata,onSuccess,onSuccessv])



useEffect(()=>{
    if(currentDetails){
      onSectionComplete(true,'service')
   setRemarkDetails(currentDetails?.Remarks)
   setInsuranceId(currentDetails?.InsuranceID)
   setIsVerified(currentDetails?.IsVerified)
   setSelectedCompany(currentDetails?.InsuranceCompanyName)
   setInsuranceNumber(currentDetails?.InsuranceNumber)
   let formatedDates = formatteDate(new Date(currentDetails?.ExpiryDate)) 
   setSelectedExp(formatedDates)
   setImageLink(currentDetails?.InsuranceFrontPath)


    }

},[currentDetails])



//FUNCTIONS


const handleSubmit = ()=>{
  setSubmitHit(true)
    const formData = new FormData();
    formData.append('registrationNumber',currentRegdata);
    formData.append('insuranceCompanyName', selectedCompany);
    formData.append('insuranceNumber', insuranceNumber);
    formData.append('expiryDate', selectedExp);
    formData.append('remarks', remarkDetails?.toString());
    formData.append('insuranceId', insuranceId);
    // formData.append('insuranceId',selectedInsuranceId); 
    // formData.append('vocationalLicenceNumber', vocationalLicenceNumber);
    // formData.append('vocIssueDate',vocationalIssueDate );
    insuranceFrontFile && formData.append('InsuranceFrontImage',insuranceFrontFile.file);

    const apiUrl = `${baseUrl}/trip-service/v1/app/insurance/update/details`;  
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
        'Content-Type': 'multipart/form-data',
  
      },
    };
  
    axios.put(apiUrl, formData, config)
    .then(response => {
      if(response?.data?.status === 'success'){
        setOnSuccess(true)
      }
    
    })
    .catch(error => {
      setOnFailed((i) => !i)
      
      if(error.message){
        setOnApiFailed(error.message)
      }else{
        setOnApiFailed("Something went wrong.!")
      }
      console.error(error, "PUT API ERROR");
    });

}



const handleVerify =()=>{
    setSubmitHitv(true);
    const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/verify/insurance/details`; 
    const TOKEN = localStorage.getItem("jwtToken");
    const config = {
      headers: {
        ['x-user-id']: driverId,
        'Authorization': `Bearer ${TOKEN}`,
        ['x-app-type']: 'AdminApp',
        'Content-Type': 'application/json', // Set the content type for JSON data
      },
    };
  
    let verifyData={driverId:driverId,vehicleRegNumber:currentRegdata}
  
    axios.put(apiUrl,verifyData,config)
    .then(response => {
      if(response?.data?.status === 'success'){
        setOnSuccessv(true)
      }
  
    })
    .catch(error => {
      console.error(error, "PUT API ERROR");
    });
    
  }



const formatteDate = (issueDate)=>{
    let year = issueDate?.getFullYear();
    let month = (issueDate?.getMonth() + 1)?.toString()?.padStart(2, "0");
    let day = issueDate?.getDate()?.toString()?.padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate
   }

const handleDateSelection =(date)=>{
    setSelectedExp(date)
   }

   const handleImageFiles = (files)=>{
   setInsuranceFrontFile(files)
  
    }


//Insurance Company

const handleSelect = (label,data)=>{
          
    if(label === 'Insurance Company'){
           setSelectedCompany(data?.name)
    }
  
  }


  return (

<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
 <Stack spacing={2}>

{/* FIRST ROW */}

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate currentSelect={selectedCompany} isError={changedData?.InsuranceCompanyName? true : false} onSelecting={handleSelect} Data={allInsurance} sideLabel={'Insurance Company'} placeHolder={'choose'} />
</Box>
<Box width={isMatch?'100%':'25%'} >

<InputFieldUpdate Value={insuranceNumber} isError={changedData?.InsuranceNumber? true : false} ErrNote={""} InChange={(e) => setInsuranceNumber(e.target.value)}   sideLabel={"Insurance Number"} placeHolder={"Insurance Number"} />
</Box>
</Stack>

{/* SECOND ROW */}

<Box width={isMatch?'100%':'25%'} >
<DatePickerComponent disablePast={true} isError={changedData?.ExpiryDate? true : false} initialValue={selectedExp} handleSelection={handleDateSelection} sideLabel={'Expiry Date'}/>
</Box>

{/* THIRD ROW */}

<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'Commercial Insurance'} imageLink={imageLink} isError={changedData?.InsuranceFrontPath? true : false} handleFileChange ={handleImageFiles} />
</Box>

<Box width={isMatch?'100%':'32.2%'} >

{/* <InputFieldUpdate Value={remarkDetails} isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} /> */}
</Box>

 </Stack>
 <NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
        <NotifiProgress closeNotification={() => setOnSuccessv(false)} submitStatus={submitHitv} successNote={'Success'} Status={onSuccessv} />
        <ErrorNotify submitStatus={submitHit || submitHitv} Status={submitHit && onFailed} errorNote={onApiFailed} />
        <Stack mt={1}>
        <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleSubmit}/>
        </Stack>
      
 {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagetwo"} goBack={"pageone"} /> */}
</Box>
  )
}

export default EditInsuranceDetails