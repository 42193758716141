import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { insertAllZoneDataAction } from '../../Redux/Actions/AllZoneActions';
import { useDispatch, useSelector } from 'react-redux';
import ApiCall from '../../../Helper/ApiCalls';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));





function AllZone({zoneDetailStatus}) {

    const [isChecked, setIsChecked] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const dispatch = useDispatch()
    const {currentAllZonedata} = useSelector((state) => state.allzone)

    const handleSwitchChange = (event) => {
      dispatch(insertAllZoneDataAction(event.target.checked))
      setIsChecked(event.target.checked);
    };

      //RENDERS

     useEffect(()=>{

    

     },[isChecked])

     useEffect(()=>{

      ApiCall("GET", "/trip-service/v1/admin/sur-charges/surge/type/?countryId=1", null, "uui").then((data)=>{
        if(data?.result){
          const surgeData = data?.result

          setIsStatus(surgeData?.CurrentSurgeSelected === 'SINGLE_ZONE' ? false : surgeData?.CurrentSurgeSelected ==='ALL_ZONE' ? true : null)


          console.log(surgeData,"<---toggleee")



  
    
     
     
     } })
    },[zoneDetailStatus,currentAllZonedata])



 


  return (
<Box
  bgcolor={'white'}
  width={'150px'}
  height={'48px'}
  border={'1px solid rgba(128, 128, 128, 0.5)'}
  borderRadius={'8px'}
  alignItems={'center'}
  display={'flex'}
  justifyContent={'space-between'}
  padding={1}
>

    {/* <Typography variant='caption'>All Zone </Typography> */}

    <FormControlLabel
        control={<IOSSwitch  sx={{ m: 1 }}  checked={isStatus} onChange={handleSwitchChange}  />}
        label={'All Zone'}
      />
    





</Box>
  )
}

export default AllZone
