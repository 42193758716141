import { Box, IconButton, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import ApiCall from '../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import SelectField from '../../Components/Forms/SelectField'
import ClearAllIcon from '@mui/icons-material/ClearAll';

function CategoryFilter({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const Seats = [
      {name:"4"},{name:"6"}
    ]
    const VehicleCategory = [
        {name:'Economy'},{name:'Premium'},{name:'N/A'},
    ]

    const [rideService,setRideService] = useState([])

    useEffect(()=>{
      ApiCall("GET", `/trip-service/v1/admin/value-service/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
          const serviceData = data?.result
          const filteredData =  serviceData?.map((eachItem,index)=>{
              return {name:eachItem?.ServiceName}
          })
          setRideService(filteredData)
        }
      })

    },[])
   


    const handleMultipleSelect = (label,data)=>{

      if(label === 'Seats'){

        let filteredData = data?.map((item)=>{
          return item?.name
        })
    
     FilterColumn(filteredData,"")
    
      }else if(label === 'Vehicle Category'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }else if(label === 'Service Name'){
        FilterColumn([data?.name],"")
      }
    
    }

    const HandleClearFilter =()=>{
      setIsClear((i)=> !i)
    }


  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={Seats} fieldName={'Seats'}/> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Seats"} Data={Seats} />
    </Box>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={VehicleCategory} fieldName={'Vehicle Category'} /> */}
    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} Data={VehicleCategory} />
    </Box>

    {/* <Box  width={'250px'}>
    <MultipleSelect notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Service Name"} Data={rideService} />
    </Box> */}

    <Box  width={'250px'}>
    <SelectField clearStatus={isClear} onSelecting={handleMultipleSelect}  isError={false}  Data={rideService} sideLabel={'Service Name'} placeHolder={'choose'} />
    </Box>

    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
    </Stack>
    
  </Box>
  )
}

export default CategoryFilter
