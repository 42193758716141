import { Box, Grid, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InputField from '../../../../Components/Forms/InputField';
import ImageSelector from '../../../../Components/Forms/ImageSelector';
import GenderSelect from '../../../../Components/Forms/GenderSelect'
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent'
import SnackBars from '../../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import { PH_CODE } from '../../../../FakeData/FakeData'
import SelectField from '../../../../Components/Forms/SelectField'
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import ApiCall from '../../../../../Helper/ApiCalls';
import { insertDriverPersonalDataAction } from '../../../../Redux/Actions/DriverPersonalActions';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';
import { toast } from 'react-toastify';
const nric = require('nric');

function PersonalForm({onButtonClick,onSectionComplete}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    //  STATES AND VARIABLES 
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [submitHit,setSubmitHit] = useState(false)
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
    
    const [selectedDob,setSelectedDob] = useState('')
    const [phoneNumber , setPhoneNumber] = useState('')
    const [email,setEmail] = useState('')
    const [gender , setGender] = useState('')
    const [address , setAddress] = useState('')
    const [floor , setFloor] = useState('')
    const [unit , setUnit] = useState('')
    const [postal , setPostal] = useState('')
    const [nricNumber , setNricNumber] = useState('')
    const [frontImageFile , setFrontImageFile] =useState()
    const [backImageFile , setBackImageFile] = useState()
    const [userRegId , setUserRegId] = useState('')
    const [lastName , setLastName] = useState('')
  
    const [fullName , setFullName] = useState('')
    const [nricFrontFile , setNricFrontFile] = useState({})
    const [profileFile , setProfileFile] = useState({})
    const [nricBackFile , setNricBackFile] = useState({})
    const [remarkDetails,setRemarkDetails] = useState('')
    const [selectedCod , setSelectedCod] = useState()
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const dispatch = useDispatch()

    const [write,setWrite] = useState()
  

    useEffect(()=>{   
      let isWrite = checkPrivilage('Drivers','CREATE')
      setWrite(isWrite)
  
       },[])

//RENDERS

useEffect(()=>{
setSelectedCod('+65')
},[])





// FUNCTIONS

const handleSelect = (label,data)=>{
          
  if(label === 'Code'){
         setSelectedCod(data?.name)
  }


}


const handleDateSelection =(date)=>{
    setSelectedDob(date)
   }

const handleGenderSelection = (gender)=>{
    setGender(gender)
  }
  
  const handleImageFiles = (files)=>{
    setNricFrontFile(files)
  
    }
    const handleProfileImageFiles = (files)=>{
      setProfileFile(files)
    
      }
  
    const handleImageFilesTwo = (files)=>{
      setNricBackFile(files)
      }

      const handleFormSubmit =()=>{
        setSubmitHit(true);
        const formData = new FormData();
         
        formData.append('usertypeId',2);
        formData.append('phoneNumber',selectedCod+phoneNumber?.toString());
        formData.append('firstName', fullName);
        formData.append('lastName', lastName);
        formData.append('gender', gender);
        formData.append('email',email); 
        formData.append('dateOfBirth', selectedDob);
        formData.append('countryId',CountryID);
        formData.append('address', address);
        formData.append('floor', floor?.toString());
        formData.append('unit', unit?.toString());
        formData.append('NRICNumber', nricNumber);
        formData.append('postal', postal?.toString());
        
        formData.append('DriverProfileImage',profileFile?.file);
        formData.append('NricFrontImage',nricFrontFile?.file);
        formData.append('NricBackImage',nricBackFile?.file);

        if(selectedCod && phoneNumber && fullName && lastName && gender && email && selectedDob && address && floor && unit && nricNumber && postal && profileFile?.file && nricFrontFile?.file && nricBackFile?.file){


        

        ApiCall("POST", "/trip-service/v1/admin/driver/create/profile/details", formData,).then((data)=>{
          if(data.status === 'success'){
            console.log(data?.result , "<----- data resulllttttttttt <-=--------")
            dispatch(insertDriverPersonalDataAction(data?.result))
            setOnSuccess((i) => !i )
            setOnApiSuccess("Success")
            onButtonClick('pagetwo')
            onSectionComplete(true,'personal')
            
      
          }
      })
      .catch((error) => {
         setOnFailed((i) => !i)
        
          if(error.message){
            setOnApiFailed(error.message)
            console.log(error , "<----errrrrrr")
          }else{
            setOnApiFailed("Something went wrong.!")
          }
      
      
      });
    }else{
      toast?.error("Fill all mandatory fields")
    }


      }


  return (
<Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>


<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'31.7%'}>
<ImageSelector sideLabel={'Profile pic'}  isError={false} handleFileChange ={handleProfileImageFiles} />
  </Box>
  </Stack>




<Stack mt={2} spacing={2}>
{/* FIRST ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch ? '100':'33%'}>
  <InputField Type={'text'}  isError={false} ErrNote={""} InChange={(e) => setFullName(e.target.value)}   sideLabel={"First Name"} placeHolder={"FullName"} />
</Box>
<Box width={isMatch ? '100':'33%'}>
  <InputField Type={'text'}  isError={false } ErrNote={""} InChange={(e) => setLastName(e.target.value)}   sideLabel={"Last Name"} placeHolder={"Last Name"} />
</Box>

<Box width={isMatch ? '100%':'33%'}>
<Grid container>
<Grid item xs={3}>
<SelectField  isError={false} onSelecting={handleSelect} Data={PH_CODE} sideLabel={'Code'} placeHolder={'+65'} />
</Grid>
<Grid item xs={0.2} />

<Grid item xs={8.5} >
<InputField Note={ selectedCod === '+91' && phoneNumber?.length > 10 && 'invalid phone number' || selectedCod === '+65' && phoneNumber?.length > 8 && 'invalid phone number'}    Type={'number'} Value={parseInt(phoneNumber)} isError={false} ErrNote={""} InChange={(e) => setPhoneNumber(e.target.value)}   sideLabel={"Phone"} placeHolder={"Phone"} />
</Grid>
</Grid>
</Box>


</Stack>


{/* SECOND ROW */}
<Stack alignItems={'center'} mt={1} direction={'row'} spacing={3} >
  <Box width={isMatch?'25%':'32.2%'}>
  <DatePickerComponent isAdult={true} disableFuture={true} isError={false}  handleSelection={handleDateSelection} sideLabel={'Date of Birth'}/>
  </Box>


<Box width={isMatch ? "100%" : "32.5%"} >
<InputField Note={email &&  !validator?.isEmail(email || '') && 'Invalid email' } Value={email} isError={submitHit && email?.length < 3} ErrNote={""} InChange={(e) => setEmail(e.target.value)}   sideLabel={"Email Id"} placeHolder={"Email Id"} />
</Box>

<GenderSelect changeGender={handleGenderSelection} currentGender={gender}/>
</Stack>

{/* THIRD ROW */}

<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'100%'} >

<InputField Value={address} isError={false} ErrNote={""} InChange={(e) => setAddress(e.target.value)}   sideLabel={"Address"} placeHolder={"Address"} />
</Box>
</Stack>

{/* FOURTH ROW */}

<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'} >
<InputField Type={'number'} Value={floor} isError={false} ErrNote={""} InChange={(e) => setFloor(e.target.value)}   sideLabel={"Floor"} placeHolder={"Floor"} />
</Box>
<Box width={isMatch?'100%':'25%'} >
<InputField Type={'number'} Value={unit} isError={false} ErrNote={""} InChange={(e) => setUnit(e.target.value)}   sideLabel={"Unit"} placeHolder={"Unit"} />
</Box>
<Box width={isMatch?'100%':'25%'} >
<InputField Type={'number'} Note={postal?.length > 6 && 'Invalid postal code'} Value={postal} isError={false} ErrNote={""} InChange={(e) => setPostal(e.target.value)}   sideLabel={"Postal"} placeHolder={"Postal"} />
</Box>
<Box width={isMatch?'100%':'25%'} >
<InputField Note={ nricNumber && !nric.validate(nricNumber) && 'Invalid NRIC Number' } Value={nricNumber} isError={false} ErrNote={""} InChange={(e) => setNricNumber(e.target.value)}   sideLabel={"NRIC Number"} placeHolder={"NRIC Number"} />
</Box>
</Stack>

{/* FIFTH ROW */}


{/* SIXTH ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >

<ImageSelector sideLabel={'Front image of NRIC'}  isError={false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelector sideLabel={'Back image of NRIC'}  isError={false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>

<SnackBars isProgress={true} submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
   <Stack  alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />
  {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagetwo"} goBack={"pageone"} /> */}
    </Stack>     


</Stack>

</Box>

  )
}

export default PersonalForm
