
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import EditAdmin from './EditAdmin';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';





function EditEmplyee() {
  useTitle("Edit Employee - Geolah");
  const navigate = useNavigate()

  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Administration
      </Typography>,
 <Typography key="3" color="text.primary">
     Edit Employee
      </Typography>,
  ];
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Employee')
    setAny(isAany)
     },[])
  return (
    any ? (
   <Box>
    <CreatePageHead title={'Edit Employee'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
        <EditAdmin/>
    </Box>
    </Box>
   </Box> ): any === false ? (
    <RestrictedUsage/>
   ) : null
  )
}

export default EditEmplyee
