import { combineReducers } from 'redux'
import { ModalReducer } from './ModalReducer'
import { TableReducer } from './TableReducer'
import { UserReducer } from './UserReducer'
import { PermissionReducer } from './PermissionReducer'
import { RegReducer } from './RegReducer'
import { BasicReducer } from './BasicDataReducer'
import { AllZoneReducer } from './AllZoneReducer'
import { UserPersonalReducer } from './UserPersonalReducer'
import { NavigationReducer } from './NavigationReducer'
import { CountryReducer } from './CountryReducer'
import { AllCoordinatesReducer } from './AllCoordinatesReducer'
import { DriverPersonalReducer } from './DriverPersonalReducer'
import { DashReducer } from './DashDataReducer'



const RootReducers = combineReducers({
    //modal

    modal : ModalReducer,

    //table details

    
    table : TableReducer,

    //User details

    user :  UserReducer,

    //permission data

    permission:PermissionReducer,

    // vehicle reg data for driver details insurance api 

    regno:RegReducer, 

    // basicfare formdata for the top portion 
   
    basic:BasicReducer,

    // handle the allzone state in surge page 

    allzone:AllZoneReducer,
    
    // handle navigation selection 

    navigation:NavigationReducer,
    // handle selection of country in top navbar

    country:CountryReducer,

   // handle all the zone coordnates in zonemangement
   
    coordinates:AllCoordinatesReducer,

    // handle the personal details from user  table to userdetails
    
    userPersonal:UserPersonalReducer,

    // handel driver id in create driver in admin panel..

    driverPersonal:DriverPersonalReducer,

    // dash reducer

    dashboard:DashReducer,

})

export default RootReducers