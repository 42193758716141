import { Box, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../Hooks/UseTitle';
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import TripCard from './TripCard';
import UserDriverDetailsCard from './UserDriverDetailsCard';
import TripTimelineCard from './TripTimelineCard';
import FeeDetailsCard from './FeeDetailsCard';
import { useNavigate, useParams } from 'react-router-dom';
import ApiCall from '../../../../Helper/ApiCalls';
import dayjs from 'dayjs';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';



function RideMainDetails() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate('/ride')}  key="3" color="text.primary">
      Ride
      </Typography>,
 <Typography key="3" color="text.primary">
      Ride Details
      </Typography>,
  ];
  useTitle("Ride Details - Geolah");
  const { id:rideId } = useParams(); 
  const [rideDetails,setRideDetails] = useState({})
  const [userDetails,setUserDetails] = useState({})
  const [driverDetails,setDriverDetails] = useState({})
  const [graphDetails,setGraphDetails] = useState({})
  const [timeLineDetails,setTimeLineDetails] = useState([])
  const [tripRequestId , setTripRequestId] = useState('')
  const [any,setAny] = useState()
  const [otherData,setOtherData] = useState({})

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Ride Details')
    setAny(isAany)
     },[])

  
  const theme = createTheme({
    breakpoints: {
      values: {
        ht: 1787,
    
      },
    },
  });



  useEffect(()=>{

  



    ApiCall("GET", `/trip-service/v1/admin/trip/ride/details/byId/?tripDetailsID=${rideId}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const rideData = data?.result?.[0]
       setTripRequestId(rideData?.TripRequestID)
       console.log(rideData , " <---rideDatrara")

      const formattedDate = dayjs(rideData?.Date).format('YYYY-MM-DD')
      let dateString = rideData?.Date
      const currentStartTime  =  dateString?.slice(11, 16);
      let DestinationAddress = rideData?.UserDestinationInfo
   
      let addressLength =  DestinationAddress?.length 

      let fareDetails =rideData?.CabDurationFareInfo

      console.log(fareDetails,"<---fareeeeeeeeeee---the fareeeeeee")


      
      let rideDetailz = {
        dateTime:`${formattedDate},${currentStartTime}`,
        rideStatus:rideData?.CurrentRideStatus,
        pickupAddress:rideData?.UserPickUpInfo?.PickupPointAddress,
        destinationAddress:rideData?.UserDestinationInfo?.[addressLength-1]?.Address,
        totalDistance:fareDetails?.TotalDistance/1000,
        categoryName:rideData?.rideCategory?.[0]?.Name,
        vehicleImage:rideData?.driverVehicleDetails?.[0]?.VehicleFrontUrl
      }

      let driverDetailz = {
        phone:rideData?.driverProfileDetails?.[0]?.PhoneNumber,
        email:rideData?.driverProfileDetails?.[0]?.Email,
        name:rideData?.driverProfileDetails?.[0]?.FirstName +" "+rideData?.driverProfileDetails?.[0]?.LastName,
        profile:rideData?.driverProfileDetails?.[0]?.DriverProfilePicPath,
        rating:parseFloat(rideData?.driverProfileDetails?.[0]?.DriverRating?.TotalRating),
      }


      let userDetailz ={
        phone:data?.result?.PhoneNumber,
        email:data?.result?.email,
        name:data?.result?.FullName,
        profile:data?.result?.passangerImage,  
        rating:parseFloat(data?.result?.PassangerReviewRating),
      }


      let extraData={
        methord:rideData?.PaymentMode,
        total:parseFloat(fareDetails?.TotalFare),
        geolahAmount:fareDetails?.GeolahAmount,
        driverAmount:fareDetails?.DriverAmount,
        discount:fareDetails?.CouponDiscount === undefined ? 0 : fareDetails?.CouponDiscount
      }

      setOtherData(extraData)

//data filtered for mui pie chart
const resultData = []
for (const key in fareDetails){
  if(key !== "TotalFare" && typeof fareDetails[key] === "number" && Number.isInteger(fareDetails[key])){
    resultData.push({value:fareDetails[key],label:key})
  }
}



      const result = [];

//data filtered for google pie chart      
for (const key in fareDetails) {

  if (  key !== "TotalFare" && typeof fareDetails[key] === "number" && Number.isInteger(fareDetails[key])) {
    result.push([key, fareDetails[key]]);
  }
}

//APEX CHARTS
const chartValue = []
const chartLable = []
// for (const key in fareDetails){
//   if(key !== "TotalFare" && key !== "BaseFare" && key !== "InitialFare" && typeof fareDetails[key] === "number" && Number.isInteger(fareDetails[key])){
//     chartValue.push(fareDetails[key])
//   }
// }
for (const key in fareDetails) {
  if (
    key == "ProgressingDistanceFare" ||
    key == "BoosterSeatFare" ||
    key == "ChildSeatFare" ||
    key == "SurgeFare" ||
    key == "SearchCost" ||
    key == "BonusFare" ||
    key == "StopAddressChangingCharge" ||
    key == "WaitingCharges" ||
    key == "TollCharges" ||
    key == "OtherCharges" ||
    key == "TipAmount" ||
    key == "AfterRideTipAmount" ||
    key == "PendingAmount" ||
    key == "AdvancedBookingCharge" ||
    key == "HourlyOvertimeAmount" ||
    key == "PassengerCancellationCharge" ||
    key == "FreeInsuranceFare" ||
    key == "AdditionalDistanceFare" ||
    key == "UpgradedInsuranceFare" &&

    typeof fareDetails[key] === "number" &&
    fareDetails[key] !== 0
  ) {
    chartValue.push(fareDetails[key]);
  }
}

for (const key in fareDetails){
  if(
    key == "ProgressingDistanceFare" ||
    key == "BoosterSeatFare" ||
    key == "ChildSeatFare" ||
    key == "SurgeFare" ||
    key == "SearchCost" ||
    key == "BonusFare" ||
    key == "StopAddressChangingCharge" ||
    key == "WaitingCharges" ||
    key == "TollCharges" ||
    key == "OtherCharges" ||
    key == "TipAmount" ||
    key == "AfterRideTipAmount" ||
    key == "PendingAmount" ||
    key == "AdvancedBookingCharge" ||
    key == "HourlyOvertimeAmount" ||
    key == "PassengerCancellationCharge" ||
    key == "FreeInsuranceFare" ||
    key == "AdditionalDistanceFare" ||
    key == "UpgradedInsuranceFare" &&

    typeof fareDetails[key] === "number" &&
    fareDetails[key] !== 0
  ){
    chartLable.push(key)
  }
}


const resultCanva = chartLable.map((label, index) => ({
  y: chartValue[index],
  label: label
}));

console.log(resultCanva , "<---- resultt canvaaaa")


      let feeDetails= {

        total:parseFloat(fareDetails?.TotalFare),
        labels:chartLable,
        values:chartValue

      }

      



  setGraphDetails(resultCanva)
  setUserDetails(userDetailz)
 setDriverDetails(driverDetailz)
setRideDetails(rideDetailz)
   } })
  },[rideId])




  console.log(graphDetails , "<--O-T-H-E-R")



  useEffect(()=>{
    if(tripRequestId?.length > 0){
      ApiCall("GET", `/trip-service/v1/admin/trip/time/line/byId/?tripRequestId=${tripRequestId}`, null, "uui").then((data)=>{
  
        if(data?.result){
          const tripData = data?.result;
          let filteredData = tripData?.map((eachItem , index)=>{
            const formattedDate = dayjs(eachItem?.createdAt).format('YYYY-MM-DD')
            let dateString = eachItem?.createdAt
            const currentStartTime  =  dateString?.slice(11, 16);
            return{...eachItem,OnlyDate:formattedDate,OnlyTime:currentStartTime}
          })
    
     setTimeLineDetails(filteredData)
        }
  
      })
    }
  
  },[tripRequestId])



  const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
  return (
    any ? (
<Box>
<CreatePageHead BackPath={'/ride'} title={'Ride Details'} BreadList={breadcrumbs}/>
{/* SECTION ONE */}
{/* <Box  display={'flex'} justifyContent={'space-evenly'} flexDirection={isMatch ? 'column':'row'} mt={2}> */}
<Stack pt={2} direction={ isMatch ? 'column':'row'} spacing={5}>

    <TripCard cardDetails={rideDetails}/>
    <Box marginTop={isMatch &&'10px'} marginBottom={isMatch &&'10px'}>
    <UserDriverDetailsCard cardDeatails={driverDetails} CardTitle={'Driver Details'}/>
    </Box>
  <UserDriverDetailsCard  cardDeatails={userDetails} CardTitle={'User Details'} />
  </Stack>
{/* </Box> */}

{/* SECTION TWO */}
{/* <Box  display={'flex'} justifyContent={'space-evenly'} flexDirection={isMatch ? 'column':'row'} mt={2}> */}

{/* <Stack pt={5} pl={isMatch ?0 : 5} direction={'row'} spacing={5} > */}
<Stack pt={5} direction={ isMatch ? 'column':'row'} spacing={5}>
<FeeDetailsCard otherDetails={otherData}  cardDetails={graphDetails}/>
<TripTimelineCard cardDetails={timeLineDetails}/>
</Stack>
{/* </Box> */}

</Box>
) : any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default RideMainDetails
