import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import EditPartnerForm from './EditPartnerForm';
import { useTitle } from '../../../../Hooks/UseTitle';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import { useNavigate } from 'react-router-dom';






function EditPartner() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Insurance
      </Typography>,
 <Typography key="3" color="text.primary">
     Edit Partner
      </Typography>,
  ];
  useTitle("Edit Insurance - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Insurance')
    setAny(isAany)
     },[])
  return (
    any ? (
<Box>
<CreatePageHead title={'Edit Insurance'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
<EditPartnerForm/>
    </Box>
    </Box>

</Box>
):any === false ? (
  <RestrictedUsage/>
):null
  )
}

export default EditPartner
