import { Stack } from '@mui/material'
import React from 'react'
import AddVehicleFleetCard from './AddVehicleFleetCard'
import AddDriverFleetCard from './AddDriverFleetCard'
import AssignVehicleCard from './AssignVehicleCard'

function AllCards() {
  return (
 <Stack pt={7} spacing={3}>

  <AddDriverFleetCard/>
  <AddVehicleFleetCard/>
  <AssignVehicleCard/>
  
 </Stack>
  )
}

export default AllCards
