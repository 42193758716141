import { Box, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { GOOGLE_MAP_API_KEY, mapStyles } from '../../../Components/Maps/MapStyles';
import { useLoadScript, GoogleMap,OverlayView } from "@react-google-maps/api";
import { Polygon } from "@react-google-maps/api";
import { useSelector } from 'react-redux';

function MiniMapZone({currentZoneIndex}) {
    const [loadingData, setLoadingData] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [zonePoints , setZonePoints] = useState([])
    const [center,setCenter] = useState({});
    const [zoom] = useState(10.8);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const {currentCoordinatesData} = useSelector((state) => state.coordinates)
    console.log(currentCoordinatesData,"<---the codinatessssss")
    const colors = ["#FB8184", "#3A9D75", "#F0A65D", "#7D7DBE"];

// delay the map loading 

    useEffect(() => {
    function delayedFunction() {
    setLoadingData(true);
    renderZones()
    }
    const timeoutId = setTimeout(delayedFunction, 500);
    return () => clearTimeout(timeoutId)
    },[]);

// setting zones
   useEffect(()=>{
   if(currentCoordinatesData){
      setZonePoints([currentCoordinatesData?.[currentZoneIndex]])
      setCenter(calculatePolygonCenter(currentCoordinatesData?.[currentZoneIndex]))
    }
   },[currentCoordinatesData,currentZoneIndex])
 //loading map
 
    const { isLoaded } = useLoadScript({googleMapsApiKey: GOOGLE_MAP_API_KEY});

// test sing zone coordinates


// function for find the center of the map
  function calculatePolygonCenter(coordinates) {
    let latSum = 0;
    let lngSum = 0;
  
    for (const point of coordinates) {
      latSum += point.lat;
      lngSum += point.lng;
    }
  
    const numPoints = coordinates.length;
  
    const center = {
      lat: latSum / numPoints,
      lng: lngSum / numPoints,
    };
  
    return center;
  }



// rendering the polygons

  const renderZones = () => {

    const zoneLabels = ["Zone A", "Zone B", "Zone C", "Zone D"];
    return zonePoints?.map((coordinates, index) => {
    const polygonBounds = new window.google.maps.LatLngBounds();
    coordinates.forEach((coordinate) => {
            polygonBounds.extend(coordinate);
          });
    const polygonCenter = polygonBounds.getCenter();
    const textPosition = { lat: polygonCenter.lat(), lng: polygonCenter.lng() };
    
      
          return (
            <Fragment key={index}>
           
              <Polygon
                path={coordinates}
                options={{
                  strokeColor: colors[currentZoneIndex],
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: colors[currentZoneIndex],
                  fillOpacity: 0.3,
                }}
              />
                <OverlayView
                   
              position={textPosition}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
              })}
            >
              <div
           
                style={{
                 
                
                 width:'150px',
                  fontSize: "12px",
                  fontWeight: "bold",
                  textDecoration: 'none', 
             
                }}
              >
                {zoneLabels[currentZoneIndex]}
              </div>
            </OverlayView>
     
            </Fragment>
          );
        });
      };








  return isLoaded  ? (
    <Box
    pt={5}
    // pl={6}
    // pr={3}
    display={'flex'}
    justifyContent={'center'}
    
      sx={{
        // flexWrap: "wrap",
        mt:'10px',
        "& > :not(style)": {
      
        //   width: "5%",
          height: "50%",
        //   display: "flex",
          alignItems:"center",
        },
      }}
    >
      <Typography variant="h6">
        <strong>{''}</strong>
      </Typography>
      <Fragment>
       <GoogleMap
        
          center={center}
          zoom={zoom}
          onLoad={() => setMapLoaded(true)}
          mapContainerStyle={{
            
            height: "337px",
            width: "250px",
            borderRadius: "4px",
          }}
          options={{
            zoomControl:false,
            streetViewControl:false,
            mapTypeControl: false,
            styles: mapStyles,
          }}
        >
          {loadingData && renderZones()} 
          
          {/* Adjust above line there is some rendering issues */}
         </GoogleMap>
   
      </Fragment>
      {/* {renderMap()} */}
    </Box>
  ) : (
    <div>Loading...</div>
  );
}

export default MiniMapZone
