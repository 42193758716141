import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Nodatafound from '../../../../../Components/NoDataFound/Nodatafound';

function Activity({AllActivity}) {
  let totalItems = AllActivity?.length
  return (
  <Box width={'35%'}  height={'500px'}
  sx={{overflowY:'scroll', '&::-webkit-scrollbar': {
    width: '5px',  // Adjust the width as needed
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'lightgray',  // Change the thumb color
    borderRadius: '5px',     // Add rounded corners to the thumb
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',  // Change the track color
  },
  scrollbarWidth: 'thin',  // Firefox scrollbar width
  scrollbarColor: 'lightgray transparent',}}
  
  >

<Stack spacing={3}>
    <Box  width={'100%'}>
    <Timeline position='left' >
     

    {totalItems ? 
AllActivity?.map((eachData,index)=>(

<TimelineItem>
<TimelineSeparator>
  <TimelineDot color="success">
    <AdsClickIcon fontSize='small'/>
  </TimelineDot>
 {index+1 < totalItems && <TimelineConnector />}
</TimelineSeparator>
<TimelineContent>
  <Box width={'300px'}>
  <Stack width={'100%'}>
  {eachData?.Title}
  <Typography sx={{textTransform:"none",opacity:'50%'}} variant='caption' >{`${eachData?.Date},${eachData?.Time} `}</Typography>
  </Stack>
  </Box>

 
  </TimelineContent>
</TimelineItem>

)) : <Nodatafound Title={'No Data Found'}/>

}

      
    </Timeline>  
    </Box>
    </Stack>

  </Box>
  )
}

export default Activity
