import { Box, Button, Checkbox, FormControlLabel, IconButton, Paper, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit'
import ApiCall from '../../../../../Helper/ApiCalls'
import { useSelector } from 'react-redux'
import DataList from '../../../../Components/Table/DataList'
import InputField from '../../../../Components/Forms/InputField'
import TableSearchBar from '../../../../Components/Forms/TableSearch'
import closeIcon from '../../../../../Assets/Table/Group 4336.svg'
import DataListSingle from '../../../../Components/Table/DataListSingleSelect'
import { checkPrivilage } from '../../../../../Sessions/Permisssions'

function SelectDriver({onButtonClick,onSectionComplete}) {
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [userData,setUserData] = useState([])
const [tableData,setTableData] = useState([])
    const [UserType,setUserType] = useState(2)
    const [limit,setLimit] = useState(5)
const [offset,setOffSet] = useState(0)
const [totalUsers , setTotalUsers] = useState(0)
const [rowsPerPage , setRowsPerPage] = useState(5)
const [filterStatus ,  setFilterStatus] = useState(false)
const [searchInput, setSearchInput] = useState('')
const [pickedDriver , setPickedDriver] = useState(0)

const [write,setWrite] = useState()
  

useEffect(()=>{   
  let isWrite = checkPrivilage('Vehicles','CREATE')
  setWrite(isWrite)

   },[])


//RENDERSSS

    useEffect(()=>{
      if(UserType === 2){
      ApiCall("GET", `/trip-service/v1/admin/driver/profile/details/?Limit=20000&&countryId=${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){

             const driverData = data?.result?.driverDetails
             const filteredData = driverData?.map((eachItem,index)=>{        
             let items={
              userID:eachItem?.UserID,
              name:eachItem?.FirstName +' '+eachItem?.LastName,
              phone:eachItem?.PhoneNumber,
              onlineStatus:eachItem?.OnlineStatus,
              bookingCount:eachItem?.TotalBooking?.toString(),   
        }
         return items;
             }) 
            setUserData(filteredData) 
            setTableData(filteredData)
         } })
        }
      },[UserType,limit,offset])


//FUNCTIONS

const handlingRowsPerpage = (rowsPerpage)=>{
    setLimit(rowsPerpage)
}


const handlePageChange = (pageNo) => {
  const newOffset = pageNo * rowsPerPage;
  // setOffSet(newOffset);

  const newLimit = (pageNo + 1) * rowsPerPage;
  setLimit(newLimit > totalUsers ? totalUsers : newLimit);
};

const handleSearch = (value)=>{
  setSearchInput(value)
 }


 const CloseModal = (status)=>{
  setFilterStatus(status)
}

const selectedUsers = (users)=>{
    if(users){
        setPickedDriver(users?.[0])
    }
   console.log(users,"<--the usersssssss <-----TTT%%%%%%%%%%%%%%%%%%%%5")
  }



  const handleColumnFilter = (items, searchText) => {
    console.log("items:",items ,"SearchText:",searchText)

    if (items.length === 0 && searchText === "") {
      setUserData(tableData);
      return;
    }
         
    console.log(tableData,"<-----TTABLEDATA")
    console.log(items,"<-----ITEMS")
    const filteredRows = tableData.filter((row) => {
      // Check if any of the selected items are present in the row's features
      const featuresMatch = items?.includes(row.bookingCount)
  
      // Check if the row matches any of the selected vehicleCategory items
      const vehicleCategoryMatch = items?.includes(row.vehicleCatogery);
  
      // Check if the row matches all of the selected vehicleType items
      // const vehicleTypeMatch = items.every(
      //   (item) => row.vehicleType && row.vehicleType.includes(item)
      // );
      const vehicleTypeMatch = items?.includes(row.onlineStatus);
  
      // Check if the row contains the search text in any of its values
      const searchTextMatch = Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
  
      // Return true if any of the filters match the row, otherwise return false
      return (
        (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
        searchTextMatch
      );
    });
  
    // Update the filtered rows
    setUserData(filteredRows);
  };

  const handleNext =(todata)=>{
    if(todata?.length > 0 && pickedDriver){
        localStorage.setItem("picked_driver", pickedDriver)
        onButtonClick(todata)
        onSectionComplete(true,'personal')
    }



  }






  return (
<Box>
<Stack spacing={2}>

{ filterStatus && 
 <Paper  sx={{width:UserType === 1 ? "60%":"60%" , padding:"10px", height:"100px"}} elevation={3} >
  <Stack alignItems={'center'} direction={'row'} spacing={1}>
 <Box paddingBottom={'12px'} width={'32.5%'}>
    <InputField sideLabel={"Booking Count"} placeHolder={"Trip Count"} InChange={(e) => handleColumnFilter([e.target.value],"")} />
         </Box>
         <Box paddingTop={1}>
         <TableSearchBar changeInSearchBar={handleSearch} />  
         </Box>
    {UserType === 2 && <FormControlLabel onChange={(e) => handleColumnFilter([e.target.checked],"")} required control={<Checkbox  color="success" />} label="Online Drivers" />}
       {/* {UserType === 2 &&  <FormControlLabel onChange={(e) => setIsPriority(e.target.checked)} required control={<Checkbox  color="success" />} label="Priority Sticker" />} */}
       <IconButton  onClick={() => CloseModal(false)} aria-label="delete" size="large">
         <img src={closeIcon} alt="filter"/>
        </IconButton> 

         </Stack>
       
  
 </Paper>
 }

<Box display={'flex'} justifyContent={'space-between'} >
    <Box width={'50%'} >
    <DataListSingle openFilter={CloseModal} handlePgination={handlePageChange} TotalUserCount={totalUsers} handleRowsper={handlingRowsPerpage} sendSelectedData={selectedUsers} TableData={userData} Title={UserType === 1 ? 'Users':UserType === 2 ? 'Drivers': 'Select User Type'}/>
    </Box>
    {/* <Box width={'30%'} maxHeight={'500px'} sx={{overflowY:'scroll'}}> */}
      {/* <DraggableList items={datas}/> */}
    {/* <ShowOnlyTable/>    */}
    {/* </Box> */}
 

</Box>
{/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagetwo"} goBack={"pageone"} /> */}
<Button
disabled={!write}
          sx={{
            width: "114px",
            height: "40px",
            borderRadius: "8px",
            textTransform:'none',
            backgroundColor: "#1C1B20",
          }}
          variant="contained"
          onClick={() => handleNext('pagetwo')}
        >
          {" "}
          Next
        </Button>

</Stack>



</Box>
  )
}

export default SelectDriver
