import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import TicketInfo from './TicketInfo'
import DriverRegistrationOverview from './DriverRegistrationOverview'

function TicketInfoandReg() {
  return (
  <Stack spacing={1}>
<TicketInfo/>
<DriverRegistrationOverview/>


  </Stack>
  )
}

export default TicketInfoandReg
