import { Alert, Box, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'

function ErrorNotify({Status,errorNote,submitStatus}) {
  
    const [open, setOpen] = React.useState(false);

    useEffect(()=>{
      submitStatus && setOpen(true);
    },[Status])
    
      const handleClick = () => {
      
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    
      return (
      <Box>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {errorNote}
            </Alert>
          </Snackbar>
    
      </Box>
      )
    }

export default ErrorNotify
