import { Box, Stack } from "@mui/material";
import React from "react";
import FormNav from "../Forms/FormNav";
import ReasonForm from "./ReasonForm";


function AddCancelReason() {
  return (
    <Box>
      <Stack spacing={4}>
        <FormNav title={"Add cancel Reason"} />
  
        <ReasonForm />
      </Stack>
    </Box>
  );
}

export default AddCancelReason;
