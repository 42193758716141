import React, { useEffect, useState } from 'react'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import ImageSelector from '../../../Components/Forms/ImageSelector'
import InputField from '../../../Components/Forms/InputField'
import { VehicleCategory } from '../../../FakeData/FakeData'
import SelectField from '../../../Components/Forms/SelectField'
import AddIcon from '@mui/icons-material/Add';
import ApiCall from '../../../../Helper/ApiCalls'
import MultipleSelect from '../../../Components/Forms/MultipleSelect'
import AlertPopUp from '../../../Components/Forms/AlertPopUp'
import SnackBars from '../../../Components/Forms/SnackBars'
import Error404 from '../../404 Error page/Error404'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import { useSelector } from 'react-redux'
import { checkPrivilage } from '../../../../Sessions/Permisssions'

function AddServiceForm() {
  const [selectedChip, setSelectedChip] = useState([]);
  const [adOnFeatures , setAdOnFeatures] = useState([])
  const [vehicleType , setVehicleType] = useState([])
  const [onSuccess , setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)
  //forms
  const [serviceName , setServiceName] = useState('')
  const [description , setDescription] = useState('')
  const [isShowService , setIsShowService] = useState(false)
  const [selectedVehicle , setSelectedVehicle] = useState([])
  const [selectedCategory , setSelectedCategory] = useState([])
  const [featureData , setFreatureData] = useState([])
  const [imageFile , setImageFile] = useState({})
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [submitHit,setSubmitHit] = useState(false)
  const [enable , setEnable] = useState(false)
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)

  const [write,setWrite] = useState()
  

  useEffect(()=>{   
    let isWrite = checkPrivilage('Ride Service','CREATE')
    setWrite(isWrite)

     },[])



 useEffect(()=>{

  const hasErrors =
  !imageFile ||
  serviceName.length < 3 ||
  description.length < 5 ||
  selectedVehicle.length < 1 ||
  selectedCategory.length < 1 


setEnable(!hasErrors);
 },[imageFile,serviceName,description,selectedVehicle,selectedCategory,selectedChip])

 
  const handleAddChip = (chipName) => {
    if (!selectedChip.includes(chipName)) {
        setSelectedChip([...selectedChip, chipName?.name]);
        setFreatureData([...featureData, chipName])
      }
  };

  console.log(selectedChip,"<---selected chippssss")

  const handleMultipleSelect = (label,data)=>{

    if(label === 'Vehicle Type'){
      let filteredData = data?.map((item)=>{
        return item?.id
      })
      setSelectedVehicle(filteredData)
    }else if(label === 'Vehicle Category'){

      let filteredData = data?.map((item)=>{
        return item?.name
      })
      setSelectedCategory(filteredData)
    }

  }

  const handleImageFiles = (files)=>{
  setImageFile(files)

  }




  useEffect(()=>{
    ApiCall("GET", "/trip-service/v1/admin/value-service/adOn/features", null,).then((data)=>{
        if(data.result){
            const rowData = data?.result
        
            const filteredData = rowData?.map((eachData)=>{
                return {name:eachData?.Type,id:eachData?._id}

            })

            setAdOnFeatures(filteredData)
        }
     
    })


    ApiCall("GET", "/trip-service/v1/admin/value-service/vehicle/type", null,).then((data)=>{
           if(data.result){
            const rowData = data?.result;
            const filteredData = rowData?.map((eachType)=>{
              if(eachType?.Type !== 'Scooter' && eachType?.Type !== 'MotorCycle' && eachType?.Type !== 'Lorry' ){
                return{id:eachType?._id,name:eachType?.Type}
              }
              
            })


            console.log(filteredData,"<---filteredddd")

            setVehicleType(filteredData.filter(item => item !== undefined))
           }


    })

  },[])




// handling form submittion 

const handleFormSubmit =()=>{
 setSubmitHit(true)

 if(enable){
    
  const adOnIds = featureData?.map(item => item.id).join(',');
  const vehicleTypes = selectedVehicle?.join(",")

  console.log(adOnIds,",--idsss")
 
  const formData = new FormData();
  formData.append('ValueServiceIconImage', imageFile?.file);
  formData.append('serviceName', serviceName);
  formData.append('description', description);
  adOnIds ? formData.append('adOnFeaturesIds', adOnIds):formData.append('adOnFeaturesIds', "");
  formData.append('showInValueServices', isShowService);
  formData.append('vehicleTypeIds', vehicleTypes); 
  formData.append('countryId', CountryID);
 
 selectedCategory.forEach((category, index) => {
   formData.append(`vehicleCategory[${index}]`, category);
 });
 
 
 ApiCall("POST", "/trip-service/v1/admin/value-service/create", formData,).then((data)=>{
     if(data.result === 'success'){
       setOnSuccess((i) => !i )
       setOnApiSuccess("Succesfully created service")
     }
 })
 .catch((error) => {
    setOnFailed((i) => !i)
   
     if(error.message){
       setOnApiFailed(error.message)
       console.log(error , "<----errrrrrr")
     }else{
       setOnApiFailed("Something went wrong.!")
     }
 });
 }
}
















  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
    
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));






  const handleDeleteChip = (chipName) => {
     setSelectedChip(
        selectedChip.filter((each) => each !== chipName)
     )
     setFreatureData(featureData.filter((each) => each.name !== chipName ))
  };


  const chipAdornment = selectedChip ? (
<InputAdornment position="start">
  {selectedChip.map(eachItem => (
    <Chip label={eachItem} onDelete={() => handleDeleteChip(eachItem)} />
  ))}
</InputAdornment>
  ) : null;

  return (
    <Box>
     
    <Stack spacing={3} >
    {/* form   */}
    <Stack spacing={2}>
    <Box width={isMatch?'100%':'29.1%'}>
    <ImageSelector isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} />
    </Box>


    <Stack direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'43%'}>
        <InputField Type={'text'} isError={submitHit && serviceName?.length < 3} ErrNote={""} InChange={(e) => setServiceName(e.target.value)}   sideLabel={"Ride Service Name"} placeHolder={"Ride Service Name"} />
        </Box>
        <Box width={isMatch ? "43%" : "100%"} >
        <InputField isError={submitHit && description.length < 5} ErrNote={""} InChange={(e) => setDescription(e.target.value)}  sideLabel={"Description"} placeHolder={"Description"} />
        </Box>
         
        </Stack>



        <Stack>
        <FormControlLabel onChange={(e) => setIsShowService(e.target.checked)} control={<Checkbox  color="success" />} label="List in value service?" />
        </Stack>



        <Stack direction={'row'} spacing={3} >
         <Box width={'30%'}>
         <MultipleSelect isError={ submitHit && selectedVehicle.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Type"} Data={vehicleType} />
         </Box>
         <Box width={'30%'}>
         <MultipleSelect isError={submitHit && selectedCategory.length < 1} onMultiSelect={handleMultipleSelect} sideLabel={"Vehicle Category"} placeHolder={"choose"} Data={VehicleCategory}/>
         </Box>
        </Stack>



{/* readOnly input field */}

        <Stack>
        <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Features</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
       
        <OutlinedInput
        error={submitHit && selectedChip.length < 1 }
    readOnly
    sx={{ borderRadius: '4px', height: '46px' }}
    fullWidth
    placeholder="select"
    startAdornment={chipAdornment}
  />
      </FormControl>
        </Stack>



        <Stack direction={'row'} spacing={2}>
        
            {
              adOnFeatures ? 
                adOnFeatures?.map((eachFeature) => !selectedChip.includes(eachFeature?.name) && (

                    <Button
                    onClick={() => handleAddChip(eachFeature)}
                    sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#0B2863',
                      },
                    }}
                    variant="contained"
                    startIcon={<AddIcon/>}
                  >
                {eachFeature?.name}
                  </Button>

                )) : 
                (
                    <Typography color={'red'}>No Features available</Typography>
                )
            }
     
      </Stack>

    </Stack>
    {/* <AlertPopUp/> */}
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />
  </Stack>
 </Box>
  )
}

export default AddServiceForm
