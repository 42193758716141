import { Box, Button, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ImageSelector from '../../../Components/Forms/ImageSelector'
import InputField from '../../../Components/Forms/InputField'
import SelectField from '../../../Components/Forms/SelectField'
import { AD_TYPE, App_Type, NO_OF_SEATS } from '../../../FakeData/FakeData'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import ApiCall from '../../../../Helper/ApiCalls'
import ImageSelectorUpdate from '../../../Components/Forms/ImageSelectorUpdate'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import { useNavigate, useParams } from 'react-router-dom'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'

function EditAddForm() {
    const { id:addID } = useParams();
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [imageFile , setImageFile] = useState({})
    const [headingName , setHeadingName] = useState('')
    const [description,setDescription] = useState('')
    const [selectedAdType , setSelectedAdType] = useState('')
    const [urlName , setUrlName] = useState('')
    const [selectedAppType,setSelectedAppType] = useState('')
    const [currentAppType , setCurrentAppType] = useState('')
    const [currentAddType , setCurrentAdtype] = useState('')
    const [imageLink , setImageLink] = useState('')
    const [edit,setEdit] = useState()
  


    const removeUnderscoresAndAddSpaces = (inputString)=> {
      // Use a regular expression to replace underscores with spaces globally
      return inputString?.replace(/_/g, ' ');
    }

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('App Ads','UPDATE')
      setEdit(isEdit)
      
  
       },[])

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


//RENDERS


useEffect(()=>{

    ApiCall("GET", `/user-service/v1/admin/promo/byId/?promoId=${addID}&&countryId=${CountryID}`, null,).then((data)=>{
        if(data.result){
            const rowData = data?.result[0]

            setImageLink(rowData?.photoUrl)
            setHeadingName(rowData?.Name)
            setDescription(rowData?.Description)
            setSelectedAdType(rowData?.Type)
            setCurrentAdtype(removeUnderscoresAndAddSpaces(rowData?.Type))
            setSelectedAppType(rowData?.AppID)
            setCurrentAppType(rowData?.AppID === 1 ? 'User App' :'Driver App')
            setUrlName(rowData?.ExtUrl)


            console.log(rowData,"<<<adddetailssssss")
        
     

          
        }
     
    })

},[])



// FUNCTIONS

const handleImageFiles = (files)=>{
    setImageFile(files)
  
    }


const handleSelect = (label,data)=>{
          
        if(label === 'App Type'){
               setSelectedAppType(data?.id)
        }else if(label === 'Ad Type'){
               setSelectedAdType(data?.id)
        }
     
    
      }  




      const navigate  = useNavigate()

      const handleDiscard =()=>{
        navigate(-1)
      }


    // handling form submittion 

const handleFormSubmit =()=>{
    setSubmitHit(true)
    
    const formData = new FormData();
    imageFile && formData.append('appAdvertisementImage',imageFile?.file);
    // formData.append('appAdvertisementImage', imageFile.file);
    formData.append('name', headingName);
    formData.append('description', description);
    formData.append('appId', selectedAppType);
    formData.append('appPath', 'app/path/type');
    formData.append('countryId', CountryID);
    formData.append('url', urlName);
    formData.append('type', selectedAdType); 
    formData.append('promoId', addID); 
     

    

    ApiCall("PUT", "/user-service/v1/admin/promo/update", formData,).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully Updated Ad")
          
    
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });
   
   
   
  
   
   
   }



  return (
<Box>
<Stack spacing={3} >
<Stack spacing={2}>

{/* FIRST ROW */}
<Box width={'30%'}>
<ImageSelectorUpdate imageLink={imageLink} sideLabel={'Image'} isError={submitHit && !imageFile} handleFileChange ={handleImageFiles} />
</Box>
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'43%'}>
        <InputFieldUpdate Value={headingName} isError={submitHit && headingName?.length < 3} ErrNote={""} InChange={(e) => setHeadingName(e.target.value)}   sideLabel={"Heading"} placeHolder={"Heading"} />
</Box>
<Box width={isMatch ? "43%" : "100%"} >
        <InputFieldUpdate Value={description} isError={false} ErrNote={""} InChange={(e) => setDescription(e.target.value)}  sideLabel={"Description"} placeHolder={"Description"} />
</Box>


</Stack>

{/* SECOND ROW */}
<Stack direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'30%'}>
<SelectFieldUpdate currentSelect={currentAppType} isError={false} onSelecting={handleSelect} Data={App_Type} sideLabel={'App Type'} placeHolder={'choose'} />
    </Box>
    <Box width={'30%'}>
<SelectFieldUpdate currentSelect={currentAddType} isError={false} onSelecting={handleSelect} Data={AD_TYPE} sideLabel={'Ad Type'} placeHolder={'choose'} />
    </Box>
<Box width={'30%'}>
        <InputFieldUpdate Value={urlName} isError={submitHit && urlName.length < 3} ErrNote={""} InChange={(e) => setUrlName(e.target.value)}   sideLabel={"URL"} placeHolder={"URL"} />
</Box>    
    </Stack>



</Stack>

<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>

</Stack>

</Box>
  )
}

export default EditAddForm