import React, { useRef, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CanvaCharts = ({chartData}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Access chart properties and methods using chartRef.current
    // For example: chartRef.current.render();
  }, []);

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"

    data: [{
      type: "pie",
      indexLabel: "{label}: {y}",
      startAngle: -90,
      dataPoints: chartData
    }]
  };

  return (
    <div>
      <CanvasJSChart options={options} />
      {/* You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods */}
    </div>
  );
};

export default CanvaCharts;
