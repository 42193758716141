import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';
import AddVehicleProgression from './AddVehicleProgression';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';







function CreateVehicle() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Vehicle
      </Typography>,
 <Typography key="3" color="text.primary">
     Add Vehicle
      </Typography>,
  ];
  useTitle("Add Vehicle - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Vehicles')
    setAny(isAany)
     },[])
  return (
    any ? (
   <Box>
    <CreatePageHead title={'Add Vehicle'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
  <AddVehicleProgression/>
    </Box>
    </Box>
   </Box> ) : any === false ? (
    <RestrictedUsage/>
   ): null
  )
}

export default CreateVehicle