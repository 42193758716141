import { Box, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'

import { useNavigate, useParams } from 'react-router-dom'
import ApiCall from '../../../../Helper/ApiCalls'
import UserPersonalCard from './UserPersonalCard'
import UserBookingTable from './UserBookingTable'
import { useTitle } from '../../../../Hooks/UseTitle'
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions'
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage'






function UserMainDetails() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate("/users")}  key="3" color="text.primary">
       Users
      </Typography>,
  <Typography key="3" color="text.primary">
     User Details
      </Typography>,
  ];
  useTitle("User Details - Geolah");
const theme = createTheme({
    breakpoints: {
      values: {
        ht: 1800,
    
      },
    },
  });
  
// STATES AND VARIABLES 
const { id:userId } = useParams(); 
const isMatch = useMediaQuery(theme?.breakpoints?.down("ht"));
const [vehicleDetails,setVehicleDetails] = useState({})
const [profileDetails , setProfileDetails] = useState({})
const [tab , setTab] = useState('left')
const [any,setAny] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('User Details')
  setAny(isAany)
   },[])

// RENDERS AND API CALLS

useEffect(()=>{


  if(userId){

    
    ApiCall("GET", `/trip-service/v1/admin/driver/details/${userId}`,null,).then((data)=>{
      if(data.result){
       
    const userDetails  = data.result?.[0]
    let dateString = userDetails?.LastOnlineTime
    const currentStartTime  =  dateString?.slice(11, 16);

       let profileData = {
        userEmail:userDetails?.Email,
        userPhone:userDetails?.PhoneNumber,
        userPrioritySticker:userDetails?.HasPrioritySticker,
        userApprove:userDetails?.IsApprovedByAdmin,
        userProfileImage:userDetails?.userProfilePicPath,
        userRating:userDetails?.userRating?.TotalRating,
        userReview:userDetails?.userRating?.TotalReviews,
        userLastOnline:currentStartTime,
        userName:userDetails?.FirstName +''+ userDetails?.LastName,
       }
       
       setProfileDetails(profileData)
 
            
      }
  })
  .catch((error) => {
    if(error.message){
      console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong [ridecategory - get]')
      }
  
  
  });






  }


},[userId])


// FUNCTIONS
const tabRender = (alignment)=>{
  setTab(alignment)
            }
            
  return (
    any ? (
    <>
<CreatePageHead BackPath={'/users'} title={'User details'} BreadList={breadcrumbs}/>
<Box p={3} display={'flex'} flexDirection={isMatch?'column':'row'} justifyContent={isMatch?'center':'space-between'} >
{/* PERSONAL DETAILS CARD */}
<UserPersonalCard/>
{/* <DriverPersonalCard ProfileData={profileDetails} /> */}
{/* GRAPH CARD */}
<Box marginTop={isMatch &&'10px'} marginBottom={isMatch &&'10px'}>
{/* <VehicleInformationCard VehicleData={vehicleDetails} /> */}
</Box> 

</Box>


    <UserBookingTable/>


</>) : any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default UserMainDetails