import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import PdfCard from '../../../../Components/Forms/PdfCard';
import DetailsSkeleton from '../DetailsSkeleton';


function DetailsCard({CardName, Doc1 , Doc2 }) {

  const [loadingData , setLoadingData] = useState(true)
useEffect(()=>{
  function delayedFunction() {
    setLoadingData(false)
   }
  const timeoutId = setTimeout(delayedFunction, 2000);
},[])

  return (
 loadingData ? (
  <DetailsSkeleton/>
 ):
 (

 


   <Box marginTop={2}  p={2} borderRadius="4px" width="430px" height="160px" border={1} borderColor="grey.300"  >
    <Stack>
        <Typography><strong>{CardName}</strong></Typography>
        <Box alignItems="center"  display="flex" justifyContent="space-between" >
          <PdfCard fileName={Doc1?.title} iconColor={Doc1?.color} /> 
          {
            Doc2 ? <PdfCard fileName={Doc2?.title} iconColor={Doc2?.color} /> : null
          }
            

       </Box>
       <Typography variant='subtitle2' sx={{opacity:'50%'}} >Issue Date</Typography>
       <Typography fontWeight={500} variant='caption' > 21 March 2023</Typography>
    </Stack>

   </Box>
   )
  )
}

export default DetailsCard
