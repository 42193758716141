import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriverBankDetailsSkelton from "./DriverBankDetailsSkelton";

function BankDetails() {
  const [loadingData , setLoadingData] = useState(true)
  useEffect(()=>{
    function delayedFunction() {
      setLoadingData(false)
     }
    const timeoutId = setTimeout(delayedFunction, 2000);
  },[])
  return (
    loadingData ? 
    (
      <DriverBankDetailsSkelton/>
    ):
    (<Box
      sx={{
        flexWrap: "wrap",
        "& > :not(style)": {
          mt: "3%",
          width: "100%",
          height: "216px",
          borderRadius: "4px",
        },
      }}
    >
      <Paper sx={{ padding: "16px" }} elevation={3}>
        <Box>
          <Box display="flex" justifyContent="space-between" padding={3}>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
                Full Name
              </Typography>
              <Typography fontWeight={450}>White</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
                Bank Name
              </Typography>
              <Typography fontWeight={450}>SG M 369</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
                Account Number
              </Typography>
              <Typography fontWeight={450}>Rental</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ opacity: "50%" }} variant="caption">
                NRIC Number
              </Typography>
              <Typography fontWeight={450}>NRIC5466</Typography>
            </Stack>
          </Box>

          <Box display="flex" justifyContent="space-between" padding={3}>
            <Box
              sx={{
                backgroundColor: "#EFF0F2",
                height: "40px",
                width: "229.5px",
              }}
            >
              <IconButton
                target="_blank"
                href="https://www.africau.edu/images/default/sample.pdf"
              >
                <Stack spacing={2} alignItems="center" direction="row">
                  <PictureAsPdfIcon sx={{ color: "red" }} />
                  <Typography fontWeight={500}>Bank Details</Typography>
                </Stack>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>)
  );
}

export default BankDetails;
