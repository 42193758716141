import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Tabs from '../../../../Components/Tabs/Tabs';
import RideInfo from './Pages/RideInfo';
import UserLicense from '../../../User/UserDetails/Pages/UserLicense';
import Service from './Pages/Service';
import PaymentDriver from './Pages/PaymentDriver';
import BankInfo from '../../../User/UserDetails/Pages/BankInfo';
import Pending from './Pages/Pending';
import Activity from './Pages/Activity';
import ApiCall from '../../../../../Helper/ApiCalls';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import DriverTickets from './Pages/DriverTickets';
import { useSelector } from 'react-redux';
import Remarks from './Pages/Remarks';


const TabItems = [
    { name: "Ride Info",
     value: "Ride Info" },
    // {
    //   name: "Track",
    //   value: "Track",
    // },
    {
      name: "Documents",
      value: "Documents",
    },
    {
      name: "Service",
      value: "Service",
    },
    {
        name: "Payment",
        value: "Payment",
      },
      // {
      //   name: "Bank Info",
      //   value: "Bank Info",
      // },
      {
        name: "Pending",
        value: "Pending",
      },
      {
        name: "Activity",
        value: "Activity",
      },    
      {
        name: "Tickets",
        value: "Tickets",
      },
      {
        name: "Remarks",
        value: "Remarks",
      },
 

  ];








// RideInfo

function GeneralDetails() {
  const { id:driverId } = useParams(); 
  const [licenseDocs,setLicenseDoc] = useState([])
  const [profileDocs,setProfileDoc] = useState([])
  const [vehicleDocs,setVehicleDoc] = useState([])
  const [insuranceDocs,setInsuranceDoc] = useState([])
  const [activityData , setActivityData] = useState([])
  const [remarkData , setRemarkData] = useState([])
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)


  useEffect(()=>{

    ApiCall("GET", `/trip-service/v1/admin/driver/activity/?driverId=${driverId}&&userTypeId=2`,null).then((data)=>{
      if(data.result){
        const activityData = data?.result
 
        const filteredData = activityData?.map((eachData,index)=>{

          let dateString = eachData?.DateTime
          const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
          const currentStartTime  =  dateString?.slice(11, 16);      

          let Items ={
           Title:eachData?.Activity,
           Date:formattedDate,
           Time:currentStartTime,
          }

          return Items
        })
        setActivityData(filteredData)    
      }})
      .catch((error) => {
    if(error.message){
    console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong')
      }});


      ApiCall("GET", `/trip-service/v1/admin/driver/all/remark/Details/?driverId=${driverId}&&countryID=${CountryID}`,null).then((data)=>{
        if(data.result){
          const remarkData = data?.result
        
   
          const filteredData = remarkData?.map((eachData,index)=>{
  
            let dateString = eachData?.DateTime
            const formattedDate = dayjs(dateString).format('YYYY-MM-DD')
            const currentStartTime  =  dateString?.slice(11, 16);      
  
            let Items ={
             Title:eachData?.Collection,
             Desc:eachData?.Content,
             Remark:eachData?.Remark,
             Date:formattedDate,
             Time:currentStartTime,
            }
  
            return Items
          })
          setRemarkData(filteredData)    
        }})
        .catch((error) => {
      if(error.message){
      console.log(`Error -> ${error.message}`)
        }else{
          console.log('something went wrong')
        }});
  


















    ApiCall("GET", `/trip-service/v1/admin/driver/details/document/ById/?DriverId=${driverId}`, null, "uui").then((data)=>{
    if(data?.result){
    const documentData = data?.result
    const LicenseDocuments = documentData?.DriverLicenseDocs
    const ProfileDocuments = documentData?.DriverprofileDocs
    const VehicleDocuments = documentData?.DriverVehicleDocs
    const InsuranceDocuments = documentData?.DriverInsuranceDocs
    // LICENSE SECTION

    const resultArray = [];

    LicenseDocuments?.forEach(item => {
      if (item.LicenceBackImagePaths) {
        resultArray?.push({
          title: "License Back",
          image: item?.LicenceBackImagePaths,
          date: dayjs(item?.updatedAt).format('DD-MM-YYYY')
        });
      }
    
      if (item?.LicenceFrontImagePaths) {
        resultArray?.push({
          title: "License front",
          image: item?.LicenceFrontImagePaths,
          date: dayjs(item?.updatedAt).format('DD-MM-YYYY')
        });
      }
    
      if (item?.VocationalLicenceDetails) {
        item?.VocationalLicenceDetails?.forEach(detail => {
          if (detail?.VocLicenceBackImagePaths) {
            resultArray.push({
              title: "Vocational license back",
              image: detail?.VocLicenceBackImagePaths,
              date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
            });
          }
    
          if (detail.VocLicenceFrontImagePaths) {
            resultArray.push({
              title: "Vocational license front",
              image: detail?.VocLicenceFrontImagePaths,
              date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
            });
          }
        });
      }
    });

    resultArray?.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA; // Sort in descending order
    });

    setLicenseDoc(resultArray)
    

//PROFILE SECTION 

const nricResultArray = [];

ProfileDocuments?.forEach(item => {
  if (item?.NricFrontImagePaths) {
    nricResultArray.push({
      title: "NRIC front",
      image: item?.NricFrontImagePaths,
      date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
    });
  }

  if (item?.NricBackImagePaths) {
    nricResultArray.push({
      title: "NRIC back",
      image: item?.NricBackImagePaths,
      date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
    });
  }
});

nricResultArray?.sort((a, b) => {
  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  return dateB - dateA; // Sort in descending order
});

setProfileDoc(nricResultArray)


//VEHICLE DOCUMENTS


const ltavResultArray = [];


VehicleDocuments?.forEach(item => {
  // Handle LtaVehicleLog
  if (item?.LTAVehicleLog) {
    ltavResultArray.push({
      title: "LtaVehicleLog",
      image: item?.LTAVehicleLog,
      date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
    });
  }

  // Handle PhvDecalImage
  if (item?.PhvDecalPath) {
    ltavResultArray?.push({
      title: "PhvDecalImage",
      image: item?.PhvDecalPath      ,
      date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
    });
  }

  // Add similar code blocks for other keys as needed.
});

ltavResultArray.sort((a, b) => {
  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  return dateB - dateA; // Sort in descending order
});

setVehicleDoc(ltavResultArray)

//INSURANCEE

const insuranceArray = [];


InsuranceDocuments?.forEach(item => {
  if (item?.InsuranceFrontPath) {
    insuranceArray.push({
      title: "Insurance",
      image: item?.InsuranceFrontPath,
      date: dayjs(item?.updatedAt)?.format('DD-MM-YYYY')
    });
  }


});

insuranceArray?.sort((a, b) => {
  const dateA = new Date(a?.date).getTime();
  const dateB = new Date(b?.date).getTime();
  return dateB - dateA; // Sort in descending order
});

setInsuranceDoc(insuranceArray)
   
 } })
},[])



  const Components = [
    {
      page: <RideInfo/>,
      value: "Ride Info",
    },
    {
      page: <UserLicense InsuranceDoc={insuranceDocs} VehicleDoc={vehicleDocs} ProfileDoc={profileDocs} LicenseDoc={licenseDocs}/>,
      value: "Documents",
    },
    {
      page: <Service/>,
      value: "Service",
    },
    {
      page: <PaymentDriver/>,
      value: "Payment",
    },

    {
      page: <BankInfo/>,
      value: "Bank Info",
    },


    {
      page: <Pending/>,
      value: "Pending",
    },

    {
      page: <Activity AllActivity={activityData} />,
      value: "Activity",
    }, 
    {
      page:<DriverTickets/>,
      value:"Tickets",
    },
    {
      page:<Remarks RemarkData={remarkData}/>,
      value:"Remarks",
    }
      
  ];



  return (
 <Box>
<Tabs current={'Ride Info'} List={TabItems} component={Components} />
 </Box>
  )
}

export default GeneralDetails
