import {
  Box,
  Grid,
  IconButton,

  Stack,
  Typography,
  // createTheme,
 
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { insertModalDataAction } from "../../Redux/Actions/ModalActions";


function FormNav({ title,Description,warning }) {

const [closeModal , setCloseMOdal] = useState(false)
  const dispatch = useDispatch()
  const {currentModaldata} = useSelector((state) => state.modal)

  // const theme = createTheme({
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 600,
  //       smd:855,
  //       md: 900,
  //       fmd:950,
  //       slg:1098,
  //       lg: 1200,
  //       sxl: 1430,
  //       xl: 1536,
  //     },
  //   },
  // });
  


  const onCloseClick = ()=>{    
     setCloseMOdal((i) => !i )
    dispatch(insertModalDataAction(!currentModaldata))
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Stack display='flex' justifyContent='space-between' direction="row" alignItems="center">
         

            <Typography variant="h6">
              <strong>{title}</strong>
            </Typography>
            <IconButton
            onClick={() => onCloseClick()}
              color="inherit"
              sx={{ border: 0.001, borderColor: "GrayText" }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid paddingTop={0.5} xs={12} item>
        <Typography sx={{opacity:'50%',color:warning ? 'red' : 'none'}} fontSize={10} variant='inherit' >{Description || ''}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FormNav;
