import { Box, IconButton, Stack } from '@mui/material'
import React, { useState } from 'react'
import MultipleSelectBar from '../../Components/Table/MultipleSelectBar'
import closeIcon from '../../../Assets/Table/Group 4336.svg'
import MultipleSelect from '../../Components/Forms/MultipleSelect'
import SelectField from '../../Components/Forms/SelectField'
import ClearAllIcon from '@mui/icons-material/ClearAll';

const VehicleType = [
  {name:'TIME'},{name:'USERS'}
]
const VehicleCategory = [
  {name:'User'},{name:'Driver'}
]
const Features = [
  {name:"Expired"},
]
function VoucherFilter({CloseModal,FilterColumn}) {
  const [isClear,setIsClear] = useState(false)
  const handleMultipleSelect = (label,data)=>{

      if(label === 'Coupon Type'){   
     FilterColumn([data?.name],"")
    
      }else if(label === 'User Type'){

        let filteredItems = data?.map((item)=>{
          return item?.name
        })

        FilterColumn(filteredItems,"")
      }
    
    }
    const HandleClearFilter =()=>{
      setIsClear((i)=> !i)
    }


  return (
  <Box >
      <Stack alignItems={'center'} spacing={2} direction={'row'}>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={VehicleType} fieldName={'Coupon Type'}/> */}
    {/* <Box  width={'250px'}>
    <MultipleSelect notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"Coupon Type"} Data={VehicleType} />
    </Box> */}
    <Box  width={'250px'}>
    <SelectField clearStatus={isClear} onSelecting={handleMultipleSelect}  isError={false}  Data={VehicleType} sideLabel={'Coupon Type'} placeHolder={'choose'} />
    </Box>
    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={VehicleCategory} fieldName={'User Type'} /> */}

    <Box  width={'250px'}>
    <MultipleSelect clearStatus={isClear} notRequired={true} isError={false} onMultiSelect={handleMultipleSelect} sideLabel={"User Type"} Data={VehicleCategory} />
    </Box>

    {/* <MultipleSelectBar FilterItems={FilterColumn}  filterNames={Features}  fieldName={'Expired'}  /> */}
    <Stack direction={'row'} spacing={1}>

    <IconButton onClick={() => HandleClearFilter()} aria-label="delete" size="large">
             <ClearAllIcon/>
    </IconButton>

    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="small">
             <img src={closeIcon} alt="filter"/>
    </IconButton>

    </Stack>
    </Stack>
    
  </Box>
  )
}

export default VoucherFilter