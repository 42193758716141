import { Box, Grid, Stack} from '@mui/material'
import React from 'react'
import SelectField from '../Forms/SelectField'
import { TypeReason, couponType } from '../../FakeData/FakeData'
import FormSubmitButtons from '../Forms/FormSubmitButtons'

import TextField from '../Forms/TextField'

function ReasonForm() {
  return (
<Box>
<Box overflow='auto' height='35vh'>
<Stack spacing={3}>
<Grid container>
          <Grid xs={5.75} item>
          <SelectField
              sideLabel={"Type"}
              placeHolder={"select"}
              Data={TypeReason}
            />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <SelectField
              sideLabel={"Status"}
              placeHolder={"select"}
              Data={couponType}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} item>
          <TextField sideLabel={"Reason"} placeHolder={"Reason"} />
          </Grid>
        </Grid>


</Stack>

</Box>
<Box paddingTop={1}>
      <FormSubmitButtons/>
    </Box>

</Box>
  )
}

export default ReasonForm
