import { Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import closeIcon from '../../../../Assets//Table/Group 4336.svg'
import SelectField from '../../../Components/Forms/SelectField'
import { monthNamesData, yearsData } from '../../../FakeData/FakeData'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'

function LtaFilterTwo({CloseModal,FilterColumn,removerIcon,currentYear,currentMonth}) {

    



    const handleSelect = (label,data)=>{

        if(label === 'Year'){
           
         data?.id &&  FilterColumn(data?.id,"year") 
        }
        if(label === 'Month'){
           
            data?.id &&  FilterColumn(data?.id,"month") 
           }
      }

  return (
    <Box>
    <Stack alignItems={'center'} spacing={2} direction={'row'}>
        
      <Box  width={'200px'}>
      <SelectFieldUpdate currentSelect={currentYear} isError={false} onSelecting={handleSelect} Data={yearsData} sideLabel={'Year'} placeHolder={'select'} />
      </Box>
      <Box  width={'200px'}>
      <SelectFieldUpdate currentSelect={currentMonth}  isError={false} onSelecting={handleSelect} Data={monthNamesData} sideLabel={'Month'} placeHolder={'select'} />
      </Box>
      
  
      {
  !removerIcon &&
    <IconButton onClick={() => CloseModal(false)} aria-label="delete" size="large">
    <img src={closeIcon} alt="filter"/>
   </IconButton> 
}
      </Stack>
      
    </Box>
  )
}

export default LtaFilterTwo
