import { Box } from '@mui/material'
import React, { useState } from 'react'
import employeeData from '../../../FakeData/MOCK_DATA3.json'
import TableNav from '../../../Components/TableSearchExport/TableNav';
import CreateTab from '../../../Components/Forms/CreateTab';
import FormModal from '../../../Components/Modal/FormModal';
import AddCoupon from '../../../Components/Coupons/AddCoupon';
import DataTable from '../../../Components/Table/DataTable';
import CreateEmployee from '../../../Components/Employee/CreateEmployee';


const columns = [
    {
      id: "id",
      label: "Id",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "left",
      sort: true,
    },
    {
      id: "job",
      label: "Job",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
      align: "left",
      sort: false,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 170,
      align: "left",
      sort: true,
    },
 {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];

function EmployeeManagement() {
    const [rows, setRows] = useState(employeeData);
    const [order, setOrder] = useState("ASC");
    const [modalStatus, setModalStatus] = useState(false);

    const columnSortingFn = (id) => {
      if (order === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() > b[id].toLowerCase()
              ? 1
              : -1
            : a[id] > b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("DSC");
      }
  
      if (order === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() < b[id].toLowerCase()
              ? 1
              : -1
            : id === "date"
            ? new Date(a.date).getTime() - new Date(b.date).getTime()
            : a[id] < b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("ASC");
      }
    };
  
    const onClick = () => {
      setModalStatus(true);
    };
  
    const onClose = () => {
      setModalStatus(false);
    };




  return (
     
    <Box>
            <TableNav title={"Employee Management"} />
      <CreateTab handleClick={onClick} title={"Create Employee"} />
      <FormModal
        handleclose={onClose}
        status={modalStatus}
        component={<CreateEmployee />}
      />

      <Box sx={{ width: "100%", height: "100%" }} pt={1}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box>

    </Box>

  )
}

export default EmployeeManagement
