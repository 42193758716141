import { Box, Button, IconButton, Stack, Typography, createTheme, useMediaQuery } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons';
import deleteIcon from '../../../../Assets/Forms/Vector.svg'
import InputField from '../../../Components/Forms/InputField';
import AddIcon from '@mui/icons-material/Add';
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate';
import SelectField from '../../../Components/Forms/SelectField';
import { METHORD } from '../../../FakeData/FakeData';
import { useSelector } from 'react-redux';
import ApiCall from '../../../../Helper/ApiCalls';
import { checkPrivilage } from '../../../../Sessions/Permisssions';

function ConvenienceForm() {

    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [write,setWrite] = useState(true)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [selectedMethord,setSelectedMethord] = useState('')
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [inputFields, setInputFields] = useState([
        { from : 0, to: 0,platFormFee:0,serviceFee:0,insuranceFee:0 }
      ]);



     
  

      useEffect(()=>{   
        let isWrite = checkPrivilage('Convenience Fee','CREATE')
        setWrite(isWrite)
         },[])

      const handleAddFields = () => {
        if(inputFields?.length < 4 ){
          const values = [...inputFields];
          values.push({ from :0, to: 0,platFormFee:0,serviceFee:0,insuranceFee:0 });
          setInputFields(values);
        }    
      };

     
        //DYNAMIC FORM HANDLING
  
  const handleInputChange = (index, event,name) => {
    console.log(`its heree ${index} -->>>> `)
    const values = [...inputFields];
    if (name === "From") {
      values[index].from = parseFloat(event.target.value);
    } else if(name === 'To') {
      values[index].to = parseFloat(event.target.value);
    }else if(name === 'Platform fee'){
      values[index].platFormFee = parseFloat(event.target.value);
    }else if(name === 'Service fee'){
        values[index].serviceFee = parseFloat(event.target.value);
      }
      else if(name === 'Driver insurance fee'){
        values[index].insuranceFee = parseFloat(event.target.value);
      }

    setInputFields(values);
  };

console.log(inputFields,"<-------fieeeee")

      const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
      };
   



    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
    const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));


    const handleSelect = (label,data)=>{

      if(label === 'Select Method'){
  
        setSelectedMethord(data?.id)
          
      }     
  
   
  
    }


    const handleFormSubmit =()=>{
      setSubmitHit(true)

      let sendData={
        countryId:CountryID,
        currency: "SGD",
        convenienceFee:inputFields,
        methord:selectedMethord,
      }

      ApiCall("POST", "/trip-service/v1/admin/convenience-fee/add/fee", sendData).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully created")      
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });


      console.log(sendData , "<-the -<- sendDatas")

    }

  return (
    <Box>     
    <Stack spacing={3} >
    <Box width={isMatch?'100%':'30%'}>
        <SelectField
        onSelecting={handleSelect}
              sideLabel={"Select Method"}
              placeHolder={"select"}
              Data={METHORD}

            />
        </Box>
    {inputFields.map((inputField, index) => (
  
  <Fragment key={`${inputField}~${index}`}>
    <Stack spacing={2}>
        <Stack direction={'row'} spacing={0.5}>
        <Typography sx={{fontWeight:'bold'}}>Add Tier</Typography>
        {/* <Typography sx={{color:'red'}}>*</Typography> */}
        </Stack>
    

    <Box width={isMatch?'100%':'30%'}>
        <Stack direction={'row'} spacing={1}>
        <InputFieldUpdate sideLabel={'From'} Type={'number'} InChange={event => handleInputChange(index, event,'From')} Value={inputField.from} isError={false} ErrNote={""}  placeHolder={"From"} />
        <InputFieldUpdate sideLabel={'To'} Type={'number'} InChange={event => handleInputChange(index, event,'To')} Value={inputField.to} isError={false} ErrNote={""}   placeHolder={"To"} />
        </Stack>
 
    </Box>

    <Stack alignItems={'center'} direction={'row'} spacing={3} >
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={event => handleInputChange(index, event,'Platform fee')} Value={inputField.platFormFee} sideLabel={'Platform fee'} isError={false} ErrNote={""}   placeHolder={""} />
    </Box>
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={event => handleInputChange(index, event,'Service fee')}  Value={inputField.serviceFee} sideLabel={'Service fee'} isError={false} ErrNote={""}   placeHolder={""} />
    </Box>
    <Box width={'30%'}>
    <InputFieldUpdate Type={'number'} InChange={event => handleInputChange(index, event,'Driver insurance fee')} Value={inputField.insuranceFee} sideLabel={'Driver insurance fee'} isError={false} ErrNote={""}  placeHolder={""} />
    </Box>

    {
  index !== 0 && (
    <Box width={isMatch ? "100%" : "1%"} >
    <IconButton onClick={() => handleRemoveFields(index)}> <img src={deleteIcon} alt='delete'/> </IconButton> 
   </Box>
  )
}
    </Stack>



    </Stack>
    </Fragment>

))}
 <Button disabled={inputFields?.length === 4}  onClick={() => handleAddFields()} sx={{width:'130px',height:'24px',textTransform:'none',border:'none'}} variant="outlined" startIcon={<AddIcon />}>
        Add New
      </Button>
    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
  <FormSubmitButtons buttonStatus={write}   handleSubmit={handleFormSubmit} />
    </Stack>
    </Box>
  )
}

export default ConvenienceForm
