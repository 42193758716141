// ConfirmationPage.js
import React from 'react';
import { Typography, Container, Grid, Avatar, Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';

const Confirmation = ({title,description,Link}) => {
    const handleClick=()=>{
        window.location.href = "/"
    }
  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item>
          <Avatar sx={{ bgcolor: 'success.main', width: 56, height: 56 }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
          </Avatar>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Stack direction={'row'} spacing={0.3}>
          <Typography>
          {description}
          </Typography>
          {Link && <Typography  onClick={handleClick}  sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
          {Link}
         </Typography>}
          </Stack>
       
       
        </Grid>
      </Grid>
    </Container>
  );
};

export default Confirmation;
