import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DataTable from '../../Components/Table/DataTable';
import { Box } from '@mui/material';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import TableHead from '../../Components/Table/TableHead';
import ApiCall from '../../../Helper/ApiCalls';
import AppAdsFilter from './AppAdsFilter';
import { useTitle } from '../../../Hooks/UseTitle';
import { useSelector } from 'react-redux';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { toast } from 'react-toastify';


const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "heading",
      label: "Heading",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "app",
      label: "App",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    // {
    //   id: "created",
    //   label: "Created",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
    // {
    //   id: "start_date",
    //   label: "Start Date",
    //   minWidth: 170,
    //   align: "left",
    //   sort: true,
    // },
 
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: true,
        onFalse: false,
      },
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];



function AppAds() {
  useTitle("App Ads - Geolah");
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)

    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0);

    const navigate = useNavigate()
    const [any,setAny] = useState()
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

    useEffect(()=>{
      let isAany = checkAnyPrivilage('App Ads')
      setAny(isAany)
      let isWrite = checkPrivilage('App Ads','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('App Ads','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('App Ads','DELETE')
       setDeletePer(isDelete)
       },[])



    useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
      ApiCall("GET", `/user-service/v1/admin/promo/all/?countryId=${CountryID}`, null, "uui").then((data)=>{
        if(data?.result){
    
        const addData = data?.result
        setTotalRows(addData?.length)
        if(searchInput?.length <= 0){
        let filteredData = addData?.slice(currentOffset, currentOffset + currentLimit).map((eachData,index)=>{
          let items={
            _id:eachData.ID,
            id:index+1,
            type:eachData?.Type,
            heading:eachData?.Name,
            description:eachData?.Description,
            app:eachData?.AppID === 1 ? 'User App':'Driver App' ,
            // created:eachData?.,
            status:eachData?.IsEnable





          }
          return items
        })
        setRows(filteredData)
      }

        let filteredRowData = addData?.map((eachData,index)=>{
          let items={
            _id:eachData.ID,
            id:index+1,
            type:eachData?.Type,
            heading:eachData?.Name,
            description:eachData?.Description,
            app:eachData?.AppID === 1 ? 'User App':'Driver App' ,
            // created:eachData?.,
            status:eachData?.IsEnable
          }
          return items
        })

        console.log(addData,"<999adddFatatata")
    
       
       setTableData(filteredRowData);
     } })
    },[success,CountryID,currentPage,searchInput,rowsPerPage])


    //search items 

    useEffect(()=>{
      setRows(
        tableData.filter((item) =>
        item?.type?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.heading?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.app?.toLowerCase().includes(searchInput.toLowerCase())
   
          ))
       },[searchInput])







    const handleFilterBar = (status)=>{
      setIsFilterOn(status)
    }


    // update service status

  const handleStatusChange =(id, newData)=>{
    //  console.log(`Switch with id ${id} changed. New data:`, newData);
     const paramsData = {promoId:id,status:newData?.status,countryId:1}
     ApiCall("PATCH", "/user-service/v1/admin/promo/update/status",paramsData,'BODY').then((data)=>{setSuccess(data)})
  }




// delete status  
const handleServiceDelete =(id, newData)=>{
// console.log(`Delete with id ${id}. New data:`, newData);
setDeleteStatus(true)
const paramsData ={promoId:id,countryId:1}
setDeleteData(paramsData)

}

const onDeleteYes =(yesData)=>{
ApiCall("PATCH", `/user-service/v1/admin/promo/delete`,yesData,'BODY').then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
// setDeleteStatus(false)
})
} 


const onCloseNotification = ()=>{
setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
if(id){
  navigate(`/edit-add/${id}`)
}


}


// handle search change

const handleChangeInSearch = (value)=>{
 setSearchInput(value)

}






    

    const handleColumnFilter = (items, searchText) => {
     console.log(items , searchText ,"<---search texttssssss")
      setSelectedItems(items); // Update selected filter items
    
      // If no items are selected and no search text, show all rows
      if (items.length === 0 && searchText === "") {
        setRows(tableData);
        return;
      }
    
     
    
      // Filter the rows based on the selected items and search text
      const filteredRows = tableData.filter((row) => {
        // Check if any of the selected items are present in the row's features
        const featuresMatch = items.every(
          (item) => row.features && row.features.includes(item)
        );
    
    
    
        // Check if the row matches any of the selected vehicleCategory items
        const vehicleCategoryMatch = items.includes(row.type);
        const vehicleTypeMatch = items.includes(row.app);
    
        // Check if the row matches all of the selected vehicleType items
        // const vehicleTypeMatch = items.every(
        //   (item) => row.vehicleType && row.vehicleType.includes(item)
        // );
    
    
    
        // Check if the row contains the search text in any of its values
        const searchTextMatch = Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    
        // Return true if any of the filters match the row, otherwise return false
        return (
          (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
          searchTextMatch
        );
      });
    
      // Update the filtered rows
      setRows(filteredRows);
    };
    
    
    
        
        const [order, setOrder] = useState("ASC");
    
        const columnSortingFn = (id) => {
            if (order === "ASC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id"
                  ? a[id].toLowerCase() > b[id].toLowerCase()
                    ? 1
                    : -1
                  : a[id] > b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("DSC");
            }
        
            if (order === "DSC") {
              const sorted = [...rows].sort((a, b) =>
                id !== "id" && id !== "ride_fare"
                  ? a[id].toLowerCase() < b[id].toLowerCase()
                    ? 1
                    : -1
                  : id === "date"
                  ? new Date(a.date).getTime() - new Date(b.date).getTime()
                  : a[id] < b[id]
                  ? 1
                  : -1
              );
              setRows(sorted);
              setOrder("ASC");
            }
          };

          const handlePageChange=(no,rowsCount)=>{
            setCurrentPage(no)
            setRowsPerPage(rowsCount)
            
          }
          


  return (

   any ? ( 
<Box>

<TableHead title={'App Ads'}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box sx={{ width: "100%", height: "100%" }} pt={1}>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
       handlingPage={handlePageChange}
       totalCount={totalRows}
       DeletePermission={deletePer}
       ButtonPermission={write}
       EditPermission={edit} 
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        ButtonPath={'/create-app-ad'}
        ButtonLabel={'Create App Ad'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <AppAdsFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
        handleRowSelection={(noOfrows) => setRowsPerPage(noOfrows) }
      />
    </Box>

</Box>

</Box>
): any === false ? (
<RestrictedUsage/>
) : null
  )
}

export default AppAds
