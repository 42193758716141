import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../../Hooks/UseTitle';
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import { useNavigate, useParams } from 'react-router-dom';
import MiniMapZone from './MiniMapZone';
import CreateRadiusForm from './CreateRadius/CreateRadiusForm';
import FormModal from '../../../Components/Modal/FormModal';
import CreatePopularPlaceForm from './CretePopularPlace/CreatePopularPlaceForm';
import { useDispatch, useSelector } from 'react-redux';
import { insertModalDataAction } from '../../../Redux/Actions/ModalActions';
import PopularPlaceTable from './PopularPlaceTable';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';




function ZoneDetails() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Zone
      </Typography>,
 <Typography key="3" color="text.primary">
      Zone Details
      </Typography>
  ];



useTitle("Zone Details - Geolah");
const [zoneINdex , setZoneIndex] = useState(0)
const { id:zoneName,zoneId } = useParams();
const [modalStatus, setModalStatus] = useState(false);
const {currentModaldata} = useSelector((state) => state.modal)
const {currentAllZonedata} = useSelector((state) => state.allzone)
const [any,setAny] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Zone')
  setAny(isAany)
   },[])

const dispatch = useDispatch()
const onClick = () => {
    setModalStatus(true);
  };
  
  const onClose = () => {
    setModalStatus(false);
  };


// useEffect(()=>{

  
// },[currentAllZonedata])

  const handleCancel=()=>{
  dispatch(insertModalDataAction(!currentModaldata))
  }

useEffect(()=>{
setZoneIndex(zoneName === 'ZoneA' ? 0 : zoneName === 'ZoneB' ? 1 : zoneName === 'ZoneC' ? 2 : zoneName === 'ZoneD' ? 3: 0 )
},[zoneName])


  return (
    any ? (
<Box>
<FormModal isZone={true} handleclose={onClose} status={modalStatus} component={<CreatePopularPlaceForm zoneIndex={zoneINdex}  CancelSubmit={handleCancel}/>} />
<CreatePageHead title={'Zone Details'} BreadList={breadcrumbs}/>
<Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
<Box  sx={{ width: "100%", height: "100%"}} p={4}>
    <Box pl={2}>
    <Typography variant='h6'>{zoneName === 'ZoneA' && 'Zone A' || zoneName === 'ZoneB' && 'Zone B' || zoneName === 'ZoneC' && 'Zone C'|| zoneName === 'ZoneD' && 'Zone D' }</Typography>
    </Box>
    <Box display={'flex'} justifyContent={'space-between'}>

    <Stack width={'100%'} spacing={5} direction={'row'}  display={'flex'} alignItems={'flex-start'}>
    <MiniMapZone currentZoneIndex={zoneINdex} />
    <CreateRadiusForm currentZoneIndex={zoneINdex}/>
    </Stack>

    {/* <Button onClick={onClick}   sx={{
             textTransform:"none",
              marginTop:"40px",
              width: "200px",
              height: "40px",
              borderRadius: "8px",
              backgroundColor: "#021640",
              color: "white",
            }} >Add popular place</Button> */}

</Box>  
</Box>
</Box>

<PopularPlaceTable clickModal={onClick}/>
</Box>
):any === false ? (
  <RestrictedUsage/>
) : null
  )
}

export default ZoneDetails
