import { Box } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import chargesData from '../../FakeData/MOCK_DATA10.json'
import TableNav from '../../Components/TableSearchExport/TableNav';
import DataTable from '../../Components/Table/DataTable';
import CreateTab from '../../Components/Forms/CreateTab';
import FormModal from '../../Components/Modal/FormModal';
import DropOffLocation from '../../Components/Charges/DropOffLocation';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "fee_type",
      label: "Fee Type",
      minWidth: 100,
      align: "left",
      sort: true,
    },
    {
      id: "remarks",
      label: "Remarks",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "left",
      sort: false,
    },
 {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];





function Charges() {
    const [rows, setRows] = useState(chargesData);
  const [order, setOrder] = useState("ASC");
  const [modalStatus, setModalStatus] = useState(false);

  const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  };

  const onClick = () => {
    setModalStatus(true);
  };

  const onClose = () => {
    setModalStatus(false);
  };

  return (
    <Box>
      <TableNav title={"Charges"} />
      <CreateTab handleClick={onClick} title={"Add Charges"} />
      <FormModal
        handleclose={onClose}
        status={modalStatus}
        component={<DropOffLocation />}
      />
      <Box sx={{ width: "100%", height: "100%" }} pt={3}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box>
    </Box>
  )
}

export default Charges
