import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as Link1, useNavigate } from "react-router-dom";
import { loginApiCall } from "../Helper/ApiCalls";
import { publicRequest } from "../App/RequestMethord";
import PermissionChecker from "../Helper/PermissionChecker";
import mainLogo from "../Assets/Group 10.png";
import { LoadingButton } from "@mui/lab";
import SigninSkelton from "./SigninSkelton";
import { useDispatch, useSelector } from "react-redux";
import { insertUserDataAction } from "../App/Redux/Actions/UserActions";
import { insertPermissionDataAction } from "../App/Redux/Actions/PermissionActions";
import { CollectionsOutlined, EmailRounded } from "@mui/icons-material";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import PasswordField from "../App/Components/Forms/PasswordField";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputField from "../App/Components/Forms/InputField";
import validator from 'validator'
import { formatRolePermissions, newformatRolePermissions } from "../App/Components/MultiFunctions/MultiFunctions";

function Signin() {

  const { currentUserdata } = useSelector((state) => state.user);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isNotValidate, setIsNotValidate] = useState(false);
  const [errorNote, setErrorNote] = useState("");
  const [show,setShow] = useState(false)

  const dispatch = useDispatch();



  useEffect(() => {
    function delayedFunction() {
      setLoadingData(false);
    }
    const timeoutId = setTimeout(delayedFunction, 500);

    return () => clearTimeout(timeoutId); // Cleanup the timeout
  }, []);

  // useEffect(()=>{
  //   if(currentUserdata?.ID){
  //      navigate('/')
  //   }

  // },[currentUserdata])

  function handleClick() {
    setLoading(true);
  }

  const onCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  function delayedFunction() {
    setLoading((i) => !i);
    navigate("/")
  }

  const handleEmailValidator = (value)=>{
  
    if (validator.isEmail(value)) {
       setEmail(value)
       setIsNotValidate(false);
      
    } else {
      setIsNotValidate(true);
      setErrorNote("Invalid Email");
    
    }
  }

  const handleLogin = () => {
    const userDetails = { email , password }
    if (email?.length > 10 && password?.length > 3) {
      setIsNotValidate(false);
      loginApiCall("POST", "/gateway/auth/admin/login", userDetails, "uui")
        .then((Data) => {
              console.log(Data,"<----")
          if (Data?.result) {
            let userData = Data?.result 
            const permission = Data?.result?.AdminUserRole;
            let formattedPermissions = newformatRolePermissions(permission,userData?.CountryIDS?.[0])
            // dispatch(insertPermissionDataAction())
            const token = Data.result.AccessToken;
            localStorage.setItem("jwtToken", token);
            dispatch(insertUserDataAction({...userData,adminRole:formattedPermissions}));
          
            // // window.location.reload(true);
            // const Pdetails = PermissionChecker(permission);
            // dispatch(insertPermissionDataAction(Pdetails));

           window.location.href = "/dasboard"
            // if (newTab) {
            //   newTab?.focus(); // Optionally, you can focus on the new tab
            // }
           
         
        
          }
        })
        .catch((error) => {
          setIsNotValidate(true);
          
            if(error.message){
              setErrorNote(error.message);
            }else{
              setErrorNote("Invalid Email or Password");
            }
        
       
        });
    } else {
      setIsNotValidate(true);
      setErrorNote("Invalid Email or Password");
    }
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd: 855,
        md: 900,
        fmd: 950,
        slg: 1098,
        lg: 1200,
        sxl: 1430,
        xl: 1836,
      },
    },
  });

  const navigate = useNavigate();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg")); //sets true below breakpoint
  const secMatch = useMediaQuery(theme.breakpoints.down("md")); //sets true below breakpoint
  const smMatch = useMediaQuery(theme.breakpoints.down("sm")); //sets true below breakpoint
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));

  return loadingData ? (
    <SigninSkelton />
  ) : (
    <>
      <Box
        bgcolor
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& > :not(style)": {
            m: 1,
            width:
              isMatch === true && secMatch === false
                ? "50%"
                : secMatch === true && smMatch === false
                ? "80%"
                : smMatch
                ? "100%"
                : "35%",
            height: xlMatch ? 650 : 600,
          },
          minHeight:
            secMatch === true && smMatch === false
              ? "100vh"
              : smMatch
              ? "85vh"
              : "100vh",
        }}
      >
        <Paper elevation={3}>
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            spacing={4}
          >
            <img
              width={53}
              height={96}
              sizes="small"
              src={mainLogo}
              alt="logo"
            />
            <Typography fontWeight={700} variant="h6">
              Welcome to Geolah Admin
            </Typography>
            {/* <TextField
              error={isNotValidate}
              required
              onChange={(e) => setEmail(e.target.value)}
              variant="filled"
            
              size="small"
              sx={{
                width: "70%",
              }}
              label="Email"
            /> */}
            <Box width={"70%"}>
            <InputField isError={isNotValidate} ErrNote={errorNote} InChange={(e) => handleEmailValidator(e.target.value)} sideLabel={'Email'} placeHolder={'Email'}/>
            </Box>
          
            {/* <TextField
              required
              type={show ? 'text' : 'password' }
              error={isNotValidate}
              helperText={isNotValidate ? errorNote : null}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              margin="none"
              size="small"
              sx={{
                width: "70%",
              }}
              label="Password"
            /> */}
            <PasswordField isError={isNotValidate} ErrNote={errorNote} InChange={(e) => setPassword(e.target.value)} sideLabel={"Password"} placeHolder={"Password"} />

            {isNotValidate ?(
              <>
      <Typography variant="caption" color="error">{errorNote}</Typography>       
      <Link color="#1C1B20" href="/reset-password" underline="hover">
        {"Forgot Password"}
      </Link>
              </>
  
      
    ):(
      <Stack direction="row" display="flex" alignItems="center" spacing={7}>
      <FormControlLabel
        control={<Checkbox color="success" checked={isChecked} onChange={(e) => onCheckBoxChange(e)} />}
        label="Remember me"
      />
    <Link color="#1C1B20" href="/reset-password" underline="hover">
        {"Forgot Password"}
      </Link>

    </Stack>

    ) }
         

        

            <LoadingButton
              onClick={handleLogin}
              loading={loading}
              loadingPosition="end"
              sx={{
                width: "70%",
                fontWeight: 800,
                textTransform: "inherit",
                backgroundColor: "#021640",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#2E2D33",
                },
              }}
              variant="contained"
            >
              Login
            </LoadingButton>
          </Stack>
        </Paper>
      </Box>
    </>
  );
}

export default Signin;