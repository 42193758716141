import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import WarningSwitch from '../../../Components/Forms/WarningSwitch'
import FormModal from '../../../Components/Modal/FormModal'
import KycDetails from '../../WalletModule/WalletDetails/KycDetails';
import { useParams } from 'react-router-dom';
import ApiCall from '../../../../Helper/ApiCalls';
import { baseUrl } from '../../../RequestMethord';
import axios from 'axios';
import SnackBars from '../../../Components/Forms/SnackBars';
import ErrorNotify from '../../../Components/Forms/ErrorNotify';
import { insertModalDataAction } from '../../../Redux/Actions/ModalActions';
import { useDispatch, useSelector } from 'react-redux';
import { checkPrivilage } from '../../../../Sessions/Permisssions';
import AddAmount from '../../WalletModule/WalletDetails/AddAmount';
import { toast } from 'react-toastify';


function DriverWalletCard({CardStatus,walletStatus}) {
  const [submitHit,setSubmitHit] = useState(false)
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [onSuccess, setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)
  const [countryId,setCountryId] = useState()
  const [changeData,setChangesData] = useState({})
  const dispatch = useDispatch()
  const [deductStatus , setDeductStatus] = useState(false)
  const [modalData,setModalData] = useState({})

  
  const [modalStatus, setModalStatus] = useState(false);
  const [modalTwoStatus, setModalTwoStatus] = useState(false);
  const [walletData , setWallet] = useState({});
  const [walletBalance,setWalletBalance] = useState('')
  const [pendingBalance,setPendingBalance] = useState('')
  const [currentKycStatus,setCurrentKycStatus] = useState('')

  const [nricData,setNricData] = useState({})
  const { id:driverId } = useParams(); 
  const {currentModaldata} = useSelector((state) => state.modal)
   
  const [edit,setEdit] = useState()

  useEffect(()=>{
  let isEdit = checkPrivilage('Driver Details','UPDATE')
  setEdit(isEdit)
   },[])


const onClick = () => {
  setModalStatus(true);
};

const onClose = () => {
  setModalStatus(false);
};


const {currentCountrydata:CountryID} = useSelector((state) => state.country)
// useEffect(()=>{

//   setCountryId(CountryID)

// },[CountryID])


useEffect(()=>{
  // /trip-service/v1/admin/driver/details/{driverId}

  if(driverId){
 
    ApiCall("GET", `/pay-service/v1/admin/driver/?userId=${driverId}&&countryId=${CountryID}`,null,).then((data)=>{

      if(data.result){
        const rowData = data?.result
        walletStatus(rowData?.IsVerified)
        const changedFields = rowData?.differingFields
        setChangesData(changedFields)
        setWallet(data?.result)   
        setCurrentKycStatus(data?.result?.Status)
            
      }
  })
  .catch((error) => {

    if(error.message){
      console.log(`Error -> ${error.message}`)
      }else{
        console.log('something went wrong [ridecategory - get]')
      }
  }
  );
  }


},[driverId])






useEffect(()=>{

  const apiUrl = `${baseUrl}/trip-service/v1/driver/profile/activity`; 
  const apiUrlTwo = `${baseUrl}/pay-service/v1/admin/driver/account/balance`;   
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };




 axios.get(apiUrl, config).then(response => {

  if(response?.data?.result){
    const personalData =response?.data?.result
    setNricData(personalData)



  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });


    axios.get(apiUrlTwo, config).then(response => {

      if(response?.data?.result){
        const personalData =response?.data?.result
    
        if(personalData?.status === 'active'){
          setWalletBalance(personalData?.balances[0]?.balance)
          setPendingBalance(personalData?.balances[0]?.pending)
        }else{
          setWalletBalance(personalData)
        }
 
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });




},[driverId])




const handleCancel=()=>{
  dispatch(insertModalDataAction(!currentModaldata))
}





const [apiLoading,setApiLoading] = useState(false)

const handleSubmit=()=>{
setApiLoading(true)
 setSubmitHit(true)

let sendData = {
  userTypeId:2, countryID:1, driverId:parseInt(driverId),docType:"NricImagePath", 
}

  ApiCall("POST", "/trip-service/v1/admin/driver/submit/kyc-adyen",sendData).then((data)=>{
    if(data.status    === 'success'){
      setApiLoading(false)
      dispatch(insertModalDataAction(!currentModaldata))
      setOnSuccess((i) => !i )
      setOnApiSuccess("Succesfully Submitted")
      

    }
})
.catch((error) => {
  setApiLoading(false)
   setOnFailed((i) => !i)
    
    if(error.message){
      setOnApiFailed(error.message)
      console.log(error , "<----errrrrrr")
    }else{
      setOnApiFailed("Something went wrong.!")
    }


});
}


const handleAddAmount =(status)=>{
  status && setDeductStatus(true)
   let modalMainData = {

     driverId:driverId,
     countryId: CountryID,
     amount: 0
   }
   setModalData(modalMainData)
   setModalTwoStatus(true);
 }

 const onCloseTwo = (isFlag) => {
  if(isFlag){
    dispatch(insertModalDataAction(!currentModaldata))
    toast.success("Transaction success");
  }
 
  setModalTwoStatus(false);
  setDeductStatus(false)
};




  return (<>
  {/* <Typography  variant='h3' sx={{marginTop:'200px',marginLeft:"200px"}}>IN ACTIVE </Typography> */}
   <Box  width={'350px'} height={'341px'} borderRadius={'8px'} bgcolor={'#FFFFFF'}>
        <FormModal Type={!walletData?.EntityData && 'Small'} handleclose={onClose} status={modalStatus} component={<KycDetails changedItems={changeData} loadingStatus={apiLoading} CancelSubmit={handleCancel} confirmSubmit={handleSubmit} WalletData={{...walletData,...nricData}}/>} />
        <FormModal Type={'Small'} handleclose={onCloseTwo} status={modalTwoStatus} component={<AddAmount isDuduct={deductStatus} Success={onCloseTwo} sendData={modalData}/>} />
   <Stack p={3} spacing={4}>
{/* FIRST ROW    */}
 <Typography fontWeight={'bold'} variant='p'>Wallet</Typography>
 {/* SECOND ROW */}
 <Stack>
 <Typography fontWeight={'bold'} variant={walletBalance === 'NoBalanceAccountPresent'? 'h6':'h2'}>{`S$ ${walletBalance === 'NoBalanceAccountPresent'? "No Balance" : (walletBalance / 100).toFixed(2)}` }</Typography>
 <Typography color={parseInt(pendingBalance) > 0 && 'red'}  variant='p'>{`Pending S$${(pendingBalance/100).toFixed(2)} `}</Typography>
 </Stack>

 {/* THIRD ROW */}
 <Stack direction={'row'} spacing={2}>
  <Button onClick={() => handleAddAmount(false)} disabled={!edit} sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Add Amount</Button>
  <Button onClick={() => handleAddAmount(true)} disabled={!edit} sx={{textTransform:"none",backgroundColor:"#D3D3D9",color:"black",borderRadius:"6px"}} >Deduct Amount</Button>
 </Stack>
{/* LAST ROW  */}
<Box alignItems = {'center'} display={'flex'} justifyContent={'space-between'} pt={2} width={'100%'} sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
{/* <Typography sx={{ opacity: "50%" }} variant="caption">Disable Wallet</Typography>
<WarningSwitch/> */}
{ edit ?
  <Button disabled={currentKycStatus === 'ADMIN_SUBMITTED'} onClick={onClick} sx={{textTransform:"none",backgroundColor:currentKycStatus === 'ADMIN_SUBMITTED'  ? "#ecf0f1":"#44bd32",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Submit KYC</Button> : <Button disabled={true} onClick={onClick} sx={{textTransform:"none",backgroundColor:currentKycStatus === 'ADMIN_SUBMITTED'  ? "#ecf0f1":"#44bd32",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Submit KYC</Button> }
             <Stack>
                <Typography sx={{ opacity: "50%" }} variant="caption">
                  Current KYC Status
                </Typography>
                <Typography  variant='caption' fontWeight={650}>{currentKycStatus}</Typography>
              </Stack>

</Box>
   </Stack>
   <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
   </Box>
   </>
  )
}

export default DriverWalletCard
 