import React, { useEffect, useState } from 'react'
import './nointernet.css'
import { useNavigate } from 'react-router-dom';

function NoInterNet() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const navigate = useNavigate()
  useEffect(()=>{
    if(!isOnline){
       navigate('/nointernet')
    }
  })
  useEffect(() => {
    
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return (
    <div className="wrapper">
      {
        isOnline ? (
          <>
             <h1 className='h1on' >ONLINE</h1>
          <h4>Please check your internet connection</h4>
          </>
       
        ) : (
          <>
          
          <h1 className='h1off' >OFFLINE</h1>
          <h4>Please check your internet connection</h4>
          </>
        )
      }
   

    <a href="." className="button">RETRY</a>
  </div>
  )
}

export default NoInterNet
