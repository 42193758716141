import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import MultistepProgressBar from '../Forms/ProgressBar/MultistepProgressBar';

import PersonalDeatils from './PersonalDeatils';
import DriverDocuments from './DriverDocuments';
import DriverVehicleDeatils from './DriverVehicleDeatils';
import DriverBankDetails from './DriverBankDetails';

function DriverForm() {
    const [page, setPage] = useState("pageone");

    const nextPage = (page) => {
      setPage(page);
    };



    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
          case "1":
            setPage("pageone");
            break;
          case "2":
            setPage("pagetwo");
            break;
          case "3":
            setPage("pagethree");
            break;
          case "4":
            setPage("pagefour");
            // alert("Ooops! Seems like you did not fill the form.");
            break;
          default:
            setPage("1");
        }
      };
  return (
    <Box>
        <Stack spacing={4} >

        <Stack spacing={0.5} >

        
         <MultistepProgressBar page={page} onPageNumberClick={nextPageNumber} />
         <Box width="100%" display="flex" justifyContent="space-between" >
         <Typography alignSelf="center"  variant='caption' >Personal Details</Typography>
         <Typography alignSelf="center"  variant='caption' >Documents</Typography>
         <Typography alignSelf="center"  variant='caption' >Vehicle Details </Typography>
         <Typography alignSelf="center"  variant='caption' >Bank Details</Typography>

         </Box>
         </Stack>
         {
        {
          pageone: <PersonalDeatils onButtonClick={nextPage} />,
          pagetwo: <DriverDocuments onButtonClick={nextPage} />,
          pagethree: <DriverVehicleDeatils onButtonClick={nextPage} />,
          pagefour: <DriverBankDetails onButtonClick={nextPage} />,
        }[page]
      }
      </Stack>
    </Box>
 
  )
}

export default DriverForm
