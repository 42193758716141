import { Box, Stack, createTheme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectField from "../../../Components/Forms/SelectField";
import InputField from "../../../Components/Forms/InputField";
import SingleCard from "../../../Components/Services/SingleCard";
import { cardData } from "../../../FakeData/FakeData";
import SnackBars from "../../../Components/Forms/SnackBars";
import ErrorNotify from "../../../Components/Forms/ErrorNotify";
import FormSubmitButtons from "../../../Components/Forms/FormSubmitButtons";
import ApiCall from "../../../../Helper/ApiCalls";
import SingleCardUpdate from "../../../Components/Services/SingleCardUpdate";
import InputFieldUpdate from "../../../Components/Forms/InputFieldUpdate";
import { useParams } from "react-router-dom";
import {
  matchAndAddDataField,
  processNestedObject,
} from "../../../Components/MultiFunctions/MultiFunctions";
import { useSelector } from "react-redux";
import LinearIndeterminate from "../../../Components/Loading/LoadingPage";
import { checkPrivilage } from "../../../../Sessions/Permisssions";

function EditRoleForm() {
  const [onSuccess, setOnSuccess] = useState(false);
  const [onFailed, setOnFailed] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [onApiSuccess, setOnApiSuccess] = useState("");
  const [onApiFailed, setOnApiFailed] = useState("");
  const [submitHit, setSubmitHit] = useState(false);
  const [allData, setAllData] = useState([]);
  const [cardDatas, setCardData] = useState({});
  const [AllCardData, setAllCardData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [alteredData, setAlteredData] = useState([]);
  const { id: roleID } = useParams();
  const [loading, setLoading] = useState(true);
  const { currentCountrydata: CountryID } = useSelector(
    (state) => state.country
  );

  const [edit, setEdit] = useState();

  useEffect(() => {
    let isEdit = checkPrivilage("Roles", "UPDATE");
    setEdit(isEdit);
  }, []);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 900,
      },
    },
  });

  const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

  const updateCardData = (cardId, cardName, isChecked) => {
    setCardData((prevData) => ({
      ...prevData,
      [cardId]: {
        name: cardName,
        data: isChecked,
      },
    }));
  };

  const contentLoaded = (status) => {
    setLoading(false);
  };

  useEffect(() => {
    ApiCall("GET", `/user-service/v1/admin/role/module`, null, "uui").then(
      (data) => {
        if (data?.result) {
          setAllCardData(data?.result);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (roleID) {
      ApiCall("GET", `/user-service/v1/admin/role/ByID/?roleId=${roleID}`, null)
        .then((data) => {
          if (data.result) {
            const roleData = data?.result;
            setCurrentData(roleData?.RolePermissions);
            setRoleName(roleData?.Name);
            setDescription(roleData?.Description);
            console.log(data?.result, "<---the by id");
          }
        })
        .catch((error) => {
          if (error.message) {
            console.log(`Error -> ${error.message}`);
          } else {
            console.log("something went wrong [ridecategory - get]");
          }
        });
    }
  }, [roleID]);

  useEffect(() => {
    if (currentData?.length > 0 && AllCardData?.length > 0) {
      let mixedData = matchAndAddDataField(currentData, AllCardData);
      setAlteredData(mixedData);
      setLoading(false);
    }
  }, [currentData, AllCardData]);

  console.log(cardDatas, "<--:DF:LDFGH:");

  const handleFormSubmit = () => {
    setSubmitHit(true);

    let SendData = {
      roleId: parseInt(roleID),
      roleName: roleName,
      description: description,
      rolePermission: processNestedObject(cardDatas),
    };

    ApiCall("POST", "/user-service/v1/admin/role/edit", SendData)
      .then((data) => {
        if (data.status === "success") {
          setOnSuccess((i) => !i);
          setOnApiSuccess("Succesfully updated role");
        }
      })
      .catch((error) => {
        setOnFailed((i) => !i);

        if (error.message) {
          setOnApiFailed(error.message);
          console.log(`error:${error}`);
        } else {
          setOnApiFailed("Something went wrong.!");
        }
      });

    console.log(SendData, "<---api Dataa");
  };

  return loading ? (
    <LinearIndeterminate />
  ) : (
    <Box>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <Stack direction={isMatch ? "column" : "row"} spacing={3}>
            <Box width={"30%"}>
              <InputFieldUpdate
                Value={roleName}
                isError={false}
                ErrNote={""}
                InChange={(e) => setRoleName(e.target.value)}
                sideLabel={"Role Name"}
                placeHolder={"Role Name"}
              />
            </Box>
            <Box width={"70%"}>
              <InputFieldUpdate
                Value={description}
                isError={false}
                ErrNote={""}
                InChange={(e) => setDescription(e.target.value)}
                sideLabel={"Description"}
                placeHolder={"Enter description"}
              />
            </Box>
          </Stack>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            {alteredData?.map((eachItem, index) => (
              <SingleCardUpdate
                // handleLoading={() => setLoading(false)}
                key={index}
                ID={eachItem?.ID}
                handleCardData={updateCardData}
                cardName={eachItem?.Name}
                cardImage={eachItem?.IconUrl}
                currentData={eachItem?.data}
              />
            ))}
          </Box>
        </Stack>
        {/* <AlertPopUp/> */}
        <SnackBars
          submitStatus={submitHit}
          successNote={onApiSuccess}
          Status={onSuccess}
        />
        <ErrorNotify
          submitStatus={submitHit}
          Status={submitHit && onFailed}
          errorNote={onApiFailed}
        />

        <FormSubmitButtons buttonStatus={edit} handleSubmit={handleFormSubmit} />
      </Stack>
    </Box>
  );
}

export default EditRoleForm;
