import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, OutlinedInput, Stack, createTheme, useMediaQuery,Typography, IconButton } from '@mui/material'

import React, { Fragment, useEffect, useState } from 'react'
import SelectField from '../../../Components/Forms/SelectField'
import { BONUS_TYPE, FARE_CONDITION, HOURLY_BOOKING, HOUR_DATA, MINUTES_DATA, NO_OF_SEATS } from '../../../FakeData/FakeData'


import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import { useSelector } from 'react-redux'
import ApiCall from '../../../../Helper/ApiCalls'
import EditBonusFareForm from './EditBonusFareForm'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import deleteIcon from '../../../../Assets/Forms/Vector.svg'
import { useNavigate, useParams } from 'react-router-dom'
import InputField from '../../../Components/Forms/InputField'
import { checkPrivilage } from '../../../../Sessions/Permisssions'

function EditMainFareForm({currentInfo}) {
    const { id:categoryID } = useParams();
    const [submitHit,setSubmitHit] = useState(false)
    const [basicFare , setBasicFare] = useState('')
    const [maxDistance,setMaxDistance] = useState('')
    const [additionalCost,setAdditionalCost] = useState('')
    const [isShowBonus , setIsShowBonus] = useState(false)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)
    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [bonusFareCount, setBonusFareCount] = useState(1); 
    const { currentBasicdata } = useSelector((state) => state.basic);
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [indexZero,setIndexZero] = useState({})
    const [indexTwo,setIndexTwo] = useState({})
    const [indexThree,setIndexThree] = useState({})
    const [indexFour,setIndexFour] = useState({})
    const [advancedBookingCost,setAdvancedBookingCost] = useState('')
    const [minBookingTime , setMinBookingTime] = useState('')
    const [maxBookingTime , setMaxBookingTime] = useState('')
    const [selectedFreequency , setSelectedFreequency] = useState('')
    const [freequencyCost,setFreequencyCost] = useState('')
    const [isShowAdvanced,setIsShowAdvanced] = useState('')
    const [isShowHourly,setIsShowHouly] = useState('')
    const [hourlyBlockOne , setHourlyBlockone] = useState('')
    const [blockOneCost, setBlockOneCost] = useState('')
    const [hourlyBlockTwo , setHourlyBlockTwo] = useState('')
    const [blockTwoCost, setBlockTwoCost] = useState('')
    const [hourlyBlockThree , setHourlyBlockThree] = useState('')
    const [blockThreeCost, setBlockThreeCost] = useState('')
    const [hourlyBlockOverTime , setHourlyBlockOverTime] = useState('')
    const [blockOverTimeCost, setBlockOverTimeCost] = useState('')
    const [selectedAdults,setSelectedAdults ] = useState('')
    const [selectedPets,setSelectedPets ] = useState('')
    const [currentCat , setCurrentCat] = useState([])
    const [insuranceData , setInsuranceData] = useState([])
    const [isShowInsurance , setIsShowInsurance] = useState(false)
    const [tripInsuranceId , setTripInsuranceId] = useState('')
    const [isShowTravelersSplit , setIsShowTravelersSplit] = useState(false)
    const [rideCatFareId,setRideCatFareId] = useState('')
    const [advancedBookingId,setAdvancedBookingId] = useState('')
    const [hourlyBookingId,setHourlyBookingId]= useState('')
    const [progressionDistance,setProgressionDistance] = useState('')
    const [inputFields, setInputFields] = useState([
      { Value : '', Condition:'Greater',Type:'Amount',Fare:''}
    ]);

    const [deletedFields , setDeletedFields] = useState([])

    const [edit,setEdit] = useState()
  

    useEffect(()=>{
   
      
      let isEdit = checkPrivilage('Basic Fare','UPDATE')
      setEdit(isEdit)
  
       },[])

    const navigate = useNavigate()

  

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
     const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

     useEffect(()=>{

      if(currentInfo){
      let currentData = currentInfo?.RideCategoryFareID
      let advancedData  =currentData?.AdvancedBookingID
      let hourlyData = currentData?.HourlyBookingID
      let travelersData = currentData?.TravelersSplit
      let insuranceData = currentData?.TripInsuranceData
       setBasicFare(currentData?.MinCharge)
       setMaxDistance(currentData?.MinDistance)
       setAdditionalCost(currentData?.FairPerKilometres)
       setProgressionDistance(currentData?.ProgressiveDistanceThreshold)
       setIsShowAdvanced(advancedData?.IsEnabled)
       setAdvancedBookingCost(advancedData?.AdvancedBookingCost)
       setMinBookingTime(advancedData?.MinAdvanceBookingTime)
       setMaxBookingTime(advancedData?.MaxAdvanceBookingTime)
       setSelectedFreequency(advancedData?.ChangeFrequency)
       setFreequencyCost(advancedData?.ChangeFare)
       setIsShowHouly(hourlyData?.IsEnabled)
       setHourlyBlockone(hourlyData?.Block1?.Hour)
       setBlockOneCost(hourlyData?.Block1?.Hour)
       setHourlyBlockTwo(hourlyData?.Block2?.Hour)
       setBlockTwoCost(hourlyData?.Block2?.Cost)
       setHourlyBlockThree(hourlyData?.Block3?.Hour)
       setBlockThreeCost(hourlyData?.Block3?.Cost)
       setHourlyBlockOverTime(hourlyData?.OverTime?.Hour)
       setBlockOverTimeCost(hourlyData?.OverTime?.Cost)
       setIsShowTravelersSplit(travelersData?.IsEnabled)
       setInputFields(currentData?.Bonus)
       setIsShowBonus(currentData?.Bonus?.[0]?.IsEnabled)
       setRideCatFareId(currentData?._id)
       setAdvancedBookingId(advancedData?._id)
       setHourlyBookingId(hourlyBookingId?._id)


       travelersData?.map((eachData,index)=>{
          if(eachData?.travelers === 'Adult'){
            setSelectedAdults(eachData?.Count)
          }else if(eachData?.travelers === 'Pets'){
            setSelectedPets(eachData?.Count)
          }
       })

       setIsShowInsurance(insuranceData?.CotData?.[0]?.IsEnabled)
       if(insuranceData?.CotData?.[0]){
        setInsuranceData(insuranceData?.CotData)
        setTripInsuranceId(insuranceData?.tripInsuranceID) 
       }else{

        ApiCall("GET", `/trip-service/v1/admin/insurance/ride/coverage/?countryId=${CountryID}`, null, "uui").then((data)=>{
          if(data?.result){
           //its done like this because in api you will recive only active  
            const results = data?.result?.[0]  
            setTripInsuranceId(results?._id) 
            
            const categoryData = data?.result?.[0]?.Cot
            console.log(categoryData,"<-------RQ")
            let filteredCot = categoryData?.map((eachItem)=>{
              return {
                cotID:eachItem?._id,
                cost:eachItem?.cost,
                isMandatory:eachItem?.isMandatory,
                IsEnabled:isShowInsurance,
                cotName:eachItem?.cotname
  
              }
            })
            setInsuranceData(filteredCot)
           
       } })

       }
      

       
      


      }
     },[currentInfo])



     console.log(inputFields,"<----inputfieldssssss")



     const handleSelect = (label,data,index)=>{
          
        if(label === 'Minimum Advance Booking Time(min)'){
          setMinBookingTime(data?.name)
        } else if(label === 'Maximum Advance Booking Time(hr)'){
          setMaxBookingTime(data?.name)
        } else if(label === 'Cost Change Frequency(hr)'){
          setSelectedFreequency(data?.name)
        } else if(label === 'Hourly Block 1'){
          setHourlyBlockone(data?.name)
          console.log(data?.name,"<---H-OU-RL-Y")
        }else if(label === 'Hourly Block 2'){
          setHourlyBlockTwo(data?.name)
        }else if(label === 'Hourly Block 3'){
          setHourlyBlockThree(data?.name)
        }else if(label === 'Hourly Overtime'){
          setHourlyBlockOverTime(data?.name)
        } else if(label === 'Type'){
        handleInputChange(index,data?.name,'Type')
           }
     
    
      }



      const handleAddAnother = () => {
        // Step 2: Increment bonusFareCount, but limit it to a maximum of 4
        setBonusFareCount((prevCount) => Math.min(prevCount + 1, 4));
      };
      const handleRemoveAnother = (indexToRemove) => {
        // Step 2: Increment bonusFareCount, but limit it to a maximum of 4
        if(bonusFareCount > 1){
          setBonusFareCount(bonusFareCount - 1 );

          if (indexToRemove === 0) {
            setIndexZero({});
          } else if (indexToRemove === 2) {
            setIndexTwo({});
          } else if (indexToRemove === 3) {
            setIndexThree({});
          } else if (indexToRemove === 4) {
            setIndexFour({});
          }
    

        }

        
        
      };

      const handleBonusFareChange = (index,data)=>{

        if(index === 0){
          setIndexZero(data)
        }else if(index === 2){
          setIndexTwo(data)
        }else if(index === 3){
          setIndexThree(data)
        }else if(index === 4){
          setIndexFour(data)
        }
         
         console.log(`index is ${index} `)
         console.log(data,"<----~!!!######")
       }


       const handleFormSubmit =()=>{
        setSubmitHit(true);

        let filteredBonus = inputFields.map((each)=>{
          if(each?._id){
            return{IsEnabled:isShowBonus,bonusId:each?._id,Condition:each?.Condition,Fare:each?.Fare,Type:each?.Type,Value:each?.Value,Action:"update"}
          }else{
            return{IsEnabled:isShowBonus,Action:"create",...each}
          }
       
         })

         let combinedBonus = []
    if(deletedFields?.[0]){
    combinedBonus = [...filteredBonus,...deletedFields]
    }else{
    combinedBonus = filteredBonus 
    }
      
console.log(progressionDistance , " <---orrrrrrr")  
      
        let data={

          valueServiceId:currentBasicdata?.valueServiceId,
          // rideCategoryId:categoryID,
          rideCategoryFareId:rideCatFareId,
          // rideCategoryFareId:"64e87ac089321b97cbd8e11d",
          paymentSplitId:currentBasicdata?.paymentSplitId,
          baseFare:basicFare,
          maxDistanceFare:maxDistance,
          aditionalCost:additionalCost,
          progressiveDistance:progressionDistance?.toString(),
          bonus:combinedBonus,
          advanceBookingCharge:{
            advanceBookingChargeId:advancedBookingId,
            bookingCost:advancedBookingCost,
            minBookingTime:minBookingTime,
            maxBookingTime:maxBookingTime,
            changeFrequency:selectedFreequency,
            changeFare:freequencyCost,
            IsEnabled:isShowAdvanced,
           },
           hourlyBooking:{

            hourlybookingId:hourlyBookingId,
            block1:{
              Hour:hourlyBlockOne,
              Cost:blockOneCost
            },
            block2:{
              Hour:hourlyBlockTwo,
              Cost:blockTwoCost
            },
            block3:{
              Hour:hourlyBlockThree,
              Cost:blockThreeCost
            },
            overtime:{
              Hour:hourlyBlockOverTime,
              Cost:blockOverTimeCost
            },
            IsEnabled:isShowHourly
           },
           travellerSplit: [
            {
              travelers: "Adult",
              Count: selectedAdults
            },
            {
              travelers: "Pets",
              Count: selectedPets
            }
          ],
          tripInsuranceData:{
            tripInsuranceID:tripInsuranceId, 
            CotData:insuranceData,
      
          },
      
          }


          console.log(data,"<---aatattatatata")
      
      
          ApiCall("POST", "/trip-service/v1/admin/ride-category/update/fare",data).then((data)=>{
            if(data.status === 'success'){
              setOnSuccess(true)
              setOnApiSuccess("Succesfully updated fare")
              
        
            }
        })
        .catch((error) => {
          setOnFailed((i) => !i)
      
          if(error.message){
            setOnApiFailed(error.message)
          }else{
            setOnApiFailed("Something went wrong.!")
          }
          console.error(error, "PUT API ERROR");
        
        
        });
      
      
        console.log(data,"<====SUBMITTED|-|DATAA")
      
      }


      const handleDiscard =()=>{
        navigate(-1)
      }
    


      const handleCostChange = (dataName, index) => {
        setInsuranceData((prevCat) => {
          const updatedCat = [...prevCat]; // Create a copy of the currentCat array
          if (index >= 0 && index < updatedCat.length) {
            // Check if the index is valid
            updatedCat[index] = {
              ...updatedCat[index],
              cost: dataName, // Update the 'cost' property with the new value
            };
          }
          return updatedCat;
        });
      };

      const handleInsuranchShowChange = (dataName, index) => {
        setInsuranceData((prevCat) => {
          const updatedCat = [...prevCat]; // Create a copy of the currentCat array
          if (index >= 0 && index < updatedCat.length) {
            // Check if the index is valid
            updatedCat[index] = {
              ...updatedCat[index],
              IsEnabled: dataName, // Update the 'cost' property with the new value
            };
          }
          return updatedCat;
        });
         setIsShowInsurance(dataName)

      };





       //DYNAMIC FORM HANDLING
  
       const handleInputChange = (index, event,name) => {
        const values = [...inputFields];
        if (name === "Fare") {
          values[index].Fare = event.target.value;
        } else if(name === 'Bonus Amount') {
          values[index].Value = event.target.value;
        }else if(name === 'Type') {
          values[index].Type = event? event :null;
        }
    
        setInputFields(values);
      };
    
      const handleAddFields = () => {
        if(inputFields?.length < 4 ){
          const values = [...inputFields];
          values.push({Value : '', Condition:'Greater',Type:'Amount',Fare:''});
          setInputFields(values);
        }
    
      };
    
      const handleRemoveFields =(index,data) => {
        if(data?._id){
       deletedFields.push({Action:"delete",bonusId:data?._id})
        }
        // console.log(data ,"<---deletee dataaaaa")
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
      };



    console.log(deletedFields,"<--deleteDielddssssssssssssssssssss")




  return (
 <Box>
    <Box>
<Stack spacing={3} >


{/* TOP FORM */}

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'20%'}>
<InputFieldUpdate Type={'number'} Value={basicFare} isError={submitHit && basicFare?.length < 1 } ErrNote={""} InChange={(e) => setBasicFare(e.target.value)}   sideLabel={"Base Fare"} placeHolder={"Base Fare"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputFieldUpdate Type={'number'} Value={maxDistance} isError={submitHit && maxDistance?.length < 1 } ErrNote={""} InChange={(e) => setMaxDistance(e.target.value)}   sideLabel={"Minimum Distance for Base Fare (Km) "} placeHolder={"Maximum Distance for Base Fare"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputFieldUpdate Value={progressionDistance} Type={'number'} isError={submitHit && progressionDistance?.length < 1 } ErrNote={""} InChange={(e) => setProgressionDistance(e.target.value)}   sideLabel={"Progressing Distance Threshold"} placeHolder={"Progressing Distance Threshold"} />
    </Box>
    <Box width={isMatch ? "20%" : "50%"} >
    <InputFieldUpdate Type={'number'} Value={additionalCost} isError={submitHit && additionalCost?.length < 1 } ErrNote={""} InChange={(e) => setAdditionalCost(e.target.value)}   sideLabel={"Additional Cost"} placeHolder={"Additional Cost"} />
    </Box>
</Stack>


{/* BONUS FORM */}
<Box >
<Stack spacing={1} alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowBonus(e.target.checked)}  control={<Checkbox checked={isShowBonus}  color="success" />} label="Bonus" />
<Button size='small'
 onClick={() => handleAddFields()}
 sx={{
 
    textTransform:"none",
     height:"24px" ,
      color:'black',
      borderColor:"ButtonShadow",
      borderRadius:"4px",
      backgroundColor:'white',
      }}  variant="outlined">Add Another</Button>
</Stack>
</Box>
{/* <BonusFare/> */}


{inputFields?.map((inputField, index) => (

  

<Fragment key={`${inputField}~${index}`}>

<Box bgcolor={'#F9F9F9'} height={isMatch ? '500px' :'125px'} width={isMatch ? '50%':'100%'} >
    <Stack padding={2} justifyContent={'center'} alignItems={'center'} width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
    <Box width={'60%'}>

<Typography variant='p'><strong>Total Ride Fare</strong></Typography>
    </Box>
    <Box width={isMatch ? "100%" : "40%"} >
    <SelectFieldUpdate currentSelect={inputField?.Fare ? 'Greater' : 'choose'}  isError={''} onSelecting={handleSelect} Data={FARE_CONDITION} sideLabel={'Condition'} placeHolder={'choose'} />
    </Box>

    <Box width={isMatch ? "100%" : "40%"}>
    <InputFieldUpdate Type={'number'} Value={inputField.Fare}  isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Fare')}  sideLabel={"Fare "} placeHolder={"Fare"} />
    </Box>

    <Box width={isMatch ? "100%" : "40%"} > 
     <SelectFieldUpdate indexes={index} currentSelect={inputField?.Type || 'select'} isError={''} onSelecting={handleSelect} Data={BONUS_TYPE} sideLabel={'Type'} placeHolder={'choose'} /> 
     </Box>
    <Box width={isMatch ? "100%" : "50%"} >
    <InputFieldUpdate Type={'number'} Value={inputField.Value}  isError={''} ErrNote={""} InChange={event => handleInputChange(index, event,'Bonus Amount')}   sideLabel={"Bonus Amount "} placeHolder={"Bonus Amount"} />
    </Box>
    <Box  width={isMatch ? "100%" : "50%"} >
      { index !== 0  &&   <IconButton  onClick={() => handleRemoveFields(index,inputField)}> <img src={deleteIcon} alt='delete'/> </IconButton> }
   </Box>

</Stack>

 </Box>

</Fragment>

))}




{/* ADVANCED BOOKING CHARGE */}

<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowAdvanced(e.target.checked)}  control={<Checkbox checked={isShowAdvanced}  color="success" />} label="Advance Booking Charge" />

</Stack>
</Box>
{isShowAdvanced ?
<>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
<InputFieldUpdate Type={'number'} Value={advancedBookingCost} isError={submitHit && advancedBookingCost?.length < 1 } ErrNote={""} InChange={(e) => setAdvancedBookingCost(e.target.value)}   sideLabel={"Advance Booking Cost "} placeHolder={"choose"} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectFieldUpdate currentSelect={minBookingTime || 'choose'} isError={''} onSelecting={handleSelect} Data={MINUTES_DATA} sideLabel={'Minimum Advance Booking Time(min)'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectFieldUpdate currentSelect={maxBookingTime || 'choose'} isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Maximum Advance Booking Time(hr)'} placeHolder={'choose'} />
    </Box>

</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'32%'}>
<SelectFieldUpdate currentSelect={selectedFreequency || 'choose'} isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Cost Change Frequency(hr)'} placeHolder={'choose'} />
</Box>
<Box width={'8%'}>
<InputFieldUpdate Type={'number'} Value={freequencyCost} isError={submitHit && freequencyCost?.length < 1 } ErrNote={""} InChange={(e) => setFreequencyCost(e.target.value)}   sideLabel={""} placeHolder={"Cost"} />
</Box>

</Stack>
</>:



<>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={'50%'}>
<InputField disable={true} isError={submitHit && advancedBookingCost?.length < 1 } ErrNote={""} InChange={(e) => setAdvancedBookingCost(e.target.value)}   sideLabel={"Advance Booking Cost"} placeHolder={"choose"} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Minimum Advance Booking Time(min)'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch ? "43%" : "50%"} >
    <SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Maximum Advance Booking Time(hr)'} placeHolder={'choose'} />
    </Box>

</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'22.5%'}>
<SelectField disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Cost Change Frequency(hr)'} placeHolder={'choose'} />
</Box>
<Box width={'8%'}>
<InputField disable={true} isError={submitHit && freequencyCost?.length < 1 } ErrNote={""} InChange={(e) => setFreequencyCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
</Box>

</Stack>

</>

}


{/* HOURLY BOOKING CHARGE */}
<Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => setIsShowHouly(e.target.checked)}  control={<Checkbox checked={isShowHourly}  color="success" />} label="Hourly Booking Charge" />

</Stack>
</Box>
{isShowHourly ? (
  <>
<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >


 <Box width={'100%'}>
<SelectFieldUpdate currentSelect={hourlyBlockOne} isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 1'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputFieldUpdate Type={'number'} Value={blockOneCost} isError={submitHit && blockOneCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOneCost(e.target.value)}   sideLabel={""} placeHolder={"Cost"} />
</Box>



<Box width={'100%'}>
<SelectFieldUpdate currentSelect={hourlyBlockTwo} isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 2'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputFieldUpdate Type={'number'} Value={blockTwoCost} isError={submitHit && blockTwoCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockTwoCost(e.target.value)}   sideLabel={""} placeHolder={"Cost"} />
</Box>


<Box width={'100%'}>
<SelectFieldUpdate currentSelect={hourlyBlockThree} isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 3'} placeHolder={'choose'} />
</Box>
<Box pl={1} width={'40%'}>
<InputFieldUpdate Type={'number'} Value={blockThreeCost} isError={false} ErrNote={""} InChange={(e) => setBlockThreeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost"} />
</Box>





</Stack>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={4} >
<Box width={'21.5%'}>
<SelectFieldUpdate  currentSelect={hourlyBlockOverTime}  isError={''} onSelecting={handleSelect} Data={HOUR_DATA} sideLabel={'Hourly Overtime'} placeHolder={'choose'} />
</Box>
<Box width={'8.7%'}>
<InputFieldUpdate Type={'number'} Value={blockOverTimeCost} isError={submitHit && blockOverTimeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOverTimeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost"} />
</Box>

</Stack>
</>
):(
  <>
  <Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
  
  
  <Box width={'100%'}>
  <SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={HOURLY_BOOKING} sideLabel={'Hourly Block 1'} placeHolder={'choose'} />
  </Box>
  <Box pl={1} width={'40%'}>
  <InputField  disable={true} isError={submitHit && blockOneCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOneCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
  </Box>
  
  
  
  <Box width={'100%'}>
  <SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Block 2'} placeHolder={'choose'} />
  </Box>
  <Box pl={1} width={'40%'}>
  <InputField  disable={true} isError={submitHit && blockTwoCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockTwoCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
  </Box>
  
  
  
  <Box width={'100%'}>
  <SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Block 3'} placeHolder={'choose'} />
  </Box>
  <Box pl={1} width={'40%'}>
  <InputField  disable={true} isError={submitHit && blockThreeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockThreeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
  </Box>
  </Stack>
  
  <Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={4} >
  <Box width={'21.5%'}>
  <SelectField  disable={true} isError={''} onSelecting={handleSelect} Data={NO_OF_SEATS} sideLabel={'Hourly Overtime'} placeHolder={'choose'} />
  </Box>
  <Box width={'8.7%'}>
  <InputField  disable={true} isError={submitHit && blockOverTimeCost?.length < 1 } ErrNote={""} InChange={(e) => setBlockOverTimeCost(e.target.value)}   sideLabel={""} placeHolder={"Cost (S$)"} />
  </Box>
  
  </Stack>
  </>
)}



{/* TRAVELERS SPLIT */}



<Box >
<Stack alignItems={'center'} direction={'row'}>
<Typography >Travelers Split</Typography>
</Stack>
</Box>

<Stack width={'100%'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={'15.5%'}>
<InputFieldUpdate Type={'number'} Value={selectedAdults} isError={submitHit && selectedAdults?.length < 1 } ErrNote={""} InChange={(e) => setSelectedAdults(e.target.value)}   sideLabel={"Maximum no of Adults"} placeHolder={"Maximum no of Adults"} />
    </Box>
    <Box width={isMatch ? "43%" : "15.5%"} >
    <InputFieldUpdate Type={'number'} Value={selectedPets} isError={submitHit && selectedPets?.length < 1 } ErrNote={""} InChange={(e) => setSelectedPets(e.target.value)}   sideLabel={"Maximum no of pets"} placeHolder={"Maximum no of pets"} />
    </Box>

</Stack>

{/*TRIP INSURANCE DETAILS */}

{/* un comment code below inorder to show trip insurance part */}


{/* <Box >
<Stack alignItems={'center'} direction={'row'}>
<FormControlLabel onChange={(e) => handleInsuranchShowChange(e.target.checked,0)}  control={<Checkbox checked={isShowInsurance}  color="success" />} label="Trip Insurance" />
</Stack>
</Box>

{insuranceData?.map((eachItem,index)=>(

<>

<Stack direction={isMatch ? 'column':'row'} spacing={3} >

<Box width={isMatch?'100%':'22.5%'}>

<Stack>
         <FormControl required component="fieldset" sx={{ width: "100%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Cot Name</FormLabel>
    
         </Stack>
        
         <OutlinedInput
            disabled={true}
         value={eachItem?.cotName}
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder="select"
   
   />
       </FormControl>
         </Stack>
    </Box>

    <Box width={isMatch ? "100%" : "8%"} >

    <InputFieldUpdate Type={'number'} Value={eachItem?.cost}  isError={submitHit && selectedPets?.length < 1 } ErrNote={""} InChange={(e) => handleCostChange(e.target.value,index)}   sideLabel={"Cost "} placeHolder={"Cost"} />

    </Box>


</Stack>

</>
))} */}





    <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
        
    <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>

</Stack>

</Box>
 </Box>
  )
}

export default EditMainFareForm
