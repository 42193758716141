import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddSpecialChargeForm from './AddSpecialChargeForm'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';
import { useNavigate } from 'react-router-dom';




function CreateSpecialCharge() {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Surcharge
      </Typography>,
 <Typography key="3" color="text.primary">
     Create Special Charge
      </Typography>,
  ];

  useTitle("Create Special Surch - Geolah");
  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Surcharges')
    setAny(isAany)
     },[])
  return (
    any ? (
  <Box>

<CreatePageHead title={'Create Special Charge'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
    <AddSpecialChargeForm/>  
    </Box>
    </Box>

  </Box> ): any === false ? (
    <RestrictedUsage/>
  ) : null
  )
}

export default CreateSpecialCharge
