import { Box } from '@mui/material'
import React, { useState } from 'react'
import TableNav from '../../Components/TableSearchExport/TableNav'
import DataTable from '../../Components/Table/DataTable'
import mockData4 from '../../FakeData/MOCK_DATA4.json'
import CreateTab from '../../Components/Forms/CreateTab'
import FormModal from '../../Components/Modal/FormModal'
import AddCancelReason from '../../Components/CancelReason/AddCancelReason'

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "left",
      sort: false,
    },
    {
      id: "reason",
      label: "Reason",
      minWidth: 170,
      align: "left",
      sort: false,
    },

    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: false,
      title: {
        onTrue: "Active",
        onFalse: "InActive",
      },
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ];  




function CancelReason() {
const [rows, setRows] = useState(mockData4);
const [order, setOrder] = useState("ASC");
const [modalStatus, setModalStatus] = useState(false);
  
const columnSortingFn = (id) => {
    if (order === "ASC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id"
          ? a[id].toLowerCase() > b[id].toLowerCase()
            ? 1
            : -1
          : a[id] > b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...rows].sort((a, b) =>
        id !== "id" && id !== "ride_fare"
          ? a[id].toLowerCase() < b[id].toLowerCase()
            ? 1
            : -1
          : id === "date"
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : a[id] < b[id]
          ? 1
          : -1
      );
      setRows(sorted);
      setOrder("ASC");
    }
  }


  const onClick = () => {
    setModalStatus(true);
  };

  const onClose = () => {
    setModalStatus(false);
  };



  return (
    <Box>
      <TableNav title={"Cancel Reason"} />
      <CreateTab handleClick={onClick} title={"Add Cancel Reason"} />
      <FormModal
        handleclose={onClose}
        status={modalStatus}
        component={<AddCancelReason />}
      />
      <Box sx={{ width: "100%", height: "100%" }} pt={1}>
        <DataTable
          columns={columns}
          tableData={rows}
          columnSorting={columnSortingFn}
        />
      </Box>
    </Box>
  )
}

export default CancelReason
