import { FormControl, FormLabel, OutlinedInput } from "@mui/material";

function TextField({sideLabel,placeHolder}) {
  return (
    <FormControl
    required
    
    component="fieldset"
    sx={{ width: "100%"}}
    variant="standard"
  >
      
    <FormLabel component="legend">{sideLabel}</FormLabel>
    <OutlinedInput sx={{borderRadius:'12px',height:'100px'}} fullWidth placeholder={placeHolder} />
    {/* <MyFormHelperText />   use when a helper text is required */}
  </FormControl>
  )
}

export default TextField
