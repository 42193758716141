import React, { useEffect, useState } from 'react'
import { useTitle } from '../../../Hooks/UseTitle';
import { useDispatch, useSelector } from 'react-redux';
import { insertNavigationDataAction } from '../../Redux/Actions/NavigationActions';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCalls';
import { Box } from '@mui/material';
import TableHead from '../../Components/Table/TableHead';
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import RestrictedUsage from '../../Components/RestrictedUsage/restrictedUsage';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import { toast } from 'react-toastify';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "min",
      label: "Min Fare",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "max",
      label: "Max Fare",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "methord",
        label: "Method",
        minWidth: 150,
        align: "left",
        sort: true,
      },
    {
      id: "platformFee",
      label: "Platform Fee",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
        id: "serviceFee",
        label: "Service Fee",
        minWidth: 170,
        align: "left",
        sort: true,
      },
    {
      id: "driverInsuranceFee",
      label: "Driver Insurance Fee",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    
    {
      id: "actions",
      label: "Actions",
      minWidth: 170,
      align: "left",
      sort: false,
    },
  ]; 

function ConvenienceFee() {
    useTitle("Convenience Fee - Geolah");

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()

    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const [selectedItems, setSelectedItems] = useState([]);
    const [any,setAny] = useState(true)
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const [totalRows,setTotalRows] = useState(0);

    const navigate = useNavigate()
    const dispatch = useDispatch()
   dispatch(insertNavigationDataAction('Convenience Fee'))


  useEffect(()=>{
    let isAany = checkAnyPrivilage('Convenience Fee')
    setAny(isAany)
   
    let isWrite = checkPrivilage('Convenience Fee','CREATE')
    setWrite(isWrite)
   let isEdit = checkPrivilage('Convenience Fee','UPDATE')
     setEdit(isEdit)
   
     let isDelete = checkPrivilage('Convenience Fee','DELETE')
     setDeletePer(isDelete)
        
     },[])

 

// api call
useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/trip-service/v1/admin/convenience-fee/get-all/${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
  
      const feeData = data?.result
      setTotalRows(feeData?.length)
     const filteredData =  feeData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{

      let items = {
            _id:eachItem._id,
            id:index+1+currentOffset,
            min:eachItem?.StartCharge,
            max:eachItem?.EndCharge,
            platformFee:eachItem?.PlatformCharge,
            serviceFee:eachItem?.ServiceCharge,
            driverInsuranceFee:eachItem?.DriverInsuranceCharge,
            methord:eachItem?.PaymentMode
            
           }
  
           return items;
     })
  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID,currentPage])


  useEffect(()=>{
    setRows(
      tableData?.filter((item) =>
      item?.min?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.max?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.platformFee?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.serviceFee?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.driverInsuranceFee?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item?.methord?.toLowerCase().includes(searchInput.toLowerCase())
        ))
     },[searchInput])




// update service status

const handleStatusChange =(id, newData)=>{
    //  console.log(`Switch with id ${id} changed. New data:`, newData);
    //  const paramsData = {valueServiceId:id,status:newData?.status}
    //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
  }




// delete status  
const handleServiceDelete =(id, newData)=>{
// console.log(`Delete with id ${id}. New data:`, newData);
setDeleteStatus(true)
const paramsData ={id,name:newData?.rideService}
setDeleteData(paramsData)

}

const onDeleteYes =(yesData)=>{
ApiCall("PATCH", `/trip-service/v1/admin/convenience-fee/delete/${yesData?.id}`,null).then((data)=>{
  if(data?.status === 'success'){
    setSuccess(data) 
    setDeleteStatus(false)
    toast.success("Successfully deleted.");
   }else{
    toast.error("Something went wrong.!");
   }
// setDeleteStatus(false)
})
} 


const onCloseNotification = ()=>{
setDeleteStatus(false)
}

const handleServiceEdit =(id,newData)=>{
if(id){
  navigate(`/edit-convenience/${id}`)
}
}

// handle search change

const handleChangeInSearch = (value)=>{
 setSearchInput(value)
 console.log(value , "-@@@@@@@@@@@@@@@@@@@-----------------COCOCOC")
}



const handleColumnFilter = (items, searchText) => {

console.log(items,"<---trhe itemssss")



setSelectedItems(items); // Update selected filter items

// If no items are selected and no search text, show all rows
if (items.length === 0 && searchText === "") {
  setRows(tableData);
  return;
}



// Filter the rows based on the selected items and search text
const filteredRows = tableData?.filter((row) => {
  // Check if any of the selected items are present in the row's features
  const featuresMatch = items.every(
    (item) => row.features && row.features.includes(item)
  );



  // Check if the row matches any of the selected vehicleCategory items
  const vehicleCategoryMatch = items.includes(row.vehicleCatogery);

  // Check if the row matches all of the selected vehicleType items
  const vehicleTypeMatch = items.every(
    (item) => row.vehicleType && row.vehicleType.includes(item)
  );



  // Check if the row contains the search text in any of its values
  const searchTextMatch = Object.values(row).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  // Return true if any of the filters match the row, otherwise return false
  return (
    (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
    searchTextMatch
  );
});

setRows(filteredRows);
};


const handleFilterBar = (status)=>{
setIsFilterOn(status)
}


  const columnSortingFn = (id) => {
      if (order === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id"
            ? a[id].toLowerCase() > b[id].toLowerCase()
              ? 1
              : -1
            : a[id] > b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("DSC");
      }
  
      if (order === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          id !== "id" && id !== "ride_fare"
            ? a[id].toLowerCase() < b[id].toLowerCase()
              ? 1
              : -1
            : id === "date"
            ? new Date(a.date).getTime() - new Date(b.date).getTime()
            : a[id] < b[id]
            ? 1
            : -1
        );
        setRows(sorted);
        setOrder("ASC");
      }
    };

    const handlePageChange=(no,rowsCount)=>{
      setCurrentPage(no)
      setRowsPerPage(rowsCount)
      
    }



  return (
    any === true ?
    (<Box>
    <TableHead title={'Convenience Fee'}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box sx={{ width: "100%", height: "100%" }} pt={1}>
    <NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
          <DataTable
              handlingPage={handlePageChange}
              totalCount={totalRows}
          DeletePermission={deletePer}
          ButtonPermission={write}
          EditPermission={edit}
           isFilterOn={isFilterOn}
           openFilter={handleFilterBar}
            columns={columns}
            tableData={rows}
            columnSorting={columnSortingFn}
            ButtonPath={'/create-convenience-fee'}
            ButtonLabel={'Add Fee'}
            handleSwitchChange={handleStatusChange}
            handleDelete={handleServiceDelete}
            handleEdit={handleServiceEdit}
            columnFilter={handleColumnFilter}
            searchInput={handleChangeInSearch}
            filterComponent={''}
          />
        </Box>
    
    </Box>
    </Box>): any === false ? (
      <RestrictedUsage/>
    ) : null
  )
}

export default ConvenienceFee

