import React from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead'
import { Box, Typography } from '@mui/material';

import { useTitle } from '../../../../Hooks/UseTitle';
import FleetProgressionDriver from './FleetProgressionDriver';


const breadcrumbs = [
    <Typography  key="3" color="text.primary">
      Fleet
      </Typography>,
 <Typography key="3" color="text.primary">
     Add Driver
      </Typography>,
  ];


function CreateDriverFleet() {
  useTitle("Add Driver - Geolah");
  return (
   <Box>
    <CreatePageHead title={'Add Driver'} BreadList={breadcrumbs}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <Box  sx={{ width: "100%", height: "100%"}} p={4}>
  <FleetProgressionDriver/>
    </Box>
    </Box>
   </Box>
  )
}

export default CreateDriverFleet