import { Box, Button, Stack, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputFieldUpdate from '../../../Components/Forms/InputFieldUpdate'
import SelectFieldUpdate from '../../../Components/Forms/SelectFieldUpdate'
import DatePickerComponent from '../../../Components/Forms/DatePickerComponent'
import FormSubmitButtons from '../../../Components/Forms/FormSubmitButtons'
import SnackBars from '../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../Components/Forms/ErrorNotify'
import { couponType,DiscountTypeData, USERS_TYPE, } from '../../../FakeData/FakeData'
import TimePickers from '../../../Components/Forms/TimePickers';
import ApiCall from '../../../../Helper/ApiCalls'
import UserList from '../CreateVoucher/UserList'
import UserListEdit from './UserListEdit'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { checkPrivilage } from '../../../../Sessions/Permisssions'
import { useSelector } from 'react-redux'
import TextAreaUpdate from '../../../Components/Forms/TextAreaUpdate'


function EditVoucherForm() {

 //STATES AND VARIABLES   

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });
    
      const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));

      const { id:voucherID } = useParams();
 const [submitHit,setSubmitHit] = useState(false)
  const [onApiSuccess , setOnApiSuccess] = useState('')
  const [onApiFailed , setOnApiFailed] = useState('')
  const [onSuccess, setOnSuccess] = useState(false)
  const [onFailed , setOnFailed] = useState(false)

  const [VoucherName , setVoucherName] = useState('')
  const [discountType , setDiscountType] = useState('')
  const {currentCountrydata:CountryID} = useSelector((state) => state.country)
  const [selectedUserType , setSelectedUserType] = useState('')
  const [selectedValue , setSelectedValue] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedLimit , setSelectedLimit] = useState('')
  const [selectedLimitPerPerson,setSelectedLimitPerPerson] = useState('')
  const [expiryDate , setExpiryDate] = useState('')
  const [selectedCoupon ,setSelectedCoupon] = useState('')
  const [selectedCouponID ,setSelectedCouponID] = useState('')
  const [description,setDescription] = useState('')
  const [code,setCode] = useState('')
  const [startDate , setStartDate] = useState('')
  const [endDate , setEndDate] = useState('')
  const [startTime , setStartTime] = useState('')
  const [endTime , setEndTime] = useState('')
  const [selectedUserIDs , setSelectedUserIds] = useState([])
  const [discountName,setDiscountName]=useState('')
  const [currentCoupon , setCurrentCoupon] = useState('')
  const [currentUserType,setCurrentUserType] = useState('')

  const [edit,setEdit] = useState()
  

  useEffect(()=>{
 
    
    let isEdit = checkPrivilage('Vouchers','UPDATE')
    setEdit(isEdit)
    

     },[])


// RENDERS AND API CALLS 


useEffect(()=>{

//     ApiCall("GET", "/trip-service/v1/admin/value-service/vehicle/type", null,).then((data)=>{
//         if(data.result){
      
//         }


//  })


},[])








  //FUNCTIONS
  
  const handleSelect = (label,data)=>{

    if(label === 'Discount Type'){

      setDiscountType(data?.id)
        
    }else if(label === 'Coupon Type'){
      setSelectedCoupon(data?.id)
    }else if(label === 'User Type'){
      setSelectedUserType(data?.id)
    }
          

 

  }


  const handleTimeChange = (label,data)=>{

    if(label === 'Start Time'){

      setStartTime(data)
  
    }else if(label === 'End Time'){
   
      setEndTime(data)

    }
  
  
  }

  const handleSelectedUsers = (users)=>{
   
    setSelectedUserIds(users)

  }

  const handleDateSelection =(date,label)=>{
    if(label === 'Start Date'){
      setStartDate(date)
    }else if(label === 'End Date'){
      setEndDate(date)
    }
   
  

   }




   useEffect(()=>{

    ApiCall("GET", `/trip-service/v1/admin/coupon/byId/?couponId=${voucherID}&&countryId=${CountryID}`, null,).then((data)=>{
      if(data.result){
          const rowData = data?.result[0]
          const formattedStartDate = dayjs(rowData?.FromDateTime).format('YYYY-MM-DD');
          const formattedEndDate = dayjs(rowData?.ToDateTime).format('YYYY-MM-DD');
          setCode(rowData?.Code)
          setVoucherName(rowData?.Name)
          setSelectedValue(rowData?.Value)
          setSelectedLimit(rowData?.UsageLimit)
          setSelectedLimitPerPerson(rowData?.UsePerPerson)
          setDescription(rowData?.Description)
          
          setSelectedCoupon(rowData?.CouponType)
          setStartTime(rowData?.FromTime)
          setEndTime(rowData?.ToTime)
          setStartDate(formattedStartDate)
          setEndDate(formattedEndDate)
          setDiscountName(rowData?.DiscountType=== 'amount' ? 'Amount':'Percentage')
          setDiscountType(rowData?.DiscountType)
          setCurrentUserType(rowData?.UserTypeID===1 ? 'User' : 'Driver')
          setSelectedUserType(rowData?.UserTypeID)
          setCurrentCoupon(rowData?.CouponType === 'TIME' ? 'Time' : 'Users')
          setSelectedUserIds(rowData?.UserIDS)
           console.log(rowData,"<---rowDataaaiiiiiiaaaaa")
      

        
      }
   
  })


   },[])




   const navigate  = useNavigate()

   const handleDiscard =()=>{
     navigate(-1)
   }


   const handleFormSubmit =()=>{
    setSubmitHit(true)
    
    let sendData = {
      code:code,
      couponName:VoucherName,
      description:description,
      countryId:CountryID,
      startTime: selectedCoupon === 'TIME' ? startTime : null,
      endTime:  selectedCoupon === 'TIME' ? endTime : null,
      userIds:selectedUserIDs[0] ? selectedUserIDs: null,
      usersTypeId:selectedUserType,
      value:parseFloat(selectedValue),
      couponType:selectedCoupon,
      discountType:discountType,
      usageLimit: parseInt(selectedLimit),
      usePerPerson: parseInt(selectedLimitPerPerson),
      startDate:startDate,
      endDate:endDate,
      couponId:voucherID, 
}


 // Removing the null fields

 for (const key in sendData) {
  if (sendData.hasOwnProperty(key) && sendData[key] === null) {
      delete sendData[key];
  }
}


    console.log(sendData,"<--send datatatatatatat")


    ApiCall("PUT", "/trip-service/v1/admin/coupon/edit",sendData,).then((data)=>{
        if(data.result === 'success'){
      
          setOnSuccess((i) => !i )
          setOnApiSuccess("Succesfully Updated")
          
    
        }
    })
    .catch((error) => {
       setOnFailed((i) => !i)
      
        if(error.message){
          setOnApiFailed(error.message)
          console.log(error , "<----errrrrrr")
        }else{
          setOnApiFailed("Something went wrong.!")
        }
    
    
    });


  }

  function validateCoupon(inputString) {
    // Check if the string length is between 4 and 7 characters
    if (inputString?.length < 4 || inputString?.length > 7) {
      return "Invalid length. The code length should be between 4 and 7 characters.";
    }
  
    // Check if all characters are uppercase letters and digits
    if (!/^[A-Z0-9]+$/.test(inputString)) {
      return "Invalid characters. The code should contain only uppercase letters and digits.";
    }
  
    // Count the number of digits in the string
    let digitCount = 0;
    for (let i = 0; i < inputString?.length; i++) {
      if (/[0-9]/.test(inputString[i])) {
        digitCount++;
      }
    }
  
    // Check if the number of digits is at most 3
    if (digitCount > 3) {
      return "Too many digits. The code should contain at most 3 digits.";
    }
  
    // If all conditions are met, return true
    return "success";
  }





  return (
    <Box>
    <Stack spacing={3}>
{/* FIRST ROW */}

        <Stack direction={isMatch ? 'column':'row'} spacing={2} >
        <InputFieldUpdate Value={VoucherName} sideLabel={"Voucher Name"} placeHolder={"value"} InChange={(e) => setVoucherName(e.target.value)} />
      
        <SelectFieldUpdate
        currentSelect={discountName}
        onSelecting={handleSelect}
              sideLabel={"Discount Type"}
              placeHolder={"select"}
              Data={DiscountTypeData}

            />
           
   
        <InputFieldUpdate Value={selectedValue} sideLabel={"value"} placeHolder={"value"} InChange={(e) => setSelectedValue(e.target.value)} />
        </Stack>



{/* SECOND ROW */}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >
        <Box width={isMatch ? '100%':'33%'}>
        <InputFieldUpdate Note={validateCoupon(code) === 'success' ? null : code?.length > 0 && validateCoupon(code)} sideLabel={"Code"} Value={code} sideLabel={"Code"} placeHolder={"Code"} InChange={(e) => setCode(e.target.value)} />
           </Box>
        <Box width={isMatch ? '100%':'33%'}>
        <SelectFieldUpdate
        currentSelect={currentCoupon}
        onSelecting={handleSelect}
        sideLabel={"Coupon Type"}
          placeHolder={"select"}
          Data={couponType}
          />
           </Box>
        <Box width={isMatch ? '100%':'33%'}>
        <SelectFieldUpdate
        currentSelect={currentUserType}
        onSelecting={handleSelect}
              sideLabel={"User Type"}
              placeHolder={"select"}
              Data={USERS_TYPE}

            />
           </Box>


  
        </Stack>


{/* THIRD ROW*/}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >

        <Box width={isMatch ? '100%':'32.5%'}>
        <InputFieldUpdate Type={'number'} Value={selectedLimit} sideLabel={"Usage Limit"} placeHolder={"Usage Limit"} InChange={(e) => setSelectedLimit(e.target.value)} />
             </Box>
             <Box width={isMatch ? '100%':'32.5%'}>
        <InputFieldUpdate  Type={'number'} Value={selectedLimitPerPerson} sideLabel={"Usage Limit /Person"} placeHolder={"Usage Limit /Person"} InChange={(e) => setSelectedLimitPerPerson(e.target.value)} />
             </Box>

       
        </Stack>

{/* DESCRIPTION SECTION  */}

<Box width={'100%'}>
        <TextAreaUpdate Value={description} sideLabel={"Description"} placeHolder={"Description"} InChange={(e) => setDescription(e.target.value)} />
           </Box>


{/* DATE ROW */}

        <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >
        <Box width={isMatch ? '100%':'32.5%'}>
        <DatePickerComponent initialValue={startDate}  handleSelection={handleDateSelection} sideLabel={'Start Date'}/>
         </Box>
         <Box width={isMatch ? '100%':'32.5%'}>
        <DatePickerComponent initialValue={endDate}  handleSelection={handleDateSelection} sideLabel={'End Date'}/>
         </Box>
         </Stack>

{/* CONDITIONAL RENDER */}

{selectedCoupon === 'TIME' ? (
     <Stack alignItems={"center"} direction={isMatch ? 'column':'row'} spacing={3} >
     <Box width={'32.5%'}>
     <TimePickers initialValue={startTime} TimeChange={handleTimeChange} sideLabel={"Start Time"} />
      </Box>
      <Box width={'32.5%'}>
      <TimePickers initialValue={endTime} TimeChange={handleTimeChange} sideLabel={"End Time"}/>
      </Box>
     </Stack>
): selectedCoupon === 'USERS' ? (
  <UserListEdit currentUserIds={selectedUserIDs} selectedUsers={handleSelectedUsers} UserType={selectedUserType} />
):null}
   


      
   <Stack alignContent={'center'} spacing={1} direction={'row'}>
   <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleFormSubmit} />
   <Button onClick={handleDiscard} sx={{
    color:'white',
    backgroundColor:'red',
    width: "114px",
    height: "40px",
    borderRadius: "8px",
    '&:hover': {
    
      backgroundColor:"#B30000"
    },
    }}>Discard</Button>
   </Stack>

        <SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
    <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />
    </Stack>

  </Box>
  )
}

export default EditVoucherForm
