import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTable from '../../../../../Components/Table/DataTable'
import RideFilter from '../../../../RideServiceManagement/RideFilter'
import { useTitle } from '../../../../../../Hooks/UseTitle';
import { useNavigate, useParams } from 'react-router-dom';
import ApiCall from '../../../../../../Helper/ApiCalls';
import { useSelector } from 'react-redux';
import { checkPrivilage } from '../../../../../../Sessions/Permisssions';

const columns = [
  {
    id: "id",
    label: "No",
    minWidth: 100,
    sort: true,
  },

  {
    id: "rideCategory",
    label: "Ride Category",
    minWidth: 150,
    align: "left",
    sort: true,
  },
  {
      id: "rideService",
      label: "Ride Service",
      minWidth: 170,
      align: "left",
      sort: true,
    },
  {
    id: "seats",
    label: "Seats",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "vehicleType",
    label: "Vehicle Type",
    minWidth: 170,
    align: "left",
    sort: true,
  },
  {
    id: "vehicleCategory",
    label: "Vehicle Category",
    minWidth: 200,
    align: "left",
    sort: true,
  },




  {
    id: "status",
    label: "Activate",
    minWidth: 170,
    align: "left",
    sort: false,
    title: {
      onTrue: true,
      onFalse: false,
    },
  },

]






function Service() {

    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [order, setOrder] = useState("ASC");
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const { id:driverId } = useParams(); 
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()
    const [totalRows,setTotalRows] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)

    useEffect(()=>{
      let isWrite = checkPrivilage('Driver Details','CREATE')
      setWrite(isWrite)
     let isEdit = checkPrivilage('Driver Details','UPDATE')
       setEdit(isEdit)
     
       let isDelete = checkPrivilage('Driver Details','DELETE')
       setDeletePer(isDelete)
       },[])
  
    const navigate = useNavigate()


       // api call
     useEffect(()=>{
      const currentLimit = rowsPerPage
      const currentOffset = (currentPage-1) * rowsPerPage
        ApiCall("GET", `/trip-service/v1/admin/driver/ride/services/?driverId=${driverId}`, null, "uui").then((data)=>{
      if(data?.result){
      const rideData = data?.result
      setTotalRows(rideData?.RideCategoryIDS?.length)
      const Seats = rideData?.Seats
      const VehicleCat =rideData?.VehicleCategory
      const VehicleType =rideData?.VehicleTypeID?.Type
      const vehicleId = rideData?._id
      const filteredData =  rideData?.RideCategoryIDS?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{

      let items = {
            _id:eachItem?.ID?._id,
            id:index+1,
            rideCategory:eachItem?.ID?.Name,
            rideService:eachItem?.ID?.valueServices || "N/A",
            seats:Seats,
            vehicleId,
            vehicleType: VehicleType,
            vehicleCategory: VehicleCat,
            status:eachItem?.isActive
           }
  
           return items;
     })
  

    
     setRows(filteredData)
     setTableData(filteredData);
   
          } })
  },[success,currentPage])

    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.rideCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.rideService?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.seats?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleType?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.vehicleCategory?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.status?.toString()?.toLowerCase().includes(searchInput.toLowerCase())
            ))
         },[searchInput])



         const handleStatusChange =(id, newData)=>{
      
          console.log(`Switch with id ${id} changed. New data:`, newData);
          const paramsData = {rideCategoryIDs:[{ID:id,isActive:newData?.status}],vehicleDetailsId:newData?.vehicleId}
          ApiCall("PATCH", "/trip-service/v1/admin/vehicle/update/service-status",paramsData,'BODY').then((data)=>{setSuccess(data)})
       }
  
  
  
  
  // delete status  
  const handleServiceDelete =(id, newData)=>{
    // console.log(`Delete with id ${id}. New data:`, newData);
    setDeleteStatus(true)
    const paramsData ={id,name:newData?.rideService}
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
//    ApiCall("PATCH", `/trip-service/v1/admin/value-service/delete/${yesData?.id}`,null).then((data)=>{
//     setSuccess(data) 
//     // setDeleteStatus(false)
//   })
  } 
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/wallet-details/${id}`)
    }
   
  
  }
  
  // handle search change
  
  const handleChangeInSearch = (value)=>{
     setSearchInput(value)

  }


    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
        // If no items are selected and no search text, show all rows
        if (items.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
            // Filter the rows based on the selected items and search text
        const filteredRows = tableData?.filter((row) => {
           // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }





    const columnSortingFn = (id) => {


        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };

      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      }



  return (
<Box borderRadius={'8px'} marginTop={2}  bgcolor='white' width='1200px' height='100%' >
<DataTable
    handlingPage={handlePageChange}
    totalCount={totalRows}
    DeletePermission={deletePer}
    ButtonPermission={write}
    EditPermission={edit} 
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={  <RideFilter CloseModal={handleFilterBar} FilterColumn={handleColumnFilter} />}
      />
</Box>
  )
}

export default Service