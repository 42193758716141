import { FormControl, FormLabel, OutlinedInput, Stack, Typography } from "@mui/material";


const handleKeyDown = (event) => {
  // Prevent digits (0-9) from being entered
  if (/^\d+$/.test(event.key)) {
    event.preventDefault();
  }
};

function InputField({sideLabel,placeHolder,InChange,isError,ErrNote,disable,Type,Note,isPercentage}) {
  return (
    <FormControl
      required
      component="fieldset"     
      sx={{ width: "100%"}}
      variant="standard"
    >
        <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
       { <Typography color={'red'}>*</Typography>}
        </Stack>
     
        <OutlinedInput
  onKeyDown={Type === 'text' ? handleKeyDown : null}
  type={Type}
  disabled={disable}
  error={isError}
  helperText={isError ? ErrNote : null}
  onChange={(e) => InChange(e)}
  onInput={(e) => {
    // Check if the input type is 'number' and isPercentage is true
    if (Type === "number" && isPercentage) {
      // Parse the value as a number
      const numericValue = parseFloat(e.target.value);
      
      // Check if the value is greater than 100
      if (numericValue > 100) {
        // If greater than 100, set the value to 100
        e.target.value = "100";
      }
    } else if (Type === "number") {
      // If type is 'number' (without percentage restriction),
      // remove non-numeric characters and the letter 'e'
      e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/e/gi, '');
    }
    
    // For other types, allow all characters
    InChange(e);
  }}
  sx={{
    borderRadius: '4px',
    height: '46px',
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  }}
  fullWidth
  placeholder={placeHolder}
/>
      {/* <MyFormHelperText />   use when a helper text is required */}
      {<Typography variant="caption" color={'red'}>{Note}</Typography>}
    </FormControl>
  );
}

export default InputField;
