import { Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormNav from '../../../../Components/Forms/FormNav'
import InputField from '../../../../Components/Forms/InputField'
import ApiCall from '../../../../../Helper/ApiCalls'
import { useParams } from 'react-router-dom'
import SnackBars from '../../../../Components/Forms/SnackBars'
import ErrorNotify from '../../../../Components/Forms/ErrorNotify'
import GoogleMapss from '../../../../Components/LocationSearch'
import GoogleMapr from '../../../../../components/locationSearchInput'
import GoogleMapSearch from '../../../../../components/locationSearchInput'
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate'

function CreatePopularPlaceFormUpdate({confirmSubmit,CancelSubmit,zoneIndex,currentData}) {
  console.log(currentData,",--INSIDE MODALLLLLL")
  const zoneLabels = ["Zone A", "Zone B", "Zone C", "Zone D"]
    const [placeName , setPlaceName] = useState('')
    const [placeCat , setPlaceCat] = useState('')
    const [placeId , setPlaceId] = useState('')
    const [address , setAddesss] = useState('')
    const [zoneName , setZoneName] = useState(zoneLabels[zoneIndex])
    const [popularDestId , setPopularDestId] = useState("")
    const { zoneId } = useParams();


    const [onApiSuccess , setOnApiSuccess] = useState('')
    const [onApiFailed , setOnApiFailed] = useState('')
    const [submitHit,setSubmitHit] = useState(false)
    const [onSuccess , setOnSuccess] = useState(false)
    const [onFailed , setOnFailed] = useState(false)

    useEffect(()=>{
        if(currentData){
          
            setPlaceName(currentData?.PlaceName)
            setPlaceCat(currentData?.PlaceCategory)
            setPlaceId(currentData?.PlaceID)
            setAddesss(currentData?.Address)
            setPopularDestId(currentData?._id)
        }

    },[currentData])

 


    const handleSubmit=()=>{
        setSubmitHit(true)

        let sendData ={
            zoneId:zoneId,
            address:address,
            placeId: placeId,
            placeName: placeName,
            placeCategory: placeCat,
            popularDestinationId:popularDestId
        }

        ApiCall("PUT", "/trip-service/v1/admin/zone/update/popular-destination", sendData).then((data)=>{
            if(data.result === 'success'){
              
              setOnSuccess((i) => !i )
              setOnApiSuccess("Succesfully Updated")
              
        
            }
        })
        .catch((error) => {
           setOnFailed((i) => !i)
          
            if(error.message){
              setOnApiFailed(error.message)
              console.log(error , "<----errrrrrr")
            }else{
              setOnApiFailed("Something went wrong.!")
            }
        
        
        });
    }

   const handleLocationValues = (values)=>{
 console.log(values , "<--- the valuessssssss--------")
 if(values?.place_id){
 setPlaceId(values?.place_id)
 setAddesss(values?.description)
 }

   }




  return (
    <Box>
    <Stack spacing={4}>

    <FormNav warning={true} Description={'Add your place.'} title={'Popular Place'}/>
    <Stack spacing={2}>
    <Box width={'100%'}>
        <InputFieldUpdate Value={placeName} isError={false} ErrNote={""} InChange={(e) => setPlaceName(e.target.value)}   sideLabel={"Place Name"} placeHolder={"Place Name"} />
        </Box>
        <Box width={"100%"} >
        <InputFieldUpdate Value={placeCat} isError={false} ErrNote={""} InChange={(e) => setPlaceCat(e.target.value)}  sideLabel={"Place Category"} placeHolder={"Place Category"} />
        </Box>
        <Box width={"100%"} >
        <InputFieldUpdate notRequired={true} disable={true} Value={zoneName} isError={false} ErrNote={""} InChange={(e) => setZoneName(e.target.value)}  sideLabel={"Zone"} placeHolder={"Zone"} />
        </Box>
        <GoogleMapSearch currentValue={address} handlePlaces={handleLocationValues}/>

        <Stack alignItems={'center'} direction={'row'} spacing={2}>
       <Box pt={2} >

<Button  sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} onClick={handleSubmit}   variant="outlined">
        Update
      </Button>
{/* <Button onClick={confirmSubmit}  sx={{textTransform:"none",backgroundColor:"#021640",color:"white",borderRadius:"6px",'&:hover': {color: 'black'}}} >Confirm</Button> */}
</Box> 
<Box pt={2} >
<Button onClick={CancelSubmit}  sx={{textTransform:"none",backgroundColor:"#e84118",color:'white',borderRadius:"6px",'&:hover': {color: 'black'}}} >Cancel</Button>
</Box> 
</Stack>

<SnackBars submitStatus={submitHit} successNote={onApiSuccess} Status={onSuccess}/>
        <ErrorNotify submitStatus={submitHit} Status={submitHit && onFailed} errorNote={onApiFailed} />

    </Stack>
    </Stack>
    </Box>
  )
}

export default CreatePopularPlaceFormUpdate