
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


import positiveReview from '../../../Assets/SideNav/positive-review 1.svg'
import bookingIcon from '../../../Assets/SideNav/booking 1.svg'
import walletIcon from '../../../Assets/SideNav/wallet 1.png'
import userIcon from '../../../Assets/SideNav/user (7) 1.svg'
import StearingWheel from '../../../Assets/SideNav/steering-wheel 2.svg'
import homeIcon from '../../../Assets/SideNav/home (2) 1.svg'
import settingsIcon from '../../../Assets/SideNav/setting (1) 1.svg'
import ticketIcon from '../../../Assets/SideNav/ticket 1.png' 
import serviceIcon from '../../../Assets/SideNav/customer 1.svg'
import trackingIcon from '../../../Assets/SideNav/mouse-clicker 1.svg'
import userGroupIcon from '../../../Assets/SideNav/group 1.svg'
import voucherIcon from '../../../Assets/SideNav/coupon 1.png'
import zoneIcon from "../../../Assets/SideNav/map 1.svg"
import fareIcon from "../../../Assets/SideNav/price-tag 1.svg"
import vehicleIcon from "../../../Assets/SideNav/car (3) 1.png"
import paymentIcon from "../../../Assets/SideNav/credit-card 1.png"
import arrowIcon from '../../../Assets/SideNav/up-arrow 1.svg'
import insuranceIcon from '../../../Assets/SideNav/life-insurance.png'
import addIcon from '../../../Assets/SideNav/megaphone.png'
import reportIcon from '../../../Assets/SideNav/Vector.svg'
import newServiceIcon from '../../../Assets/icons8-service-30.png'
import fleetIcon from '../../../Assets/SideNav/icons8-fleet-management-48.png'
import { checkAnyPrivilage } from '../../../Sessions/Permisssions';
 
export const SidevaLinks = [
   {
        name:"Dashboard",
        icon :<img src={homeIcon} alt='icon'/> ,
        path :'/dasboard'

    },
    {
      name: "Admin",
      icon: <img src={userIcon} alt='icon'/>,
      path: '/administration',
      expand:false,
      childrens: [
        {
          name: "Employee",
          icon: <img src={arrowIcon} alt="icon" />,
          path: '/administration',
        },
        {
          name: "Roles",
          icon: <img src={arrowIcon} alt="icon" />,
          path: '/role-management',
        },
      ],
      expanded: 0, // Add a new property to track expansion state
    },
    {
        name:"Zone",
        icon :<img src={zoneIcon} alt='icon'/> ,
        path :'/zone-management'

    },
    {
        name: "Services",
        icon: <img width={'24px'} height={'24px'} src={newServiceIcon} alt="icon"   />,
        path: '/administration',
        expand:false,
        childrens: [
          {
            name: "Ride Service",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/rideServiceManagement',
          },
          {
            name: "Ride Category",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/ridecategorymanagement',
          },
        ],
        expanded: 1, // Add a new property to track expansion state
      },


      {
        name: "Fare",
        icon: <img src={fareIcon} alt='icon'/>,
        path: '/administration',
        expand:false,
        childrens: [
          {
            name: "Basic Fare",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/basicfare',
          },
          {
            name: "Surcharges",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/surcharge',
          },
          {
            name: "Other Charges",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/other-charges',
          },
          {
            name: "Convenience Fee",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/convenience-fee',
          },
          {
            name: "Tip",
            icon: <img src={arrowIcon} alt="icon" />,
            path: '/tip',
          },
         
        ],
        expanded: 2, // Add a new property to track expansion state
      },
    {
        name:"App Ads",
        icon :<img width={'24px'} height={'24px'} src={addIcon} alt='icon'/> ,
        path :'/advertisement'

    },
    ,{
        name:'Ride',
        icon: <img src={bookingIcon} alt='icon'/>,
        path:'/ride',
    },
    {
        name:'Drivers',
        icon:<img src={StearingWheel} alt='icon'/> ,
        path:'/driver',
    },
  //   {
  //     name:'Fleet',
  //     expand:false,
  //     icon: <img width={'24px'} height={'24px'} src={fleetIcon} alt='icon'/> ,
  //     path:'/fleet',
  //     childrens: [
  //       {
  //         name: "Home",
  //         icon: <img src={arrowIcon} alt="icon" />,
  //         path: '/fleet-management',
  //       },
  //       {
  //         name: "Driver List",
  //         icon: <img src={arrowIcon} alt="icon" />,
  //         path: '/driver-list',
  //       },
  //       {
  //         name: "Vehicle List",
  //         icon: <img src={arrowIcon} alt="icon" />,
  //         path: '/vehicle-list',
  //       },
  //     ],
  //     expanded: 4, // Add a new property to track expansion state
  // },
    {
        name:'Users',
        icon:<img src={userGroupIcon} alt='icon'/> ,
        path:'/users',
    },
    ,{
        name:'Vehicles',
        icon: <img src={vehicleIcon} alt='reviw'/> ,
        path:'/vehicles',
    },
    {
        name:'Payments',
        icon: <img src={paymentIcon} alt='reviw'/>,
        path:'/payment',
    },

    {
        name:'Wallet',
        icon: <img src={walletIcon} alt='icon'/>  ,
        path:'/wallet',
    },
    {
      name:'Insurance',
      icon: <img width={'24px'} height={'24px'} src={insuranceIcon} alt='icon'/>  ,
      path:'/insurance',
  }
    
    ,{
        name:'Ticket',
        icon: <img src={ticketIcon} alt='icon'/> ,
        path:'/ticketing',
    }
    ,{
      name:'Reports',
      icon: <img src={reportIcon} alt='icon'/> ,
      path:'/reports',
  }

    ,{
        name:'Vouchers',
        icon: <img src={voucherIcon} alt='voucher'/> ,
        path:'/voucher',
    }
   
]
