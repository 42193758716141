import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreatePageHead from '../../../Components/CreatePage/CreatePageHead';
import EditBasicFareForm from './EditBasicFareForm';
import EditMainFareForm from './EditMainFareForm';
import ApiCall from '../../../../Helper/ApiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from '../../../../Hooks/UseTitle';
import { checkAnyPrivilage } from '../../../../Sessions/Permisssions';
import RestrictedUsage from '../../../Components/RestrictedUsage/restrictedUsage';







function UpdateBasicFare() {

  const navigate = useNavigate()
  const breadcrumbs = [
    <Typography onClick={() => navigate(-1)}  key="3" color="text.primary">
      Basic Fare
      </Typography>,
 <Typography key="3" color="text.primary">
      Edit Fare
      </Typography>,
  ];


  useTitle("Edit Basic Fare - Geolah");

  const [any,setAny] = useState()

  useEffect(()=>{
    let isAany = checkAnyPrivilage('Basic Fare')
    setAny(isAany)
     },[])
// STATES AND VARIBLES

const [basicFareData,setBasicFareData] = useState([])
const [topFormData,setTopFormData] = useState([])
const [splitData , setSplitData] = useState({})
const [catName , setCatName] =useState('')
const { id:categoryID } = useParams();
const [mainData , setMainData] = useState({})


//RENDERS AND API CALLS

useEffect(()=>{
    if(categoryID){

        ApiCall("GET", `/trip-service/v1/admin/ride-category/${categoryID}`,null,).then((data)=>{
            if(data.result){

              const catData = data?.result;
              setMainData(catData)
              console.log(catData,"cccccccccccccc")
              setSplitData(catData?.RideCategoryFareID?.PaymentSplitID)
              setCatName(catData?.Name)

                
            }
        })
        .catch((error) => {
            if(error.message){
              console.log(`Error -> ${error.message}`)
              }else{
                console.log('something went wrong [ridecategory - get]')
              }
          
          
          });




    }

},[categoryID])


  return (
any ? (
    <Box>
    <CreatePageHead title={'Edit Fare'} BreadList={breadcrumbs}/>
       <Stack spacing={2}>
       <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
        <Box  sx={{ width: "100%", height: "100%"}} p={4}>
        <EditBasicFareForm currentInfo={{...splitData,catName}} />
        </Box>
        </Box>
    
        <Box Box borderRadius={'8px'} marginTop={2} bgcolor='white' width='97%' minHeight='850px'>
        <Box  sx={{ width: "100%", height: "100%"}} p={4}>
      {/* <AddMainFareForm/> */}
      <EditMainFareForm currentInfo={mainData}/>
        </Box>
        </Box>
    
       </Stack>
    
    </Box>): any === false ? (
      <RestrictedUsage/>
    ) : null
  )
}

export default UpdateBasicFare
