import React, { useEffect, useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FormLabel, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

function TimePickers({sideLabel,TimeChange,initialValue}) {
  const [currentTime , setCurrentTime] = useState()
console.log(currentTime , "<---currentTime")

  useEffect(() => {
    const defaultDate = dayjs('2023-01-01'); // You can use any default date here
    const parsedTime = dayjs(`${defaultDate.format('YYYY-MM-DD')}T${initialValue}:00`);
    setCurrentTime(parsedTime);
  }, [initialValue]);


  const handleTimeChange = (newTime) => {

  
    const formattedTime = dayjs(newTime).format('HH:mm')
    TimeChange(sideLabel,formattedTime)

  };
  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction={'row'}>
        <FormLabel sx={{color:"black"}} required={false} >{sideLabel}</FormLabel>
        <Typography color={'red'}>*</Typography>
        </Stack>
    <DemoContainer components={['TimePicker']}>
      <TimePicker   sx={{
            width: '100%',
         
          }}
          onChange={(newTime) => handleTimeChange(newTime)} 
          value={initialValue ? currentTime:null}
          ampm={false}  />
    </DemoContainer>
  </LocalizationProvider>
  )
}

export default TimePickers
