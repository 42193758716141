import { Box, Grid, Stack, createTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react'
import ProgressionSubmit from '../Forms/ProgressionSubmit';
import InputField from '../Forms/InputField';
import { FilePond } from 'react-filepond';

function DriverBankDetails({onButtonClick}) {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd:855,
            md: 900,
            fmd:950,
            slg:1098,
            lg: 1200,
            sxl: 1430,
            xl: 1836,
          },
        },
      });
      const [files, setFiles] = useState([])
      
      const isXlMatch = useMediaQuery(theme?.breakpoints?.up("xl"));
  return (
    <Box>
      
    <Box overflow='auto' height={isXlMatch ? "25vh" : '28vh'}  >  
      <Stack spacing={3}>


      <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Bank Name"} placeHolder={"Bank Name"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"Branch"} placeHolder={"Branch"} />
          </Grid>
        </Grid>


        <Grid container>
          <Grid xs={5.75} item>
            <InputField sideLabel={"Account Number "} placeHolder={"Account Number"} />
          </Grid>
          <Grid xs={0.5} item />
          <Grid xs={5.75} item>
            <InputField sideLabel={"NRIC Number"} placeHolder={"NRIC Number"} />
          </Grid>
        </Grid>


        <Grid container>
          <Grid xs={12} item>
          <FilePond
        style={{ border: '1px dashed #999 !important' }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
        </Grid>
        </Grid>




      </Stack>
    </Box>
    <Box paddingTop={5} >
      <ProgressionSubmit Clicking={onButtonClick} toPage={"pagefour"} goBack={"pagethree"} />
    </Box>
    </Box>
  )
}

export default DriverBankDetails
