import { Box, Button, Chip, FormControl, FormLabel, InputAdornment, OutlinedInput, Stack, Typography, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../Components/Forms/SelectField';
import { COLORS, ENGINE_TYPE, NO_OF_SEATS, NO_OF_SEATS_CAT, OWNERSHIP, VEHICLE_CATEGORY } from '../../../../FakeData/FakeData';
import InputField from '../../../../Components/Forms/InputField';
import ImageSelectorUpdate from '../../../../Components/Forms/ImageSelectorUpdate';
import SaveAndVerify from '../../../../Components/Forms/SaveAndVerify';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../../RequestMethord';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import ApiCall from '../../../../../Helper/ApiCalls';
import DatePickerComponent from '../../../../Components/Forms/DatePickerComponent';
import SelectFieldUpdate from '../../../../Components/Forms/SelectFieldUpdate';
import InputFieldUpdate from '../../../../Components/Forms/InputFieldUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { insertRegDataAction } from "../../../../Redux/Actions/RegAction";
import NotifiProgress from '../../../../Components/Forms/NotifiProgress';
import ErrorNotify from '../../../../Components/Forms/ErrorNotify';
import FormSubmitButtons from '../../../../Components/Forms/FormSubmitButtons';
import ProgressionSubmit from '../../../../Components/Forms/ProgressionSubmit';
import { checkPrivilage } from '../../../../../Sessions/Permisssions';

function EditVehicleDetails({onButtonClick,onSectionComplete}) {
  const [onSuccess , setOnSuccess] = useState(false)
  const [onSuccessv , setOnSuccessv] = useState(false)
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 900,
        
          },
        },
      });


// STATES AND VARIABLE
const { driverId } = useParams(); 
const isMatch = useMediaQuery(theme?.breakpoints?.down("xs"));
const [submitHit,setSubmitHit] = useState(false)
const [submitHitv,setSubmitHitv] = useState(false)
const [currentDetails , setCurrentDetails] = useState({})
const [regNo,setRegNo] = useState('')
const [vehicleImageLink , setVehicleImageLink] = useState('')
const [selectedMaker , setSelectedMaker] = useState('')
const [selectedModel , setSelectedModel] = useState('')
const [selectedColor , setSelectedColor] = useState('')
const [selectedSeats , setSelectedSeats] = useState('')
const [frontImageFile , setFrontImageFile] = useState()
const [featureData , setFreatureData] = useState([])
const [selectedChip, setSelectedChip] = useState([]);
const [adOnFeatures , setAdOnFeatures] = useState([])
const [allCat , setAllCat] = useState([])
const [allTaxiComp , setAllTaxiComp] = useState([])
const [selectedIND , setSelectedIND] = useState('')
const [selectedEngine , setSelectedEngine] = useState('')
const [selectedCat , setSelectedCategory] = useState('')
const [selectedOwnerShip , setSelectedOwner ] = useState('')
const [phvDecalLink , setPhvDecalLink] = useState('')
const [ltaVehicleLink , setLtaVehicleLink] = useState('')
const [vehicleType , setVehicleType] = useState('')
const [vehicleBrands , setVehicleBrands] = useState([])
const [vehicleModel, setVehicleModel] = useState([{name:"select maker"}])
const [inspectionDate , setInspectionDate] = useState('')
const [selectedMakerId , setSelectedMakerId] = useState('')
const [selectedModelId , setSelectedModelId] = useState('')
const [selectedTaxiCompId , setSelectedTaxiCompId] = useState('')
const [vehicleFrontFile , setVehicleFrontFile] = useState({})
const [phvDecalFile , setphvDecalFile] = useState({})
const [ltaVehicleLogFile , setLtaVehicleLogFile] = useState({})
const [letterOfEmploymentFile , setLetterOfEmploymentFile] = useState({})
const [MdtScreenUploadFile , setMdtScreenUploadFile] = useState({})
const [onApiFailed , setOnApiFailed] = useState('')
const [onFailed , setOnFailed] = useState(false)
const [remarkDetails,setRemarkDetails] = useState('')
const [mDTlink,setMDTlink] = useState('')
const [letterLink , setLetterLink] = useState('')
const [selectedTaxi,setSelectedTaxi] = useState('')
const [changedData , setChangedData] = useState({})
const {currentCountrydata:CountryID} = useSelector((state) => state.country)
const [isVerified,setIsVerified] = useState(false)
const [VehicleTypeID , setVehicleTypeId] = useState('')
const { id:vehicleId } = useParams(); 

const [edit,setEdit] = useState()
  

useEffect(()=>{

  
  let isEdit = checkPrivilage('Vehicles','UPDATE')
  setEdit(isEdit)
  

   },[])





const dispatch = useDispatch()




// RENDERS AND API CALLS

useEffect(() => {

  const apiUrl = `${baseUrl}/trip-service/v1/admin/vehicle/information`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',

    },
  };

  ApiCall("GET", `/trip-service/v1/admin/vehicle/information/?driverVehicleId=${vehicleId}`, null).then((data)=>{
    if(data?.result){
      
      const Data = data?.result?.[0]
      setCurrentDetails(Data)

      console.log(Data,"<-----~|D|A|T|A")
 



 
} })

//  axios.get(apiUrl, config).then(response => {

//   if(response?.data?.result){
//     const personalData =response?.data?.result

   

 
//    setCurrentDetails(response?.data?.result)
//   }
  





// /trip-service/v1/app/vehicle/brand
//vehicle model and brand
const apiUrlTwo = `${baseUrl}/trip-service/v1/app/vehicle/brand`;  



axios.get(apiUrlTwo, config).then(response => {

  if(response?.data?.result){
    const brandData = response?.data?.result 
  const filtered = brandData?.map((eachItem)=>{
    let modelArray = eachItem?.VehicleBrandModelID

    if(modelArray?.some((obj) => obj?.VehicleTypeID === VehicleTypeID)){

    
        return {id:eachItem?._id,name:eachItem?.Name,modelDetail:eachItem?.VehicleBrandModelID}
    }
  })

  // setVehicleModel()

  setVehicleBrands(filtered.filter((each)=> each !== undefined))

  }
  
    })
    .catch(error => {
      console.error(error,"GET API ERROR");
    });


    // /trip-service/v1/driver/cab/company

    const apiUrlThree = `${baseUrl}/trip-service/v1/driver/cab/company`;  


    axios.get(apiUrlThree, config).then(response => {

      if(response?.data?.result){

      const taxiCompData =  response?.data?.result
      
      let filtereditems = taxiCompData?.map((items)=>{
        return {id:items?._id,name:items?.Name}
      })

        setAllTaxiComp(filtereditems)

    
      }
      
        })
        .catch(error => {
          console.error(error,"GET API ERROR");
        });



// /trip-service/v1/driver/ride/service
// get vehicle type api

// const apiUrlFour = `${baseUrl}/trip-service/v1/driver/ride/service`;  

// axios.get(apiUrlFour, config).then(response => {

//   if(response?.data?.result){

//     const vehicleTypeDetails = response?.data?.result?.VehicleTypeID

//     // setVehicleType(vehicleTypeDetails?.Type)



//   }
  
//     })
//     .catch(error => {
//       console.error(error,"GET API ERROR");
//     });


}, [driverId,onSuccess,onSuccessv,VehicleTypeID]);




useEffect(()=>{

  ApiCall("GET", "/trip-service/v1/admin/value-service/adOn/features", null,).then((data)=>{
    if(data.result){
        const rowData = data?.result
    
        const filteredData = rowData?.map((eachData)=>{
            return {name:eachData?.Type,id:eachData?._id}

        })

        setAdOnFeatures(filteredData)
    }})



    ApiCall("GET", `/trip-service/v1/admin/ride-category/?countryId=${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
        
        const catData = data?.result
        const filtered = catData?.map((eachitem)=>{
          return {id:eachitem?._id , name:eachitem?.Name}
        })

        setAllCat(filtered)

   
   } })

  },[])





useEffect(()=>{

  if(currentDetails){
    onSectionComplete(true,'personal')
  const FormatedDate = formatteDate(new Date(currentDetails?.InspectionDate))
  setVehicleType(currentDetails?.VehicleType?.[0]?.Type)
  setVehicleTypeId(currentDetails?.VehicleTypeID)
  setSelectedIND(FormatedDate)
  // setRemarkDetails(currentDetails?.Remarks || '') 
  setIsVerified(currentDetails?.IsVerified)
 setSelectedColor(currentDetails?.VehicleColor)
 setSelectedMaker(currentDetails?.VehicleBrand?.[0]?.Name)
 setSelectedMakerId(currentDetails?.VehicleBrandID)
 setSelectedModel(currentDetails?.VehicleBrandModel?.[0]?.Name)
 setSelectedModelId(currentDetails?.VehicleBrandModelID)
 setSelectedSeats(currentDetails?.Seats)
 setRegNo(currentDetails?.RegistrationNumber)
 dispatch(insertRegDataAction(currentDetails?.RegistrationNumber));
 setVehicleImageLink(currentDetails?.VehicleFrontUrl)
 setSelectedEngine(currentDetails?.VehicleEngineType)
 setSelectedCategory(currentDetails?.VehicleCategory)
 setSelectedOwner(currentDetails?.OwnerShip)
 setPhvDecalLink(currentDetails?.PhvDecalPath)
 setLtaVehicleLink(currentDetails?.LTAVehicleLog)
 setMDTlink(currentDetails?.MDTScreenImage)
 setLetterLink(currentDetails?.EmploymentLetterPath)
 setSelectedTaxi(currentDetails?.CabCompanyID?.Name)
 
 let chipData = currentDetails?.AdOnFeatures?.map((each)=>{
 return each?.Type
 })
 let featureData = currentDetails?.AdOnFeatures?.map((each)=>{
  return {id:each?._id , name:each?.Type}
 })
setFreatureData(featureData)
setSelectedChip(chipData)
const currentBrandDetails = vehicleBrands?.filter((eachItem) => eachItem?.name === selectedMaker )

const modelFil = currentBrandDetails?.[0]?.modelDetail?.map((eachBrand)=>{
  return {id:eachBrand?._id , name:eachBrand?.Name}
})

setVehicleModel(modelFil)






 
}


  function newFunction(FormatedDate) {
    setSelectedIND(FormatedDate);
  }
},[currentDetails])




//FUNCTIONS



const formatteDate = (issueDate)=>{
  let year = issueDate?.getFullYear();
  let month = (issueDate?.getMonth() + 1).toString().padStart(2, "0");
  let day = issueDate.getDate().toString().padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate
 }





const handleSelect = (label,data)=>{

  if(label === 'Maker'){
    const filtered = data?.modelDetail?.map((item)=>{
         return {id:item?._id ,name:item?.Name}
    })

    setVehicleModel(filtered)


    setSelectedMakerId(data?.id)
  }else if(label === 'Model'){
    setSelectedModelId(data?.id)
  }else if(label === 'Color'){
      setSelectedColor(data?.name)
  } else if(label === 'Number of Seats'){
    setSelectedSeats(data?.name)
  } else if(label === 'Vehicle Category'){
      setSelectedCategory(data?.name)
  } else if(label === 'Ownership'){
     setSelectedOwner(data?.name)
  } else if( label  === 'Choose your Taxi company'){
     setSelectedTaxiCompId(data?.id)
  } else if(label === 'Engine Type'){
       setSelectedEngine(data?.name)
  }

  

}

const handleImageFiles = (files)=>{
   setVehicleFrontFile(files)
}
const handleImageFilesTwo = (files)=>{
  setLetterOfEmploymentFile(files)
}
const handleImageFilesThree = (files)=>{
  setphvDecalFile(files)
}
const handleImageFilesFour = (files)=>{
  setLtaVehicleLogFile(files)
}
const handleImageFilesFive = (files)=>{
  setMdtScreenUploadFile(files)
}

  const handleDeleteChip = (chipName) => {
    setSelectedChip(
       selectedChip.filter((each) => each !== chipName)
    )
    setFreatureData(featureData.filter((each) => each?.name !== chipName ))
 };

 const handleDateSelection =(date)=>{
  setSelectedIND(date)
 }



 const handleAddChip = (chipName) => {
  if (!selectedChip.includes(chipName)) {
      setSelectedChip([...selectedChip, chipName?.name]);
      setFreatureData([...featureData, chipName])
    }
};


const handleSubmit = ()=>{
  setSubmitHit(true);
  const adOnIds = featureData.map(item => item.id);


  const formData = new FormData();
  formData.append('vehicleBrandId',selectedMakerId);
  formData.append('vehicleBrandModelID', selectedModelId);
  formData.append('seat',selectedSeats);
  formData.append('registrationNumber', regNo);
  formData.append('vehicleColor',selectedColor);
  formData.append('rideCatagory',selectedCat )
  formData.append('inspectionDate', selectedIND )
  formData.append('ownerShip',selectedOwnerShip )
  // formData.append('remarks', remarkDetails?.toString());
  adOnIds.forEach((feature, index) => {
    formData.append(`adOnSeatIds[${index}]`, feature);
  });


 selectedTaxiCompId?.length > 1 && formData.append('cabCompanyId',selectedTaxiCompId )
  formData.append('vehicleEngineType',selectedEngine )

  vehicleFrontFile && formData.append('VehicleFrontImage',vehicleFrontFile.file);
  letterOfEmploymentFile && formData.append('LetterOfEmployment',letterOfEmploymentFile.file);
  phvDecalFile.file && formData.append('PhvDecalImage',phvDecalFile.file);
  ltaVehicleLogFile && formData.append('LtaVehicleLog',ltaVehicleLogFile.file);
  MdtScreenUploadFile && formData.append('MdtScreenUpload',MdtScreenUploadFile.file);

  const apiUrl = `${baseUrl}/trip-service/v1/driver/update/vehicle/details`;  
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'multipart/form-data',

    },
  };

  axios.put(apiUrl, formData, config)
  .then(response => {

    if(response?.data?.status === 'success'){
      setOnSuccess(true)
    }
 
 
  })
  .catch(error => {
    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });



}


const handleVerify =()=>{
  setSubmitHitv(true);
  const apiUrl = `${baseUrl}/trip-service/v1/admin/driver/verify/vehicle/details`; 
  const TOKEN = localStorage.getItem("jwtToken");
  const config = {
    headers: {
      ['x-user-id']: driverId,
      'Authorization': `Bearer ${TOKEN}`,
      ['x-app-type']: 'AdminApp',
      'Content-Type': 'application/json', // Set the content type for JSON data
    },
  };

  let verifyData={driverId:driverId}

  axios.put(apiUrl,verifyData,config)
  .then(response => {
    if(response?.data?.status === 'success'){
      setOnSuccessv(true)
    }
  })
  .catch(error => {
    setOnFailed((i) => !i)
      
    if(error.message){
      setOnApiFailed(error.message)
    }else{
      setOnApiFailed("Something went wrong.!")
    }
    console.error(error, "PUT API ERROR");
  });
  
}












  const chipAdornment = selectedChip ? (
    <InputAdornment position="start">
     {selectedChip.map(eachItem => (
       <Chip label={eachItem} onDelete={() => handleDeleteChip(eachItem)} />
     ))}
    </InputAdornment>
     ) : null;




  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}  sx={{ width: "100%", height: "100%"}} p={4}>
        <Stack spacing={2}>
{/* FIRST ROW */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate onSelecting={handleSelect} currentSelect={selectedMaker} isError={changedData?.VehicleBrandID? true : false}  Data={vehicleBrands} sideLabel={'Maker'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate onSelecting={handleSelect} currentSelect={selectedModel} isError={changedData?.VehicleBrandModelID? true : false}  Data={vehicleModel} sideLabel={'Model'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate onSelecting={handleSelect} currentSelect={selectedColor} isError={changedData?.VehicleColor? true : false}  Data={COLORS} sideLabel={'Color'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate onSelecting={handleSelect} currentSelect={selectedSeats} isError={changedData?.Seats? true : false}  Data={NO_OF_SEATS_CAT} sideLabel={'Number of Seats'} placeHolder={'choose'} />
    </Box>

</Stack>

{/* SECOND ROW */}

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >

    <Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate currentSelect={selectedEngine} isError={changedData?.VehicleEngineType? true : false} onSelecting={handleSelect} Data={ENGINE_TYPE} sideLabel={'Engine Type'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'}>
    <SelectFieldUpdate currentSelect={selectedCat} isError={changedData?.RideCategoryIDS? true : false} onSelecting={handleSelect} Data={VEHICLE_CATEGORY} sideLabel={'Vehicle Category'} placeHolder={'choose'} />
    </Box>
    <Box width={isMatch?'100%':'25%'} >
<InputFieldUpdate Note={regNo?.length > 8 && 'Invalid number' || regNo?.length < 6 && 'Invalid number'  } Value={regNo} isError={changedData?.RegistrationNumber? true : false} ErrNote={""} InChange={(e) => setRegNo(e.target.value)}  sideLabel={"Registration Plate"} placeHolder={"Registration Plate"} />
</Box>
{

vehicleType === 'Car' ? 
(
  <Box width={isMatch?'100%':'25%'}>
<SelectFieldUpdate currentSelect={selectedOwnerShip} isError={changedData?.OwnerShip? true : false} onSelecting={handleSelect} Data={OWNERSHIP} sideLabel={'Ownership'} placeHolder={'choose'} />
    </Box>):(
      <Box width={isMatch?'100%':'25%'}>
      <SelectFieldUpdate currentSelect={selectedTaxi} isError={changedData?.CabCompanyID? true : false} onSelecting={handleSelect} Data={allTaxiComp} sideLabel={'Choose your Taxi company'} placeHolder={'choose'} />
          </Box>
    )}

    </Stack>

{/* THIRD ROW */}

{/* <Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >

</Stack> */}

{

vehicleType === 'Car' ? 
(

<>
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
{/* <Box width={isMatch?'100%':'32%'}>
<SelectFieldUpdate currentSelect={selectedOwnerShip} isError={changedData?.OwnerShip? true : false} onSelecting={handleSelect} Data={OWNERSHIP} sideLabel={'Ownership'} placeHolder={'choose'} />
    </Box> */}

</Stack>

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'PHV Decal'} imageLink={phvDecalLink} isError={changedData?.PhvDecalPath? true : false} handleFileChange ={handleImageFilesThree} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'LTA Vehicle Log'} imageLink={ltaVehicleLink} isError={changedData?.LTAVehicleLog? true : false} handleFileChange ={handleImageFilesFour} />
</Box>
</Stack>
</>
) : (


  <>
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
{/* <Box width={isMatch?'100%':'32%'}>
<SelectFieldUpdate currentSelect={selectedTaxi} isError={changedData?.CabCompanyID? true : false} onSelecting={handleSelect} Data={allTaxiComp} sideLabel={'Choose your Taxi company'} placeHolder={'choose'} />
    </Box> */}

</Stack>

<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'MDT Screen upload'} imageLink={mDTlink} isError={changedData?.MDTScreenImage? true : false} handleFileChange ={handleImageFilesFive} />
</Box>
</Stack>
</>)

}




{/* DATEROW */}
<Box width={isMatch?'100%':'25%'} >
<DatePickerComponent  disableFuture={true} isError={changedData?.InspectionDate? true : false} initialValue={selectedIND} handleSelection={handleDateSelection} sideLabel={'Vehicle Inspection date'}/>
</Box>



{/* FOURTH ROW */}
<Stack alignItems={'center'} direction={isMatch ? 'column':'row'} spacing={3} >
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate sideLabel={'Vehicle front Picture'} imageLink={vehicleImageLink} isError={changedData?.EmploymentLetterPath? true : false} handleFileChange ={handleImageFiles} />
</Box>
<Box width={isMatch?'100%':'32.2%'} >
<ImageSelectorUpdate disableReq={true} sideLabel={'Letter of Employment (Optional)'} imageLink={letterLink} isError={changedData?.EmploymentLetterPath? true : false} handleFileChange ={handleImageFilesTwo} />
</Box>
</Stack>

{/* FIFTH ROW */}

<Stack>
         <FormControl required component="fieldset" sx={{ width: "66%"}} variant="standard">
         <Stack direction={'row'}>
         <FormLabel sx={{color:"black"}} component="legend" required={false} >Select Features</FormLabel>
         <Typography color={'red'}>*</Typography>
         </Stack>
        
         <OutlinedInput
         error={changedData?.AdOnFeatures? true : false }
     readOnly
     sx={{ borderRadius: '4px', height: '46px' }}
     fullWidth
     placeholder={selectedChip?.length < 1 &&"select"}
     startAdornment={chipAdornment}
   />
       </FormControl>
         </Stack>


{/* SIXTH */}

<Stack direction={'row'} spacing={2}>
         
         {
           adOnFeatures ? 
             adOnFeatures?.map((eachFeature) => !selectedChip?.includes(eachFeature?.name) && (

                 <Button
                 onClick={() => handleAddChip(eachFeature)}
                 sx={{color: '#0B2863',backgroundColor: '#EBF2FD',borderRadius: '4px',textTransform: 'none',
                   '&:hover': {
                     backgroundColor: '#FFFFFF',
                     color: '#0B2863',
                   },
                 }}
                 variant="contained"
                 startIcon={<AddIcon />}
               >
             {eachFeature?.name}
               </Button>

             )) : 
             (
                 <Typography color={'red'}>No Features available</Typography>
             )
         }
  
   </Stack>

   <Box width={isMatch?'100%':'32.2%'} >
{/* <InputFieldUpdate Value={remarkDetails} isError={false} ErrNote={""} InChange={(e) => setRemarkDetails(e.target.value)}   sideLabel={"Remarks"} placeHolder={"Remarks"} /> */}
</Box>

        </Stack>
        <NotifiProgress closeNotification={() => setOnSuccess(false)} submitStatus={submitHit} successNote={'Success'} Status={onSuccess} />
        <NotifiProgress closeNotification={() => setOnSuccessv(false)} submitStatus={submitHitv} successNote={'Success'} Status={onSuccessv} />
        <ErrorNotify submitStatus={submitHit || submitHitv} Status={submitHit && onFailed} errorNote={onApiFailed} />
        <FormSubmitButtons buttonStatus={edit}   handleSubmit={handleSubmit}/>
        {/* <ProgressionSubmit Clicking={onButtonClick} toPage={"pagetwo"} goBack={"pageone"} /> */}
   </Box>
  )
}

export default EditVehicleDetails