import React, { useEffect, useState } from 'react'
import NotifyAlertWhileDelete from '../../Components/Forms/NotifyAlertWhileDelete';
import DataTable from '../../Components/Table/DataTable';
import { Box, TableHead } from '@mui/material';
import dayjs from 'dayjs';
import ApiCall from '../../../Helper/ApiCalls';
import { checkAnyPrivilage, checkPrivilage } from '../../../Sessions/Permisssions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const columns = [
    {
      id: "id",
      label: "No",
      minWidth: 100,
      sort: true,
    },
  
    {
      id: "rideId",
      label: "Ride Id",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
      id: "rideType",
      label: "Ride Type",
      minWidth: 150,
      align: "left",
      sort: true,
    },
    {
        id: "userName",
        label: "User Id",
        minWidth: 170,
        align: "left",
        sort: true,
      },
    {
      id: "driverName",
      label: "Driver Id",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    
    {
      id: "pickupAddress",
      label: "Pickup Address",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "address",
      label: "Drop Address",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "fair",
      label: "Fare",
      minWidth: 200,
      align: "left",
      sort: true,
    },

    {
      id: "DateTime",
      label: "Date & Time",
      minWidth: 170,
      align: "left",
      sort: true,
    },
    {
      id: "rideStatus",
      label: "Status",
      minWidth: 170,
      align: "left",
      sort: true,
    },

    // rideType
    // {
    //   id: "status",
    //   label: "Status",
    //   minWidth: 170,
    //   align: "left",
    //   sort: false,
    //   title: {
    //     onTrue: true,
    //     onFalse: false,
    //   },
    // },
    {
        id: "actionCancel",
        label: "Actions",
        minWidth: 170,
        align: "left",
        sort: false,
      },
  ]; 



function ScheduledRide() {
    const [order, setOrder] = useState("ASC");
    const [tab , setTab] = useState('left')
    const [rows, setRows] = useState();
    const [success, setSuccess] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchInput , setSearchInput] = useState('')
    const [isFilterOn , setIsFilterOn] = useState(false)
    const [deleteStatus , setDeleteStatus] = useState(false)
    const [deleteData , setDeleteData] = useState({})
    const [totalRows,setTotalRows] = useState(0);
    const [disableCancel,setDisableCancel] = useState(false)
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const [currentPage,setCurrentPage] = useState(1)
    const {currentCountrydata:CountryID} = useSelector((state) => state.country)
    const [any,setAny] = useState()
    
    const [write,setWrite] = useState()
    const [edit,setEdit] = useState()
    const [deletePer,setDeletePer] = useState()

useEffect(()=>{
  let isAany = checkAnyPrivilage('Ride')
  setAny(isAany)
  let isWrite = checkPrivilage('Ride','CREATE')
  setWrite(isWrite)
 let isEdit = checkPrivilage('Ride','UPDATE')
   setEdit(isEdit)
 
   let isDelete = checkPrivilage('Ride','DELETE')
   setDeletePer(isDelete)
   },[])

    
  
    const navigate = useNavigate()
    const removeUnderscoresAndAddSpaces = (inputString)=> {
      // Use a regular expression to replace underscores with spaces globally
      return inputString?.replace(/_/g, ' ');
    }

  // api call
useEffect(()=>{
  const currentLimit = rowsPerPage
  const currentOffset = (currentPage-1) * rowsPerPage
    ApiCall("GET", `/trip-service/v1/admin/trip/all/shedule-rides/request/?countryId=${CountryID}`, null, "uui").then((data)=>{
      if(data?.result){
  
     const rideData = data?.result?.scheduledRideRequest
        
     console.log(rideData , "<---ride datasssssss - - - >>")
     const filteredData =  rideData?.slice(currentOffset, currentOffset + currentLimit).map((eachItem,index)=>{
      const currentStatus = eachItem?.CurrentRideStatus
             
      if(currentStatus === 'RIDE_CANCELLED_BY_ADMIN' ||currentStatus === 'RIDE_CANCELLED_BY_PASSENGER'|| currentStatus === 'RIDE_CANCELLED_BY_DRIVER'|| currentStatus === 'RIDE_COMPLETED'  ){
        setDisableCancel(true)
           
      }
       

      const formattedDate = dayjs(eachItem?.Date).format('YYYY-MM-DD')
      let dateString = eachItem?.Date
      const currentStartTime  =  dateString?.slice(11, 16);
     
      let items = {

            _id:eachItem._id,
            userID:eachItem._id,   //its not userId or anything , its the detail id , given because details page is build on favor with userId for userTable.
            id:index+1,
            rideId:eachItem?.CabTripID,
            fair:parseFloat(eachItem?.CabDurationFareInfo?.TotalFare)?.toFixed(2),
            DateTime:formattedDate+"  "+currentStartTime,
            rideStatus:removeUnderscoresAndAddSpaces(eachItem?.CurrentRideStatus),
            rideType:eachItem?.RideType,
            address:eachItem?.UserDestinationInfo?.[0]?.Address || 'N/A',
            pickupAddress:eachItem?.UserPickUpInfo?.PickupPointAddress || 'N/A',
            driverName:eachItem?.DriverID,
            userName:eachItem?.UserID,
            disable:!disableCancel
            // rideService:eachItem?.ServiceName,
            // description:eachItem?.Description,
            // vehicleType: vehicles?.join(", "),
            // vehicleCatogery: eachItem.VehicleCategory ?eachItem.VehicleCategory.join(", "):"N/A",
            // features:adOnFeatures.length > 0 ? adOnFeatures?.join(", "): "N/A",
            // status:eachItem?.IsEnabled
           }
  
           return items;
     })
  
 
  
     setRows(filteredData)
     setTableData(filteredData);
   } })
  },[success,CountryID,currentPage])


  


    useEffect(()=>{
        setRows(
          tableData.filter((item) =>
          item?.rideId?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.fair?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.DateTime?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.rideStatus?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.rideType?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.address?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.userName?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.driverName?.toString().toLowerCase().includes(searchInput.toLowerCase())      
            ))
         },[searchInput])


    const handleStatusChange =(id, newData)=>{
        //  console.log(`Switch with id ${id} changed. New data:`, newData);
         const paramsData = {valueServiceId:id,status:newData?.status}
        //  ApiCall("PATCH", "/trip-service/v1/admin/value-service/update/status",paramsData).then((data)=>{setSuccess(data)})
      }
  
  
  
  
  // delete status  
  const handleServiceDelete =(newData)=>{

    setDeleteStatus(true)
    const paramsData ={
      tripId:newData?._id
    }
    setDeleteData(paramsData)
   
  }
  
  const onDeleteYes =(yesData)=>{
    ApiCall("POST", `/cab-alloc-service/v1/admin/trip/cancel/ride`,yesData).then((data)=>{
     setSuccess(data) 
     // setDeleteStatus(false)
   })
   }
  
  
  const onCloseNotification = ()=>{
    setDeleteStatus(false)
  }
  
  const handleServiceEdit =(id,newData)=>{
    if(id){
      navigate(`/edit-service/${id}`)
    }
   
  
  }

  const handleProfileEdit = (id,newData)=>{
    const newTab = window.open(`/ride-details/${id}`, '_blank');
  if (newTab) {
    newTab.focus(); // Optionally, you can focus on the new tab
  }
    // if(id){
    //   navigate(`/ride-details/${id}`)
    // }
 }

    const handleChangeInSearch = (value)=>{
        setSearchInput(value)
   
     }

     const tabRender = (alignment)=>{
      setTab(alignment)
                              }


    const handleColumnFilter = (items, searchText) => {

        setSelectedItems(items); // Update selected filter items
      
        // If no items are selected and no search text, show all rows
        if (items?.length === 0 && searchText === "") {
          setRows(tableData);
          return;
        }
      
       
      
        // Filter the rows based on the selected items and search text
        const filteredRows = tableData.filter((row) => {
          // Check if any of the selected items are present in the row's features
          const featuresMatch = items.every(
            (item) => row.features && row.features.includes(item)
          );
      
      
      
          // Check if the row matches any of the selected vehicleCategory items
          const vehicleCategoryMatch = items.includes(row.vehicleCatogery);
      
          // Check if the row matches all of the selected vehicleType items
          const vehicleTypeMatch = items.every(
            (item) => row.vehicleType && row.vehicleType.includes(item)
          );
      
      
      
          // Check if the row contains the search text in any of its values
          const searchTextMatch = Object.values(row).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Return true if any of the filters match the row, otherwise return false
          return (
            (featuresMatch || vehicleCategoryMatch && items?.length === 1 || vehicleTypeMatch) &&
            searchTextMatch
          );
        });
      
        // Update the filtered rows
        setRows(filteredRows);
      };


    const handleFilterBar = (status)=>{
        setIsFilterOn(status)
      }
      


    const columnSortingFn = (id) => {
        if (order === "ASC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id"
              ? a[id].toLowerCase() > b[id].toLowerCase()
                ? 1
                : -1
              : a[id] > b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("DSC");
        }
    
        if (order === "DSC") {
          const sorted = [...rows].sort((a, b) =>
            id !== "id" && id !== "ride_fare"
              ? a[id].toLowerCase() < b[id].toLowerCase()
                ? 1
                : -1
              : id === "date"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : a[id] < b[id]
              ? 1
              : -1
          );
          setRows(sorted);
          setOrder("ASC");
        }
      };


      const handlePageChange=(no,rowsCount)=>{
        setCurrentPage(no)
        setRowsPerPage(rowsCount)
        
      } 


  return (
    <Box>
    <TableHead title={'Scheduled Ride'}/>
    <Box  borderRadius={'8px'} marginTop={2}  bgcolor='white' width='97%' height='100%' >
    <>
<NotifyAlertWhileDelete OnDeleteYes={onDeleteYes} Info={deleteData} onClosing={onCloseNotification} openStatus={deleteStatus} />
      <DataTable
           handlingPage={handlePageChange}
           totalCount={totalRows}
         DeletePermission={deletePer}
         ButtonPermission={write}
         EditPermission={edit} 
        handleClickProfileDetails={handleProfileEdit}
       isFilterOn={isFilterOn}
       openFilter={handleFilterBar}
        columns={columns}
        tableData={rows}
        columnSorting={columnSortingFn}
        // ButtonPath={'/create-service'}
        // ButtonLabel={'Create Service'}
        handleSwitchChange={handleStatusChange}
        handleDelete={handleServiceDelete}
        handleEdit={handleServiceEdit}
        columnFilter={handleColumnFilter}
        searchInput={handleChangeInSearch}
        filterComponent={''}
      />
      </>
     </Box>
    </Box>
  )
}

export default ScheduledRide
